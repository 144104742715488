module MasterData.Entities.FormValidation

open Elmish
open Elmish.Navigation
open Thoth.Json
open Shared
open MasterData.Entities.Types
open SharedComponents.Alerts
open Validation
open System

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let validateNewForm (formToValid : EntityNewForm) : FormValidation =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }

    let entity = formToValid.Entity
    let entityName _ =
        if not (String.IsNullOrWhiteSpace entity.Name) then
            create "entityname" ValidationState.Valid
        else create "entityname" (ValidationState.NotValid "Ein Gerätename muss vergeben sein.")
    let startNumber _ =
        match entity.CreateMultipleEntities with
        | Some cme ->
            if not (String.IsNullOrWhiteSpace cme.StartNumber) then
                let number =
                    match Int32.TryParse(cme.StartNumber) with
                    | (true, value) -> Some value
                    | _ -> None
                match number with
                | Some _ -> create "startnumber" ValidationState.Valid
                | None -> create "startnumber" (ValidationState.NotValid "Startnummer muss eine Ganzzahl und darf nicht leer sein.")
            else create "startnumber" (ValidationState.NotValid "Startnummer muss eine Ganzzahl und darf nicht leer sein.")
        | None -> create "startnumber" (ValidationState.NotValid "Startnummer muss eine Ganzzahl und darf nicht leer sein.")
    let quantity _ =
        match entity.CreateMultipleEntities with
        | Some cme ->
            if not (String.IsNullOrWhiteSpace cme.Quantity) then
                let quantity =
                    match Int32.TryParse(cme.Quantity) with
                    | (true, value) -> Some value
                    | _ -> None
                match quantity with
                | Some _ -> create "quantity" ValidationState.Valid
                | None -> create "quantity" (ValidationState.NotValid "Anzahl muss eine Ganzzahl und darf nicht leer sein.")
            else create "quantity" (ValidationState.NotValid "Anzahl muss eine Ganzzahl und darf nicht leer sein.")
        | None -> create "quantity" (ValidationState.NotValid "Anzahl muss eine Ganzzahl und darf nicht leer sein.")
    let newValidations = [
        match formToValid.Entity.CreateMultipleEntities with
        | None ->
            entityName ()
        | Some cme ->
            startNumber()
            quantity()
    ]
    let formValid =
        let isValid =
            newValidations
            |> List.map (fun r -> r.Value)
            |> List.filter (fun value -> match value with
                                         | ValidationState.Valid -> false
                                         | ValidationState.NotValid _ -> true)
            |> List.isEmpty
        if isValid then ValidationState.Valid else ValidationState.NotValid ""
    { Validations = [ 
        match formToValid.Entity.CreateMultipleEntities with
        | None ->
            entityName ()
        | Some cme ->
            startNumber()
            quantity() ]
      FormValid = formValid }

let validateEditForm (formToValid : EntityForm) : FormValidation =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }

    let entity = formToValid.Entity
    let entityName _ =
        if not (String.IsNullOrWhiteSpace entity.Name) then
            create "entityname" ValidationState.Valid
        else create "entityname" (ValidationState.NotValid "Ein Gerätename muss vergeben sein.")
    let newValidations = [ entityName (); ]
    let formValid =
        let isValid =
            newValidations
            |> List.map (fun r -> r.Value)
            |> List.filter (fun value -> match value with
                                         | ValidationState.Valid -> false
                                         | ValidationState.NotValid _ -> true)
            |> List.isEmpty
        if isValid then ValidationState.Valid else ValidationState.NotValid ""
    { Validations = [ entityName(); ]
      FormValid = formValid }

let entityNameAlreadyExists _ =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }
    let entityName _ =
        create "entityname" (ValidationState.NotValid "Dieser Name ist bereits vergeben.")
    { Validations = [ entityName(); ]
      FormValid = ValidationState.NotValid "" }
let numberInputWrong _ =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }
    let startnumber _ =
        create "startnumber" (ValidationState.NotValid "")
    let quantity _ =
        create "quantity" (ValidationState.NotValid "")
    { Validations = [ quantity()
                      startnumber() ]
      FormValid = ValidationState.NotValid "" }