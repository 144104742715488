module Dispositions.FreeOrderDisposition.State

open Client
open Elmish
open Dispositions.FreeOrderDisposition.Types
open Shared
open Shared.TaskTypes
open Shared.Entity
open Shared.ServiceOrder
open SharedComponents.Toast
open Fable.Core.JsInterop
open Browser.Dom
open Fetch
open SharedComponents.Spinners

let printTaskCmd (taskId : TaskId) taskName =
    let (TaskId taskId) = taskId
    let id = taskId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/print/pdf/document/task/%s" id), (sprintf "%s.pdf" taskName)) PdfFetched FetchError

let getFreeOrderCmd (freeOrderId : FreeOrderId) =
    let id = Shared.Helper.unwrapFreeOrderId freeOrderId
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/freeorders/%s" id) FreeOrderFetched FetchError

let init (orderId : FreeOrderId) taskId : Model * Cmd<Msg> =
   { FreeOrderId = orderId
     TaskId = taskId
     FreeOrder = None
     Signature = None
     Images = [ ]
     RequestState = RequestState.Active
     ImageViewerOpen = false
     ImageViewerIndex = 1
     Employees = [ ] }, Cmd.batch [ getFreeOrderCmd orderId; Commands.getEmployeesCmd EmployeesFetched FetchError ] 

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let update (msg:Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | OpenImageViewer index ->
        { model with ImageViewerOpen = true; ImageViewerIndex = index }, Cmd.none
    | CloseImageViewer ->
        { model with ImageViewerOpen = false }, Cmd.none
    | PrintAsPdf ->
        let newModel, newCmd =
            let pdfName = sprintf "report_%s" (System.DateTime.Now.ToString())
            { model with RequestState = RequestState.Active }, Cmd.batch [ printTaskCmd model.TaskId pdfName; toast (ToastType.Info "Dokument wird heruntergeladen")  ]
        newModel, newCmd
    | FreeOrderFetched response ->
        let task = response.Tasks |> List.find(fun t -> (t |> Shared.TaskTypes.Helper.freeOrderTaskId) = model.TaskId)
        let cmd =
            match task with
            | FreeOrderTask.FreeTask _ -> Cmd.none
            | FreeOrderTask.AssignedFreeTask t ->
                match t.TaskReport with
                | Some report ->
                    match report.Signature with
                    | Some signature ->
                        [ Commands.getImage signature.Id SignatureFetched FetchError ]
                    | None -> [ ]
                    |> List.append
                        (report.Images |> List.map (fun i -> Commands.getImage i.Id ImageFetched FetchError))
                    |> Cmd.batch
                | None -> Cmd.none

        { model with FreeOrder = response |> Some
                     RequestState = RequestState.NotActive }, cmd
    | ImageFetched image ->
        { model with Images = image :: model.Images }, Cmd.none
    | SignatureFetched signature ->
        { model with Signature = Some signature }, Cmd.none
    | PdfFetched (response, filename) ->
        { model with RequestState = RequestState.NotActive}, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none
    | EmployeesFetched response ->
        { model with Employees = response.Employees }, Cmd.none
    | FetchError e ->
        printfn "Error %O" e
        model, ErrorHandling.handleFetchError e
