module Shared.Material

open Shared

type Label =
    { Id : LabelId
      Label : string
      ColorHex : string }

type MaterialUnit =
    | Millimeter
    | Centimeter
    | Meter
    | Gram
    | Kilogram
    | Piece
    | Liter
    | Milliliter
    | Hour

type PrinzingCustomerSpecificMaterial =
    { RentPrice : float option }

type CustomerSpecificMaterial =
    | Default
    | Prinzing of PrinzingCustomerSpecificMaterial

type Material =
    { Id : MaterialId
      Name : string
      Unit : MaterialUnit
      Labels : Label list
      IsDeleted : bool
      CustomerSpecific : CustomerSpecificMaterial }

type MaterialsResponse =
    { Materials : Material list }

module Helper =

    let emptyCustomerSpecificMaterial = function
        | Shared.Customer.Prinzing ->
            { RentPrice = None }
            |> CustomerSpecificMaterial.Prinzing
        | Shared.Customer.Default -> CustomerSpecificMaterial.Default

    let emptyMaterial customer =
        { Id = System.Guid.Empty |> MaterialId
          Name = ""
          Unit = MaterialUnit.Piece
          Labels = [ ]
          IsDeleted = false
          CustomerSpecific = emptyCustomerSpecificMaterial customer }

    let unitToString (unit : MaterialUnit) =
        match unit with
        | Millimeter -> "Millimeter"
        | Centimeter -> "Zentimeter"
        | Meter -> "Meter"
        | Gram -> "Gramm"
        | Kilogram -> "Kilogramm"
        | Piece -> "Stück"
        | Liter -> "Liter"
        | Milliliter -> "Milliliter"
        | Hour -> "Stunde"

    let unitToShortString (unit : MaterialUnit) =
        match unit with
        | Millimeter -> "mm"
        | Centimeter -> "cm"
        | Meter -> "m"
        | Gram -> "g"
        | Kilogram -> "kg"
        | Piece -> "Stk"
        | Liter -> "l"
        | Milliliter -> "ml"
        | Hour -> "h"