module Dispositions.Helper

open Shared.TaskTypes
open System
open Shared
open SharedComponents.Badges

let mechanicTaskValues (tasks : ClientAssignedTask list) (referencedTaskId : TaskId) =
    let referencedTask =
        tasks
        |> List.tryFind (fun t -> t.Id = referencedTaskId)
    match referencedTask with
    | Some t ->
        t.ExecutionAdress, t.TaskPosition, t.ShortInfo, t.AdditionalInfo, t.State, t.ScheduledDate
    | None -> failwith "Value for referenced task not found."

let workTimeSumBadge (tasks : ClientAssignedTask list) userId date =
    let mapEmployeeDayTime (dateToCheck : DateTime) (plannedWorkTime : int<Minutes>) (travelTime : TravelTime option) =
        let secondsPerMinute = 60<Seconds/Minutes>
        if dateToCheck = date then
            match travelTime with
            | Some travelTime -> travelTime.Duration + (plannedWorkTime * secondsPerMinute)
            | None -> (plannedWorkTime * secondsPerMinute)
        else 0<Seconds>
    let plannedWorkTimeAndTravelTime (executionInformation : TaskExecutionInformation) =
        executionInformation.PlannedWorkTime, executionInformation.TravelTime
    let referencedTask taskId =
        tasks
        |> List.find (fun t -> taskId = t.Id)
        |> (fun t ->
            match t.TaskType with
            | AssignedTaskType.FreeTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.DeliveryTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.PickTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.ServiceTask-> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.ReturnTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.SelfserviceDeliveryTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.SelfserviceReturnTask -> plannedWorkTimeAndTravelTime t.ExecutionInformation
            | AssignedTaskType.SupportTask _ -> 0<Minutes>, None)
    let taskWorkTimes =
        tasks
        |> List.filter (fun t -> t.UserId = userId)
        |> List.map (fun t ->
            match t.TaskType with
            | AssignedTaskType.FreeTask _
            | AssignedTaskType.DeliveryTask _
            | AssignedTaskType.PickTask _
            | AssignedTaskType.ServiceTask _
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.SelfserviceReturnTask _
            | AssignedTaskType.ReturnTask -> mapEmployeeDayTime t.ScheduledDate t.ExecutionInformation.PlannedWorkTime t.ExecutionInformation.TravelTime
            | AssignedTaskType.SupportTask referencedTaskId ->
                (let _, _, _, _, _, taskDate = mechanicTaskValues tasks referencedTaskId
                 let plannedWorkTime, travelTime = referencedTask t.Id
                 mapEmployeeDayTime taskDate plannedWorkTime travelTime))
    let dayWorkTimeSum = taskWorkTimes |> List.sum
    let dayWorkTimeHours = dayWorkTimeSum / 3600
    let dayWorkTimeMinutes = (dayWorkTimeSum - dayWorkTimeHours * 3600) / 60
    if dayWorkTimeSum = 0<Seconds> then Fable.React.Helpers.nothing
    else badgePill (sprintf "Arbeitszeit ca. (%i:%02i h)" dayWorkTimeHours dayWorkTimeMinutes) BadgeClassType.Secondary

let datesAreEqual (date1 : DateTime) (date2 : DateTime) =
    date1.ToShortDateString() = date2.ToShortDateString()
