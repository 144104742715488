module Dispositions.TaskSplitModal

open Fable.React
open Fable.React.Props
open Dispositions.Types
open Shared.TaskTypes
open Shared.Entity
open SharedComponents
open Shared

let private dropArea splitAction dispatch =
    DragAndDrop.dropContainer
        (div [ Class (sprintf"drop-container") ]
            [ ])
        ((fun (e, currentSplittedTask) -> DroppedSplitTask (e,currentSplittedTask, splitAction)) >> dispatch)

let private entityBody (currentSplittedTask : SplittedTask) (entity : Entity) =
    DragAndDrop.dragContainer ""
        (div [ Class "disposition-split-drag-entity" ]
          [ p [ Class "disposition-split-drag-entity-description" ] [ str entity.Name ] ])
        (entity, currentSplittedTask)

let private splittedTaskBody dispatch isAddEntityNotAllowed (splittedTask : SplittedTask) =
    let (PositionNumber posNo) = splittedTask.PosNo
    div [ Class "disposition-split-body"]
      [ h5 [ Class "split-task-header" ] [ str (sprintf "%s %i" splittedTask.Name posNo) ]
        splittedTask.Entities
        |> List.map (entityBody splittedTask)
        |> ofList
        (if isAddEntityNotAllowed then
          Fable.React.Helpers.nothing
        else dropArea (SplitAction.ToSplit splittedTask) dispatch) ]


let private body model dispatch =
    let addEntityIsNotAllowed = model.SplittedTasks.Length <= 1
    let optionDropArea =
      if not (List.exists (fun (splitTask : SplittedTask) -> splitTask.Entities.Length > 1) model.SplittedTasks) then
        Fable.React.Helpers.nothing
      else
        dropArea SplitAction.NewSplit dispatch
    div [ Id "disposition-split-container" ]
      [ div [ Class "disposition-split-content" ]
          [ (model.SplittedTasks
            |> List.map (splittedTaskBody dispatch addEntityIsNotAllowed)
            |> ofList)
            optionDropArea ] ]

let view (model : Model) positiveAnswer negativeAnswer dispatch =
    let firstSplitTask = model.SplittedTasks.Head
    div [ Class "modal-content" ]
      [ div [ Class "modal-header" ]
          [ h5 [ Class "modal-title" ]
                [ str (sprintf "%s aufteilen" firstSplitTask.Name) ]
            button [ HTMLAttr.Type "button"
                     Class "close"
                     Data ("dismiss", "modal")
                     OnClick negativeAnswer]
              [ i [ Class "fas fa-times" ] [ ] ] ]
        div [ Class "modal-body" ]
          [ body model dispatch ]
        div [ Class "modal-footer"]
          [ button [ HTMLAttr.Type "button"
                     Class "btn btn-primary"
                     Data ("dismiss", "modal")
                     OnClick positiveAnswer]
              [ str "Teilen" ]
            button [ HTMLAttr.Type "button"
                     Class "btn btn-secondary"
                     Data ("dismiss", "modal")
                     OnClick negativeAnswer]
              [ str "Abbrechen" ]]]

let emptyContent =
    div [ Class "modal-content" ]
        [ ]

let viewModalContent modalContent =
    div [ Class "modal fade"
          Id "disposition-split-task-modal"
          TabIndex -1
          Data("backdrop", "static")
          Data("keyboard", "false")
          Role "dialog"]
        [ div [ Class "modal-dialog"
                Role "document" ]
               [ modalContent ]]
