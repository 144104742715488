module TemplateConfiguration.View

open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open TemplateConfiguration.Types
open SharedComponents.Alerts
open SharedComponents.Spinners
open Shared.Configuration
open Routes
open Fable.React
open Fable.React.Props
open Thoth.Json

open Shared
open Routes

open Fable.Core.JsInterop
open TemplateConfiguration.Types
open TemplateConfiguration.Helper
open SharedComponents.Spinners
open SharedComponents.Headers
open Shared.Entity
open System
open SharedComponents.Types
open SharedComponents.ReactSelect
open SharedComponents
open SharedComponents.FileUploads
open SharedComponents.Buttons
open TemplateConfiguration.FormValidation
open SharedComponents.Badges
open SharedComponents.DeleteMsgBox
open SharedComponents.Breadcrumb
open SharedComponents.Form
open Shared.Entity.Helper

let typeOptions =
    [ { Form.SelectOption.Value = (Encode.toString 0 AttributeType.Integer)
        Form.SelectOption.Label = "Ganzzahl" }
      { Form.SelectOption.Value = (Encode.toString 0 AttributeType.Decimal)
        Form.SelectOption.Label = "Kommazahl" }
      { Form.SelectOption.Value = (Encode.toString 0 AttributeType.String)
        Form.SelectOption.Label = "Text" }
      { Form.SelectOption.Value = (Encode.toString 0 AttributeType.Boolean)
        Form.SelectOption.Label = "Ja/Nein" }
      { Form.SelectOption.Value = (Encode.toString 0 AttributeType.Date)
        Form.SelectOption.Label = "Datum" }
      { Form.SelectOption.Value = (Encode.toString 0 AttributeType.DateWithReminder)
        Form.SelectOption.Label = "Datum mit Erinnerung" } ]

let private selectFilterProps (model : Model) dispatch =
    let createOptions =
      [| { label = "Geräte"
           value = SelectFilterType.TemplateFilter }
         { label = "Geräte-Gruppen"
           value = SelectFilterType.TemplateGroupFilter } |]

    let getDisplayName (filterType : SelectFilterType) =
      createOptions
      |> Array.find (fun v -> v.value = filterType) |> (fun o -> o.label)

    let selectFilterToDisplay =
      if model.SelectedFilters.IsEmpty then createOptions
      else
        createOptions
        |> Array.filter (fun v -> not (model.SelectedFilters |> List.contains v.value))

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Filter gefunden werden." ]

    let valueElements =
      if model.SelectedFilters.IsEmpty then
        [||]
      else
        let elements =
          model.SelectedFilters
          |> List.map (fun e -> { label = getDisplayName e; value = e })
          |> List.toArray
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Value elements |]

    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Options (selectFilterToDisplay)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.OnChange (SetSelectFilters >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Placeholder "Filter wählen"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.ClassName "ml-2 overview-select-filter"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.NoOptionsMessage (fun _ -> emptySearchResultContent) |]
        valueElements

let placeholderImg =
    img [ Src "noimage.png"
          Class "card-img-top"
          Alt "..." ]

let private templateProps (form : TemplateGroupNewForm) (referencedTemplate : Shared.Entity.ReferencedTemplate option) (model : Model) dispatch =
    let options (templates : Template list) =
        templates
        |> List.map (fun el ->
            { label = el.TemplateBaseInformation.Name
              value = el })
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Typ gefunden werden." ]

    let valueElement =
        match referencedTemplate with
        | Some referencedTemplate ->
            model.Templates
            |> List.find (fun t -> t.Id = referencedTemplate.TemplateId)
            |> (fun template ->
                [| ReactSelect.CommonProps<Template>.Value (Some { label = template.TemplateBaseInformation.Name
                                                                   value = template }) |] )
        | None -> [| ReactSelect.CommonProps<Template>.Value None |]
    Array.append
        [| SharedComponents.ReactSelect.CommonProps<Template>.Options (options form.SelectableTemplates)
           SharedComponents.ReactSelect.CommonProps<Template>.OnChange (fun e -> (referencedTemplate, e) |> SelectReferencedTemplate |> dispatch)
           SharedComponents.ReactSelect.CommonProps<Template>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<Template>.Placeholder "Teil auswählen"
           SharedComponents.ReactSelect.CommonProps<Template>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<Template>.ClassName "template-select flex-grow-1" |]
        valueElement

let referencedTemplateRow (model : Model) (form : TemplateGroupNewForm) (dispatch : Msg -> unit) index (referencedTemplate : Shared.Entity.ReferencedTemplate option) =
    let rowKey =
        match referencedTemplate with
        | Some referencedTemplate ->
            let (TemplateId referencedTemplateId) = referencedTemplate.TemplateId
            referencedTemplateId.ToString()
        | None ->
            "new-referenced-template-row"

    tr [ Key rowKey ]
        [ td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  Buttons.iconButton "fas fa-times" (fun _ -> referencedTemplate.TemplateId |> RemoveReferencedTemplate |> dispatch)
              | None -> nothing
               ]
          td [ ]
            [ ReactSelect.selectWithoutLabel (templateProps form referencedTemplate model dispatch) ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  Form.Input.inlineInput(
                      (fun ev -> (referencedTemplate.TemplateId, ev |> int) |> SetReferencedTemplateMin |> dispatch),
                      referencedTemplate.Min |> string)
              | None -> nothing ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  Form.Input.inlineInput(
                      (fun ev -> (referencedTemplate.TemplateId, ev |> int) |> SetReferencedTemplateMax |> dispatch),
                      referencedTemplate.Max |> string)
              | None -> nothing ] ]

let referencedTemplates (form : TemplateGroupNewForm) (model : Model) formState (dispatch : Msg -> unit) =
    div [ Class "table" ]
        [ table [ Class "table" ]
            [ thead [ ]
                [ tr [ ]
                    [ th [ Class "action-buttons-col" ]
                        [ str "" ]
                      th [ ]
                        [ str "Name" ]
                      th [ Class "min-col" ]
                        [ str "Min" ]
                      th [ Class "max-col" ]
                        [ str "Max" ] ] ]
              tbody [ ]
                [ if form.NewReferencedTemplateRow then
                    referencedTemplateRow model form dispatch 0 None
                  match form.Template.ChildTemplates with
                  | Some children ->
                      children.Templates
                      |> List.map Some
                      |> List.mapi (referencedTemplateRow model form dispatch)
                      |> ofList
                  | None -> nothing ] ] ]

let private cast attributeType =
    match attributeType with
    | Integer -> attributeType
    | Decimal -> attributeType
    | String -> attributeType
    | Boolean -> attributeType
    | Date -> attributeType
    | DateWithReminder -> attributeType
    | OptionalInteger -> Integer
    | OptionalDecimal -> AttributeType.Decimal
    | OptionalString -> AttributeType.String
    | OptionalBoolean -> AttributeType.Boolean
    | OptionalDate -> Date
    | OptionalDateWithReminder -> DateWithReminder

let templateAttributeRow model formState (dispatch : Msg -> unit) index (attribute : Shared.Entity.Attribute) =
    tr [ Key (attribute.Id.ToString()) ]
        [ td [ ]
            [ Buttons.iconButton "fas fa-times" (fun _ -> index |> RemoveAttribute |> dispatch) ]
          td [ ]
            [ Form.inputWithoutLabel
                  (fun ev -> (index, ev.Value) |> SetAttributeName |> dispatch)
                  attribute.Name "Attributsname" (formState <> New)
               ]
          td [ ]
            [ Form.selectField
                typeOptions (fun ev -> dispatch (SetAttributeType (index, (Decode.Auto.unsafeFromString<Shared.Entity.AttributeType> ev.Value))))
                (Encode.toString 0 (cast attribute.Type)) (formState <> New) ]
          td [ ]
            [ Form.checkBoxWithoutLabel (fun ev -> (index, ev.Checked) |> SetAttributeOptional |> dispatch)
                                        (not (isAttributeTypeOptional attribute.Type)) false ]
          td [ ]
            [ Form.checkBoxWithoutLabel (fun ev -> (index, ev.Checked) |> SetAttributeDisplayOnPrint |> dispatch)
                                        attribute.DisplayOnPrint false ] ]

let templateAttributes attributes model formState (dispatch : Msg -> unit) =
    div [ Class "table" ]
        [ table [ Class "table" ]
            [ thead [ ]
                [ tr [ ]
                    [ th [ ]
                        [ str "Aktion" ]
                      th [ ]
                        [ str "Name" ]
                      th [ ]
                        [ str "Eigenschaft" ]
                      th [ ]
                        [ str "Pflichtfeld" ]
                      th [ ]
                        [ str "Drucken" ] ] ]
              tbody [ ]
                [ attributes
                  |> List.mapi (templateAttributeRow model formState dispatch)
                  |> ofList ] ] ]

let private displayedImage (contentOption : DocumentWithContent option) =
    div [ Class "template-uploaded-image-preview template-image"]
        [ match contentOption with
          | Some image ->
            img [ Class "card-img-top template-image"
                  Src (sprintf "data:image/png;base64, %s" image.Content) ]
          | None -> placeholderImg ]

let private templateGroupForm (form : TemplateGroupNewForm) (model : Model) (dispatch : Msg -> unit) (formState : FormState) =
    let template = form.Template
    div [ Id "masterdata-content" ]
        [ mainHeader "Neue Baugerätekategorie (Gruppe)"
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ div [ Class "p-2" ]
                      [ subHeaderForm "Allgemein"
                        div [ ]
                          [ Form.Input.inlineInput((fun ev -> ev |> SetTemplateName |> dispatch), template.TemplateBaseInformation.Name,
                                                 inputLabel =  "Name", validation = (form.FormValidation, "templatename"),
                                                 required = true, isDisabled = false)
                            Form.Input.inlineInput((fun ev -> ev |> SetTemplateDescription |> dispatch), template.TemplateBaseInformation.Description,
                                                 inputLabel = "Beschreibung", isDisabled = false)
                            Form.requiredLegend ] ] ]
              div [ Class "flex-grow-1"]
                [ ] ]
          div [ Class "d-flex controls" ]
            [ subHeaderFormWithAddButton "Eigenschaften" (fun _ -> dispatch AddAttribute) ]
          validationWarning form.FormValidation "duplicateattributename"
          validationWarning form.FormValidation "emptyattributename"
          templateAttributes template.Attributes model formState dispatch
          div [ Class "d-flex flex-row flex-column"]
            [ ]
          subHeaderFormWithAddButton "Teile" (fun _ -> dispatch AddReferencedTemplate)
          validationWarning form.FormValidation "referencedTemplates"
          referencedTemplates form model formState dispatch
          div [ Class "d-flex" ]
            [ Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> SaveTemplateGroup |> dispatch) (model.SaveState = SaveState.Saving) "Baugerätekategorie speichern" "ml-auto" ] ]

let private templateForm (form : TemplateNewForm) (model : Model) (dispatch : Msg -> unit) (formState : FormState) =
    let template = form.Template
    div [ Id "masterdata-content" ]
        [ mainHeader "Neue Baugerätekategorie"
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ div [ Class "p-2" ]
                      [ subHeaderForm "Allgemein"
                        div [ ]
                          [ Form.Input.inlineInput((fun ev -> ev |> SetTemplateName |> dispatch), template.TemplateBaseInformation.Name,
                                                 inputLabel =  "Name", validation = (form.FormValidation, "templatename"),
                                                 required = true, isDisabled = false)
                            Form.Input.inlineInput((fun ev -> ev |> SetTemplateDescription |> dispatch), template.TemplateBaseInformation.Description,
                                                 inputLabel = "Beschreibung", isDisabled = false)
                            Form.requiredLegend ] ] ]
              div [ Class "flex-grow-1"]
                [ ] ]
          div [ Class "d-flex controls" ]
            [ subHeaderFormWithAddButton "Eigenschaften" (fun _ -> dispatch AddAttribute) ]
          validationWarning form.FormValidation "duplicateattributename"
          validationWarning form.FormValidation "emptyattributename"
          templateAttributes template.Attributes model formState dispatch
          div [ Class "d-flex" ]
            [ Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> SaveTemplateGroup |> dispatch) (model.SaveState = SaveState.Saving) "Baugerätekategorie speichern" "ml-auto" ] ]

let referencedTemplateRowView (model : Model) (templateGroup : Template) (dispatch : Msg -> unit) index (referencedTemplate : Shared.Entity.ReferencedTemplate option) =
    let rowKey =
        match referencedTemplate with
        | Some referencedTemplate ->
            let (TemplateId referencedTemplateId) = referencedTemplate.TemplateId
            referencedTemplateId.ToString()
        | None ->
            "new-referenced-template-row"

    tr [ Key rowKey ]
        [ td [ ]
            [ match model.FormState with
              | Loading -> nothing
              | TemplateConfiguration.Types.FormState.Edit _ -> nothing
              | TemplateConfiguration.Types.FormState.New _ ->
                  match referencedTemplate with
                  | Some referencedTemplate ->
                      Buttons.iconButton "fas fa-times" (fun _ -> referencedTemplate.TemplateId |> RemoveReferencedTemplate |> dispatch)
                  | None -> nothing ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  match model.Templates |> List.tryFind (fun t -> t.Id = referencedTemplate.TemplateId) with
                  | Some template -> str template.TemplateBaseInformation.Name
                  | None -> str "Lädt ..."
              | None -> str "todo"
               ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  referencedTemplate.Min |> string |> str
              | None -> nothing ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  referencedTemplate.Max |> string |> str
              | None -> nothing ] ]

let referencedTemplatesView (template : Template) (model : Model) formState (dispatch : Msg -> unit) =
    div [ Class "table" ]
        [ table [ Class "table" ]
            [ thead [ ]
                [ tr [ ]
                    [ th [ ]
                        [ str "" ]
                      th [ ]
                        [ str "Name" ]
                      th [ ]
                        [ str "Min" ]
                      th [ ]
                        [ str "Max" ] ] ]
              tbody [ ]
                [ match template.ChildTemplates with
                  | Some children ->
                      children.Templates
                      |> List.map Some
                      |> List.mapi (referencedTemplateRowView model template dispatch)
                      |> ofList
                  | None -> nothing ] ] ]

let editTemplateAttributeRow model (form : TemplateGroupEditForm) (dispatch : Msg -> unit) index (attribute : Shared.Entity.Attribute) =
    let isDisabled =
        match form.EditField with
        | EditField.Attribute attributeId -> attributeId <> attribute.Id
        | _ -> true
    tr [ Key (attribute.Id.ToString()) ]
        [ td [ ]
            [ match form.EditField with
              | EditField.Attribute attributeId when attributeId = attribute.Id ->
                  Buttons.iconButton "fas fa-undo" (fun _ -> EndEdit |> dispatch)
                  Buttons.iconButton "fas fa-save" (fun _ -> SaveTemplateGroup |> dispatch)
                  Buttons.iconButtonToOpenModal "fas fa-trash" (fun _ -> OpenDeleteAttributeModal |> dispatch) "#delete-messagebox-form-modal"
              | _ ->
                  Buttons.iconButton "fas fa-pen" (fun _ -> attribute.Id |> EditAttribute |> dispatch)
            ]
          td [ ]
            [ Form.inputWithoutLabel
                (fun ev -> (index, ev.Value) |> SetAttributeName |> dispatch)
                attribute.Name "Attributsname" isDisabled ]
          td [ ]
            [ str (attribute.Type |> Helper.toString)
//              Form.selectField
//                typeOptions (fun ev -> dispatch (SetAttributeType (index, (Decode.Auto.unsafeFromString<Shared.Entity.AttributeType> ev.Value))))
//                (Encode.toString 0 attribute.Type) (formState <> New)
            ]
          td [ ]
            [ Form.checkBoxWithoutLabel (fun ev -> (index, ev.Checked) |> SetAttributeOptional |> dispatch)
                                        (not (isAttributeTypeOptional attribute.Type)) true ]
          td [ ]
            [ Form.checkBoxWithoutLabel (fun ev -> (index, ev.Checked) |> SetAttributeDisplayOnPrint |> dispatch)
                                        attribute.DisplayOnPrint isDisabled ] ]

let templateAttributeEditNewRow model formState (dispatch : Msg -> unit) index (attribute : Shared.Entity.Attribute) =
    tr [ Key (attribute.Id.ToString()) ]
        [ td [ ]
            [ Buttons.iconButton "fas fa-times" (fun _ -> index |> RemoveAttribute |> dispatch) ]
          td [ ]
            [ Form.inputWithoutLabel
                  (fun ev -> (index, ev.Value) |> SetAttributeName |> dispatch)
                  attribute.Name "Attributsname" (formState <> New)
               ]
          td [ ]
            [ Form.selectField
                typeOptions (fun ev -> dispatch (SetAttributeType (index, (Decode.Auto.unsafeFromString<Shared.Entity.AttributeType> ev.Value))))
                (Encode.toString 0 (cast attribute.Type)) (formState <> New) ]
          td [ ]
            [ Form.checkBoxWithoutLabel (fun ev -> (index, ev.Checked) |> SetAttributeDisplayOnPrint |> dispatch)
                                        attribute.DisplayOnPrint true ] ]

let editTemplateAttributes (attributes : Shared.Entity.Attribute list) model formState (dispatch : Msg -> unit) =
    let disabledFormClass = match formState.EditField with | EditField.EditNewAttribute _ -> "" | _ -> "disabled-form"
    div [ Class "table" ]
        [ table [ Class "table" ]
            [ thead [ ]
                [ tr [ ]
                    [ th [ Class "action-buttons-col" ]
                        [ str "Aktion" ]
                      th [ ]
                        [ str "Name" ]
                      th [ ]
                        [ str "Eigenschaft" ]
                      th [ ]
                        [ str "Pflichtfeld" ]
                      th [ Class "print-option-col" ]
                        [ str "Drucken" ] ] ]
              tbody [ Class disabledFormClass ]
                [ attributes
                  |> List.mapi (fun i a ->
                      match formState.EditField with
                      | EditField.EditNewAttribute newAttributes ->
                          if newAttributes |> List.map (fun a -> a.Id) |> List.contains a.Id then templateAttributeEditNewRow model FormState.New dispatch i a
                          else editTemplateAttributeRow model formState dispatch i a
                      | EditField.EditBaseData _
                      | EditField.ReferencedTemplate _
                      | EditField.Attribute _
                      | EditField.Nothing ->
                          editTemplateAttributeRow model formState dispatch i a)
                  |> ofList ] ] ]

let private logo (form : TemplateGroupEditForm) model dispatch =
    div [ ]
      [ subHeaderForm "Bild"
        displayedImage form.TemplateGroupImage
        ImageUploadDialog.imageUploadDialog (ImageUploadDialog.TargetType.Template form.Template.Id) (fun _ -> dispatch Msg.FetchTemplate)
      ]

let private deleteAttributeModal (form : TemplateGroupEditForm) dispatch =
    match form.DeleteAttributeRequested with
    | None ->
        deleteRequestMessageBox Fable.React.Helpers.nothing
    | Some id ->
        let displayText = sprintf "Wollen Sie die Eigenschaft wirklich löschen? Alle bereits gespeicherten Daten an den Geräten gehen verloren."
        deleteRequestMessageBox
            (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteAttributeRequest))
                                            (fun _ -> dispatch (AbortAttributeDelete))
                                            displayText)

let private templateDocumentRow (template : Template) model dispatch (document : Document)  =
    tr [ Key (string document.Id) ]
        [ td [ Class "entity-document-name" ]
            [ a [ OnClick (fun _ -> dispatch (DownloadDocument (document.Id, document.Name)))
                  Download true ]
                [ str document.Name ] ]
          td [ ]
            [ str document.Description ]
          td [ ]
            [ DocumentDeleteDialog.documentDeleteDialog template.Id document.Id (fun _ -> dispatch Msg.FetchTemplate) ]]

let private templateGroupEditView (form : TemplateGroupEditForm) (model : Model) (dispatch : Msg -> unit) (formState : FormState) =
    let templateGroup = form.Template.TemplateBaseInformation
    div [ Id "masterdata-content" ]
        [ section [ ]
            [ deleteAttributeModal form dispatch ]
          mainHeader templateGroup.Name
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ div [ Class "p-2"]
                    [ subHeaderFormWithEdit (match form.EditField with | EditField.EditBaseData _ -> true | _ -> false)
                            "Allgemein"
                            (fun _ -> EditBaseInformation |> dispatch) ""
                            (fun _ -> SaveTemplateGroup |> dispatch) ""
                            (fun _ -> EndEdit |> dispatch) ""

                      match form.EditField with
                      | EditField.EditBaseData ->
                          div [ ]
                            [ Form.Input.inlineInput((fun ev -> ev |> SetTemplateName |> dispatch), templateGroup.Name,
                                                   inputLabel =  "Name", validation = (form.FormValidation, "templatename"),
                                                   required = true, isDisabled = false)
                              Form.Input.inlineInput((fun ev -> ev |> SetTemplateDescription |> dispatch), templateGroup.Description,
                                                   inputLabel = "Beschreibung", isDisabled = false)
                              Form.requiredLegend ]
                      | _ ->
                          div [ Class "disabled-form" ]
                            [ Form.Input.inlineInput((fun ev -> ev |> SetTemplateName |> dispatch), templateGroup.Name,
                                                   inputLabel =  "Name", validation = (form.FormValidation, "templatename"),
                                                   required = true, isDisabled = true)
                              Form.Input.inlineInput((fun ev -> ev |> SetTemplateDescription |> dispatch), templateGroup.Description,
                                                   inputLabel = "Beschreibung", isDisabled = true)
                              Form.requiredLegend ] ] ]
              div [ Class "flex-grow-1"]
                [ div [ Class "p-2"]
                    [ logo form model dispatch ] ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ div [ Class "p-2" ]
                      [ ] ]
              div [ Class "flex-grow-1"]
                [ ] ]
          div [ Class "d-flex controls" ]
            [ subHeaderByFormStateWithAddButton (match form.EditField with | EditField.EditNewAttribute _ -> true | _ -> false)
                  "Eigenschaften"
                  (fun _ -> AddAttribute |> dispatch)
                  (fun _ -> EditNewAttribute |> dispatch) ""
                  (fun _ -> dispatch SaveTemplateGroup) ""
                  (fun _ -> EndEdit |> dispatch) ""
                  FormState.View ]
          validationWarning form.FormValidation "duplicateattributename"
          validationWarning form.FormValidation "emptyattributename"
          editTemplateAttributes form.Template.Attributes model form dispatch
          div [ Class "d-flex flex-row flex-column"]
            [ ]
          if form.Template.ChildTemplates.IsSome then
            subHeaderForm "Teile"
            referencedTemplatesView form.Template model formState dispatch
          div [ Class "p-2" ]
            [ subHeaderForm "Dateien"
              FileUploadDialog.fileUploadDialog
                (FileUploadDialog.TargetType.Template form.Template.Id) (fun _ -> dispatch UploadedDocument)
              div [ Class "table-responsive" ]
                [ table [ Class "table" ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ ]
                                [ str "Name" ]
                              th [ ]
                                [ str "Beschreibung"]
                              th [ ]
                                [ str "Aktion"] ] ]
                      tbody [ ]
                        [ form.Template.Documents
                          |> List.map (templateDocumentRow form.Template model dispatch)
                          |> ofList  ] ] ] ]
        ]

let newTemplateBreadcrumb name =
    breadcrumb [
        breadcrumbLink (Page.TemplateConfiguration |> Routes.toPath) "Baugerätekategorien-Administration"
        breadcrumbStr name
    ]

let newView (model : Model) (dispatch : Msg -> unit) =
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.TemplateRequestState
          match model.FormState with
          | Loading -> overlaySpinner model.TemplateRequestState
          | TemplateConfiguration.Types.FormState.New newOf ->
              div [ ]
                [ match newOf with
                  | Template form ->
                      newTemplateBreadcrumb form.Template.TemplateBaseInformation.Name
                      templateForm form model dispatch FormState.New
                  | TemplateGroup form ->
                      newTemplateBreadcrumb form.Template.TemplateBaseInformation.Name
                      templateGroupForm form model dispatch FormState.New]
          | TemplateConfiguration.Types.FormState.Edit templateGroup -> str "error case" ]

let editTemplateFormBreadcrumb (templateSnapshot : Template) (template : Template) =
    let (TemplateId templateId) = template.Id
    breadcrumb [
        breadcrumbLink (Page.TemplateConfiguration |> Routes.toPath) "Baugerätekategorien-Administration"
        breadcrumbStr template.TemplateBaseInformation.Name
    ]

let editView (model : Model) (dispatch : Msg -> unit) =
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.TemplateRequestState
          match model.FormState with
          | Loading -> overlaySpinner model.TemplateRequestState
          | TemplateConfiguration.Types.FormState.New form -> str "error case"
          | TemplateConfiguration.Types.FormState.Edit form ->
              div [ ]
                [ match form.Template.ChildTemplates with
                  | Some _ ->
                      editTemplateFormBreadcrumb form.TemplateSnapshot form.Template
                  | None ->
                      editTemplateFormBreadcrumb form.TemplateSnapshot  form.Template
                  templateGroupEditView form model dispatch FormState.New ] ]

let templateRow model dispatch (template : Template) =
    let (TemplateId templateId) = template.Id
    tr [ ]
        [ td []
            [ str template.TemplateBaseInformation.Name ]
          td [ ]
            [ match template.ChildTemplates with
              | Some _ -> badgePill "Geräte-Gruppe" BadgeClassType.Primary
              | None -> badgePill "Gerät" BadgeClassType.Secondary ]
          td [ ]
            [ str template.TemplateBaseInformation.Description ]
          td [ ]
            [ a [ Href (Routes.toPath (Page.TemplateGroupViewForm templateId)) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Anzeigen" ] ]
              a [ Href (Routes.toPath (Page.Entity templateId)) ]
                [ i [ Class ("fas fa-eye ml-3" ) ] [ ]
                  span [ Class "url-text" ] [ str "Alle Geräte der Kategorie anzeigen" ] ] ] ]

let templatesOverview (model : Model) dispatch =
  let isEditOrCreatable = isTemplateEditableOrCreatable model.UserData
  div [ Id "masterdata-content" ]
      [ mainHeader "Baugerätekategorien-Administration"
        div [ Class "controls d-flex" ]
          [ div [ Class "" ]
              [ SearchBar.searchBar (fun ev -> ev.Value |> SetFilter |> dispatch) model.FilterText "overview-search-bar" ]
            div [ Class "flex-fill" ]
              [ ReactSelect.multiSelectWithoutLabel (selectFilterProps model dispatch) ]
            if isEditOrCreatable then
                div [ Class "ml-auto mr-3" ]
                  [ Buttons.primaryButton (Routes.toPath Page.TemplatesNewForm) "Kategorie anlegen" "ml-auto" ]
                div [ ]
                  [ Buttons.primaryButton (Routes.toPath Page.TemplateGroupNewForm) "Kategorie (Gruppe) anlegen" "ml-auto" ]
            else Fable.React.Helpers.nothing ]
        div [ Class "d-flex flex-wrap" ]
            [ table [ Class "table" ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ ]
                                [ str "Name" ]
                              th [ ]
                                [ str "Art"]
                              th [ ]
                                [ str "Beschreibung"]
                              th [ ]
                                [ str "Aktion"] ] ]
                      tbody [ ]
                        [ model.SelectFilterTemplates
                          |> List.map (templateRow model dispatch)
                          |> ofList  ] ] ] ]

let view (model : Model) (dispatch : Msg -> unit) =
  div [ Id "masterdata-container"
        Class "flex-grow-1 d-flex flex-column" ]
      [ overlaySpinner model.TemplateRequestState
        templatesOverview model dispatch ]

