module ErrorViews.FatalErrorView

open Fable.React
open Fable.React.Props
open Elmish
open SharedComponents.Jumbotron

type Model = { Loading : bool }

type Msg =
    | FetchError of exn

let init () : Model * Cmd<Msg> =
    let initialModel = { Loading = false }
    initialModel, Cmd.none

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | FetchError e ->
        model, Cmd.none

let content model dispatch =
    let className = "error-jumbotron"
    let headerTxt = "Oops! Es tut uns leid."
    let leadText = "Es ist leider ein Fehler aufgetreten."
    let descText = "Wir arbeiten mit Hochdruck daran, dass Problem zu lösen."
    let route = Routes.toPath Routes.Page.Dashboard
    let btnText = "zur Anwendung"
    div [ Id "errorview-content"]
        [ errorJumbotron className headerTxt leadText descText route btnText ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "errorview-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ content model dispatch  ]
