module UserManagement.View

open Fable.React
open Fable.React.Props
open UserManagement.Types
open Routes
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.Spinners
open SharedComponents.ReactSelect
open SharedComponents.Types.Helpers
open Validation
open UserManagement.Helper
open SharedComponents.Breadcrumb

let private selectFilterProps (model : Model) dispatch =
    let createOptions =
      [| { label = "Administrator"
           value = SelectFilterType.Admin }
         { label = "Disponent"
           value = SelectFilterType.Dispatcher }
         { label = "Monteur"
           value = SelectFilterType.Mechanic }
         { label = "Fahrer"
           value = SelectFilterType.Driver } |]

    let getDisplayName (filterType : SelectFilterType) =
      createOptions
      |> Array.find (fun v -> v.value = filterType) |> (fun o -> o.label)

    let selectFilterToDisplay =
      if model.SelectedFilters.IsEmpty then createOptions
      else
        createOptions
        |> Array.filter (fun v -> not (model.SelectedFilters |> List.contains v.value))

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Filter gefunden werden." ]

    let valueElements =
      if model.SelectedFilters.IsEmpty then
        [||]
      else
        let elements =
          model.SelectedFilters
          |> List.map (fun e -> { label = getDisplayName e; value = e })
          |> List.toArray
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Value elements |]

    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Options (selectFilterToDisplay)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.OnChange (SetSelectFilters >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Placeholder "Filter wählen"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.ClassName "ml-2 user-select-filter"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.NoOptionsMessage (fun _ -> emptySearchResultContent) |]
        valueElements


let private roleProps (roles : Role list) (formValidation : FormValidation option, key) (model : Model) isDisabled dispatch =
    let isInvalid, invalidText = Validation.Validate.isInvalid formValidation key
    let getRoleName r =
        match r with
        | Administrator -> "Admin"
        | Driver -> "Fahrer"
        | Dispatcher -> "Disponent"
        | Mechanic -> "Monteur"
        | Technician -> "Techniker"
        | Picker -> "Kommissionierer"
    let options (roles : Role list) =
        roles
        |> List.map (fun r ->
            { label = getRoleName r.Name
              value = r })
        |> List.toArray
    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte keine passende Rolle gefunden werden." ]
    let rolesToDisplay =
      (if isDisabled then
        model.Roles
       else
        model.Roles
        |> List.filter(fun e -> not (roles |> List.contains(e))))
    let valueElements =
      let elements =
        roles
        |> List.map (fun e -> { label = getRoleName e.Name; value = e })
        |> List.toArray
      [| SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Value elements |]
    let className =
      if isInvalid then
        "role-select disposition-select flex-grow-1"
      else
        "role-select flex-grow-1"
    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Options (options rolesToDisplay)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.OnChange (SetRoles >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsDisabled isDisabled
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.ClassName className |]
        valueElements

let private userEditForm (userForm : EditUserForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    let user = userForm.User.User
    let userSecurityInfo = userForm.User
    let isBaseInformationNotInEditMode = (match userForm.EditField with | EditField.EditBaseInformation _ -> false | _ -> true)
    let disabledFormClass = if isBaseInformationNotInEditMode then "disabled-form" else ""
    let isUserRightsNotInEditMode = (match userForm.EditField with | EditField.EditRoles _ -> false | _ -> true)
    let isPasswordNotInEditMode = (match userForm.EditField with | EditField.EditPassword _ -> false | _ -> true)

    div [ Id "user-management-content" ]
      [ div [ ]
          [ mainHeaderBar None "Benutzer" None (Some (fun _ -> dispatch RequestDelete))
            subHeaderByFormState (match userForm.EditField with | EditField.EditBaseInformation _ -> true | _ -> false)
                "Allgemein"
                (fun _ -> EditBaseInformation |> dispatch) ""
                (fun _ -> SaveUser |> dispatch) ""
                (fun _ -> EndEdit |> dispatch) ""
                formState
            div [ Class disabledFormClass ]
                [ Form.Input.inlineInput((fun ev -> ev |> string |> SetEmail |> dispatch), user.Email,
                                   inputLabel = "Email", validation = (userForm.FormValidation, "useremail"),
                                   isDisabled = isBaseInformationNotInEditMode, cyPostFix = "email")
                  Form.Input.inlineInput((fun ev -> ev |> string |> SetFirstname |> dispatch), user.Firstname,
                                   inputLabel = "Vorname", isDisabled = isBaseInformationNotInEditMode, cyPostFix = "firstname")
                  Form.Input.inlineInput((fun ev -> ev |> string |> SetLastname |> dispatch), user.Lastname,
                                   inputLabel = "Nachname", isDisabled = isBaseInformationNotInEditMode, cyPostFix = "lastname")
                ]
            subHeaderByFormState (match userForm.EditField with | EditField.EditRoles _ -> true | _ -> false)
                "Rechte"
                (fun _ -> EditRoles |> dispatch) ""
                (fun _ -> SaveUser |> dispatch) ""
                (fun _ -> EndEdit |> dispatch) ""
                formState
            ReactSelect.multiSelectWithLabel (roleProps userForm.User.Roles (userForm.FormValidation, "userroles") model isUserRightsNotInEditMode dispatch) (toLabelType "Rolle" false true formState) "label label-right"
            subHeaderByFormState (match userForm.EditField with | EditField.EditPassword _ -> true | _ -> false)
                "Passwort"
                (fun _ -> EditPassword |> dispatch) ""
                (fun _ -> SaveUser |> dispatch) ""
                (fun _ -> EndEdit |> dispatch) ""
                formState
            Form.passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.Password (toLabelType "Passwort" isPasswordNotInEditMode true formState) "password"
            Form.passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetSecondPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.SecondPassword (toLabelType "Passwort wiederholen" isPasswordNotInEditMode true formState) "password-confirmation" ] ]

let private userNewForm (userForm : NewUserForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    let user = userForm.User.User
    let userSecurityInfo = userForm.User
    div [ Id "user-management-content" ]
      [ div [ ]
          [ mainHeader "Benutzer anlegen"
            subHeaderForm "Allgemein"
            Form.Input.inlineInput((fun ev -> ev |> string |> SetEmail |> dispatch), user.Email,
                                   inputLabel = "Email", validation = (userForm.FormValidation, "useremail"), cyPostFix = "email")
            Form.Input.inlineInput((fun ev -> ev |> string |> SetFirstname |> dispatch), user.Firstname,
                                   inputLabel = "Vorname", cyPostFix = "firstname")
            Form.Input.inlineInput((fun ev -> ev |> string |> SetLastname |> dispatch), user.Lastname,
                                   inputLabel = "Nachname", cyPostFix = "lastname")
            subHeaderForm "Rechte"
            ReactSelect.multiSelectWithLabel (roleProps userForm.User.Roles (userForm.FormValidation, "userroles") model false dispatch) (toLabelType "Rolle" false true formState) "label label-right"
            subHeaderForm "Passwort"
            Form.passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.Password (toLabelType "Passwort" false true formState) "password"
            Form.passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetSecondPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.SecondPassword (toLabelType "Passwort wiederholen" false true formState) "password-confirmation"
            div [ Class "d-flex"]
              [ Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> SaveUser |> dispatch) (model.SaveState = SaveState.Saving) "Benutzer speichern" "ml-auto" ] ] ]

let usermanagementFormBreadcrumb name =
    breadcrumb [
        breadcrumbLink (Page.UserManagement |> Routes.toPath) "Benutzer"
        breadcrumbStr name
    ]

let userFormView model dispatch =
    div [ Id "user-management-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ (match model.FormState with
          | FormState.New form ->
              div [ ]
                [ overlaySpinner model.UsersRequestState
                  usermanagementFormBreadcrumb (sprintf "%s %s" form.User.User.Firstname form.User.User.Lastname)
                  userNewForm form model dispatch Types.FormState.New]
          | Edit form -> failwith "wrong formstate"
          | Loading -> overlaySpinner RequestState.NotActive) ]

let userViewFormView (model : Model) dispatch =
    div [ Id "user-management-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.UsersRequestState
          section [ ]
            [ deleteModal model.FormState dispatch ]
          (match model.FormState with
          | Edit form ->
              div [ ]
                [ usermanagementFormBreadcrumb (sprintf "%s %s" form.UserSnapshot.User.Firstname form.UserSnapshot.User.Lastname)
                  userEditForm form model dispatch SharedComponents.Types.FormState.View ]
          | New form -> failwith "wrong formstate"
          | Loading -> overlaySpinner RequestState.Active) ]

let private userRow (user : User)  =
    tr [ Key (string user.Id) ]
        [ td [ ]
            [ str user.Firstname ]
          td [ ]
            [ str user.Lastname ]
          td [ ]
            [ str user.Email ]
          td [ ]
            [ a [ Href (Routes.toPath (Routes.Page.UserManagementViewForm user.Id)) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Anzeigen" ] ] ] ]

let private usersTable (model : Model) dispatch =
    table [ Class "table" ]
      [ thead [ ]
          [ tr [ ]
              [ th [ ]
                  [ str "Vorname" ]
                th [ ]
                  [ str "Nachname" ]
                th [ ]
                  [ str "Email"]
                th [ ]
                  [ str "Aktion"] ] ]
        tbody [ ]
          [ model.SelectFilterUsers
            |> List.map userRow
            |> ofList ] ]

let private userManagementContent (model : Model) dispatch =
    div [ Id "user-management-content"
          Class "d-flex flex-column" ]
        [ mainHeader "Benutzerverwaltung"
          div [ Class "controls d-flex mb-2" ]
            [ div [ Class "" ]
                    [ SearchBar.searchBar (fun ev -> ev.Value |> SetFilter |> dispatch) model.FilterText "user-search-bar" ]
              div [ Class "flex-fill" ]
                [ ReactSelect.multiSelectWithoutLabel (selectFilterProps model dispatch) ]
              div [ Class "" ]
                [ Buttons.primaryButton (Routes.toPath Page.UserManagementNewForm) "Neue Benutzer anlegen" "ml-auto" ] ]
          div [ Class "d-flex flex-wrap"]
            [ usersTable model dispatch ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "user-management-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.UsersRequestState
          userManagementContent model dispatch ]
