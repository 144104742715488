module CarrierManagement.State

open Elmish
open CarrierManagement.Types
open Elmish.Navigation
open Shared
open Client
open System
open Validation
open Shared.Configuration
open SharedComponents.Toast
open Routes
open Shared.Carrier
open SharedComponents.Spinners

let getCarriersCmd =
    Commands.getCarriersCmd CarriersFetched FetchError

let getCarrierCmd carrierId =
    Commands.getCarrierCmd carrierId CarrierFetched FetchError

let createCarrierCmd (carrier : Carrier) =
    let body = Thoth.Json.Encode.Auto.toString (0, carrier)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<SaveCarrierResult>> ("/api/carriers", body) CarrierCreated FetchError

let updateCarrierCmd (carrierId : CarrierId) (updateElement : CarrierUpdateDto) =
    let (CarrierId carrierId) = carrierId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<SaveCarrierResult>>
                             ((sprintf "/api/carriers/%s" (carrierId.ToString())), body)
                             CarrierUpdated FetchError
let validateForm (carrier : Carrier) : FormValidation =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }

    let nameValidation _ =
        if not (String.IsNullOrWhiteSpace carrier.Name) then
            create "carrier-name" ValidationState.Valid
        else create "carrier-name" (ValidationState.NotValid "Ein Name muss vergeben sein.")

    let validations = [ nameValidation () ]
    let formValid =
        let isValid =
            validations
            |> List.map (fun r -> r.Value)
            |> List.filter (fun value -> match value with
                                         | ValidationState.Valid -> false
                                         | ValidationState.NotValid _ -> true)
            |> List.isEmpty
        if isValid then ValidationState.Valid else ValidationState.NotValid ""
    { Validations = validations
      FormValid = formValid }

let initialModel : Model =
    { Carriers = []
      Form = Loading
      CarrierRequestState = RequestState.Active }

let initOverview userData : Model * Cmd<Msg> =
    initialModel, getCarriersCmd

let initNewView userData : Model * Cmd<Msg> =
    let form =
        { CarrierNewForm.Carrier = Helper.emptyCarrier
          FormValidation = None }
    { initialModel with Form = form |> CarrierForm.New
                        CarrierRequestState = RequestState.NotActive }, Cmd.batch [ ]

let initEditView carrierId userData : Model * Cmd<Msg> =
    let carrierId = carrierId |> CarrierId
    initialModel, Cmd.batch [ getCarrierCmd carrierId ]

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | SetName name ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with Name = name } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with Name = name } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetAdditionalInfo value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with AdditionalInfoText = value } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with AdditionalInfoText = value } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetContactPerson value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with ContactPerson = value } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with ContactPerson = value } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetEmailAddress value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with ContactInformation = { form.Carrier.ContactInformation with EMailAddress = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with ContactInformation = { form.Carrier.ContactInformation with EMailAddress = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetPhoneNumber value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with ContactInformation = { form.Carrier.ContactInformation with PhoneNumber = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with ContactInformation = { form.Carrier.ContactInformation with PhoneNumber = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetStreet value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with Street = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with Street = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetHouseNumber value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with HouseNumber = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with HouseNumber = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetCity value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with City = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with City = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none
    | SetZipCode value ->
        let form =
            match model.Form with
            | CarrierForm.Loading -> model.Form
            | New form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with ZipCode = value} } }
                |> CarrierForm.New
            | Edit form ->
                { form with Carrier = { form.Carrier with Address = { form.Carrier.Address with ZipCode = value} } }
                |> CarrierForm.Edit
        { model with Form = form }, Cmd.none

    | SaveCarrier ->
        match model.Form with
        | Loading -> model, Cmd.none
        | New form ->
            let validation = form.Carrier |> validateForm
            match validation.FormValid with
            | ValidationState.Valid ->
                { model with CarrierRequestState = RequestState.Active }, createCarrierCmd form.Carrier
            | NotValid _ ->
                { model with Form = { form with FormValidation = Some validation } |> CarrierForm.New }, Cmd.none
        | Edit form ->
            let validation = form.Carrier |> validateForm
            match validation.FormValid with
            | ValidationState.Valid ->
                match form.EditField with
                | EditField.EditCarrier ->
                    { model with CarrierRequestState = RequestState.Active },
                    form.Carrier
                    |> CarrierUpdateDto.UpdateCarrier
                    |> updateCarrierCmd form.Carrier.Id
                | EditField.EditAddress ->
                    { model with CarrierRequestState = RequestState.Active },
                    form.Carrier.Address
                    |> CarrierUpdateDto.UpdateAddress
                    |> updateCarrierCmd form.Carrier.Id
                | EditField.Nothing -> model, Cmd.none
            | NotValid _ ->
                { model with Form = { form with FormValidation = Some validation } |> CarrierForm.Edit }, Cmd.none

    | EditCarrier ->
        match model.Form with
        | Loading
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = EditField.EditCarrier } |> CarrierForm.Edit }, Cmd.none
    | EditAddress ->
        match model.Form with
        | Loading
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = EditField.EditAddress } |> CarrierForm.Edit }, Cmd.none
    | AbortEdit ->
        match model.Form with
        | Loading
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = Nothing
                                            Carrier = form.CarrierSnapshot } |> CarrierForm.Edit }, Cmd.none


    /// Requests
    | CarriersFetched response ->
        { model with Carriers = response.Carriers
                     CarrierRequestState = RequestState.NotActive }, Cmd.none
    | CarrierFetched carrier ->
        match model.Form with
        | Loading ->
            let form =
                { Carrier = carrier
                  CarrierSnapshot = carrier
                  EditField = EditField.Nothing
                  FormValidation = None }
            { model with Form = form |> CarrierForm.Edit
                         CarrierRequestState = RequestState.NotActive }, Cmd.none
        | New _
        | Edit _ ->
            { model with CarrierRequestState = RequestState.NotActive }, Cmd.none
    | CarrierCreated carrierResult ->
        match carrierResult.Result with
        | Saved carrierId ->
            let (CarrierId carrierId) = carrierId
            { model with CarrierRequestState = RequestState.NotActive }, Cmd.batch [
                carrierId |> Page.CarrierManagementViewForm |> Routes.toPath |> Navigation.newUrl
                toast (ToastType.Success "Spedition erfolgreich angelegt.")
            ]
        | NameAlreadyExists carrierId ->
            let (CarrierId carrierId) = carrierId
            { model with CarrierRequestState = RequestState.NotActive }, Cmd.batch [ toast (ToastType.Error "Spedition mit diesem Namen exisitert bereits.") ]
    | CarrierUpdated response ->
        match model.Form with
        | Loading -> { model with CarrierRequestState = RequestState.NotActive }, Cmd.none
        | New _ -> { model with CarrierRequestState = RequestState.NotActive }, Cmd.none
        | Edit form ->
            match response.Result with
            | Saved carrierId ->
                { model with Form = { form with EditField = EditField.Nothing } |> CarrierForm.Edit
                             CarrierRequestState = RequestState.NotActive },
                Cmd.batch [
                    toast (ToastType.Success "Spedition erfolgreich bearbeitet.")
                ]
            | NameAlreadyExists carrierId ->
                { model with CarrierRequestState = RequestState.NotActive }, Cmd.batch [ toast (ToastType.Error "Spedition mit diesem Namen exisitert bereits.") ]
    | FetchError e ->
        printfn "Failed"
        { model with CarrierRequestState = RequestState.NotActive }, Cmd.none

