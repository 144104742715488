module MasterData.Templates.State

open Client
open Elmish
open MasterData.Templates.Types
open Shared.Entity
open SharedComponents.Spinners

let private initialModel userData =
    { Templates = [ ]
      UserData = userData
      Filter = SharedComponents.SearchBar.init [ ]
      FilterText = ""
      SelectedFilters = []
      SelectFilterTemplates = []
      TemplateRequestState = RequestState.Active }

let initOverview userData : Model * Cmd<Msg> =
      initialModel userData , Cmd.batch [ Commands.getTemplatesCmd TemplatesFetched FetchError ]

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | SetFilter filter ->
        { model with FilterText = filter }, Cmd.OfAsync.result (SharedComponents.SearchBar.filter<Template, Msg> filter model.Filter Filtered)
    | Filtered filterModel ->
        { model with Filter = filterModel }, Cmd.ofMsg ApplySelectFilter
    | SetSelectFilters filters ->
        let newFilters = filters |> Array.toList |> List.map (fun o -> o.value)
        { model with SelectedFilters = newFilters }, Cmd.ofMsg ApplySelectFilter
    | ApplySelectFilter ->
        let filteredTemplates =
            if model.SelectedFilters.IsEmpty then model.Filter.FilteredElements else
            model.Filter.FilteredElements
            |> List.filter (fun x ->
                            match x.ChildTemplates with
                            | Some _ -> model.SelectedFilters |> List.contains (SelectFilterType.TemplateGroupFilter)
                            | None -> model.SelectedFilters |> List.contains (SelectFilterType.TemplateFilter))
        { model with SelectFilterTemplates = filteredTemplates }, Cmd.none

    // Requests
    | TemplatesFetched response ->
        { model with Templates = response.Templates
                     Filter = SharedComponents.SearchBar.init response.Templates
                     SelectFilterTemplates = response.Templates
                     TemplateRequestState = RequestState.NotActive }, Cmd.none

    | FetchError e ->
        model, ErrorHandling.handleFetchError e
