module DispositionOverview.State

open Elmish
open DispositionOverview.Types
open Shared
open System
open Shared.Entity
open Client
open Shared.TaskTypes
open SharedComponents.Spinners

let getEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntitiesResponse> "/api/entities" EntitiesFetched FetchError

let getRolesCmd =
    Cmd.OfPromise.either Communication.getRequest<RolesResponse> "/api/roles" RolesFetched FetchError

let getTasksCmd (startDate : DateTime) (endDate : DateTime) =
    let startDate = startDate.ToString("dd.MM.yyyy")
    let endDate = endDate.ToString("dd.MM.yyyy")
    Cmd.OfPromise.either Communication.getRequest<ClientTasksResponse> (sprintf "/api/tasks/bydaterange/%s/%s" startDate endDate) TasksFetched FetchError

let elementsForUserAt (tasks : ClientAssignedTask list) userId date =
    tasks
    |> List.filter (fun t -> t.UserId = userId && t.ScheduledDate = date)
    |> List.map TableElement.AssignedTask

let weekRange (date : DateTime) =
    let dayOfWeek = date.DayOfWeek |> int
    let startDate =
        if dayOfWeek = 0 then /// special case sunday
            (date.AddDays -6.).Date
        else
            (date.AddDays ((-1 * (dayOfWeek - 1)) |> float)).Date
    [ for i in [ 0 .. 6 ] -> (startDate.AddDays (i |> float)).Date ]

let weekStartAndEndDate (dateRange : DateTime list) =
    dateRange.Head, (dateRange |> List.rev |> List.head)

let init query : Model * Cmd<Msg> =
    let initialModel =
        { SidebarState = Open
          Employees = [ ]
          Entities = [ ]
          VisibleEmployees = [ ]
          Roles = [ ]
          DateRange = weekRange DateTime.UtcNow.Date
          AssignedTasks = [ ]
          Message = None
          SelectedRoles = [ ]
          RequestState = RequestState.Active }
    let startDate, endDate = weekStartAndEndDate initialModel.DateRange
    initialModel, Cmd.batch [ Commands.getEmployeesCmd EmployeesFetched FetchError; getTasksCmd startDate endDate; getEntitiesCmd; getRolesCmd ]

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | SwitchSidebarState ->
        let newSidebarState =
            match model.SidebarState with
            | Close -> Open
            | Open -> Close
        { model with SidebarState = newSidebarState }, Cmd.none
    | ShowPrevWeek ->
        let range =
            model.DateRange |> List.head |> (fun d -> d.AddDays -1.)
            |> weekRange
        let startDate, endDate = weekStartAndEndDate range
        { model with DateRange = range }, getTasksCmd startDate endDate
    | ShowNextWeek ->
        let range =
            model.DateRange |> List.rev |> List.head |> (fun d -> d.AddDays 1.)
            |> weekRange
        let startDate, endDate = weekStartAndEndDate range
        { model with DateRange = range }, getTasksCmd startDate endDate

    | SetRoles roles ->
        let newRoles = roles |> Array.toList |> List.map (fun o -> o.value)
        { model with SelectedRoles = newRoles }, Cmd.ofMsg SetVisibleEmployees
    | SetVisibleEmployees ->
        let selectedRoles =
          model.SelectedRoles
          |> List.map (fun r -> r.Name)
        let filteredEmployees =
          model.Employees
          |> List.filter (fun e -> e.RoleNames |> List.exists (fun r -> selectedRoles |> List.contains r))
          |> Seq.toList
        { model with VisibleEmployees = filteredEmployees }, Cmd.none
    | EmployeesFetched response ->
        { model with Employees = response.Employees }, Cmd.ofMsg SetVisibleEmployees
    | EntitiesFetched response ->
        { model with Entities = response.Entities }, Cmd.none
    | RolesFetched response ->
        let filteredRoles =
            response.Roles
            |> List.filter (fun r ->
                r.Name = RoleNames.Driver ||
                r.Name = Mechanic ||
                r.Name = Picker ||
                r.Name = RoleNames.Technician)
        { model with Roles = filteredRoles
                     SelectedRoles = filteredRoles }, Cmd.ofMsg SetVisibleEmployees
    | TasksFetched response ->
        let assignedTasks =
            response.Tasks |> List.map (fun task ->
                match task with
                | ClientTask.AssignedTask t -> Some t
                | ClientTask.UnassignedTask t -> None)
            |> List.choose id
        { model with AssignedTasks = assignedTasks
                     RequestState = RequestState.NotActive }, Cmd.none
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
