module Dispositions.TaskModal

open Fable.React
open Fable.React.Props
open Feliz
open Dispositions.Types
open Shared.TaskTypes
open Client.SharedComponents
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.ReactSelect
open Shared.TaskTypes.Helper
open Shared.Configuration
open Shared.Address


let private mechanicProps (model : Model) (taskUserId : int) (selectedMechanics : Employee list) dispatch isDisabled =
    let options (mechanics : Employee list) =
        mechanics
        |> List.filter (fun m -> m.Id <> taskUserId)
        |> List.filter (fun m -> m.RoleNames |> List.exists (fun r -> r = RoleNames.Mechanic))
        |> List.filter (fun m -> selectedMechanics |> List.contains m |> not)
        |> List.map (fun m ->
            { label = sprintf "%s %s" m.Firstname m.Lastname
              value = m })
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Monteur gefunden werden." ]

    let valueElement = [| ReactSelect.CommonProps<Employee>.Value None |]
    Array.append
        [| SharedComponents.ReactSelect.CommonProps<Employee>.Options (options model.Employees)
           SharedComponents.ReactSelect.CommonProps<Employee>.OnChange (fun m -> AddMechanicToTask m.Value |> dispatch)
           SharedComponents.ReactSelect.CommonProps<Employee>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<Employee>.IsClearable false
           SharedComponents.ReactSelect.CommonProps<Employee>.Placeholder "Monteur auswählen"
           SharedComponents.ReactSelect.CommonProps<Employee>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<Employee>.ClassName "mechanic-select flex-grow-1"
           SharedComponents.ReactSelect.CommonProps<Employee>.IsDisabled isDisabled |]
        valueElement

let private saveButton isDisabled dispatch =
    if isDisabled then Fable.React.Helpers.nothing
    else
        button [ HTMLAttr.Type "button"
                 Class "btn btn-primary ml-2"
                 Data ("dismiss", "modal")
                 OnClick (fun _ -> dispatch SaveTaskInformation) ]
            [ str "Speichern" ]

let private finishTaskButton (task : ClientAssignedTask) dispatch =

    match task.State with
    | ClientTaskState.Finished _ -> Fable.React.Helpers.nothing
    | ClientTaskState.Started _
    | ClientTaskState.Planned _ ->
        button [ HTMLAttr.Type "button"
                 Class "btn btn-primary"
                 Data ("dismiss", "modal")
                 OnClick (fun _ -> dispatch CloseTask) ]
            [ str "Aufgabe abschließen" ]

let private assignedRentOrderTaskBody (allUsers : Employee list) (task : ClientAssignedTask) (model : Model) (taskUserId : int) (selectedMechanics : Employee list) isDisabled dispatch =
    let mechanicElement (mechanic : Employee) =
        if isDisabled then
          str (sprintf "%s %s" mechanic.Firstname mechanic.Lastname)
        else Buttons.iconButtonWithLabel "fas fa-times" (sprintf "%s %s" mechanic.Firstname mechanic.Lastname) (fun _ -> dispatch (RemoveMechanicFromTask mechanic.Id))
    let userName = match (allUsers |> List.tryFind(fun e -> e.Id = taskUserId)) with | Some e -> sprintf "%s %s" e.Firstname e.Lastname | None -> ""
    let plannedWorkTime = task.ExecutionInformation.PlannedWorkTime
    let comment = task.ExecutionInformation.Comment
    let durationHours = plannedWorkTime / 60
    let durationMinutes = plannedWorkTime - durationHours * 60
    div [ ]
      [ Alerts.getAlert model.TaskModalMessage
        subHeaderForm "Informationen"
        div [ Class "d-flex" ]
            [ Form.inlineCheckBox (fun ev -> SetAllowMultipleDrivers |> dispatch) task.AllowMultipleDrivers "mehrere Fahrer / Spedition" isDisabled
              Html.span [
                prop.className "task-tooltip ml-auto mt-auto mb-auto mr-2"
                prop.children [
                    Html.i [
                        prop.custom ("data-tip", (sprintf "Die Lieferung erfolgt durch mehrere Fahrer oder eine Spedition. %s ist für die Verladung zuständig." userName))
                        prop.className "fas fa-info-circle date-icon ml-1"
                    ]
                    ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
                ]
            ]
            ]
        Form.eightHourSlider (fun ev -> ev.Value |> int |> SetTaskWorkTime |> dispatch) plannedWorkTime (sprintf "Dauer (%i:%02i h)" durationHours durationMinutes) isDisabled
        Form.textArea (fun ev -> dispatch (SetTaskComment ev)) comment "Kommentar" isDisabled "task-modal-comment"
        subHeaderForm "Monteure"
        (if isDisabled then Fable.React.Helpers.nothing else ReactSelect.selectWithoutLabel (mechanicProps model taskUserId selectedMechanics dispatch isDisabled))
        ul [ Class "list-group mt-2" ]
          [ selectedMechanics
            |> List.map (fun mechanic ->
              li [ Class "list-group-item"
                   Key (mechanic.Id.ToString()) ]
                [ mechanicElement mechanic ])
            |> ofList  ] ]

let private assignedFreeOrderTaskBody (task : ClientAssignedTask) (model : Model) isDisabled dispatch =
    let plannedWorkTime = task.ExecutionInformation.PlannedWorkTime
    let comment = task.ExecutionInformation.Comment
    let durationHours = plannedWorkTime / 60
    let durationMinutes = plannedWorkTime - durationHours * 60
    div [ ]
      [ Alerts.getAlert model.TaskModalMessage
        subHeaderForm "Informationen"
        Form.eightHourSlider (fun ev -> ev.Value |> int |> SetTaskWorkTime |> dispatch) plannedWorkTime (sprintf "Dauer (%i:%02i h)" durationHours durationMinutes) isDisabled
        Form.textArea (fun ev -> dispatch (SetTaskComment ev)) comment "Kommentar" isDisabled "task-modal-comment"
      ]

let private assignedRentOrderReturnTaskBody (allUsers : Employee list) (task : ClientAssignedTask) (model : Model) (taskUserId : int) (selectedMechanics : Employee list) isDisabled dispatch =
    let mechanicElement (mechanic : Employee) =
        if isDisabled then
          str (sprintf "%s %s" mechanic.Firstname mechanic.Lastname)
        else Buttons.iconButtonWithLabel "fas fa-times" (sprintf "%s %s" mechanic.Firstname mechanic.Lastname) (fun _ -> dispatch (RemoveMechanicFromTask mechanic.Id))
    let userName = match (allUsers |> List.tryFind(fun e -> e.Id = taskUserId)) with | Some e -> sprintf "%s %s" e.Firstname e.Lastname | None -> ""
    let plannedWorkTime = task.ExecutionInformation.PlannedWorkTime
    let comment = task.ExecutionInformation.Comment
    let durationHours = plannedWorkTime / 60
    let durationMinutes = plannedWorkTime - durationHours * 60
    div [ ]
      [ Alerts.getAlert model.TaskModalMessage
        subHeaderForm "Informationen"
        div [ Class "d-flex" ]
            [ Form.inlineCheckBox (fun ev -> SetAllowMultipleDrivers |> dispatch) task.AllowMultipleDrivers "mehrere Fahrer / Spedition" isDisabled
              Html.span [
                prop.className "task-tooltip ml-auto mt-auto mb-auto mr-2"
                prop.children [
                    Html.i [
                        prop.custom ("data-tip", (sprintf "Die Lieferung erfolgt durch mehrere Fahrer oder eine Spedition. %s ist für die Verladung zuständig." userName))
                        prop.className "fas fa-info-circle date-icon ml-1"
                    ]
                    ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
                ]
            ]
            ]
        Form.eightHourSlider (fun ev -> ev.Value |> int |> SetTaskWorkTime |> dispatch) plannedWorkTime (sprintf "Dauer (%i:%02i h)" durationHours durationMinutes) isDisabled
        Form.textArea (fun ev -> dispatch (SetTaskComment ev)) comment "Kommentar" isDisabled "task-modal-comment"
        subHeaderForm "Monteure"
        (if isDisabled then Fable.React.Helpers.nothing else ReactSelect.selectWithoutLabel (mechanicProps model taskUserId selectedMechanics dispatch isDisabled))
        ul [ Class "list-group mt-2" ]
          [ selectedMechanics
            |> List.map (fun mechanic ->
              li [ Class "list-group-item"
                   Key (mechanic.Id.ToString()) ]
                [ mechanicElement mechanic ])
            |> ofList  ]

        let listElement key header content toolTipText (address : Address) (returnTarget : ReturnTarget) isActive =
            let active = if isActive then "active" else ""
            let onClickFnct = if task.State = ClientTaskState.Planned then (fun _ -> returnTarget |> SetReturnTarget |> dispatch) else ignore
            li [ Class (sprintf "list-group-item %s" active)
                 Key key
                 OnClick onClickFnct ]
              [ div [ Class "d-flex w-100 justify-content-between" ]
                  [ h6 [ Class "mb-1" ]
                      [ str header ] ]
                small [ ]
                  [ str content
                    Html.span [
                      prop.className "task-tooltip ml-auto mt-auto mb-auto mr-2"
                      prop.children [
                          Html.i [
                              prop.custom ("data-tip", toolTipText)
                              prop.className "fas fa-info-circle date-icon ml-1"
                          ]
                          ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
                      ]
                     ]
                    br [ ]
                    str (sprintf "%s %s" address.Street address.HouseNumber)
                    br [ ]
                    str (sprintf "%s %s" address.ZipCode address.City) ] ]

        let isTaskSelected taskId =
          match task.ReturnTarget with
          | Some rt ->
              match rt with
              | ReturnTarget.Storage locationId -> false
              | ReturnTarget.Task taskId -> taskId = taskId
          | None -> false

        let isLocationSelected locId =
          match task.ReturnTarget with
          | Some rt ->
              match rt with
              | ReturnTarget.Storage locationId -> locationId = locId
              | ReturnTarget.Task taskId -> false
          | None -> false

        let getUserName userId =
            match model.Employees |> List.tryFind(fun e -> e.Id = userId) with
            | Some e -> sprintf "%s %s" e.Firstname e.Lastname
            | None -> ""
        let assignedDeliveryTasks =
            if task.State = ClientTaskState.Finished then
              model.AssignedTasks
                  |> List.filter(fun t ->
                    t.TaskType = AssignedTaskType.DeliveryTask && t.EntityIds |> List.exists (fun e -> task.EntityIds |> List.contains e))
                  |> List.map (fun t -> listElement (t.Id.ToString()) "Lieferaufgabe (eingeplant)" (sprintf "eingeplante Lieferung: %s durch: %s" (t.ScheduledDate.ToShortDateString()) (getUserName t.UserId)) t.AdditionalInfo t.ExecutionAdress (ReturnTarget.Task t.Id) (isTaskSelected t.Id) )
            else
              model.AssignedTasks
                  |> List.filter(fun t ->
                    t.State = ClientTaskState.Planned &&
                    t.TaskType = AssignedTaskType.DeliveryTask && t.EntityIds |> List.exists (fun e -> task.EntityIds |> List.contains e))
                  |> List.map (fun t -> listElement (t.Id.ToString()) "Lieferaufgabe (eingeplant)" (sprintf "eingeplante Lieferung: %s durch: %s" (t.ScheduledDate.ToShortDateString()) (getUserName t.UserId)) t.AdditionalInfo t.ExecutionAdress (ReturnTarget.Task t.Id) (isTaskSelected t.Id) )
        let deliveryTasks =
            model.UnassignedTasks
                |> List.filter(fun t ->
                  t.TaskType = TaskType.DeliveryTask && t.EntityIds |> List.exists (fun e -> task.EntityIds |> List.contains e))
                |> List.map (fun t -> listElement (t.Id.ToString()) "Lieferaufgabe" (sprintf "geplante Lieferung: %s" (t.PlannedDate.ToShortDateString())) t.AdditionalInfo t.ExecutionAdress (ReturnTarget.Task t.Id) (isTaskSelected t.Id) )
        let storages =
            match model.Configuration with
            | None -> []
            | Some config -> //str config.MainLocation.Name
              let mainLoc = listElement (config.MainLocation.Id.ToString()) "Hauptlager" config.MainLocation.Name "" config.MainLocation.Address (ReturnTarget.Storage config.MainLocation.Id) (isLocationSelected config.MainLocation.Id)
              let locations =
                 config.Locations
                |> List.map (fun loc -> listElement (loc.Id.ToString()) "Lager" loc.Name "" loc.Address (ReturnTarget.Storage loc.Id) (isLocationSelected loc.Id))
              mainLoc :: locations

        subHeaderForm "Zielort"
        ul [ Class "list-group mt-2 list-group-followup-delivery" ]
          [ assignedDeliveryTasks |> List.append deliveryTasks |> List.append  storages |> ofList
             ] ]

let private assignedSelfServiceReturnTaskBody (task : ClientAssignedTask) (model : Model) dispatch =
    let isLocationSelected locId =
      match task.ReturnTarget with
      | Some rt ->
          match rt with
          | ReturnTarget.Storage locationId -> locationId = locId
          | ReturnTarget.Task taskId -> false
      | None -> false
    div [ ]
      [ Alerts.getAlert model.TaskModalMessage
        subHeaderForm "Informationen"

        let listElement key header content toolTipText (address : Address) (returnTarget : ReturnTarget) isActive =
            let active = if isActive then "active" else ""
            let onClickFnct = if task.State = ClientTaskState.Planned then (fun _ -> returnTarget |> SetReturnTarget |> dispatch) else ignore
            li [ Class (sprintf "list-group-item %s" active)
                 Key key
                 OnClick onClickFnct ]
              [ div [ Class "d-flex w-100 justify-content-between" ]
                  [ h6 [ Class "mb-1" ]
                      [ str header ] ]
                small [ ]
                  [ str content
                    Html.span [
                      prop.className "task-tooltip ml-auto mt-auto mb-auto mr-2"
                      prop.children [
                          Html.i [
                              prop.custom ("data-tip", toolTipText)
                              prop.className "fas fa-info-circle date-icon ml-1"
                          ]
                          ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
                      ]
                     ]
                    br [ ]
                    str (sprintf "%s %s" address.Street address.HouseNumber)
                    br [ ]
                    str (sprintf "%s %s" address.ZipCode address.City) ] ]
        let storages =
            match model.Configuration with
            | None -> []
            | Some config ->
              let mainLoc = listElement (config.MainLocation.Id.ToString()) "Hauptlager" config.MainLocation.Name "" config.MainLocation.Address (ReturnTarget.Storage config.MainLocation.Id) (isLocationSelected config.MainLocation.Id)
              let locations =
                 config.Locations
                |> List.map (fun loc -> listElement (loc.Id.ToString()) "Lager" loc.Name "" loc.Address (ReturnTarget.Storage loc.Id) (isLocationSelected loc.Id))
              mainLoc :: locations

        subHeaderForm "Zielort"
        ul [ Class "list-group mt-2 list-group-followup-delivery" ]
          [ storages |> ofList ]
          ]

let private pickTaskBody (task : ClientAssignedTask) (model : Model) isDisabled dispatch =
    let mechanicElement (mechanic : Employee) =
        if isDisabled then
          str (sprintf "%s %s" mechanic.Firstname mechanic.Lastname)
        else Buttons.iconButtonWithLabel "fas fa-times" (sprintf "%s %s" mechanic.Firstname mechanic.Lastname) (fun _ -> dispatch (RemoveMechanicFromTask mechanic.Id))
    let plannedWorkTime = task.ExecutionInformation.PlannedWorkTime
    let durationHours = plannedWorkTime / 60
    let durationMinutes = plannedWorkTime - durationHours * 60
    div [ ]
      [ Alerts.getAlert model.TaskModalMessage
        subHeaderForm "Informationen"
        Form.eightHourSlider (fun ev -> ev.Value |> int |> SetTaskWorkTime |> dispatch) plannedWorkTime (sprintf "Dauer (%i:%02i h)" durationHours durationMinutes) isDisabled
        Form.textArea (fun ev -> ev |> SetTaskComment |> dispatch) task.ExecutionInformation.Comment "Kommentar" isDisabled "task-modal-comment"
         ]

let view (model : Model) (task : ClientAssignedTask) isDisabled positiveAnswer negativeAnswer dispatch =
    let assignedTaskUserId = task.UserId
    let mechanicIds =
        assignedMechanicTasks model.AssignedTasks
        |> List.filter (fun (_, referencedTaskId) -> referencedTaskId = task.Id)
        |> List.map (fun (t, _) -> t.UserId)
    let selectedMechanics =
        model.Employees
        |> List.filter (fun m -> mechanicIds |> List.contains m.Id)
    div [ Class "modal-content" ]
      [ div [ Class "modal-header" ]
          [ h5 [ Class "modal-title" ]
                [ str "Aufgaben-Details" ]
            button [ HTMLAttr.Type "button"
                     Class "close"
                     Data ("dismiss", "modal")
                     OnClick negativeAnswer]
              [ i [ Class "fas fa-times" ] [ ] ] ]
        div [ Class "modal-body" ]
          [ match task.TaskType with
            | AssignedTaskType.FreeTask -> assignedFreeOrderTaskBody task model isDisabled dispatch
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.ServiceTask _
            | AssignedTaskType.SupportTask _ -> ()
            | AssignedTaskType.SelfserviceReturnTask _ -> assignedSelfServiceReturnTaskBody task model dispatch
            | AssignedTaskType.DeliveryTask _ -> assignedRentOrderTaskBody model.Employees task model assignedTaskUserId selectedMechanics isDisabled dispatch
            | AssignedTaskType.ReturnTask _ -> assignedRentOrderReturnTaskBody model.Employees task model assignedTaskUserId selectedMechanics isDisabled dispatch
            | AssignedTaskType.PickTask _ -> pickTaskBody task model isDisabled dispatch ]
        div [ Class "modal-footer"]
          [ match task.TaskType with
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.SelfserviceReturnTask -> finishTaskButton task dispatch
            | AssignedTaskType.PickTask -> saveButton isDisabled dispatch
            | AssignedTaskType.SupportTask _ -> ()
            | AssignedTaskType.FreeTask _
            | AssignedTaskType.ServiceTask _
            | AssignedTaskType.DeliveryTask _
            | AssignedTaskType.ReturnTask _ ->
              div [ ] [
                finishTaskButton task dispatch
                saveButton isDisabled dispatch
              ]
            button [ HTMLAttr.Type "button"
                     Class "btn btn-secondary"
                     Data ("dismiss", "modal")
                     OnClick negativeAnswer]
              [ str "Schliessen" ]]]

let hiddenModal _ =
    div [ Class "modal fade"
          Id "disposition-task-modal"
          TabIndex -1
          Data("backdrop", "static")
          Data("keyboard", "false")
          Role "dialog"]
        [ div [ Class "modal-dialog"
                Role "document" ]
               [ Fable.React.Helpers.nothing ]]

let viewModalContent modalContent =
    div [ Class "modal fade"
          Id "disposition-task-modal"
          TabIndex -1
          Data("backdrop", "static")
          Data("keyboard", "false")
          Role "dialog"]
        [ div [ Class "modal-dialog"
                Role "document" ]
               [ modalContent ] ]
