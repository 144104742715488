module SharedComponents.FileUploadDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Fable.Core.JsInterop
open Browser.Blob
open Browser.Types

type TargetType =
    | Entity of EntityId
    | Template of TemplateId

type Msg =
    | Empty
    | Upload of FormData
    | UploadCompleted of PostResponse<EmptyResponse>
    | FetchError of exn

let saveDocumentCmd (targetType : TargetType) formData =
    let body = formData
    let url =
        match targetType with
        | TargetType.Entity entityId ->
            let (EntityId entityId) = entityId
            sprintf "/api/entities/%s/documents" (entityId.ToString())
        | TargetType.Template templateId ->
            let (TemplateId templateId) = templateId
            sprintf "/api/templates/%s/documents" (templateId.ToString())
    Cmd.OfPromise.either Communication.postFileRequest<PostResponse<EmptyResponse>> (url, body) UploadCompleted FetchError

type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { TargetType : TargetType
      SuccessCallback : unit -> unit }

let init target successCallback : State * Cmd<Msg> =
    printfn "init file upload called"
    let initialModel =
        { TargetType = target
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | Empty -> state, Cmd.none
    | Upload formData ->
        state, saveDocumentCmd state.TargetType formData
    | UploadCompleted _ ->
        state.SuccessCallback()
        Swal.close (SweetAlert.Result.Value ())
        state, Cmd.none
    | FetchError e ->
        state, Cmd.none

let dialogBody = React.functionComponent(fun ((targetType : TargetType, dispatch)) ->
    let description, setDescription = React.useState("")
    let loading, setLoading = React.useState(false)

    if loading then
        Html.div [
            prop.children SharedComponents.Spinners.spinner
        ]
    else
        Html.div [
            prop.className "form-group"
            prop.children [

                Html.label [
                    prop.htmlFor "input-manufacturer"
                    prop.className "form-label"
                    prop.text "Beschreibung"
                ]
                Html.input [
                    prop.id "input-manufacturer"
                    prop.className [
                        "form-control"
                    ]
                    prop.custom ("data-cy", "tool-manufacturer-input")
                    prop.valueOrDefault description
                    prop.onChange setDescription

                ]
                Html.label [
                    prop.htmlFor "input-manufacturer"
                    prop.className "form-label"
                    prop.text "Es können nur Bilder und PDFs hochgeladen werden. Die Dateigröße darf maximal 5 MB groß sein."
                ]
                Html.div [
                    prop.children [
                        ReactDropzone.dropzone
                            [ ReactDropzone.CommonProps.OnChangeStatus (fun (meta, status, files) ->
                                printfn "onChangeStatus called")
                              ReactDropzone.CommonProps.OnSubmit (fun (files, allFiles) ->
                                let formData = FormData.Create()
                                formData.append(files?meta?name, files?file)
                                formData.append("Description", description)
                                setLoading true
                                Upload formData |> dispatch
                                // saveDocumentCmd toolId formData |> ignore
                                )
                              ReactDropzone.CommonProps.InputContent "Datei ziehen und ablegen oder wählen"
                              ReactDropzone.CommonProps.MaxFiles 1
                              ReactDropzone.CommonProps.Multiple false
                              ReactDropzone.CommonProps.Accept ".PNG,.JPEG,.JPG,.png,.jpg,.jpeg,.pdf,.PDF"
                              ReactDropzone.CommonProps.MaxSizeBytes 5242880
                              ReactDropzone.CommonProps.SubmitButtonContent "Hochladen"
                              ReactDropzone.CommonProps.SubmitButtonDisabled loading
                            //   ReactDropzone.CommonProps.Disabled loading
                            ]
                    ]
                ]

                Html.div [
                    prop.className "swal2-actions"
                    prop.children [
                        Buttons.secondaryButtonWithFnct
                            (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                            "Schließen" false ""
                    ]
                ]

            ]
        ])

let render (state : State) (dispatch : Msg -> unit) =
    Buttons.primaryButtonWithFnct
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody (state.TargetType, dispatch))
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value _ ->
                        state.SuccessCallback ()
                        Swal.Simple.success "Dokument wurde gespeichert"
                        printfn "Dismiss"
                    | SweetAlert.Result.Dismissal d ->
                        state.SuccessCallback ()
                        printfn "Dismiss %O" d
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
        "Datei hochladen" ""

open Feliz.ElmishComponents

let fileUploadDialog targetType successCallback =
    React.elmishComponent("FileUploadDialog", init targetType successCallback, update, render)
