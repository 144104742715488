module Shared.Configuration

open Shared
open Shared.Address

type CompanyBaseInformation = { Name : string
                                EmailAddress : string
                                Website : string
                                PhoneNumber : string
                                FaxNumber : string }

type Company = { HomeAddress : Address
                 Image : Image option
                 CompanyBaseInformation : CompanyBaseInformation }

type Workflow = { CloseRentOrderAfterReturnTaskFinished : bool}
type Configuration = { Company : Company
                       MainLocation : Location
                       Locations : Location list
                       Workflow : Workflow  }

type ConfigurationUpdate =
    | Workflow of Workflow
    | BaseInformation of CompanyBaseInformation
    | HomeAddress of Address
// type DTOs

type CompanyDto = { ConfigurationId : ConfigurationId
                    Company : Company }

module Helper =
    let emptyLocation =
        { Id = System.Guid.Empty |> LocationId
          Name = ""
          Address = Address.emptyAddress }

    let emptyConfiguration =
        { Company =
              { CompanyBaseInformation =
                  { Name = ""
                    EmailAddress = ""
                    Website = ""
                    PhoneNumber = ""
                    FaxNumber = "" }
                HomeAddress = Address.emptyAddress
                Image = None }
          MainLocation = emptyLocation
          Workflow =
            { CloseRentOrderAfterReturnTaskFinished = false }
          Locations = [ ] }