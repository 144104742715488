module Support.State

open Elmish
open Support.Types
open Client

let init _ : Model * Cmd<Msg> =
    let initialModel =
        { Configuration = None }
    initialModel, Commands.getConfigurationCmd ConfigurationFetched FetchError

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | ConfigurationFetched response ->
        { model with Configuration = Some response }, Cmd.none
    | FetchError e ->
        model, Cmd.none
