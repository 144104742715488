module MessageCenter.State

open Elmish
open MessageCenter.Types
open Shared
open Thoth.Json
open Shared.Message
open SharedComponents.Spinners

let getMessagesCmd =
    Cmd.OfPromise.either Communication.getRequest<MessagesResponse> "/api/messages" MessagesFetched FetchError

let updateMarkAsReadMessageCmd (messageId : MessageId) message =
    let (MessageId id) = messageId
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>> ((sprintf "/api/messages/%s/read" (id.ToString())), (Encode.toString 0 message)) MessageUpdated FetchError

let updateMarkAsUnreadMessageCmd (messageId : MessageId) message =
    let (MessageId id) = messageId
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>> ((sprintf "/api/messages/%s/unread" (id.ToString())), (Encode.toString 0 message)) MessageUpdated FetchError

let deleteMessageCmd (messageId : MessageId) =
    let (MessageId id) = messageId
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<EmptyResponse>> (sprintf "/api/messages/%s/delete" (id.ToString ())) MessageDeleted FetchError

let init _ : Model * Cmd<Msg> =
    let initialModel =
        { UnreadMessages = [ ]
          ReadMessages = [ ]
          RequestState = RequestState.Active }
    initialModel, getMessagesCmd

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | MarkAsRead messageId ->
        let message =
            List.append model.ReadMessages model.UnreadMessages
            |> List.find (fun m -> m.Id = messageId)
        let cmd =
            if message.MarkAsRead then updateMarkAsUnreadMessageCmd messageId message
            else  updateMarkAsReadMessageCmd messageId message
        { model with RequestState = RequestState.Active }, cmd
    | DeleteMessage messageId ->
        { model with RequestState = RequestState.Active }, deleteMessageCmd messageId
    /// Requests
    | MessagesFetched response ->
        let readMessages = response.Messages |> List.filter (fun m -> m.MarkAsRead)
        let unreadMessages = response.Messages |> List.filter (fun m -> not m.MarkAsRead)
        { model with UnreadMessages = unreadMessages
                     ReadMessages = readMessages
                     RequestState = RequestState.NotActive }, Cmd.none
    | MessageUpdated response ->
        { model with RequestState = RequestState.NotActive }, getMessagesCmd
    | MessageDeleted response ->
        { model with RequestState = RequestState.NotActive }, getMessagesCmd
    | FetchError e ->
        { model with RequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
