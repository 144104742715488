namespace Sww.Frontend

module I18next =
    open Fable
    open Fable.Core
    open Fable.Core.JS
    open Fable.Core.JsInterop
    open Feliz

    // fsharplint:disable
    type Ii18n =
        [<Emit("use($0)")>]
        abstract start : obj -> Ii18n
        [<Emit("init($0)")>]
        abstract init : obj -> unit

    type I18nextTranslation =
        [<Emit("$0.t($1)")>]
        abstract translate : string -> string

        [<Emit("$0.t($1, $2)")>]
        abstract translateInterpreted : string -> obj -> string
    // fsharplint:enable

    [<Import("default", from="i18next")>]
    let i18n : Ii18n = jsNative

    [<Import("useTranslation", from="react-i18next")>]
    let useTranslation(unit) : I18nextTranslation = jsNative

    [<Import("initReactI18next", from="react-i18next")>]
    let initReactI18next : obj = jsNative

    [<Import("default", from="i18next-http-backend")>]
    let backend : obj = jsNative

    let initialize applicationKey : Promise<unit> =
        let initialLanguages =
            match LocalStorageHelper.currentLanguage applicationKey with
            | "de" -> [| "de"; "en" |]
            | "en" -> [| "en"; "de" |]
            | _ -> [| "de"; "en" |]
        let i18nOptions =
            {| debug = true
               lng = initialLanguages
               fallbackLng = "de"
               defaultNs = "translation"
               interpolation= {| escapeValue = false |}
               react = {| useSuspense = false |}
               backend = {| requestOptions = {| cache = "no-store" |} |} |}

        i18n
            ?``use``(backend)
            ?``use``(initReactI18next)
            ?init(i18nOptions)

    type TranslateProps = { Key : string }

    let languageSelector = React.functionComponent(fun (props : {| applicationKey : string |}) ->
        let useTranslation = useTranslation()
        let i18next = useTranslation?i18n

        let translate = useTranslation?t
        let translation key : string = translate(key)
        let onClick lng =
            i18next?changeLanguage(lng)
            LocalStorageHelper.writeLanguage props.applicationKey lng

        let currentLanguage : string = (i18next?language)
        let selectedLanguage =
            match currentLanguage with
            | "en" -> "English"
            | "de" -> "Deutsch"
            | _ -> ""

        Html.div [
            Html.li [
                prop.className "dropdown language-selector"
                prop.children [
                    Html.a [
                        prop.className "dropdown-toggle"
                        prop.id "language-dropdown"
                        prop.custom ("data-toggle", "dropdown")
                        prop.ariaHasPopup true
                        prop.ariaExpanded false
                        prop.text selectedLanguage

                    ]
                    Html.div [
                        prop.className "dropdown-menu"
                        prop.ariaLabelledBy "language-dropdown"
                        prop.children [
                            Html.a [
                                prop.className "dropdown-item"
                                prop.onClick (fun _ -> onClick "de")
                                prop.text (translation "languages.de")
                            ]
                            Html.a [
                                prop.className "dropdown-item"
                                prop.onClick (fun _ -> onClick "en")
                                prop.text (translation "languages.en")
                            ]
                        ]
                    ]
                ]
            ]
        ]
    )

    let private t' = React.functionComponent(fun (props : TranslateProps) ->
        let i18n = useTranslation()
        Html.span (i18n.translate props.Key)
        )

    let t key : ReactElement = t' { Key = key }