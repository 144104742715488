module SharedComponents.FileUploads

open Fable.React
open Fable.React.Props

let fileUploadWithFnct fileFnct =
    if SharedComponents.BrowserCheck.isInternetExplorer then
        Fable.React.Helpers.nothing
        // div [ ]
        //     [ str "Es ist nicht möglich mit dem Internet Explorer Datein hochzuladen." ]
    else
        form [ ]
            [ div [ Class "form-group" ]
                [ input [ Type "file"
                          OnChange fileFnct ] ] ]
