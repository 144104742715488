module SharedComponents.Buttons

open Browser.Types
open Fable.React
open Fable.React.Props
open Feliz

let primaryButton target label className =
    a [ Class ("btn btn-primary " + className)
        Href target
        Role "button" ]
        [ str label ]

let formSubmitButton label className cytag =
    button [ Type "submit"
             Data ("cy", cytag)
             Class ("btn btn-primary " + className) ]
         [ str label ]

let primaryButtonWithFnct fnct label className =
    button [ Class ("btn btn-primary " + className)
             OnClick fnct
             Role "button" ]
        [ str label ]

let secondaryButtonWithFnct fnct label isDisabled className =
        button [ Class ("btn btn-secondary " + className)
                 OnClick fnct
                 Disabled isDisabled
                 Role "button" ]
            [ str label ]

let primaryButtonWithFnctAndIsDisabled fnct isDisabled label className =
        button [ Class ("btn btn-primary " + className)
                 OnClick fnct
                 Disabled isDisabled
                 Role "button" ]
             [ str label ]

let deleteButtonWithFnct fnct label className =
        button [ Class ("btn btn-danger " + className)
                 OnClick fnct
                 Data ("toggle", "modal")
                 Data ("target", "#delete-messagebox-form-modal")
                 Role "button" ]
            [ str label ]

let iconButton iconClassName fnct =
        button [ Class "btn"
                 OnClick fnct ]
            [ i [ Class iconClassName ]
                [ ] ]

let iconButtonToOpenModal iconClassName fnct target =
        button [ Class "btn"
                 Data ("toggle", "modal")
                 Data ("target", target)
                 OnClick fnct ]
            [ i [ Class iconClassName ]
                [ ] ]

let iconButtonWithLabel iconClassName label fnct =
        a [ Class "btn"
            OnClick fnct ]
            [ i [ Class iconClassName ]
                [ ]
              span [ Class "url-text" ] [ str label ] ]

let dropDownButton fnct isDisabled text =
    button [ Class "dropdown-item"
             Disabled isDisabled
             OnClick fnct ]
        [ str text ]

let dropDownBtn (customBtns : (ReactElement list) option) (printFnct : (Browser.Types.MouseEvent -> unit) option) (deleteFnct : (Browser.Types.MouseEvent -> unit) option) className =
    let printBtn =
        match printFnct with
        | Some fnct ->
            button [ Class "dropdown-item"
                     OnClick fnct ]
                [ i [ Class "fa fa-print mr-1" ]
                    [ ]
                  str "Drucken" ]
        | None -> Fable.React.Helpers.nothing
    let deleteBtn =
        match deleteFnct with
        | Some fnct ->
              button [ Class "dropdown-item"
                       Data ("toggle", "modal")
                       Disabled false
                       Data ("target", "#delete-messagebox-form-modal")
                       OnClick fnct ]
        | None ->
              button [ Class "dropdown-item"
                       Data ("toggle", "modal")
                       Disabled true
                       Data ("target", "#delete-messagebox-form-modal")
                       OnClick ignore ]
    div [ Class "dropdown show" ]
        [ a [ Class (sprintf "btn btn-primary dropdown-toggle %s" className)
              Href "#"
              Role "button"
              Id "dropdownMenuLink"
              DataToggle "dropdown"
              AriaHasPopup true
              AriaExpanded false ]
            [ str "Aktionen" ]
          div [ Class "dropdown-menu"
                HTMLAttr.Custom ("aria-labelledby", "dropdownMenuLink") ]
            [ (match customBtns with
              | Some btns -> btns |> ofList
              | None -> Fable.React.Helpers.nothing)
              printBtn
              deleteBtn
                [ i [ Class "fa fa-trash mr-1" ]
                    [ ]
                  str "Löschen" ]  ] ]

let editbtn fnct className cyPostFix =
    let cyIdentifier = (sprintf "edit-button-%s" cyPostFix)
    button [ OnClick fnct
             Class ("btn btn-outline-primary btn-sm btn-edit " + className)
             Data ("cy", cyIdentifier) ]
        [ i [ Class "fa fa-edit mr-1" ]
            [ ]
          str "Bearbeiten" ]

let saveBtn fnct className cyPostFix =
    let cyIdentifier = (sprintf "save-button-%s" cyPostFix)
    button [ OnClick fnct
             Class ("btn btn-outline-success btn-sm btn-edit " + className)
             Data ("cy", cyIdentifier) ]
        [ i [ Class "fa fa-save mr-1" ]
            [ ]
          str "Speichern" ]

let undoBtn fnct className cyPostFix =
    let cyIdentifier = (sprintf "undo-button-%s" cyPostFix)
    button [ OnClick fnct
             Class ("btn btn-outline-dark btn-sm btn-edit " + className)
             Data ("cy", cyIdentifier) ]
        [ i [ Class "fas fa-undo-alt mr-1" ]
            [ ]
          str "Verwerfen" ]

let textButtonExternalLink (href : string) (label : string) (className: string) =
    Html.a [
        prop.target "_blank"
        prop.className (sprintf "textbtn %s" className)
        prop.href href
        prop.children [ Html.text label ]
    ]
    
open Feliz

type Button () =
    static member iconButton(onClick, (text : string), iconName, ?className, ?cyIdentifier) =
        let className = defaultArg className ""
        let cyTag = defaultArg cyIdentifier ""
        let onClick (event : MouseEvent) =
            event.preventDefault ()
            onClick ()
        Html.button [
            prop.onClick onClick
            prop.className ("btn btn-outline-primary btn-sm btn-edit " + className)
            prop.custom ("data-cy", cyIdentifier)
            prop.children [
                Html.i [
                    prop.className (sprintf "fas fa-%s mr-1" iconName)
                ]
                Html.text text
            ]
        ]

    static member editButton(onClick, ?className, ?cyPostFix) =
        let className = defaultArg className "" |> (+) "btn-outline-primary "
        let cyIdentifier = defaultArg cyPostFix "" |> (+) "edit-button-"
        Button.iconButton(onClick, "Bearbeiten", "edit", className = className, cyIdentifier = cyIdentifier)

    static member undoButton(onClick, ?className, ?cyPostFix) =
        let className = defaultArg className "" |> (+) "btn-outline-dark "
        let cyIdentifier = defaultArg cyPostFix "" |> (+) "undo-button-"
        Button.iconButton(onClick, "Verwerfen", "undo-alt", className = className, cyIdentifier = cyIdentifier)

    static member saveButton(onClick, ?className, ?cyPostFix) =
        let className = defaultArg className ""|> (+) "btn-outline-success"
        let cyIdentifier = defaultArg cyPostFix "" |> (+) "save-button-"
        Button.iconButton(onClick, "Speichern", "save", className = className, cyIdentifier = cyIdentifier)
