module DispositionOverview.View

open Fable.React
open Fable.React.Props
open DispositionOverview.State
open DispositionOverview.Types
open Shared
open SharedComponents.Headers
open SharedComponents.ReactSelect
open System
open SharedComponents
open Shared.TaskTypes
open Shared.TaskTypes.Helper
open SharedComponents.Badges
open Feliz
open Client.SharedComponents
open SharedComponents.Spinners

let tooltip tooltipText =
    Html.span [
        prop.className "task-tooltip"
        prop.children [
            Html.i [
                prop.custom ("data-tip", tooltipText)
                prop.className "fas fa-info-circle date-icon ml-1"
            ]
            ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
        ]
    ]

let private roleProps (model : Model) dispatch =
    let getRoleName r =
        match r with
        | Administrator -> "Admin"
        | Driver -> "Fahrer"
        | Dispatcher -> "Disponent"
        | Mechanic -> "Monteur"
        | Technician -> "Techniker"
        | Picker -> "Kommissionierer"
    let options (roles : Role list) =
        roles
        |> List.map (fun r ->
            { label = getRoleName r.Name
              value = r })
        |> List.toArray
    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte keine passende Rolle gefunden werden." ]
    let rolesToDisplay =
      model.Roles
      |> List.filter(fun e -> not (model.SelectedRoles |> List.contains(e)))
    let valueElements =
      let elements =
        model.SelectedRoles
        |> List.map (fun e -> { label = getRoleName e.Name; value = e })
        |> List.toArray
      [| SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Value elements |]

    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Options (options rolesToDisplay)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.OnChange (SetRoles >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.IsDisabled false
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.Placeholder "Filter wählen"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Role>.ClassName "role-select mb-2" |]
        valueElements

let private tableTaskElementFooter (task : ClientAssignedTask) (model : Model) =
    let mechanicTaskReferencedTaskIds = mechanicTaskReferencedTaskIds model.AssignedTasks
    let mechanicIcon =
        if mechanicTaskReferencedTaskIds |> List.contains task.Id then
            span [ Class "small" ]
                [ i [ Class "fas fa-user-friends mr-auto" ]
                    [ ] ]
        else Fable.React.Helpers.nothing

    div [ Class "modal-footer task-element-footer" ]
      [ mechanicIcon ]

let tableTaskElement (task : TableElement) (model : Model) dispatch =
    let entityName (entityType : EntityType) =
        let entity =
            model.Entities
            |> List.tryFind (fun e ->
                match entityType with
                | EntityType.EntityId eId -> eId = e.Id
                | EntityType.CompoundEntityId comp -> comp.EntityId = e.Id)
        match entity with
        | Some entity -> [ str entity.Name; br [] ] |> ofList
        | None -> str ""

    match task with
    | TableElement.AssignedTask task ->
        let title =
            match task.TaskType with
            | AssignedTaskType.FreeTask -> "Eigene Aufgabe"
            | AssignedTaskType.DeliveryTask -> "Lieferung"
            | AssignedTaskType.ReturnTask -> "Abholung"
            | AssignedTaskType.ServiceTask -> "Service"
            | AssignedTaskType.PickTask -> "Kommissionierung"
            | AssignedTaskType.SupportTask _ -> "Montage"
            | AssignedTaskType.SelfserviceDeliveryTask _ -> "Abholung"
            | AssignedTaskType.SelfserviceReturnTask _ -> "Rückgabe"
        (div [ Class "task-element" ]
                [ div [ Class (sprintf "modal-header")]
                    [ h6 [ Class "modal-title" ] [ str title] ]
                  div [ Class "task-element-body"]
                    [ p [ Class "small" ]
                        [ str task.ShortInfo
                          tooltip task.AdditionalInfo ]
                      p [ Class "small" ]
                        [ str (match task.ExecutionAdress.CompanyName with | Some c -> c | None -> "")
                          br [ ]
                          str (sprintf "%s %s" task.ExecutionAdress.Street task.ExecutionAdress.HouseNumber)
                          br [ ]
                          str (sprintf "%s %s" task.ExecutionAdress.ZipCode task.ExecutionAdress.City) ] ]
                  tableTaskElementFooter task model ] ), task.TaskPosition

let dayOfWeekString (dayOfWeek : DayOfWeek) =
    match dayOfWeek with
    | DayOfWeek.Monday -> "Montag"
    | DayOfWeek.Tuesday -> "Dienstag"
    | DayOfWeek.Wednesday -> "Mittwoch"
    | DayOfWeek.Thursday -> "Donnerstag"
    | DayOfWeek.Friday -> "Freitag"
    | DayOfWeek.Saturday -> "Samstag"
    | DayOfWeek.Sunday -> "Sonntag"
    | _ -> failwith "case not covered."

let tableHeader (date : DateTime) =
    let className =
        let currentDate = System.DateTime.UtcNow.Date
        if date = currentDate then
          "today-header"
        elif date < currentDate then
          "past-header"
        else ""

    th [ Class className ]
        [ str (sprintf "%s %s." (dayOfWeekString date.DayOfWeek) (date.ToString("ddd"))) ]

let tableElements model dispatch userId (date : DateTime)=
    let elements =
        elementsForUserAt model.AssignedTasks userId date
        |> List.map (fun e -> tableTaskElement e model dispatch)
        |> List.sortBy (fun (t, i) -> i)
    let id = System.Guid.NewGuid().ToString()
    td [ Id id
         Class "greyedout" ]
        [ (elements
          |> List.fold (fun result (e, index) ->
              let dropContainerIndex =
                  if result |> List.isEmpty then
                      ((index + 0.) / 2.)
                  else
                      let prevElement, prevElementIndex = result |> List.last
                      ((index + prevElementIndex) / 2.)
              List.append result [ (e, index)] ) [ ]
          |> List.map (fun (e, _) -> e)
          |> ofList)]

let roleBadge (model : Model) (employee : Employee) =
    let badge (employees : Employee list) badgeText roleName =
        match employees |> List.tryFind (fun e -> e.Id = employee.Id) with
        | Some employee ->
            if employee.RoleNames |> List.exists (fun r -> r = roleName) then
                badgePill badgeText BadgeClassType.Info
            else Fable.React.Helpers.nothing
        | None -> Fable.React.Helpers.nothing
    div [ Class "d-flex flex-column role-badges" ]
      [ badge model.Employees "Fahrer" RoleNames.Driver
        badge model.Employees "Techniker" RoleNames.Technician
        badge model.Employees "Mechaniker" RoleNames.Mechanic
        badge model.Employees "Kommissionierer" RoleNames.Picker ]

let employeeRow model dispatch (employee : Employee) =
    tr [ ]
        [ td [ ]
            [ str (sprintf "%s %s" employee.Firstname employee.Lastname)
              roleBadge model employee ]
          model.DateRange
          |> List.map (tableElements model dispatch employee.Id)
          |> ofList ]

let selfserviceTaskRow model dispatch =
    tr [ ]
        [ td [ ]
            [ str "Selbstabholung / Selbstrückgabe"
               ]
          model.DateRange
          |> List.map (tableElements model dispatch 0)
          |> ofList]


let dispositionTable model dispatch =
    let employeeRowElements = model.VisibleEmployees |> List.map (employeeRow model dispatch)
    let rowElements = (selfserviceTaskRow model dispatch) :: employeeRowElements
    div [ Class "disposition-table" ]
      [ div [ Class "d-flex month-selector" ]
          [ span [ Class "arrow fas fa-angle-left my-auto ml-auto"
                   OnClick (fun _ -> ShowPrevWeek |> dispatch) ]
                  [  ]
            div [ Class "my-auto d-flex flex-column month-name" ]
                [ span [ Class " my-auto" ]
                    [ str "Woche" ]
                  str (sprintf "%s - %s" (model.DateRange.Head.ToString("dd.MM.yyyy"))
                                         (model.DateRange |> List.rev |> List.head |> (fun d -> d.ToString("dd.MM.yyyy")))) ]

            span [ Class "arrow fas fa-angle-right my-auto mr-auto"
                   OnClick (fun _ -> ShowNextWeek |> dispatch) ]
                    [ ] ]
        table [ Class "table table-bordered disposition-table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                        [ str "" ]
                    model.DateRange
                    |> List.map tableHeader
                    |> ofList ] ]
            tbody [ ]
              [ rowElements |> ofList ] ] ]

let private userNotification model dispatch =
    match model.Message with
    | Some message -> Alerts.errorAlert message
    | None -> Fable.React.Helpers.nothing

let private planContent model dispatch =
    div [ Class "flex-grow-1 pl-3" ]
      [ mainHeaderWithClassName "Dispositionsübersicht" "header"
        ReactSelect.multiSelectWithoutLabel (roleProps model  dispatch)
        userNotification model dispatch
        dispositionTable model dispatch ]

let private dispositionContent (model : Model) dispatch =
    div [ Id "disposition-overview-content"
          Class "d-flex flex-grow-1" ]
        [ planContent model dispatch
          div [ Class "d-flex flex-row" ]
            [ div [ Class "sidebar-container d-flex flex-column pr-2"]
                [  ]
              div [ Class "flex-grow-1 pl-2"]
                []] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "disposition-overview-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          dispositionContent model dispatch ]
