module MasterData.Entities.RemoveNotAvailableRangeDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Shared.Entity
open SharedComponents.Toast
open SharedComponents.Headers
open Feliz.ElmishComponents

type Msg =
    | EntityUpdated of PostResponse<UpdateEntityResult>
    | UpdateEntity of EntityNotAvailableRangeId
    | FetchError of exn

let updateEntityCmd entityId (updateElement : EntityGroupUpdate) =
    let (EntityId entityId) = entityId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<UpdateEntityResult>>
                             ((sprintf "/api/entities/%s" (entityId.ToString())), body)
                             EntityUpdated FetchError
type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { NotAvailableRangeId : EntityNotAvailableRangeId
      EntityId : EntityId
      SuccessCallback : unit -> unit }

let init successCallback notAvailableRangeId entityId : State * Cmd<Msg> =
    let initialModel =
        { NotAvailableRangeId = notAvailableRangeId
          EntityId = entityId
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | UpdateEntity notAvailableRangeId ->
        let cmd =
            notAvailableRangeId
            |> EntityGroupUpdate.RemoveNotAvailableRange
            |> updateEntityCmd state.EntityId
        state, cmd
    | EntityUpdated response ->
        match response.Result with
        | UpdateEntityResult.Updated entityId ->
            state.SuccessCallback()
            Swal.close (SweetAlert.Result.Value ())
            state, toast (ToastType.Success "außer Betrieb Zeitrum wurde gelöscht")
        | UpdateEntityResult.AddNotAvailableRangeFailed _
        | UpdateEntityResult.EntityStateUpdated _
        | UpdateEntityResult.StateUpdatedFailedActiveEntityNameExists _
        | UpdateEntityResult.StateUpdatedFailed _
        | UpdateEntityResult.EntityNameAlreadyExists _ -> state, toast (ToastType.Success "Es ist etwas schief gelaufen.")
    | FetchError e ->
        state, Cmd.none

let body (state : State) dispatch =

    Html.div [
        prop.className "delete-not-available-range-dialog"
        prop.children [

            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.text "Willst du den außer Betrieb Zeitraum wirklich löschen?"
            ]

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnct
                        (fun _ ->
                            state.NotAvailableRangeId |> UpdateEntity |> dispatch
                        )
                        "Löschen" "mr-3"
                    Buttons.secondaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" false ""
                ]
            ]
        ]
    ]

let dialogBody successCallback entityId notAvailableRangeId =
    React.elmishComponent("DeleteNotAvailableRangeDialog", init successCallback notAvailableRangeId entityId, update, body)

let render entityId notAvailableRangeId successCallback =
    Buttons.iconButton "fas fa-times"
        // (fun _ -> notAvailableRange.Id |> Msg.RemoveNotAvailableRange |> dispatch)
    // Buttons.dropDownButton
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody successCallback entityId notAvailableRangeId)
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value _ ->
                        successCallback ()
                        Swal.Simple.success "außer Betrieb Zeitraum wurde gelöscht"
                    | SweetAlert.Result.Dismissal d ->
                        ()
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
        // false
        // "Verfügbarkeit bearbeiten"

let removeNotAvailableRangeDialog' =
    React.functionComponent(fun (props : {| entityId : EntityId
                                            notAvailableRangeId : EntityNotAvailableRangeId
                                            successCallback : unit -> unit |}) -> render props.entityId props.notAvailableRangeId props.successCallback)

let removeNotAvailableRangeDialog (entityId : EntityId) (notAvailableRangeId : EntityNotAvailableRangeId) successCallback =
    removeNotAvailableRangeDialog' {| entityId = entityId
                                      notAvailableRangeId = notAvailableRangeId
                                      successCallback = successCallback |}

