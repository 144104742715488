module MyProfil.View

open Shared
open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open MyProfil.Types
open SharedComponents.Form
open SharedComponents.Types.Helpers
open SharedComponents.Spinners

let private userDate (userForm : UserForm) =
    let email = userForm.User.User.Email
    let firstName = userForm.User.User.Firstname
    let lastName = userForm.User.User.Lastname

    div [ Class "p-2 disabled-form" ]
      [ subHeaderForm "Allgemein"
        Input.inlineInput(ignore, email, inputLabel = "Email", isDisabled = true)
        Input.inlineInput(ignore, firstName, inputLabel = "Vorname", isDisabled = true)
        Input.inlineInput(ignore, lastName, inputLabel = "Nachname", isDisabled = true) ]

let private password (userForm : UserForm) dispatch =
    let editPassword = (match userForm.EditField with | EditField.EditPassword _ -> false | _ -> true)
    let editFormClass = if editPassword then "disabled-form" else ""
    let userSecurityInfo = userForm.User
    div [ Class ("p-2 " + editFormClass) ]
      [ subHeaderByFormState (match userForm.EditField with | EditField.EditPassword _ -> true | _ -> false)
            "Passwort"
            (fun _ -> EditPassword |> dispatch) ""
            (fun _ -> SaveMyProfile |> dispatch) ""
            (fun _ -> EndEdit |> dispatch) ""
            SharedComponents.Types.FormState.View
        passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.Password (toLabelType "Passwort" editPassword true SharedComponents.Types.FormState.View)  ""
        passwordInlineInputFormValidation (fun ev -> ev.Value |> string |> SetSecondPassword |> dispatch) (userForm.FormValidation, "userpass") userSecurityInfo.SecondPassword (toLabelType "Passwort wiederholen" editPassword true SharedComponents.Types.FormState.View) "" ]

let form userForm model dispatch =
    div [ Id "myprofil-content" ]
      [ div [ Class "flex-grow-1 d-flex flex-column" ]
          [ mainHeader "Mein Profil"
            div [ Class "d-flex flex-sm-row flex-column"]
              [ div [ Class "flex-grow-1"]
                  [ userDate userForm ]
                div [ Class "flex-grow-1"]
                  [ password userForm dispatch ] ] ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "myprofil-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          match model.FormState with
          | FormState.Edit userForm -> form userForm model dispatch
          | FormState.Loading -> overlaySpinner RequestState.Active ]