module Control.State

open Elmish
open Control.Types
open Shared
open Shared.Entity

let getAllDataCmd =
    Cmd.OfPromise.either Communication.getRequest<(RentOrderId * Entity list) list> "/api/rentorders/control" DataFetched FetchError


let updateEntityCmd (dto : UpdatEntityLocation) =
    let body = dto |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<string>>
                             (("/api/rentorders/control/update-entity-location"), body)
                             EntityUpdated FetchError
                             
let init user : Model * Cmd<Msg> =
    { Loading = false
      Data = [] }, getAllDataCmd

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | UpdateEntity (rentOrderId, entity) ->
        let dto =
            { Entity = entity
              RentOrderId = rentOrderId
              All = model.Data }
        model, updateEntityCmd dto
    | DataFetched response -> { model with Data = response }, Cmd.none
    | EntityUpdated response ->
        match response.Result with
        | "done" ->  model, getAllDataCmd
        | _ -> model, Cmd.none
    | FetchError _ -> model, Cmd.none
