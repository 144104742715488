module Client.Commands

open Shared
open Elmish
open Shared.Entity
open Shared.Configuration
open Shared.Material
open Shared.Carrier

let getImage (imageId : DocumentId) success error =
    let (DocumentId imageId) = imageId
    Cmd.OfPromise.either Communication.getRequest<DocumentWithContent> (sprintf "/api/image/%s" (imageId.ToString ())) success error

let downloadFileCmd documentId filename success error =
    let (DocumentId documentId) = documentId
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/document/%s" (documentId.ToString())), filename) success error

/// Entities
let getEntityDtoForTemplateCmd (templateId : TemplateId) (entityId : EntityId) success error =
    let (EntityId entityId) = entityId
    let entityId = entityId.ToString ()
    let (TemplateId templateId) = templateId
    let templateId = templateId.ToString ()
    Cmd.OfPromise.either Communication.getRequest<EntityDetailDto> (sprintf "/api/templates/%s/entities/%s" templateId entityId) success error

/// Templates

let getTemplatesCmd success error =
    Cmd.OfPromise.either Communication.getRequest<TemplatesResponse> "/api/templates" success error

let getTemplateCmd (templateId : TemplateId) success error =
    let (TemplateId templateId) = templateId
    let templateId = templateId.ToString ()
    Cmd.OfPromise.either Communication.getRequest<Template> (sprintf "/api/templates/%s" templateId) success error

/// Configuration

let getConfigurationCmd success error =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" success error

/// Employee

let getEmployeesCmd success error =
    Cmd.OfPromise.either Communication.getRequest<EmployeesResponse> "/api/users/employees" success error

let getActiveEmployeesCmd success error =
    Cmd.OfPromise.either Communication.getRequest<EmployeesResponse> "/api/users/employees/active" success error

// Material

let getMaterialsCmd success error =
    Cmd.OfPromise.either Communication.getRequest<MaterialsResponse> "/api/materials" success error

let getMaterialCmd materialId success error =
    let (MaterialId materialId) = materialId
    let guid = materialId.ToString()
    Cmd.OfPromise.either Communication.getRequest<Material> (sprintf "/api/materials/%s" guid) success error

// Carrier

let getCarriersCmd success error =
    Cmd.OfPromise.either Communication.getRequest<CarriersResponse> "/api/carriers" success error

let getCarrierCmd carrierId success error =
    let (CarrierId carrierId) = carrierId
    let guid = carrierId.ToString()
    Cmd.OfPromise.either Communication.getRequest<Carrier> (sprintf "/api/carriers/%s" guid) success error
