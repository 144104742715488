module SharedComponents.SearchBar

open Fable.React
open Fable.React.Props
open Elmish.React.Helpers
open Thoth.Json

type SearchBarFilter<'a> =
    { Elements : 'a list
      FilteredElements : 'a list
      SearchList : ('a * string) list }

/// Returns an empty search bar model
let inline init (elements : 'a list) =
    { Elements = elements
      FilteredElements = elements
      SearchList = elements |> List.map(fun e -> (e, Encode.Auto.toString(0, e))) }

let inline filter<'a, 'b> (filterText : string) (filterModel : SearchBarFilter<'a>) (msg : SearchBarFilter<'a> -> 'b) = async {
    let filters = filterText.Split(' ') |> Array.toList |> List.map (fun f -> f.ToLower())
    let filteredElements =
        filterModel.SearchList
        |> List.filter(fun (e, eString) ->
            filters |> List.map (fun f -> eString.ToLower().Contains f) |> List.reduce (&&))
        |> List.map(fun (e, _) -> e)
    let filterModel = { filterModel with FilteredElements = filteredElements }
    return filterModel |> msg
}

let searchBar onChangeFnct value className =
    div [ Class ("input-group " + className) ]
        [ div [ Class "input-group-prepend" ]
            [ span [ Class "input-group-text" ]
                [ i [ Class "fas fa-search" ] [ ] ] ]
          input [ Class "form-control"
                  valueOrDefault value
                  OnChange onChangeFnct
                  Placeholder "Suche" ] ]
