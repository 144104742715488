module SharedComponents.ReactDatepicker

open Fable.Core
open Fable.React
open Fable.React.Props
open Fable.Core.JsInterop
open System

let de : obj = importDefault "date-fns/locale/de"

type CommonProps =
    | Selected of DateTime option
    | OnChange of (DateTime option -> unit)
    | DateFormat of string
    | ClassName of string
    | StartDate of DateTime
    | EndDate of DateTime
    | MinDate of DateTime
    | MaxDate of DateTime
    | ExcludeDates of DateTime array
    | ShowMonthYearPicker of bool
    | IsClearable of bool
    | Disabled of bool
    | Locale of obj

let inline datepicker (props : CommonProps array) =
    ofImport "default" "react-datepicker" (keyValueList CaseRules.LowerFirst props) []

let private onChangeWrapper (onChange : DateTime -> unit) (onClear : (unit -> unit) option) (date : DateTime option) =
    match date with
    | Some date ->
        let date = DateTime(date.Year, date.Month, date.Day, 0, 0, 0, DateTimeKind.Utc)
        onChange date
    | None ->
        match onClear with
        | Some onClear ->
            onClear()
        | None -> failwith "Missing onClear function."

let datepickerWithLabel defaultDate onChange disabled labelname =
    div [ Class "datepicker-with-label form-group d-flex" ]
        [ label [ Class "label label-right mt-auto mb-auto" ]
            [ str labelname ]
          datepicker [| CommonProps.Selected (Some defaultDate)
                        CommonProps.OnChange (onChangeWrapper onChange None)
                        CommonProps.ClassName "form-control"
                        CommonProps.DateFormat "dd.MM.yyyy"
                        CommonProps.Locale de
                        CommonProps.Disabled disabled |] ]

let datepickerWithoutLabel defaultDate onChange disabled =
    div [ Class "" ]
        [ datepicker [| CommonProps.Selected (Some defaultDate)
                        CommonProps.OnChange (onChangeWrapper onChange None)
                        CommonProps.ClassName "form-control"
                        CommonProps.DateFormat "dd.MM.yyyy"
                        CommonProps.Locale de
                        CommonProps.Disabled disabled |] ]

let customDatepickerWithoutLabel defaultDate onChange disabled (config : CommonProps list) =
    let configuration =
        [| CommonProps.Selected (Some defaultDate)
           CommonProps.OnChange (onChangeWrapper onChange None)
           CommonProps.ClassName "form-control"
           CommonProps.DateFormat "dd.MM.yyyy"
           CommonProps.Locale de
           CommonProps.Disabled disabled |]
        |> Array.append (config |> List.toArray)
    div [ Class "" ]
        [ datepicker configuration ]

let datepickerWithoutLabelWithEndAndStartDate defaultDate onChange startDate endDate minDate excludedDates disabled =
    div [ Class "" ]
        [ datepicker [| CommonProps.Selected (Some defaultDate)
                        CommonProps.OnChange (onChangeWrapper onChange None)
                        CommonProps.ClassName "form-control"
                        CommonProps.DateFormat "dd.MM.yyyy"
                        CommonProps.StartDate startDate
                        CommonProps.EndDate endDate
                        CommonProps.MinDate minDate
                        CommonProps.ExcludeDates excludedDates
                        CommonProps.Locale de
                        CommonProps.Disabled disabled |] ]

type Datepicker() =
    static member datepicker(defaultDate, (onChange : DateTime -> unit),
                             ?labelName,
                             (?startDate : DateTime), ?endDate,
                             ?minDate, ?maxDate,
                             ?excludedDates, ?isDisabled,
                             (?onClear : unit -> unit),
                             (?monthPicker : bool),
                             (?dateFormat : string)) =
        let isDisabled = defaultArg isDisabled false
        let isClearable = defaultArg (onClear |> Option.map (fun _ -> true)) false
        let datepickerConfig =
            [|
                CommonProps.Selected defaultDate
                CommonProps.OnChange (onChangeWrapper onChange onClear)
                CommonProps.ClassName "form-control"
                CommonProps.DateFormat
                    (match dateFormat with
                     | Some dateFormat -> dateFormat
                     | None -> "dd.MM.yyyy")
                match startDate with
                | Some startDate -> CommonProps.StartDate startDate
                | None -> ()
                match endDate with
                | Some endDate -> CommonProps.EndDate endDate
                | None -> ()
                match minDate with
                | Some minDate -> CommonProps.MinDate minDate
                | None -> ()
                match maxDate with
                | Some maxDate -> CommonProps.MaxDate maxDate
                | None -> ()
                match excludedDates with
                | Some excludedDates -> CommonProps.ExcludeDates excludedDates
                | None -> ()
                match monthPicker with
                | Some monthPicker -> CommonProps.ShowMonthYearPicker monthPicker
                | None -> ()
                CommonProps.Locale de
                CommonProps.Disabled isDisabled
                CommonProps.IsClearable isClearable
            |]

        let datepicker = datepicker datepickerConfig

        match labelName with
        | Some labelName ->
            div [ Class "datepicker-with-label form-group d-flex" ]
                [ label [ Class "label label-right mt-auto mb-auto" ]
                    [ str labelName ]
                  datepicker ]
        | None ->
            div [ Class "" ] [
                datepicker
            ]
