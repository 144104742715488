module Shared.RentOrder

open Shared
open Address
open Entity
open TaskTypes
open System

type ProjectInformation =
    { ProjectNumber : string
      BuildingProjectName : string
      Comment : string }

type Reminder =
    { Id : ReminderId
      Description : string
      TargetDate : DateTime }


// type CompletedRentOrderTask =
//     { TaskId : TaskId
//       Image : Image list
//       Signature : Image option
//       Signer : string option
//       Comment : string }

// type RentOrderWithCompletedTasks =
//     { Id : RentOrderId
//       State : RentOrderState
//       ExecutionLocation : Address
//       ProjectInformation : ProjectInformation
//       Positions : RentOrderPosition list
//       CompletedTasks : CompletedRentOrderTask list }

type RentOrderV1 =
    { Id : RentOrderId
      State : RentOrderState
      ExecutionLocation : Address
      ProjectInformation : ProjectInformation
      Positions : RentOrderPositionV1 list }

type RentOrderV2 =
    { Id : RentOrderId
      State : RentOrderState
      ExecutionLocation : Address
      ProjectInformation : ProjectInformation
      Positions : RentOrderPositionV2 list }

type RentOrderV3 =
    { Id : RentOrderId
      State : RentOrderState
      ExecutionLocation : Address
      ProjectInformation : ProjectInformation
      Positions : RentOrderPositionV3 list }

type RentOrder =
    { Id : RentOrderId
      OrderNumber : string
      State : RentOrderState
      ExecutionLocation : Address
      ProjectInformation : ProjectInformation
      Tasks : RentOrderTask list
      Positions : RentOrderPosition list
      Reminders : Reminder list }

type RentOrderOverview =
    { Id : RentOrderId
      OrderNumber : string
      State : RentOrderState
      ExecutionLocation : Address
      ProjectInformation : ProjectInformation
      EntitiesInfo : string }


type RentOrdersOverviewResponse =
    { RentOrders : RentOrderOverview list }

type PickEntitiesData =
    { StartRentDate : System.DateTime
      EndRentDate : System.DateTime }

type RentOrderDto =
    { State : RentOrderState
      ExecutionLocation : Address
      WithoutDelivery : bool
      PickEntities : PickEntitiesData option
      ProjectInformation : ProjectInformation
      Positions : RentOrderPosition list }

type RentOrdersResponse =
    { RentOrders : RentOrder list }

type RentOrderTermOfLeaseDto =
    { RentOrderId : RentOrderId
      StartDate : System.DateTime
      EndDate : System.DateTime }

type RentOrderReminder =
    { RentOrderId : RentOrderId
      Reminders : Reminder list }

type Validated<'T> =
    { Raw : string
      Parsed : 'T option }

type MaterialPositionValue =
    { PosNo : PositionNumber
      Value : Validated<float> }

type RentOrderUpdateDto =
    | UpdateTermOfLease of RentOrderTermOfLeaseDto
    | UpdateExecutionLocation of Address
    | UpdatePositions of (bool * RentOrderPosition list)
    | UpdateProjectInformation of ProjectInformation
    | AddReminders of Reminder list
    | UpdateReminder of Reminder
    | DeleteReminder of ReminderId

type SaveRentOrderResult =
    | Saved of RentOrderId
    | InvalidPositions of RentOrderPosition list
    | InvalidPositionsSelfserviceConvert of RentOrderPosition list

type DeleteRentOrderResult =
    | Deleted of RentOrderId
    | WrongStateError of RentOrderId
    | AssignedTasksExistsError of RentOrderId

type RentOrderCloseResult =
    | RentOrderClosed of RentOrderId
    | RentOrderHasOpenPositions
    | RentOrderAlreadyClosed of RentOrderId
    | RentOrderIsInPlannedState of RentOrderId

type RentOrderReleaseResult =
    | Released of RentOrderId
    | PositionsInWrongState of (RentOrderPositionId * string) list

type RentOrderRevertReleaseResult =
    | RevertRelease of RentOrderId
    | PositionsInWrongState of (RentOrderPositionId * string) list

type RentOrderDeleteResult =
    | Deleted of RentOrderId
    | PositionsInWrongState of (RentOrderPositionId * string) list

type ConvertToSelfServiceResult =
    | Converted of RentOrderPositionId
    | Failed of RentOrderPositionId

type RentOrderPositionDto =
    { Templates : Template list
      Materials : Shared.Material.Material list
      ActiveEntities : EntityDto list
      DeactivatedEntities : EntityDto list }
    
module Helper =
    let completedTaskReport rentOrder taskId =
        match rentOrder.Tasks |> List.tryFind (fun t -> taskId = (t |> Helper.rentOrderTaskId)) with
        | Some t ->
            match t with
            | RentOrderTask.AssignedDeliveryTask assignedTask -> assignedTask.RentOrderTaskReport
            | RentOrderTask.AssignedReturnTask assignedTask -> assignedTask.RentOrderTaskReport
            | RentOrderTask.AssignedSelfserviceDeliveryTask assignedTask -> assignedTask.RentOrderTaskReport
            | RentOrderTask.AssignedSelfserviceReturnTask assignedTask -> assignedTask.RentOrderTaskReport
            | _ -> None
        | None -> None

    module Migration =
        let rentOrderPositionV1ToRentOrderPositionV2 (v1 : RentOrderPositionV1) : RentOrderPositionV2 =
            { RentOrderPositionV2.Id = v1.Id
              PosNo = v1.PosNo
              SubPosNo = v1.SubPosNo
              EntityId = v1.EntityId
              PlannedDeliveryDate = v1.DeliveryDate
              DeliveryDate = None
              PlannedReturnDate = v1.ReturnDate
              ReturnDate = None
              ReleaseDate = v1.ReleaseDate
              State = v1.State }

        let rentOrderPositionV2ToRentOrderPositionV3 (v2 : RentOrderPositionV2) : RentOrderPositionV3 =
            { RentOrderPositionV3.Id = v2.Id
              PosNo = v2.PosNo
              SubPosNo = v2.SubPosNo
              EntityId = v2.EntityId
              PlannedDeliveryDate = v2.PlannedDeliveryDate
              DeliveryDate = v2.DeliveryDate
              PlannedReturnDate = v2.PlannedReturnDate
              ReturnDate = v2.ReturnDate
              ReleaseDate = v2.ReleaseDate
              State = v2.State
              Comment = "" }

        let rentOrderV1ToRentOrderV2 (v1 : RentOrderV1) : RentOrderV2 =
            { RentOrderV2.Id = v1.Id
              State = v1.State
              ExecutionLocation = v1.ExecutionLocation
              ProjectInformation = v1.ProjectInformation
              Positions = v1.Positions |> List.map rentOrderPositionV1ToRentOrderPositionV2 }

        let rentOrderV2ToRentOrderV3 (v2 : RentOrderV2) : RentOrderV3 =
            { RentOrderV3.Id = v2.Id
              State = v2.State
              ExecutionLocation = v2.ExecutionLocation
              ProjectInformation = v2.ProjectInformation
              Positions = v2.Positions |> List.map rentOrderPositionV2ToRentOrderPositionV3 }

    module Position =
        let id =
            function
            | RentOrderEntityPosition pos -> pos.Id
            | RentOrderMaterialPosition pos -> pos.Id

        let posNo =
            function
            | RentOrderEntityPosition pos -> pos.PosNo
            | RentOrderMaterialPosition pos -> pos.PosNo

        let subPosNo =
            function
            | RentOrderEntityPosition pos -> pos.SubPosNo
            | RentOrderMaterialPosition pos -> pos.SubPosNo

        let plannedDeliveryDate =
            function
            | RentOrderEntityPosition pos -> pos.PlannedDeliveryDate
            | RentOrderMaterialPosition pos -> pos.PlannedDeliveryDate

        let plannedReturnDate =
            function
            | RentOrderEntityPosition pos -> pos.PlannedReturnDate
            | RentOrderMaterialPosition pos -> pos.PlannedReturnDate

        let releaseDate =
            function
            | RentOrderEntityPosition pos -> pos.ReleaseDate
            | RentOrderMaterialPosition pos -> pos.ReleaseDate

        let deliveryDate =
            function
            | RentOrderEntityPosition pos -> pos.DeliveryDate
            | RentOrderMaterialPosition pos -> pos.DeliveryDate

        let returnDate =
            function
            | RentOrderEntityPosition pos -> pos.ReturnDate
            | RentOrderMaterialPosition pos -> pos.ReturnDate

        let state =
            function
            | RentOrderEntityPosition pos -> pos.State
            | RentOrderMaterialPosition pos -> pos.State

        let stateToString =
            function
            | RentOrderPositionState.Planned -> "erfasst"
            | RentOrderPositionState.AssignedToDeliver -> "Lieferung eingeplant"
            | RentOrderPositionState.AssignedToReturn -> "Abholung eingeplant"
            | RentOrderPositionState.Delivered -> "geliefert"
            | RentOrderPositionState.Returned -> "abgeholt"
            | RentOrderPositionState.Released -> "freigegeben"

        let comment =
            function
            | RentOrderEntityPosition pos -> pos.Comment
            | RentOrderMaterialPosition pos -> pos.Comment

        let selfserviceDelivery =
            function
            | RentOrderEntityPosition pos -> pos.SelfserviceDelivery
            | RentOrderMaterialPosition pos -> pos.SelfserviceDelivery

        let selfserviceReturn =
            function
            | RentOrderEntityPosition pos -> pos.SelfserviceReturn
            | RentOrderMaterialPosition pos -> pos.SelfserviceReturn

        let filterEntityPositions positions =
            positions
            |> List.choose (function
                            | RentOrderEntityPosition pos -> Some pos
                            | RentOrderMaterialPosition _ -> None)

        let filterMaterialPositions positions =
            positions
            |> List.choose (function
                            | RentOrderMaterialPosition pos -> Some pos
                            | RentOrderEntityPosition _ -> None)

        let addOrUpdate newPosition positions =
            let isInList = positions |> List.exists (fun p -> p |> posNo = (newPosition |> posNo) && p |> subPosNo = (newPosition |> subPosNo))
            if isInList then
                positions
                |> List.map (fun p ->
                    if p |> posNo = (newPosition |> posNo) && p |> subPosNo = (newPosition |> subPosNo) then newPosition
                    else p)
            else
                newPosition :: positions
                |> List.sortBy posNo

        let increasePosNo (PositionNumber posNo) =
            posNo + 1 |> PositionNumber

    // let toRentOrderWithCompletedTask (rentOrder : RentOrder) : RentOrderWithCompletedTasks =
    //     { Id = rentOrder.Id
    //       State = rentOrder.State
    //       ExecutionLocation = rentOrder.ExecutionLocation
    //       Positions = rentOrder.Positions
    //       ProjectInformation = rentOrder.ProjectInformation
    //       CompletedTasks = [ ] }

    // let emptyCompleteRentOrderTask taskId =
    //     { TaskId = taskId
    //       Image = [ ]
    //       Signature = None
    //       Signer = None
    //       Comment = "" }

    let emptyRentOrderEntityPosition posNo : RentOrderEntityPosition =
        { Id = RentOrderPositionId (System.Guid.Empty)
          PosNo = posNo
          SubPosNo = None
          EntityId = Shared.EntityId (System.Guid.Empty)
          State = RentOrderPositionState.Planned
          PlannedDeliveryDate = DateTime.UtcNow.Date
          PlannedReturnDate = DateTime.UtcNow.Date
          DeliveryDate = None
          ReturnDate = None
          Comment = ""
          SelfserviceDelivery = false
          SelfserviceReturn = false
          ReleaseDate = None }

    let emptyRentOrderMaterialPosition posNo : RentOrderMaterialPosition =
        { Id = System.Guid.Empty |> RentOrderPositionId
          PosNo = posNo
          SubPosNo = None
          MaterialId = System.Guid.Empty |> MaterialId
          Quantity = 1.
          State = RentOrderPositionState.Planned
          PlannedDeliveryDate = DateTime.UtcNow.Date
          PlannedReturnDate = DateTime.UtcNow.Date
          DeliveryDate = None
          ReturnDate = None
          Comment = ""
          SelfserviceDelivery = false
          SelfserviceReturn = false
          ReleaseDate = None }

    let intialProjectInformation =
        { ProjectNumber = ""
          BuildingProjectName = ""
          Comment = "" }

    let initialRentOrder =
        { Id = RentOrderId (System.Guid.Empty)
          State = RentOrderState.Placed
          ExecutionLocation = emptyAddress
          ProjectInformation = intialProjectInformation
          OrderNumber = "" 
          Positions = []
          Tasks = []
          Reminders = [] }

    let toRentOrderDto (rentOrder : RentOrder) withDelivery pickEntities =
        { State = rentOrder.State
          ExecutionLocation = rentOrder.ExecutionLocation
          WithoutDelivery = withDelivery
          PickEntities = pickEntities
          ProjectInformation = rentOrder.ProjectInformation
          Positions = rentOrder.Positions }

    let toCompoundEntityId (positions : RentOrderEntityPosition list) : EntityType list =
        positions
        |> List.groupBy (fun p -> p.PosNo)
        |> List.collect (fun (_, positions) ->
            if positions.Length = 1 then
                [ EntityType.EntityId positions.Head.EntityId ]
            else
                match positions |> List.tryFind (fun p -> p.SubPosNo.IsNone) with
                | Some mainPos ->
                    let compoundEntity : CompoundEntityId =
                        { EntityId = (positions |> List.find (fun p -> p.SubPosNo.IsNone)).EntityId
                          SubEntityIds = positions |> List.choose (fun p -> if p.SubPosNo.IsNone then None else Some p.EntityId) }
                    [ EntityType.CompoundEntityId compoundEntity ]
                | None -> positions |> List.map (fun p -> p.EntityId |> EntityType.EntityId))

    let minPositionPlannedDate (positions : RentOrderPosition list) =
        positions
        |> List.map(fun p ->
            match p with
            | RentOrderPosition.RentOrderEntityPosition pos -> pos.PlannedDeliveryDate
            | RentOrderPosition.RentOrderMaterialPosition pos -> pos.PlannedDeliveryDate)
        |> List.min

    let maxPositionPlannedDate (positions : RentOrderPosition list) =
        positions
        |> List.map(fun p ->
            match p with
            | RentOrderPosition.RentOrderEntityPosition pos -> pos.PlannedDeliveryDate
            | RentOrderPosition.RentOrderMaterialPosition pos -> pos.PlannedDeliveryDate)
        |> List.max

    let stateToString = function
        | RentOrderState.Placed -> "erfasst"
        | RentOrderState.InProgress -> "aktiv"
        | RentOrderState.Completed -> "abgeschlossen"
        | RentOrderState.WaitForPick _ -> "in Kommissionierung"
        | RentOrderState.PlacedForPick _ -> "in Disponierung"

let (|Position|_|) (posNo, subPosNo) (positions : RentOrderPosition list) =
    positions
    |> List.tryFind (fun pos -> pos |> Helper.Position.posNo = posNo && pos |> Helper.Position.subPosNo = subPosNo)
    |> (function
        | Some pos -> Position pos |> Some
        | None -> None)

let (|MaterialPosition|_|) (posNo, subPosNo) (positions : RentOrderPosition list) =
    match positions with
    | Position (posNo, subPosNo) position ->
        match position with
        | RentOrderMaterialPosition p -> MaterialPosition p |> Some
        | _ -> None
    | _ -> None

let (|EntityPosition|_|) (posNo, subPosNo) (positions : RentOrderPosition list) =
    match positions with
    | Position (posNo, subPosNo) position ->
        match position with
        | RentOrderEntityPosition p -> EntityPosition p |> Some
        | _ -> None
    | _ -> None

let (|PositionById|_|) posId (positions : RentOrderPosition list) =
    positions
    |> List.tryFind (fun pos -> pos |> Helper.Position.id = posId)
    |> (function
        | Some pos -> PositionById pos |> Some
        | None -> None)

let (|MaterialPositionById|_|) id (positions : RentOrderPosition list) =
    match positions with
    | PositionById id position ->
        match position with
        | RentOrderMaterialPosition p -> MaterialPositionById p |> Some
        | _ -> None
    | _ -> None

let (|EntityPositionById|_|) id (positions : RentOrderPosition list) =
    match positions with
    | PositionById id position ->
        match position with
        | RentOrderEntityPosition p -> EntityPositionById p |> Some
        | _ -> None
    | _ -> None
