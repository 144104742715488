module Appointment.View

open Shared
open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open Appointment.Types
open Shared.Appointment
open SharedComponents.Badges
open SharedComponents.Dividers
open SharedComponents.ReactCalendar
open SharedComponents.Spinners
open Sww.Frontend.ReactTable
open Feliz

let private state (appointment : Appointment) =
    match appointment.State with
    | AppointmentState.Future -> badgePill "zukünftig" BadgeClassType.Light
    | AppointmentState.Past -> badgePill "überfällig" BadgeClassType.Danger
    | AppointmentState.Today -> badgePill "heute" BadgeClassType.Primary

let private appointmentInfoBox (model : Model) (appointment : Appointment) =
    let body =
        let msgPart mainMsg subMsg =
            div [ ]
              [ p [ ]
                  [ str mainMsg ]
                p [ ]
                  [ strong [ ]
                      [ str subMsg ] ]
                p [ ]
                  [ state appointment] ]
        match appointment.AppointmentType with
        | CheckAppointment a -> msgPart a.Message a.SubMessage
        | RentOrderAppointment a -> msgPart a.Message a.SubMessage
    let link =
        let href, text =
            match appointment.AppointmentType with
            | CheckAppointment a ->
                let (TemplateId templateId) = a.TemplateId
                let (EntityId entityId) = a.EntityId
                Routes.toPath (Routes.Page.EntityViewForm (templateId, entityId)), "Zum Gerät"
            | RentOrderAppointment a ->
                let (RentOrderId rentOrderId) = a.RentOrderId
                Routes.toPath (Routes.Page.RentOrderDetail (rentOrderId)), "Zum Mietauftrag"
        a [ Href href ]
            [ i [ Class "fas fa-info" ] [ ]
              span [ Class "url-text" ] [ str text ] ]
    let additionalClassName =
      match model.SelectedView with
      | ViewOption.Table -> "card-container-table-view"
      | ViewOption.Calendar -> ""
    div [ Class (sprintf "card card-container %s" additionalClassName) ]
        [ div [ Class "card-body" ]
            [ subHeaderForm "Information"
              div [ Class "card-text" ]
                [ body
                  divider
                  link ] ] ]

let private appointmentRow (model : Model) dispatch (appointment : Appointment) =
    let className =
        match model.SelectedAppointment with
        | Some a -> if appointment = a then "table-active" else ""
        | None -> ""
    tr [ Key (string appointment.Id)
         Class className
         OnClick (fun _ -> dispatch (SelectAppointment appointment.Id)) ]
        [ td [ ]
            [ str (appointment.Date.ToShortDateString()) ]
          td [ ]
            [ match appointment.AppointmentType with
              | AppointmentType.CheckAppointment a ->
                  str a.Message
              | AppointmentType.RentOrderAppointment a ->
                  str a.Message ]
          td [ ]
            [ state appointment ] ]

let private toAppointmentRow (appointment : Appointment) : AppointmentRow =
    { Date = appointment.Date
      Id = appointment.Id
      Description =
        match appointment.AppointmentType with
        | AppointmentType.CheckAppointment a -> a.Message
        | AppointmentType.RentOrderAppointment a -> a.Message
      AppointmentType =
        match appointment.AppointmentType with
        | AppointmentType.CheckAppointment _ -> "Baugerät"
        | AppointmentType.RentOrderAppointment _ -> "Mietauftrag"
      State =
        match appointment.State with
        | AppointmentState.Future -> "zukünftig"
        | AppointmentState.Past -> "überfällig"
        | AppointmentState.Today -> "heute" }

let private appointmentsTable (model : Model) dispatch =
    let rows = model.Appointments |> List.map toAppointmentRow

    let detailBtn (row : AppointmentRow) =
        a [ Class "display-btn"
            OnClick (fun _ -> dispatch (SelectAppointment row.Id)) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]
      
    reactTable [
        tableProp.keyField "Id"
        tableProp.defaultSortField ("Date", "desc")
        tableProp.rows rows
        tableProp.tableKey "appointments"
        tableProp.columns [
            tableColumn [
                colProp.selector "Date"
                colProp.label "Datum"
                let selector = (fun (row : AppointmentRow) -> row.Date)
                colProp.formatter (Date selector)
                colProp.sortFunction (DateSort selector)
                colProp.filter NoFilter
            ]
            tableColumn [
                colProp.selector "Description"
                colProp.label "Beschreibung"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
                colProp.classes "desc"
            ]
            tableColumn [
                colProp.selector "Type"
                colProp.label "Typ"
                colProp.sortFunction NaturalSort
                colProp.filter (
                    SelectFilter (
                        [ "Mietauftrag"
                          "Baugerät" ],
                        (fun a -> a.AppointmentType)))
                colProp.formatter (Formatter.Custom (fun value row ->
                    match row.AppointmentType with
                    | "Baugerät" -> badgePill "Baugerät" BadgeClassType.Success
                    | "Mietauftrag" -> badgePill "Mietauftrag" BadgeClassType.Secondary
                    | _ -> Html.none))
                colProp.csvFormatter(fun value row -> Html.text row.AppointmentType)
            ]
            tableColumn [
                colProp.selector "State"
                colProp.label "Status"
                colProp.sortFunction NaturalSort
                colProp.filter (
                    SelectFilter (
                        [ "heute"
                          "zukünftig"
                          "überfällig" ],
                        (fun a -> a.State)))
                colProp.formatter (Formatter.Custom (fun value row ->
                    match row.State with
                    | "zukünftig" -> badgePill "zukünftig" BadgeClassType.Light
                    | "überfällig" -> badgePill "überfällig" BadgeClassType.Danger
                    | "heute" -> badgePill "heute" BadgeClassType.Primary
                    | _ -> Html.none))
                colProp.csvFormatter(fun value row -> Html.text row.State)
            ]
            tableColumn [
                colProp.classes "action"
                colProp.selector "Aktion"
                colProp.label "Aktion"
                colProp.formatter (Custom (fun value row -> detailBtn row))
                colProp.csvPrint false
            ]
        ]
    ]
    
let private buildEvents (appointments : Appointment list) =
    let title (appointment : Appointment) =
        match appointment.AppointmentType with
        | CheckAppointment a -> a.Message
        | RentOrderAppointment a -> a.Message
    appointments
    |> List.mapi (fun i a ->
        let (AppointmentId appointmentId) = a.Id
        { id = appointmentId
          title = title a
          start = a.Date
          ``end`` = a.Date
          allDay = true })
    |> List.toArray

let overview (model:Model) (dispatch: Msg -> unit) =
    let calendarSelected, tableSelected =
        match model.SelectedView with
        | Table -> "", "active"
        | Calendar -> "active", ""
    div [ Id "appointment-content"
          Class "flex-grow-1"]
      [ mainHeader "Termine"
        div [ Class "d-flex justify-content-end" ]
            [ div [ Class "btn-group mb-2"
                    Role "group" ]
                [ button [ Class (sprintf "btn btn-secondary %s" tableSelected)
                           OnClick (fun _ -> dispatch (SelectView ViewOption.Table)) ]
                    [ i [ Class (sprintf "fas fa-list")
                           ]
                        [ ] ]
                  button [ Class (sprintf "btn btn-secondary %s" calendarSelected)
                           OnClick (fun _ -> dispatch (SelectView ViewOption.Calendar))  ]
                    [ i [ Class (sprintf "fas fa-calendar-alt")
                          ]
                        [ ] ] ] ]
        div [ Class "d-flex" ]
            [ match model.SelectedView with
              | Table ->
                  div [ Class "flex-grow-1" ]
                      [ appointmentsTable model dispatch ]
              | Calendar ->
                  div [ Class "flex-grow-1 calendar" ]
                      [ defaultCalendar (buildEvents model.Appointments) (fun ev -> dispatch (SelectEvent ev))
                       ]
              div [ Class "infobox" ]
                  [ match model.SelectedAppointment with
                    | Some appointment -> appointmentInfoBox model appointment
                    | None -> Fable.React.Helpers.nothing ] ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "appointment-container"
          Class "flex-grow-1 d-flex" ]
        [ overlaySpinner model.AppointmentFetchedState
          overview model dispatch ]