module UserManagement.State

open Elmish
open Elmish.Navigation
open UserManagement.Types
open Shared
open Thoth.Json
open SharedComponents.Alerts
open Validation
open SharedComponents.Toast
open UserManagement.FormValidation
open SharedComponents.Spinners

let getUsersCmd =
    Cmd.OfPromise.either Communication.getRequest<UsersResponse> "/api/users/active" UsersFetched FetchError

let getUsersWithRolesCmd =
    Cmd.OfPromise.either Communication.getRequest<UsersResponse> "/api/users/withroles" UsersFetched FetchError

let getUserCmd userId =
    Cmd.OfPromise.either Communication.getRequest<User> (sprintf "/api/users/%i" userId) UserFetched FetchError

let createUserCmd user password roles =
    let userDto : NewUserDto = { User = user; Password = password; SecondPassword = password; Roles = roles }
    Cmd.OfPromise.either Communication.postRequest<PostResponse<UserCreateResponseResult>> ("/api/users", (Encode.toString 0 userDto)) UserCreated FetchError

let updateUserCmd userId (updateElement : UserUpdate) =
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>>
                             (sprintf "/api/users/%i" userId, body)
                             UserUpdated FetchError

let getRolesCmd =
    Cmd.OfPromise.either Communication.getRequest<RolesResponse> "/api/roles" RolesFetched FetchError

let deleteUserCmd userId =
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<UserDeleteResult>> (sprintf "/api/users/%i" userId) UserDeleted FetchError

let initialUser = { User = { Id = 0
                             Email = ""
                             Firstname = ""
                             Lastname = ""
                             IsDeleted = false
                             AssignedRoles = [] }
                    Password = ""
                    SecondPassword = ""
                    Roles = [ ] }
let initialModel =
    { Model.Roles = [ ]
      FormState = FormState.Loading
      Users = [ ]
      UserData = None
      SaveState = SaveState.Nothing
      UsersRequestState = RequestState.NotActive
      Filter = SharedComponents.SearchBar.init [ ]
      FilterText = ""
      SelectedFilters = [ ]
      SelectFilterUsers = [ ]  }

let init (oldModel : Model option) : Model * Cmd<Msg> =
    { initialModel with UsersRequestState = RequestState.Active }, getUsersWithRolesCmd

let initNewForm _ : Model * Cmd<Msg> =
    let newFormState =
        { User = initialUser
          FormValidation = None
          EditField = EditField.Nothing } |> FormState.New
    { initialModel with FormState = newFormState }, Cmd.batch [getUsersCmd; getRolesCmd]

let initViewForm userId : Model * Cmd<Msg> =
    { initialModel with UsersRequestState = RequestState.Active }, Cmd.batch [ getUserCmd userId; getRolesCmd; getUsersCmd ]

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    // Overview
    | SetFilter filter ->
        { model with FilterText = filter }, Cmd.OfAsync.result (SharedComponents.SearchBar.filter<User, Msg> filter model.Filter Filtered)
    | Filtered filterModel ->
        { model with Filter = filterModel }, Cmd.ofMsg ApplySelectFilter
    | SetSelectFilters filters ->
        let newFilters = filters |> Array.toList |> List.map (fun o -> o.value)
        { model with SelectedFilters = newFilters }, Cmd.ofMsg ApplySelectFilter
    | ApplySelectFilter ->
        let filteredUsers =
            if model.SelectedFilters.IsEmpty then model.Filter.FilteredElements else
            model.Filter.FilteredElements
            |> List.filter (fun x ->
                let assignedRoleNames = x.AssignedRoles |> List.map (fun r -> r.Name)
                let a =
                    assignedRoleNames
                    |> List.map (fun r ->
                        match r with
                        | RoleNames.Administrator -> if (model.SelectedFilters |> List.contains (SelectFilterType.Admin)) then Some true else None
                        | RoleNames.Dispatcher ->
                            if (model.SelectedFilters |> List.contains (SelectFilterType.Dispatcher)) then Some true else None
                        | RoleNames.Driver -> if (model.SelectedFilters |> List.contains (SelectFilterType.Driver)) then Some true else None
                        | RoleNames.Mechanic -> if (model.SelectedFilters |> List.contains (SelectFilterType.Mechanic)) then Some true else None
                        | RoleNames.Technician -> if (model.SelectedFilters |> List.contains (SelectFilterType.Technician)) then Some true else None
                        | RoleNames.Picker -> if (model.SelectedFilters |> List.contains (SelectFilterType.Picker)) then Some true else None)
                    |> List.choose id
                not a.IsEmpty)
        { model with SelectFilterUsers = filteredUsers }, Cmd.none

    | SetEmail email ->
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with User = { form.User.User with Email = email} } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with User = { form.User.User with Email = email} } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SetPassword password ->
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with Password = password } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with Password = password } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SetSecondPassword password ->
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with SecondPassword = password } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with SecondPassword = password } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SetFirstname firstname ->
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with User = { form.User.User with Firstname = firstname} } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with User = { form.User.User with Firstname = firstname} } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SetLastname lastname ->
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with User = { form.User.User with Lastname = lastname} } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with User = { form.User.User with Lastname = lastname} } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SetRoles roles ->
        let newRoles = roles |> Array.toList |> List.map (fun o -> o.value)
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Loading
            | Edit form ->
                { form with User = { form.User with Roles = newRoles } } |> FormState.Edit
            | New form ->
                { form with User = { form.User with Roles = newRoles } } |> FormState.New
        { model with FormState = newFormState }, Cmd.none
    | SaveUser ->
        let validation =
            match model.FormState with
            | New form -> validateNewForm model form
            | Edit form ->
                match form.EditField with
                | EditField.EditBaseInformation _ -> validateBaseInformation model form
                | EditField.EditPassword -> validatePassword form
                | EditField.EditRoles _ -> validateRoles form
                | EditField.Nothing -> notValid
            | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let newModel, cmd =
                    match model.FormState with
                    | New form ->  { model with UsersRequestState = RequestState.Active }, createUserCmd form.User.User form.User.Password form.User.Roles
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditBaseInformation ->
                            { model with UsersRequestState = RequestState.Active },
                            { Email = form.User.User.Email
                              Firstname = form.User.User.Firstname
                              Lastname = form.User.User.Lastname }
                            |> UserUpdate.BaseInformation
                            |> updateUserCmd form.User.User.Id
                        | EditField.EditPassword ->
                            { model with UsersRequestState = RequestState.Active },
                            form.User.Password
                            |> UserUpdate.Password
                            |> updateUserCmd form.User.User.Id
                        | EditField.EditRoles ->
                            { model with UsersRequestState = RequestState.Active },
                            form.User.Roles
                            |> UserUpdate.Roles
                            |> updateUserCmd form.User.User.Id
                        | EditField.Nothing -> model, Cmd.none
                    | FormState.Loading -> model, Cmd.none
                { newModel with SaveState = SaveState.Saving }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, warningToast
                | FormState.Loading -> model, Cmd.none
                | New form ->
                    let newFormState = { form with FormValidation = Some validation } |> New
                    { model with FormState = newFormState }, warningToast
        newModel, cmd

    // Set edit states
    | EditBaseInformation ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditBaseInformation
                            User = form.UserSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditPassword ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditPassword
                            User = form.UserSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditRoles ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditRoles
                            User = form.UserSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Nothing
                            User = form.UserSnapshot
                            FormValidation = None } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    // Delete actions
    | AbortDelete ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | FormState.New form -> form |> FormState.New
            | FormState.Edit form ->
                { form with DeleteRequested = None } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | RequestDelete ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | FormState.New form -> form |> FormState.New
            | FormState.Edit form ->
                { form with DeleteRequested = Some form.User.User.Id } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | DeleteRequest ->
        let newModel, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | FormState.New form -> model, Cmd.none
            | FormState.Edit form ->
                match form.DeleteRequested with
                | Some id -> { model with UsersRequestState = RequestState.NotActive }, deleteUserCmd id
                | None -> model, Cmd.none
        newModel, cmd

    /// Requests
    | UserFetched user ->
        let user = { User = user;
                     Password = "";
                     SecondPassword = "";
                     Roles = user.AssignedRoles }
        let newFormState =
            match model.FormState with
            | FormState.Loading ->
                { User = user
                  UserSnapshot = user
                  FormValidation = None
                  EditField = EditField.Nothing
                  DeleteRequested = None } |> FormState.Edit
            | FormState.New form -> form |> FormState.New
            | FormState.Edit form ->
                { form with User = user
                            UserSnapshot = user
                            FormValidation = None } |> FormState.Edit
        { model with FormState = newFormState
                     UsersRequestState = RequestState.NotActive }, Cmd.none
    | UsersFetched response ->
        let users =
            response.Users
            |> List.sortBy (fun u -> u.Lastname)
        { model with Users = users
                     Filter = SharedComponents.SearchBar.init response.Users
                     SelectFilterUsers = users
                     UsersRequestState = RequestState.NotActive }, Cmd.none
    | UserCreated response ->
        let cmd =
            match response.Result with
            | Created userId ->
                Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.UserManagementViewForm userId))
                            toast (ToastType.Success "Benutzer wurde erfolgreich angelegt.") ]
            | UserCreateResponseResult.UserWithEmailExists ->
                Cmd.batch [ toast (ToastType.Warning "Benutzer konnte nicht angelegt werden. Benutzer mit dieser E-Mail exisitiert bereits.") ]
        { model with SaveState = SaveState.Nothing
                     UsersRequestState = RequestState.NotActive }, cmd
    | UserUpdated response ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                { model with FormState = { form with UserSnapshot = form.User
                                                     EditField = EditField.Nothing
                                                     FormValidation = None } |> FormState.Edit },
                toast (ToastType.Success "Benutzer erfolgreich aktualisiert.")
        { model with SaveState = Nothing
                     UsersRequestState = RequestState.NotActive }, cmd
    | RolesFetched response ->
        { model with Roles = response.Roles }, Cmd.none
    | AbortDeleteUser ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | FormState.New form -> form |> FormState.New
            | FormState.Edit form ->
                { form with DeleteRequested = None } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | UserDeleted response ->
        let newCmd =
            match response.Result with
            | UserDeleteResult.Successful ->
                Cmd.batch [ Navigation.newUrl (Routes.toPath Routes.Page.UserManagement)
                            toast (ToastType.Info "Benutzer wurde erfolgreich gelöscht.") ]
            | UserDeleteResult.CannotDeleteYourself ->
                toast (ToastType.Error "Du kannst dich nicht selber löschen")
        { model with UsersRequestState = RequestState.NotActive }, newCmd
    | FetchError e ->
        { model with UsersRequestState = RequestState.NotActive}, ErrorHandling.handleFetchError e
