module Order.RentOrder.DeletePositionDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Thoth.Json
open Client.SharedComponents
open Shared.Configuration
open Shared.Address
open SharedComponents.ReactSelect
open SharedComponents.Types.Helpers
open Types
open Shared.RentOrder
open Feliz.ElmishComponents
open System
open SharedComponents.Toast

type Msg =
    | DeletePosition of DeleteRentOrderPositionsDto
    | PositionDeleted of PostResponse<RentOrderDeleteResult>
    | FetchError of exn


let deleteRentOrderPositionCmd (dto : DeleteRentOrderPositionsDto) =
    let url = "/api/rentorders/deletepositions"
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<RentOrderDeleteResult>> (url, body) PositionDeleted FetchError

type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { RentOrderId : RentOrderId
      PositionIds : RentOrderPositionId list
      RequestActive : bool
      WarningMsg : string option
      SuccessCallback : unit -> unit }

let init rentOrderId rentOrderPositionIds successCallback : State * Cmd<Msg> =
    let initialModel =
        { RentOrderId = rentOrderId
          PositionIds = rentOrderPositionIds
          RequestActive = false
          WarningMsg = None
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | DeletePosition dto ->
        { state with RequestActive = true }, deleteRentOrderPositionCmd dto
    | PositionDeleted response ->
        let state, newCmd =
            match response.Result with
            | RentOrderDeleteResult.Deleted _ ->
                state.SuccessCallback()
                Swal.close (SweetAlert.Result.Value ())
                { state with RequestActive = false }, toast (ToastType.Success "Positionen wurden gelöscht.")
            | RentOrderDeleteResult.PositionsInWrongState positionIds ->
                let posNos = positionIds |> List.map(fun (pId, posNo) -> posNo)
                let msg = "Folgende Positionen konnten nicht gelöscht werden: " + (String.concat " " posNos)
                { state with WarningMsg = msg |> Some }, toast (ToastType.Error "Positionen konnten nicht gelöscht werden. Positionen sind im falschen Zustand.")
        state, newCmd
    | FetchError e ->
        { state with RequestActive = false }, Cmd.none

let dialogBody (state : State) dispatch =
    Html.div [
        prop.id "delete-position-dialog"
        prop.className "form-group"
        prop.children [

            match state.WarningMsg with
            | Some msg -> SharedComponents.Alerts.warningAlert msg
            | None -> Html.none

            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.text "Willst du die selektierten Positionen wirklich löschen?"
            ]

            SharedComponents.Alerts.warningAlert "Als Admin kann jede Position gelöscht werden, egal in welchem Zustand sich die Position befindet. Die dazugehörige Aufgaben werden ebenfalls gelöscht und das Gerät ins Hauptlager gebucht."

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnctAndIsDisabled
                        (fun _ ->
                            let dto =
                                { DeleteRentOrderPositionsDto.RentOrderId = state.RentOrderId
                                  DeleteRentOrderPositionsDto.PositionIds = state.PositionIds }
                            dto |> DeletePosition |> dispatch)
                        (state.RequestActive)
                        "Löschen" "mr-3"
                    Buttons.primaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" ""
                ]
            ]

        ]
    ]

let elmishComp (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) successCallback =
    React.elmishComponent("DeleteDialog", init rentOrderId positionIds successCallback, update, dialogBody)

let render (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (isDisabled : bool) successCallback =
    let disabledClass = if isDisabled then "disabled" else ""
    Html.button [
        prop.className (sprintf "dropdown-item %s" disabledClass)
        prop.disabled isDisabled
        prop.text "Löschen"
        prop.onClick (fun _ ->
                        Swal.fire ([
                            swal.html (elmishComp rentOrderId positionIds successCallback)
                            swal.showCancelButton false
                            swal.showConfirmButton false
                            ],
                            (function
                                | SweetAlert.Result.Value _ ->
                                    Swal.Simple.success "Positionen wurden gelöscht"
                                    successCallback ()
                                | SweetAlert.Result.Dismissal d ->
                                    printfn "Dismiss %O" d
                                | SweetAlert.Result.Denied ->
                                    printfn "Denied"
                            )
                        )
                    )
    ]

let deletePositionDialog' =
    React.functionComponent(fun (props : {| rentOrderId : RentOrderId
                                            positionIds : RentOrderPositionId list
                                            isDisabled : bool
                                            successCallback : unit -> unit |}) -> render props.rentOrderId props.positionIds props.isDisabled props.successCallback)

let deletePositionDialog (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (isDisabled : bool) successCallback =
    deletePositionDialog' {| rentOrderId = rentOrderId
                             positionIds = positionIds
                             isDisabled = isDisabled
                             successCallback = successCallback |}