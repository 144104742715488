module Sww.Frontend.ReactTable

open Fable.Core
open Fable.React
open Fable.Core.JsInterop
open Fable.React.Props
open Feliz
open System
open Sww.Frontend.I18next
open Sww.Frontend.LocalStorageHelper


// https://react-bootstrap-table.github.io

type SizePerPageOptionType =
    { text : string option
      page : int option
      onSizePerPageChange : (int -> unit) }

type PaginationProps =
    { sizePerPageOptionRenderer : SizePerPageOptionType -> ReactElement
      onSizePerPageChange : int -> unit
      onPageChange : int -> int -> unit
      sizePerPage : int
      page : int }

type DefaultSorted =
    { dataField : string
      order : string }

[<ImportDefault("react-bootstrap-table2-paginator")>]
let paginationFactory(v:PaginationProps): (unit -> obj) = jsNative

[<ImportDefault("react-bootstrap-table2-filter")>]
let filterFactory(): (unit -> obj) = jsNative

type TextFilterProps<'a> =
    { placeholder : string
      onFilter : (string option -> 'a[] -> 'a[] option) option
      caseSensitive : bool
      defaultValue : string }

[<ImportMember("react-bootstrap-table2-filter")>]
let textFilter(v : TextFilterProps<'a>): (unit -> obj) = jsNative

type SelectFilterOption =
    { value : int
      label : obj }

type SelectFilterProps<'a> =
    { options : SelectFilterOption[]
      placeholder : string
      onFilter : string -> 'a[] -> 'a[]
      defaultValue : string }

[<ImportMember("react-bootstrap-table2-filter")>]
let selectFilter(v : SelectFilterProps<'a>): (unit -> obj) = jsNative

let selectFilterWithOptions (useTranslation : I18nextTranslation) (updateParams : (string -> unit) option) (options : SelectFilterOption list) (onFilter : string -> 'a[] -> 'a[]) defaultValue =
    let onSelectFilter =
        match updateParams with
        | Some updateParams ->
            fun filterValue ->
                updateParams filterValue
                onFilter filterValue
        | None -> onFilter
    let selectFilter =
        selectFilter(
            { options = options |> List.toArray
              placeholder = (useTranslation.translate("general.choose_filter"))
              onFilter = onSelectFilter
              defaultValue = defaultValue })
    selectFilter

let onFilterWithExtractor<'a> (extractor : 'a -> string) (updateParams : (string -> unit) option) (filterText : string option) (input : 'a[]) : 'a[] option =
    match filterText with
    | Some filterText ->
        match updateParams with
        | Some updateParams ->
            updateParams filterText
        | None -> ()
        input
        |> Array.filter (fun element ->
            (element |> extractor).Contains filterText
        )
        |> Some
    | None -> Some input


let onFilter<'a, 'Params> (updateParams : string -> unit) (filterText : string option) (input : 'a[]) : 'a[] option =
    match filterText with
    | Some filterText ->
        updateParams filterText
        None
    | None -> None

let textFilterWithOptions<'a, 'Params> (useTranslation : I18nextTranslation) (updateParams : (string -> unit) option) (extractor : ('a -> string) option) (defaultValue : string) =
    let onFilterText =
        match extractor with
        | Some e -> onFilterWithExtractor e updateParams |> Some
        | None ->
            match updateParams with
            | Some updateParams ->
                onFilter updateParams |> Some
            | None -> None
    textFilter(
        { placeholder = (useTranslation.translate("general.search"))
          onFilter = onFilterText
          caseSensitive = false
          defaultValue = defaultValue })

let customDropDownItem (sizePerPageOption : SizePerPageOptionType) =
    let text =
        match sizePerPageOption.text with
        | Some t -> t
        | None -> ""
    let page =
        match sizePerPageOption.page with
        | Some t -> t
        | None -> 0
    li [ Role "presentation"
         Key text
         ClassName "dropdown-item" ]
        [ a [ Href "#"
              Class "dropdown-item"
              TabIndex -1
              Role "menuitem"
              Data ("data-page", page)
              OnClick (fun e -> e.preventDefault())
              OnMouseDown (fun e -> e.preventDefault()
                                    sizePerPageOption.onSizePerPageChange(page)) ]
            [ str text ] ]

let initPaginationFactory<'Params> (tableKey : string option) localStorageKey (updatePage : (int -> unit) option) page =
    let paginationFactory =
        paginationFactory(
            { sizePerPageOptionRenderer = (fun sizePerPageOption -> customDropDownItem sizePerPageOption)
              onSizePerPageChange = (fun (page) -> writeInitialPageSize localStorageKey tableKey page)
              onPageChange = (fun page sizePerPage ->
                match updatePage with
                | Some updatePage ->
                    // writeQueryParams (updatePage page) readParams writeParams
                    updatePage page
                | None-> ()
                )
              sizePerPage = initialPageSize localStorageKey tableKey
              page = page })
    paginationFactory

type ColumnFilter<'a> =
    | NoFilter
    | TextFilter
    | CustomTextFilter of ('a -> string)
    | SelectFilter of string list * ('a -> string)
    | SelectFilterMultiOptions of string list * ('a -> string list)

type SortFunction<'a> =
    | NoSort
    | NaturalSort
    | CustomNaturalSort of ('a -> string)
    | DateSort of ('a -> DateTime)

type Formatter<'a> =
    | Text
    | Translate
    | Date of ('a -> DateTime)
    | DateTime of ('a -> DateTime)
    | Custom of (string -> 'a -> ReactElement)

type Column<'a> =
    { dataField : string
      text : string
      sort : bool
      filter : (unit -> obj) option
      formatter : (string -> 'a -> ReactElement) option
      sortFunc : (string -> string -> string -> string -> 'a -> 'a -> int) option
      headerClasses : string
      csvExport : bool
      csvFormatter : (string -> 'a -> ReactElement) option
      classes : string }

type ReactTableProps<'a> =
    | Bootstrap4 of bool
    | Bordered of bool
    | DefaultSorted of DefaultSorted array
    | KeyField of string
    | Data of 'a array
    | Rows of 'a list
    | Columns of Column<'a> array
    | Pagination of (unit -> obj)
    | NoDataIndication of string
    | Filter of (unit -> obj)
    | ExportCSV of bool

let inline ofFunction2 (f : obj -> ReactElement) : ReactElement =
    unbox f

[<Emit("Object.assign($0, $1)")>]
let merge obj1 obj2 = jsNative

let inline table exportName exportCSV (props : ReactTableProps<'a> list) =
    let props =
        [ match props |> List.tryFind(fun prop -> match prop with | Bordered _ -> true | _ -> false) with
          | Some _ -> ()
          | None -> Bordered false
        ] |> List.append props
    let toolkitProps =
        if exportCSV then
            merge
                (keyValueList CaseRules.LowerFirst props)
                {| exportCSV =
                    {| fileName = exportName
                       separator = ";"
                       exportAll = false
                       onlyExportFiltered = true
                       noAutoBOM = false |} |}
        else
            (keyValueList CaseRules.LowerFirst props)
    ofImport "default" "@softwarewerkstatt/react-bootstrap-table2-toolkit" toolkitProps [
        ofFunction2 (fun p ->
            let tableProps =
                merge
                    (keyValueList CaseRules.LowerFirst props)
                    p?baseProps
            let buttonProps =
                merge
                    p?csvProps
                    {| className = "ml-auto" |}
            Html.div [
                prop.className "d-flex flex-column"
                prop.children [
                    if exportCSV then
                        ofImport "CSVExport.ExportCSVButton" "@softwarewerkstatt/react-bootstrap-table2-toolkit" buttonProps [
                            Html.div [
                                Html.i [
                                    prop.className "fas fa-file-csv mr-2"
                                ]
                                t "general.download_table"
                            ]
                        ]
                    ofImport "default" "react-bootstrap-table-next" tableProps [ ]
                ]
            ]
        )
    ]

type TableColumn<'a> =
    { Selector : string
      Label : string
      SortFunction : SortFunction<'a>
      Filter : ColumnFilter<'a>
      FilterValue : string
      UpdateParams : (string -> unit) option
      Formatter : Formatter<'a>
      CSVExport : bool
      CSVFormatter : (string -> 'a -> ReactElement) option
      Classes : string }

type ColProp<'a> =
    | Selector of string
    | Label of string
    | SortFunction of SortFunction<'a>
    | Filter of ColumnFilter<'a>
    | FilterValue of string
    | Formatter of Formatter<'a>
    | CSVExport of bool
    | CSVFormatter of (string -> 'a -> ReactElement)
    | Classes of string
    | UpdateParams of (string -> unit)

type TableProp<'a> =
    | KeyField of string
    | Columns of TableColumn<'a> list
    | Rows of 'a list
    | DefaultSortField of string * string
    | TableKey of string
    | Page of int
    | UpdatePage of (int -> unit)
    | LocalStorageKey of string
    | TableExportName of string
    | Pagination of bool
    | ExportCSV of bool

type tableProp() =
    static member columns cols = Columns cols
    static member keyField keyField = KeyField keyField
    static member rows rows = Rows rows
    static member defaultSortField (field, direction) = DefaultSortField (field, direction)
    static member tableKey tableKey = TableKey tableKey
    static member page p = Page p
    static member updatePage p = UpdatePage p
    static member tableExportName n = TableExportName n
    static member pagination p = Pagination p
    static member exportCSV e = ExportCSV e

type colProp() =
    static member selector s = Selector s
    static member label l = Label l
    static member sortFunction s = SortFunction s
    static member filter f = Filter f
    static member filterValue f = FilterValue f
    static member formatter form = Formatter form
    static member csvPrint print = CSVExport print
    static member csvFormatter formatter = CSVFormatter formatter
    static member classes c = Classes c
    static member updateParams p = UpdateParams p

let tableColumn props =
    let selector = props |> List.pick (function | ColProp.Selector s -> Some s | _ -> None)
    let label = props |> List.pick (function | ColProp.Label l -> Some l | _ -> None)
    let sortFunction = props |> List.tryPick (function | ColProp.SortFunction s -> Some s | _ -> None) |> Option.defaultValue SortFunction.NoSort
    let filter = props |> List.tryPick (function | ColProp.Filter f -> Some f | _ -> None) |> Option.defaultValue NoFilter
    let filterValue = props |> List.tryPick (function | ColProp.FilterValue f -> Some f | _ -> None) |> Option.defaultValue ""
    let formatter = props |> List.tryPick (function | Formatter f -> Some f | _ -> None) |> Option.defaultValue Text
    let csvExport = props |> List.tryPick (function | CSVExport f -> Some f | _ -> None) |> Option.defaultValue true
    let csvFormatter = props |> List.tryPick (function | CSVFormatter f -> Some f | _ -> None)
    let classes = props |> List.tryPick (function | Classes c -> Some c | _ -> None) |> Option.defaultValue ""
    let updateParams = props |> List.tryPick (function | UpdateParams p -> Some p | _ -> None)
    { Selector = selector
      Label = label
      SortFunction = sortFunction
      Filter = filter
      FilterValue = filterValue
      Formatter = formatter
      CSVExport = csvExport
      CSVFormatter = csvFormatter
      Classes = classes
      UpdateParams = updateParams }

let reactTable<'a> = React.functionComponent(fun (props : TableProp<'a> list) ->
    let useTranslation = useTranslation()

    let rows = props |> List.pick (function | TableProp.Rows rows -> Some rows | _ -> None)
    let columns = props |> List.pick (function | TableProp.Columns col -> Some col | _ -> None)
    let keyField = props |> List.pick (function | TableProp.KeyField k -> Some k | _ -> None)
    let sortField = props |> List.tryPick (function | TableProp.DefaultSortField (s, d) -> Some (s, d) | _ -> None)
    let tableKey = props |> List.tryPick (function | TableProp.TableKey k -> Some k | _ -> None)
    let page = props |> List.tryPick (function | TableProp.Page p -> Some p | _ -> None) |> Option.defaultValue 1
    let updatePage = props |> List.tryPick (function | TableProp.UpdatePage p -> Some p | _ -> None)
    let localStorageKey = props |> List.tryPick (function | TableProp.LocalStorageKey p -> Some p | _ -> None) |> Option.defaultValue ""
    let tableExportName = props |> List.tryPick (function | TableProp.TableExportName n -> Some n | _ -> None) |> Option.defaultValue "data_export.csv"
    let pagination = props |> List.tryPick (function | TableProp.Pagination n -> Some n | _ -> None) |> Option.defaultValue true
    let exportCSV = props |> List.tryPick (function | TableProp.ExportCSV n -> Some n | _ -> None) |> Option.defaultValue true

    let selectFilter (extractValueFunc : 'a -> string) (options : string list) (index : string) (list : 'a array) =
        if index <> "" then
            let selectedOption = options.[index |> int]
            list
            |> Array.filter (fun element -> extractValueFunc element = selectedOption)
        else list

    let selectFilterMultiOptions (extractValueFunc : 'a -> string list) (options : string list) (index : string) (list : 'a array) =
        if index <> "" then
            let selectedOption = options.[index |> int]
            list
            |> Array.filter (fun element -> extractValueFunc element |> List.contains selectedOption)
        else list

    let toColumn (col : TableColumn<'a>) =
        let filter =
            match col.Filter with
            | NoFilter -> None
            | TextFilter -> Some (textFilterWithOptions useTranslation col.UpdateParams None col.FilterValue)
            | CustomTextFilter extractor -> Some (textFilterWithOptions useTranslation col.UpdateParams (Some extractor) col.FilterValue)
            | SelectFilter (options, extractValueFunc) ->
                let translatedLables = options |> List.map useTranslation.translate
                let ops = translatedLables |> List.mapi (fun i o -> { value = i; label = o})
                Some (selectFilterWithOptions useTranslation col.UpdateParams ops (selectFilter extractValueFunc options) col.FilterValue)
            | SelectFilterMultiOptions (options, extractValueFunc) ->
                let translatedLables = options |> List.map useTranslation.translate
                let ops = translatedLables |> List.mapi (fun i o -> { value = i; label = o})
                Some (selectFilterWithOptions useTranslation col.UpdateParams ops (selectFilterMultiOptions extractValueFunc options) col.FilterValue)
        let formatter =
            match col.Formatter with
            | Text -> Some (fun (text : string) _ -> Html.text (text |> Option.ofObj |> Option.defaultValue ""))
            | Translate -> Some (fun translationKey _ -> Html.text (useTranslation.translate translationKey))
            | Date extractor -> Some (fun _ row -> Html.text ((extractor row).ToLocalTime().ToString("dd.MM.yyyy")))
            | DateTime extractor -> Some (fun _ row -> Html.text ((extractor row).ToLocalTime().ToString("dd.MM.yyyy HH:mm")))
            | Custom formatter -> Some formatter
        { Column.dataField = col.Selector
          text = useTranslation.translate(col.Label)
          sort = match col.SortFunction with | NoSort -> false | _ -> true
          filter = filter
          formatter = formatter
          sortFunc =
            match col.SortFunction with
            | NaturalSort -> Some NaturalOrder.customSort
            | CustomNaturalSort extractor -> Some (NaturalOrder.customSortWithExtractor extractor)
            | DateSort extractor -> Some (NaturalOrder.dateSort extractor)
            | NoSort -> None
          headerClasses = "header-class"
          csvExport = col.CSVExport
          csvFormatter = match col.CSVFormatter with | Some csvFormatter -> col.CSVFormatter | None -> formatter
          classes = col.Classes }

    let generatedProps = [
        ReactTableProps<'a>.Columns (columns |> List.map toColumn |> List.toArray)
        ReactTableProps<'a>.KeyField keyField
        ReactTableProps<'a>.Data (rows |> List.toArray)
        if pagination then
            ReactTableProps<'a>.Pagination (initPaginationFactory tableKey localStorageKey updatePage page)
        ReactTableProps<'a>.Filter (filterFactory())
        ReactTableProps<'a>.NoDataIndication (useTranslation.translate("general.no_data"))
        ReactTableProps<'a>.Bootstrap4 true
        ReactTableProps<'a>.Bordered false
        match sortField with
        | Some (sortField, direction) ->
            ReactTableProps<'a>.DefaultSorted [| { dataField = sortField; order = direction } |]
        | None -> ()
        if exportCSV then
            ReactTableProps<'a>.ExportCSV true
    ]

    table tableExportName exportCSV generatedProps
)
