module TemplateConfiguration.State

open Client
open Elmish
open Elmish.Navigation
open TemplateConfiguration.Types
open TemplateConfiguration.FormValidation
open Routes
open Shared
open Shared.Entity
open SharedComponents.Toast
open Validation
open Browser.Dom
open Browser.Blob
open Browser.Types
open Browser.WebStorage
open Browser.XMLHttpRequest
open Fable.Core.JsInterop
open System
open Thoth.Json
open Fetch
open SharedComponents.Spinners

let private initialModel userData =
    { Templates = [ ]
      FormState = Loading
      UserData = userData
      TemplateImages = [ ]
      SaveState = SaveState.Nothing
      DocumentUploadForm = None
      Filter = SharedComponents.SearchBar.init [ ]
      FilterText = ""
      SelectedFilters = []
      SelectFilterTemplates = []
      TemplateRequestState = RequestState.NotActive }

let initNewTemplateGroupForm userData =
    let newFormState =
        { Template = { Helper.emptyTemplate with ChildTemplates = Some { ChildTemplates.Templates = [ ] } }
          NewReferencedTemplateRow = false
          FormValidation = None
          SelectableTemplates = [ ] }
        |> NewFormType.TemplateGroup |> FormState.New
    { initialModel userData with FormState = newFormState }, Commands.getTemplatesCmd TemplatesFetched FetchError

let initNewTemplateForm userData =
    let newFormState =
        { Template = Helper.emptyTemplate
          FormValidation = None }
        |> NewFormType.Template |> FormState.New
    { initialModel userData with FormState = newFormState } , Cmd.none

let initViewForm templateId userData =
    let templateId = templateId |> TemplateId
    initialModel userData,
    Cmd.batch [
        Commands.getTemplateCmd templateId TemplateFetched FetchError
        Commands.getTemplatesCmd TemplatesFetched FetchError ]

let initOverview userData : Model * Cmd<Msg> =
      { initialModel userData with TemplateRequestState = RequestState.Active } , Cmd.batch [ Commands.getTemplatesCmd TemplatesFetched FetchError ]

let saveTemplateGroupCmd form =
    Cmd.OfPromise.either Communication.postRequest<PostResponse<SaveTemplateResult>> ("/api/templates", form) TemplateGroupSaved FetchError

let updateTemplateCmd templateId (updateElement : TemplateGroupUpdate) =
    let (TemplateId templateId) = templateId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<SaveTemplateResult>>
                             ((sprintf "/api/templates/%s" (templateId.ToString())), body)
                             TemplateGroupUpdated FetchError

let toActionList (action : (Msg -> 'a) -> unit) =
    let actionList = [action]
    actionList

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | SetFilter filter ->
        { model with FilterText = filter }, Cmd.OfAsync.result (SharedComponents.SearchBar.filter<Template, Msg> filter model.Filter Filtered)
    | Filtered filterModel ->
        { model with Filter = filterModel }, Cmd.ofMsg ApplySelectFilter
    | SetSelectFilters filters ->
        let newFilters = filters |> Array.toList |> List.map (fun o -> o.value)
        { model with SelectedFilters = newFilters }, Cmd.ofMsg ApplySelectFilter
    | ApplySelectFilter ->
        let filteredTemplates =
            if model.SelectedFilters.IsEmpty then model.Filter.FilteredElements else
            model.Filter.FilteredElements
            |> List.filter (fun x ->
                            match x.ChildTemplates with
                            | Some _ -> model.SelectedFilters |> List.contains (SelectFilterType.TemplateGroupFilter)
                            | None -> model.SelectedFilters |> List.contains (SelectFilterType.TemplateFilter))
        { model with SelectFilterTemplates = filteredTemplates }, Cmd.none
    // Form actions
    | EditBaseInformation ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditBaseData
                            Template = form.TemplateSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditNewAttribute ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditNewAttribute []
                            Template = form.TemplateSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditAttribute attributeId ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Attribute attributeId
                            Template = form.TemplateSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Nothing
                            Template = form.TemplateSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    | SetTemplateName name ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New newOf ->
                match newOf with
                | Template form ->
                    { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Name = name } } }
                    |> NewFormType.Template |> FormState.New
                | TemplateGroup form ->
                    { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Name = name } } }
                    |> NewFormType.TemplateGroup |> FormState.New
            | Edit form ->
                { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Name = name } } }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | SetTemplateDescription description ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New newOf ->
                match newOf with
                | Template form ->
                    { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Description = description } } }
                    |> NewFormType.Template |> FormState.New
                | TemplateGroup form ->
                    { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Description = description } } }
                    |> NewFormType.TemplateGroup |> FormState.New
            | Edit form ->
                { form with Template = { form.Template with TemplateBaseInformation = { form.Template.TemplateBaseInformation with Description = description } } }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    | AddAttribute ->
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let newAttribute = { Id = System.Guid.NewGuid () |> AttributeId; Name = ""; Type = Integer; DisplayOnPrint = false }
                    { form with Template = { form.Template with Attributes = newAttribute :: form.Template.Attributes } }
                    |> Template|> FormState.New
                | TemplateGroup form ->
                    let newAttribute = { Id = System.Guid.NewGuid () |> AttributeId; Name = ""; Type = Integer; DisplayOnPrint = false }
                    { form with Template = { form.Template with Attributes = newAttribute :: form.Template.Attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute attributes ->
                    let newAttribute = { Id = System.Guid.NewGuid () |> AttributeId; Name = ""; Type = OptionalInteger; DisplayOnPrint = false }
                    { form with Template = { form.Template with Attributes = newAttribute :: form.Template.Attributes }
                                EditField = EditField.EditNewAttribute (newAttribute :: attributes) }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _
                | EditField.Attribute _ -> form |> Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none
    | SetAttributeName (index, name) ->
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Name = name } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> Template |> FormState.New
                | TemplateGroup form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Name = name } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute newAttributes ->
                    let updatedElement = form.Template.Attributes.[index]
                    let updatedNewAttributes =
                        newAttributes
                        |> List.map (fun a ->
                            if a.Id = updatedElement.Id then { a with Name = name }
                            else a)
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Name = name } else a)
                    { form with Template = { form.Template with Attributes = attributes }
                                EditField = EditField.EditNewAttribute updatedNewAttributes }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> form |> Edit
                | EditField.Attribute _ ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Name = name } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> FormState.Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none
    | SetAttributeType (index, attributeType) ->
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Type = attributeType } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> Template |> FormState.New
                | TemplateGroup form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Type = attributeType } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute newAttributes ->
                    let attributeType =
                        match attributeType with
                        | AttributeType.Boolean -> AttributeType.OptionalBoolean
                        | AttributeType.Integer -> AttributeType.OptionalInteger
                        | AttributeType.Decimal -> AttributeType.OptionalDecimal
                        | AttributeType.String -> AttributeType.OptionalString
                        | AttributeType.Date ->  AttributeType.OptionalDate
                        | AttributeType.DateWithReminder -> AttributeType.OptionalDateWithReminder
                        | AttributeType.OptionalInteger -> attributeType
                        | AttributeType.OptionalDecimal -> attributeType
                        | AttributeType.OptionalString -> attributeType
                        | AttributeType.OptionalBoolean -> attributeType
                        | AttributeType.OptionalDate -> attributeType
                        | AttributeType.OptionalDateWithReminder -> attributeType
                    let updatedElement = form.Template.Attributes.[index]
                    let updatedNewAttributes =
                        newAttributes
                        |> List.map (fun a ->
                            if a.Id = updatedElement.Id then { a with Type = attributeType }
                            else a)
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Type = attributeType } else a)
                    { form with Template = { form.Template with Attributes = attributes }
                                EditField = EditField.EditNewAttribute updatedNewAttributes }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> form |> Edit
                | EditField.Attribute _ ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with Type = attributeType } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> FormState.Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none
    | SetAttributeDisplayOnPrint (index, displayOnPrint) ->
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with DisplayOnPrint = displayOnPrint } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> Template |> FormState.New
                | TemplateGroup form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with DisplayOnPrint = displayOnPrint } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute newAttributes ->
                    let updatedElement = form.Template.Attributes.[index]
                    let updatedNewAttributes =
                        newAttributes
                        |> List.map (fun a ->
                            if a.Id = updatedElement.Id then { a with DisplayOnPrint = displayOnPrint }
                            else a)
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with DisplayOnPrint = displayOnPrint } else a)
                    { form with Template = { form.Template with Attributes = attributes }
                                EditField = EditField.EditNewAttribute updatedNewAttributes }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> form |> Edit
                | EditField.Attribute _ ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then { a with DisplayOnPrint = displayOnPrint } else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> FormState.Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none
    | SetAttributeOptional (index, isChecked) ->
        let updateAttributeType a =
            if isChecked then
                { a with Attribute.Type = Shared.Entity.Helper.toAttributeType a.Type }
            else
                { a with Attribute.Type = Shared.Entity.Helper.toOptionalAttributeType a.Type }
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a ->if i = index then updateAttributeType a else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> Template |> FormState.New
                | TemplateGroup form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a ->if i = index then updateAttributeType a else a)
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form -> form |> FormState.Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none
    | RemoveAttribute index ->
        let formState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i <> index then Some a else None)
                        |> List.choose id
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> Template |> FormState.New
                | TemplateGroup form ->
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i <> index then Some a else None)
                        |> List.choose id
                    { form with Template = { form.Template with Attributes = attributes } }
                    |> TemplateGroup |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute newAttributes ->
                    let removedElementId =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i = index then Some a else None)
                        |> List.choose id
                        |> List.head
                        |> (fun a -> a.Id)
                    let attributes =
                        form.Template.Attributes
                        |> List.mapi (fun i a -> if i <> index then Some a else None)
                        |> List.choose id
                    { form with Template = { form.Template with Attributes = attributes }
                                EditField = EditField.EditNewAttribute (newAttributes |> List.filter (fun a -> a.Id <> removedElementId)) }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _
                | EditField.Attribute _ -> form |> Edit
            | Loading -> FormState.Loading
        { model with FormState = formState }, Cmd.none

    | AddReferencedTemplate ->
        let newFormState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form -> form |> Template |> FormState.New
                | TemplateGroup form ->
                    { form with NewReferencedTemplateRow = true }
                    |> TemplateGroup |> FormState.New
            | Edit templateGroup -> FormState.Edit templateGroup
            | Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SelectReferencedTemplate (oldTemplate, templateOption) ->
        match templateOption with
        | Some templateOption ->
            let template = templateOption.value
            let newFormState =
                match model.FormState with
                | New newOf ->
                    match newOf with
                    | Template form -> form |> Template |> FormState.New
                    | TemplateGroup form ->
                        let referencedTemplate =
                            { TemplateId = template.Id
                              Min = 1
                              Max = 1 }
                        let selectableTemplates =
                            match oldTemplate with
                            | Some oldTemplate ->
                                let oldTemplateObject = model.Templates |> List.find (fun t -> t.Id = oldTemplate.TemplateId)
                                oldTemplateObject :: form.SelectableTemplates |> List.filter (fun t -> t.Id <> template.Id)
                            | None ->
                                form.SelectableTemplates |> List.filter (fun t -> t.Id <> template.Id)
                        let childTemplates =
                            match form.Template.ChildTemplates with
                            | Some children ->
                                match oldTemplate with
                                | Some oldTemplate ->
                                    let childTemplates = children.Templates |> List.filter (fun t -> t.TemplateId <> oldTemplate.TemplateId)
                                    { ChildTemplates.Templates = referencedTemplate :: childTemplates } |> Some
                                | None ->
                                    { ChildTemplates.Templates = referencedTemplate :: children.Templates } |> Some
                            | None -> { ChildTemplates.Templates = [ referencedTemplate ] } |> Some
                        { form with NewReferencedTemplateRow = false
                                    SelectableTemplates = selectableTemplates
                                    Template = { form.Template with ChildTemplates = childTemplates } }
                        |> TemplateGroup |> FormState.New
                | Edit templateGroup -> FormState.Edit templateGroup
                | Loading -> FormState.Loading
            { model with FormState = newFormState }, Cmd.none
        | None ->
            model, Cmd.none
    | SetReferencedTemplateMin (templateId, min) ->
        let newFormState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form -> form |> Template |> FormState.New
                | TemplateGroup form ->
                    let childTemplates =
                        match form.Template.ChildTemplates with
                        | Some children ->
                            let templates =
                                children.Templates |> List.map (fun rt ->
                                    if rt.TemplateId = templateId then
                                        if min > rt.Max then
                                            { rt with Min = min; Max = min }
                                        else
                                            { rt with Min = min }
                                    else rt)
                            { children with Templates = templates } |> Some
                        | None -> None
                    { form with Template =
                                { form.Template with ChildTemplates = childTemplates } }
                    |> TemplateGroup |> FormState.New
            | Edit templateGroup -> FormState.Edit templateGroup
            | Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetReferencedTemplateMax (templateId, max) ->
        let newFormState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form -> form |> Template |> FormState.New
                | TemplateGroup form ->
                    let childTemplates =
                        match form.Template.ChildTemplates with
                        | Some children ->
                            let templates =
                                children.Templates |> List.map (fun rt ->
                                    if rt.TemplateId = templateId then
                                        if rt.Min > max then
                                            { rt with Min = max; Max = max }
                                        else
                                            { rt with Max = max }
                                    else rt)
                            { children with Templates = templates } |> Some
                        | None -> None
                    { form with Template =
                                { form.Template with ChildTemplates = childTemplates } }
                    |> TemplateGroup |> FormState.New
            | Edit templateGroup -> FormState.Edit templateGroup
            | Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | RemoveReferencedTemplate templateId ->
        let newFormState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form -> form |> Template |> FormState.New
                | TemplateGroup form ->
                    let template = model.Templates |> List.find (fun t -> t.Id = templateId)
                    let childTemplates =
                        match form.Template.ChildTemplates with
                        | Some children ->
                            let templates =
                                children.Templates |> List.filter (fun rt -> rt.TemplateId <> templateId)
                            { children with Templates = templates } |> Some
                        | None -> None
                    { form with SelectableTemplates = template :: form.SelectableTemplates
                                Template =
                                    { form.Template with ChildTemplates = childTemplates } }
                    |> TemplateGroup |> FormState.New
            | Edit templateGroup -> FormState.Edit templateGroup
            | Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Delete actions
    | AbortAttributeDelete ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> Loading
            | New form -> form |> New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute _
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> form |> Edit
                | EditField.Attribute _ -> { form with DeleteAttributeRequested = None } |> Edit
        { model with FormState = newFormState }, Cmd.none
    | OpenDeleteAttributeModal ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> Loading
            | New form -> form |> New
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute _
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> form |> Edit
                | EditField.Attribute attributeId -> { form with DeleteAttributeRequested = Some attributeId } |> Edit
        { model with FormState = newFormState }, Cmd.none
    | DeleteAttributeRequest ->
        let newModel, newCmd =
            match model.FormState with
            | FormState.Loading _
            | New _ -> model, Cmd.none
            | Edit form ->
                match form.EditField with
                | EditField.EditNewAttribute _
                | EditField.Nothing
                | EditField.ReferencedTemplate _
                | EditField.EditBaseData _ -> model, Cmd.none
                | EditField.Attribute attributeId ->
                    { model with TemplateRequestState = RequestState.Active },
                    attributeId
                    |> TemplateGroupUpdate.DeleteAttribute
                    |> updateTemplateCmd form.Template.Id
        newModel, newCmd
    | SaveTemplateGroup ->
        let validation =
                match model.FormState with
                | New newOf ->
                    match newOf with
                    | Template form -> validateNewForm form.Template
                    | TemplateGroup form -> validateNewForm form.Template
                | Edit form ->
                    match form.EditField with
                    | EditField.Attribute _ -> validateEditAttributesForm form
                    | EditField.EditBaseData -> validateEditNameForm form
                    | EditField.EditNewAttribute _ -> validateEditAttributesForm form
                    | EditField.ReferencedTemplate _ -> failwith "not implemented"
                    | EditField.Nothing -> notValid
                | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let cmd =
                    match model.FormState with
                    | New newOf ->
                        match newOf with
                        | Template form ->
                            form.Template
                            |> Encode.toString 0
                            |> saveTemplateGroupCmd
                        | TemplateGroup form ->
                            form.Template
                            |> Encode.toString 0
                            |> saveTemplateGroupCmd
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditBaseData ->
                            form.Template.TemplateBaseInformation
                            |> TemplateGroupUpdate.BaseInformation
                            |> updateTemplateCmd form.Template.Id
                        | EditField.Attribute id ->
                            match form.Template.Attributes |> List.tryFind (fun a -> a.Id = id) with
                            | Some attribute ->
                                attribute
                                |> TemplateGroupUpdate.Attibute
                                |> updateTemplateCmd form.Template.Id
                            | None -> Cmd.none
                        | EditField.EditNewAttribute attributes ->
                            attributes
                            |> TemplateGroupUpdate.NewAttributes
                            |> updateTemplateCmd form.Template.Id
                        | EditField.ReferencedTemplate templateId -> failwith "not implemented"
                        | EditField.Nothing -> Cmd.none
                    | FormState.Loading -> Cmd.none
                { model with SaveState = SaveState.Saving
                             TemplateRequestState = RequestState.Active }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, warningToast
                | FormState.Loading -> model, Cmd.none
                | New newOf ->
                    match newOf with
                    | Template form ->
                        let newFormState = { form with FormValidation = Some validation } |> Template |> New
                        { model with FormState = newFormState }, warningToast
                    | TemplateGroup form ->
                        let newFormState = { form with FormValidation = Some validation } |> TemplateGroup |> New
                        { model with FormState = newFormState }, warningToast
        newModel, cmd
    | TemplateGroupSaved response ->
        let model, cmd =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form ->
                    match response.Result with
                    | SaveTemplateResult.Saved (TemplateId templateId) ->
                        model, Cmd.batch [ templateId |> Page.TemplatesViewForm |> Routes.toPath |> Navigation.newUrl; toast (ToastType.Success "Geräte-Gruppe erfolgreich angelegt.")]
                    | InvalidName ->
                        { model with FormState = { form with FormValidation = Some (validateTemplateName ()) } |> Template |> FormState.New }, toast (ToastType.Warning "Bitte überprüfe das Formular.")
                | TemplateGroup form ->
                    match response.Result with
                    | SaveTemplateResult.Saved (TemplateId templateId) ->
                        model, Cmd.batch [ templateId |> Page.TemplateGroupViewForm |> Routes.toPath |> Navigation.newUrl; toast (ToastType.Success "Geräte-Gruppe erfolgreich angelegt.")]
                    | InvalidName ->
                        { model with FormState = { form with FormValidation = Some (validateTemplateName ()) } |> TemplateGroup |> FormState.New }, toast (ToastType.Warning "Bitte überprüfe das Formular.")
            | Edit _
            | FormState.Loading -> model, Cmd.none
        { model with SaveState = SaveState.Nothing
                     TemplateRequestState = RequestState.NotActive }, cmd

    | TemplateGroupUpdated response ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                match response.Result with
                | SaveTemplateResult.Saved templateId ->
                    { model with FormState = { form with TemplateSnapshot = form.Template } |> FormState.Edit },
                    Cmd.batch [ Commands.getTemplateCmd templateId TemplateFetched FetchError; toast (ToastType.Success "Geräte erfolgreich aktualisiert.")]
                | InvalidName ->
                    { model with FormState = { form with FormValidation = Some (validateTemplateName ()) } |> FormState.Edit }, Cmd.none
        { model with SaveState = Nothing
                     TemplateRequestState = RequestState.NotActive }, cmd

    // File upload
    | FromFile (action, target) ->
        match model.FormState with
        | Loading -> model, Cmd.none
        | New _ -> model, Cmd.none
        | Edit form ->
            let name : string = target?files?(0)?name
            let file = target?files?item(0)
            let documentUploadForm =
                match model.DocumentUploadForm with
                | Some duf -> { duf with Name = name }
                | None -> { File = file
                            Name = name
                            TemplateId = form.Template.Id
                            Description = ""
                            Target = Some target }
            let delayedAction newAction =
                let fileReader = createNew FileReader "" :?> FileReader
                fileReader?onloadend <- (fun () -> newAction <| action !!fileReader?result)
                fileReader?readAsBinaryString target?files?(0) |> ignore
            { model with DocumentUploadForm = Some documentUploadForm }, delayedAction |> toActionList
    | SetField content ->
        model, Cmd.none
    // Upload image
    | TemplateGroupImageFetched response ->
        let newFormState =
            match model.FormState with
            | Loading -> model.FormState
            | New _ -> model.FormState
            | Edit form ->
                { form with TemplateGroupImage = Some response }
                |> Edit
        { model with FormState = newFormState }, Cmd.none

    | Msg.FetchTemplate ->
        let newCmd =
            match model.FormState with
            | Loading -> Cmd.none
            | New _ -> Cmd.none
            | Edit form -> Commands.getTemplateCmd form.Template.Id TemplateFetched FetchError
        model, newCmd

    // Requests
    | TemplatesFetched response ->
        let newFormState =
            match model.FormState with
            | New newOf ->
                match newOf with
                | Template form -> form |> Template |> FormState.New
                | TemplateGroup form ->
                    let selectableTemplates =
                        response.Templates
                        |> List.filter (fun t -> t.ChildTemplates.IsNone)
                    { form with SelectableTemplates = selectableTemplates }
                    |> TemplateGroup |> FormState.New
            | Edit templateGroup -> FormState.Edit templateGroup
            | Loading -> FormState.Loading
        { model with Templates = response.Templates
                     FormState = newFormState
                     Filter = SharedComponents.SearchBar.init response.Templates
                     SelectFilterTemplates = response.Templates
                     TemplateRequestState = RequestState.NotActive }, Cmd.none
    | TemplateFetched response ->
        let cmd =
            match response.Image with
            | Some image -> Commands.getImage image.Id TemplateGroupImageFetched FetchError
            | None -> Cmd.none
        let editForm =
            { Template = response
              EditField = EditField.Nothing
              TemplateSnapshot = response
              FormValidation = None
              DeleteAttributeRequested = None
              TemplateGroupImage = None }
        let newFormState =
            match model.FormState with
            | Loading -> FormState.Edit editForm
            | New form -> FormState.New form
            | Edit _ -> FormState.Edit editForm
        { model with FormState = newFormState }, cmd

    // File
    | UploadedDocument ->
        let cmd =
            match model.FormState with
            | Loading -> Cmd.none
            | New _ -> Cmd.none
            | Edit form -> Client.Commands.getTemplateCmd form.Template.Id TemplateFetched FetchError
        model, cmd
    | DownloadDocument (documentId, filename) ->
        { model with TemplateRequestState = RequestState.Active }, Cmd.batch [ Commands.downloadFileCmd documentId filename DocumentFetched FetchError; toast (ToastType.Info "Dokument wird heruntergeladen") ]
    | DocumentFetched (response, filename) ->
        { model with TemplateRequestState = RequestState.NotActive }, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none

    | FetchError e ->
        { model with TemplateRequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
