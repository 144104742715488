module Order.FreeOrder.View


open Fable.React
open Fable.React.Props
open Order.FreeOrder.Types
open Order.FreeOrder.Helper
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.Types.NaturalOrder
open Shared.TaskTypes
open SharedComponents.Breadcrumb
open Routes
open SharedComponents.Spinners
open Helper.Table
open Shared.Address
open System
open Shared.FreeOrder
open Validation
open SharedComponents.Badges

let private basePart isCreateOrEditable plannedExecutionDate model dispatch formState =
    match model.FormState with
    | FormState.New form ->
        div [ ]
          [ subHeaderForm "Allgemein"
            ReactDatepicker.Datepicker.datepicker(
                Some plannedExecutionDate,
                (fun date -> dispatch (SetPlannedExecutionDate date)),
                labelName = "Geplantes Ausführungsdatum",
                minDate = System.DateTime.UtcNow.Date,
                isDisabled = false) ]
    | FormState.Edit form ->
        div [ ]
          [ if not isCreateOrEditable || form.FreeOrder.State <> FreeOrderState.Undisposed then
                subHeaderForm "Allgemein"
            else
              subHeaderByFormState (match form.EditField with | EditField.EditPlannedExecutionDate _ -> true | _ -> false)
                "Allgemein"
                (fun _ -> EditPlannedExecutionDate |> dispatch) ""
                (fun _ -> SaveFreeOrder |> dispatch) ""
                (fun _ -> EndEdit |> dispatch) ""
                formState
              ReactDatepicker.Datepicker.datepicker(
                  Some plannedExecutionDate,
                  (fun date -> dispatch (SetPlannedExecutionDate date)),
                  labelName = "Geplantes Ausführungsdatum",
                  minDate = System.DateTime.UtcNow.Date,
                  isDisabled = (match form.EditField with | EditField.EditPlannedExecutionDate _ -> false | _ -> true)) ]
    | FormState.Loading ->
        Fable.React.Helpers.nothing

let private addressPart (freeOrder : FreeOrder) (validation : FormValidation option) isDisabled dispatch =
    let executionLocation = freeOrder.ExecutionAddress
    div [ ]
      [ Form.Input.inlineInput((fun ev -> dispatch (SetCompany ev)), executionLocation.CompanyName |> Option.defaultValue "",
                                inputLabel = "Firma", validation = (validation, "company"), cyPostFix = "company", isDisabled=isDisabled)
        Form.Input.inlineInput((fun ev -> dispatch (SetContactPerson ev)), executionLocation.ContactPerson |> Option.defaultValue "",
                                 inputLabel = "Ansprechpartner", validation = (validation, "contact-person"), cyPostFix = "contact-person", isDisabled=isDisabled)
        Form.Input.inlineInput((fun ev -> dispatch (SetStreet ev)), executionLocation.Street,
                                inputLabel = "Straße", validation = (validation, "street"), required = true, cyPostFix = "street", isDisabled=isDisabled)
        Form.Input.inlineInput((fun ev -> dispatch (SetHouseNumber ev)), executionLocation.HouseNumber,
                                 inputLabel = "Hausnummer", cyPostFix = "housenumber", isDisabled=isDisabled)
        Form.Input.inlineInput((fun ev -> dispatch (SetZipCode ev)), executionLocation.ZipCode,
                                 inputLabel = "PLZ", cyPostFix = "zipcode", isDisabled=isDisabled)
        Form.Input.inlineInput((fun ev -> dispatch (SetCity ev)), executionLocation.City,
                               inputLabel = "Stadt", validation = (validation, "city"), required = true, cyPostFix = "city", isDisabled=isDisabled)]

let private editForm (form : FreeOrderEditForm) (model : Model) (dispatch : Msg -> unit) isCreateOrEditable (formState : SharedComponents.Types.FormState) =
    let isInfoDisabled = (match form.EditField with | EditField.EditInformation _ -> false | _ -> true)
    let isAddressDisabled = (match form.EditField with | EditField.EditAddress _ -> false | _ -> true)
    div [ ]
        [ div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ if not isCreateOrEditable || form.FreeOrder.State <> FreeOrderState.Undisposed then
                    div [] [
                      subHeaderForm "Adresse"
                      addressPart form.FreeOrder form.FormValidation isAddressDisabled dispatch
                    ]
                  else
                    subHeaderByFormState (match form.EditField with | EditField.EditAddress _ -> true | _ -> false)
                      "Adresse"
                      (fun _ -> EditAddress |> dispatch) ""
                      (fun _ -> SaveFreeOrder |> dispatch) ""
                      (fun _ -> EndEdit |> dispatch) ""
                      formState
                    addressPart form.FreeOrder form.FormValidation isAddressDisabled dispatch ]
              div [ Class "flex-grow-1"]
                [ basePart isCreateOrEditable form.FreeOrder.PlannedExecutionDate model dispatch formState ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ if not isCreateOrEditable || form.FreeOrder.State <> FreeOrderState.Undisposed then
                      subHeaderForm "Informationen"
                  else
                    subHeaderByFormState (match form.EditField with | EditField.EditInformation _ -> true | _ -> false)
                      "Informationen"
                      (fun _ -> EditInformation |> dispatch) ""
                      (fun _ -> SaveFreeOrder |> dispatch) ""
                      (fun _ -> EndEdit |> dispatch) ""
                      formState
                  Form.Input.inlineInput((fun ev -> dispatch (SetTitle ev)), form.FreeOrder.Title,
                                         inputLabel = "Title", isDisabled = isInfoDisabled, cyPostFix = "title",
                                         validation = (form.FormValidation, "title"), required = true)
                  Form.textArea (fun ev -> dispatch (SetDescription ev)) form.FreeOrder.Description "Beschreibung" isInfoDisabled "description" ] ]
          ]

let private newForm (form : FreeOrderNewForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    div [ ]
        [ div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ subHeaderForm "Adresse"
                  addressPart form.FreeOrder form.FormValidation false dispatch ]
              div [ Class "flex-grow-1"]
                [ basePart true form.FreeOrder.PlannedExecutionDate model dispatch formState ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ subHeaderForm "Informationen"
                  Form.Input.inlineInput((fun ev -> dispatch (SetTitle ev)), form.FreeOrder.Title,
                                         inputLabel = "Titel", cyPostFix = "title",
                                         validation = (form.FormValidation, "title"), required = true)
                  Form.textArea (fun ev -> dispatch (SetDescription ev)) form.FreeOrder.Description "Beschreibung" false "description"  ] ]
          div [ Class "controls d-flex" ]
            [ Buttons.primaryButtonWithFnct (fun _ -> SaveFreeOrder |> dispatch ) "Auftrag anlegen" "ml-auto" ] ]

let formBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.FreeOrderOverview |> Routes.toPath) "Eigene Aufträge"
        breadcrumbStr "Eigener Auftrag"
    ]

let newFreeOrderFormView (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ formBreadcrumb
          overlaySpinner model.RequestState
          div [ Id "order-content" ]
            [ mainHeader "Eigener Auftrag anlegen"
              div [ ]
                [ (match model.FormState with
                  | New form -> newForm form model dispatch SharedComponents.Types.New
                  | Edit form -> failwith "wrong formstate"
                  | FormState.Loading -> Spinners.overlaySpinner SharedComponents.Spinners.RequestState.Active ) ] ] ]

let private technicanName (technicans : Employee list) technicanId =
    technicans
    |> List.tryFind (fun d -> d.Id = technicanId)
    |> (function
        | Some d -> sprintf "%s %s" d.Firstname d.Lastname
        | None -> "")

let private taskDispositionRow (form : FreeOrderEditForm) (task : FreeOrderTask) =
    let repairBadge = (badgePill "Eigene Aufgabe" BadgeClassType.Light)
    let executionDate (assignedTask : AssignedFreeTask) =
      match assignedTask.TaskReport with
      | Some r -> r.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm")
      | None -> assignedTask.ScheduledExecutionDate.ToLocalTime().ToShortDateString()
    let (taskBadge, stateBadge, showDetails), TaskId taskId, FreeOrderId freeOrderId, technicanName, deliveryDate =
        match task with
        | FreeOrderTask.FreeTask t ->
            (repairBadge, (badgePill "offen" BadgeClassType.Info), false), t.TaskId, t.FreeOrderId, "", ""
        | FreeOrderTask.AssignedFreeTask t ->
            (match t.State with
            | FreeTaskState.Planned -> repairBadge, (badgePill "eingelant" BadgeClassType.Info), false
            | FreeTaskState.Executing -> repairBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
            | FreeTaskState.Finished ->
              repairBadge, (badgePill "ausgeführt" BadgeClassType.Info), true), t.TaskId, t.FreeOrderId, technicanName form.Employees t.UserId, executionDate t

    tr [ Key (taskId |> string) ]
        [ td [ ]
            [ taskBadge ]
          td [ ]
            [ str deliveryDate ]
          td [ ]
            [ str technicanName ]
          td [ ]
            [ stateBadge ]
          td [ ]
            [ (if showDetails then
                a [ Href (Routes.toPath (Routes.Page.FreeOrderDispositionDetail (freeOrderId, taskId))) ]
                    [ str "Details"]
               else Fable.React.Helpers.nothing) ] ]

let private taskDispositionTable (form : FreeOrderEditForm) =
    div [ Class "table-responsive" ]
      [ table [ Class "table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                      [ str "Aufgabe" ]
                    th [ ]
                      [ str "Datum" ]
                    th [ ]
                      [ str "Ausführer" ]
                    th [ ]
                      [ str "Status" ]
                    th [ ]
                      [ str "" ] ] ]
            tbody [ ]
              [ form.FreeOrder.Tasks
                |> List.map (taskDispositionRow form)
                |> ofList ] ] ]

let detailView orderId (model : Model) (dispatch : Msg -> unit) =
    let isCreateOrEditable = isFreeOrderEditableOrCreatable model.UserData
    let openDeleteCmd =
      match model.FormState with
      | Edit form ->
          if form.FreeOrder.State = FreeOrderState.Undisposed then (Some (fun _ -> dispatch RequestDelete))
          else None
      | New form -> None
      | FormState.Loading -> None
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ SharedComponents.Spinners.overlaySpinner model.RequestState
          formBreadcrumb
          section [ ]
            [ deleteModal model.FormState dispatch ]
          match model.FormState with
          | FormState.Edit form ->
              div [ Id "order-content" ]
                [ if isCreateOrEditable then
                    mainHeaderBar None "Eigener Auftrag" None openDeleteCmd
                  else mainHeader "Eigener Auftrag"
                  div [ ]
                    [ editForm form model dispatch isCreateOrEditable SharedComponents.Types.FormState.View
                      subHeaderForm "Disposition"
                      taskDispositionTable form ] ]
          | FormState.New form -> failwith "wrong formstate"
          | FormState.Loading -> SharedComponents.Spinners.overlaySpinner SharedComponents.Spinners.RequestState.Active ]

open Sww.Frontend.ReactTable

let private orderTable (model : Model) dispatch =
    reactTable [
        tableProp.keyField "Id"
        tableProp.defaultSortField ("Name", "asc")
        tableProp.rows model.FreeOrders
        tableProp.tableExportName "eigene_auftraege_export.csv"
        tableProp.tableKey "freeorders"
        tableProp.columns [
            tableColumn [
                colProp.selector "Title"
                colProp.label "Titel"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "ExecutionAddress.City"
                colProp.label "Stadt"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "PlannedExecutionDate"
                colProp.label "Geplantes Ausführungsdatum"
                colProp.sortFunction (DateSort (fun order -> order.PlannedExecutionDate))
                colProp.filter TextFilter
                colProp.formatter (Formatter.Date (fun order -> order.PlannedExecutionDate))
            ]
            tableColumn [
                colProp.selector "State"
                colProp.label "Status"
                colProp.sortFunction (CustomNaturalSort (fun order -> order.State |> Helper.stateToString))
                colProp.filter (SelectFilter(selectOptions, (fun data -> data.State |> Helper.stateToString)))
                colProp.formatter (Custom (fun value order -> stateBadge order))
                colProp.csvFormatter (fun _ data -> data.State |> Helper.stateToString |> str)
            ]
            tableColumn [
                colProp.selector "Aktion"
                colProp.label "Aktion"
                colProp.formatter (Custom (fun value order -> detailBtn order))
                colProp.csvPrint false
            ]
        ]
    ]

let overviewBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbStr "Eigeneaufträge"
    ]

let view (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          overviewBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Eigeneaufträge"
              div [ Class "controls d-flex justify-content-end mb-2" ]
                [ if isFreeOrderEditableOrCreatable model.UserData then
                      div [ Class "" ]
                        [ Buttons.primaryButton (Routes.toPath Routes.Page.FreeOrderNewForm) "Auftrag anlegen" "ml-auto"]
                  else Fable.React.Helpers.nothing ]
              orderTable model dispatch
              div [ Class "controls d-flex justify-content-end" ]
                  [ div [ Class "" ]
                      [ Buttons.primaryButton (Routes.toPath (Page.FreeOrderOverviewCompleted)) "Archiv" "ml-auto btn-sm" ] ]  ] ]

let overviewCompletedBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.FreeOrderOverview |> Routes.toPath) "Serviceaufträge"
        breadcrumbStr "Archiv"
    ]

let viewFreeOrdersCompleted (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          overviewCompletedBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Eigeneaufträge"
              orderTable model dispatch ] ]

