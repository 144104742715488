module TemplateConfiguration.DocumentDeleteDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Shared.Entity

type Props =
    { TemplateId : TemplateId
      DocumentId : DocumentId
      SuccessCallback : unit -> unit }

let private deleteDocumentCmd (templateId : TemplateId) documentId =
    let docId = documentId |> Helper.unwrapDocumentId
    let templateId = templateId |> Helper.unwrapTemplateId
    let url =  sprintf "/api/templates/%s/document/%s/delete" templateId docId
    Communication.deleteRequest<Shared.EmptyResponse> url

let private dialogBody props =
    Html.div [
        prop.className "form-group"
        prop.children [
            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.children [ Html.text "Willst du das Dokument wirklich löschen?" ]
            ]
            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnct
                        (fun _ ->
                            deleteDocumentCmd props.TemplateId props.DocumentId
                            |> Promise.iter (fun res ->
                                Swal.close (SweetAlert.Result.Value ()))
                        )
                        "Löschen" "mr-3"
                    Buttons.secondaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" false ""
                ]
            ]
        ]
    ]

let private documentDeleteDialog' = React.functionComponent(fun props ->
    Buttons.iconButton "fas fa-times"
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody props)
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value _ ->
                        props.SuccessCallback ()
                        Swal.Simple.success "Dokument wurde erfolgreich gelöscht"
                    | SweetAlert.Result.Dismissal d ->
                        ()
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
    )

let documentDeleteDialog (templateId : TemplateId) (documentId : DocumentId) successCallback =
    documentDeleteDialog'
        { TemplateId = templateId
          DocumentId = documentId
          SuccessCallback = successCallback }
