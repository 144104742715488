module MasterData.Entities.NotAvailableRangeEditDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Shared.Entity
open SharedComponents.Toast
open SharedComponents.Headers
open Feliz.ElmishComponents

type Msg =
    | SetStartDate of System.DateTime
    | SetDescription of string
    | SetEndDate of System.DateTime
    | ClearEndDate
    | EntityUpdated of PostResponse<UpdateEntityResult>
    | UpdateEntity of NotAvailableRange
    | FetchError of exn

let updateEntityCmd entityId (updateElement : EntityGroupUpdate) =
    let (EntityId entityId) = entityId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<UpdateEntityResult>>
                             ((sprintf "/api/entities/%s" (entityId.ToString())), body)
                             EntityUpdated FetchError
type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { NotAvailableRangeId : EntityNotAvailableRangeId
      EntityId : EntityId
      StartDate : System.DateTime
      EndDate : System.DateTime option
      Description : string
      SuccessCallback : unit -> unit }

let init successCallback entityId (notAvailableRange : NotAvailableRange)  : State * Cmd<Msg> =
    let initialModel =
        { NotAvailableRangeId = notAvailableRange.Id
          EntityId = entityId
          StartDate = notAvailableRange.StartDate
          EndDate = notAvailableRange.EndDate
          Description = notAvailableRange.Description
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | SetStartDate startDate ->
        { state with StartDate = startDate }, Cmd.none
    | SetDescription description ->
        { state with Description = description }, Cmd.none
    | SetEndDate endDate ->
        { state with EndDate = Some endDate }, Cmd.none
    | ClearEndDate ->
        { state with EndDate = None }, Cmd.none
    | UpdateEntity newNotAvailableRange ->
        let cmd =
            newNotAvailableRange
            |> EntityGroupUpdate.UpdateNotAvailableRange
            |> updateEntityCmd state.EntityId
        state, cmd
    | EntityUpdated response ->
        match response.Result with
        | UpdateEntityResult.Updated entityId ->
            state.SuccessCallback()
            Swal.close (SweetAlert.Result.Value ())
            state, toast (ToastType.Success "außer Betrieb Zeitraum wurde hinzugefügt")
        | UpdateEntityResult.AddNotAvailableRangeFailed addNotAvailableRangeFailed ->
            match addNotAvailableRangeFailed with
            | AddNotAvailableRangeFailed.OverlapsWithOtherNotAvailableRange _ ->
                state, toast (ToastType.Error "Es existiert bereits ein außer Betrieb Zeitraum die diesen Zeitraum beinhaltet.")
            | AddNotAvailableRangeFailed.OverlapsWithRentOrders _ ->
                state, toast (ToastType.Error "Es existiert ein Mietauftrag der diesen Zeitraum betrifft.")
        | UpdateEntityResult.EntityStateUpdated _
        | UpdateEntityResult.StateUpdatedFailedActiveEntityNameExists _
        | UpdateEntityResult.StateUpdatedFailed _
        | UpdateEntityResult.EntityNameAlreadyExists _ -> state, toast (ToastType.Success "Es ist etwas schief gelaufen.")
    | FetchError e ->
        state, Cmd.none

let body (state : State) dispatch =

    Html.div [
        prop.className "not-available-range-dialog"
        prop.children [
            subHeaderForm "außer Betrieb Zeitraum bearbeiten"

            Form.Input.inlineInput((fun ev -> ev |> SetDescription |> dispatch), state.Description,
               inputLabel = "Bezeichnung", placeholder="z.B. Wartung")

            Html.div [
                if state.EndDate.IsSome then
                    ReactDatepicker.Datepicker.datepicker(
                        Some state.StartDate,
                        (SetStartDate >> dispatch),
                        minDate=System.DateTime.UtcNow.Date,
                        labelName="Startdatum",
                        maxDate=state.EndDate.Value)
                else
                    ReactDatepicker.Datepicker.datepicker(
                        Some state.StartDate,
                        (SetStartDate >> dispatch),
                        labelName="Startdatum",
                        minDate=System.DateTime.UtcNow.Date)
            ]

            Html.div [
                ReactDatepicker.Datepicker.datepicker(
                    state.EndDate,
                    (SetEndDate >> dispatch),
                    labelName="Enddatum",
                    minDate=state.StartDate,
                    onClear = (fun _ -> dispatch ClearEndDate))
            ]

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnct
                        (fun _ ->
                            let notAvailableRange =
                                { Id = state.NotAvailableRangeId
                                  StartDate = state.StartDate
                                  EndDate = state.EndDate
                                  Description = state.Description }
                            notAvailableRange |> UpdateEntity |> dispatch
                        )
                        "Speichern" "mr-3"
                    Buttons.secondaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" false ""
                ]
            ]
        ]
    ]

let dialogBody successCallback (notAvailableRange : NotAvailableRange) entityId =
    React.elmishComponent("NotAvailableRangeEditDialog", init successCallback entityId notAvailableRange, update, body)

let render entityId (notAvailableRange : NotAvailableRange) successCallback =
    Buttons.iconButton "fas fa-pen"
        // (fun _ -> notAvailableRange.Id |> Msg.RemoveNotAvailableRange |> dispatch)
    // Buttons.dropDownButton
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody successCallback notAvailableRange entityId)
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value _ ->
                        successCallback ()
                        Swal.Simple.success "außer Betrieb Zeitraum wurde gelöscht"
                    | SweetAlert.Result.Dismissal d ->
                        ()
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
//    Buttons.dropDownButton
//        (fun _ ->
//            Swal.fire ([
//                swal.html (dialogBody successCallback entityId)
//                swal.showCancelButton false
//                swal.showConfirmButton false
//                ],
//                (function
//                    | SweetAlert.Result.Value _ ->
//                        successCallback ()
//                        Swal.Simple.success "außer Betrieb Zeitrum wurde gespeichert"
//                    | SweetAlert.Result.Dismissal d ->
//                        ()
//                    | SweetAlert.Result.Denied ->
//                        printfn "Denied"
//                )
//            )
//        )
//        false
//        "Gerät außer Betrieb setzen"

let notAvailableRangeEditDialog' =
    React.functionComponent(fun (props : {| entityId : EntityId
                                            notAvailableRange : NotAvailableRange
                                            successCallback : unit -> unit |}) -> render props.entityId props.notAvailableRange props.successCallback)

let notAvailableRangeEditDialog (entityId : EntityId) (notAvailableRange : NotAvailableRange) successCallback =
    notAvailableRangeEditDialog' {| entityId = entityId
                                    notAvailableRange = notAvailableRange
                                    successCallback = successCallback |}

