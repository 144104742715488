module SharedComponents.Badges

open Fable.React
open Fable.React.Props

type BadgeClassType =
    | Primary
    | Success
    | Danger
    | Secondary
    | Warning
    | Info
    | Light
    | Dark

let private badgeTypeClass currentBadgeClass =
    match currentBadgeClass with
    | Primary -> "badge-primary"
    | Success -> "badge-success"
    | Danger -> "badge-danger"
    | Secondary -> "badge-secondary"
    | Warning -> "badge-warning"
    | Info -> "badge-info"
    | Light -> "badge-light"
    | Dark -> "badge-dark"

let badgePill label badgeClassName =
    span [ Class (sprintf "badge badge-pill %s" (badgeTypeClass badgeClassName) ) ]
        [ str label ]