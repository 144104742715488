module Dispositions.View

open Fable.React
open Fable.React.Props
open Dispositions.State
open Dispositions.Types
open Routes
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.ReactSelect
open System
open SharedComponents.Buttons
open Shared.TaskTypes
open Shared.Address
open TaskTypes.Helper
open Dispositions.Helper
open SharedComponents.Badges
open Client.SharedComponents
open Feliz

let selectedClassName model taskId =
    match model.SelectedTaskCard with
    | Some (t, taskIds) ->
        if t = taskId then "task-element-selected-header"
        else
            if taskIds |> List.contains taskId then "task-element-selected-header" else ""
    | None -> ""

let tooltip tooltipText =
    Html.span [
        prop.className "task-tooltip"
        prop.children [
            Html.i [
                prop.custom ("data-tip", tooltipText)
                prop.className "fas fa-info-circle date-icon ml-1"
            ]
            ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
        ]
    ]

let private priorityBadge priority =
    let priority =
      match priority with
      | Low -> badgePill "niedrig" BadgeClassType.Info
      | Medium -> badgePill "mittel" BadgeClassType.Warning
      | High -> badgePill "hoch" BadgeClassType.Danger
    div [ ]
        [ br [ ]
          span [ ]
              [ str "Priorität: "
                priority ] ]

let private taskHeaderClassName (state : ClientTaskState) isPast =
    let taskHeaderClassName className =
        match state with
        | Planned _ -> if isPast then "task-element-header-open-past" else ""
        | Started _ -> sprintf "task-element-header-started %s" className
        | Finished _ -> sprintf "task-element-header-finished %s" className
    if isPast then taskHeaderClassName "greyedout"
    else taskHeaderClassName ""

let private entityName (model : Model) entityType =
    let entity =
        model.Entities
        |> List.tryFind (fun e ->
            match entityType with
            | EntityType.EntityId eId -> eId = e.Id
            | EntityType.CompoundEntityId comp -> comp.EntityId = e.Id)
    match entity with
    | Some entity -> [ str entity.Name; br [] ] |> ofList
    | None -> str ""

let private optionLabel (filterObjectType : FilterObjectType) =
    match filterObjectType with
    | FilterObjectType.FreeOrder -> "Eigene Aufgabe"
    | FilterObjectType.DeliveryOrder -> "Lieferungen"
    | FilterObjectType.ReturnOrder -> "Abholungen"
    | FilterObjectType.RepairOrder -> "Service"
    | FilterObjectType.PickOrder -> "Kommissionierung"
    | FilterObjectType.MechanicOrder -> "Montage"
    | FilterObjectType.All -> "Alle Auftragsarten"

let private taskFilterProps (model : Model) dispatch  =
    let options (currentFilterObjectType : FilterObjectType) =
        [ { label = optionLabel FilterObjectType.All
            value = FilterObjectType.All }
          { label = optionLabel FilterObjectType.FreeOrder
            value = FilterObjectType.FreeOrder }
          { label = optionLabel FilterObjectType.DeliveryOrder
            value = FilterObjectType.DeliveryOrder }
          { label = optionLabel FilterObjectType.ReturnOrder
            value = FilterObjectType.ReturnOrder }
          { label = optionLabel FilterObjectType.RepairOrder
            value = FilterObjectType.RepairOrder }
          { label = optionLabel FilterObjectType.PickOrder
            value = FilterObjectType.PickOrder }
          { label = optionLabel FilterObjectType.MechanicOrder
            value = FilterObjectType.MechanicOrder }  ]
        |> List.filter (fun f -> f.value <> currentFilterObjectType)
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Typ gefunden werden." ]

    let valueElement =
        match model.SelectedFilterObjectType with
        | FilterObjectType.ReturnOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.ReturnOrder; value = FilterObjectType.ReturnOrder }) |]
        | FilterObjectType.RepairOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.RepairOrder; value = RepairOrder }) |]
        | FilterObjectType.DeliveryOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.DeliveryOrder; value = DeliveryOrder }) |]
        | FilterObjectType.PickOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.PickOrder; value = PickOrder }) |]
        | FilterObjectType.MechanicOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.MechanicOrder; value = MechanicOrder }) |]
        | FilterObjectType.FreeOrder ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.FreeOrder; value = FreeOrder }) |]
        | FilterObjectType.All ->
            [| ReactSelect.CommonProps<FilterObjectType>.Value (Some { label = optionLabel FilterObjectType.All; value = All }) |]

    Array.append
        [| SharedComponents.ReactSelect.CommonProps<FilterObjectType>.Options (options model.SelectedFilterObjectType)
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.OnChange (fun e -> (SetFilterObjectType e) |> dispatch)
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.IsClearable false
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<FilterObjectType>.ClassName "disposition-select flex-grow-1" |]
        valueElement

let sidebarTaskElement dispatch (model : Model) (task : ClientUnassignedTask) =
    let toOrder  =
        let route =
            match task.OrderId with
            | OrderIdType.RentOrderId rentOrderId ->
                let (RentOrderId rentOrderId) = rentOrderId
                Page.RentOrderDetail rentOrderId
            | OrderIdType.ServiceOrderId serviceOrderId ->
                let (ServiceOrderId serviceOrderId) = serviceOrderId
                Page.RepairOrderDetail serviceOrderId
            | OrderIdType.FreeOrderId freeOrderId ->
                let (FreeOrderId freeOrderId) = freeOrderId
                Page.FreeOrderDetail freeOrderId
                
        a [ Href (Routes.toPath route ) ]
            [ p [ Class "small" ]
                [ str "Zum Auftrag"] ]
    let taskTitle =
        match task.TaskType with
        | TaskType.FreeTask -> "Eigene Aufgabe"
        | TaskType.DeliveryTask -> "Lieferung"
        | TaskType.ReturnTask -> "Abholung"
        | TaskType.ServiceTask -> "Service"
        | TaskType.PickTask -> "Kommissionierung"
    let selectedClassName = selectedClassName model task.Id
    let dragElement =
        div [ Key (task.Id |> Helper.unwrapTaskId)
              Class "task-element" ]
            [ div [ Class (sprintf "modal-header task-element-header %s" selectedClassName)
                    OnClick (fun _ -> SelectTaskCard(task |> ClientTask.UnassignedTask) |> dispatch) ]
                    [ h6 [ Class "modal-title" ] [ str taskTitle ] ]
              div [ Class "task-element-body"]
                [ p [ Class "small" ] [ str (sprintf "Geplant %s" (task.PlannedDate.ToShortDateString())) ]
                  p [ Class "small" ]
                    [ str task.ShortInfo
                      if task.AdditionalInfo <> "" then
                        tooltip task.AdditionalInfo ]
                  div [ Class "small" ]
                    [ (match task.ExecutionAdress.CompanyName with
                      | Some company ->
                        [ str (sprintf "%s" company)
                          br [ ] ] |> ofList
                      | None -> nothing)
                      str (sprintf "%s %s" task.ExecutionAdress.Street task.ExecutionAdress.HouseNumber)
                      br [ ]
                      str (sprintf "%s %s" task.ExecutionAdress.ZipCode task.ExecutionAdress.City)
                      match task.Priority with
                      | Some priority ->
                          priorityBadge priority
                      | None -> Fable.React.Helpers.nothing
                      toOrder ] ] ]
    DragAndDrop.dragContainer "mb-3" dragElement (task |> ClientTask.UnassignedTask)

let private sidebarOpenContent model dispatch =
    let startDate, endDate = model.SelectedDateRange
    let minDate = DateTime(DateTime.UtcNow.Year, 1, 1).Date
    let taskDisplayFilter (ut : ClientUnassignedTask) =
        let dateInRange dateToCheck =
          dateToCheck >= startDate && dateToCheck <= endDate
        dateInRange ut.PlannedDate

    let isFilterTypeSelected typeToCheck =
        model.SelectedFilterObjectType = typeToCheck || model.SelectedFilterObjectType = All

    let isTaskVisible (ut : ClientUnassignedTask) =
        match ut.TaskType with
        | TaskType.FreeTask -> isFilterTypeSelected FreeOrder
        | TaskType.DeliveryTask -> isFilterTypeSelected DeliveryOrder
        | TaskType.ReturnTask -> isFilterTypeSelected ReturnOrder
        | TaskType.ServiceTask -> isFilterTypeSelected RepairOrder
        | TaskType.PickTask -> isFilterTypeSelected PickOrder

    Html.div [
        prop.className "d-flex flex-column h-100"
        prop.children [
            Html.div [
                prop.className "d-flex flex-column flex-grow-1"
                prop.children [
                    ReactSelect.selectWithoutLabel (taskFilterProps model dispatch)
                    ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                        startDate
                        (SetDateRangeFilterStartDate >> dispatch)
                        startDate
                        endDate
                        minDate
                        [||]
                        false
                    ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                        endDate
                        (SetDateRangeFilterEndDate >> dispatch)
                        startDate
                        endDate
                        startDate
                        [||]
                        false
                    Html.div [
                        prop.className "sidebar-tasks-scrollable d-flex flex-column align-items-center"
                        model.UnassignedTasks
                        |> List.filter isTaskVisible
                        |> List.filter taskDisplayFilter
                        |> List.sortBy (fun t -> t.PlannedDate)
                        |> List.map (sidebarTaskElement dispatch model)
                        |> prop.children
                    ]
                ]
            ]
            Html.div [
                prop.className "collapse-btn"
                prop.children [
                    Html.a [
                        prop.onClick (fun _ -> dispatch SwitchSidebarState)
                        prop.className "mr-4"
                        prop.children [
                            Html.i [
                                prop.className "fas fa-angle-double-left p-2"
                            ]
                            Html.span "einklappen"
                        ]
                    ]
                ]
            ]
        ]
    ]

let private sidebarCloseContent model dispatch =
    div [ Class "d-flex flex-column flex-grow-1" ]
        [ div [ Class "d-flex flex-column flex-grow-1" ]
            [ ]
          div [ Class "collapse-btn" ]
            [ a [ OnClick (fun _ -> dispatch SwitchSidebarState)
                  Class "" ]
            [ i [ Class "fas fa-angle-double-right p-2" ] [ ]
              span [ ] [ ] ] ] ]

let private sidebar model dispatch =
    let className =
        match model.SidebarState with
        | SidebarState.Close -> "close"
        | Open -> "open"

    div [ Class (sprintf "d-flex flex-column sidebar %s" className) ]
      [ (match model.SidebarState with
         | SidebarState.Open -> sidebarOpenContent model dispatch
         | SidebarState.Close -> sidebarCloseContent model dispatch ) ]

let private tableTaskElementFooter (task : ClientAssignedTask) (model : Model) isDisabled dispatch =
    let openModalButton = iconButtonToOpenModal "card-link fas fa-ellipsis-h" (fun _ -> dispatch (OpenTaskModal (task, isDisabled))) "#disposition-task-modal"
    let mechanicTaskReferencedTaskIds = mechanicTaskReferencedTaskIds model.AssignedTasks
    let mechanicIcon =
        if mechanicTaskReferencedTaskIds |> List.contains task.Id then
            span [ Class "small" ]
                [ i [ Class "fas fa-user-friends mr-auto" ]
                    [ ] ]
        else Fable.React.Helpers.nothing

    let travelTime =
        match task.ExecutionInformation.TravelTime with
        | Some travelTime ->
            let convertMeterToKilometer (meter : int<Meter>) =
                meter |> int |> float |> (fun m -> m / 1000.)
            let distance = travelTime.Distance |> convertMeterToKilometer
            let durationHours = travelTime.Duration / 3600
            let durationMinutes = (travelTime.Duration - durationHours * 3600) / 60
            div [ Class "mr-auto" ]
                [ span [ Class "small" ]
                    [ i [ Class "fas fa-route mr-auto p-2" ] [ ]
                      str (sprintf "%.1f km / %i:%02i h" distance durationHours durationMinutes) ] ]
        | None -> Fable.React.Helpers.nothing

    let loadingIcon =
        if task.AllowMultipleDrivers then
            div [ Class "mr-auto" ]
                [ span [ Class "small" ]
                    [ i [ Class "fas fa-truck-loading mr-auto p-2" ] [ ] ] ]
        else Fable.React.Helpers.nothing

    div [ Class "modal-footer task-element-footer" ]
      [ travelTime
        mechanicIcon
        loadingIcon
        openModalButton ]

let removeButton onClick (task : ClientAssignedTask) isPast =
    let displayButtonForTaskType =
        match task.TaskType with
        | AssignedTaskType.SelfserviceDeliveryTask
        | SelfserviceReturnTask -> false
        | _ -> true
    match task.State, displayButtonForTaskType with
    | Planned _, true ->
        a [ Class "close"
            HTMLAttr.Custom ("aria-label", "Close") ]
            [ span [ HTMLAttr.Custom ("aria-hidden", "true")
                     OnClick onClick ]
                [ str "×" ] ]
    | Planned _, false
    | Started _, _
    | Finished _, _ -> nothing

let private isTaskModalDisabled (state : ClientTaskState) =
    match state with
    | ClientTaskState.Started _
    | ClientTaskState.Finished _ -> true
    | ClientTaskState.Planned _ -> false

let taskTaskElement (task : ClientAssignedTask) isPast (model : Model) dispatch =
    
    let toOrder  =
        let route =
            match task.OrderId with
            | OrderIdType.RentOrderId rentOrderId ->
                let (RentOrderId rentOrderId) = rentOrderId
                Page.RentOrderDetail rentOrderId
            | OrderIdType.ServiceOrderId serviceOrderId ->
                let (ServiceOrderId serviceOrderId) = serviceOrderId
                Page.RepairOrderDetail serviceOrderId
            | OrderIdType.FreeOrderId freeOrderId ->
                let (FreeOrderId freeOrderId) = freeOrderId
                Page.FreeOrderDetail freeOrderId
                
        a [ Href (Routes.toPath route ) ]
            [ p [ Class "small" ]
                [ str "Zum Auftrag"] ]
            
    let title =
        match task.TaskType with
        | AssignedTaskType.FreeTask -> "Eigene Aufgabe"
        | AssignedTaskType.SelfserviceDeliveryTask -> "Abholung"
        | AssignedTaskType.SelfserviceReturnTask -> "Rückgabe"
        | AssignedTaskType.DeliveryTask -> "Lieferung"
        | AssignedTaskType.PickTask -> "Kommissionierung"
        | AssignedTaskType.ReturnTask -> "Abholung"
        | AssignedTaskType.ServiceTask -> "Service"
        | AssignedTaskType.SupportTask _ -> "Montage"
    let headerClassName = taskHeaderClassName task.State isPast
    let taskAddress = task.ExecutionAdress
    let selectedClassName = selectedClassName model task.Id
    let element =
        div [ Class "task-element"
              Key (task.Id.ToString()) ]
            [ div [ Class (sprintf "modal-header task-element-header %s %s" headerClassName selectedClassName)
                    OnClick (fun _ -> SelectTaskCard(task |> ClientTask.AssignedTask) |> dispatch) ]
                [ h6 [ Class "modal-title" ]
                    [ str title ]
                  removeButton (fun _ -> task.Id |> UnassignTask |> dispatch) task isPast ]
              div [ Class "task-element-body"]
                [ p [ Class "small" ]
                    [ str task.ShortInfo
                      if task.AdditionalInfo <> "" then
                        tooltip task.AdditionalInfo ]
                  div [ Class "small" ]
                    [ (match taskAddress.CompanyName with
                      | Some company ->
                        [ str (sprintf "%s" company)
                          br [ ] ] |> ofList
                      | None -> nothing)
                      str (sprintf "%s %s" taskAddress.Street taskAddress.HouseNumber)
                      br [ ]
                      str (sprintf "%s %s" taskAddress.ZipCode taskAddress.City)
                      match task.Priority with
                      | Some priority -> priorityBadge priority
                      | None -> Fable.React.Helpers.nothing ]
                    
                  toOrder]
              tableTaskElementFooter task model (isTaskModalDisabled task.State) dispatch ]
    match task.State with
    | Started _ -> element
    | Finished _ -> element
    | Planned _ -> DragAndDrop.dragContainer "" element (task |> ClientTask.AssignedTask)

let mechanicTaskElement (mechanicTask : ClientAssignedTask) state shortInfo additionalInfo referencedTaskId (taskAddress : Address) isPast (model : Model) dispatch =
    let headerClassName = taskHeaderClassName state isPast
    div [ Class "task-element"
          Key (mechanicTask.Id.ToString()) ]
        [ div [ Class (sprintf "modal-header task-element-header %s" headerClassName) ]
            [ h6 [ Class "modal-title" ]
                [ str "Montage" ]
              removeButton (fun _ -> (mechanicTask.UserId, referencedTaskId) |> RemoveMechanicFromBoard |> dispatch) mechanicTask isPast ]
          div [ Class "task-element-body"]
            [ p [ Class "small" ]
                [ str shortInfo
                  tooltip additionalInfo ]
              p [ Class "small" ]
                [ (match taskAddress.CompanyName with
                  | Some company ->
                    [ str (sprintf "%s" company)
                      br [ ] ] |> ofList
                  | None -> nothing)
                  str (sprintf "%s %s" taskAddress.Street taskAddress.HouseNumber)
                  br [ ]
                  str (sprintf "%s %s" taskAddress.ZipCode taskAddress.City) ] ]
          tableTaskElementFooter mechanicTask model false dispatch ]

let isPlannedState (state : ClientTaskState) =
    match state with
    | ClientTaskState.Planned _ -> true
    | ClientTaskState.Finished _
    | ClientTaskState.Started _ -> false

let tableTaskElement (tableElement : TableElement) isPast (model : Model) dispatch =
    let mechanicTaskReferencedIds = mechanicTaskReferencedTaskIds model.AssignedTasks
    let hasNoMechanicTask taskId =
        not (mechanicTaskReferencedIds
            |> List.exists (fun referencedTaskId -> referencedTaskId = taskId))
    let isAssignedMechanicTask taskId =
        mechanicTaskReferencedIds
        |> List.exists (fun referencedTaskId -> referencedTaskId = taskId)
    let isBeforeDropableForMechanicTask taskId =
        model.AssignedTasks
        |> List.find (fun t -> t.Id = taskId)
        |> (fun t -> isPlannedState t.State)
    match tableElement with
    | TableElement.AssignedTask task ->
        match task.TaskType with
        | AssignedTaskType.FreeTask _
        | AssignedTaskType.SelfserviceDeliveryTask _
        | AssignedTaskType.SelfserviceReturnTask _
        | AssignedTaskType.DeliveryTask _
        | AssignedTaskType.ReturnTask _
        | AssignedTaskType.ServiceTask _
        | AssignedTaskType.PickTask ->
            taskTaskElement task isPast model dispatch, task.TaskPosition, isPlannedState task.State
        | AssignedTaskType.SupportTask referencedTaskId ->
            let address, position, shortInfo, additionalInfo, state, _ = mechanicTaskValues model.AssignedTasks referencedTaskId
            mechanicTaskElement task state shortInfo additionalInfo referencedTaskId address isPast model dispatch, position, isBeforeDropableForMechanicTask referencedTaskId

    | TableElement.PendingTask task ->
        (DragAndDrop.dragContainer ""
        ( div [ Class "task-element" ]
              [ p [ ] [ str "" ]
                div [ Class "d-flex" ]
                  [ p [ Class "small my-auto" ]  [ str "Synchronisieren" ]
                    Spinners.spinner ] ] ) task ), task.Index, false

let tableDropContainer className date userId index model dispatch =
    DragAndDrop.dropContainer<ClientTask>
        (div [ Class (sprintf"drop-container %s" className) ]
            [ ])
        // TODO: pre and succ task
        ((fun task -> DroppedTask (date, userId, task, index) ) >> dispatch)

let dayOfWeekString (dayOfWeek : DayOfWeek) =
    match dayOfWeek with
    | DayOfWeek.Monday -> "Montag"
    | DayOfWeek.Tuesday -> "Dienstag"
    | DayOfWeek.Wednesday -> "Mittwoch"
    | DayOfWeek.Thursday -> "Donnerstag"
    | DayOfWeek.Friday -> "Freitag"
    | DayOfWeek.Saturday -> "Samstag"
    | DayOfWeek.Sunday -> "Sonntag"
    | _ -> failwith "case not covered."

let tableHeader (date : DateTime) =
    let key = date.ToShortDateString()
    let className =
        let currentDate = System.DateTime.UtcNow.Date
        if date = currentDate then
          "today-header"
        elif date < currentDate then
          "past-header"
        else ""
    Html.th [
        prop.key key
        prop.className className
        prop.text (sprintf "%s %s." (dayOfWeekString date.DayOfWeek) (date.ToString("ddd")))
    ]

let tableElements model dispatch userId (date : DateTime) =
    let key = sprintf "%s+%i" (date.ToShortDateString()) userId
    let isPast = date < System.DateTime.UtcNow.Date
    let todayCellClassName = if date = System.DateTime.UtcNow.Date then "today-cell" else ""
    let elements =
        elementsForUserAt model.AssignedTasks model.PendingTasks userId date model dispatch
        |> List.map (fun e -> tableTaskElement e isPast model dispatch)
        |> List.sortBy (fun (t, i, _) -> i)
    let id = System.Guid.NewGuid().ToString()
    if isPast then
      td [ Id id; Key key ]
          [ div [ ]
              [ (elements
                |> List.fold (fun result (e, index, _) ->
                    let dropContainerIndex =
                        if result |> List.isEmpty then
                            ((index + 0.) / 2.)
                        else
                            let prevElement, prevElementIndex = result |> List.last
                            ((index + prevElementIndex) / 2.)
                    List.append result [ (e, index)] ) [ ]
                |> List.map (fun (e, _) -> e)
                |> ofList)]
            div [ Class "d-flex justify-content-center" ]
              [ workTimeSumBadge model.AssignedTasks userId date ] ]
    else
      td [ Id id
           Class todayCellClassName
           Key key ]
          [ div [ ]
              [ (if elements |> List.isEmpty then
                  tableDropContainer "drop-container-empty" date userId (100.) model dispatch
                 else
                  elements
                  |> List.fold (fun result (e, index, isTaskInPlanning) ->
                      let dropContainerIndex, isPrevTaskInPlanning =
                          if result |> List.isEmpty then
                              ((index + 0.) / 2.), true
                          else
                              let prevElement, prevElementIndex, isPrevTaskInPlanning = result |> List.last
                              ((index + prevElementIndex) / 2.), isPrevTaskInPlanning
                      let areAllFollowingTasksInPlanning =
                          elements
                          |> List.exists (fun (e, i, taskInPlanning) -> if i > index then not taskInPlanning else false)
                          |> not
                      let dropContainer =
                          if areAllFollowingTasksInPlanning && isTaskInPlanning && isPrevTaskInPlanning then
                              tableDropContainer "" date userId dropContainerIndex model dispatch
                          elif areAllFollowingTasksInPlanning && isTaskInPlanning && not isPrevTaskInPlanning then
                              tableDropContainer "" date userId dropContainerIndex model dispatch
                          else Fable.React.Helpers.nothing
                      List.append result [ (dropContainer, dropContainerIndex, isTaskInPlanning); (e, index, isPrevTaskInPlanning)] ) [ ]
                  |> (fun r ->
                      let newIndex =
                          r
                          |> List.last
                          |> (fun (r, index, _) -> index + 100.)
                      List.append r [tableDropContainer "" date userId newIndex model dispatch, newIndex, false])
                  |> List.map (fun (e, _, _) -> e)
                  |> ofList)
                div [ Class "d-flex justify-content-center" ]
                  [ workTimeSumBadge model.AssignedTasks userId date ] ]
          ]

let roleBadge (model : Model) (employee : Employee) =
    let badge (employees : Employee list) badgeText roleName =
        match employees |> List.tryFind (fun e -> e.Id = employee.Id) with
        | Some employee ->
            if employee.RoleNames |> List.exists (fun r -> r = roleName) then
                badgePill badgeText BadgeClassType.Info
            else Fable.React.Helpers.nothing
        | None -> Fable.React.Helpers.nothing
    div [ Class "d-flex flex-column role-badges" ]
      [ badge model.Employees "Fahrer" RoleNames.Driver
        badge model.Employees "Techniker" RoleNames.Technician
        badge model.Employees "Mechaniker" RoleNames.Mechanic
        badge model.Employees "Kommissionierer" RoleNames.Picker ]

let employeeRow model dispatch (employee : Employee) =
    tr [ ]
        [ td [ ]
            [ str (sprintf "%s %s" employee.Firstname employee.Lastname)
              roleBadge model employee ]
          model.DateRange
          |> List.map (tableElements model dispatch employee.Id)
          |> ofList]

let selfserviceTaskRow model dispatch =
    tr [ ]
        [ td [ ]
            [ str "Selbstabholung / Selbstrückgabe"
            //   roleBadge model employee
               ]
          model.DateRange
          |> List.map (tableElements model dispatch 0)
          |> ofList]


let dispositionTable model dispatch =
    let employeeRowElements = model.VisibleEmployees |> List.map (employeeRow model dispatch)
    let rowElements = (selfserviceTaskRow model dispatch) :: employeeRowElements
    div [ Class "disposition-table" ]
      [ div [ Class "d-flex month-selector" ]
          [ span [ Class "arrow fas fa-angle-left my-auto ml-auto"
                   OnClick (fun _ -> ShowPrevWeek |> dispatch) ]
                  [  ]
            div [ Class "my-auto d-flex flex-column month-name" ]
                [ span [ Class " my-auto" ]
                    [ str "Woche" ]
                  str (sprintf "%s - %s" (model.DateRange.Head.ToString("dd.MM.yyyy"))
                                         (model.DateRange |> List.rev |> List.head |> (fun d -> d.ToString("dd.MM.yyyy")))) ]

            span [ Class "arrow fas fa-angle-right my-auto mr-auto"
                   OnClick (fun _ -> ShowNextWeek |> dispatch) ]
                    [ ] ]
        table [ Class "table table-bordered disposition-table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                        [ str "" ]
                    model.DateRange
                    |> List.map tableHeader
                    |> ofList ] ]
            tbody [ ]
              [ rowElements
                |> ofList ] ] ]

let private planContent model dispatch =
    div [ Class "flex-grow-1 pl-3 dispotable-content" ]
      [ div [ Class "content" ]
            [ mainHeaderWithClassName "Disponieren" "header"
              dispositionTable model dispatch ] ]

let private dispositionContent (model : Model) dispatch =
    Html.div [
        prop.id "disposition-content"
        prop.className "d-flex h-100"
        prop.children [
            SharedComponents.Spinners.overlaySpinner model.RequestState
            sidebar model dispatch
            planContent model dispatch
        ]
    ]

let private taskSplitModal model dispatch =
    match model.SplitModalState with
    | SplitModalState.Closed ->
        TaskSplitModal.viewModalContent Fable.React.Helpers.nothing
    | SplitModalState.Open task ->
        TaskSplitModal.viewModalContent
            (TaskSplitModal.view model
                                 (fun _ -> dispatch (TaskSplitRequest))
                                 (fun _ -> dispatch (AbortSplit))
                                 dispatch)

let private taskModal model dispatch =
    match model.TaskModalState with
    | TaskModalState.Closed ->
        TaskModal.hiddenModal ()
    | TaskModalState.Open (task, isDisabled) ->
        TaskModal.viewModalContent
            (TaskModal.view model task isDisabled
                            ignore
                            (fun _ -> dispatch (AbortTaskModal))
                            dispatch)

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "disposition-container"
          Class "d-flex flex-column" ]
        [ section [ ]
            [ taskSplitModal model dispatch
              taskModal model dispatch ]
          dispositionContent model dispatch
        ]
