module Order.State

open Elmish
open Order.Types

let init user : Model * Cmd<Msg> =
    { Empty = ""
      UserData = user }, Cmd.none

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | Empty -> model, Cmd.none
