module MyProfil.FormValidation

open Shared
open MyProfil.Types
open Validation
open System

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let validatePassword (userForm : UserForm ) : FormValidation =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }

    let user = userForm.User
    let userPassword _ =
        if not (String.IsNullOrWhiteSpace user.Password) && not (String.IsNullOrWhiteSpace user.SecondPassword) && (user.SecondPassword = user.Password) then
            create "userpass" ValidationState.Valid
        else create "userpass" (ValidationState.NotValid "Passwort muss übereinstimmen und darf nicht leer sein.")
    let newValidations = [ userPassword (); ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = [ userPassword(); ]
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid }