module MasterData.Entities.TransformEntityDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Shared.Entity
open SharedComponents.Toast
open SharedComponents.Headers
open Feliz.ElmishComponents
open SharedComponents.ReactSelect

type Msg =
    | SelectTemplate of OptionType<Template> option
    | TransformEntity
    | EntityTransformed of PostResponse<TransformEntityResult>
    | FetchError of exn

let transformEntityCmd (dto : TransformEntityDto) =
    let body = dto |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.postRequest<PostResponse<TransformEntityResult>>
                             ("/api/entities/transform", body)
                             EntityTransformed FetchError
type State =
    { Entity : EntityDetailDto
      Templates : Template list
      SelectedTemplate : Template option
      Loading : bool
      SuccessCallback : EntityId * TemplateId -> unit }

let init successCallback entity templates : State * Cmd<Msg> =
    let initialModel =
        { Entity = entity
          Templates = templates |> List.filter(fun t -> t.Id <> entity.TemplateId)
          SelectedTemplate = None
          Loading = false
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | SelectTemplate selectedTemplate ->
        match selectedTemplate with
        | Some value ->
            { state with SelectedTemplate = Some value.value }, Cmd.none
        | None -> state, Cmd.none
    | TransformEntity ->
        match state.SelectedTemplate with
        | Some template ->
            let dto = { EntityId = state.Entity.Id
                        TargetTemplateId = template.Id }
            { state with Loading = true }, transformEntityCmd dto
        | None -> state, Cmd.none
    | EntityTransformed response ->
        match response.Result with
        | TransformEntityResult.Saved (entityId, templateId) ->
            state.SuccessCallback(entityId, templateId)
            Swal.close (SweetAlert.Result.Value ())
            state, toast (ToastType.Success "Baugerät wurde transformiert.")
        | TransformEntityResult.NotInStorage ->
            { state with Loading = false }, toast (ToastType.Error "Das Baugerät befindet sich nicht im Lager.")
        | TransformEntityResult.EntityNameAlreadyExists ->
            { state with Loading = false }, toast (ToastType.Error "Es existiert bereits Baugerät mit diesem Namen.")
    | FetchError e ->
        state, Cmd.none

let body (state : State) dispatch =

    let templateProps dispatch =
        let options (templates : Template list) =
            templates
            |> List.map (fun el ->
                { label = el.TemplateBaseInformation.Name
                  value = el })
            |> List.toArray

        let emptySearchResultContent =
            Html.div [
                prop.classes [
                    "mr-auto"
                    "p-2"
                ]
                prop.text "Es konnte kein passender Typ gefunden werden."
            ]
              
        let valueElement =
            match state.SelectedTemplate with
            | Some template -> [| ReactSelect.CommonProps<Template>.Value (Some { label = template.TemplateBaseInformation.Name; value = template }) |]
            | None -> [| ReactSelect.CommonProps<Template>.Value None |]
            
        Array.append
            [| SharedComponents.ReactSelect.CommonProps<Template>.Options (options state.Templates)
               SharedComponents.ReactSelect.CommonProps<Template>.OnChange (fun e -> dispatch (SelectTemplate (e)))
               SharedComponents.ReactSelect.CommonProps<Template>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<Template>.IsClearable true
               SharedComponents.ReactSelect.CommonProps<Template>.Placeholder "Suchen oder Filtern"
               SharedComponents.ReactSelect.CommonProps<Template>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<Template>.ClassName "template-select flex-grow-1 mb-3"
               SharedComponents.ReactSelect.CommonProps<Template>.IsDisabled state.Loading |]
            valueElement
            
    Html.div [
        prop.className "transform-entity-dialog"
        prop.children [
            subHeaderForm "Baugerät transformieren"
            Html.div [
                ReactSelect.selectWithoutLabel (templateProps dispatch)
            ]

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnctAndIsDisabled
                        (fun _ ->
                            TransformEntity |> dispatch
                        )
                        (state.SelectedTemplate.IsNone || state.Loading)
                        "Speichern" "mr-3"
                    Buttons.secondaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" false ""
                ]
            ]
        ]
    ]

let dialogBody successCallback entityId templates =
    React.elmishComponent("TransformEntityDialog", init successCallback entityId templates, update, body)

let render entityId templates successCallback =
    Buttons.dropDownButton
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody successCallback entityId templates)
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value response ->
                        successCallback response
                        Swal.Simple.success "Baugerät wurde erfolgreich transformiert"
                    | SweetAlert.Result.Dismissal d ->
                        ()
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
        false
        "Gerät transformieren"

let transformEntityDialog' =
    React.functionComponent(fun (props : {| Entity : EntityDetailDto
                                            Templates : Template list
                                            SuccessCallback : EntityId * TemplateId -> unit |}) -> render props.Entity props.Templates props.SuccessCallback)

let transformEntityDialog (entity : EntityDetailDto) (templates : Template list) successCallback =
    transformEntityDialog' {| Entity = entity
                              Templates = templates
                              SuccessCallback = successCallback |}

