module SharedComponents.Toast

open Fable.React
open Fable.React.Props
open Thoth.Elmish

type ToastType =
    | Success of string
    | Info of string
    | Warning of string
    | Error of string

let private success msg =
    Toast.message msg
    |> Toast.title "Erfolgreich"
    |> Toast.position Toast.BottomRight
    |> Toast.timeout (System.TimeSpan.FromSeconds (5.0))
    |> Toast.withCloseButton
    |> Toast.dismissOnClick
    |> Toast.success

let private info msg =
    Toast.message msg
    |> Toast.title "Information"
    |> Toast.position Toast.BottomRight
    |> Toast.timeout (System.TimeSpan.FromSeconds (5.0))
    |> Toast.withCloseButton
    |> Toast.dismissOnClick
    |> Toast.info

let private warning msg =
    Toast.message msg
    |> Toast.title "Warnung"
    |> Toast.position Toast.BottomRight
    |> Toast.timeout (System.TimeSpan.FromSeconds (5.0))
    |> Toast.withCloseButton
    |> Toast.dismissOnClick
    |> Toast.warning

let private error msg =
    Toast.message msg
    |> Toast.title "Fehlermeldung"
    |> Toast.position Toast.BottomRight
    |> Toast.timeout (System.TimeSpan.FromSeconds (5.0))
    |> Toast.withCloseButton
    |> Toast.dismissOnClick
    |> Toast.error

let toast toastType =
    match toastType with
    | Success msg -> success msg
    | Info msg -> info msg
    | Warning msg -> warning msg
    | Error msg -> error msg