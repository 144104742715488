module Configuration.Workflow.State

open Configuration.Workflow.Types
open Elmish
open Shared.Configuration
open SharedComponents
open Thoth.Json
open Shared
open Fable.Core.JsInterop
open Browser.Dom
open Browser.Blob
open Browser.Types
open Browser.WebStorage
open Browser.XMLHttpRequest
open SharedComponents.Alerts
open SharedComponents.Toast
open SharedComponents.Spinners

let getConfigurationCmd =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" ConfigurationFetched FetchError

let updateConfigurationCmd (updateElement : ConfigurationUpdate) =
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>> ("/api/configuration", body) ConfigurationSaved FetchError

let init : Model * Cmd<Msg> =
    let initialModel =
        { FormState = FormState.Loading
          RequestState = RequestState.Active }
    initialModel, getConfigurationCmd

let toActionList (action : (Msg -> 'a) -> unit) =
    let actionList = [action]
    actionList

let update (msg : Msg) (model : Model) =
    match msg with
    // Set company base information
    | SetCloseRentOrderOption value ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let workflow =
                    { form.Configuration.Workflow with CloseRentOrderAfterReturnTaskFinished = value }
                { form with Configuration = { form.Configuration with Workflow = workflow } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Set edit states
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form ->
                { form with EditField = EditField.Nothing
                            Configuration = form.ConfigurationSnapshot }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditBaseWorkflow ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form -> { form with EditField = EditField.EditBaseWorkflow
                                       Configuration = form.ConfigurationSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    // Actions
    | SaveConfiguration ->
        let newModel, cmd =
            match model.FormState with
            | Edit form ->
                match form.EditField with
                | EditField.EditBaseWorkflow ->
                    { model with RequestState = RequestState.Active },
                    form.Configuration.Workflow
                    |> ConfigurationUpdate.Workflow
                    |> updateConfigurationCmd
                | EditField.Nothing -> model, Cmd.none
            | FormState.Loading -> model, Cmd.none
        newModel, cmd

    // Fetched
    | ConfigurationSaved response ->
        let newFormState, cmd =
            match model.FormState with
            | Edit form ->
                { Configuration = form.Configuration
                  ConfigurationSnapshot = form.Configuration
                  EditField = EditField.Nothing } |> FormState.Edit, toast (ToastType.Success "Konfiguration erfolgreich gespeichert.")
            | FormState.Loading -> FormState.Loading, Cmd.none
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, cmd
    | ConfigurationFetched configuration ->
        let newFormState =
            match model.FormState with
            | Loading ->
                { Configuration = configuration
                  ConfigurationSnapshot = configuration
                  EditField = EditField.Nothing } |> FormState.Edit
            | Edit form ->
                { Configuration = configuration
                  ConfigurationSnapshot = configuration
                  EditField = EditField.Nothing } |> FormState.Edit
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, Cmd.none
    | FetchError e ->
        { model with RequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
