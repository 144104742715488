module CarrierManagement.View

open Feliz
open SharedComponents
open SharedComponents.Spinners
open SharedComponents.Headers

open CarrierManagement.Types
open Shared
open SharedComponents.Breadcrumb
open Routes
open Shared.Carrier
open SharedComponents.Types
open Sww.Frontend.ReactTable

let private newForm (form : CarrierNewForm) (dispatch : Msg -> unit) =
    Html.div [
        Html.div [
            prop.className "d-flex flex-row"
            prop.children [
                Html.div [
                    prop.className "flex-fill mr-2"
                    prop.children [
                        subHeaderForm "Details"
                        Form.Input.inlineInput((SetName >> dispatch), form.Carrier.Name,
                                               inputLabel = "Name", cyPostFix = "carrier-name", required = true,
                                               validation = (form.FormValidation, "carrier-name"))
                        Form.Input.inlineInput((SetContactPerson >> dispatch), form.Carrier.ContactPerson,
                                               inputLabel = "Kontaktperson", cyPostFix = "carrier-contactperson")
                        Form.Input.inlineInput((SetEmailAddress >> dispatch), form.Carrier.ContactInformation.EMailAddress,
                                               inputLabel = "E-Mail", cyPostFix = "carrier-email")
                        Form.Input.inlineInput((SetPhoneNumber >> dispatch), form.Carrier.ContactInformation.PhoneNumber,
                                               inputLabel = "Telefonnummer", cyPostFix = "carrier-phonenumber")
                        Form.textArea (SetAdditionalInfo >> dispatch) form.Carrier.AdditionalInfoText "Info-Text" false "carrier-infotext"

                        Form.requiredLegend
                    ]
                ]
                Html.div [
                    prop.className "flex-fill ml-2"
                    prop.children [
                        subHeaderForm "Adresse"
                        Form.Input.inlineInput((SetStreet >> dispatch), form.Carrier.Address.Street,
                                               inputLabel = "Straße", cyPostFix = "carrier-street")
                        Form.Input.inlineInput((SetHouseNumber >> dispatch), form.Carrier.Address.HouseNumber,
                                               inputLabel = "Hausnummer", cyPostFix = "carrier-housenumber")
                        Form.Input.inlineInput((SetZipCode >> dispatch), form.Carrier.Address.ZipCode,
                                               inputLabel = "PLZ", cyPostFix = "carrier-zipcode")
                        Form.Input.inlineInput((SetCity >> dispatch), form.Carrier.Address.City,
                                               inputLabel = "Stadt", cyPostFix = "carrier-city")
                    ]
                ]
            ]
        ]

        Html.div [
            prop.className "controls d-flex"
            prop.children [
                Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> SaveCarrier |> dispatch) false "Spedition anlegen" "ml-auto"
            ]
        ]
    ]

let private editForm (form : CarrierEditForm) (dispatch : Msg -> unit) =
    Html.div [
        prop.className "d-flex flex-row"
        prop.children [
            Html.div [
                prop.className [ if form.EditField <> EditField.EditCarrier then "disabled-form flex-fill mr-2" else "flex-fill mr-2" ]
                prop.children [
                    subHeaderFormWithEdit (form.EditField = EditField.EditCarrier) "Details"
                      (fun _ -> EditCarrier |> dispatch) ""
                      (fun _ -> SaveCarrier |> dispatch) ""
                      (fun _ -> AbortEdit |> dispatch) ""
                    Form.Input.inlineInput((SetName >> dispatch), form.Carrier.Name,
                                           inputLabel = "Name", cyPostFix = "carrier-name", required = true,
                                           isDisabled = (form.EditField <> EditField.EditCarrier),
                                           validation = (form.FormValidation, "carrier-name"))
                    Form.Input.inlineInput((SetContactPerson >> dispatch), form.Carrier.ContactPerson,
                                           isDisabled = (form.EditField <> EditField.EditCarrier),
                                           inputLabel = "Kontaktperson", cyPostFix = "carrier-contactperson")
                    Form.Input.inlineInput((SetEmailAddress >> dispatch), form.Carrier.ContactInformation.EMailAddress,
                                           isDisabled = (form.EditField <> EditField.EditCarrier),
                                           inputLabel = "E-Mail", cyPostFix = "carrier-email")
                    Form.Input.inlineInput((SetPhoneNumber >> dispatch), form.Carrier.ContactInformation.PhoneNumber,
                                           isDisabled = (form.EditField <> EditField.EditCarrier),
                                           inputLabel = "Telefonnummer", cyPostFix = "carrier-phonenumber")
                    Form.textArea (SetAdditionalInfo >> dispatch) form.Carrier.AdditionalInfoText "Info-Text" (form.EditField <> EditField.EditCarrier) ""

                    Form.requiredLegend
                ]
            ]
            Html.div [
                prop.className[ if form.EditField <> EditField.EditAddress then "disabled-form flex-fill mr-2" else "flex-fill mr-2" ]
                prop.children [
                    subHeaderFormWithEdit (form.EditField = EditField.EditAddress) "Adresse"
                      (fun _ -> EditAddress |> dispatch) ""
                      (fun _ -> SaveCarrier |> dispatch) ""
                      (fun _ -> AbortEdit |> dispatch) ""
                    Form.Input.inlineInput((SetStreet >> dispatch), form.Carrier.Address.Street,
                                           isDisabled = (form.EditField <> EditField.EditAddress),
                                           inputLabel = "Straße", cyPostFix = "carrier-street")
                    Form.Input.inlineInput((SetHouseNumber >> dispatch), form.Carrier.Address.HouseNumber,
                                           isDisabled = (form.EditField <> EditField.EditAddress),
                                           inputLabel = "Hausnummer", cyPostFix = "carrier-housenumber")
                    Form.Input.inlineInput((SetZipCode >> dispatch), form.Carrier.Address.ZipCode,
                                           isDisabled = (form.EditField <> EditField.EditAddress),
                                           inputLabel = "PLZ", cyPostFix = "carrier-zipcode")
                    Form.Input.inlineInput((SetCity >> dispatch), form.Carrier.Address.City,
                                           isDisabled = (form.EditField <> EditField.EditAddress),
                                           inputLabel = "Stadt", cyPostFix = "carrier-city")
                ]
            ]
        ]
    ]

let private formContainer model dispatch formElement =
    Html.div [
        prop.className "d-flex flex-column flex-grow-1"
        prop.children [
            Html.div [
                prop.className "d-flex"
                prop.children [
                    Html.div [
                        prop.className "flex-grow-1 p-2"
                        prop.children [
                            formElement
                        ]
                    ]
                ]
            ]
        ]
    ]

let carrierFormBreadcrumb (form : CarrierForm) =
    let breadcrumb name =
        breadcrumb [
            breadcrumbLink (Page.CarrierManagement |> Routes.toPath) "Spedition-Administration"
            breadcrumbStr name
        ]
    match form with
    | CarrierForm.Loading -> Fable.React.Helpers.nothing
    | CarrierForm.New form -> breadcrumb form.Carrier.Name
    | CarrierForm.Edit form -> breadcrumb form.Carrier.Name

let editView (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.children [
            SharedComponents.Spinners.overlaySpinner model.CarrierRequestState
            carrierFormBreadcrumb model.Form
            Html.div [
                Html.div [
                    prop.id "masterdata-container"
                    prop.className "material flex-grow-1 d-flex flex-column"
                    prop.children [
                        Html.div [
                            prop.id "content-container"
                            prop.children [
                                mainHeader "Spedition"
                                match model.Form with
                                | CarrierForm.Loading -> Html.none
                                | CarrierForm.New _ -> Html.span "Failed"
                                | CarrierForm.Edit form ->
                                    editForm form dispatch
                                    |> formContainer model dispatch
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let newView (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.children [
            SharedComponents.Spinners.overlaySpinner model.CarrierRequestState
            carrierFormBreadcrumb model.Form
            Html.div [
                prop.id "masterdata-container"
                prop.className "material flex-grow-1 d-flex flex-column"
                prop.children [
                    Html.div [
                        prop.id "content-container"
                        prop.children [
                            mainHeader "Spedition"
                            match model.Form with
                            | CarrierForm.Loading -> Html.none
                            | CarrierForm.New form ->
                                newForm form dispatch
                                |> formContainer model dispatch
                            | CarrierForm.Edit _ -> Html.span "Failed"
                        ]
                    ]
                ]
            ]
        ]
    ]

let private carrierRow (carrier : Carrier) =
    let (CarrierId carrierId) = carrier.Id

    Html.tr [
        prop.key (carrierId |> string)
        prop.children [
            Html.td carrier.Name
            Html.td [
                Html.a [
                    prop.href (Routes.toPath (Routes.Page.CarrierManagementViewForm carrierId))
                    prop.children [
                        Html.i [ prop.className "fas fa-eye" ]
                        Html.span [
                            prop.className "url-text"
                            prop.text "Anzeigen"
                        ]
                    ]
                ]
            ]
        ]
    ]

let private speditionTable (model : Model) =
    reactTable [
        tableProp.keyField "Id"
        tableProp.defaultSortField ("Name", "asc")
        tableProp.rows model.Carriers
        tableProp.tableKey "carriers"
        tableProp.tableExportName "speditionen_export.csv"
        tableProp.columns [
            tableColumn [
                colProp.selector "Name"
                colProp.label "Name"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "ContactPerson"
                colProp.label "Kontaktperson"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "Address.City"
                colProp.label "Stadt"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "Aktion"
                colProp.label "Aktion"
                colProp.formatter (Custom (fun value order -> CarrierManagement.Helper.Table.detailBtn order))
                colProp.csvPrint false
            ]
        ]
    ]

let overview (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.id "masterdata-container"
        prop.className "flex-grow-1 d-flex flex-column"
        prop.children [
            SharedComponents.Spinners.overlaySpinner model.CarrierRequestState
            Html.div [
                prop.id "content-container"
                prop.children [
                    mainHeader "Spedition-Administration"
                    Html.div [
                        prop.className "controls d-flex mb-2"
                        prop.children [
                            Html.div [  ]
                            Html.div [ prop.className "flex-fill" ]
                            Html.div [
                                Buttons.primaryButton (Routes.toPath Routes.Page.CarrierManagementNewForm) "Spedition anlegen" "ml-auto"
                            ]
                        ]
                    ]

                    Html.div [
                        prop.className "d-flex flex-wrap"
                        prop.children [
                            speditionTable model
                        ]
                    ]
                ]
            ]
        ]
    ]