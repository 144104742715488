module MasterData.Entities.View

open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open SharedComponents.Headers
open SharedComponents.Types.Helpers
open SharedComponents.Breadcrumb

open Shared
open SharedComponents
open SharedComponents.Types
open SharedComponents.Spinners
open SharedComponents.ReactSelect
open SharedComponents.FileUploads
open SharedComponents.Buttons
open SharedComponents.Form
open Routes
open MasterData.Entities.Helper

open MasterData.Entities.Types
open System
open Fable.Import
open Shared.Entity
open SharedComponents.ReactGoogleMap
open SharedComponents.Badges
open Shared.Address
open Shared.Configuration
open Helper.Table
open SharedComponents.DeleteMsgBox
open Feliz


let private homeAddressProps (form : EntityForm) model dispatch isDisabled =
    let displayText (location : Location) =
        let address = location.Address
        sprintf "%s | %s %s | %s %s" location.Name address.Street address.HouseNumber address.City address.ZipCode

    let options (configuration : Configuration option) =
        match configuration with
        | None -> [||]
        | Some configuration ->
            configuration.MainLocation :: configuration.Locations
            |> List.map (fun location ->
                { label = displayText location
                  value = location })
            |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Standort gefunden werden." ]

    let valueElement =
        match model.Configuration with
        | None -> [| SharedComponents.ReactSelect.CommonProps<Location>.Value None |]
        | Some configuration ->
            match form.Entity.CurrentLocation with
            | EntityLocation.RentOrderLocation _
            | EntityLocation.ExecutionLocation _
            | EntityLocation.OnRoadToRentOrder _
            | EntityLocation.OnRoadFromRentOrder _
            | EntityLocation.OnRoad _ -> [| SharedComponents.ReactSelect.CommonProps<Location>.Value None |]
            | EntityLocation.HomeLocation id ->
                if configuration.MainLocation.Id = id then
                    [| SharedComponents.ReactSelect.CommonProps<Location>.Value (Some { label = displayText configuration.MainLocation; value = configuration.MainLocation }) |]
                else
                  configuration.Locations
                  |> List.tryFind (fun l -> l.Id = id)
                  |> (fun location ->
                      match location with
                      | None -> [| SharedComponents.ReactSelect.CommonProps<Location>.Value None |]
                      | Some location ->
                          [| SharedComponents.ReactSelect.CommonProps<Location>.Value (Some { label = displayText location; value = location }) |])

    Array.append
        [| SharedComponents.ReactSelect.CommonProps<Location>.Options (options model.Configuration)
           SharedComponents.ReactSelect.CommonProps<Location>.OnChange (fun address -> dispatch (SetHomeLocation address))
           SharedComponents.ReactSelect.CommonProps<Location>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<Location>.IsClearable false
           SharedComponents.ReactSelect.CommonProps<Location>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<Location>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<Location>.ClassName "address-select flex-grow-1"
           SharedComponents.ReactSelect.CommonProps<Location>.IsDisabled isDisabled |]
        valueElement

let reminderIntervallSelectConfig (attributeId : AttributeId) reminder isDisabled model dispatch =
    let options =
        [| { label = "Drei Tage vorher"; value = DateReminder.ThreeDays }
           { label = "Eine Woche vorher"; value = DateReminder.OneWeek }
           { label = "Ein Monat vorher"; value = DateReminder.OneMonth } |]
    [| SharedComponents.ReactSelect.CommonProps<DateReminder>.Options options
       ReactSelect.CommonProps<DateReminder>.Value (options |> Array.find (fun o -> o.value = reminder) |> Some)
       SharedComponents.ReactSelect.CommonProps<DateReminder>.OnChange (fun value -> dispatch (SetDateReminder (attributeId, value.Value.value)))
       SharedComponents.ReactSelect.CommonProps<DateReminder>.Placeholder "Erinnerungszeitpunkt auswählen"
       SharedComponents.ReactSelect.CommonProps<DateReminder>.IsDisabled isDisabled
       ReactSelect.CommonProps<DateReminder>.ClassName "reminder-select flex-grow-1" |]

let templateAttributes (decimalAttributeStrings : DecimalAttributeAsStringHolder list) (entityAttributes : EntityAttribute list) (template : Template) (isDisabled : bool) model (dispatch : Msg -> unit) =
    let attributeDiv (templateAttribute : Attribute) =
        let entityAttribute = entityAttributes |> List.find (fun attr -> attr |> Helper.entityAttributeId = templateAttribute.Id)
        let attributeName = templateAttribute.Name
        match entityAttribute with
        | EntityBoolAttribute b ->
            Form.checkBox (fun ev -> dispatch (SetBoolAttributeValue (b.AttributeId, ev.Checked))) b.Value attributeName isDisabled ""
        | EntityIntAttribute i ->
            Form.numberInlineInput (fun ev -> dispatch (SetIntAttributeValue (i.AttributeId, (ev.Value |> int)))) i.Value attributeName "1" isDisabled ""
        | EntityDecimalAttribute i ->
            let valueOption = decimalAttributeStrings |> List.find (fun d -> d.AttributeId = i.AttributeId)
            let value =
                match valueOption.Value with
                | Some v -> v
                | None -> ""
            Form.Input.inlineInput((fun ev -> dispatch (SetDecimalAttributeValue (i.AttributeId, ev))), value,
                                   inputLabel = attributeName, isDisabled = isDisabled)
        | EntityStringAttribute s ->
            Form.Input.inlineInput((fun ev -> dispatch (SetStringAttributeValue (s.AttributeId, ev))), s.Value,
                                   inputLabel = attributeName, isDisabled = isDisabled)
        | EntityDateAttribute s ->
            ReactDatepicker.datepickerWithLabel s.Value (fun date -> dispatch (SetDateAttributeValue (s.AttributeId, date))) isDisabled attributeName
        | EntityDateWithReminderAttribute s ->
            div [ Class "d-flex" ]
                [ ReactDatepicker.datepickerWithLabel s.Value (fun date -> dispatch (SetDateAttributeValue (s.AttributeId, date))) isDisabled attributeName
                  ReactSelect.selectWithLabel (reminderIntervallSelectConfig s.AttributeId s.Reminder isDisabled model dispatch) (toLabelType "Erinnerung" isDisabled false FormState.View) "label label-right" ]
        | OptionalEntityBoolAttribute b ->
            match b.Value with
            | Some value ->
                Form.checkBox (fun ev -> dispatch (SetBoolAttributeValue (b.AttributeId, ev.Checked))) value attributeName isDisabled ""
            | None -> Form.checkBox (fun ev -> dispatch (SetBoolAttributeValue (b.AttributeId, ev.Checked))) false attributeName isDisabled ""
        | OptionalEntityIntAttribute i ->
            Form.numberInlineInput (fun ev -> dispatch (SetOptionalIntAttributeValue (i.AttributeId, ev.Value))) i.Value attributeName "1" isDisabled ""
        | OptionalEntityDecimalAttribute i ->
            let valueOption = decimalAttributeStrings |> List.find (fun d -> d.AttributeId = i.AttributeId)
            let value = match valueOption.Value with | Some v -> v | None -> ""
            Form.Input.inlineInput((fun ev -> dispatch (SetOptionalDecimalAttributeValue (i.AttributeId, ev))), value,
                                   inputLabel = attributeName, isDisabled = isDisabled)
        | OptionalEntityStringAttribute s ->
            let value = match s.Value with | Some v -> v | None -> ""
            Form.Input.inlineInput((fun ev -> dispatch (SetStringAttributeValue (s.AttributeId, ev))), value,
                                   inputLabel = attributeName, isDisabled = isDisabled)
        | OptionalEntityDateAttribute s ->
            ReactDatepicker.Datepicker.datepicker(s.Value, (fun date -> dispatch (SetOptionalDateAttributeValue (s.AttributeId, date))),
                                                isDisabled = isDisabled, labelName = attributeName,
                                                onClear = (fun _ -> s.AttributeId |> ClearOptionalDateAttributeValue |> dispatch))
        | OptionalEntityDateWithReminderAttribute s ->
            div [ Class "d-flex" ]
                [ ReactDatepicker.Datepicker.datepicker(s.Value, (fun date -> dispatch (SetOptionalDateAttributeValue (s.AttributeId, date))),
                                                isDisabled = isDisabled, labelName = attributeName,
                                                onClear = (fun _ -> s.AttributeId |> ClearOptionalDateAttributeValue |> dispatch))
                  ReactSelect.selectWithLabel (reminderIntervallSelectConfig s.AttributeId s.Reminder isDisabled model dispatch) (toLabelType "Erinnerung" isDisabled false FormState.View) "label label-right" ]
    
    let disabledFormClass = if isDisabled then "disabled-form" else ""
    div [ Class ("entity-attributes-form " + disabledFormClass) ]
        [ template.Attributes
          |> List.map attributeDiv
          |> ofList ]

let referencedTemplateRowView (model : Model) (templateGroup : Template) (dispatch : Msg -> unit) index (referencedTemplate : Shared.Entity.ReferencedTemplate option) =
    let rowKey =
        match referencedTemplate with
        | Some referencedTemplate ->
            let (TemplateId referencedTemplateId) = referencedTemplate.TemplateId
            referencedTemplateId.ToString()
        | None ->
            "no-referenced-template-row"
    tr [ Key rowKey ]
        [ td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  match model.Templates |> List.tryFind (fun t -> t.Id = referencedTemplate.TemplateId) with
                  | Some template ->
                    let (TemplateId templateId) = template.Id
                    a [ Href (Routes.toPath (Routes.Page.TemplatesViewForm templateId)) ]
                      [ str template.TemplateBaseInformation.Name ]
                  | None -> str "Lädt ..."
              | None -> str "todo"
               ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  referencedTemplate.Min |> string |> str
              | None -> nothing ]
          td [ ]
            [ match referencedTemplate with
              | Some referencedTemplate ->
                  referencedTemplate.Max |> string |> str
              | None -> nothing ] ]

let private entityNewForm (form : EntityNewForm) (template : Template) (model : Model) (dispatch : Msg -> unit) =
    let entity = form.Entity
    let tableHead (attribute : Attribute) =
        th [ ]
            [ str attribute.Name ]
    div [ Id "masterdata-content" ]
      [ mainHeader "Baugerät anlegen"
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1"]
              [ div [ Class "p-2" ]
                  [ subHeaderForm "Allgemein"
                    Form.Input.inlineInput((fun ev -> dispatch (SetName ev)), entity.Name,
                       inputLabel = "Name", validation = (form.FormValidation, "entityname"),
                       isDisabled = form.Entity.CreateMultipleEntities.IsSome,
                       required = form.Entity.CreateMultipleEntities.IsNone)
                    Form.checkBox (fun ev -> dispatch SetMultipleEntitiesCreation) (form.Entity.CreateMultipleEntities.IsSome) "Mehrere Baugeräte anlegen" false ""
                    match entity.CreateMultipleEntities with
                    | Some multipleEntities ->
                        Form.Input.inlineInput((fun ev -> dispatch (SetStartNumber ev)), multipleEntities.StartNumber |> string,
                           inputLabel = "Startnummer", validation = (form.FormValidation, "startnumber"),
                           isDisabled = form.Entity.CreateMultipleEntities.IsNone,
                           required = form.Entity.CreateMultipleEntities.IsSome)
                        Form.Input.inlineInput((fun ev -> dispatch (SetQuantity ev)), multipleEntities.Quantity |> string,
                           inputLabel = "Anzahl", validation = (form.FormValidation, "quantity"),
                           isDisabled = form.Entity.CreateMultipleEntities.IsNone,
                           required = form.Entity.CreateMultipleEntities.IsSome)
                    | None -> ()
                     ] ] ]
        div [ Class "p-2" ]
            [ subHeaderForm "Eigenschaften"
              templateAttributes form.DecimalAttributeAsStringHolder entity.Attributes template false model dispatch ]
        match template.ChildTemplates with
        | None -> Fable.React.Helpers.nothing
        | Some children ->
            div [ Class "p-2" ]
                [ subHeaderForm "Teile"
                  div [ Class "table" ]
                    [ table [ Class "table" ]
                        [ thead [ ]
                            [ tr [ ]
                                [ th [ ]
                                    [ str "Name" ]
                                  th [ ]
                                    [ str "Min" ]
                                  th [ ]
                                    [ str "Max" ] ] ]
                          tbody [ ]
                            [ children.Templates
                              |> List.map Some
                              |> List.mapi (referencedTemplateRowView model template dispatch)
                              |> ofList ] ] ] ]
        div [ Class "d-flex"]
            [ Buttons.primaryButtonWithFnct (fun _ -> dispatch (ValidateForm ActionType.Add)) "Baugerät speichern" "ml-auto" ] ]

let homeAddress model locationId =
  match model.Configuration with
  | None -> emptyAddress
  | Some c ->
      if c.MainLocation.Id = locationId then c.MainLocation.Address
      else
        match c.Locations |> List.tryFind (fun l -> l.Id = locationId) with
        | Some a -> a.Address
        | None -> emptyAddress

let private overviewMap model (entity : EntityDetailDto) dispatch =
    let location =
      match entity.CurrentLocation with
      | EntityLocation.RentOrderLocation _ -> entity.CurrentAddress |> Some
      | EntityLocation.ExecutionLocation a -> a |> Some
      | EntityLocation.HomeLocation hId -> (homeAddress model hId) |> Some
      | OnRoadToRentOrder _
      | OnRoadFromRentOrder _
      | EntityLocation.OnRoad _ -> None
    let marker, lat, lng =
        match location with
        | Some location ->
            match location.Coordinates with
            | Some coords ->
                  marker (markerElement  "maps-marker-executionlocations")
                                         { lat = coords.Latitude
                                           lng = coords.Longitude
                                           text = "" }, coords.Latitude, coords.Longitude
            | None -> Fable.React.Helpers.nothing, 48.703147, 9.654052
        | None -> Fable.React.Helpers.nothing, 48.703147, 9.654052

    div [ Style [ (CSSProp.Width "100%"); (CSSProp.Height "280px"); (CSSProp.Border 0)  ] ]
        [ googleMap [| ReactGoogleMap.CommonProps.BootstrapURLKeys { key = "AIzaSyASFW97YoSKQe3o0vMBC1opstpId4VUqfI" };
                       ReactGoogleMap.CommonProps.Center [|lat; lng|]
                       ReactGoogleMap.CommonProps.Zoom 14
                       ReactGoogleMap.CommonProps.YesIWantToUseGoogleMapApiInternals true |]
            [ marker ] ]

let executionAddressPart (executionLocation : Address) form (homelocationSelect : ReactElement option) model dispatch =
    let isDisabled = true
    div [ Class "p-2 disabled-form" ]
      [ match homelocationSelect with
        | Some select -> select
        | None -> Fable.React.Helpers.nothing
        Form.Input.inlineInput(ignore, executionLocation.Street,
                               inputLabel = "Straße", validation = (form.FormValidation, "street"), isDisabled = isDisabled, cyPostFix = "street")
        Form.Input.inlineInput(ignore, executionLocation.HouseNumber,
                               inputLabel = "Hausnummer", isDisabled = isDisabled, cyPostFix = "housenumber")
        Form.Input.inlineInput(ignore, executionLocation.ZipCode,
                               inputLabel = "PLZ", isDisabled = isDisabled, cyPostFix = "zipcode")
        Form.Input.inlineInput(ignore, executionLocation.City,
                               inputLabel = "Stadt", validation = (form.FormValidation, "city"), isDisabled = isDisabled, cyPostFix = "city") ]

let onRoadAddressPart =
    let isDisabled = true
    div [ Class "p-2 disabled-form" ]
      [ Form.Input.inlineInput(ignore, "Unterwegs",
                               inputLabel = "Standort", isDisabled = isDisabled) ]

let private addressPart (form : EntityForm) model dispatch =
    match form.Entity.CurrentLocation with
    | EntityLocation.RentOrderLocation _ -> executionAddressPart form.Entity.CurrentAddress form None model dispatch
    | EntityLocation.ExecutionLocation a -> executionAddressPart a form None model dispatch
    | OnRoadToRentOrder _
    | OnRoadFromRentOrder _
    | EntityLocation.OnRoad _ -> onRoadAddressPart
    | EntityLocation.HomeLocation hId ->
        let address = homeAddress model hId
        let isDisabled = match form.EditField with | EditField.EditAddress -> false | _ -> true
        let homeLocationSelect =
            Some (ReactSelect.selectWithLabel (homeAddressProps form model dispatch isDisabled) (toLabelType "Standort" isDisabled true FormState.View) "label label-right")
        executionAddressPart address form homeLocationSelect model dispatch

let private entityDocumentRow (entity : EntityDetailDto) (isTemplateDocument : bool) dispatch (document : Document)  =
    tr [ Key (string document.Id) ]
        [ td [ Class "entity-document-name" ]
            [ a [ OnClick (fun _ -> dispatch (DownloadDocument (document.Id, document.Name)))
                  Download true ]
                [ str document.Name ] ]
          td [ ]
            [ str document.Description ]
          td [ ]
            [ if isTemplateDocument then
                badgePill "Geräte-Typ" BadgeClassType.Primary
              else badgePill "Gerät" BadgeClassType.Secondary ]
          td [ ]
            [ DocumentDeleteDialog.documentDeleteDialog entity isTemplateDocument document.Id (fun _ -> dispatch Msg.FetchEntity) ]  ]

let private notAvailableRangeRow dispatch (entityId : EntityId) (notAvailableRange : NotAvailableRange) =
    let endDate =
      match notAvailableRange.EndDate with
      | Some endDate -> endDate.ToShortDateString()
      | None -> ""
    tr [ Key (string notAvailableRange.Id) ]
        [ td [ ]
            [ str notAvailableRange.Description ]
          td [ ]
            [ str (notAvailableRange.StartDate.ToShortDateString()) ]
          td [ ]
            [ str endDate ]
          td [ ]
            [ NotAvailableRangeEditDialog.notAvailableRangeEditDialog entityId notAvailableRange (fun _ -> Msg.FetchEntity |> dispatch)
              RemoveNotAvailableRangeDialog.removeNotAvailableRangeDialog entityId notAvailableRange.Id (fun _ -> Msg.FetchEntity |> dispatch)
            ] ]

let private displayedImage (form : EntityForm) =
    match form.EntityImage with
    | Some image ->
        div [ Class "template-uploaded-image-preview"]
            [ img [ Class "card-img-top entity-image"
                    Src (sprintf "data:image/png;base64, %s" image.Content) ] ]
    | None ->
        img [ Src "noimage.png"
              Class "card-img-top entity-image"
              Alt "..." ]

let private logo form isEditOrCreateable model dispatch =
    div [ ]
      [ subHeaderForm "Bild"
        displayedImage form
        if isEditOrCreateable then
            ImageUploadDialog.imageUploadDialog (ImageUploadDialog.TargetType.Entity form.Entity.Id) (fun _ -> dispatch Msg.FetchEntity)
        else Fable.React.Helpers.nothing ]

let private historyRow (history : EntityHistory) =
    let idString, href, orderType, date =
        match history.EntityHistoryOrderType with
        | EntityHistoryOrderType.RentOrder (rentOrderId, endDate) ->
            let date = sprintf "%s - %s" (history.Date.ToShortDateString()) (endDate.ToShortDateString())
            let (RentOrderId id) = rentOrderId
            id.ToString(), (Routes.toPath (Routes.Page.RentOrderDetail id)), "Mietauftrag", date
        | EntityHistoryOrderType.ServiceOrder serviceOrderId ->
            let (ServiceOrderId id) = serviceOrderId
            id.ToString(), (Routes.toPath (Routes.Page.RepairOrderDetail id)), "Serviceauftrag", (history.Date.ToShortDateString())
    let state =
        match history.State with
        | EntityHistoryState.Active -> badgePill "aktiv" BadgeClassType.Success
        | EntityHistoryState.Completed -> badgePill "abgeschlossen" BadgeClassType.Secondary
        | EntityHistoryState.Planned -> badgePill "geplant" BadgeClassType.Warning
    tr [ Key idString ]
        [ td [ ]
            [ str date ]
          td [ ]
            [ badgePill orderType BadgeClassType.Info ]
          td [ ]
            [ p [ ]
                [ str (sprintf "%s %s" history.Address.Street history.Address.HouseNumber) ]
              p [ ]
                [ str (sprintf "%s %s" history.Address.ZipCode history.Address.City) ] ]
          td [ ]
            [ p [ ]
                [ str (sprintf "Projektnummer: %s" history.ProjectNumber) ]
              p [ ]
                [ str (sprintf "Bauvorhaben: %s" history.BuildingProjectName) ] ]
          td [ ]
            [ state ]
          td [ ]
            [ a [ Href href ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Anzeigen" ] ] ] ]

let tabElement (text : string) active (tabName : string) onClick =
    Html.li [
        prop.className "nav-item"
        prop.children [
            Html.a [
                prop.id (sprintf "%s-tab" tabName)
                prop.className [
                    "nav-link"
                    if active then "active" ]
                prop.custom ("data-toggle", "tab")
                prop.text text
                prop.href (sprintf "#%s" tabName)
            ]
        ]
    ]

let private entityEditForm (form : EntityForm) (template : Template) (isEditOrCreatable : bool) (model : Model) (dispatch : Msg -> unit) =
    let entity = form.Entity
    let (TemplateId templateId) = template.Id
    let (EntityId entityId) = entity.Id
    let tableHead (attribute : Attribute) =
        th [ ]
            [ str attribute.Name ]
    let openDeleteCmd =
        match form.Entity.CurrentLocation with
        | OnRoadToRentOrder _
        | EntityLocation.OnRoadFromRentOrder _
        | EntityLocation.OnRoad _
        | EntityLocation.RentOrderLocation _
        | EntityLocation.ExecutionLocation _ -> None
        | EntityLocation.HomeLocation _ -> Some (fun _ -> dispatch OpenDeleteModal)

    let disabledFormClass = if isEditOrCreatable then "disabled-form" else ""
    let copyBtn =
        a [ Href (Routes.toPath (Page.EntityCopyForm (templateId, entityId)) ) ]
            [ span [ Class "dropdown-item" ] [ str "Duplizieren" ] ]
            
    let additionalDropdownBtns =
      [ copyBtn
        NotAvailableRangeDialog.notAvailableRangeDialog form.Entity.Id  (fun _ -> Msg.FetchEntity |> dispatch)
        TransformEntityDialog.transformEntityDialog form.Entity model.Templates (fun (entityId, templateId) -> dispatch (Msg.FetchEntityWithTemplateId (entityId, templateId)))
      ] |> Some
    div [ Id "masterdata-content" ]
      [ if isEditOrCreatable then
            mainHeaderBar additionalDropdownBtns entity.Name None openDeleteCmd
        else mainHeader entity.Name
        div [ Class ("d-flex flex-sm-row flex-column " + disabledFormClass) ]
          [ div [ Class "flex-grow-1"]
              [ div [ Class "p-2"]
                  [ if isEditOrCreatable then
                        subHeaderByFormState (match form.EditField with | EditField.EditName _ -> true | _ -> false)
                            "Allgemein"
                            (fun _ -> dispatch EditName) ""
                            (fun _ -> dispatch (ValidateForm ActionType.SaveName)) ""
                            (fun _ -> dispatch EndEdit) ""
                            FormState.View
                    else subHeaderForm "Allgemein"
                    match form.EditField with
                    | EditField.EditName ->
                        Form.Input.inlineInput((fun ev -> dispatch (SetName ev)), entity.Name,
                           inputLabel = "Name", validation = (form.FormValidation, "entityname"),
                           required = true, isDisabled = false)
                    | _ ->
                        Form.Input.inlineInput((fun ev -> dispatch (SetName ev)), entity.Name,
                           inputLabel = "Name", validation = (form.FormValidation, "entityname"),
                           required = true, isDisabled = true)
                    Form.requiredLegend ] ]
            div [ Class "flex-grow-1"]
              [ div [ Class "p-2"]
                  [ logo form isEditOrCreatable model dispatch ] ] ]
        div [ Class "p-2" ]
            [ match form.Entity.CurrentLocation with
              | OnRoadToRentOrder _
              | OnRoadFromRentOrder _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.OnRoad _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.RentOrderLocation _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.ExecutionLocation _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.HomeLocation _ ->
                  if isEditOrCreatable then
                      subHeaderByFormState (match form.EditField with | EditField.EditAddress _ -> true | _ -> false)
                        "Gerätestandort"
                        (fun _ -> dispatch EditAddress) ""
                        (fun _ -> dispatch (ValidateForm ActionType.SaveAddress)) ""
                        (fun _ -> dispatch EndEdit) ""
                        FormState.View
                  else subHeaderForm "Gerätestandort" ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1"]
              [ addressPart form model dispatch ]
            div [ Class "flex-grow-1"]
              [ overviewMap model entity dispatch ] ]
        div [ Class "p-2" ]
            [ if isEditOrCreatable then
                  subHeaderByFormState (match form.EditField with | EditField.EditAttributes _ -> true | _ -> false)
                      "Eigenschaften"
                      (fun _ -> dispatch EditAttributes) ""
                      (fun _ -> dispatch (ValidateForm ActionType.SaveAttributes)) ""
                      (fun _ -> dispatch EndEdit) ""
                      FormState.View
              else subHeaderForm "Eigenschaften"
              if entity.Attributes.IsEmpty then
                str "Für dieses Gerät existieren keine Eigenschaften. (Eigenschaften müssen in der Baugeräte-Administration definiert werden.)"
              else
                templateAttributes form.DecimalAttributeAsStringHolder entity.Attributes template (match form.EditField with | EditField.EditAttributes _ -> false | _ -> true) model dispatch ]
        match template.ChildTemplates with
        | None -> Fable.React.Helpers.nothing
        | Some children ->
            div [ Class "p-2" ]
                [ subHeaderForm "Teile"
                  div [ Class "table" ]
                    [ table [ Class "table" ]
                        [ thead [ ]
                            [ tr [ ]
                                [ th [ ]
                                    [ str "Name" ]
                                  th [ ]
                                    [ str "Min" ]
                                  th [ ]
                                    [ str "Max" ] ] ]
                          tbody [ ]
                            [ children.Templates
                              |> List.map Some
                              |> List.mapi (referencedTemplateRowView model template dispatch)
                              |> ofList ] ] ] ]

        div [ Class "p-2" ] [
          Html.ul [
            prop.className "nav nav-tabs"
            prop.children [
                tabElement "Dateien" true "files" ignore
                tabElement "Historie" false "history" ignore
                tabElement "außer Betrieb Zeiträume" false "notactiverange" ignore
            ]
          ]
          Html.div [
            prop.className "tab-content mt-3"
            prop.children [
                Html.div [
                    prop.id "files"
                    prop.className "tab-pane active"
                    prop.text "Dateien"
                    prop.children [
                      if isEditOrCreatable then
                          div [ Class "controls d-flex justify-content-end" ]
                                  [ div [ Class "" ]
                                      [ FileUploadDialog.fileUploadDialog (FileUploadDialog.TargetType.Entity entity.Id) (fun _ -> dispatch UploadedDocument) ] ]
                      else Fable.React.Helpers.nothing
                      div [ Class "table-responsive  mt-3" ]
                        [ table [ Class "table" ]
                            [ thead [ ]
                                [ tr [ ]
                                    [ th [ ]
                                        [ str "Name" ]
                                      th [ ]
                                        [ str "Beschreibung"]
                                      th [ ]
                                        [ str "Typ" ]
                                      th [ ]
                                        [ str "Aktion" ] ] ]
                              tbody [ ]
                                [ form.Entity.Documents
                                  |> List.map (entityDocumentRow entity false dispatch)
                                  |> ofList
                                  template.Documents
                                  |> List.map (entityDocumentRow entity  true dispatch)
                                  |> ofList ] ] ]
                    ]
                ]

                Html.div [
                    prop.id "history"
                    prop.className "tab-pane"
                    prop.children [
                      if isEditOrCreatable then
                          div [ Class "p-2 mt-3" ]
                              [ div [ Class "controls d-flex justify-content-end" ]
                                  [ div [ Class "" ]
                                      [ Buttons.primaryButtonWithFnct (fun _ -> dispatch DownloadHistoryCSV) "Historie drucken" "ml-auto" ] ]
                                table [ Class "table" ]
                                  [ thead [ ]
                                      [ tr [ ]
                                          [ th [ ]
                                              [ str "Datum" ]
                                            th [ ]
                                              [ str "Auftragsart" ]
                                            th [ ]
                                              [ str "Adresse" ]
                                            th [ ]
                                              [ str "Projektinformation" ]
                                            th [ ]
                                              [ str "Status"]
                                            th [ ]
                                              [ str "Aktion" ] ] ]
                                    tbody [ ]
                                      [ form.EntityHistory
                                        |> List.map historyRow
                                        |> ofList ] ] ]
                        ]
                    ]

                Html.div [
                    prop.id "notactiverange"
                    prop.className "tab-pane"
                    prop.children [
                      div [ Class "table-responsive mt-3" ]
                        [ table [ Class "table" ]
                            [ thead [ ]
                                [ tr [ ]
                                    [ th [ ]
                                        [ str "Bezeichnung" ]
                                      th [ ]
                                        [ str "Startdatum" ]
                                      th [ ]
                                        [ str "Enddatum" ]
                                      th [ ]
                                        [ str "Aktion" ] ] ]
                              tbody [ ]
                                [ form.Entity.NotAvailableRanges
                                  |> List.map (notAvailableRangeRow dispatch form.Entity.Id )
                                  |> ofList ] ]
                          ]
                        ]
                    ]
                ]
            ]
        ]
      ]

let private entityDeactivatedForm (form : EntityForm) (template : Template) (isEditOrCreatable : bool) (model : Model) (dispatch : Msg -> unit) =
    let entity = form.Entity
    let tableHead (attribute : Attribute) =
        th [ ]
            [ str attribute.Name ]
    let activeStateBtn =
        dropDownButton (fun _ -> ActivateDeletedEntity |> dispatch) false "aktivieren"

    div [ Id "masterdata-content" ]
      [ mainHeaderBar (Some [ activeStateBtn ]) "Gerät" None None
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1"]
              [ div [ Class "p-2"]
                  [ subHeaderForm "Allgemein"
                    Form.Input.inlineInput((fun ev -> dispatch (SetName ev)), entity.Name,
                       inputLabel = "Name", validation = (form.FormValidation, "entityname"),
                       required = true, isDisabled = true) ] ]
            div [ Class "flex-grow-1"]
              [ div [ Class "p-2"]
                  [ logo form false model dispatch ] ] ]
        div [ Class "p-2" ]
            [ match form.Entity.CurrentLocation with
              | OnRoadToRentOrder _
              | OnRoadFromRentOrder _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.OnRoad _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.RentOrderLocation _ -> subHeaderForm "Gerätestandort"
              | ExecutionLocation _ -> subHeaderForm "Gerätestandort"
              | EntityLocation.HomeLocation _ -> subHeaderForm "Gerätestandort" ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1"]
              [ addressPart form model dispatch ]
            div [ Class "flex-grow-1"]
              [ overviewMap model entity dispatch ] ]
        div [ Class "p-2" ]
            [ subHeaderForm "Eigenschaften"
              templateAttributes form.DecimalAttributeAsStringHolder entity.Attributes template (match form.EditField with | EditField.EditAttributes _ -> false | _ -> true) model dispatch ]
        match template.ChildTemplates with
        | None -> Fable.React.Helpers.nothing
        | Some children ->
            div [ Class "p-2" ]
                [ subHeaderForm "Teile"
                  div [ Class "table" ]
                    [ table [ Class "table" ]
                        [ thead [ ]
                            [ tr [ ]
                                [ th [ ]
                                    [ str "Name" ]
                                  th [ ]
                                    [ str "Min" ]
                                  th [ ]
                                    [ str "Max" ] ] ]
                          tbody [ ]
                            [ children.Templates
                              |> List.map Some
                              |> List.mapi (referencedTemplateRowView model template dispatch)
                              |> ofList ] ] ] ]
        div [ Class "p-2" ]
            [ subHeaderForm "Dateien"
              div [ Class "table-responsive" ]
                [ table [ Class "table" ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ ]
                                [ str "Name" ]
                              th [ ]
                                [ str "Beschreibung"]
                              th [ ]
                                [ str "Typ" ] ] ]
                      tbody [ ]
                        [ form.Entity.Documents
                          |> List.map (entityDocumentRow entity false dispatch)
                          |> ofList
                          template.Documents
                          |> List.map (entityDocumentRow entity true dispatch)
                          |> ofList ] ] ] ]
        if isEditOrCreatable then
            div [ Class "p-2" ]
                [ subHeaderForm "Historie"
                  table [ Class "table" ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ ]
                                [ str "Datum" ]
                              th [ ]
                                [ str "Auftragsart" ]
                              th [ ]
                                [ str "Adresse" ]
                              th [ ]
                                [ str "Projektinformation" ]
                              th [ ]
                                [ str "Status"]
                              th [ ]
                                [ str "Aktion" ] ] ]
                      tbody [ ]
                        [ form.EntityHistory
                          |> List.map historyRow
                          |> ofList ] ] ]
        else Fable.React.Helpers.nothing ]

let entityFormBreadcrumb (template : Template) (form : EntityNewForm) =
    let (TemplateId templateId) = template.Id
    let x = templateId |> Page.TemplatesViewForm |> Routes.toPath
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
        breadcrumbStr form.Entity.Name
    ]

let entityEditFormBreadcrumb (template : Template) (form : EntityForm) =
    let (TemplateId templateId) = template.Id
    let x = templateId |> Page.TemplatesViewForm |> Routes.toPath
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
        //breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) "Geräte"
        breadcrumbStr form.Entity.Name
    ]

let entityDeactivatedFormBreadcrumb (template : Template) (form : EntityForm) =
    let (TemplateId templateId) = template.Id
    let x = templateId |> Page.TemplatesViewForm |> Routes.toPath
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
        breadcrumbLink (templateId |> Page.DeactivateEntity |> Routes.toPath) (sprintf "Gelöscht %s" template.TemplateBaseInformation.Name)
        //breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) "Geräte"
        breadcrumbStr form.Entity.Name
    ]

let private deleteEntityModal (model : Model) dispatch =
    match model.DeleteRequested with
    | None ->
        deleteRequestMessageBox Fable.React.Helpers.nothing
    | Some id ->
        let displayText = sprintf "Wollen Sie das Gerät wirklich löschen? Das Gerät wird in den gelöscht Ordner geschoben."
        deleteRequestMessageBox
            (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                            (fun _ -> dispatch (AbortDelete))
                                            displayText)

let entityFormView templateId model (dispatch : Msg -> unit) newOrEdit =
    let isEditOrCreatable = isEntityEditableOrCreatable model.UserData
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ section [ ]
            [ deleteEntityModal model dispatch ]
          overlaySpinner model.EntityRequestState
          match model.FormState, model.Template with
          | MasterData.Entities.Types.FormState.New form, Some template ->
              entityFormBreadcrumb template form
              entityNewForm form template model dispatch
          | MasterData.Entities.Types.FormState.Edit form, Some template ->
              match form.Entity.ActiveState with
              | EntityActiveState.Active ->
                  entityEditFormBreadcrumb template form
                  entityEditForm form template isEditOrCreatable model dispatch
              | EntityActiveState.NotActive ->
                  entityDeactivatedFormBreadcrumb template form
                  entityDeactivatedForm form template isEditOrCreatable model dispatch
          | MasterData.Entities.Types.FormState.Edit form, _ -> overlaySpinner SharedComponents.Spinners.RequestState.Active
          | MasterData.Entities.Types.FormState.New form, _ -> overlaySpinner SharedComponents.Spinners.RequestState.Active
          | Loading, _ -> overlaySpinner SharedComponents.Spinners.RequestState.Active ]

let entityCopyView templateId model (dispatch : Msg -> unit) newOrEdit =
    let isEditOrCreatable = isEntityEditableOrCreatable model.UserData
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ str "todo" ]

open Sww.Frontend.ReactTable

let entityOverview (entities : EntityForTemplateOverviewDto list) (template : Template) (model : Model) dispatch =
    let isEditOrCreatable = isEntityEditableOrCreatable model.UserData
    let (TemplateId templateId) = template.Id

    let detailBtn (entity : EntityForTemplateOverviewDto) =
        let (EntityId entityId) = entity.Id
        a [ Href (Routes.toPath (Page.EntityViewForm (templateId, entityId)) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    Html.div [
        prop.id "masterdata-content"
        prop.children [
            mainHeader (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
            if isEditOrCreatable then
                Html.div [
                    prop.className "controls d-flex justify-content-end"
                    prop.children [
                        Html.div [
                            Buttons.primaryButton (Routes.toPath (Page.EntityNewForm templateId)) "Neues Baugerät anlegen" "ml-auto"
                        ]
                    ]
                ]
            else Fable.React.Helpers.nothing
            reactTable [
                tableProp.keyField "Id"
                tableProp.defaultSortField ("Name", "asc")
                tableProp.rows entities
                tableProp.tableKey "entities"
                tableProp.columns [
                    yield tableColumn [
                        colProp.selector "Name"
                        colProp.label "Name"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    for attribute in template.Attributes do
                        match attribute.Type with
                        | AttributeType.Integer _
                        | AttributeType.Decimal _
                        | AttributeType.String _
                        | AttributeType.OptionalInteger _
                        | AttributeType.OptionalDecimal _
                        | AttributeType.OptionalString _
                        | AttributeType.OptionalBoolean _
                        | AttributeType.Boolean ->
                          yield tableColumn [
                              colProp.selector (attributeIdString attribute.Id)
                              colProp.label attribute.Name
                              colProp.sortFunction (CustomNaturalSort (fun (entity : EntityForTemplateOverviewDto) -> attributeValueAsString attribute.Id entity))
                              colProp.filter (CustomTextFilter(fun entity -> attributeValueAsString attribute.Id entity))
                              colProp.formatter (Custom(fun value entity -> attributeValue attribute.Id entity))
                          ]
                        | AttributeType.Date _
                        | AttributeType.DateWithReminder _
                        | AttributeType.OptionalDate _
                        | AttributeType.OptionalDateWithReminder -> 
                          yield tableColumn [
                              colProp.selector (attributeIdString attribute.Id)
                              colProp.label attribute.Name
                              colProp.sortFunction (DateSort (fun (entity : EntityForTemplateOverviewDto) ->
                                let optionalDate = entityAttributeValueOptinalDateTime attribute.Id entity
                                match optionalDate with
                                | Some date -> date
                                | None -> DateTime.UtcNow.AddYears 100))
                              colProp.filter (CustomTextFilter(fun entity -> attributeValueAsString attribute.Id entity))
                              colProp.formatter (Custom(fun value entity -> attributeValue attribute.Id entity))
                          ]
                    yield tableColumn [
                        colProp.selector "CurrentLocation"
                        colProp.label "Standort"
                        colProp.sortFunction (CustomNaturalSort (fun (entity : EntityForTemplateOverviewDto) -> entity |> entityCurrentLocationAsString))
                        colProp.csvFormatter((fun value a -> a |> entityCurrentLocationAsString |> str))
                        colProp.filter (
                            SelectFilter (
                                selectOptions, (fun (a : EntityForTemplateOverviewDto) -> a |> entityCurrentLocationAsString)))
                        colProp.formatter (Custom (fun value entity -> locationBadge entity))
                    ]
                    yield tableColumn [
                        colProp.selector "Aktion"
                        colProp.label "Aktion"
                        colProp.formatter (Custom (fun value order -> detailBtn order))
                        colProp.csvPrint false
                    ]
                ]
            ]
            Html.div [
                prop.className "controls d-flex justify-content-end"
                prop.children [
                    Html.div [
                        Buttons.primaryButton (Routes.toPath (Page.DeactivateEntity templateId)) "Gelöscht" "ml-auto btn-sm"
                    ]
                ]
            ]
        ]
    ]

let entityOverviewBreadcrumb (template : Template) =
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbStr (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
    ]

let entityView templateId (model : Model) (dispatch : Msg -> unit) =
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.EntityRequestState
          (match model.Template with
           | Some template ->
              div [ ]
                [ entityOverviewBreadcrumb template
                  entityOverview model.Entities template model dispatch ]
           | None -> overlaySpinner SharedComponents.Spinners.RequestState.Active ) ]

let entityDeactivatedOverviewBreadcrumb (template : Template) =
    let (TemplateId templateId) = template.Id
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbLink (templateId |> Page.Entity |> Routes.toPath) (sprintf "Übersicht %s" template.TemplateBaseInformation.Name)
        breadcrumbStr (sprintf "Gelöscht %s" template.TemplateBaseInformation.Name)
    ]

let deactivatedEntityOverview (entities : EntityForTemplateOverviewDto list) (template : Template) (model : Model) dispatch =
    let (TemplateId templateId) = template.Id
    let detailBtn (entity : EntityForTemplateOverviewDto) =
        let (EntityId entityId) = entity.Id
        a [ Href (Routes.toPath (Page.EntityViewForm (templateId, entityId)) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    Html.div [
        prop.id "masterdata-content"
        prop.children [
            mainHeader (sprintf "%s Gelöscht" template.TemplateBaseInformation.Name)
            reactTable [
                tableProp.keyField "Id"
                tableProp.defaultSortField ("Name", "asc")
                tableProp.rows entities
                tableProp.tableKey "entities"
                tableProp.tableExportName "baugeraete_export.csv"
                tableProp.columns [
                    yield tableColumn [
                        colProp.selector "Name"
                        colProp.label "Name"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    for attribute in template.Attributes do
                        yield tableColumn [
                            colProp.selector (attributeIdString attribute.Id)
                            colProp.label attribute.Name
                            colProp.sortFunction (CustomNaturalSort (fun (entity : EntityForTemplateOverviewDto) -> attributeValueAsString attribute.Id entity))
                            colProp.filter (CustomTextFilter(fun entity -> attributeValueAsString attribute.Id entity))
                            colProp.formatter (Custom(fun value entity -> attributeValue attribute.Id entity))
                        ]
                    yield tableColumn [
                        colProp.selector "CurrentLocation"
                        colProp.label "Standort"
                        colProp.sortFunction NaturalSort
                        colProp.csvFormatter((fun value a -> 
                          match a.CurrentLocation with
                          | EntityLocationForTemplateOverviewDto.RentOrderLocation -> str "Baustelle"
                          | EntityLocationForTemplateOverviewDto.HomeLocation -> str "Lager"
                          | OnRoad -> str "unterwegs"
                          | NotAvaiable -> str "außer Betrieb"))
                        colProp.filter (
                            SelectFilter (
                                selectOptions, (fun (a : EntityForTemplateOverviewDto) ->
                                    match a.CurrentLocation with
                                    | EntityLocationForTemplateOverviewDto.RentOrderLocation -> "Baustelle"
                                    | EntityLocationForTemplateOverviewDto.HomeLocation -> "Lager"
                                    | OnRoad -> "unterwegs"
                                    | NotAvaiable -> "außer Betrieb")))
                        colProp.formatter (Custom (fun value entity -> locationBadge entity))
                    ]
                    yield tableColumn [
                        colProp.selector "Aktion"
                        colProp.label "Aktion"
                        colProp.formatter (Custom (fun value order -> detailBtn order))
                        colProp.csvPrint false
                    ]
                ]
            ]
        ]
    ]



let deactivateEntityView templateId (model : Model) (dispatch : Msg -> unit) =
    div [ Id "masterdata-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.EntityRequestState
          (match model.Template with
           | Some template ->
              div [ ]
                [ entityDeactivatedOverviewBreadcrumb template
                  deactivatedEntityOverview model.Entities template model dispatch ]
           | None -> overlaySpinner SharedComponents.Spinners.RequestState.Active) ]