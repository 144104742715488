module Order.ServiceOrder.Helper

open Order.ServiceOrder.Types
open SharedComponents.DeleteMsgBox
open Shared.Entity
open Shared.Configuration
open Shared.Address
open Validation
open Elmish
open SharedComponents.Toast
open Shared
open SharedComponents
open Shared.ServiceOrder
open Routes
open SharedComponents.Badges
open Shared.TaskTypes

let isServiceOrderEditableOrCreatable (userData : UserData) =
    userData.AssignedRoles
    |> List.exists (fun r -> r = Administrator || r = Dispatcher)

let addressToastCmd validation =
    match validation.Validations |> List.tryFind (fun v -> v.Key = "address") with
    | Some v ->
        match v.Value with
        | ValidationState.NotValid e -> toast (ToastType.Error e)
        | Valid -> Cmd.none
    | None -> Cmd.none

let deleteModal (formState : FormState) dispatch =
    match formState with
    | FormState.Edit form ->
        match form.DeleteRequested with
        | None ->
            deleteRequestMessageBox Fable.React.Helpers.nothing
        | Some id ->
            let displayText = sprintf "Wollen Sie den Serviceauftrag wirklich löschen?"
            deleteRequestMessageBox
                (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                                (fun _ -> dispatch (AbortDelete))
                                                displayText)
    | FormState.New _
    | FormState.Loading -> Fable.React.Helpers.nothing

module Table =
    open Fable.React.Helpers
    open Fable.React
    open Fable.React.Props

    let detailBtn (serviceOrder : ServiceOrderOverviewDto) =
        let (ServiceOrderId serviceOrderId) = serviceOrder.Id
        a [ Href (Routes.toPath (Page.RepairOrderDetail serviceOrderId) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    let entitiesToDisplayAsString (order : ServiceOrderOverviewDto) =
        if order.EntityNames.IsEmpty then "lädt ..."
        else
            if order.EntityNames.Length > 3 then
                sprintf "%s, %s, %s, ..." order.EntityNames.[0] order.EntityNames.[1] order.EntityNames.[2]
            else String.concat ", " order.EntityNames

    let stateBadge (serviceOrder : ServiceOrderOverviewDto) =
        match serviceOrder.State with
        | ServiceOrderState.Undisposed -> badgePill "erfasst" BadgeClassType.Warning
        | ServiceOrderState.Disposed -> badgePill "aktiv" BadgeClassType.Success
        | ServiceOrderState.Completed -> badgePill "abgeschlossen" BadgeClassType.Secondary

    let selectOptions =
        [ "erfasst"
          "aktiv"
          "abgeschlossen" ]

    let selectFilter (selectedValue : string) (serviceOrders : ServiceOrderOverviewDto[]) =
        if selectedValue = "0" then
            serviceOrders
            |> Array.filter (fun order ->
                match order.State with
                | Undisposed -> true
                | Disposed _
                | Completed -> false)
        elif selectedValue = "1" then
            serviceOrders
            |> Array.filter (fun order ->
                match order.State with
                | Disposed -> true
                | Undisposed _
                | Completed -> false)
        elif selectedValue = "2" then
            serviceOrders
            |> Array.filter (fun order ->
                match order.State with
                | Completed -> true
                | Disposed _
                | Undisposed -> false)
        else serviceOrders