module Dispositions.FreeOrderDisposition.View

open Fable.React
open Fable.React.Props
open Dispositions.FreeOrderDisposition.Types
open Shared
open Shared.FreeOrder
open Shared.TaskTypes
open Shared.Entity
open SharedComponents.Headers
open Shared.ServiceOrder
open Routes
open Shared.TaskTypes.Helper
open SharedComponents.Breadcrumb
open SharedComponents
open SharedComponents.ReactImageViewer
open SharedComponents.ReactImgOrientation
open SharedComponents.Spinners

let private employeeName (employees : Employee list) (employeeId : int) =
    match employees |> List.tryFind (fun e -> e.Id = employeeId) with
    | Some employee -> sprintf "%s %s" employee.Firstname employee.Lastname
    | None -> ""

let private executionDate (report : RentOrderTaskReport) =
    report.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm")
    
let private deliveryDispositionDescriptionField (model : Model) (task : AssignedFreeTask) (report : RentOrderTaskReport)=
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Eigene Aufgabe"
          div [ Class "d-flex" ]
            [ div [ Class "mr-3" ]
                  [ p [ ] [ str (sprintf "Ausführungsdatum: %s" (report |> executionDate)) ]
                    p [ ] [ str (sprintf "Ausführer: %s" (employeeName model.Employees task.UserId) ) ] ]] ]

let private orderDescription (model : Model) =
    let freeOrder = model.FreeOrder.Value
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Adresse"
          div [ Class "d-flex" ]
            [ div [ Class "mr-3" ]
                    [ p [ ] [ str (sprintf "%s %s" freeOrder.ExecutionAddress.Street freeOrder.ExecutionAddress.HouseNumber) ]
                      p [ ] [ str (sprintf "%s %s" freeOrder.ExecutionAddress.ZipCode freeOrder.ExecutionAddress.City) ] ]
            ] ]

let private signatureField (model : Model) (signature : Image option) (signer :string option) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kundenunterschrift"
          match signature, model.Signature with
          | Some signature, Some signatureDoc ->
              div [ Class "d-flex flex-column" ]
                [ div [ Class "signature-image-container" ]
                    [ img [ Class "signature-image"
                            HTMLAttr.Src (sprintf "data:image/png;base64, %s" signatureDoc.Content) ] ]
                  match signer with
                  | Some signer ->
                        div [ ]
                            [ str (sprintf "Name: %s" signer) ]
                  | None -> Fable.React.Helpers.nothing ]
          | _, _ -> div [ ]
                      [ p [ ] [ str "Es ist keine Unterschrift vorhanden." ] ] ]

let private commentField comment =
    let commentContent =
        if System.String.IsNullOrWhiteSpace comment then "Es ist kein Kommentar vorhanden." else comment
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kommentar"
          div [ Class "d-flex" ]
            [ p [ Class "mr-3" ]
                [ str commentContent ] ] ]

let private imagesField (images : Image list) (imageDocuments : DocumentWithContent list) model dispatch =
    let imageContainer index (image : Image) =
        let imageDoc = imageDocuments |> List.tryFind (fun i -> i.Id = image.Id)
        match imageDoc with
        | Some imageDoc ->
            div [ Class "signature-image-container" ]
                [ a [ OnClick (fun _ -> index |> OpenImageViewer |> dispatch)
                      Class "img-thumbnail-href" ]
                    [ ReactImgOrientation.image [ ClassName "signature-image img-thumbnail"
                                                  Src (sprintf "data:image/png;base64, %s" imageDoc.Content) ] ] ]
        | None -> nothing
    let optionalInfoText =
        if images.IsEmpty then str "Es sind keine Fotos vorhanden" else Fable.React.Helpers.nothing
    let viewer =
        let images =
            images |> List.choose (fun image ->
                let imageDoc = imageDocuments |> List.tryFind (fun i -> i.Id = image.Id)
                match imageDoc with
                | Some doc ->
                    { ImageDecorator.src = (sprintf "data:image/png;base64, %s" doc.Content) } |> Some
                | None -> None)
            |> List.toArray
        imageViewer [ CommonProps.ClassName "img-viewer"
                      Images images
                      Visible model.ImageViewerOpen
                      ActiveIndex model.ImageViewerIndex
                      OnClose (fun _ -> dispatch CloseImageViewer) ]
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Fotos"
          div [ Class "d-flex" ]
            [ div [ Class "d-flex image-container" ]
                [ images |> List.mapi imageContainer |> ofList
                  viewer
                  optionalInfoText ] ] ]

let private dispositionView model (task : AssignedFreeTask) (report : RentOrderTaskReport) dispatch =
    let comment, signature, signer, images = report.Comment, report.Signature, report.Signer, report.Images

    div [ Class "d-flex flex-column flex-grow-1" ]
      [ div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ deliveryDispositionDescriptionField model task report ]
            div [ Class "flex-grow-1 p-2"]
              [ orderDescription model ] ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ commentField comment ]
            div [ Class "flex-grow-1 p-2"]
              [ signatureField model signature signer ] ]
        div [ Class "d-flex flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ imagesField images model.Images model dispatch ] ] ]

let dispositionBreadcrumb (freeOrderId : FreeOrderId) =
    let (FreeOrderId freeOrderId) = freeOrderId
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RepairOrderOverview |> Routes.toPath) "Eigene Aufträge"
        breadcrumbLink (freeOrderId |> Page.FreeOrderDetail |> Routes.toPath) "Eigener Auftrag"
        breadcrumbStr "Report"
    ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "repair-order-report"
          Class "flex-grow-1 d-flex flex-column" ]
        [ SharedComponents.Spinners.overlaySpinner model.RequestState

          dispositionBreadcrumb model.FreeOrderId
          div [ Id "order-content"]
            [ mainHeaderBar None "Disposition" (Some (fun _ -> dispatch PrintAsPdf)) None
              match model.FreeOrder with
              | Some freeOrder ->
                  let task = freeOrder.Tasks |> List.find(fun t -> (t |> Shared.TaskTypes.Helper.freeOrderTaskId) = model.TaskId)
                  match task with
                  | FreeOrderTask.FreeTask _ -> Fable.React.Helpers.nothing
                  | FreeOrderTask.AssignedFreeTask assignedFreeTask ->
                      match assignedFreeTask.TaskReport with
                      | None -> Fable.React.Helpers.nothing
                      | Some report -> dispositionView model assignedFreeTask report  dispatch 
              | None -> Fable.React.Helpers.nothing
             ] ]
