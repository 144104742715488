module Reports.State

open Elmish
open Reports.Types
open Shared
open SharedComponents.Spinners
open Shared.Reports
open System

let getInitialReportDataCmd =
    Cmd.OfPromise.either Communication.getRequest<InitialReportData> "/api/reports/initialdata" InitialReportDataFetched FetchError

let getReportResultDataCmd (dto : ReportDtoTypes) =
    let body = dto |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<ReportResult> ("/api/reports/result", body) ReportResultFetched FetchError

let init (user : UserData) : Model * Cmd<Msg> =
    let initialModel : Model =
        { SelectedReportNum = None
          InitialReportData = None  
          SelectedTemplateId = None
          StartDate = DateTime.UtcNow
          EndDate = DateTime.UtcNow
          ReportResult = None
          ReportFetchState = RequestState.Active }
    initialModel, getInitialReportDataCmd

let update (msg:Msg) (model : Model) : Model*Cmd<Msg> =
    match msg with
    | SelectReport id ->
        match id with
        | Some id ->
            { model with SelectedReportNum = id.value |> Some }, Cmd.none
        | None -> model, Cmd.none
    | SelectTemplateId id ->
        match id with
        | Some id ->
            { model with SelectedTemplateId = id.value |> Some }, Cmd.none
        | None -> model, Cmd.none
    | SetStartDate startDate ->
        { model with StartDate = startDate }, Cmd.none
    | SetEndDate endDate ->
        { model with EndDate = endDate }, Cmd.none
    | RequestReport ->
        match model.SelectedReportNum with
        | Some id ->
            match id with
            | 1 ->
                match model.SelectedTemplateId with
                | Some templateId ->
                    let dto =
                        { TemplateId = templateId
                          StartDate = model.StartDate
                          EndDate = model.EndDate } |> ReportDtoTypes.AvailableTemplateDateRangeReport
                    { model with ReportFetchState = RequestState.Active }, getReportResultDataCmd dto
                | None -> model, Cmd.none
            | _ -> model, Cmd.none 
        | None -> model, Cmd.none
    | InitialReportDataFetched response ->
        { model with InitialReportData = response |> Some
                     ReportFetchState = RequestState.NotActive }, Cmd.none
    | ReportResultFetched response ->
        { model with ReportResult = response |> Some
                     ReportFetchState = RequestState.NotActive }, Cmd.none
    | FetchError e ->
        model, Cmd.none
