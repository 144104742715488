module SharedComponents.Types

open Fable.React
open Fable.React.Props

type FormState =
    | New
    | View
    | Edit

type LabelType =
    { FormState : FormState
      IsRequired : bool
      Value : string
      IsDisabled : bool }

module Helpers =

    let toLabelType value isDisabled isRequired formState =
        { FormState = formState
          IsRequired = isRequired
          Value = value
          IsDisabled = isDisabled }

    let labelText required =
        let requiredText = " *"
        let labelText =
            match required.FormState with
            | New _
            | Edit -> required.Value, Some requiredText
            | View ->
                match required.IsDisabled with
                | true -> required.Value, None
                | false -> required.Value, Some requiredText
        match required.IsRequired with
        | true -> labelText
        | false -> required.Value, None

    let requiredSpan requiredOption =
        match requiredOption with
        | Some v ->
          span [ Class "required-asterisk"]
            [ str v ]
        | None -> Fable.React.Helpers.nothing


/// http://www.fssnip.net/7X8/title/Natural-Sort-Order
module NaturalOrder =
    module private Impl =
        open System
        open System.Text.RegularExpressions

        let regex = Regex ("([0-9]+)")

        let trimLeadingZeros (s: string) =
            s.TrimStart '0'

        let toChars (s: string) =
            s.ToCharArray()

        let split text =
            text
            |> regex.Split
            |> Seq.filter (fun s -> s.Length > 0)
            |> Seq.toList

        let compareStrings (s1: string) (s2: string) =
            // each string is either all letters or all numbers
            let isNumeric1 = Char.IsDigit s1.[0]
            let isNumeric2 = Char.IsDigit s2.[0]

            // If we have a string and a number, the number comes first. When we have
            // two strings, compare them normally. The tricky case is two numbers.
            match isNumeric1, isNumeric2 with
            | true, false -> -1
            | false, true -> 1
            | false, false -> String.Compare (s1, s2, true)
            | true, true ->
                // leading zeros will trip us up, get rid of them
                let n1, n2 = trimLeadingZeros s1, trimLeadingZeros s2
                if n1.Length < n2.Length then -1
                elif n2.Length < n1.Length then 1
                else
                    // compare digit-by-digit
                    let chars1, chars2 = toChars n1, toChars n2
                    let result =
                        chars2
                        |> Seq.zip chars1
                        |> Seq.tryPick (fun (c1, c2) ->
                            if c1 < c2 then Some -1
                            elif c2 < c1 then Some 1
                            else None)
                    match result with
                    | Some i -> i
                    | None -> 0

        type Pair = {
            Name: string
            Pieces: string[]
        }

    open Impl

    let sortAsc (a : string) (b: string) =
        let b = b |> split
        let a = a |> split
        List.compareWith compareStrings a b

    let sortDesc (a : string) (b: string) =
        let b = b |> split
        let a = a |> split

        List.compareWith compareStrings b a

    let customSort<'a> (a : string) (b : string) (order : string) _ _ _ =
        if order = "asc" then
            sortAsc a b
        else
            sortDesc a b