module Configuration.Company.View

open System
open Fable.React
open Fable.React.Props
open Configuration.Company.Types
open SharedComponents.Headers
open SharedComponents
open Shared.Configuration
open SharedComponents.FileUploads
open SharedComponents.Buttons
open Fable.Core.JsInterop
open Elmish.React.Common

open Shared
open SharedComponents.Spinners
open Shared.Address


let private baseInformation (baseInformation : CompanyBaseInformation) (currentEditField : EditField) dispatch =
    let baseInformationDisabled = (match currentEditField with | EditField.EditBaseInformation _ -> false | _ -> true)
    let formDisabledClass = if baseInformationDisabled then "disabled-form" else ""
    div [ Class ("p-2 " + formDisabledClass) ]
      [ subHeaderByFormState (match currentEditField with | EditField.EditBaseInformation _ -> true | _ -> false)
            "Basisinformationen"
            (fun _ -> EditBaseInformation |> dispatch) "companydata"
            (fun _ -> SaveCompany |> dispatch) "companydata"
            (fun _ -> EndEdit |> dispatch) ""
            SharedComponents.Types.FormState.View
        Form.Input.inlineInput((fun ev -> dispatch (SetCompanyName ev)), baseInformation.Name,
                               inputLabel = "Unternehmensname", isDisabled = baseInformationDisabled, cyPostFix = "companyname")
        Form.Input.inlineInput((fun ev -> dispatch (SetEmailAddress ev)), baseInformation.EmailAddress,
                               inputLabel = "E-Mail-Adresse", isDisabled = baseInformationDisabled, cyPostFix = "companyemailaddress")
        Form.Input.inlineInput((fun ev -> dispatch (SetWebsite ev)), baseInformation.Website,
                               inputLabel = "Webseite", isDisabled = baseInformationDisabled, cyPostFix = "companywebsite")
        Form.Input.inlineInput((fun ev -> dispatch (SetPhoneNumber ev)), baseInformation.PhoneNumber,
                               inputLabel = "Telefon", isDisabled = baseInformationDisabled, cyPostFix = "companyphonenumber")
        Form.Input.inlineInput((fun ev -> dispatch (SetFaxNumber ev)), baseInformation.FaxNumber,
                               inputLabel = "Fax", isDisabled = baseInformationDisabled, cyPostFix = "companyfaxnumber") ]

let placeholderImg =
    img [ Src "noimage.png"
          Class "card-img-top configuration-company-img"
          Alt "..." ]

let private displayedImage (form : ConfigurationForm) =
    match form.CompanyImage with
    | Some image ->
        div [ Class "template-uploaded-image-preview"]
            [ img [ Class "card-img-top configuration-company-img"
                    Src (sprintf "data:image/png;base64, %s" image.Content) ] ]
    | None -> placeholderImg

let private logo form model dispatch =
    div [ Class "p-2" ]
      [ subHeaderForm "Bild"
        displayedImage form
        fileUploadWithFnct (fun e -> dispatch ((FromFile (SetField, e?target))))
        (match model.DocumentUploadForm with
         | Some uf -> div [ Class "form-group" ]
                        [ primaryButtonWithFnctAndIsDisabled (fun _ -> dispatch UploadImage) model.DocumentUploadForm.IsNone "Hochladen" "ml-auto"]
         | None -> Fable.React.Helpers.nothing) ]

let private homeAddressInformation (address : Address) (currentEditField : EditField) dispatch =
    let homeAddress = address
    let addressPartDisabled = (match currentEditField with | EditField.EditAddress _ -> false | _ -> true)
    let formDisabledClass = if addressPartDisabled then "disabled-form" else ""
    div [ Class ("p-2 " + formDisabledClass) ]
      [ subHeaderByFormState (match currentEditField with | EditField.EditAddress _ -> true | _ -> false)
          "Hausanschrift"
          (fun _ -> EditAddress |> dispatch) "homeaddress"
          (fun _ -> dispatch SaveCompany) "homeaddress"
          (fun _ -> EndEdit |> dispatch) ""
          SharedComponents.Types.FormState.View
        Form.Input.inlineInput((fun ev -> dispatch (SetHomeStreet ev)), homeAddress.Street,
                               inputLabel = "Straße", isDisabled = addressPartDisabled, cyPostFix = "homestreet")
        Form.Input.inlineInput((fun ev -> dispatch (SetHomeHouseNumber ev)), homeAddress.HouseNumber,
                               inputLabel = "Hausnummer", isDisabled = addressPartDisabled, cyPostFix = "homehousenumber")
        Form.Input.inlineInput((fun ev -> dispatch (SetHomeZipCode ev)), homeAddress.ZipCode,
                               inputLabel = "Postleitzahl", isDisabled = addressPartDisabled, cyPostFix = "homezipcode")
        Form.Input.inlineInput((fun ev -> dispatch (SetHomeCity ev)), homeAddress.City,
                               inputLabel = "Stadt", isDisabled = addressPartDisabled, cyPostFix = "homecity") ]

let private form (c : ConfigurationForm) (model : Model) dispatch =
    div [ ]
        [ div [ Class "flex-row p-2 flex-column"]
            [ mainHeader "Unternehmen" ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ let baseInfoView baseInfo =
                    baseInformation c.Configuration.Company.CompanyBaseInformation c.EditField dispatch
                  lazyView baseInfoView (c.Configuration.Company.CompanyBaseInformation, c.EditField) ]
              div [ Class "flex-grow-1"]
                [ logo c model dispatch ] ]
          div [ Class "flex-sm-row flex-column p-2" ]
            [ let addressView address =
                  homeAddressInformation c.Configuration.Company.HomeAddress c.EditField dispatch
              lazyView addressView (c.Configuration.Company.HomeAddress, c.EditField) ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ ]
      [ overlaySpinner model.RequestState
        div [ Class "d-flex flex-grow-1 flex-column p-2" ]
         [ (match model.FormState with
            | Edit c -> form c model dispatch
            | Loading -> Fable.React.Helpers.nothing) ] ]
