module Shared.Address

type Coordinates =
    { Latitude : float
      Longitude : float }

type Address =
    { CompanyName : string option
      ContactPerson : string option
      Street : string
      HouseNumber : string
      City : string
      ZipCode : string
      Coordinates : Coordinates option }

type StoredAddress =
    { Id : AddressId
      CompanyName : string option
      ContactPerson : string option
      Street : string
      HouseNumber : string
      City : string
      ZipCode : string
      Coordinates : Coordinates option }

type DistanceRequestDto =
    { Origin : Address
      Destination : Address
      TaskId : TaskId }

type Location =
    { Id : LocationId
      Name : string
      Address : Address }

let emptyAddress =
    { CompanyName = None
      ContactPerson = None
      Street = ""
      HouseNumber = ""
      City = ""
      ZipCode = ""
      Coordinates = None }

module Helper =
    let toStoredAddress (address : Address) id =
        { Id = id
          CompanyName = address.CompanyName
          ContactPerson = address.ContactPerson
          Street = address.Street
          HouseNumber = address.HouseNumber
          City = address.City
          ZipCode = address.ZipCode
          Coordinates = address.Coordinates }