module TemplateConfiguration.FormValidation

open Shared
open TemplateConfiguration.Types
open SharedComponents.Alerts
open Validation
open System
open Shared.Entity

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let create key value : ValidationKeyValue =
    { Key = key
      Value = value }

let templateName template =
    if (String.IsNullOrWhiteSpace template.TemplateBaseInformation.Name) then
        create "templatename" (ValidationState.NotValid "Ein Name muss vergeben sein.")
    else create "templatename" ValidationState.Valid

let validateTemplateName _ =
    { Validations = [ create "templatename" (ValidationState.NotValid "Dieser Name ist bereits vergeben.") ]
      FormValid = ValidationState.NotValid "" }

let templateAttributesDuplicateName (template : Template) =
    let hasDuplicateNames =
        template.Attributes
        |> List.groupBy(fun a -> a.Name.ToLower())
        |> List.exists (fun (x, y) -> y.Length > 1 )
    if hasDuplicateNames then
        create "duplicateattributename" (ValidationState.NotValid "Die Namen der Eigenschaften müssen eindeutig sein.")
    else create "duplicateattributename" ValidationState.Valid

let templateAttributesNameEmpty (template : Template) =
    let hasEmptyNames =
        template.Attributes
        |> List.exists(fun a -> (String.IsNullOrWhiteSpace a.Name))
    if hasEmptyNames then
        create "emptyattributename" (ValidationState.NotValid "Für die Eigenschaften müssen Namen vergeben sein.")
    else create "emptyattributename" ValidationState.Valid

let templateGroups (template : Template) =
    match template.ChildTemplates with
    | Some children when children.Templates.IsEmpty ->
        create "referencedTemplates" (ValidationState.NotValid "Es müssen Teile angelegt sein.")
    | _ -> create "referencedTemplates" ValidationState.Valid

let validateNewForm (template : Template) : FormValidation =
    let newValidations = [ templateName template
                           templateGroups template
                           templateAttributesDuplicateName template
                           templateAttributesNameEmpty template ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid}

let validateEditAttributesForm (templateToValid : TemplateGroupEditForm) : FormValidation =
    let template = templateToValid.Template
    let newValidations =
        [ templateAttributesDuplicateName template
          templateAttributesNameEmpty template ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid}

let validateEditNameForm (templateToValid : TemplateGroupEditForm) : FormValidation =
    let template = templateToValid.Template
    let newValidations =
        [ templateName template ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid}


let validationWarning (currentFormValidation : FormValidation option) (key : string) =
    match currentFormValidation with
    | None -> Fable.React.Helpers.nothing
    | Some validation ->
        match validation.Validations |> List.tryFind(fun t -> t.Key = key) with
        | Some value ->
            if value.Value = ValidationState.Valid then
                Fable.React.Helpers.nothing
            else
                match value.Value with
                | NotValid msg ->
                    SharedComponents.Alerts.getAlert (buildMessage MessageType.Warning msg |> Some)
                | Valid -> Fable.React.Helpers.nothing
        | None -> Fable.React.Helpers.nothing