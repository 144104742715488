module Order.RentOrder.State


open Elmish
open Elmish.Navigation
open Thoth.Json
open Order.RentOrder.Types
open Order.RentOrder.FormValidation
open SharedComponents.Alerts
open SharedComponents.Toast
open System
open Shared
open Shared.Entity
open Shared.Address
open Shared.Material
open Shared.RentOrder
open Shared.RentOrder.Helper
open Shared.TaskTypes
open Routes
open Fable.Core.JsInterop
open Browser.Dom
open Client
open Fetch
open Validation
open SharedComponents.Spinners
open Shared.Configuration

let printRentOrderReportCmd (rentOrderId : RentOrderId) rentOrderName =
    let (RentOrderId rentOrderId) = rentOrderId
    let id = rentOrderId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/rentorders/pdf/%s/report" id), (sprintf "%s.pdf" rentOrderName)) PdfFetched FetchError

let printRentOrdersExcelCmd =
    // TODO: Remove useless param and add download api without param
    let rentOrderId = System.Guid.NewGuid()
    let id = rentOrderId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/rentorders/prinzing/%s/report" id), "prinzing_export.xlsx") PdfFetched FetchError

let getConfigurationCmd =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" ConfigurationFetched FetchError

let getDriversCmd =
    Cmd.OfPromise.either Communication.getRequest<DriversResponse> "/api/users/driver" DriversFetched FetchError

let getRentOrdersCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/rentorders/overview/active" RentOrdersFetched FetchError

let getRentOrdersCompletedCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/rentorders/overview/completed" RentOrdersFetched FetchError

let getRentOrderCmd (orderId : System.Guid) =
    let id = orderId.ToString()
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/rentorders/%s" id) RentOrderFetched FetchError

let getNotAvailablePosDateTimesCmd (orderId : System.Guid) =
    let id = orderId.ToString()
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/rentorders/%s/notavailableposdates" id) NotAvailablePosDateTimesFetched FetchError

let saveRentOrderCmd form =
    Cmd.OfPromise.either Communication.postRequest<PostResponse<SaveRentOrderResult>> ("/api/rentorders", form) RentOrderSaved FetchError

let updateRentOrderCmd rentOrderId (updateElement : RentOrderUpdateDto) =
    let (RentOrderId rentOrderId) = rentOrderId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<SaveRentOrderResult>>
                             ((sprintf "/api/rentorders/%s" (rentOrderId.ToString())), body)
                             RentOrderUpdated FetchError

let deleteRentOrderCmd (rentOrderId : Guid) =
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<DeleteRentOrderResult>> (sprintf "/api/rentorders/%s/delete" (rentOrderId.ToString ())) RentOrderDeleted FetchError

let closeRentOrderCmd (rentOrderId : RentOrderId) =
    let (RentOrderId rentOrderId) = rentOrderId
    Cmd.OfPromise.either Communication.getRequest<PostResponse<RentOrderCloseResult>> (sprintf "/api/rentorders/%s/close" (rentOrderId.ToString ())) Order.RentOrder.Types.Msg.RentOrderClosed FetchError

let initialModel userData =
    { FetchedTypes = { Entities = None
                       Templates = [ ]
                       Materials = [ ]
                       Drivers = [ ] }
      RentOrders = []
      FormState = FormState.Loading
      UserData = userData
      SelectedRentOrderPositionIds = [ ]
      NotValidRentOrderPositions = [ ]
      DetailRentOrderMainMessage = None
      DetailRentOrderMessage = None
      OverviewRentOrderMessage = None
      SavedRentOrderGuid = None
      EntitiesNotAvailableDateTimes = [ ]
      DeleteRequested = None
      PositionReleaseDate = DateTime.UtcNow.Date
      Configuration = None
      RequestState = RequestState.Active
      SaveRequested = false
      SaveState = SaveState.Nothing }

let initNewForm userData : Model * Cmd<Msg> =
    let newRentOrderForm =
        { RentOrder = initialRentOrder
          FormValidation = None
          PickEntities = false
          WithoutDelivery = false
          RentOrderSnapshot = initialRentOrder
          RentStartDate = DateTime.UtcNow.Date
          RentEndDate = DateTime.UtcNow.Date.AddDays 1. }
    let initialModel =
        { initialModel userData with FormState = FormState.New newRentOrderForm
                                     RequestState = RequestState.NotActive }
    initialModel, Cmd.batch [ getConfigurationCmd ]

let initEditForm userData orderId : Model * Cmd<Msg> =
    initialModel userData, Cmd.batch [ getRentOrderCmd orderId; getConfigurationCmd ]

let initOverview userData (oldModel : Model option) : Model * Cmd<Msg> =
    let newInitialModel =
        match oldModel with
        | Some m -> { m with FormState = FormState.Loading }
        | None -> initialModel userData

    newInitialModel, Cmd.batch [ getRentOrdersCmd ]

let initOverviewCompleted userData (oldModel : Model option) : Model * Cmd<Msg> =
    let newInitialModel =
        match oldModel with
        | Some m -> { m with FormState = FormState.Loading
                             RequestState = RequestState.Active }
        | None -> initialModel userData

    newInitialModel, Cmd.batch [ getRentOrdersCompletedCmd ]

let initDetail userData orderId : Model * Cmd<Msg> =
    initialModel userData, Cmd.batch [ getRentOrderCmd orderId; getNotAvailablePosDateTimesCmd orderId; getDriversCmd; getConfigurationCmd ]

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let (|Float|_|) (str : string) =
    let str = str.Replace(",", ".")
    match Double.TryParse(str) with
    | (true, value) -> Some value
    | _ -> None

let currentMaterialPositionValues (rentOrder : RentOrder) =
    rentOrder.Positions
    |> List.choose (function | RentOrderMaterialPosition p -> Some p | RentOrderEntityPosition _ -> None )
    |> List.map (fun p -> { PosNo = p.PosNo; Value = { Raw = p.Quantity |> Helper.floatValueToString; Parsed = Some p.Quantity }} )

let update (msg:Msg) (model : Model) : Model*Cmd<Msg> =
    match msg with
    | SetCompany company ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with CompanyName = Some company }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with CompanyName = Some company }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetContactPerson contact ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with ContactPerson = Some contact }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with ContactPerson = Some contact }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetCity city ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with City = city }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with City = city }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetStreet street ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with Street = street }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with Street = street }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetZipCode zipCode ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with ZipCode = zipCode }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with ZipCode = zipCode }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHouseNumber houseNumber ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newAddress = { r.RentOrder.ExecutionLocation with HouseNumber = houseNumber }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.New
            | Edit r ->
                let newAddress = { r.RentOrder.ExecutionLocation with HouseNumber = houseNumber }
                { r with RentOrder = { r.RentOrder with ExecutionLocation = newAddress} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetDefaultDelivery ->
        let newFormState =
            match model.FormState with
            | New r ->
                { r with PickEntities = false } |> FormState.New
            | Edit r ->
                { r with PickEntities = false } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState
                     DetailRentOrderMessage = None }, Cmd.none
    | SetPickEntities ->
        let newFormState =
            match model.FormState with
            | New r ->
                { r with PickEntities = true } |> FormState.New
            | Edit r ->
                { r with PickEntities = true } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        let message =
            { MessageType = MessageType.Info
              Message = "Es wird ein Kommissionierungs Auftrag angelegt. Die Geräte müssen vom Kommissionierer per App ausgewählt werden." } |> Some
        { model with FormState = newFormState
                     DetailRentOrderMessage = message }, Cmd.none
    | SetRentStartDate date ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newForm =
                    if date > r.RentEndDate then
                        { r with RentStartDate = date
                                 RentEndDate = date }
                    else { r with RentStartDate = date }
                newForm |> FormState.New
            | Edit r ->
                let newForm =
                    if date > r.RentEndDate then
                        { r with RentStartDate = date
                                 RentEndDate = date }
                    else { r with RentStartDate = date }
                newForm |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetRentEndDate date ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newForm =
                    if date < r.RentStartDate then
                        { r with RentStartDate = date
                                 RentEndDate = date }
                    else { r with RentEndDate = date }
                newForm |> FormState.New
            | Edit r ->
                let newForm =
                    if date < r.RentStartDate then
                        { r with RentStartDate = date
                                 RentEndDate = date }
                    else { r with RentEndDate = date }
                newForm |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetProjectNumber projectNumber ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with ProjectNumber = projectNumber }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.New
            | Edit r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with ProjectNumber = projectNumber }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetBuildingProjectName buildingProjectName ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with BuildingProjectName = buildingProjectName }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.New
            | Edit r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with BuildingProjectName = buildingProjectName }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetComment comment ->
        let newFormState =
            match model.FormState with
            | New r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with Comment = comment }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.New
            | Edit r ->
                let newProjectInformation = { r.RentOrder.ProjectInformation with Comment = comment }
                { r with RentOrder = { r.RentOrder with ProjectInformation = newProjectInformation } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    | SetPositions (withoutDelivery, positions) ->
        let newFormState =
            match model.FormState with
            | New r ->
                { r with RentOrder = { r.RentOrder with Positions = positions }
                         WithoutDelivery = withoutDelivery } |> FormState.New
            | Edit r ->
                { r with RentOrder = { r.RentOrder with Positions = positions } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState
                     SaveRequested = false }, Cmd.ofMsg SaveRentOrderForm
    | AddReminder ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                match form.EditField with
                | EditField.EditNewReminder reminders ->
                    let newReminder =
                        { Id = ReminderId (System.Guid.NewGuid())
                          Description = ""
                          TargetDate = System.DateTime.UtcNow.Date }
                    { form with RentOrder = { form.RentOrder with Reminders = newReminder :: form.RentOrder.Reminders }
                                EditField = EditField.EditNewReminder (newReminder :: reminders) }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditTermOfLease _
                | EditField.EditReminder _
                | EditField.EditProjectInformation _ -> form |> Edit
        { model with FormState = formState }, Cmd.none
    | RemoveReminder index ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                match form.EditField with
                | EditField.EditNewReminder newReminders ->
                    let removedElementId =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i a -> if i = index then Some a else None)
                        |> List.choose id
                        |> List.head
                        |> (fun a -> a.Id)
                    let reminders =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i a -> if i <> index then Some a else None)
                        |> List.choose id
                    { form with RentOrder = { form.RentOrder with Reminders = reminders }
                                EditField = EditField.EditNewReminder (newReminders |> List.filter (fun a -> a.Id <> removedElementId)) }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditTermOfLease _
                | EditField.EditProjectInformation _
                | EditField.EditReminder _ -> form |> Edit
        { model with FormState = formState }, Cmd.none
    | SetReminderDescription (index, description) ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                match form.EditField with
                | EditField.EditNewReminder newReminders ->
                    let updatedElement = form.RentOrder.Reminders.[index]
                    let updatedNewReminders =
                        newReminders
                        |> List.map (fun r ->
                            if r.Id = updatedElement.Id then { r with Description = description }
                            else r)
                    let reminders =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i r -> if i = index then { r with Description = description } else r)
                    { form with RentOrder = { form.RentOrder with Reminders = reminders }
                                EditField = EditField.EditNewReminder updatedNewReminders }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditTermOfLease _
                | EditField.EditProjectInformation _ -> form |> Edit
                | EditField.EditReminder _ ->
                    let reminders =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i r -> if i = index then { r with Description = description } else r)
                    { form with RentOrder = { form.RentOrder with Reminders = reminders } }
                    |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | SetReminderTargetDate (index, targetDate) ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                match form.EditField with
                | EditField.EditNewReminder newReminders ->
                    let updatedElement = form.RentOrder.Reminders.[index]
                    let updatedNewReminders =
                        newReminders
                        |> List.map (fun r ->
                            if r.Id = updatedElement.Id then { r with TargetDate = targetDate }
                            else r)
                    let reminders =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i r -> if i = index then { r with TargetDate = targetDate } else r)
                    { form with RentOrder = { form.RentOrder with Reminders = reminders }
                                EditField = EditField.EditNewReminder updatedNewReminders }
                    |> FormState.Edit
                | EditField.Nothing
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditTermOfLease _
                | EditField.EditProjectInformation _ -> form |> Edit
                | EditField.EditReminder _ ->
                    let reminders =
                        form.RentOrder.Reminders
                        |> List.mapi (fun i r -> if i = index then { r with TargetDate = targetDate } else r)
                    { form with RentOrder = { form.RentOrder with Reminders = reminders } }
                    |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    // Set edit states
    | EditCustomerAndExecutionInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditCustomerAndExecutionInformation
                                       RentOrder = form.RentOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditProjectInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditProjectInformation
                                       RentOrder = form.RentOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditTermOfLease ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditTermOfLease
                                       RentOrder = form.RentOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditNewReminder ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditNewReminder []
                            RentOrder = form.RentOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditReminder reminderId ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditReminder reminderId
                            RentOrder = form.RentOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Nothing
                            RentOrder = form.RentOrderSnapshot
                            // TemplatePerPositions = form.TemplatePerPositionsSnapshot
                             }
                |> FormState.Edit
        { model with FormState = formState
                     NotValidRentOrderPositions = [] }, Cmd.none
    // Delete actions
    | AbortDelete ->
        { model with DeleteRequested = None }, Cmd.none
    | OpenDeleteModal ->
        let model =
            match model.FormState with
            | FormState.Loading -> model
            | New form -> { model with DeleteRequested = Some form.RentOrder.Id }
            | Edit form -> { model with DeleteRequested = Some form.RentOrder.Id }
        model, Cmd.none
    | DeleteRequest ->
        let newModel, newCmd =
            match model.DeleteRequested with
            | Some id ->
                let (RentOrderId id) = id
                { model with RequestState = RequestState.Active },
                deleteRentOrderCmd id
            | None -> model, Cmd.none
        newModel, newCmd

    // Print pdf
    | PrintAsPdf ->
        let newModel, newCmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                let pdfName = sprintf "report_%s" (System.DateTime.Now.ToString())
                { model with RequestState = RequestState.Active }, Cmd.batch [ printRentOrderReportCmd form.RentOrder.Id pdfName; toast (ToastType.Info "Dokument wird heruntergeladen")  ]
        newModel, newCmd
    | PdfFetched (response, filename) ->
        { model with RequestState = RequestState.NotActive }, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none

    | RequestSave ->
        match model.FormState with
        | New form ->
            if form.PickEntities then
                model, Cmd.ofMsg SaveRentOrderForm
            else
                { model with SaveRequested = true }, Cmd.none
        | Edit _ -> model, Cmd.none
        | FormState.Loading -> model, Cmd.none
    | SaveRentOrderForm ->
        let validation =
            match model.FormState with
            | New form ->
                validateNewForm form
            | Edit form ->
                match form.EditField with
                | EditField.EditCustomerAndExecutionInformation -> validateEditAddressForm form
                | EditField.EditProjectInformation -> validateEditAddressForm form
                | EditField.EditTermOfLease -> validateEditAddressForm form
                | EditField.EditNewReminder newReminders -> valid
                | EditField.EditReminder reminderId -> valid
                | EditField.Nothing -> notValid
            | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let cmd =
                    match model.FormState with
                    | New form ->
                        if form.PickEntities then
                            { StartRentDate = form.RentStartDate; EndRentDate = form.RentEndDate } |> Some
                        else None
                        |> toRentOrderDto form.RentOrder form.WithoutDelivery
                        |> Encode.toString 0
                        |> saveRentOrderCmd
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditCustomerAndExecutionInformation ->
                            form.RentOrder.ExecutionLocation
                            |> RentOrderUpdateDto.UpdateExecutionLocation
                            |> updateRentOrderCmd form.RentOrder.Id
                        | EditField.EditProjectInformation ->
                            form.RentOrder.ProjectInformation
                            |> RentOrderUpdateDto.UpdateProjectInformation
                            |> updateRentOrderCmd form.RentOrder.Id
                        | EditField.EditTermOfLease ->
                            { RentOrderId = form.RentOrder.Id
                              StartDate = form.RentStartDate
                              EndDate = form.RentEndDate }
                            |> RentOrderUpdateDto.UpdateTermOfLease
                            |> updateRentOrderCmd form.RentOrder.Id
                        | EditField.EditNewReminder newReminders ->
                            newReminders
                            |> RentOrderUpdateDto.AddReminders
                            |> updateRentOrderCmd form.RentOrder.Id
                        | EditField.EditReminder reminderId ->
                            match form.RentOrder.Reminders |> List.tryFind (fun r -> r.Id = reminderId) with
                            | Some reminder ->
                                reminder
                                |> RentOrderUpdateDto.UpdateReminder
                                |> updateRentOrderCmd form.RentOrder.Id
                            | None -> Cmd.none
                        | EditField.Nothing -> Cmd.none
                    | FormState.Loading -> Cmd.none
                { model with SaveState = SaveState.Saving
                             RequestState = RequestState.Active }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, warningToast
                | FormState.Loading -> model, Cmd.none
                | New form ->
                    let newFormState = { form with FormValidation = Some validation } |> New
                    { model with FormState = newFormState }, warningToast
        { newModel with NotValidRentOrderPositions = [ ] }, cmd
    | RentOrderSaved response ->
        let model, cmd =
            match model.FormState with
            | New form ->
                match response.Result with
                | Saved (RentOrderId rentOrderId) ->
                    model,
                    Cmd.batch [ Navigation.newUrl (Routes.toPath (Page.RentOrderDetail rentOrderId)); toast (ToastType.Success "Mietauftrag erfolgreich angelegt.")]
                | InvalidPositions positions ->
                    let validations = createPosNotAvailableValidation positions
                    let positionValidation = validateNewForm form
                    let positionValidation = { positionValidation with Validations = validations |> List.append positionValidation.Validations }
                    { model with NotValidRentOrderPositions = positions
                                 FormState = { form with FormValidation = Some positionValidation } |> FormState.New },
                    toast (ToastType.Warning "Mietauftrag konnte nicht angelegt werden. Bitte korrigiere deine Positionen.")
                | InvalidPositionsSelfserviceConvert positions ->
                    let validations = createPosNotAvailableValidation positions
                    let positionValidation = validateNewForm form
                    let positionValidation = { positionValidation with Validations = validations |> List.append positionValidation.Validations }
                    { model with NotValidRentOrderPositions = positions
                                 FormState = { form with FormValidation = Some positionValidation } |> FormState.New },
                    toast (ToastType.Warning "Mietauftrag konnte nicht angelegt werden. Bitte korrigiere deine Positionen.")
            | Edit _
            | FormState.Loading -> model, Cmd.none
        { model with SaveState = SaveState.Nothing
                     RequestState = RequestState.NotActive }, cmd
    | RentOrderFetched response ->
        let editForm =
            { RentOrder = response
              EditField = EditField.Nothing
              DeleteReminderRequested = None
              FormValidation = None
              PickEntities = match response.State with | WaitForPick _ | PlacedForPick _ -> true | _ -> false
              RentOrderSnapshot = response
              RentStartDate = match response.State with | WaitForPick (s, _) -> s | PlacedForPick (s, _) -> s | _ -> DateTime.UtcNow.Date
              RentEndDate = match response.State with | WaitForPick (_, e) -> e | PlacedForPick (_, e) -> e | _ -> DateTime.UtcNow.Date }
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form -> editForm |> Edit
            | FormState.Loading -> editForm |> Edit
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, Cmd.none
    | FetchRentOrder ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                let (RentOrderId rentOrderId) = form.RentOrder.Id
                { model with RequestState = Active}, getRentOrderCmd rentOrderId
            | FormState.Loading -> model, Cmd.none
        newModel, newCmd
    | PositionIdsDisposed ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                { model with SelectedRentOrderPositionIds = [] }, Cmd.ofMsg FetchRentOrder
            | FormState.Loading -> model, Cmd.none
        newModel, newCmd
    | RentOrdersFetched response ->
        { model with RentOrders = response.RentOrders
                     RequestState = RequestState.NotActive }, Cmd.none
    | TemplatesFetched response ->
        let newFetchedTypes =
            { model.FetchedTypes with Templates = response.Templates }
        { model with FetchedTypes = newFetchedTypes }, Cmd.none
    | EntitiesFetched response ->
        let newFetchedTypes =
            { model.FetchedTypes with Entities = Some response.Entities}
        { model with FetchedTypes = newFetchedTypes }, Cmd.none
    | MaterialsFetched response ->
        let newFetchedTypes =
            { model.FetchedTypes with Materials = response.Materials }
        { model with FetchedTypes = newFetchedTypes }, Cmd.none
    | DriversFetched response ->
        let newFetchedTypes =
            { model.FetchedTypes with Drivers = response.Drivers }
        { model with FetchedTypes = newFetchedTypes }, Cmd.none
    | ConfigurationFetched configuration ->
        { model with Configuration = configuration |> Some }, Cmd.none
    | EntityAvailableDateTimesFetched response ->
        let filteredEntitiesNotAvailableDateTimes =
            model.EntitiesNotAvailableDateTimes
                |> List.filter (fun e -> e.EntityId <> response.EntityId)
        { model with EntitiesNotAvailableDateTimes = response :: filteredEntitiesNotAvailableDateTimes }, Cmd.none
    | NotAvailablePosDateTimesFetched response ->
        { model with EntitiesNotAvailableDateTimes = response }, Cmd.none
    | RentOrderUpdated response ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                match response.Result with
                | Saved (RentOrderId rentOrderId) ->
                    { model with FormState = { form with RentOrderSnapshot = form.RentOrder } |> FormState.Edit },
                    Cmd.batch [ getRentOrderCmd rentOrderId; toast (ToastType.Success "Geräte erfolgreich aktualisiert.") ]
                | InvalidPositions invalidPositions -> model, Cmd.none
                | InvalidPositionsSelfserviceConvert positions -> model, Cmd.none
        { model with SaveState = Nothing
                     RequestState = RequestState.NotActive }, cmd
    | RentOrderDeleted response ->
        let newCmd =
            match response.Result with
            | DeleteRentOrderResult.Deleted _ ->
                Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.RentOrderOverview ))
                            toast (ToastType.Info "Mietauftrag wurde erfolgreich gelöscht.")]
            | DeleteRentOrderResult.WrongStateError _ ->
                toast (ToastType.Error "Mietauftrag konnte nicht gelöscht werden.")
            | DeleteRentOrderResult.AssignedTasksExistsError _ ->
                toast (ToastType.Error "Mietauftrag konnte nicht gelöscht werden. Es exisitieren zugewiesene Aufgaben.")
        { model with RequestState = RequestState.NotActive }, newCmd

    | CloseRentOrder ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form -> { model with RequestState = RequestState.Active }, closeRentOrderCmd form.RentOrder.Id
        model, cmd

    | Order.RentOrder.Types.Msg.RentOrderClosed response ->
        let newCmd =
            match response.Result with
            | RentOrderCloseResult.RentOrderClosed rentOrderId ->
                let (RentOrderId rentOrderId) = rentOrderId
                Cmd.batch [ toast (ToastType.Info "Mietauftrag wurde erfolgreich abgeschlossen."); getRentOrderCmd rentOrderId ]
            | RentOrderCloseResult.RentOrderAlreadyClosed _ ->
                toast (ToastType.Error "Mietauftrag wurde bereits abgeschlossen.")
            | RentOrderCloseResult.RentOrderHasOpenPositions ->
                toast (ToastType.Error "Mietauftrag hat noch offene Positionen und kann nicht abgeschlossen werden.")
            | RentOrderCloseResult.RentOrderIsInPlannedState _ ->
                toast (ToastType.Error "Mietauftrag kann nicht abgeschlossen werden bevor er gestartet wurde.")
        { model with RequestState = RequestState.NotActive }, newCmd
    // Delete actions
    | AbortReminderDelete ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> form |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.Nothing _
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditProjectInformation _
                | EditField.EditTermOfLease _
                | EditField.EditNewReminder _
                    -> form |> Edit
                | EditField.EditReminder _ -> { form with DeleteReminderRequested = None } |> Edit
        { model with FormState = newFormState }, Cmd.none
    | OpenDeleteReminderModal ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> form |> FormState.New
            | Edit form ->
                match form.EditField with
                | EditField.Nothing _
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditProjectInformation _
                | EditField.EditTermOfLease _
                | EditField.EditNewReminder _ -> form |> FormState.Edit
                | EditField.EditReminder reminderId -> { form with DeleteReminderRequested = Some reminderId } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | DeleteReminderRequest ->
        let newModel, newCmd =
            match model.FormState with
            | FormState.Loading _
            | New _ -> model, Cmd.none
            | Edit form ->
                match form.EditField with
                | EditField.EditReminder reminderId ->
                    { model with RequestState = RequestState.Active },
                    reminderId
                    |> RentOrderUpdateDto.DeleteReminder
                    |> updateRentOrderCmd form.RentOrder.Id
                | EditField.Nothing _
                | EditField.EditCustomerAndExecutionInformation _
                | EditField.EditProjectInformation _
                | EditField.EditTermOfLease _
                | EditField.EditNewReminder _ -> model, Cmd.none
        newModel, newCmd
    | PrinzingPrintRentOrders ->
        model, Cmd.batch [ printRentOrdersExcelCmd; toast (ToastType.Info "Excel wird heruntergeladen") ]
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
