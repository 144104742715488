module Control.View



open Fable.React
open Control.Types
open Fable.React.Props
open Routes
open SharedComponents
open Shared.Entity
open Shared

let detailBtn (rentOrderId : RentOrderId) =
    let (RentOrderId rentOrderId) = rentOrderId
    a [ Href (Routes.toPath (Page.RentOrderDetail rentOrderId) ) ]
        [ i [ Class ("fas fa-eye" ) ] [ ]
          span [ Class "url-text" ] [ str "Anzeigen RentOrder" ] ]
    
let detailBtnEntitiy (entity : Entity) =
    let (EntityId entityId) = entity.Id
    let (TemplateId templateId) = entity.TemplateId
    a [ Href (Routes.toPath (Page.EntityViewForm (templateId, entityId)) ) ]
        [ i [ Class ("fas fa-eye" ) ] [ ]
          span [ Class "url-text" ] [ str "Anzeigen" ] ]
        
let displayError (data : (RentOrderId * Entity list)) dispatch =
    let rentOrderId, entities = data
    div []
        [
            p [] [ str (sprintf "rentOrderId: %s" (rentOrderId |> Shared.Helper.unwrapRentOrderId))
                   detailBtn rentOrderId ]
            for entity in entities do
                p []
                   [ str (entity.Id |> Shared.Helper.unwrapEntityId)
                     detailBtnEntitiy entity
                     Buttons.primaryButtonWithFnct (fun _ -> dispatch (UpdateEntity (rentOrderId, entity))) "Update" "ml-auto"  ]
            Dividers.divider
        ]
let view (model : Model) (dispatch : Msg -> unit) =
    div [  ]
        [
            h1 []
                [ str "Control" ]
            for data in model.Data do
                displayError data dispatch
        ]