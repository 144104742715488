module Dispositions.RepairOrderDisposition.State

open Client
open Elmish
open Dispositions.RepairOrderDisposition.Types
open Shared
open Shared.TaskTypes
open Shared.Entity
open Shared.ServiceOrder
open SharedComponents.Toast
open Fable.Core.JsInterop
open Browser.Dom
open Fetch
open SharedComponents.Spinners

let printTaskCmd (taskId : TaskId) taskName =
    let (TaskId taskId) = taskId
    let id = taskId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/print/pdf/document/task/%s" id), (sprintf "%s.pdf" taskName)) PdfFetched FetchError

let getEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntitiesResponse> "/api/entities" EntitiesFetched FetchError

let getRepairOrderCmd (orderId : ServiceOrderId) =
    let id = orderId |> Helper.unwrapServiceOrderId
    Cmd.OfPromise.either Communication.getRequest<ServiceOrderDto> (sprintf "/api/repairorders/%s" id) RepairOrderWithServiceReportFetched FetchError

let getTechniciansCmd =
    Cmd.OfPromise.either Communication.getRequest<TechniciansResponse> "/api/users/technican" TechniciansFetched FetchError

let init orderId taskId : Model * Cmd<Msg> =
   { OrderId = orderId
     TaskId = taskId
     RepairOrderWithServiceReport = None
     Task = None
     Signature = None
     Entities = [ ]
     SelectedEntity = None
     Technicans = [ ]
     Images = [ ]
     RequestState = RequestState.Active
     ImageViewerOpen = Map.empty
     ImageViewerIndex = 1 }, Cmd.batch [ getRepairOrderCmd orderId; getEntitiesCmd; getTechniciansCmd; ]

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let update (msg:Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | OpenImageViewer (entityId, index) ->
        { model with ImageViewerOpen = model.ImageViewerOpen.Add (entityId, true); ImageViewerIndex = index }, Cmd.none
    | CloseImageViewer entityId ->
        { model with ImageViewerOpen = model.ImageViewerOpen.Add (entityId, false) }, Cmd.none
    | PrintAsPdf ->
        let newModel, newCmd =
            match model.Task with
            | None -> model, Cmd.none
            | Some t ->
                let pdfName = sprintf "report_%s" (System.DateTime.Now.ToString())
                { model with RequestState = RequestState.Active }, Cmd.batch [ printTaskCmd t.TaskId pdfName; toast (ToastType.Info "Dokument wird heruntergeladen")  ]
        newModel, newCmd
    | SelectEntity entity ->
        { model with SelectedEntity = Some entity }, Cmd.none
    | RepairOrderWithServiceReportFetched response ->
        let task =
            response.Tasks
            |> List.pick (fun t ->
                if t |> Helper.serviceOrderTaskId = model.TaskId then
                    match t with | AssignedServiceTask t -> Some t | _ -> None
                else None)

        let cmd, imageViewer =
            let report =
                if response.Tasks.IsEmpty then
                    None
                else
                    match response.Tasks.Head with
                    | ServiceOrderTask.AssignedServiceTask t -> t.ServiceReport
                    | ServiceOrderTask.ServiceTask t -> None
            match report with
            | Some report ->
                let imageCmds =
                    report.ReportPerEntities
                    |> List.collect (fun entityReport -> entityReport.Images |> List.map (fun i -> i.Id))
                    |> List.map (fun imageId -> Commands.getImage imageId ImageFetched FetchError)
                let imageViewer = report.ReportPerEntities |> List.fold (fun (result : Map<EntityId, bool>) element -> result.Add (element.EntityId, false)) Map.empty<EntityId, bool>
                match report.Signature with
                | Some signature ->
                    (Commands.getImage signature.Id SignatureFetched FetchError) :: imageCmds
                | None -> imageCmds
                |> Cmd.batch, imageViewer
            | None -> Cmd.none, Map.empty
        { model with RepairOrderWithServiceReport = Some response
                     ImageViewerOpen = imageViewer
                     RequestState = RequestState.NotActive
                     Task = Some task }, cmd
    | TechniciansFetched response ->
        { model with Technicans = response.Technicians }, Cmd.none
    | EntitiesFetched response ->
        { model with Entities = response.Entities }, Cmd.none
    | ImageFetched image ->
        { model with Images = image :: model.Images }, Cmd.none
    | SignatureFetched signature ->
        { model with Signature = Some signature }, Cmd.none
    | PdfFetched (response, filename) ->
        { model with RequestState = RequestState.NotActive}, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none
    | FetchError e ->
        printfn "Error %O" e
        model, ErrorHandling.handleFetchError e
