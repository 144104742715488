module SharedComponents.Form

open Elmish.React.Helpers
open Shared
open Validation
open Types
open Types.Helpers


open System.Text.RegularExpressions
open Feliz

let invalidContent (invalidText : string) =
    Html.div [
        prop.className "invalid-feedback"
        prop.text invalidText
    ]

type Input () =
    static member inlineInput(onChangeFnct : string -> unit, value : string,
                              ?inputLabel : string, ?validation : FormValidation option * string,
                              ?placeholder : string, ?isDisabled : bool,
                              ?className : string, ?cyPostFix : string,
                              ?required : bool, ?pattern : string,
                              ?appendElement : string) =
        let formValidation, key = defaultArg validation (None, "")
        let isInvalid, invalidText = Validation.Validate.isInvalid formValidation key
        let isDisabled = defaultArg isDisabled false
        let placeholder = defaultArg placeholder (defaultArg inputLabel "")
        let className = defaultArg className ""
        let cyIdentifier = defaultArg cyPostFix "" |> sprintf "input-field-%s"
        let classNameExtension = if isInvalid then "is-invalid" else ""
        let required = defaultArg required false
        let pattern = pattern |> Option.map Regex
        let inputLabel =
            match inputLabel with
            | Some l ->
                Html.label [
                    prop.htmlFor "inputPassword"
                    prop.className "label label-right mt-auto mb-auto mr-3"
                    prop.children [
                        Html.span l
                        if required then
                            Html.span [
                                prop.className "required-asterisk"
                                prop.text "*"
                            ]
                        else Html.none
                    ]
                ]
            | None -> Html.none

        Html.div [
            prop.className (sprintf "input-with-label form-group d-flex %s" className)
            prop.children [
                inputLabel
                Html.div [
                    prop.className [
                        if appendElement.IsSome then "input-group"
                        else "flex-grow-1"
                    ]
                    prop.children [
                        Html.input [
                            prop.className (sprintf "form-control %s" classNameExtension)
                            prop.valueOrDefault value
                            prop.onChange onChangeFnct
                            prop.disabled isDisabled
                            prop.placeholder placeholder
                            match pattern with | Some p -> prop.pattern p | None -> ()
                            prop.custom ("data-cy", cyIdentifier)
                        ]
                        match appendElement with
                        | Some text ->
                            Html.div [
                                prop.className "input-group-append"
                                prop.children [
                                    Html.span [
                                        prop.className "input-group-text"
                                        prop.text text
                                    ]
                                ]
                            ]
                        | None -> Html.none
                        if isInvalid then invalidContent invalidText
                    ]
                ]
            ]
        ]

open Fable.React
open Fable.React.Props

let inlineLabel labelValue inputValue (cyPostFix : string) =
    let cyIdentifier = (sprintf "input-field-%s" cyPostFix)
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor "inputPassword"
                  Class "label label-right mt-auto mb-auto mr-3 required-label" ]
            [ str labelValue ]
          div [ Class "flex-grow-1" ]
            [ label [ HtmlFor "inputPassword"
                      Class "label form-control no-border mt-auto mb-auto mr-3"
                      Data ("cy", cyIdentifier) ]
                [ str inputValue ] ] ]

let numberInlineInput onChangeFnct value labelText step isDisabled cyPostFix =
    let cyIdentifier = (sprintf "input-number-field-%s" cyPostFix)
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor "inputPassword"
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Class "form-control"
                      valueOrDefault value
                      OnChange onChangeFnct
                      Disabled isDisabled
                      Data ("cy", cyIdentifier)
                      Placeholder labelText
                      HTMLAttr.Type "number"
                      HTMLAttr.Step step ] ] ]

let private valueToCurrency (value : int<EUR>) =
    value |> float |> (fun v -> v / 100.)

let currencyInlineInput onChangeFnct value labelText inputText isDisabled cyPostFix =
    let cyIdentifier = (sprintf "input-number-field-%s" cyPostFix)
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor ""
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Class "form-control"
                      valueOrDefault (value |> valueToCurrency)
                      OnChange onChangeFnct
                      Placeholder inputText
                      Disabled isDisabled
                      Data ("cy", cyIdentifier)
                      HTMLAttr.Type "number"
                      HTMLAttr.Max "10000.00"
                      HTMLAttr.Min "0.00"
                      HTMLAttr.Step "0.01" ] ] ]

let quantityInlineInput onChangeFnct (value : int<Quantity>) labelText isDisabled cyPostFix =
    let cyIdentifier = (sprintf "input-number-field-%s" cyPostFix)
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor ""
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Class "form-control"
                      valueOrDefault value
                      OnChange onChangeFnct
                      Disabled isDisabled
                      Data ("cy", cyIdentifier)
                      HTMLAttr.Type "number"
                      HTMLAttr.Max "1000000"
                      HTMLAttr.Min "0"
                      HTMLAttr.Step "1" ] ] ]

let currencyInlineInputWithout onChangeFnct value inputText isDisabled cyPostFix =
    let cyIdentifier = (sprintf "input-number-field-%s" cyPostFix)
    div [ Class "input-with-label form-group d-flex" ]
        [ div [ Class "flex-grow-1" ]
            [ input [ Class "form-control"
                      valueOrDefault (value |> valueToCurrency)
                      Disabled isDisabled
                      Data ("cy", cyIdentifier)
                      OnChange onChangeFnct
                      Placeholder inputText
                      HTMLAttr.Type "number"
                      HTMLAttr.Max "10000.00"
                      HTMLAttr.Min "0.00"
                      HTMLAttr.Step "0.01" ] ] ]

let passwordInlineInput onChangeFnct invalidFnct value labelText isDisabled =
    let isInvalid, invalidText = invalidFnct ()
    let classNameExtension =
        if isInvalid then "is-invalid" else ""
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor "inputPassword"
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Class (sprintf "form-control %s" classNameExtension)
                      valueOrDefault value
                      OnChange onChangeFnct
                      Placeholder labelText
                      Disabled isDisabled
                      HTMLAttr.Type "password" ]
              (if not isInvalid then Fable.React.Helpers.nothing
               else invalidContent invalidText) ] ]

let passwordInlineInputFormValidation onChangeFnct (formValidation : FormValidation option, key : string) value (labelType : LabelType) (cyPostFix : string) =
    let isInvalid, invalidText = Validation.Validate.isInvalid formValidation key
    let cyIdentifier = (sprintf "input-password-%s" cyPostFix)
    let classNameExtension =
        if isInvalid then "is-invalid" else ""
    let labelText, requiredOption = labelText labelType
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor "inputPassword"
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText
              requiredSpan requiredOption ]
          div [ Class "flex-grow-1" ]
            [ input [ Class (sprintf "form-control %s" classNameExtension)
                      valueOrDefault value
                      OnChange onChangeFnct
                      Disabled labelType.IsDisabled
                      Placeholder labelType.Value
                      Data ("cy", cyIdentifier)
                      HTMLAttr.Type "password" ]
              (if not isInvalid then Fable.React.Helpers.nothing
               else invalidContent invalidText) ] ]


let inlineInvalidInput onChangeFnct value labelText invalidText =
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HTMLAttr.Custom ("for", "inputPassword")
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Class "form-control is-invalid"
                      valueOrDefault value
                      OnChange onChangeFnct
                      Placeholder labelText ]
              div [ Class "invalid-feedback" ]
                [ str invalidText ]] ]

let inputWithoutLabel onChangeFnct value placeholderText isDisabled =
    div [ Class "form-group" ]
        [ input [ Class "form-control"
                  valueOrDefault value
                  OnChange onChangeFnct
                  Disabled isDisabled
                  Placeholder placeholderText ] ]

let inputWithoutLabelAndDisabledFnct onChangeFnct value placeholderText isDisabled =
    div [ Class "form-group" ]
        [ input [ Class "form-control"
                  valueOrDefault value
                  OnChange onChangeFnct
                  Disabled isDisabled
                  Placeholder placeholderText ] ]

let currencyLabel value =
    div [ Class "form-group" ]
        [ label [ HTMLAttr.Custom ("for", "inputPassword")
                  Class "label label-right mt-auto mb-auto mr-3"
                  HTMLAttr.Type "number"
                  HTMLAttr.Max "10000.00"
                  HTMLAttr.Min "0.00"
                  HTMLAttr.Step "0.01" ]
            [ str value ] ]

type SelectOption =
    { Value : string
      Label : string }

let selectField options onChangeFnct value isDisabled =
    let option o =
        option [ Value o.Value
                 Key o.Value ]
            [ str o.Label ]
    select [ Class "custom-select"
             Disabled isDisabled
             OnChange onChangeFnct
             Value value ]
        [ options
          |> List.map option
          |> ofList ]

let radioButton onChangeFnct value trueLabel falseLabel =
    /// TODO not ready to use
    div [ ]
        [ div [ Class "form-check form-check-inline" ]
            [ input [ Class "form-check-input"
                      Type "radio"
                      Name "inlineRadioOptions"
                      Id trueLabel
                      Value "option1"
                      OnChange (onChangeFnct true) ]
              label [ Class "form-check-label"
                      HtmlFor trueLabel ]
                [ str trueLabel ] ]
          div [ Class "form-check form-check-inline" ]
            [ input [ Class "form-check-input"
                      Type "radio"
                      Name "inlineRadioOptions"
                      Id falseLabel
                      Value "option2"
                      OnChange (onChangeFnct true) ]
              label [ Class "form-check-label"
                      HtmlFor falseLabel ]
                [ str falseLabel ] ] ]

let inlineCheckBox onChangeFnct value (labelText : string) isDisabled =
    let checkboxId = labelText.Replace(" ", "-")
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ Class "form-check-label label label-right"
                  HtmlFor checkboxId ]
            [ str labelText ]
          input [ Id checkboxId
                  Type "checkbox"
                  Class "ml-3"
                  OnChange onChangeFnct
                  Disabled isDisabled
                  Checked value ] ]

let inlineCheckBoxLabelRight onChangeFnct value (labelText : string) isDisabled =
    let checkboxId = labelText.Replace(" ", "-")
    div [ Class "input-with-label form-group d-flex" ]
        [ input [ Id checkboxId
                  Type "checkbox"
                  Class ""
                  OnChange onChangeFnct
                  Disabled isDisabled
                  Checked value ]
          label [ Class "form-check-label label"
                  HtmlFor checkboxId ]
            [ str labelText ] ]


let checkBox onChangeFnct value (labelText : string) isDisabled className =
    let checkBoxId = labelText.Replace(" ", "-")
    div [ Class (sprintf "datepicker-with-label form-group form-check form-check-inline %s" className) ]
        [ label [ Class "label label-right mt-auto mb-auto"
                  HtmlFor checkBoxId ]
            [ str labelText ]
          div [ Class "react-datepicker-wrapper" ]
            [ input [ Class "form-check-input"
                      Type "checkbox"
                      Id checkBoxId
                      OnChange onChangeFnct
                      Disabled isDisabled
                      Checked value ] ] ]

let checkBoxWithoutLabel onChangeFnct value isDisabled =
    div [ Class "form-group row dh-checkbox" ]
        [ div [ Class "col-sm-10" ]
            [ input [ Class "form-check-input"
                      Type "checkbox"
                      OnChange onChangeFnct
                      Disabled isDisabled
                      Checked value ] ] ]

let invalidSpan labelText =
    span [ Id "invalid-feedback-select" ]
        [ str labelText ]

let textArea (onChangeFnct : string -> unit) (value : string) (labelText : string) isDisabled (cyPostFix : string) =
    let textAreaId = labelText.Replace(" ", "-")
    let cyIdentifier = (sprintf "textarea-input-%s" cyPostFix)
    Html.div [
        prop.className "input-with-label form-group d-flex"
        prop.children [
            Html.label [
                prop.className "label label-right mt-auto mb-auto mr-3"
                prop.htmlFor textAreaId
                prop.text labelText
            ]
            Html.div [
                prop.className "flex-grow-1"
                prop.children [
                    Html.textarea [
                        prop.className "form-control"
                        prop.id textAreaId
                        prop.onChange onChangeFnct
                        prop.valueOrDefault value
                        prop.disabled isDisabled
                        prop.custom ("data-cy", cyIdentifier)
                        prop.rows 3
                    ]
                ]
            ]
        ]
    ]

let eightHourSlider onChangeFnct (value : int<Minutes>) labelText isDisabled =
    div [ Class "input-with-label form-group d-flex" ]
        [ label [ HtmlFor labelText
                  Class "label label-right mt-auto mb-auto mr-3" ]
            [ str labelText ]
          div [ Class "flex-grow-1" ]
            [ input [ Type "range"
                      Class "custom-range"
                      Id labelText
                      valueOrDefault value
                      HTMLAttr.Max "480"
                      HTMLAttr.Min "0"
                      HTMLAttr.Step "15"
                      OnChange onChangeFnct
                      Disabled isDisabled ] ] ]

let requiredLegend =
    div [ ]
        [ span [ Class "required-asterisk" ] [str "*" ]
          span [ Class "ml-1" ] [ str "Pflichtfeld" ] ]
