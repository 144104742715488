module Dispositions.State

open Elmish
open Dispositions.Types
open Shared
open System
open Thoth.Json
open Shared.TaskTypes
open Shared.Entity
open Shared.TaskTypes.Helper
open SharedComponents.Alerts
open Shared.Address
open Dispositions.Helper
open SharedComponents.Toast
open SharedComponents.Spinners
open Client

let getEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntitiesResponse> "/api/entities" EntitiesFetched FetchError

let getTasksCmd (startDate : DateTime) (endDate : DateTime) =
    let startDate = startDate.ToString("dd.MM.yyyy")
    let endDate = endDate.ToString("dd.MM.yyyy")
    Cmd.OfPromise.either Communication.getRequest<ClientTasksResponse> (sprintf "/api/tasks/bydaterange/%s/%s" startDate endDate) TasksFetched FetchError

let assignTaskCmd (dto : AssignTaskDto) =
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.DispositionDomain.AssignTaskResponse>> ("/api/disposition/delivery", body) TaskAssigned (fun e -> AssignTaskFailed (dto, e))

let unassignTaskCmd (dto : UnassignTaskDto) =
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.DispositionDomain.UnassignTaskResponse>> ("/api/disposition/task/unassign", body) TaskUnassigned FetchError

let updateTaskWithInformation (dto : UpdatedTaskDto) =
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.DispositionDomain.TaskInformationUpdatedResponse>> ("/api/disposition/task/update/information", body) TaskInformationUpdated TaskInformationUpdateFailed

let closeTask (dto : CloseTaskDto) =
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.TaskTypes.CloseTaskResultResponse>> ("/api/disposition/task/close", body) TaskClosed CloseTaskFailed

let assignMechanicToTaskCmd (mechanicTaskDto : SaveMechanicTaskDto) =
    let body = Encode.Auto.toString (0, mechanicTaskDto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.DispositionDomain.AssignMechanicResponse>> ("/api/disposition/mechanic/assign", body) MechanicTaskAssigned AssignMechanicTaskFailed

let unassignMechanicFromTaskCmd (mechanicTaskDto : SaveMechanicTaskDto) =
    let body = Encode.Auto.toString (0, mechanicTaskDto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<Shared.DispositionDomain.UnassignMechanicResponse>> ("/api/disposition/mechanic/unassign", body) MechanicTaskUnassigned UnassignMechanicTaskFailed

let elementsForUserAt (tasks : ClientAssignedTask list) (pendingTasks : PendingTask list) userId date model dispatch =
    let assignedTasks =
        tasks
        |> List.filter (fun t -> t.UserId = userId)
        |> List.filter (fun t ->
            match t.TaskType with
            | AssignedTaskType.FreeTask _
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.SelfserviceReturnTask _
            | AssignedTaskType.DeliveryTask _
            | AssignedTaskType.ReturnTask _
            | AssignedTaskType.PickTask _
            | AssignedTaskType.ServiceTask -> t.ScheduledDate = date
            | AssignedTaskType.SupportTask referencedTaskId ->
                (let _, _, _, _, _, taskDate = mechanicTaskValues tasks referencedTaskId
                 taskDate = date))
        |> List.map TableElement.AssignedTask

    let pendingTasks =
        pendingTasks
        |> List.filter (fun t -> t.UserId = userId)
        |> List.filter (fun t -> t.Date = date)
        |> List.map TableElement.PendingTask
    List.append assignedTasks pendingTasks

let private initialDateRange =
    let currentYear = DateTime.UtcNow.Year
    let currentMonth = DateTime.UtcNow.Month
    let monthBefore = currentMonth-1
    let monthAfter = currentMonth+1
    let startDate = DateTime(currentYear, monthBefore, 1).Date
    let endDate = DateTime(currentYear, monthAfter, DateTime.DaysInMonth(currentYear, monthAfter)).Date
    startDate, endDate

let weekRange (date : DateTime) =
    let dayOfWeek = date.DayOfWeek |> int
    let startDate =
        if dayOfWeek = 0 then /// special case sunday
            (date.AddDays -6.).Date
        else
            (date.AddDays ((-1 * (dayOfWeek - 1)) |> float)).Date
    [ for i in [ 0 .. 6 ] -> (startDate.AddDays (i |> float)).Date ]

let weekStartAndEndDate (dateRange : DateTime list) =
    dateRange.Head, (dateRange |> List.rev |> List.head)

let init query : Model * Cmd<Msg> =
    printfn "query: %O " query
    let initialModel =
        { SidebarState = Open
          UnassignedTasks = [ ]
          PendingTasks = [ ]
          Employees = [ ]
          VisibleEmployees = [ ]
          Entities = [ ]
          SelectedDateRange = initialDateRange
          SelectedFilterObjectType = FilterObjectType.All
          DateRange = weekRange DateTime.UtcNow.Date
          AssignedTasks = [ ]
          SplitModalState = SplitModalState.Closed
          SplittedTasks = [ ]
          TaskModalState = TaskModalState.Closed
          TaskModalMessage = None
          RequestState = RequestState.Active
          Configuration = None
          SelectedTaskCard = None }
    let startDate, endDate = weekStartAndEndDate initialModel.DateRange
    initialModel, Cmd.batch [ Commands.getConfigurationCmd ConfigurationFetched FetchError; Commands.getActiveEmployeesCmd EmployeesFetched FetchError; getEntitiesCmd; getTasksCmd startDate endDate ]

let private isAssignable (model : Model) (task : ClientTask) (employeeId : int) =
    let containsUserRole (roleToCheck : RoleNames) =
        match model.Employees |> List.tryFind (fun e -> e.Id = employeeId) with
        | Some empl -> empl.RoleNames |> List.exists (fun r -> r = roleToCheck)
        | None -> false

    match task with
    | ClientTask.UnassignedTask t ->
        match t.TaskType with
        | TaskType.DeliveryTask
        | TaskType.ReturnTask -> containsUserRole RoleNames.Driver
        | TaskType.ServiceTask -> containsUserRole RoleNames.Technician
        | TaskType.PickTask -> containsUserRole RoleNames.Picker
        | TaskType.FreeTask -> true
    | ClientTask.AssignedTask t ->
        match t.TaskType with
        | AssignedTaskType.SelfserviceDeliveryTask -> containsUserRole RoleNames.Dispatcher
        | AssignedTaskType.SelfserviceReturnTask -> containsUserRole RoleNames.Dispatcher
        | AssignedTaskType.DeliveryTask
        | ReturnTask -> containsUserRole RoleNames.Driver
        | ServiceTask -> containsUserRole RoleNames.Technician
        | PickTask -> containsUserRole RoleNames.Picker
        | SupportTask _ -> containsUserRole RoleNames.Mechanic
        | FreeTask _ -> true

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | SelectTaskCard clientTask ->
        let newSelectedTaskCard taskId connectedTaskIds =
            let newSelectedCard = (taskId, connectedTaskIds) |> Some
            match model.SelectedTaskCard with
            | Some (t, connectedTaskIds) ->
                let selectedConnectedTaskId = connectedTaskIds |> List.tryFind(fun t -> t = taskId)
                match selectedConnectedTaskId with
                | Some _ -> None
                | None ->
                    if t = taskId then None
                    else newSelectedCard
            | None ->
                newSelectedCard
        let selectedCard =
            match clientTask with
            | ClientTask.AssignedTask assignedTask -> newSelectedTaskCard assignedTask.Id assignedTask.ConnectedTaskIds
            | ClientTask.UnassignedTask unassignedTask -> newSelectedTaskCard unassignedTask.Id unassignedTask.ConnectedTaskIds
        { model with SelectedTaskCard = selectedCard }, Cmd.none
    | SwitchSidebarState ->
        let newSidebarState =
            match model.SidebarState with
            | Close -> Open
            | Open -> Close
        { model with SidebarState = newSidebarState }, Cmd.none
    | ShowPrevWeek ->
        let range =
            model.DateRange |> List.head |> (fun d -> d.AddDays -1.)
            |> weekRange
        let startDate, endDate = weekStartAndEndDate range
        { model with DateRange = range }, getTasksCmd startDate endDate
    | ShowNextWeek ->
        let range =
            model.DateRange |> List.rev |> List.head |> (fun d -> d.AddDays 1.)
            |> weekRange
        let startDate, endDate = weekStartAndEndDate range
        { model with DateRange = range }, getTasksCmd startDate endDate
    | SetFilterObjectType filterObjectType ->
        match filterObjectType with
        | Some filterObjectType ->
            let newFilteredEmployees =
                let filter role =
                    model.Employees
                    |> List.filter (fun e -> e.RoleNames |> List.exists (fun r -> r = role))
                match filterObjectType.value with
                | FilterObjectType.DeliveryOrder _
                | FilterObjectType.ReturnOrder -> filter RoleNames.Driver
                | FilterObjectType.RepairOrder -> filter RoleNames.Technician
                | FilterObjectType.PickOrder -> filter RoleNames.Picker
                | MechanicOrder -> filter RoleNames.Mechanic
                | FilterObjectType.FreeOrder -> model.Employees
                | All -> model.Employees
            { model with SelectedFilterObjectType = filterObjectType.value
                         VisibleEmployees = newFilteredEmployees }, Cmd.none
        | None -> { model with VisibleEmployees = model.Employees }, Cmd.none
    | SetDateRangeFilterStartDate startDate ->
        let _, oldEndDate = model.SelectedDateRange
        let newSelectedDateRange = (startDate, oldEndDate)
        { model with SelectedDateRange = newSelectedDateRange}, Cmd.none
    | SetDateRangeFilterEndDate endDate ->
        let oldStartDate, _ = model.SelectedDateRange
        let newSelectedDateRange = (oldStartDate, endDate)
        { model with SelectedDateRange = newSelectedDateRange}, Cmd.none
    | DroppedTask (date, userId, task, index) ->
        let relevantTasks = model.AssignedTasks |> List.filter (fun t -> t.ScheduledDate = date && t.UserId = userId) |> List.sortBy (fun t -> t.TaskPosition)
        let predecessorTaskId = relevantTasks |> List.rev |> List.tryFind (fun t -> t.TaskPosition < index) |> Option.map (fun t -> t.Id)
        let successorTaskId = relevantTasks |> List.tryFind (fun t -> t.TaskPosition > index) |> Option.map (fun t -> t.Id)
        let warningToastCmd (dateToTest : DateTime) =
            if datesAreEqual date dateToTest then Cmd.none
            else toast (ToastType.Warning "Bitte überprüfe die Richtigkeit der Disponierung. Das geplante Datum unterscheidet sich vom zugewiesenen Datum.")
        let model, cmd =
            if not (isAssignable model task userId) then
                model, Cmd.batch [ toast (ToastType.Warning "Die Aufgabe konnte leider nicht zugewiesen werden, der Benutzer besitzt diese Rolle nicht.")]
            else
                match task with
                | ClientTask.UnassignedTask task ->
                    let unassignedTasks = model.UnassignedTasks |> List.filter (fun t -> t <> task)
                    let pendingTask =
                        { PendingTask.Task = task |> ClientTask.UnassignedTask
                          UserId = userId
                          Index = index
                          Date = date
                          AffectedTaskId = task.Id
                          PredecessorTaskId = predecessorTaskId
                          SuccessorTaskId = successorTaskId }
                    let pendingTasks = pendingTask :: model.PendingTasks
                    { model with UnassignedTasks = unassignedTasks
                                 PendingTasks = pendingTasks }, Cmd.batch [ Cmd.ofMsg (AssignTask pendingTask); warningToastCmd task.PlannedDate ]
                | AssignedTask assignedTask ->
                    let pendingTask =
                        { Task = assignedTask |> ClientTask.AssignedTask
                          UserId = userId
                          Index = index
                          Date = date
                          AffectedTaskId = assignedTask.Id
                          PredecessorTaskId = predecessorTaskId
                          SuccessorTaskId = successorTaskId }
                    let pendingTasks = pendingTask :: model.PendingTasks
                    { model with PendingTasks = pendingTasks }, Cmd.batch [ Cmd.ofMsg (AssignTask pendingTask); warningToastCmd assignedTask.PlannedDate ]
        model, cmd
    | AssignTask pendingTask ->
        let dto =
            { UserId = pendingTask.UserId
              Index = pendingTask.Index
              Date = pendingTask.Date
              AffectedTaskId = pendingTask.AffectedTaskId
              PredecessorTaskId = pendingTask.PredecessorTaskId
              SuccessorTaskId = pendingTask.SuccessorTaskId }
        { model with RequestState = RequestState.Active }, assignTaskCmd dto
    | UnassignTask taskId ->
        let task = model.AssignedTasks |> List.find(fun t -> t.Id = taskId)
        let relevantTasks = model.AssignedTasks |> List.filter (fun t -> t.ScheduledDate = task.ScheduledDate && t.UserId = task.UserId) |> List.sortBy (fun t -> t.TaskPosition)
        let predecessorTaskId = relevantTasks |> List.rev |> List.tryFind (fun t -> t.TaskPosition < task.TaskPosition) |> Option.map (fun t -> t.Id)
        let successorTaskId = relevantTasks |> List.tryFind (fun t -> t.TaskPosition > task.TaskPosition) |> Option.map (fun t -> t.Id)
        let dto =
            { AffectedTaskId = taskId
              PredecessorTaskId = predecessorTaskId
              SuccessorTaskId = successorTaskId }
        { model with RequestState = RequestState.Active }, unassignTaskCmd dto
    | TaskAssigned response ->
        let startDate, endDate = weekStartAndEndDate model.DateRange
        let pendingTasks pendingTaskId =
            model.PendingTasks
            |> List.filter (fun t -> Helper.clientTaskId t.Task <> pendingTaskId)
        match response.Result with
        | Shared.DispositionDomain.AssignTaskResponse.TaskAssigned taskId ->
            let pendingTasks = pendingTasks taskId
            { model with PendingTasks = pendingTasks }, getTasksCmd startDate endDate
        | Shared.DispositionDomain.AssignTaskResponse.TaskIsNotAssignable taskId ->
            let pendingTasks = pendingTasks taskId
            { model with RequestState = Active
                         PendingTasks = pendingTasks }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Die Aufgabe ist bereits in einem anderen Zustand. Deine Dispotafel wird aktualisiert."))]
        | Shared.DispositionDomain.AssignTaskResponse.TaskIsNotInCorrectOrder taskId ->
            let pendingTasks = pendingTasks taskId
            { model with RequestState = Active
                         PendingTasks = pendingTasks }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Eine Lieferung kann nicht vor einer Abholung stattfinden."))]
    | TaskUnassigned response ->
        let startDate, endDate = weekStartAndEndDate model.DateRange
        match response.Result with
        | Shared.DispositionDomain.UnassignTaskResponse.TaskUnassigned taskId ->
            let pendingTasks pendingTaskId =
                model.PendingTasks
                |> List.filter (fun t -> Helper.clientTaskId t.Task <> pendingTaskId)
            let pendingTasks = pendingTasks taskId
            { model with PendingTasks = pendingTasks }, getTasksCmd startDate endDate
        | Shared.DispositionDomain.UnassignTaskResponse.TaskAlreadyUnnassigned taskId ->
            { model with RequestState = Active }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Die Aufgabe ist bereits in einem anderen Zustand. Deine Dispotafel wird aktualisiert."))]
        | Shared.DispositionDomain.UnassignTaskResponse.TaskIsDeleted taskId ->
            { model with RequestState = Active }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Die Aufgabe ist bereits gelöscht. Deine Dispotafel wird aktualisiert."))]
        | Shared.DispositionDomain.UnassignTaskResponse.TaskNotInPlannedState taskId ->
            { model with RequestState = Active }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Die Aufgabe ist bereits aktiv. Deine Dispotafel wird aktualisiert."))]
    | AssignTaskFailed (dto , ex) ->
        let pendingTaskId = dto.AffectedTaskId
        let pendingTasks =
            model.PendingTasks
            |> List.filter (fun t -> Helper.clientTaskId t.Task <> pendingTaskId)
        let startDate, endDate = weekStartAndEndDate model.DateRange
        { model with PendingTasks = pendingTasks }, Cmd.batch [ getTasksCmd startDate endDate; (toast (ToastType.Warning "Aufgabe konnte nicht disponiert werden."))]
    | EmployeesFetched response ->
        let filterByRole e =
            e.RoleNames
            |> List.exists (fun r ->
                r = Driver ||
                r = Mechanic ||
                r = Technician ||
                r = Picker)
        let filteredEmployees =
            response.Employees
            |> List.filter filterByRole
        { model with Employees = filteredEmployees
                     VisibleEmployees = filteredEmployees }, Cmd.none
    | EntitiesFetched response ->
        { model with Entities = response.Entities }, Cmd.none
    | TasksFetched response ->
        let assignedTasks =
           response.Tasks |> List.map (fun task ->
               match task with
               | ClientTask.AssignedTask t -> Some t
               | ClientTask.UnassignedTask t -> None)
           |> List.choose id
        let unassignedTasks =
            response.Tasks |> List.choose (fun task ->
                match task with
                | ClientTask.UnassignedTask t -> Some t
                | ClientTask.AssignedTask _ -> None)
        { model with AssignedTasks = assignedTasks
                     UnassignedTasks = unassignedTasks
                     RequestState = NotActive
                      }, Cmd.none
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
    | OpenTaskModal (task, isDisabled) ->
        let newState =
            let openState = TaskModalState.Open (task, isDisabled)
            match model.TaskModalState with
            | TaskModalState.Open _ -> TaskModalState.Closed
            | TaskModalState.Closed -> openState
        let startDate, endDate = weekStartAndEndDate model.DateRange
        { model with TaskModalState = newState }, Cmd.none
    | AddMechanicToTask mechanic ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let mechanicTask =
                    { ReferencedTaskId = task.Id
                      UserId = mechanic.value.Id }
                TaskModalState.Open (task, isDisabled), assignMechanicToTaskCmd mechanicTask
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | SetAllowMultipleDrivers ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let updatedTask = { task with AllowMultipleDrivers = not task.AllowMultipleDrivers }
                TaskModalState.Open (updatedTask, isDisabled), Cmd.none
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | SetTaskComment comment ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let updatedTask =
                    { task with ExecutionInformation = { task.ExecutionInformation with Comment = comment } }
                TaskModalState.Open (updatedTask, isDisabled), Cmd.none
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | SetTaskWorkTime workTime ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let parsedWorkTime = workTime * 1<Minutes>
                let updatedTask =
                    match task.TaskType with
                    | AssignedTaskType.FreeTask _
                    | AssignedTaskType.DeliveryTask _
                    | AssignedTaskType.PickTask _
                    | AssignedTaskType.ReturnTask _
                    | AssignedTaskType.ServiceTask ->
                        { task with ExecutionInformation = { task.ExecutionInformation with PlannedWorkTime = parsedWorkTime } }
                    | AssignedTaskType.SupportTask _ -> task
                    | AssignedTaskType.SelfserviceDeliveryTask -> task
                    | AssignedTaskType.SelfserviceReturnTask -> task
                TaskModalState.Open (updatedTask, isDisabled), Cmd.none
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | SetReturnTarget returnTarget ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let updatedTask =
                    match task.TaskType with
                    | AssignedTaskType.SelfserviceReturnTask _
                    | AssignedTaskType.ReturnTask _ ->
                        { task with ReturnTarget = returnTarget |> Some }
                    | AssignedTaskType.DeliveryTask _
                    | AssignedTaskType.PickTask _
                    | AssignedTaskType.ServiceTask _
                    | AssignedTaskType.SelfserviceDeliveryTask _
                    | AssignedTaskType.FreeTask _
                    | AssignedTaskType.SupportTask _ -> task
                TaskModalState.Open (updatedTask, isDisabled), Cmd.none
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | SaveTaskInformation ->
        let newModel, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let dto =
                    { UpdatedTaskDto.TaskId = task.Id
                      UserId = task.UserId
                      TaskType = task.TaskType
                      AllowMultipleDrivers = task.AllowMultipleDrivers
                      ReturnTarget = task.ReturnTarget
                      PlannedWorkTime = task.ExecutionInformation.PlannedWorkTime
                      Comment = task.ExecutionInformation.Comment }
                { model with TaskModalState = TaskModalState.Open (task, isDisabled)
                             RequestState = RequestState.Active }, updateTaskWithInformation dto
            | TaskModalState.Closed -> { model with TaskModalState = TaskModalState.Closed }, Cmd.none
        newModel, cmd
    | CloseTask ->
        let newModel, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let dto =
                    { TaskId = task.Id
                      ReturnTarget = task.ReturnTarget
                      CloseDate = task.ScheduledDate }
                { model with TaskModalState = TaskModalState.Open (task, isDisabled)
                             RequestState = RequestState.Active }, closeTask dto
            | TaskModalState.Closed -> { model with TaskModalState = TaskModalState.Closed }, Cmd.none
        newModel, cmd
    | RemoveMechanicFromTask employeeId ->
        let newTaskModalState, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let mechanicTask =
                    { ReferencedTaskId = task.Id
                      UserId = employeeId }
                TaskModalState.Open (task, isDisabled), unassignMechanicFromTaskCmd mechanicTask
            | TaskModalState.Closed -> TaskModalState.Closed, Cmd.none
        { model with TaskModalState = newTaskModalState }, cmd
    | RemoveMechanicFromBoard (mechanicId, referencedTaskId) ->
        let mechanicTask =
            { ReferencedTaskId = referencedTaskId
              UserId = mechanicId }
        model, unassignMechanicFromTaskCmd mechanicTask
    | TaskClosed response ->
        let toastCmd =
            match response.Result with
            | Shared.TaskTypes.CloseTaskResultResponse.Closed taskId -> Cmd.none
            | Shared.TaskTypes.CloseTaskResultResponse.NotAllEntitiesOnCorrectLocationFailed taskId ->
                (toast (ToastType.Warning "Die Aufgabe konnte nicht geschlossen werden. Die Geräte befinden sich am falschen Ort. Deine Dispotafel wird aktualisiert."))
            | Shared.TaskTypes.CloseTaskResultResponse.Failed taskId ->
                (toast (ToastType.Warning "Die Aufgabe ist bereits aktiv und konnte nicht aktualisiert werden. Deine Dispotafel wird aktualisiert."))
        let newModel, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let startDate, endDate = weekStartAndEndDate model.DateRange
                { model with TaskModalState = TaskModalState.Open (task, isDisabled) }, Cmd.batch [ getTasksCmd startDate endDate; toastCmd; Cmd.ofMsg AbortTaskModal ]
            | TaskModalState.Closed ->
                 { model with TaskModalState = TaskModalState.Closed
                              RequestState = RequestState.NotActive }, toastCmd
        newModel, cmd
    | TaskInformationUpdated response ->
        let toastCmd =
            match response.Result with
            | Shared.DispositionDomain.TaskInformationUpdatedResponse.TaskInformationUpdated taskId -> Cmd.none
            | Shared.DispositionDomain.TaskInformationUpdatedResponse.TaskNotInPlannedState taskId ->
                (toast (ToastType.Warning "Die Aufgabe ist bereits aktiv und konnte nicht aktualisiert werden. Deine Dispotafel wird aktualisiert."))
        let newModel, cmd =
            match model.TaskModalState with
            | TaskModalState.Open (task, isDisabled) ->
                let startDate, endDate = weekStartAndEndDate model.DateRange
                { model with TaskModalState = TaskModalState.Open (task, isDisabled) }, Cmd.batch [ getTasksCmd startDate endDate; toastCmd; Cmd.ofMsg AbortTaskModal ]
            | TaskModalState.Closed ->
                 { model with TaskModalState = TaskModalState.Closed
                              RequestState = RequestState.NotActive }, toastCmd
        newModel, cmd
    | MechanicTaskAssigned response ->
        let toastCmd =
            match response.Result with
            | Shared.DispositionDomain.AssignMechanicResponse.MechanicAssigned userId ->
                (toast (ToastType.Info "Mechaniker wurde erfolgreich hinzugefügt."))
            | Shared.DispositionDomain.AssignMechanicResponse.TaskNotInPlannedState userId ->
                (toast (ToastType.Warning "Mechaniker konnte nicht hinzugefügt. Die Aufgabe ist in einem anderen Zustand."))
            | Shared.DispositionDomain.AssignMechanicResponse.NoReferencedTaskFound userId ->
                (toast (ToastType.Warning "Mechaniker konnte nicht hinzugefügt werden da die Aufgabe gelöscht wurde."))

        let startDate, endDate = weekStartAndEndDate model.DateRange
        { model with RequestState = Active }, Cmd.batch [getTasksCmd startDate endDate; toastCmd]
    | MechanicTaskUnassigned response ->
        let toastCmd =
            match response.Result with
            | Shared.DispositionDomain.UnassignMechanicResponse.MechanicUnassigned userId ->
                (toast (ToastType.Info "Mechaniker wurde erfolgreich entfernt."))
            | Shared.DispositionDomain.UnassignMechanicResponse.TaskNotInPlannedState userId ->
                (toast (ToastType.Warning "Mechaniker konnte nicht entfernt werden da die Aufgabe bereits aktiv ist."))
            | Shared.DispositionDomain.UnassignMechanicResponse.NoReferencedTaskFound userId ->
                (toast (ToastType.Warning "Mechaniker konnte nicht entfernt werden da die Aufgabe bereits gelöscht wurde."))
        let startDate, endDate = weekStartAndEndDate model.DateRange
        model, Cmd.batch [getTasksCmd startDate endDate; toastCmd]
    | TaskInformationUpdateFailed exn ->
        let newTaskModalMessage =
            match model.TaskModalState with
            | TaskModalState.Open (_) ->
                Some { MessageType = MessageType.Error
                       Message = "Es ist ein Fehler beim speichern der Informationen aufgetreten." }
            | TaskModalState.Closed -> None
        { model with TaskModalMessage = newTaskModalMessage
                     RequestState = RequestState.NotActive }, Cmd.none
    | CloseTaskFailed exn ->
        let newTaskModalMessage =
            match model.TaskModalState with
            | TaskModalState.Open (_) ->
                Some { MessageType = MessageType.Error
                       Message = "Es ist ein Fehler beim abschließen der Aufgabe aufgetreten." }
            | TaskModalState.Closed -> None
        { model with TaskModalMessage = newTaskModalMessage
                     RequestState = RequestState.NotActive }, Cmd.none
    | AssignMechanicTaskFailed exn ->
        let newTaskModalMessage =
            match model.TaskModalState with
            | TaskModalState.Open (_) ->
                Some { MessageType = MessageType.Error
                       Message = "Es ist ein Fehler beim hinzufügen eines Monteurs aufgetreten." }
            | TaskModalState.Closed -> None
        { model with TaskModalMessage = newTaskModalMessage }, Cmd.none
    | UnassignMechanicTaskFailed exn ->
        let newTaskModalMessage =
            match model.TaskModalState with
            | TaskModalState.Open (_) ->
                Some { MessageType = MessageType.Error
                       Message = "Es ist ein Fehler beim entfernen eines Monteurs aufgetreten." }
            | TaskModalState.Closed -> None
        { model with TaskModalMessage = newTaskModalMessage }, Cmd.none
    | OpenSplitModal task ->
        let newState =
            let openState = SplitModalState.Open task
            match model.SplitModalState with
            | SplitModalState.Open _ -> SplitModalState.Closed
            | SplitModalState.Closed -> openState
        let splitTaskName = " TODO implement me"
        { model with SplitModalState = newState
                     SplittedTasks = [ ] }, Cmd.none
    | AbortTaskModal ->
        { model with TaskModalState = TaskModalState.Closed
                     TaskModalMessage = None }, Cmd.none
    | AbortSplit ->
        { model with SplitModalState = SplitModalState.Closed
                     SplittedTasks = [ ] }, Cmd.none
    | TaskSplitRequest ->
        { model with SplitModalState = SplitModalState.Closed }, Cmd.none
    | ConfigurationFetched response ->
        { model with Configuration = response |> Some }, Cmd.none
    | DroppedSplitTask (entity, currentSplitTask, splitAction) ->
        let filterSplittedTasks (s : SplittedTask) =
            let filteredEntities =
                s.Entities |> List.filter (fun e -> e.Id <> entity.Id)
            let filteredSplitTasks =
                model.SplittedTasks |> List.map (fun st -> if st.Id = currentSplitTask.Id then
                                                            { st with Entities = filteredEntities }
                                                           else st )
            filteredSplitTasks |> List.filter (fun s -> not (s.Id = currentSplitTask.Id && s.Entities.IsEmpty))

        let newSplittedTasks =
            match splitAction with
            | ToSplit splitTask ->
                match model.SplittedTasks |> List.tryFind (fun s -> s.Id = currentSplitTask.Id) with
                | Some s ->
                    if s.Id = currentSplitTask.Id then
                        model.SplittedTasks
                    else
                        let updatedSplittedTasks = filterSplittedTasks s
                        updatedSplittedTasks |> List.map (fun st -> if st.Id = splitTask.Id then
                                                                     { st with Entities = entity :: st.Entities }
                                                                    else st )
                | None -> model.SplittedTasks
            | NewSplit ->
                match model.SplittedTasks |> List.tryFind (fun s -> s.Id = currentSplitTask.Id) with
                | Some s ->
                    let maxPosNumber =
                        model.SplittedTasks
                        |> List.map (fun e ->
                                        let (PositionNumber posNo) = e.PosNo
                                        posNo)
                        |> List.max
                    let splitTask =
                        { Entities = [ entity ]
                          Name = currentSplitTask.Name
                          Id = System.Guid.NewGuid()
                          PosNo = (PositionNumber (maxPosNumber + 1)) }
                    splitTask :: (filterSplittedTasks s)
                | None -> model.SplittedTasks
        { model with SplittedTasks = newSplittedTasks }, Cmd.none
