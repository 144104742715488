module Shared.Carrier
open Address
open Shared

type CarrierContactInformation = {
    EMailAddress : string
    PhoneNumber : string }

type Carrier = {
    Id : CarrierId
    Name : string
    Address : Address
    ContactPerson : string
    ContactInformation : CarrierContactInformation
    AdditionalInfoText : string }

type CarrierUpdateDto =
    | UpdateCarrier of Carrier
    | UpdateAddress of Address

type SaveCarrierResult =
    | Saved of CarrierId
    | NameAlreadyExists of CarrierId

type CarriersResponse =
    { Carriers : Carrier list }

module Helper =
    let emptyCarrier =
        { Id = System.Guid.Empty |> CarrierId
          Name = ""
          Address = emptyAddress
          ContactPerson = ""
          ContactInformation = {
            EMailAddress = ""
            PhoneNumber = "" }
          AdditionalInfoText = "" }