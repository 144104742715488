module MasterData.AllEntities.View

open Fable.React
open Fable.React.Props
open SharedComponents.Headers
open SharedComponents.Breadcrumb

open Shared
open SharedComponents
open SharedComponents.Spinners
open Routes

open MasterData.AllEntities.Types
open Shared.Entity
open Feliz

open Sww.Frontend.ReactTable

let private allEntitiesOverview (entities : EntityAllEntitiesOverviewDto list) =

    let detailBtn (entity : EntityAllEntitiesOverviewDto) =
        let (EntityId entityId) = entity.EntityId
        let (TemplateId templateId) = entity.TemplateId
        a [ Href (Routes.toPath (Page.EntityViewForm (templateId, entityId)) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    let locationBadge (entity : EntityAllEntitiesOverviewDto) =
        match entity.CurrentLocationTypeAsString with
        | "Baustelle" -> Badges.badgePill "Baustelle" Badges.Primary
        | "Lager" -> Badges.badgePill "Lager" Badges.Secondary
        | "unterwegs" -> Badges.badgePill "Unterwegs" Badges.Light
        | _ -> Html.none

    let allTemplates = entities |> List.map(fun e -> e.TemplateName) |> List.distinct
    let allStorgeNames = entities |> List.map(fun e -> e.StorageName) |> List.filter(fun sName -> sName <> "") |> List.distinct

    Html.div [
        prop.id "masterdata-content"
        prop.children [
            mainHeader "Alle Geräte"
            reactTable [
                tableProp.keyField "Id"
                tableProp.defaultSortField ("Name", "asc")
                tableProp.rows entities
                tableProp.tableKey "allentities"
                tableProp.columns [
                    tableColumn [
                        colProp.selector "Name"
                        colProp.label "Name"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    tableColumn [
                        colProp.selector "TemplateName"
                        colProp.label "Baugerätekategorie"
                        colProp.sortFunction NaturalSort
                        colProp.filter (
                            SelectFilter (
                                allTemplates, (fun (entity : EntityAllEntitiesOverviewDto) -> entity.TemplateName)))
                    ]
                    tableColumn [
                        colProp.selector "CurrentLocationTypeAsString"
                        colProp.label "Standort"
                        colProp.sortFunction (CustomNaturalSort (fun (entity : EntityAllEntitiesOverviewDto) -> entity.CurrentLocationTypeAsString))
                        colProp.csvFormatter((fun value entity -> entity.CurrentLocationTypeAsString |> str))
                        colProp.filter (
                            SelectFilter (
                                [
                                  "Baustelle"
                                  "Lager"
                                  "unterwegs"
                                ], (fun (entity : EntityAllEntitiesOverviewDto) -> entity.CurrentLocationTypeAsString)))
                        colProp.formatter (Custom (fun value entity -> locationBadge entity))
                    ]
                    tableColumn [
                        colProp.selector "StorageName"
                        colProp.label "Lager"
                        colProp.sortFunction NaturalSort
                        colProp.filter (
                            SelectFilter (
                                allStorgeNames, (fun (entity : EntityAllEntitiesOverviewDto) -> entity.StorageName)))
                    ]
                    tableColumn [
                        colProp.selector "ActiveStateAsString"
                        colProp.label "Status"
                        colProp.sortFunction (CustomNaturalSort (fun (entity : EntityAllEntitiesOverviewDto) -> entity.ActiveStateAsString))
                        colProp.csvFormatter((fun value entity -> entity.ActiveStateAsString |> str))
                        colProp.filter (
                            SelectFilter (
                                [
                                  "Aktiv"
                                  "außer Betrieb"
                                ], (fun (entity : EntityAllEntitiesOverviewDto) -> entity.ActiveStateAsString)))
                        colProp.formatter (Custom (fun value entity -> Html.text entity.ActiveStateAsString))
                    ]
                    tableColumn [
                        colProp.selector "CurrentAddressAsString"
                        colProp.label "Adresse"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    tableColumn [
                        colProp.selector "ProjectNumber"
                        colProp.label "Projektnummer"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    tableColumn [
                        colProp.selector "BuildingProjectNumber"
                        colProp.label "Bauvorhaben"
                        colProp.sortFunction NaturalSort
                        colProp.filter TextFilter
                    ]
                    tableColumn [
                        colProp.selector "Aktion"
                        colProp.label "Aktion"
                        colProp.formatter (Custom (fun value order -> detailBtn order))
                        colProp.csvPrint false
                    ]
                ]
            ]
        ]
    ]

let entityOverviewBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.Templates |> Routes.toPath) "Baugerätekategorien"
        breadcrumbStr "Alle Baugeräte"
    ]

let view (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.id "masterdata-container"
        prop.className "flex-grow-1 d-flex flex-column"
        prop.children [
          overlaySpinner model.CurrentRequestState
          Html.div [
              entityOverviewBreadcrumb
              allEntitiesOverview model.Entities
          ]
        ]
    ]
