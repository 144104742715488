module Order.ServiceOrder.View


open Fable.React
open Fable.React.Props
open Order.ServiceOrder.Types
open Order.ServiceOrder.Helper
open SharedComponents
open Shared
open Shared.ServiceOrder
open SharedComponents.Headers
open SharedComponents.ReactSelect
open SharedComponents.Types.NaturalOrder
open SharedComponents.Types.Helpers
open Validation
open Shared.Entity
open Shared.RentOrder
open SharedComponents.Badges
open Shared.TaskTypes
open Shared.TaskTypes.Helper
open SharedComponents.Breadcrumb
open Routes
open SharedComponents.Spinners
open Helper.Table
open Elmish.React.Common
open Shared.Address
open System

let private newFormRentOrderProps (form : ServiceOrderNewForm) (model : Model) dispatch isDisabled =
    let displayName (rentOrder : RentOrder) =
        match rentOrder.ExecutionLocation.CompanyName with
        | Some companyName ->
            sprintf "%s %s %s %s %s" companyName rentOrder.ExecutionLocation.Street rentOrder.ExecutionLocation.HouseNumber rentOrder.ExecutionLocation.City rentOrder.ExecutionLocation.ZipCode
        | None ->
            sprintf "%s %s %s %s" rentOrder.ExecutionLocation.Street rentOrder.ExecutionLocation.HouseNumber rentOrder.ExecutionLocation.City rentOrder.ExecutionLocation.ZipCode

    let options (rentOrders : RentOrder list) =
        let gernerateValue (rentOrder : RentOrder) =
            { label = displayName rentOrder
              value = rentOrder }
        rentOrders
        |> List.filter (fun r -> r.State = RentOrderState.InProgress)
        |> List.map gernerateValue
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein Mietauftrag gefunden werden." ]

    let valueElement =
        match form.ServiceOrder.ReferencedRentOrderId with
        | Some rentOrderId ->
            model.RentOrders
            |> List.tryFind (fun rentOrder -> rentOrder.Id = rentOrderId)
            |> (fun rentOrder ->
                match rentOrder with
                | Some rentOrder -> [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Value (Some { label = displayName rentOrder; value = rentOrder }) |]
                | None -> [| |])
        | None ->  [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Value None |]

    Array.append
        [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Options (options model.RentOrders)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.OnChange (SetReferencedRentOrderId >> dispatch)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsClearable true
           SharedComponents.ReactSelect.CommonProps<RentOrder>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<RentOrder>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.ClassName "rentorder-select flex-grow-1"
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsDisabled (isDisabled) |]
        valueElement

let private editFormRentOrderProps (form : ServiceOrderEditForm) (model : Model) dispatch =
    let displayName (rentOrder : RentOrder) =
        match rentOrder.ExecutionLocation.CompanyName with
        | Some companyName ->
            sprintf "%s %s %s %s %s" companyName rentOrder.ExecutionLocation.Street rentOrder.ExecutionLocation.HouseNumber rentOrder.ExecutionLocation.City rentOrder.ExecutionLocation.ZipCode
        | None ->
            sprintf "%s %s %s %s" rentOrder.ExecutionLocation.Street rentOrder.ExecutionLocation.HouseNumber rentOrder.ExecutionLocation.City rentOrder.ExecutionLocation.ZipCode

    let options (rentOrders : RentOrder list) =
        let gernerateValue (rentOrder : RentOrder) =
            { label = displayName rentOrder
              value = rentOrder }
        rentOrders
        |> List.filter (fun r -> r.State = RentOrderState.InProgress)
        |> List.map gernerateValue
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein Mietauftrag gefunden werden." ]

    let valueElement =
        match form.ServiceOrder.ReferencedRentOrder with
        | Some rentOrder -> [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Value (Some { label = displayName rentOrder; value = rentOrder }) |]
        | None ->  [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Value None |]

    Array.append
        [| SharedComponents.ReactSelect.CommonProps<RentOrder>.Options (options model.RentOrders)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.OnChange (SetReferencedRentOrderId >> dispatch)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsClearable true
           SharedComponents.ReactSelect.CommonProps<RentOrder>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<RentOrder>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<RentOrder>.ClassName "rentorder-select flex-grow-1"
           SharedComponents.ReactSelect.CommonProps<RentOrder>.IsDisabled (true) |]
        valueElement

let private newFormEntityProps (form : ServiceOrderNewForm) (model : Model) (formValidation : FormValidation option, key) dispatch isDisabled =
    let entityName eId =
        let entityName entityId =
            match model.Entities
                  |> List.tryFind (fun e -> e.Id = entityId) with
            | Some entity -> entity.Name
            | None -> ""
        match eId with
        | EntityType.EntityId eId -> entityName eId
        | EntityType.CompoundEntityId comp -> entityName comp.EntityId
    let isInvalid, invalidText = Validation.Validate.isInvalid formValidation key
    let className =
      if isInvalid then
        "entity-select invalid-select flex-grow-1"
      else
        "entity-select flex-grow-1"
    let options =
        match form.ServiceOrder.ReferencedRentOrderId with
        | Some rentOrderId ->
            form.FilteredEntities
            |> List.map (fun eId ->
                { label = entityName eId
                  value = eId })
            |> List.toArray
        | None ->
            form.FilteredEntities
            |> List.map (fun eId ->
                { label = entityName eId
                  value = eId })
            |> List.toArray
    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passendes Gerät gefunden werden." ]
    let valueElements =
        let elements =
            form.ServiceOrder.EntityIds
            |> List.map (fun eId -> { label = entityName eId; value = eId })
            |> List.toArray
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.Value elements |]
    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.Options (options)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.OnChange (SetEntities >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.IsDisabled isDisabled
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<EntityType>.ClassName className |]
        valueElements

let private editFormEntityProps (form : ServiceOrderEditForm) (model : Model) (formValidation : FormValidation option, key) dispatch =
    // let entityName eId =
    //     let entityName entityId =
    //         match model.Entities
    //               |> List.tryFind (fun e -> e.Id = entityId) with
    //         | Some entity -> entity.Name
    //         | None -> ""
    //     match eId with
    //     | EntityType.EntityId eId -> entityName eId
    //     | EntityType.CompoundEntityId comp -> entityName comp.EntityId
    let isInvalid, invalidText = Validation.Validate.isInvalid formValidation key
    let className =
      if isInvalid then
        "entity-select invalid-select flex-grow-1"
      else
        "entity-select flex-grow-1"
    // let options =
    //     match form.ServiceOrder.ReferencedRentOrder with
    //     | Some rentOrder ->
    //         form.FilteredEntities
    //         |> List.map (fun eId ->
    //             { label = entityName eId
    //               value = eId })
    //         |> List.toArray
    //     | None ->
    //         form.FilteredEntities
    //         |> List.map (fun eId ->
    //             { label = entityName eId
    //               value = eId })
    //         |> List.toArray
    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passendes Gerät gefunden werden." ]
    let valueElements =
        let elements =
            // model.FetchedTypes.Entities
            // |> List.filter (fun e -> repairOrderForm.RepairOrder.RepairOrderBaseInformation.EntityIds |> List.contains (e.Id |> EntityType.EntityId))
            form.ServiceOrder.Entities
            |> List.map (fun entity -> { label = entity.Name; value = entity })
            |> List.toArray
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.Value elements |]
    Array.append
        [|
          // SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.Options (options)
          //  SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.OnChange (SetEntities >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.IsDisabled true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<Entity>.ClassName className |]
        valueElements

let priorityChooser (priority : PriorityLevel) dispatch disabled =
  div [ Class "btn-group flex-grow-1"
        Role "group"
        HTMLAttr.Custom ("aria-label", "Basic example") ]
      [ button [ Class (sprintf "%s" (if priority = PriorityLevel.Low && disabled then "btn btn-info"
                                      elif priority = PriorityLevel.Low && not disabled then "btn btn-outline-info active"
                                      else "btn btn-outline-info"))
                 OnClick (fun _ -> dispatch (SetPriorityLevel PriorityLevel.Low))
                 Disabled disabled ]
          [ str "niedrig" ]
        button [ Class (sprintf "%s" (if priority = PriorityLevel.Medium && disabled then "btn btn-warning"
                                      elif priority = PriorityLevel.Medium && not disabled then "btn btn-outline-warning active"
                                      else "btn btn-outline-warning"))
                 OnClick (fun _ -> dispatch (SetPriorityLevel PriorityLevel.Medium))
                 Disabled disabled]
          [ str "mittel" ]
        button [ Class (sprintf "%s" (if priority = PriorityLevel.High && disabled then "btn btn-danger"
                                      elif priority = PriorityLevel.High && not disabled then "btn btn-outline-danger active"
                                      else "btn btn-outline-danger"))
                 OnClick (fun _ -> dispatch (SetPriorityLevel PriorityLevel.High))
                 Disabled disabled ]
          [ str "hoch" ] ]

let private basePart validation plannedExecutionDate isDisabled model dispatch formState =
    match model.FormState with
    | FormState.New form ->
        div [ ]
          [ subHeaderForm "Allgemein"
            ReactSelect.selectWithLabel (newFormRentOrderProps form model dispatch isDisabled) (toLabelType "Mietauftrag" isDisabled false formState) ""
            ReactSelect.multiSelectWithLabel (newFormEntityProps form model (validation, "entities") dispatch isDisabled) (toLabelType "Gerät" isDisabled true formState) "entity-select-label"
            ReactDatepicker.Datepicker.datepicker(
                Some plannedExecutionDate,
                (fun date -> dispatch (SetPlannedExecutionDate date)),
                labelName = "Geplantes Ausführungsdatum",
                minDate = System.DateTime.UtcNow.Date,
                isDisabled = isDisabled) ]
    | FormState.Edit form ->
        div [ ]
          [ subHeaderByFormState (match form.EditField with | EditField.EditGeneralInformation _ -> true | _ -> false)
              "Allgemein"
              (fun _ -> EditGeneralInformation |> dispatch) ""
              (fun _ -> SaveServiceOrder |> dispatch) ""
              (fun _ -> EndEdit |> dispatch) ""
              formState
            ReactSelect.selectWithLabel (editFormRentOrderProps form model dispatch) (toLabelType "Mietauftrag" isDisabled false formState) ""
            ReactSelect.multiSelectWithLabel (editFormEntityProps form model (validation, "entities") dispatch) (toLabelType "Gerät" isDisabled true formState) "entity-select-label"
            ReactDatepicker.Datepicker.datepicker(
                Some plannedExecutionDate,
                (fun date -> dispatch (SetPlannedExecutionDate date)),
                labelName = "Geplantes Ausführungsdatum",
                minDate = System.DateTime.UtcNow.Date,
                isDisabled = (match form.EditField with | EditField.EditGeneralInformation _ -> false | _ -> true)) ]
    | FormState.Loading ->
        Fable.React.Helpers.nothing

let private addressPart (executionLocation : Address) =
    div [ ]
      [ subHeaderForm "Adresse"
        Form.inlineLabel "Straße" executionLocation.Street "street"
        Form.inlineLabel "Hausnummer" executionLocation.HouseNumber "housenumber"
        Form.inlineLabel "PLZ" executionLocation.ZipCode "zipcode"
        Form.inlineLabel "Stadt" executionLocation.City "city" ]

let private serviceOrderEditForm (form : ServiceOrderEditForm) (model : Model) (dispatch : Msg -> unit) isCreateOrEditable (formState : SharedComponents.Types.FormState) =
    let isInfoDisabled = (match form.EditField with | EditField.EditInformation _ -> false | _ -> true)
    let isPriorityDisabled = (match form.EditField with | EditField.EditPriorityLevel _ -> false | _ -> true)
    div [ ]
        [ div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ basePart form.FormValidation form.ServiceOrder.PlannedExecutionDate true model dispatch formState ]
              div [ Class "flex-grow-1"]
                [ addressPart form.ServiceOrder.ExecutionAddress ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ if not isCreateOrEditable || form.ServiceOrder.State <> ServiceOrderState.Undisposed then
                      subHeaderForm "Informationen"
                  else
                    subHeaderByFormState (match form.EditField with | EditField.EditInformation _ -> true | _ -> false)
                      "Informationen"
                      (fun _ -> EditInformation |> dispatch) ""
                      (fun _ -> SaveServiceOrder |> dispatch) ""
                      (fun _ -> EndEdit |> dispatch) ""
                      formState
                  Form.Input.inlineInput((fun ev -> dispatch (SetContactPerson ev)), form.ServiceOrder.ContactPerson,
                                         inputLabel = "Ansprechpartner", isDisabled = isInfoDisabled, cyPostFix = "contact-person")
                  Form.textArea (fun ev -> dispatch (SetFailureDescription ev)) form.ServiceOrder.FailureDescription "Beschreibung" isInfoDisabled "description" ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1 p-2"]
                [ if not isCreateOrEditable || form.ServiceOrder.State <> ServiceOrderState.Undisposed then
                      subHeaderForm "Priorität"
                  else
                      subHeaderByFormState (match form.EditField with | EditField.EditPriorityLevel _ -> true | _ -> false)
                        "Priorität"
                        (fun _ -> EditPriorityLevel |> dispatch) ""
                        (fun _ -> SaveServiceOrder |> dispatch) ""
                        (fun _ -> EndEdit |> dispatch) ""
                        formState
                  priorityChooser form.ServiceOrder.Priority dispatch isPriorityDisabled ] ] ]

let private serviceOrderNewForm (form : ServiceOrderNewForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    div [ ]
        [ div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ basePart form.FormValidation form.ServiceOrder.PlannedExecutionDate false model dispatch formState ]
              div [ Class "flex-grow-1"]
                [ addressPart form.ServiceOrder.ExecutionAddress ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1"]
                [ subHeaderForm "Informationen"
                  Form.Input.inlineInput((fun ev -> dispatch (SetContactPerson ev)), form.ServiceOrder.ContactPerson,
                                         inputLabel = "Ansprechpartner", cyPostFix = "contact-person")
                  Form.textArea (fun ev -> dispatch (SetFailureDescription ev)) form.ServiceOrder.FailureDescription "Beschreibung" false "description"  ] ]
          div [ Class "d-flex flex-sm-row flex-column"]
            [ div [ Class "flex-grow-1 p-2"]
                [ subHeaderForm "Priorität"
                  priorityChooser form.ServiceOrder.Priority dispatch false ] ]
          div [ Class "controls d-flex" ]
            [ Buttons.primaryButtonWithFnct (fun _ -> SaveServiceOrder |> dispatch ) "Auftrag anlegen" "ml-auto" ] ]

let serviceOrderFormBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RepairOrderOverview |> Routes.toPath) "Serviceaufträge"
        breadcrumbStr "Serviceauftrag"
    ]

let newRepairOrderFormView (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ serviceOrderFormBreadcrumb
          overlaySpinner model.ServiceOrderRequestState
          div [ Id "order-content" ]
            [ mainHeader "Serviceauftrag anlegen"
              div [ ]
                [ (match model.FormState with
                  | New form -> serviceOrderNewForm form model dispatch SharedComponents.Types.New
                  | Edit form -> failwith "wrong formstate"
                  | FormState.Loading -> Spinners.overlaySpinner SharedComponents.Spinners.RequestState.Active ) ] ] ]

let private technicanName (technicans : Employee list) technicanId =
    technicans
    |> List.tryFind (fun d -> d.Id = technicanId)
    |> (function
        | Some d -> sprintf "%s %s" d.Firstname d.Lastname
        | None -> "")

let private taskDispositionRow (form : ServiceOrderEditForm) (task : ServiceOrderTaskType) =
    let repairBadge = (badgePill "Service" BadgeClassType.Light)
    let executionDate (assignedServiceTask : AssignedServiceTask) =
      match assignedServiceTask.ServiceReport with
      | Some r -> r.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm")
      | None -> assignedServiceTask.ScheduledExecutionDate.ToLocalTime().ToShortDateString()
    let (taskBadge, stateBadge, showDetails), TaskId taskId, ServiceOrderId repairOrderId, technicanName, deliveryDate =
        match task with
        | ServiceOrderTaskType.ServiceTask t ->
            (repairBadge, (badgePill "offen" BadgeClassType.Info), false), t.TaskId, t.ServiceOrderId, "", ""
        | ServiceOrderTaskType.AssignedServiceTask t ->
            (match t.State with
            | ServiceTaskState.Planned -> repairBadge, (badgePill "eingelant" BadgeClassType.Info), false
            | ServiceTaskState.Approaching
            | ServiceTaskState.DrivingHome
            | ServiceTaskState.Executing -> repairBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
            | ServiceTaskState.Finished ->
              repairBadge, (badgePill "ausgeführt" BadgeClassType.Info), true), t.TaskId, t.ServiceOrderId, technicanName form.ServiceOrder.Technicans t.UserId, executionDate t

    tr [ Key (taskId |> string) ]
        [ td [ ]
            [ taskBadge ]
          td [ ]
            [ str deliveryDate ]
          td [ ]
            [ str technicanName ]
          td [ ]
            [ stateBadge ]
          td [ ]
            [ (if showDetails then
                a [ Href (Routes.toPath (Routes.Page.RepairOrderDispositionDetail (repairOrderId, taskId))) ]
                    [ str "Details"]
               else Fable.React.Helpers.nothing) ] ]

let private taskDispositionTable (form : ServiceOrderEditForm) =
    div [ Class "table-responsive" ]
      [ table [ Class "table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                      [ str "Aufgabe" ]
                    th [ ]
                      [ str "Datum" ]
                    th [ ]
                      [ str "Techniker" ]
                    th [ ]
                      [ str "Status" ]
                    th [ ]
                      [ str "" ] ] ]
            tbody [ ]
              [ form.ServiceOrderTasks
                |> List.map (taskDispositionRow form)
                |> ofList ] ] ]

let detailView orderId (model : Model) (dispatch : Msg -> unit) =
    let isCreateOrEditable = isServiceOrderEditableOrCreatable model.UserData
    let openDeleteCmd =
      match model.FormState with
      | Edit form ->
          if form.ServiceOrder.State = ServiceOrderState.Undisposed then (Some (fun _ -> dispatch RequestDelete))
          else None
      | New form -> None
      | FormState.Loading -> None
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ SharedComponents.Spinners.overlaySpinner model.ServiceOrderRequestState
          serviceOrderFormBreadcrumb
          section [ ]
            [ deleteModal model.FormState dispatch ]
          match model.FormState with
          | FormState.Edit form ->
              div [ Id "order-content" ]
                [ if isCreateOrEditable then
                    mainHeaderBar None "Serviceauftrag" None openDeleteCmd
                  else mainHeader "Serviceauftrag"
                  div [ ]
                    [ serviceOrderEditForm form model dispatch isCreateOrEditable SharedComponents.Types.FormState.View
                      subHeaderForm "Disposition"
                      taskDispositionTable form ] ]
          | FormState.New form -> failwith "wrong formstate"
          | FormState.Loading -> SharedComponents.Spinners.overlaySpinner SharedComponents.Spinners.RequestState.Active ]

open Sww.Frontend.ReactTable

let private orderTable (model : Model) dispatch =
    let dateSort (a : string) (b : string) (order : string) _ (rowA : ServiceOrderOverviewDto) (rowB : ServiceOrderOverviewDto) =
        if order = "asc" then
            DateTime.Compare(rowA.PlannedExecutionDate, rowB.PlannedExecutionDate)
        else
            DateTime.Compare(rowB.PlannedExecutionDate, rowA.PlannedExecutionDate)

    reactTable [
        tableProp.keyField "Id"
        tableProp.rows model.ServiceOrders
        tableProp.tableKey "serviceorders"
        tableProp.tableExportName "serviceauftraege_export.csv"
        tableProp.columns [
            tableColumn [
                colProp.selector "EntityNames"
                colProp.label "Geräte"
                colProp.sortFunction (CustomNaturalSort(fun v -> entitiesToDisplayAsString v))
                colProp.filter TextFilter
                colProp.formatter (
                    Custom (fun value order ->
                        entitiesToDisplayAsString order |> str))
            ]
            tableColumn [
                colProp.selector "ExecutionAddress.City"
                colProp.label "Stadt"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "PlannedExecutionDate"
                colProp.label "Geplantes Ausführungsdatum"
                colProp.sortFunction (DateSort (fun order -> order.PlannedExecutionDate))
                colProp.filter NoFilter
                colProp.formatter (Custom (fun value order -> order.PlannedExecutionDate.ToString("dd.MM.yyyy") |> str))
            ]
            tableColumn [
                colProp.selector "State"
                colProp.label "Status"
                colProp.sortFunction (CustomNaturalSort (fun (data : ServiceOrderOverviewDto) -> 
                  data.State |> Shared.ServiceOrder.Helper.stateToString))
                colProp.filter (SelectFilter(selectOptions, (fun data -> data.State |> Shared.ServiceOrder.Helper.stateToString)))
                colProp.formatter (Custom (fun value order -> stateBadge order))
                colProp.csvFormatter((fun value data -> data.State |> Shared.ServiceOrder.Helper.stateToString |> str))
            ]
            tableColumn [
                colProp.selector "Aktion"
                colProp.label "Aktion"
                colProp.formatter (Custom (fun value order -> detailBtn order))
                colProp.csvPrint false
            ]
        ]
    ]


let serviceOrderOverviewBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbStr "Serviceaufträge"
    ]

let view (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.ServiceOrderRequestState
          serviceOrderOverviewBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Serviceaufträge"
              div [ Class "controls d-flex justify-content-end mb-2" ]
                [ if isServiceOrderEditableOrCreatable model.UserData then
                      div [ Class "" ]
                        [ Buttons.primaryButton (Routes.toPath Routes.Page.RepairOrderNewForm) "Auftrag anlegen" "ml-auto"]
                  else Fable.React.Helpers.nothing ]
              orderTable model dispatch
              div [ Class "controls d-flex justify-content-end" ]
                  [ div [ Class "" ]
                      [ Buttons.primaryButton (Routes.toPath (Page.ServiceOrderOverviewCompleted)) "Archiv" "ml-auto btn-sm" ] ]  ] ]


let serviceOrderOverviewCompletedBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RepairOrderOverview |> Routes.toPath) "Serviceaufträge"
        breadcrumbStr "Archiv"
    ]

let viewServiceOrderCompleted (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.ServiceOrderRequestState
          serviceOrderOverviewCompletedBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Serviceaufträge"
              orderTable model dispatch ] ]
