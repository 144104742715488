module Order.ServiceOrder.State

open Elmish
open Elmish.Navigation
open Thoth.Json
open Order.ServiceOrder.Types
open Shared
open Shared.ServiceOrder
open Order.ServiceOrder.FormValidation
open Order.ServiceOrder.Helper
open Validation
open Shared.Entity
open System
open Shared.Address
open Shared.Configuration
open Shared.TaskTypes
open Routes
open SharedComponents.Toast
open SharedComponents.Spinners

let getConfigurationCmd =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" ConfigurationFetched FetchError

let getEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntitiesDtoResponse> "/api/entitiesdto" EntitiesFetched FetchError

let getServiceOrdersCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/repairorders/active" ServiceOrdersFetched FetchError

let getServiceOrdersCompletedCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/repairorders/completed" ServiceOrdersFetched FetchError

let getRentOrdersCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/rentorders" RentOrdersFetched FetchError

let getServiceOrderCmd (orderId : System.Guid) =
    let id = orderId.ToString()
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/repairorders/%s" id) ServiceOrderFetched FetchError

let saveServiceOrderCmd form =
    Cmd.OfPromise.either Communication.postRequest ("/api/repairorders", form) ServiceOrderSaved FetchError

let updateServiceOrderCmd serviceOrderId form =
    let (ServiceOrderId serviceOrderId) = serviceOrderId
    let url = sprintf "/api/serviceorders/%s" (serviceOrderId |> string)
    Cmd.OfPromise.either Communication.putRequest (url, form) ServiceOrderUpdated FetchError

let deleteRepairOrderCmd (repairOrderId : Guid) =
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<ServiceOrderId>> (sprintf "/api/repairorders/%s/delete" (repairOrderId.ToString ())) ServiceOrderDeleted FetchError

let initialModel userData =
    { ServiceOrders = []
      FormState = FormState.Loading
      Configuration = None
      Entities = [ ]
      UserData = userData
      RentOrders = [ ]
      SaveState = SaveState.Nothing
      ServiceOrderRequestState = RequestState.Active
    }

let initialServiceOrder =
    { PlannedExecutionDate = System.DateTime.UtcNow
      ExecutionDate = System.DateTime.UtcNow
      EntityIds = [ ]
      ContactPerson = ""
      FailureDescription = ""
      Priority = PriorityLevel.Low
      ExecutionAddress = emptyAddress
      Id = ServiceOrderId System.Guid.Empty
      State = ServiceOrderState.Undisposed
      ReferencedRentOrderId = None
      PreviousServiceOrderId = None
      Tasks = [] }

let initNewForm userData : Model * Cmd<Msg> =
    let newFormState =
        { ServiceOrder = initialServiceOrder
          FilteredEntities = [ ]
          FormValidation = None } |> FormState.New
    { initialModel userData with FormState = newFormState }, Cmd.batch [ getEntitiesCmd; getRentOrdersCmd; getConfigurationCmd ]

let initOverview userData (oldModel : Model option) : Model * Cmd<Msg> =
    { initialModel userData with ServiceOrderRequestState = RequestState.Active }, getServiceOrdersCmd

let initOverviewCompleted userData (oldModel : Model option) : Model * Cmd<Msg> =
    { initialModel userData with ServiceOrderRequestState = RequestState.Active }, getServiceOrdersCompletedCmd

let initDetail userData orderId : Model * Cmd<Msg> =
    { initialModel userData with ServiceOrderRequestState = RequestState.Active }, Cmd.batch [ getServiceOrderCmd orderId; getConfigurationCmd ]

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    // Form
    | SetEntities entities ->
        let entitiyIds =
            entities |> Array.toList |> List.map (fun o -> o.value)

        let newFormState, cmd =
            match model.FormState with
            | New form->
                match form.ServiceOrder.ReferencedRentOrderId with
                | Some rentOrderId ->
                    let referencedRentOrderIdOption, newFilteredEntities, cmd =
                        if entitiyIds.IsEmpty then None, model.Entities |> List.map (fun e -> e.Id |> EntityType.EntityId), Cmd.ofMsg (SetAddressFromRentOrder None)
                        else
                            let rentOrderEntityIds =
                                let rentOrder =
                                    model.RentOrders
                                    |> List.find (fun r -> r.Id = rentOrderId)
                                rentOrder.Positions
                                |> List.choose (function
                                                | RentOrderEntityPosition pos -> Some pos
                                                | RentOrderMaterialPosition _ -> None)
                                |> Shared.RentOrder.Helper.toCompoundEntityId
                                |> List.filter (fun e -> not (entitiyIds |> List.contains e))
                            Some rentOrderId, rentOrderEntityIds, Cmd.none
                    { form with ServiceOrder = { form.ServiceOrder with EntityIds = entitiyIds
                                                                        ReferencedRentOrderId = referencedRentOrderIdOption }
                                FilteredEntities = newFilteredEntities } |> FormState.New, cmd
                | None ->
                    let filteredEntities, cmd =
                        if entitiyIds.Length >= 1 then
                            let entity =
                                (model.Entities
                                |> List.find (fun e ->
                                    match entitiyIds.Head with
                                    | EntityType.EntityId entityId -> entityId = e.Id
                                    | EntityType.CompoundEntityId comp -> comp.EntityId = e.Id))
                            let currentAddress = entity.CurrentAddress
                            let filteredEntities =
                                let alreadyAddedEntityIds = entitiyIds |> List.collect Shared.TaskTypes.Helper.toEntityIds
                                model.Entities
                                |> List.filter (fun e -> not (alreadyAddedEntityIds |> List.contains e.Id) && e.CurrentLocation = entity.CurrentLocation)
                                |> List.map (fun e -> e.Id |> EntityType.EntityId)
                            filteredEntities, Cmd.batch [ Cmd.ofMsg (SetStreet currentAddress.Street)
                                                          Cmd.ofMsg (SetHouseNumber currentAddress.HouseNumber)
                                                          Cmd.ofMsg (SetZipCode currentAddress.ZipCode)
                                                          Cmd.ofMsg (SetCity currentAddress.City)
                                                          Cmd.ofMsg (SetCoordinates currentAddress.Coordinates) ]
                        else model.Entities |> List.map (fun e -> e.Id |> EntityType.EntityId),
                             Cmd.batch [ Cmd.ofMsg (SetStreet "")
                                         Cmd.ofMsg (SetHouseNumber "")
                                         Cmd.ofMsg (SetZipCode "")
                                         Cmd.ofMsg (SetCity "")
                                         Cmd.ofMsg (SetCoordinates None) ]
                    { form with ServiceOrder = { form.ServiceOrder with EntityIds = entitiyIds
                                                                        ReferencedRentOrderId = None }
                                FilteredEntities = filteredEntities } |> FormState.New, cmd
            | Edit form -> form |> FormState.Edit, Cmd.none
            | FormState.Loading -> FormState.Loading, Cmd.none
        { model with FormState = newFormState }, cmd
    | SetReferencedRentOrderId rentOrderOption ->
        let newFormState, cmd =
            match model.FormState with
            | New form ->
                match rentOrderOption with
                | Some rentOrder ->
                    let rentOrderEntityIds =
                        rentOrder.value.Positions
                        |> List.choose (function
                                        | RentOrderEntityPosition pos -> Some pos
                                        | RentOrderMaterialPosition _ -> None)
                        |> List.filter (fun p -> p.State <> RentOrderPositionState.Returned && p.State <> RentOrderPositionState.Planned)
                        |> Shared.RentOrder.Helper.toCompoundEntityId
                    { form with ServiceOrder = { form.ServiceOrder with EntityIds = rentOrderEntityIds
                                                                        ReferencedRentOrderId = Some rentOrder.value.Id }
                                FilteredEntities = rentOrderEntityIds }
                    |> FormState.New, Cmd.ofMsg (SetAddressFromRentOrder (Some rentOrder.value))
                | None ->
                    { form with ServiceOrder = { form.ServiceOrder with EntityIds = [ ]
                                                                        ReferencedRentOrderId = None }
                                FilteredEntities = model.Entities |> List.map (fun e -> EntityType.EntityId e.Id) }
                    |> FormState.New, Cmd.ofMsg (SetAddressFromRentOrder (None))
            | Edit form -> form |> FormState.Edit, Cmd.none
            | FormState.Loading -> FormState.Loading, Cmd.none
        { model with FormState = newFormState }, cmd
    | SetAddressFromRentOrder rentOrderOption ->
        let newFormState, newCmd =
            match rentOrderOption with
            | Some rentOrder ->
                model.FormState,
                Cmd.batch [ Cmd.ofMsg (SetCity rentOrder.ExecutionLocation.City)
                            Cmd.ofMsg (SetZipCode rentOrder.ExecutionLocation.ZipCode)
                            Cmd.ofMsg (SetStreet rentOrder.ExecutionLocation.Street)
                            Cmd.ofMsg (SetHouseNumber rentOrder.ExecutionLocation.HouseNumber)
                            Cmd.ofMsg (SetCoordinates rentOrder.ExecutionLocation.Coordinates) ]
            | None ->
                match model.FormState with
                | New form ->
                    { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = emptyAddress } } |> FormState.New, Cmd.none
                | Edit form -> form |> FormState.Edit, Cmd.none
                | FormState.Loading -> FormState.Loading, Cmd.none
        { model with FormState = newFormState }, newCmd
    | SetFailureDescription failureDescription ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with ServiceOrder = { form.ServiceOrder with FailureDescription = failureDescription } } |> FormState.New
            | Edit form ->
                { form with ServiceOrder = { form.ServiceOrder with FailureDescription = failureDescription } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetPriorityLevel priorityLevel ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with ServiceOrder = { form.ServiceOrder with Priority = priorityLevel } } |> FormState.New
            | Edit form ->
                { form with ServiceOrder = { form.ServiceOrder with Priority = priorityLevel } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetPlannedExecutionDate plannedExecutionDate ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with ServiceOrder = { form.ServiceOrder with PlannedExecutionDate = plannedExecutionDate } } |> FormState.New
            | Edit form ->
                { form with ServiceOrder = { form.ServiceOrder with PlannedExecutionDate = plannedExecutionDate } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetContactPerson contactPerson ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with ServiceOrder = { form.ServiceOrder with ContactPerson = contactPerson } } |> FormState.New
            | Edit form ->
                { form with ServiceOrder = { form.ServiceOrder with ContactPerson = contactPerson } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetCity city ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with City = city }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with City = city }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetStreet street ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with Street = street }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with Street = street }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetZipCode zipCode ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with ZipCode = zipCode }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with ZipCode = zipCode }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHouseNumber houseNumber ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with HouseNumber = houseNumber }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with HouseNumber = houseNumber }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetCoordinates coordinates ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with Coordinates = coordinates }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.ServiceOrder.ExecutionAddress with Coordinates = coordinates }
                { form with ServiceOrder = { form.ServiceOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Set edit states
    | EditInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditInformation
                                       ServiceOrder = form.ServiceOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditPriorityLevel ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditPriorityLevel
                                       ServiceOrder = form.ServiceOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditGeneralInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditGeneralInformation
                                       ServiceOrder = form.ServiceOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Nothing
                            ServiceOrder = form.ServiceOrderSnapshot }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    | SaveServiceOrder ->
        let validation =
            match model.FormState with
            | New form ->
                validateNewForm form
            | Edit form ->
                match form.EditField with
                | EditField.EditInformation -> isValid
                | EditField.EditPriorityLevel -> isValid
                | EditField.EditGeneralInformation -> isValid
                | EditField.Nothing -> notValid
            | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let newModel, cmd =
                    match model.FormState with
                    | New form ->
                        { model with ServiceOrderRequestState = RequestState.Active },
                        form.ServiceOrder
                        |> Encode.toString 0
                        |> saveServiceOrderCmd
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditInformation ->
                            { model with ServiceOrderRequestState = RequestState.Active },
                            { ServiceInformation.ContactPerson = form.ServiceOrder.ContactPerson
                              FailureDescription = form.ServiceOrder.FailureDescription }
                            |> ServiceOrderUpdate.FailureDescription
                            |> Encode.toString 0
                            |> updateServiceOrderCmd form.ServiceOrder.Id
                        | EditField.EditPriorityLevel ->
                            { model with ServiceOrderRequestState = RequestState.Active },
                            form.ServiceOrder.Priority
                            |> ServiceOrderUpdate.Priority
                            |> Encode.toString 0
                            |> updateServiceOrderCmd form.ServiceOrder.Id
                        | EditField.EditGeneralInformation ->
                            { model with ServiceOrderRequestState = RequestState.Active },
                            { GeneralInformation.PlannedExecutionDate = form.ServiceOrder.PlannedExecutionDate }
                            |> ServiceOrderUpdate.GeneralInformation
                            |> Encode.toString 0
                            |> updateServiceOrderCmd form.ServiceOrder.Id
                        | EditField.Nothing -> model, Cmd.none
                    | FormState.Loading -> model, Cmd.none
                { newModel with SaveState = SaveState.Saving }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, Cmd.batch [addressToastCmd validation; warningToast]
                | FormState.Loading -> model, Cmd.none
                | New form ->
                    let newFormState = { form with FormValidation = Some validation } |> New
                    { model with FormState = newFormState }, Cmd.batch [addressToastCmd validation; warningToast]
        newModel, cmd

    // Delete actions
    | AbortDelete ->
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form ->
                { form with DeleteRequested = None } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | RequestDelete ->
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form ->
                { form with DeleteRequested = Some form.ServiceOrder.Id } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | DeleteRequest ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                let (ServiceOrderId id) = form.ServiceOrder.Id
                { model with ServiceOrderRequestState = RequestState.Active },
                deleteRepairOrderCmd id
            | FormState.Loading -> model, Cmd.none
        newModel, newCmd

    | ServiceOrderSaved response ->
        let model, cmd =
            match model.FormState with
            | New form ->
                model, Cmd.batch [ Navigation.newUrl (Routes.toPath (Page.RepairOrderDetail response.Result)); toast (ToastType.Success "Serviceauftrag erfolgreich angelegt.")]
            | Edit _
            | FormState.Loading -> model, Cmd.none
        { model with SaveState = SaveState.Nothing
                     ServiceOrderRequestState = RequestState.NotActive }, cmd
    | ServiceOrderDeleted response ->
        { initialModel model.UserData with ServiceOrderRequestState = RequestState.NotActive }, Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.RepairOrderOverview )); toast (ToastType.Info "Serviceauftrag wurde erfolgreich gelöscht.")]
    | ServiceOrderUpdated response ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                match response.Result with
                | ServiceOrderUpdatedResponse.Updated ->
                    { model with FormState = { form with ServiceOrderSnapshot = form.ServiceOrder
                                                         EditField = EditField.Nothing } |> FormState.Edit },
                    toast (ToastType.Success "Serviceauftrag erfolgreich aktualisiert.")
                | ServiceOrderUpdatedResponse.ServiceOrderCompleted ->
                    model, toast (ToastType.Error "Serviceauftrag konnte nicht aktualisiert werden. Der Auftrag ist bereits abgeschlossen.")
                | ServiceOrderUpdatedResponse.ServiceOrderTaskAlreadyAssigned ->
                    model, toast (ToastType.Error "Serviceauftrag konnte nicht aktualisiert werden. Die Serviceaufgabe ist bereits einem Techniker zugewiesen.")
        { model with SaveState = Nothing
                     ServiceOrderRequestState = RequestState.NotActive }, cmd
    | ServiceOrderFetched response ->
        let serviceOrderTasks =
            response.Tasks
            |> List.map
                (function
                | ServiceOrderTask.AssignedServiceTask t -> ServiceOrderTaskType.AssignedServiceTask t
                | ServiceOrderTask.ServiceTask t -> ServiceOrderTaskType.ServiceTask t)
        let formStateEdit =
            { ServiceOrder = response
              ServiceOrderSnapshot = response
              FormValidation = None
              DeleteRequested = None
              ServiceOrderTasks = serviceOrderTasks
              EditField = EditField.Nothing }
        let newFormState, newCmd =
            match model.FormState with
            | New form -> form |> FormState.New, Cmd.none
            | FormState.Loading -> formStateEdit |> FormState.Edit, Cmd.none
            | FormState.Edit form -> formStateEdit |> FormState.Edit, Cmd.none
        { model with FormState = newFormState
                     ServiceOrderRequestState = RequestState.NotActive }, newCmd
    | ConfigurationFetched response ->
        { model with Configuration = Some response }, Cmd.none
    | ServiceOrdersFetched response ->
        { model with ServiceOrders = response.ServiceOrders
                     ServiceOrderRequestState = RequestState.NotActive }, Cmd.none
    | EntitiesFetched response ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with FilteredEntities = response.EntityDtos |> List.map (fun e -> e.Id |> EntityType.EntityId) }
                |> FormState.New
            | Edit form ->
                form
                |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with Entities = response.EntityDtos
                     FormState = newFormState
                     ServiceOrderRequestState = RequestState.NotActive }, Cmd.none
    | RentOrdersFetched response ->
        { model with RentOrders = response.RentOrders }, Cmd.none
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
