module MasterData.Entities.Helper

open MasterData.Entities.Types
open Shared
open System
open SharedComponents.DeleteMsgBox
open Shared.Entity
open SharedComponents
open Fable.React.Helpers

let floatRegexInputFieldPattern = @"[0-9]+([,\.][0-9]+)?"
let floatStateRegex = @"^[-+]?\d+\,?\d*$"

let isEntityEditableOrCreatable (userData : UserData) =
    userData.AssignedRoles
    |> List.exists (fun r -> r = Administrator || r = Dispatcher)

let createAttribute attribute =
    match attribute.Type with
    | AttributeType.Boolean ->
        EntityAttribute.EntityBoolAttribute { AttributeId = attribute.Id; Value = false }
    | AttributeType.Integer ->
        EntityAttribute.EntityIntAttribute { AttributeId = attribute.Id; Value = 0 }
    | AttributeType.Decimal ->
        EntityAttribute.EntityDecimalAttribute { AttributeId = attribute.Id; Value = 0.0 }
    | AttributeType.String ->
        EntityAttribute.EntityStringAttribute { AttributeId = attribute.Id; Value = "" }
    | AttributeType.Date ->
        EntityAttribute.EntityDateAttribute { AttributeId = attribute.Id; Value = System.DateTime.UtcNow.Date }
    | AttributeType.DateWithReminder ->
        EntityAttribute.EntityDateWithReminderAttribute { AttributeId = attribute.Id; Value = System.DateTime.UtcNow.Date; Reminder = ThreeDays }
    | AttributeType.OptionalInteger ->
        EntityAttribute.OptionalEntityIntAttribute { AttributeId = attribute.Id; Value = None }
    | AttributeType.OptionalDecimal ->
        EntityAttribute.OptionalEntityDecimalAttribute { AttributeId = attribute.Id; Value = None }
    | AttributeType.OptionalString ->
        EntityAttribute.OptionalEntityStringAttribute { AttributeId = attribute.Id; Value = None }
    | AttributeType.OptionalBoolean ->
        EntityAttribute.OptionalEntityBoolAttribute { AttributeId = attribute.Id; Value = None }
    | AttributeType.OptionalDate ->
        EntityAttribute.OptionalEntityDateAttribute { AttributeId = attribute.Id; Value = None }
    | AttributeType.OptionalDateWithReminder ->
        EntityAttribute.OptionalEntityDateWithReminderAttribute { AttributeId = attribute.Id; Value = None; Reminder = ThreeDays }

let initialEntity templateId : CreateEntityDto =
    { TemplateId = templateId
      Attributes = [ ]
      Name = ""
      Image = None
      Thumbnail = None
      CurrentLocation = EntityLocation.HomeLocation (LocationId System.Guid.Empty)
      Documents = [ ]
      ActiveState = EntityActiveState.Active
      NotAvailableRanges = []
      CreateMultipleEntities = None }

let updateInitialEntity (template : Template) : CreateEntityDto =
    { TemplateId = template.Id
      Attributes = template.Attributes |> List.map createAttribute
      Name = ""
      Image = None
      Thumbnail = None
      CurrentLocation = EntityLocation.HomeLocation (LocationId System.Guid.Empty)
      Documents = [ ]
      ActiveState = EntityActiveState.Active
      NotAvailableRanges = []
      CreateMultipleEntities = None }

let decimalAttributeAsStringHolder (entityAttributes : EntityAttribute list) =
    entityAttributes
    |> List.choose (fun attr ->
        match attr with
        | EntityAttribute.OptionalEntityStringAttribute _
        | EntityAttribute.OptionalEntityIntAttribute _
        | EntityAttribute.OptionalEntityBoolAttribute _
        | EntityAttribute.OptionalEntityDateAttribute _
        | EntityAttribute.OptionalEntityDateWithReminderAttribute _
        | EntityAttribute.EntityBoolAttribute _
        | EntityAttribute.EntityStringAttribute _
        | EntityAttribute.EntityIntAttribute _
        | EntityAttribute.EntityDateAttribute _
        | EntityAttribute.EntityDateWithReminderAttribute _ -> None
        | EntityAttribute.EntityDecimalAttribute a ->
            { DecimalAttributeAsStringHolder.AttributeId = a.AttributeId
              DecimalAttributeAsStringHolder.Value = a.Value |> Helper.floatValueToString |> Some } |> Some
        | EntityAttribute.OptionalEntityDecimalAttribute a ->
            let value =
                match a.Value with
                | Some v -> v |> Helper.floatValueToString |> Some
                | None -> None
            { DecimalAttributeAsStringHolder.AttributeId = a.AttributeId
              DecimalAttributeAsStringHolder.Value = value } |> Some)

let initialEntityNewForm templateId =
    { Entity = initialEntity templateId
      EntityImage = None
      DecimalAttributeAsStringHolder = [ ]
      FormValidation = None }

let initialEntityEditForm (entity : EntityDetailDto) =
    let decimalAttributeAsStringHolder = decimalAttributeAsStringHolder entity.Attributes
    { Entity = entity
      EntitySnapshot = entity
      EntityHistory = [ ]
      DecimalAttributeAsStringHolder = decimalAttributeAsStringHolder
      DecimalAttributeAsStringHolderSnapshot = decimalAttributeAsStringHolder
      FormValidation = None
      EntityImage = None
      EditField = EditField.Nothing }

let deleteModal (model : Model) dispatch =
    match model.DeleteRequested with
    | None ->
        deleteRequestMessageBox Fable.React.Helpers.nothing
    | Some id ->
        let displayText = sprintf "Wollen Sie das Gerät wirklich löschen?"
        deleteRequestMessageBox
            (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                            (fun _ -> dispatch (AbortDelete))
                                            displayText)

module Table =

    let asc = "asc"

    let locationBadge (entity : EntityForTemplateOverviewDto) =
        match entity.CurrentLocation with
        | EntityLocationForTemplateOverviewDto.RentOrderLocation -> Badges.badgePill "Baustelle" Badges.Primary
        | EntityLocationForTemplateOverviewDto.HomeLocation -> Badges.badgePill "Lager" Badges.Secondary
        | EntityLocationForTemplateOverviewDto.NotAvaiable _ -> Badges.badgePill "außer Betrieb" Badges.Danger
        | EntityLocationForTemplateOverviewDto.OnRoad _ -> Badges.badgePill "Unterwegs" Badges.Light

    let selectFilter (selectedValue : string) (entities : EntityForTemplateOverviewDto[]) =
        if selectedValue = "0" then
            entities
            |> Array.filter (fun e ->
                match e.CurrentLocation with
                | EntityLocationForTemplateOverviewDto.RentOrderLocation -> true
                | EntityLocationForTemplateOverviewDto.NotAvaiable
                | EntityLocationForTemplateOverviewDto.OnRoad _
                | EntityLocationForTemplateOverviewDto.HomeLocation _ -> false)
        elif selectedValue = "1" then
            entities
            |> Array.filter (fun e ->
                match e.CurrentLocation with
                | EntityLocationForTemplateOverviewDto.HomeLocation -> true
                | EntityLocationForTemplateOverviewDto.NotAvaiable
                | EntityLocationForTemplateOverviewDto.OnRoad _
                | EntityLocationForTemplateOverviewDto.RentOrderLocation _ -> false)
        elif selectedValue = "2" then
            entities
            |> Array.filter (fun e ->
                match e.CurrentLocation with
                | EntityLocationForTemplateOverviewDto.OnRoad -> true
                | EntityLocationForTemplateOverviewDto.NotAvaiable
                | EntityLocationForTemplateOverviewDto.HomeLocation _
                | EntityLocationForTemplateOverviewDto.RentOrderLocation _ -> false)
        elif selectedValue = "3" then
            entities
            |> Array.filter (fun e ->
                match e.CurrentLocation with
                | EntityLocationForTemplateOverviewDto.NotAvaiable -> true
                | EntityLocationForTemplateOverviewDto.OnRoad
                | EntityLocationForTemplateOverviewDto.HomeLocation _
                | EntityLocationForTemplateOverviewDto.RentOrderLocation _ -> false)
        else entities

    let customAttributeFilter (attributeId : AttributeId) (searchString : string option) (entities : EntityForTemplateOverviewDto[]) =
        let searchString =
            match searchString with
            | Some s -> s.ToLower()
            | None -> ""
        let attributes (attr : EntityAttribute list) =
            attr
            |> List.filter (fun a -> (Shared.Entity.Helper.entityAttributeId a) = attributeId)
            |> List.exists (fun a -> (Shared.Entity.Helper.entityAttributeValueAsString a).ToLower().Contains searchString)
        entities
        |> Array.toList
        |> List.filter (fun e -> attributes e.Attributes)
        |> List.toArray

    let private boolComparer order (vA : bool) (vB : bool) =
        let vA = if vA then 1 else 0
        let vB = if vB then 1 else 0
        if order = asc then vB - vA
        else vA - vB

    let private datetimeComparer order (vA : DateTime) (vB : DateTime) =
        if order = asc then DateTime.Compare (vA, vB)
        else DateTime.Compare (vB, vA)

    let private decimalComparer order (vA :float) (vB : float) =
        if order = asc then
          if vB > vA then 1 else 0
        else if vA > vB then 1 else 0

    let private intComparer order (vA :int) (vB : int) =
        if order = asc then vA - vB
        else vB - vA

    let private stringComparer order (vA : string) (vB : string) =
        if order = asc then String.Compare (vA, vB)
        else String.Compare (vB, vA)

    let customSortFunc (a : string) (b : string) (order : string) (value : string) (rowA : EntityForTemplateOverviewDto) (rowB : EntityForTemplateOverviewDto) (attr : Attribute) =
        let attrA = rowA.Attributes |> List.find (fun a -> (Shared.Entity.Helper.entityAttributeId a) = attr.Id)
        let attrB = rowB.Attributes |> List.find (fun a -> (Shared.Entity.Helper.entityAttributeId a) = attr.Id)
        match attrA, attrB with
        | EntityAttribute.EntityBoolAttribute vA, EntityAttribute.EntityBoolAttribute vB -> boolComparer order vA.Value vB.Value
        | EntityAttribute.EntityDateAttribute vA, EntityAttribute.EntityDateAttribute vB -> datetimeComparer order vA.Value vB.Value
        | EntityAttribute.EntityDateWithReminderAttribute vA, EntityAttribute.EntityDateWithReminderAttribute vB -> datetimeComparer order vA.Value vB.Value
        | EntityAttribute.EntityDecimalAttribute vA, EntityAttribute.EntityDecimalAttribute vB -> decimalComparer order vA.Value vB.Value
        | EntityAttribute.EntityIntAttribute vA, EntityAttribute.EntityIntAttribute vB -> intComparer order vA.Value vB.Value
        | EntityAttribute.EntityStringAttribute vA, EntityAttribute.EntityStringAttribute vB -> stringComparer order vA.Value vB.Value
        | EntityAttribute.OptionalEntityBoolAttribute vA, EntityAttribute.OptionalEntityBoolAttribute vB ->
            let noValue = false
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vB -> noValue, vB
                | None, None -> noValue, noValue
            boolComparer order valueA valueB
        | EntityAttribute.OptionalEntityDateAttribute vA, EntityAttribute.OptionalEntityDateAttribute vB ->
            let noValue = DateTime(1900, 1, 1)
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vA -> noValue, vA
                | None, None -> noValue, noValue
            datetimeComparer order valueA valueB
        | EntityAttribute.OptionalEntityDateWithReminderAttribute vA, EntityAttribute.OptionalEntityDateWithReminderAttribute vB ->
            let noValue = DateTime(1900, 1, 1)
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vA -> noValue, vA
                | None, None -> noValue, noValue
            datetimeComparer order valueA valueB
        | EntityAttribute.OptionalEntityDecimalAttribute vA, EntityAttribute.OptionalEntityDecimalAttribute vB ->
            let noValue = 0.
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vB -> noValue, vB
                | None, None -> noValue, noValue
            decimalComparer order valueA valueB
        | EntityAttribute.OptionalEntityIntAttribute vA, EntityAttribute.OptionalEntityIntAttribute vB ->
            let noValue = 0
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vB -> noValue, vB
                | None, None -> noValue, noValue
            intComparer order valueA valueB
        | EntityAttribute.OptionalEntityStringAttribute vA, EntityAttribute.OptionalEntityStringAttribute vB ->
            let noValue = ""
            let valueA, valueB =
                match vA.Value, vB.Value with
                | Some vA, Some vB -> vA, vB
                | Some vA, None -> vA, noValue
                | None, Some vB -> noValue, vB
                | None, None -> noValue, noValue
            stringComparer order valueA valueB
        | _, _ -> failwith "wrong state"

    let selectOptions =
        [ "Baustelle"
          "Lager"
          "Unterwegs"
          "außer Betrieb" ]

    let attributeIdString attributeId =
        let (AttributeId attributeId) = attributeId
        attributeId.ToString()

    let attributeValueAsString (attributeId : AttributeId) (entity : EntityForTemplateOverviewDto) =
        let attr = entity.Attributes |> List.find (fun a -> (Shared.Entity.Helper.entityAttributeId a) = attributeId)
        let value = Shared.Entity.Helper.entityAttributeValueAsString attr
        value

    let entityAttributeValueOptinalDateTime (attributeId : AttributeId) (entity : EntityForTemplateOverviewDto) =
        let attr = entity.Attributes |> List.find (fun a -> (Shared.Entity.Helper.entityAttributeId a) = attributeId)
        let value = Shared.Entity.Helper.entityAttributeValueOptinalDateTime attr
        value

    let attributeValue (attributeId : AttributeId) (entity : EntityForTemplateOverviewDto) =
        str (attributeValueAsString attributeId entity)

    let entityCurrentLocationAsString (entity : EntityForTemplateOverviewDto) =
        match entity.CurrentLocation with
        | EntityLocationForTemplateOverviewDto.RentOrderLocation -> "Baustelle"
        | EntityLocationForTemplateOverviewDto.HomeLocation -> "Lager"
        | OnRoad -> "unterwegs"
        | NotAvaiable -> "außer Betrieb"