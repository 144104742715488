module Support.View

open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open Support.Types
open SharedComponents.Alerts
open SharedComponents.Spinners
open Shared.Configuration

let private contactView (configuration : Configuration) =
    let companyName = configuration.Company.CompanyBaseInformation.Name
    div [ Id "support-content" ]
      [ mainHeader "Support"
        div [ Class "flex-grow-1 d-flex flex-column" ]
          [ div [ Class "d-flex justify-content-center" ]
              [ div [ Class "card w-50" ]
                  [ div [ Class "card-body" ]
                      [ h5 [ Class "card-title" ]
                          [ str "Kontakt" ]
                        p [ Class "card-text" ]
                          [ str "Bei Fragen oder Probleme kontaktiere uns per Telefon oder E-Mail." ]
                        div [ Class "d-flex justify-content-center"]
                          [ div [ ]
                              [ span [ Class "fas fa-phone m-2" ]
                                  [ ]
                                span [ ]
                                  [ str "07172 / 2090009" ]
                                br [ ]
                                span [ Class "fas fa-envelope m-2" ]
                                  [ ]
                                span [ ]
                                  [ a [ Class "mailto"
                                        Href (sprintf "mailto:support@softwarewerkstatt.io?subject=Support dispoprofi&body=Unternehmen: %s" companyName) ]
                                        [ str "support@softwarewerkstatt.io" ] ] ] ] ] ] ] ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "support-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ match model.Configuration with
          | Some configuration -> contactView configuration
          | None -> overlaySpinner RequestState.Active ]

