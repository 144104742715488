module MasterData.AllEntities.State

open Elmish
open MasterData.AllEntities.Types
open Client
open Shared.Entity
open SharedComponents.Spinners

let getAllEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntityAllEntitiesOverviewDto list> ("/api/entities/all/overview") EntitiesFetched FetchError

let init userData : Model * Cmd<Msg> =
    { Entities = [ ]
      CurrentRequestState = RequestState.Active }, getAllEntitiesCmd

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | FetchError e ->
        { model with CurrentRequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
    | EntitiesFetched response ->
        { model with Entities = response
                     CurrentRequestState = RequestState.NotActive }, Cmd.none