module Configuration.View

open Fable.React
open Fable.React.Props
open Configuration.Types
open SharedComponents.Headers

let private currentView (model : Model) expectedView =
  if model.CurrentView = expectedView then "selected" else ""

let private sideNav (model : Model) dispatch =
  div [ Class "configuration-sidenav"]
      [ mainHeaderWithClassName "Einstellungen" "p-2"
        ul [ Class "sidebar-elements" ]
          [ li [ Class (sprintf "sidebar-element %s"(currentView model Views.CompanyView))
                 OnClick(fun _ -> dispatch OpenCompanyView) ]
              [ a [ Data ("cy", "configuration-side-nav-company") ]
                  [ str "Unternehmen" ] ]
            li [ Class (sprintf "sidebar-element %s"(currentView model Views.LocationsView))
                 OnClick(fun _ -> dispatch OpenLocationsView) ]
              [ a [ Data ("cy", "configuration-side-nav-locations") ]
                  [ str "Standorte" ] ]
            li [ Class (sprintf "sidebar-element %s"(currentView model Views.WorkflowView))
                 OnClick(fun _ -> dispatch OpenWorkflowView) ]
              [ a [ Data ("cy", "configuration-side-nav-workflow") ]
                  [ str "Workflow" ] ]  ] ]


let private mainContent model dispatch =
    match model.DetailPageModel with
    | CompanyViewModel m ->
        Company.View.view m (CompanyViewMsg >> dispatch)
    | LocationsViewModel m ->
        Locations.View.view m (LocationsViewMsg >> dispatch)
    | WorkflowViewModel m ->
        Workflow.View.view m (WorkflowViewMsg >> dispatch)
    | NotSelected -> Fable.React.Helpers.nothing

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "configuration-container"
          Class "flex-grow-1 d-flex flex-row" ]
        [ sideNav model dispatch
          mainContent model dispatch ]