module Routes

open Elmish.UrlParser
open System

type DispositionQuery =
    { entity : string option
      location : int option
      end_date : int option }

[<RequireQualifiedAccess>]
type Page =
    | Dashboard
    | Login
    | FatalErrorView
    | NotLoggedInErrorView
    | MyProfil
    | Appointment
    | Available
    | Support
    | TemplateConfiguration
    | MyTaskOverview
    | MyTaskViewForm of int
    | Templates
    | TemplateGroupNewForm
    | TemplateGroupViewForm of Guid
    | TemplatesNewForm
    | TemplatesViewForm of Guid
    | AllEntities
    | Entity of Guid
    | DeactivateEntity of Guid
    | EntityNewForm of Guid
    | EntityCopyForm of Guid * Guid
    | EntityViewForm of Guid * Guid
    | MessageCenter
    | UserManagement
    | UserManagementNewForm
    | UserManagementViewForm of int
    | Configuration
    | OrderOverview
    | RentOrderOverview
    | RentOrderOverviewCompleted
    | RentOrderDetail of System.Guid
    | RentOrderNewForm
    | RentOrderEditForm of System.Guid
    | RentOrderDispositionDetail of Guid * Guid
    | RepairOrderOverview
    | ServiceOrderOverviewCompleted
    | RepairOrderDetail of System.Guid
    | RepairOrderNewForm
    | RepairOrderDispositionDetail of Guid * Guid
    | FreeOrderOverview
    | FreeOrderOverviewCompleted
    | FreeOrderDetail of System.Guid
    | FreeOrderNewForm
    | FreeOrderDispositionDetail of Guid * Guid
    | MaterialOverview
    | MaterialNewForm
    | MaterialEditForm of System.Guid
    | DispositionOverview
    | DispostionNewForm of DispositionQuery option
    | CarrierManagement
    | CarrierManagementNewForm
    | CarrierManagementViewForm of System.Guid
    | Reports
    | Control

    with static member DispostionNewFormParams entityId locationId endDate =
            match entityId, locationId, endDate with
            | None, None, None -> DispostionNewForm None
            | _, _, _ -> DispostionNewForm (Some { entity = entityId; location = locationId; end_date = endDate })

let toPath =
    function
    | Page.Dashboard -> "#"
    | Page.Login -> "#login"
    | Page.FatalErrorView -> "#error"
    | Page.NotLoggedInErrorView -> "#notloggedinerror"
    | Page.MyProfil -> "#myprofil"
    | Page.Appointment -> "#appointment"
    | Page.Available -> "#available"
    | Page.Support -> "#support"
    | Page.TemplateConfiguration -> "#templateconfiguration"
    | Page.TemplatesNewForm -> "#templateconfiguration/template/new"
    | Page.TemplatesViewForm i -> (sprintf "#templateconfiguration/template/%s" (i.ToString()))
    | Page.TemplateGroupNewForm -> "#templateconfiguration/templategroup/new"
    | Page.TemplateGroupViewForm i -> (sprintf "#templateconfiguration/templategroup/%s" (i.ToString()))
    | Page.MyTaskOverview -> "#mytask"
    | Page.MyTaskViewForm i -> (sprintf "#mytask/%i/view" i)
    | Page.Templates -> "#masterdata/templates"
    | Page.AllEntities -> "#masterdata/allentities"
    | Page.Entity templateId -> (sprintf "#masterdata/template/%s/entities" (templateId.ToString()))
    | Page.DeactivateEntity templateId -> (sprintf "#masterdata/template/%s/entities/deactivated" (templateId.ToString()))
    | Page.EntityNewForm templateId -> (sprintf "#masterdata/template/%s/entities/new" (templateId.ToString()))
    | Page.EntityCopyForm (templateId, entityId) -> (sprintf "#masterdata/template/%s/entities/copy/%s" (templateId.ToString()) (entityId.ToString()))
    | Page.EntityViewForm (templateId, entityId) -> (sprintf "#masterdata/template/%s/entities/%s" (templateId.ToString()) (entityId.ToString()))
    | Page.MessageCenter -> "#messages"
    | Page.UserManagement -> "#users"
    | Page.UserManagementNewForm -> "#users/new"
    | Page.UserManagementViewForm i -> (sprintf "#users/%i/view" i)
    | Page.CarrierManagement -> "#carriers"
    | Page.CarrierManagementNewForm -> "#carriers/new"
    | Page.CarrierManagementViewForm carrierId -> (sprintf "#carriers/%s/view" (carrierId.ToString()))
    | Page.Configuration -> "#configuration"
    | Page.OrderOverview -> "#order"
    | Page.RentOrderOverview -> "#order/rent"
    | Page.RentOrderOverviewCompleted -> "#order/rent/completed"
    | Page.RentOrderDetail s -> (sprintf "#order/rent/%s/view" (s.ToString()))
    | Page.RentOrderNewForm -> "#order/rent/new"
    | Page.RentOrderEditForm s -> (sprintf "#order/rent/%s" (s.ToString()))
    | Page.RentOrderDispositionDetail (rentOrderId, dispoId) -> (sprintf "#order/rent/%s/disposition/%s" (rentOrderId.ToString()) (dispoId.ToString()))
    | Page.RepairOrderOverview -> "#order/repair"
    | Page.ServiceOrderOverviewCompleted -> "#order/repair/completed"
    | Page.RepairOrderDetail s -> (sprintf "#order/repair/%s/view" (s.ToString()))
    | Page.RepairOrderNewForm -> "#order/repair/new"
    | Page.FreeOrderOverview -> "#order/free"
    | Page.FreeOrderOverviewCompleted -> "#order/free/completed"
    | Page.FreeOrderDetail s -> (sprintf "#order/free/%s/view" (s.ToString()))
    | Page.FreeOrderNewForm -> "#order/free/new"
    | Page.FreeOrderDispositionDetail (freeOrderId, dispoId) -> (sprintf "#order/freeorder/%s/disposition/%s" (freeOrderId.ToString()) (dispoId.ToString()))
    | Page.MaterialOverview -> "#material"
    | Page.MaterialNewForm -> "#material/new"
    | Page.MaterialEditForm id -> (sprintf "#material/%s" (id.ToString()))
    | Page.RepairOrderDispositionDetail (repairOrderId, dispoId) -> (sprintf "#order/repair/%s/disposition/%s" (repairOrderId.ToString()) (dispoId.ToString()))
    | Page.Reports -> "#reports"
    | Page.Control -> "#controls"
    | Page.DispositionOverview -> "#dispositions"
    | Page.DispostionNewForm query ->
        match query with
        | Some q ->
            let queryString =
                if q.entity.IsSome then (sprintf "entity=%s" (q.entity.Value.ToString())) else ""
                |> (fun s -> if q.location.IsSome then (sprintf "%s&location=%i" s q.location.Value) else s)
                |> (fun s -> if q.end_date.IsSome then (sprintf "%s&end-date=%i" s q.end_date.Value) else s)
            (sprintf "#dispositions/new?%s" queryString)
        | None -> "#dispositions/new"

let curry f x y = f (x,y)
let curry2 f x y z= f (x,y,z)
let parseGuid f id = f (System.Guid.Parse id)
let curryGuids f x y = f ((System.Guid.Parse x),(System.Guid.Parse y))


let tryOneOf parsers state =
    List.collect (fun parser ->
        try
            parser state
        with
        | e ->
            printfn "Guid parsing failed"
            [ ]
        ) parsers


let pageParser : Parser<Page -> Page, _> =
    tryOneOf
        [ map Page.Dashboard (top)
          map Page.Login (s "login")
          map Page.FatalErrorView (s "error")
          map Page.NotLoggedInErrorView (s "notloggedinerror")
          map Page.MyProfil (s "myprofil")
          map Page.Appointment (s "appointment")
          map Page.Available (s "available")
          map Page.Support (s "support")
          map Page.TemplateConfiguration (s "templateconfiguration")
          map Page.TemplatesNewForm (s "templateconfiguration" </> s "template" </> s "new")
          map (parseGuid Page.TemplatesViewForm) (s "templateconfiguration" </> s "template" </> str)
          map Page.TemplateGroupNewForm (s "templateconfiguration" </> s "templategroup" </> s "new")
          map (parseGuid Page.TemplateGroupViewForm) (s "templateconfiguration" </> s "templategroup"  </> str)
          map Page.Templates (s "masterdata" </> s "templates")
          map (parseGuid Page.Entity) (s "masterdata" </> s "template" </> str </> s "entities")
          map (parseGuid Page.DeactivateEntity) (s "masterdata" </> s "template" </> str </> s "entities" </> s "deactivated")
          map (parseGuid Page.EntityNewForm) (s "masterdata" </> s "template" </> str </> s "entities" </> s "new")
          map (curryGuids Page.EntityCopyForm) (s "masterdata" </> s "template" </> str </> s "entities" </> s "copy" </> str)
          map (curryGuids Page.EntityViewForm) (s "masterdata" </> s "template" </> str </> s "entities" </> str)
          map Page.AllEntities (s "masterdata" </> s "allentities")
          map Page.MessageCenter (s "messages")
          map Page.UserManagement (s "users")
          map Page.UserManagementNewForm (s "users" </> s "new")
          map Page.UserManagementViewForm (s "users" </> i32 </> s "view")
          map Page.CarrierManagement (s "carriers")
          map Page.CarrierManagementNewForm (s "carriers" </> s "new")
          map (parseGuid Page.CarrierManagementViewForm) (s "carriers" </> str </> s "view")
          map Page.Configuration (s "configuration")
          map Page.OrderOverview (s "order")
          map Page.MyTaskOverview (s "mytask")
          map Page.MyTaskViewForm (s "mytask" </> i32 </> s "view")
          map Page.RentOrderOverview (s "order" </> s "rent")
          map Page.RentOrderOverviewCompleted (s "order" </> s "rent" </> s "completed")
          map (parseGuid Page.RentOrderDetail) (s "order" </> s "rent" </> str </> s "view")
          map Page.RentOrderNewForm (s "order" </> s "rent" </> s "new")
          map (parseGuid Page.RentOrderEditForm) (s "order" </> s "rent" </> str)
          map (curryGuids Page.RentOrderDispositionDetail) (s "order" </> s "rent" </> str </> s "disposition" </> str)
          map Page.RepairOrderOverview (s "order" </> s "repair")
          map Page.ServiceOrderOverviewCompleted (s "order" </> s "repair" </> s "completed")
          map (parseGuid Page.RepairOrderDetail) (s "order" </> s "repair" </> str </> s "view")
          map Page.RepairOrderNewForm (s "order" </> s "repair" </> s "new")
          map (curryGuids Page.RepairOrderDispositionDetail) (s "order" </> s "repair" </> str </> s "disposition" </> str)
          map Page.FreeOrderOverview (s "order" </> s "free")
          map Page.FreeOrderOverviewCompleted (s "order" </> s "free" </> s "completed")
          map (parseGuid Page.FreeOrderDetail) (s "order" </> s "free" </> str </> s "view")
          map Page.FreeOrderNewForm (s "order" </> s "free" </> s "new")
          map (curryGuids Page.FreeOrderDispositionDetail) (s "order" </> s "freeorder" </> str </> s "disposition" </> str)
          map Page.MaterialOverview (s "material")
          map Page.MaterialNewForm (s "material" </> s "new")
          map (parseGuid Page.MaterialEditForm) (s "material" </> str)
          map Page.DispositionOverview (s "dispositions")
          map Page.DispostionNewFormParams (s "dispositions" </> s "new" <?> stringParam "entity" <?> intParam "location" <?> intParam "end-date")
          map Page.Reports (s "reports")
          map Page.Control (s "controls")
          ]

let urlParser location = parseHash pageParser location

let isPageVisibleForRole (page : Page) (rolesToCheck : Shared.RoleNames list) =
    let containsRoles neededRoles =
        rolesToCheck |> List.map (fun userRole -> neededRoles |> List.contains userRole) |> List.contains true

    match page with
    | Page.MyTaskViewForm _
    | Page.MyTaskOverview -> (containsRoles [Shared.RoleNames.Driver; Shared.RoleNames.Technician; Shared.RoleNames.Mechanic; Shared.RoleNames.Picker])
    | Page.MessageCenter _
    | Page.MyProfil _
    | Page.Support _
    | Page.Dashboard -> not rolesToCheck.IsEmpty
    | Page.FatalErrorView -> true
    | Page.NotLoggedInErrorView -> true
    | Page.Login -> true
    | Page.TemplateConfiguration _
    | Page.TemplatesNewForm _
    | Page.TemplateGroupNewForm _
    | Page.TemplatesViewForm _
    | Page.TemplateGroupViewForm _
    | Page.UserManagement _
    | Page.UserManagementNewForm _
    | Page.UserManagementViewForm _
    | Page.CarrierManagement _
    | Page.CarrierManagementNewForm _
    | Page.CarrierManagementViewForm _
    | Page.Configuration -> containsRoles [Shared.RoleNames.Administrator]
    | Page.DispositionOverview _
    | Page.DispostionNewForm _ -> containsRoles [ Shared.RoleNames.Administrator
                                                  Shared.RoleNames.Dispatcher
                                                  Shared.RoleNames.Technician
                                                  Shared.RoleNames.Mechanic
                                                  Shared.RoleNames.Picker
                                                  Shared.RoleNames.Driver ]
    | Page.Templates _
    | Page.AllEntities _
    | Page.EntityViewForm _
    | Page.DeactivateEntity _
    | Page.Entity _ -> containsRoles [ Shared.RoleNames.Administrator
                                       Shared.RoleNames.Dispatcher
                                       Shared.RoleNames.Technician ]
    | Page.OrderOverview _
    | Page.RentOrderOverview _
    | Page.RentOrderOverviewCompleted _
    | Page.RentOrderEditForm _
    | Page.RentOrderDetail _
    | Page.RentOrderDispositionDetail _
    | Page.RepairOrderOverview _
    | Page.ServiceOrderOverviewCompleted _
    | Page.RepairOrderDetail _
    | Page.RepairOrderDispositionDetail _
    | Page.RentOrderNewForm _
    | Page.RepairOrderNewForm _
    | Page.FreeOrderOverview _
    | Page.FreeOrderOverviewCompleted _
    | Page.FreeOrderNewForm _
    | Page.FreeOrderDetail _
    | Page.FreeOrderDispositionDetail _
    | Page.EntityNewForm _
    | Page.EntityCopyForm _
    | Page.MaterialOverview
    | Page.MaterialNewForm
    | Page.MaterialEditForm _
    | Page.Reports 
    | Page.Control 
    | Page.Available _
    | Page.Appointment _ -> containsRoles [ Shared.RoleNames.Administrator
                                            Shared.RoleNames.Dispatcher ]