module Order.RentOrder.Positions

open Client.SharedComponents
open Elmish
open Elmish.React.Common
open Feliz
open Feliz.UseDeferred
open Feliz.UseElmish
open Order.RentOrder.Types
open Order.RentOrder.PositionTypes
open Order.RentOrder.Helper
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.ReactSelect
open SharedComponents.Types
open System
open SharedComponents.Alerts
open Validation
open Shared.Entity
open Shared.RentOrder
open Shared.TaskTypes
open SharedComponents.Badges
open Shared.TaskTypes.Helper
open Order.RentOrder.FormValidation
open SharedComponents.Breadcrumb
open SharedComponents.Buttons
open Order.RentOrder.Helper
open Order.RentOrder.Helper.Table
open Routes
open Shared.Material
open Client
open Shared.RentOrder.Helper
open SharedComponents.Toast
open Shared.Configuration

type Props =
    { RentOrder : RentOrder
      Configuration : Configuration
      InitialComponentState : ComponentState
      ComponentState : ComponentState
      UserData : UserData
      FormValidation : FormValidation option
      SaveRequested : bool
      SaveCallback : bool -> RentOrderPosition list -> unit
      SuccessCallback : unit -> unit }

let toPositionRow = function
    | RentOrderEntityPosition pos -> ShowEntityPosition (pos, false)
    | RentOrderMaterialPosition pos -> ShowMaterialPosition (pos, false)

let (|PositionRow|_|) (posNo, subPosNo) (positions : PositionRow list) =
    positions
    |> List.tryFind (fun pos -> pos |> Row.apply Helper.Position.posNo = posNo && pos |> Row.apply Helper.Position.subPosNo = subPosNo)
    |> (function
        | Some pos -> PositionRow pos |> Some
        | None -> None)

let updateRentOrderCmd rentOrderId (updateElement : RentOrderUpdateDto) =
    let (RentOrderId rentOrderId) = rentOrderId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<SaveRentOrderResult>>
                             ((sprintf "/api/rentorders/%s" (rentOrderId.ToString())), body)
                             RentOrderUpdated PositionTypes.FetchError

let init (props : Props) =
    let positions = props.RentOrder.Positions |> List.map toPositionRow
    { Entities = [ ]
      Templates = [ ]
      Materials = [ ]
      NotActiveEntities = [ ]
      Configuration = props.Configuration
      RentOrder = props.RentOrder
      Positions = positions
      InitialComponentState = props.ComponentState
      ComponentState = props.ComponentState

      RefreshRentOrderFromProps = false
      UserData = props.UserData
      DataFetched = false
      EntitiesNotAvailableDateTimes = []
      WithoutDelivery = false
      FormValidation = None
      DeliveryDateForAllPositions = DateTime.UtcNow.Date
      ReturnDateForAllPositions = DateTime.UtcNow.Date
      NotValidRentOrderPositions = [ ]
      SaveState = SaveState.Nothing
      SuccessCallback = props.SuccessCallback
      CurrentFilterEntityName = ""
      CurrentFilterState = None },
    Cmd.none

let (|Float|_|) (str : string) =
    let str = str.Replace(",", ".")
    match Double.TryParse(str) with
    | (true, value) -> Some value
    | _ -> None

let getNotAvailableDateTimesCmd (entityId : System.Guid) (rentOrderId : RentOrderId) =
    let id = entityId.ToString()
    let startDate = DateTime.UtcNow.ToString("dd.MM.yyyy") //.ToShortDateString()
    let rentOrderId = rentOrderId |> Shared.Helper.unwrapRentOrderId
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/entities/%s/%s/%s" id startDate rentOrderId) EntityAvailableDateTimesFetched FetchError

let update msg (state : State) =
    match msg with
    | SetEdit ->
        let positions =
            state.Positions
            |> List.map (fun position ->
                match position with
                | ShowEntityPosition (pos, isChecked) ->
                    if isChecked then
                        let templateId =
                            state.Entities |> List.tryFind(fun e -> e.Id = pos.EntityId) |> Option.map (fun e -> e.TemplateId)
                        (pos, templateId) |> PositionRow.EditEntityPosition
                    else position
                | ShowMaterialPosition (pos, isChecked) ->
                    if isChecked
                    then (pos, { Raw = pos.Quantity |> Helper.floatValueToString; Parsed = Some pos.Quantity}) |> PositionRow.EditMaterialPosition
                    else position
                | _ -> position)
        { state with ComponentState = ComponentState.EditPositions
                     Positions = positions
                     CurrentFilterEntityName = "" }, Cmd.none
    | SetAddPositions ->
        { state with ComponentState = ComponentState.NewPosition
                     CurrentFilterEntityName = "" }, Cmd.none
    | Abort ->
        { state with ComponentState = ComponentState.Show
                     Positions = state.RentOrder.Positions |> List.map toPositionRow }, Cmd.none
    | SetValidations validations ->
        { state with FormValidation = validations }, Cmd.none
    | RefreshRentOrderFromProps rentOrder ->
        let positions = rentOrder.Positions |> List.map toPositionRow
        { state with RefreshRentOrderFromProps = false
                     Positions = positions
                     RentOrder = rentOrder }, Cmd.none
    | SavePositions rentOrderId ->
        match state.SaveState with
        | SaveState.Nothing ->
            let validation = validateEditPositionsForm state.Positions
            if validation.FormValid = ValidationState.Valid then
                let cmd =
                    state.Positions
                    |> List.map Row.toRentOrderPosition
                    |> (fun positions ->
                        RentOrderUpdateDto.UpdatePositions (state.WithoutDelivery, positions))
                    |> updateRentOrderCmd rentOrderId
                { state with SaveState = SaveState.Saving }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                { state with FormValidation = Some validation }, warningToast
        | SaveState.Saving -> state, Cmd.none
    | AddEntityPosition ->
        let newRentOrderPosition =
            if state.Positions.IsEmpty then
                [ (emptyRentOrderEntityPosition (PositionNumber 1), None) |> PositionRow.NewEntityPosition ]
            else
                let lastPosNumber =
                    state.Positions
                    |> List.map (fun positionRow ->
                        positionRow |> Row.apply Helper.Position.posNo |> Helper.unwrapPositionNumber)
                    |> List.max
                (((emptyRentOrderEntityPosition (PositionNumber (lastPosNumber + 1)), None)
                |> PositionRow.NewEntityPosition) :: state.Positions)
        let newRentOrderPosition =
            newRentOrderPosition
            |> List.sortBy (Row.apply Helper.Position.posNo)
        { state with Positions = newRentOrderPosition }, Cmd.none
    | AddMaterialPosition ->
        let newRentOrderPosition =
            if state.Positions.IsEmpty then
                [ (emptyRentOrderMaterialPosition (PositionNumber 1), { Raw = ""; Parsed = None }) |> PositionRow.NewMaterialPosition ]
            else
                let lastPosNumber =
                    state.Positions
                    |> List.map (fun positionRow ->
                        positionRow |> Row.apply Helper.Position.posNo |> Helper.unwrapPositionNumber)
                    |> List.max
                (((emptyRentOrderMaterialPosition (PositionNumber (lastPosNumber + 1)), { Raw = ""; Parsed = None })
                |> PositionRow.NewMaterialPosition) :: state.Positions)
        let newRentOrderPosition =
            newRentOrderPosition
            |> List.sortBy (Row.apply Helper.Position.posNo)
        { state with Positions = newRentOrderPosition }, Cmd.none
    // Form
    | SetTemplateToPosition (templateIdOption, posNo) ->
        let newTemplateId = templateIdOption |> Option.map (fun t -> t.value)
        let updatedPositions =
            let updatePosition = function
                | NewEntityPosition (pos, templateId) ->
                    ({ pos with EntityId = System.Guid.Empty |> Shared.EntityId}, newTemplateId)
                    |> PositionRow.NewEntityPosition
                | EditEntityPosition (pos, templateId) ->
                    ({ pos with EntityId = System.Guid.Empty |> Shared.EntityId}, newTemplateId)
                    |> PositionRow.EditEntityPosition
                | _ -> failwith "invalid case - set template can only called on new and edit entity"
            state.Positions
            |> List.map (fun position ->
                if position |> Row.apply Helper.Position.posNo = posNo
                then position |> updatePosition
                else position)
            |> List.filter (fun position ->
                not (position |> Row.apply Helper.Position.posNo = posNo &&
                        position |> Row.apply Helper.Position.subPosNo |> Option.isSome))

        { state with Positions = updatedPositions }, Cmd.none
    | SetMaterialToPosition (materialOption, posNo) ->
        let materialId = materialOption |> Option.get |> (fun m -> m.value.Id)
        let updatePosition = function
            | RentOrderPosition.RentOrderMaterialPosition p ->
                { p with MaterialId = materialId } |> RentOrderPosition.RentOrderMaterialPosition
            | RentOrderPosition.RentOrderEntityPosition p -> p |> RentOrderPosition.RentOrderEntityPosition
        let updatedPositions =
            state.Positions
            |> List.map (fun position ->
                if position |> Row.apply Helper.Position.posNo = posNo && position |> Row.isMaterialPosition
                then position |> Row.map updatePosition
                else position)
        { state with Positions = updatedPositions }, Cmd.none

    | SetEntityToPosition (entityIdAndTemplateIdOption, posNo, subPosNo) ->
        let updatePosition entityId = function
            | RentOrderEntityPosition p ->
                { p with EntityId = entityId } |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition p -> p |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions, cmd =
            match entityIdAndTemplateIdOption with
            | Some entityIdAndTemplateId ->
                let entityId, templateId = entityIdAndTemplateId.value
                let updatedPositions =
                    state.Positions
                    |> List.map (fun rPos ->
                        if rPos |> Row.apply Helper.Position.posNo = posNo && rPos |> Row.apply Helper.Position.subPosNo = subPosNo
                        then rPos |> Row.map (updatePosition entityId) |> Row.setTemplateId (Some templateId)
                        else rPos)
                let template = state.Templates |> List.find (fun t -> t.Id = templateId)
                let updatedPositions =
                    match template.ChildTemplates with
                    | Some children ->
                        // Prüfen ob schon Subtemplates da sind
                        if state.Positions |> List.exists (fun pos -> pos |> Row.apply Helper.Position.posNo = posNo && pos |> Row.apply Helper.Position.subPosNo |> Option.isSome)
                        // Subtemplates sind bereits vorhanden
                        then updatedPositions
                        else
                            // Subtemplates müssen hinzugefügt werden
                            let subPositions =
                                children.Templates
                                |> List.mapFold (fun i t ->
                                    [ for subPosId in [ i .. i + t.Max - 1 ] do
                                        let position =
                                            ({ emptyRentOrderEntityPosition posNo with SubPosNo = subPosId |> PositionNumber |> Some }, Some t.TemplateId)
                                            |> PositionRow.NewEntityPosition
                                        t.TemplateId, position ],
                                    i + t.Max) 1
                                |> (fun (pos, _) -> pos)
                                |> List.collect id
                            let positions =
                                subPositions
                                |> List.choose (fun (_, pos) ->
                                    if updatedPositions |> List.contains pos then
                                        None
                                    else Some pos)
                                |> List.append updatedPositions
                            positions
                    | None -> updatedPositions
                    |> List.sortBy (fun pos ->
                        sprintf "%i.%i"
                            (pos |> Row.toRentOrderPosition |> Helper.Position.posNo |> Helper.unwrapPositionNumber)
                            (pos |> Row.toRentOrderPosition |> Helper.Position.subPosNo |> Option.map Helper.unwrapPositionNumber |> Option.defaultValue 0)
                        |> float)
                let cmd =
                    let (Shared.EntityId entityId) = entityId
                    getNotAvailableDateTimesCmd entityId state.RentOrder.Id
                updatedPositions, cmd
            | None ->
                let updatedPositions =
                    match subPosNo with
                    | Some _ ->
                        state.Positions
                        // TODO warum ist hier choose? würde nicht auch map gehen?
                        |> List.choose (fun rPos ->
                            if rPos |> Row.apply Helper.Position.posNo = posNo && rPos |> Row.apply Helper.Position.subPosNo = subPosNo
                            then rPos |> Row.map (updatePosition (System.Guid.Empty |> Shared.EntityId)) |> Row.setTemplateId None |> Some
                            else Some rPos)
                    | None ->
                        state.Positions
                        |> List.choose (fun rPos ->
                            if rPos |> Row.apply Helper.Position.posNo = posNo then
                                if rPos |> Row.apply Helper.Position.subPosNo |> Option.isSome
                                then None
                                else rPos |> Row.map (updatePosition (System.Guid.Empty |> Shared.EntityId)) |> Row.setTemplateId None |> Some
                            else Some rPos)
                updatedPositions, Cmd.none
        { state with Positions = updatedPositions }, cmd
    | RemoveRentOrderPosition (posNo, subPosNo) ->
        let updatedPositions =
            match subPosNo with
            | Some subPosValue ->
                let newRentOrderPositions =
                    state.Positions
                    |> List.filter (fun pos -> not ((pos |> Row.apply Helper.Position.posNo) = posNo && (pos |> Row.apply Helper.Position.subPosNo) = subPosNo))
                    |> List.map (fun pos ->
                        match (pos |> Row.apply Helper.Position.subPosNo) with
                        | None -> pos
                        | Some subPos ->
                            if subPos > subPosValue then
                                let (PositionNumber posNo) = subPos
                                pos |> Row.map (function
                                    | RentOrderEntityPosition pos ->
                                        { pos with SubPosNo = (posNo - 1) |> PositionNumber |> Some }
                                        |> RentOrderPosition.RentOrderEntityPosition
                                    | RentOrderMaterialPosition pos ->
                                        { pos with SubPosNo = (posNo - 1) |> PositionNumber |> Some }
                                        |> RentOrderPosition.RentOrderMaterialPosition)
                            else pos )
                newRentOrderPositions
            | None ->
                let newRentOrderPositions =
                    state.Positions
                    |> List.filter (fun pos -> (pos |> Row.apply Helper.Position.posNo) <> posNo)
                    |> List.map (fun pos ->
                        if (pos |> Row.apply Helper.Position.posNo) > posNo then
                            let (PositionNumber posNo) = pos |> Row.apply Helper.Position.posNo
                            pos |> Row.map (function
                                | RentOrderEntityPosition p ->
                                    { p with PosNo = PositionNumber (posNo - 1) }
                                    |> RentOrderPosition.RentOrderEntityPosition
                                | RentOrderMaterialPosition p ->
                                    { p with PosNo = PositionNumber (posNo - 1) }
                                    |> RentOrderPosition.RentOrderMaterialPosition)
                        else pos )
                newRentOrderPositions
        { state with Positions = updatedPositions }, Cmd.none
    | SelectRentOrderPosition (posNo, subPosNo) ->
        let updatePosition = function
            | ShowEntityPosition (pos, isChecked) ->
                (pos, not isChecked) |> PositionRow.ShowEntityPosition
            | ShowMaterialPosition (pos, isChecked) ->
                (pos, not isChecked) |> PositionRow.ShowMaterialPosition
            | _ -> failwith "invalid case - Select Position only valid in show state"
        let updatedPositions =
            state.Positions
            |> List.map (fun rPos ->
                if (Row.apply Helper.Position.posNo rPos) = posNo && (Row.apply Helper.Position.subPosNo rPos) = subPosNo then
                    updatePosition rPos
                else rPos)
        { state with Positions = updatedPositions }, Cmd.none
    | SetPositionStartDate (date, posNo) ->
        let updateStartDate rPos =
            let returnDate =
                if date > (rPos |> Helper.Position.plannedReturnDate) then
                    date
                else (rPos |> Helper.Position.plannedReturnDate)
            match rPos with
            | RentOrderEntityPosition r ->
                match r.DeliveryDate with
                | Some deliveryDate ->
                    { r with DeliveryDate = Some date }
                | None ->
                    { r with PlannedDeliveryDate = date
                             PlannedReturnDate = returnDate }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                match r.DeliveryDate with
                | Some deliveryDate ->
                    { r with DeliveryDate = Some date }
                | None ->
                    { r with PlannedDeliveryDate = date
                             PlannedReturnDate = returnDate }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions, cmd =
            let newRentOrderPositions =
                state.Positions
                |> List.map (fun rPos ->
                    if (Row.apply Helper.Position.posNo rPos) = posNo then
                        Row.map updateStartDate rPos
                    else rPos)
            let cmd =
                match state.RentOrder.Positions with
                | EntityPosition (posNo, None) p when p.EntityId <> Shared.EntityId (System.Guid.Empty) ->
                    let (Shared.EntityId entityId) = p.EntityId
                    Cmd.batch [ getNotAvailableDateTimesCmd entityId state.RentOrder.Id; ]
                | _ -> Cmd.none
            (newRentOrderPositions, cmd)
        { state with Positions = updatedPositions }, cmd
    | SetPositionSelfserviceDelivery (flag, posNo) ->
        let updateSelfServiceDeliveryFlag rPos =
            match rPos with
            | RentOrderEntityPosition r ->
                { r with SelfserviceDelivery = flag }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                { r with SelfserviceDelivery = flag }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions, cmd =
            let newRentOrderPositions =
                state.Positions
                |> List.map (fun rPos ->
                    if (Row.apply Helper.Position.posNo rPos) = posNo then
                        Row.map updateSelfServiceDeliveryFlag rPos
                    else rPos)
            let cmd =
                match state.RentOrder.Positions with
                | EntityPosition (posNo, None) p when p.EntityId <> Shared.EntityId (System.Guid.Empty) ->
                    let (Shared.EntityId entityId) = p.EntityId
                    Cmd.batch [ getNotAvailableDateTimesCmd entityId state.RentOrder.Id; ]
                | _ -> Cmd.none
            (newRentOrderPositions, cmd)
        { state with Positions = updatedPositions }, cmd
    | SetPositionSelfserviceReturn (flag, posNo) ->
        let updateSelfServiceReturnFlag rPos =
            match rPos with
            | RentOrderEntityPosition r ->
                { r with SelfserviceReturn = flag }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                { r with SelfserviceReturn = flag }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions, cmd =
            let newRentOrderPositions =
                state.Positions
                |> List.map (fun rPos ->
                    if (Row.apply Helper.Position.posNo rPos) = posNo then
                        Row.map updateSelfServiceReturnFlag rPos
                    else rPos)
            let cmd =
                match state.RentOrder.Positions with
                | EntityPosition (posNo, None) p when p.EntityId <> Shared.EntityId (System.Guid.Empty) ->
                    let (Shared.EntityId entityId) = p.EntityId
                    Cmd.batch [ getNotAvailableDateTimesCmd entityId state.RentOrder.Id; ]
                | _ -> Cmd.none
            (newRentOrderPositions, cmd)
        { state with Positions = updatedPositions }, cmd
    | SetPositionEndDate (date, posNo) ->
        let updateEndDate = function
            | RentOrderEntityPosition r ->
                match r.ReturnDate with
                | Some d ->{ r with ReturnDate = date |> Some }
                | None -> { r with PlannedReturnDate = date }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                match r.ReturnDate with
                | Some d ->{ r with ReturnDate = date |> Some }
                | None -> { r with PlannedReturnDate = date }
                // { r with PlannedReturnDate = date }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions =
            state.Positions
            |> List.map (fun rPos ->
                if (Row.apply Helper.Position.posNo rPos) = posNo then
                    Row.map updateEndDate rPos
                else rPos)
        { state with Positions = updatedPositions }, Cmd.none
    | SetPositionComment (comment, posNo, subPosNo) ->
        let updateComment = function
            | RentOrderEntityPosition r ->
                { r with Comment = comment }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                { r with Comment = comment }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions =
            state.Positions
            |> List.map (fun rPos ->
                if (Row.apply Helper.Position.posNo rPos) = posNo && (Row.apply Helper.Position.subPosNo rPos) = subPosNo then
                    Row.map updateComment rPos
                else rPos)
        { state with Positions = updatedPositions }, Cmd.none
    | SetDeliveryDateToAllPositions startDate ->
        let returnDate date plannedReturnDate =
            if date > plannedReturnDate then
                date
            else plannedReturnDate
        let updatePosition = function
            | RentOrderEntityPosition r ->
                { r with PlannedDeliveryDate = startDate
                         PlannedReturnDate = returnDate startDate r.PlannedReturnDate }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                { r with PlannedDeliveryDate = startDate
                         PlannedReturnDate = returnDate startDate r.PlannedReturnDate }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions =
            state.Positions
            |> List.map (fun p ->
                if p |> Row.apply Helper.Position.state = RentOrderPositionState.Planned then
                    p |> Row.map updatePosition
                else p)
        { state with Positions = updatedPositions
                     DeliveryDateForAllPositions = startDate
                     ReturnDateForAllPositions = returnDate startDate state.ReturnDateForAllPositions }, Cmd.none
    | SetReturnDateToAllPositions endDate ->
        let deliveryDate date plannedDeliveryDate =
            if date < plannedDeliveryDate then
                date
            else plannedDeliveryDate
        let updatePosition = function
            | RentOrderEntityPosition r ->
                { r with PlannedReturnDate = endDate
                         PlannedDeliveryDate = deliveryDate endDate r.PlannedDeliveryDate }
                |> RentOrderPosition.RentOrderEntityPosition
            | RentOrderMaterialPosition r ->
                { r with PlannedReturnDate = endDate
                         PlannedDeliveryDate = deliveryDate endDate r.PlannedDeliveryDate }
                |> RentOrderPosition.RentOrderMaterialPosition
        let updatedPositions =
            state.Positions
            |> List.map (fun p ->
                if p |> Row.apply Helper.Position.state = RentOrderPositionState.AssignedToReturn ||
                   p |> Row.apply Helper.Position.state = RentOrderPositionState.Released ||
                   p |> Row.apply Helper.Position.state = RentOrderPositionState.Returned
                then p
                else p |> Row.map updatePosition)

        { state with Positions = updatedPositions
                     ReturnDateForAllPositions = endDate
                     DeliveryDateForAllPositions = deliveryDate endDate state.DeliveryDateForAllPositions }, Cmd.none
    | SetUnitQuantity (quantity, positionNumber) ->
        let parsedValue =
            match quantity with
            | Float f -> Some f
            | _ -> None
        let setParsedValue (pos : RentOrderMaterialPosition) =
            match parsedValue with
            | Some v -> { pos with Quantity = v }
            | None -> pos
        let updatePosition = function
            | NewMaterialPosition (pos, value) ->
                (pos |> setParsedValue, { Raw = quantity; Parsed = parsedValue })
                |> PositionRow.NewMaterialPosition
            | EditMaterialPosition (pos, value) ->
                (pos |> setParsedValue, { Raw = quantity; Parsed = parsedValue })
                |> PositionRow.EditMaterialPosition
            | _ -> failwith "invalid case - set unit quantity can only called on new and edit material"
        let updatedPositions =
            state.Positions
            |> List.map (fun pos ->
                if pos |> Row.apply Helper.Position.posNo = positionNumber
                then pos |> updatePosition
                else pos)
        { state with Positions = updatedPositions }, Cmd.none
    | SetWithoutDelivery ->
        { state with WithoutDelivery = not state.WithoutDelivery }, Cmd.none

    | SaveRentOrder ->
        state, Cmd.none
    | FetchRentOrder ->
        state.SuccessCallback()
        { state with RefreshRentOrderFromProps = true }, Cmd.none
    | DataFetched dto ->
        { state with Entities = dto.ActiveEntities
                     Templates = dto.Templates
                     Materials = dto.Materials
                     NotActiveEntities = dto.DeactivatedEntities
                     DataFetched = true }, Cmd.none
    | EntityAvailableDateTimesFetched response ->
        let filteredEntitiesNotAvailableDateTimes =
            state.EntitiesNotAvailableDateTimes
                |> List.filter (fun e -> e.EntityId <> response.EntityId)
        { state with EntitiesNotAvailableDateTimes = response :: filteredEntitiesNotAvailableDateTimes }, Cmd.none
    | RentOrderUpdated response ->
        match response.Result with
        | Saved (RentOrderId rentOrderId) ->
            state.SuccessCallback()
            { state with ComponentState = ComponentState.Show
                         RefreshRentOrderFromProps = true
                         SaveState = SaveState.Nothing },
            toast (ToastType.Success "Geräte erfolgreich aktualisiert.")
        | InvalidPositions invalidPositions ->
            let validation =
                { Validations = createPosNotAvailableValidation invalidPositions
                  FormValid = ValidationState.NotValid "Markierte Positionen sind zu angegebenen Datum nicht verfügbar." }
            { state with NotValidRentOrderPositions = invalidPositions
                         FormValidation = Some validation
                         SaveState = SaveState.Nothing },
            toast (ToastType.Warning "Markierte Positionen sind zu angegebenen Datum nicht verfügbar.")
        | InvalidPositionsSelfserviceConvert invalidPositions ->
            let validation =
                { Validations = createPosNotAvailableValidation invalidPositions
                  FormValid = ValidationState.NotValid "Markierte Positionen sind zu angegebenen Datum nicht verfügbar." }
            { state with NotValidRentOrderPositions = invalidPositions
                         FormValidation = Some validation
                         SaveState = SaveState.Nothing },
            toast (ToastType.Warning "Markierte Positionen konnten nicht in eine Selbstabholung/Selbstrückgabe Position umgewandelt werden.")
    | FilterEntityName name ->
        { state with CurrentFilterEntityName = name }, Cmd.none
    | FilterByState filteredState ->
        match filteredState with
        | Some filteredState -> { state with CurrentFilterState = filteredState.value |> Some }, Cmd.none
        | None -> { state with CurrentFilterState = None }, Cmd.none
    | FetchError e ->
        state, ErrorHandling.handleFetchError e

let private releasePositionMaxDeliveryDate (rentOrder : RentOrder) (affectedPositionIds : RentOrderPositionId list) =
    if affectedPositionIds.IsEmpty then
        System.DateTime.UtcNow
    else
        rentOrder.Positions
        |> List.filter(fun p -> affectedPositionIds |> List.contains (p |> Helper.Position.id))
        |> List.map(fun p ->
            match Shared.RentOrder.Helper.Position.deliveryDate p with
            | Some d -> d
            | None -> Shared.RentOrder.Helper.Position.plannedDeliveryDate p)
        |> List.max

let releaseButton (state : State) dispatch =
    let selectedPositions =
        state.Positions
        |> List.choose (fun pos ->
            match pos with
            | PositionRow.ShowEntityPosition (pos, isSelected) ->
                if isSelected
                then Some (RentOrderPosition.RentOrderEntityPosition pos)
                else None
            | PositionRow.ShowMaterialPosition (pos, isSelected) ->
                if isSelected
                then Some (RentOrderPosition.RentOrderMaterialPosition pos)
                else None
            | _ -> None)
    let selectedPositionsIds = selectedPositions |> List.map Helper.Position.id
    let isDisabled = selectedPositions |> List.isEmpty
    let onlyWithTask =
        selectedPositions
        |> List.exists(fun p ->
            match p with
            | RentOrderPosition.RentOrderEntityPosition ep -> ep.State = RentOrderPositionState.AssignedToDeliver || ep.State = RentOrderPositionState.Planned
            | RentOrderPosition.RentOrderMaterialPosition ep -> ep.State = RentOrderPositionState.AssignedToDeliver || ep.State = RentOrderPositionState.Planned)

    ReleaseDialog.releaseDialog state.Configuration state.RentOrder.Id selectedPositionsIds (releasePositionMaxDeliveryDate state.RentOrder selectedPositionsIds) onlyWithTask isDisabled (fun _ -> dispatch FetchRentOrder)

let revertReleaseButton (state : State) dispatch =
    let selectedPositions =
        state.Positions
        |> List.choose (fun pos ->
            match pos with
            | PositionRow.ShowEntityPosition (pos, isSelected) ->
                if isSelected
                then Some (RentOrderPosition.RentOrderEntityPosition pos)
                else None
            | PositionRow.ShowMaterialPosition (pos, isSelected) ->
                if isSelected
                then Some (RentOrderPosition.RentOrderMaterialPosition pos)
                else None
            | _ -> None)
    let selectedPositionsIds = selectedPositions |> List.map Helper.Position.id
    let isDisabled = selectedPositions |> List.isEmpty

    RevertReleaseDialog.revertReleaseDialog state.RentOrder.Id selectedPositionsIds isDisabled (fun _ -> dispatch FetchRentOrder)


let deletePositionButton (state : State) dispatch =
    let selectedPositions =
        state.Positions
        |> List.choose (fun pos ->
            match pos with
            | PositionRow.ShowEntityPosition (pos, isSelected) ->
                if isSelected then Some pos.Id else None
            | PositionRow.ShowMaterialPosition (pos, isSelected) ->
                if isSelected then Some pos.Id else None
            | _ -> None)
    let isDisabled = selectedPositions |> List.isEmpty

    DeletePositionDialog.deletePositionDialog state.RentOrder.Id selectedPositions isDisabled (fun _ -> dispatch FetchRentOrder)

let actionPositionDropdown state dispatch =
    Html.div [
        prop.className "ml-2 dropdown show"
        prop.id "action-position-dropdown"
        prop.children [
            Html.button [
                prop.id "dropdownMenuLink"
                prop.className "btn btn-secondary dropdown-toggle"
                prop.href "#"
                prop.role "button"
                prop.custom ("data-toggle", "dropdown")
                prop.text "Aktionen"
            ]
            Html.div [
                prop.className "dropdown-menu"
                prop.children [
                    Html.button [
                        prop.className "dropdown-item"
                        prop.onClick (fun _ -> SetEdit |> dispatch)
                        prop.text "Bearbeiten"
                    ]
                    Html.div [ prop.className "dropdown-divider" ]
                    Html.button [
                        prop.className "dropdown-item"
                        prop.onClick (fun _ -> SetAddPositions |> dispatch)
                        prop.text "Position hinzufügen"
                    ]
                    Html.div [ prop.className "dropdown-divider" ]
                    releaseButton state dispatch
                    revertReleaseButton state dispatch
                    deletePositionButton state dispatch
                ]
            ]
        ]
    ]

let private editPositionHeader (state : State) dispatch =
    Html.div [
        prop.className "d-flex"
        prop.children [
            match state.ComponentState with
            | ComponentState.Show ->
                actionPositionDropdown state dispatch
            | ComponentState.EditPositions
            | ComponentState.NewPosition ->
                if state.InitialComponentState <> ComponentState.NewPosition then
                    Button.saveButton((fun _ -> state.RentOrder.Id |> SavePositions |> dispatch))
                    Button.undoButton((fun _ -> dispatch Abort))
                else ()
        ]
    ]

let view' = React.functionComponent("Positions", fun (props : Props) ->
    let loadData = async {
//        let! entities = Communication.asyncGetRequest<EntitiesDtoResponse> "/api/entitiesdto/all"
//        let! templates = Communication.asyncGetRequest<TemplatesResponse> "/api/templates"
//        let! materials = Communication.asyncGetRequest<MaterialsResponse> "/api/materials"
//        let! notActiveEntities = Communication.asyncGetRequest<EntitiesResponse> "/api/entities/deactivated"
        let! dto = Communication.asyncGetRequest<RentOrderPositionDto> "/api/rentorders/positions"

        return dto
    }

    let state, dispatch = React.useElmish((fun _ -> init props), update, [| |])
    let data = React.useDeferred(loadData, [| |])

    let publishPositions, setPublishPositions = React.useState(false)

    if props.FormValidation |> Option.isSome && props.FormValidation <> state.FormValidation
    then props.FormValidation |> SetValidations |> dispatch

    if state.RefreshRentOrderFromProps && props.RentOrder <> state.RentOrder
    then props.RentOrder |> RefreshRentOrderFromProps |> dispatch

    if props.SaveRequested then
        if not publishPositions then
            setPublishPositions true
            state.Positions |> List.map Row.toRentOrderPosition |> props.SaveCallback state.WithoutDelivery
    else
        if publishPositions then setPublishPositions false

    Html.div [
        Header.subHeader(
            "Positionen",
            className = "d-flex",
            child = editPositionHeader state dispatch)

        match data with
        | Deferred.HasNotStartedYet
        | Deferred.InProgress ->
            Html.div "Loading"
        | Deferred.Failed e ->
            Html.div "Failed Loading"
        | Deferred.Resolved data ->
            if (not state.DataFetched) then
                data |> DataFetched |> dispatch

            if state.ComponentState = ComponentState.NewPosition then
                Form.checkBox
                    (fun _ -> dispatch SetWithoutDelivery) state.WithoutDelivery
                    "Geräte & Material direkt der Baustelle zuweisen. Es wird keine Lieferaufgabe erstellt, die disponiert werden kann."
                    false "assignment-without-delivery-checkbox d-flex flex-row-reverse justify-content-end"
            validationWarning state.FormValidation "positions"
            PositionTable.positionTable state.Positions state dispatch

            if (state.ComponentState = ComponentState.NewPosition) then
                Html.div [
                    prop.id "add-position-dropdown"
                    prop.className "ml-2 dropdown show"
                    prop.children [
                        Html.button [
                            prop.id "dropdownMenuLink"
                            prop.className "btn btn-primary dropdown-toggle"
                            prop.href "#"
                            prop.role "button"
                            prop.custom ("data-toggle", "dropdown")
                            prop.text "Position hinzufügen"
                        ]
                        Html.div [
                            prop.className "dropdown-menu"
                            prop.children [
                                Html.button [
                                    prop.className "dropdown-item"
                                    prop.onClick (fun _ -> AddEntityPosition |> dispatch)
                                    prop.text "Gerät hinzufügen"
                                ]
                                Html.button [
                                    prop.className "dropdown-item"
                                    prop.onClick (fun _ -> AddMaterialPosition |> dispatch)
                                    prop.text "Material hinzufügen"
                                ]
                            ]
                        ]
                    ]
                ]
        ]
    )

let view rentOrder configuration componentState userData formValidation saveRequested saveCallback successCallback =
    view' { RentOrder = rentOrder
            Configuration = configuration
            InitialComponentState = componentState
            ComponentState = componentState
            UserData = userData
            FormValidation = formValidation
            SaveRequested = saveRequested
            SaveCallback = saveCallback
            SuccessCallback = successCallback }