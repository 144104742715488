module Navigation

open Client.Types
open Fable.React
open Fable.React.Props
open SharedComponents.Dividers
open Shared
open Feliz

let isActive currentRoute target =
    match currentRoute with
    | Routes.Page.AllEntities _
    | Routes.Page.Entity _
    | Routes.Page.DeactivateEntity _
    | Routes.Page.EntityNewForm _
    | Routes.Page.EntityViewForm _
    | Routes.Page.EntityCopyForm _
    | Routes.Page.Templates _ ->
        if target = Routes.Page.Templates then " active " else ""
    | Routes.Page.DispositionOverview
    | Routes.Page.DispostionNewForm _ ->
        if target = Routes.Page.DispositionOverview then " active " else ""
    | Routes.Page.OrderOverview
    | Routes.Page.FreeOrderDetail _
    | Routes.Page.FreeOrderOverview _
    | Routes.Page.FreeOrderOverviewCompleted _
    | Routes.Page.FreeOrderNewForm _
    | Routes.Page.FreeOrderDispositionDetail _
    | Routes.Page.RentOrderDetail _
    | Routes.Page.RentOrderOverview
    | Routes.Page.RentOrderOverviewCompleted
    | Routes.Page.RentOrderNewForm
    | Routes.Page.RentOrderEditForm _
    | Routes.Page.RepairOrderDetail _
    | Routes.Page.ServiceOrderOverviewCompleted
    | Routes.Page.RepairOrderOverview
    | Routes.Page.RentOrderDispositionDetail _
    | Routes.Page.RepairOrderDispositionDetail _
    | Routes.Page.RepairOrderNewForm ->
        if target = Routes.Page.OrderOverview then " active" else ""
    | Routes.Page.Login _
    | Routes.Page.FatalErrorView _
    | Routes.Page.NotLoggedInErrorView _
    | Routes.Page.Dashboard
    | Routes.Page.MessageCenter
    | Routes.Page.MyProfil
    | Routes.Page.Support
    | Routes.Page.TemplateConfiguration
    | Routes.Page.TemplatesNewForm _
    | Routes.Page.TemplatesViewForm _
    | Routes.Page.TemplateGroupNewForm _
    | Routes.Page.TemplateGroupViewForm _
    | Routes.Page.Configuration
    | Routes.Page.MyTaskOverview _
    | Routes.Page.MyTaskViewForm _
    | Routes.Page.CarrierManagement
    | Routes.Page.CarrierManagementNewForm
    | Routes.Page.CarrierManagementViewForm _
    | Routes.Page.UserManagement
    | Routes.Page.UserManagementNewForm
    | Routes.Page.UserManagementViewForm _ ->
        if target = currentRoute then " active " else ""
    | Routes.Page.Appointment ->
        if target = currentRoute then " active " else ""
    | Routes.Page.Available ->
        if target = currentRoute then " active " else ""
    | Routes.Page.Reports ->
        if target = currentRoute then " active " else ""
    | Routes.Page.Control ->
        if target = currentRoute then " active " else ""
    | Routes.Page.MaterialOverview
    | Routes.Page.MaterialNewForm
    | Routes.Page.MaterialEditForm _ ->
        if target = Routes.Page.MaterialOverview then " active " else ""

let navigationElement target label currentRoute dispatch cyIdentifier =
  let cyIdentifier = "navigationElement-" + cyIdentifier
  let active = isActive currentRoute target
  div [ Class ("nav-element d-flex align-items-center px-3" + active) ]
      [ a [ Href (Routes.toPath target)
            Data ("cy", cyIdentifier )
            Data ("target", "#main-navbar")
            OnClick (fun _ -> dispatch ToggleMenu) ]
          [ str label ] ]

let subNavigationElement target label currentRoute dispatch cyName =
  div [ Class ("dropdown-nav-element d-flex align-items-center mx-3 ")]
      [ a [ Href (Routes.toPath target)
            Data ("cy", cyName )
            Data ("target", "#main-navbar")
            OnClick (fun _ -> dispatch ToggleMenu) ]
          [ str label ] ]

let dispositionDropdown model dispatch logoutFnct currentRoute =
   let active = isActive currentRoute Routes.Page.DispositionOverview
   li [ Class ("d-flex align-items-center nav-item dropdown px-3 " + active)
        Id "disposition-dropdown" ]
    [ a [ Class ("nav-dropdown-main nav-link dropdown-toggle " + active)
          Href "#"
          Id "navbar-disposition-dropdown"
          Role "button"
          DataToggle "dropdown"
          AriaHasPopup true
          AriaExpanded false ]
        [ str "Disposition" ]
      div [ Class "dropdown-menu"
            HTMLAttr.Custom ("aria-labelledby", "navbar-disposition-dropdown") ]
        [ subNavigationElement Routes.Page.DispositionOverview "Übersicht" currentRoute dispatch "nav-overview-link"
          divider
          subNavigationElement (Routes.Page.DispostionNewForm None) "Disponieren" currentRoute dispatch "nav-disposition-link" ] ]

let logoutElement logoutFnct =
  div [ Class "dropdown-nav-element d-flex align-items-center mx-3 logout-button" ]
    [ a [ OnClick logoutFnct ]
        [ str "Abmelden"] ]

let userDataDropdown (model : Model) dispatch logoutFnct currentRoute =
    Html.li [
        prop.id "user-dropdown"
        prop.className ("d-flex align-items-center nav-item dropdown mr-3")
        prop.children [
            Html.button [
                prop.id "navbarDropdown"
                prop.className ("btn dropdown")
                prop.href "#"
                prop.role "button"
                prop.custom ("data-toggle", "dropdown")
                prop.custom ("data-cy", "username-nav-dropdown")
                prop.children [
                    Html.img [
                        prop.src "cog.svg"
                        prop.height 25.
                    ]
                ]
            ]
            Html.div [
                prop.className "dropdown-menu dropdown-menu-right"
                prop.custom ("aria-labelledby", "navbarDropdown")
                prop.children [
           if Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Administrator then
                subNavigationElement Routes.Page.MessageCenter "Nachrichten" currentRoute dispatch "nav-message-center-link"
                divider
                subNavigationElement Routes.Page.UserManagement "Benutzerverwaltung" currentRoute dispatch "nav-usermanagement-link"
                divider
                subNavigationElement Routes.Page.Configuration "Einstellungen" currentRoute dispatch "nav-configuration-link"
                divider
                subNavigationElement Routes.Page.TemplateConfiguration "Baugeräte-Administration" currentRoute dispatch "nav-template-configuration-link"
                divider
                subNavigationElement Routes.Page.CarrierManagement "Spedition-Administration" currentRoute dispatch "nav-template-carrier-link"
                divider
                subNavigationElement Routes.Page.MyProfil "Mein Profil" currentRoute dispatch "nav-myprofil-link"
                divider
                logoutElement logoutFnct
           elif Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Dispatcher then
                subNavigationElement Routes.Page.MessageCenter "Nachrichten" currentRoute dispatch "nav-message-center-link"
                divider
                subNavigationElement Routes.Page.MyProfil "Mein Profil" currentRoute dispatch "nav-myprofil-link"
                divider
                logoutElement logoutFnct
           elif Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Driver ||
                Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Mechanic ||
                Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Picker ||
                Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Technician then
                subNavigationElement Routes.Page.MessageCenter "Nachrichten" currentRoute dispatch "nav-message-center-link"
                divider
                subNavigationElement Routes.Page.MyProfil "Mein Profil" currentRoute dispatch "nav-myprofil-link"
                divider
                logoutElement logoutFnct ] ]
      ]
    ]

let supportDropdown (state : Model) currentRoute dispatch =
    Html.li [
        prop.id "user-dropdown"
        prop.className ("d-flex align-items-center nav-item dropdown")
        prop.children [
            Html.button [
                prop.id "navbarDropdown"
                prop.className ("btn dropdown")
                prop.href "#"
                prop.role "button"
                prop.custom ("data-toggle", "dropdown")
                prop.custom ("data-cy", "username-nav-dropdown")
                prop.children [
                    Html.img [
                        prop.src "question-circle.svg"
                        prop.height 25.
                    ]
                ]
            ]
            Html.div [
                prop.className "dropdown-menu dropdown-menu-right"
                prop.custom ("aria-labelledby", "navbarDropdown")
                prop.children [
                  SharedComponents.Buttons.textButtonExternalLink "https://support.softwarewerkstatt.io/help/de-de/1-dispoprofi" "Hilfe" "dropdown-nav-element d-flex align-items-center mx-3"
                  divider
                  subNavigationElement Routes.Page.Support "Support" currentRoute dispatch "nav-support-link"
                ]
            ]
        ]
    ]
    
let memberMainNav model dispatch logoutFnct currentRoute =
  [ navigationElement Routes.Page.Dashboard "Übersicht" currentRoute dispatch "dashboard"
    divider
    navigationElement Routes.Page.MyTaskOverview "Meine Aufgaben" currentRoute dispatch "mytask"
    divider
    Html.div [
        prop.className "nav navbar-nav navbar-right ml-auto"
        prop.children [
            supportDropdown model currentRoute dispatch
            userDataDropdown model dispatch logoutFnct currentRoute
        ]
      ]
    ]

let allNavLinks model dispatch logoutFnct currentRoute =
  [ navigationElement Routes.Page.Dashboard "Übersicht" currentRoute dispatch "dashboard"
    divider
    navigationElement Routes.Page.OrderOverview "Aufträge" currentRoute dispatch "orders"
    divider
    dispositionDropdown model dispatch logoutFnct currentRoute
    divider
    navigationElement Routes.Page.Available "Buchungsplan" currentRoute dispatch "available"
    divider
    navigationElement Routes.Page.Appointment "Termine" currentRoute dispatch "appointments"
    divider
    navigationElement Routes.Page.Templates "Baugeräte" currentRoute dispatch "nav-masterdata-entities-link"
    divider
    navigationElement Routes.Page.MaterialOverview "Material" currentRoute dispatch "nav-masterdata-material-link"
    divider
    navigationElement Routes.Page.Reports "Berichte" currentRoute dispatch "reports"
    divider
    //navigationElement Routes.Page.Control "Control" currentRoute dispatch "controls"
    //divider
    Html.div [
        prop.className "nav navbar-nav navbar-right ml-auto"
        prop.children [
            supportDropdown model currentRoute dispatch
            userDataDropdown model dispatch logoutFnct currentRoute
        ]
      ]
    ]

let technicianNavLinks model dispatch logoutFnct currentRoute =
  [ navigationElement Routes.Page.Dashboard "Übersicht" currentRoute dispatch "dashboard"
    divider
    navigationElement Routes.Page.MyTaskOverview "Meine Aufgaben" currentRoute dispatch "mytask"
    divider
    navigationElement Routes.Page.Templates "Baugeräte" currentRoute dispatch "nav-masterdata-entities-link"
    divider
    Html.div [
        prop.className "nav navbar-nav navbar-right ml-auto"
        prop.children [
            supportDropdown model currentRoute dispatch
            userDataDropdown model dispatch logoutFnct currentRoute
        ]
      ] 
  ]

let mainNavbar (model : Model) dispatch logoutFnct currentRoute =
  nav [ Class "d-flex navbar navbar-expand-lg" ]
      [ a [ Class "navbar-brand"
            Href "/" ]
          [ img [ Src "Logo.jpg"
                  Class "navbar-logo ml-3"
                  Href (Routes.toPath Routes.Page.Dashboard) ] ]
        button [ Class "navbar-toggler"
                 Type "button"
                 DataToggle "collapse"
                 OnClick (fun _ -> dispatch ToggleMenu)
                 Data ("target", "#main-navbar") ]
          [ span [ Class "fas fa-bars" ]
              [ ] ]
        div [ Class (sprintf "collapse navbar-collapse")
              Id "main-navbar" ]
          [ div [ Class "d-flex flex-grow-1 navbar-nav" ]
              (if Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Administrator ||
                  Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Dispatcher  then
                allNavLinks model dispatch logoutFnct currentRoute
              elif Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Technician then
                technicianNavLinks model dispatch logoutFnct currentRoute
              elif Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Driver ||
                   Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Mechanic ||
                   Shared.RoleValidation.containsRoles model.User.Value.AssignedRoles Shared.RoleNames.Picker then
                memberMainNav model dispatch logoutFnct currentRoute
              else
                []) ] ]

let initSubNavigation model dispatch logoutFnct currentRoute =
  match currentRoute with
      | Routes.Page.Entity _
      | Routes.Page.DeactivateEntity _
      | Routes.Page.EntityNewForm _
      | Routes.Page.EntityCopyForm _
      | Routes.Page.EntityViewForm _
      | Routes.Page.AllEntities _
      | Routes.Page.Templates _
      | Routes.Page.TemplatesNewForm _
      | Routes.Page.TemplatesViewForm _
      | Routes.Page.TemplateGroupNewForm _
      | Routes.Page.TemplateGroupViewForm _
      | Routes.Page.Login _
      | Routes.Page.NotLoggedInErrorView _
      | Routes.Page.FatalErrorView _
      | Routes.Page.Dashboard
      | Routes.Page.MessageCenter
      | Routes.Page.MyProfil
      | Routes.Page.TemplateConfiguration
      | Routes.Page.Support
      | Routes.Page.UserManagement
      | Routes.Page.UserManagementNewForm
      | Routes.Page.UserManagementViewForm _
      | Routes.Page.CarrierManagement
      | Routes.Page.CarrierManagementNewForm
      | Routes.Page.CarrierManagementViewForm _
      | Routes.Page.Configuration _
      | Routes.Page.OrderOverview
      | Routes.Page.FreeOrderOverview
      | Routes.Page.FreeOrderOverviewCompleted
      | Routes.Page.FreeOrderDetail _
      | Routes.Page.FreeOrderNewForm _
      | Routes.Page.FreeOrderDispositionDetail _
      | Routes.Page.RentOrderOverview
      | Routes.Page.RentOrderOverviewCompleted
      | Routes.Page.RentOrderDetail _
      | Routes.Page.RentOrderNewForm
      | Routes.Page.RentOrderEditForm _
      | Routes.Page.RentOrderDispositionDetail _
      | Routes.Page.RepairOrderOverview
      | Routes.Page.ServiceOrderOverviewCompleted
      | Routes.Page.RepairOrderDetail _
      | Routes.Page.RepairOrderNewForm
      | Routes.Page.RepairOrderDispositionDetail _
      | Routes.Page.MyTaskOverview _
      | Routes.Page.MyTaskViewForm _
      | Routes.Page.Appointment _
      | Routes.Page.Available _
      | Routes.Page.Reports _
      | Routes.Page.Control _
      | Routes.Page.DispositionOverview
      | Routes.Page.DispostionNewForm _
      | Routes.Page.MaterialOverview
      | Routes.Page.MaterialNewForm
      | Routes.Page.MaterialEditForm _ ->
          Fable.React.Helpers.nothing

let view model dispatch logoutFnct currentRoute =
  div [ Id "navigation" ]
    [ mainNavbar model dispatch logoutFnct currentRoute ]
