module Dispositions.RepairOrderDisposition.View

open Fable.React
open Fable.React.Props
open Dispositions.RepairOrderDisposition.Types
open Shared
open Shared.RentOrder
open Shared.TaskTypes
open Shared.Entity
open SharedComponents.Headers
open Shared.ServiceOrder
open Routes
open Shared.TaskTypes.Helper
open SharedComponents.Breadcrumb
open SharedComponents
open SharedComponents.ReactImageViewer
open SharedComponents.ReactImgOrientation
open SharedComponents.Spinners

let private employeeName (employees : Employee list) (employeeId : int) =
    match employees |> List.tryFind (fun e -> e.Id = employeeId) with
    | Some employee -> sprintf "%s %s" employee.Firstname employee.Lastname
    | None -> ""

let private entityName (entities : Entity list) (entityIds : EntityType list) =
    let entities =
        entities |> List.map (fun e -> if entityIds |> List.collect toEntityIds |> List.contains e.Id then Some e.Name else None)
        |> List.choose id
    if not entities.IsEmpty then
        entities
        |> List.reduce (fun combined element -> sprintf "%s, %s" combined element)
    else ""


let private rentOrderReference (model : Model) =
    match model.RepairOrderWithServiceReport with
    | None -> Fable.React.Helpers.nothing
    | Some r ->
        match r.ReferencedRentOrder with
        | Some rentOrder  ->
            let (RentOrderId rentOrderId) = rentOrder.Id
            a [ Href (Routes.toPath (Page.RentOrderDetail rentOrderId)) ]
                [ i [ Class "fas fa-info" ] [ ]
                  span [ Class "url-text" ] [ str "zum Mietauftrag" ] ]
        | None -> Fable.React.Helpers.nothing

let private repairTaskContent (model : Model) (task : AssignedServiceTask) =
    let executionDate =
      match task.ServiceReport with
      | Some r -> r.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm")
      | None -> task.ScheduledExecutionDate.ToLocalTime().ToShortDateString()
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Servicedatum: %s" executionDate) ]
          p [ ] [ str (sprintf "Geräte: %s" (entityName model.Entities task.EntityIds)) ]
          p [ ] [ str (sprintf "Techniker: %s" (employeeName model.Technicans task.UserId) ) ]
          rentOrderReference model ]

let private repairDispositionDescriptionField (model : Model) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Service"
          div [ Class "d-flex" ]
            [ (match model.Task with
              | Some task -> repairTaskContent model task
              | None -> SharedComponents.Spinners.overlaySpinner RequestState.Active) ] ]

let private orderDescription (model : Model) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Serviceadresse"
          div [ Class "d-flex" ]
            [ (match model.Task with
              | Some t ->
                div [ Class "mr-3" ]
                    [ p [ ] [ str (sprintf "%s %s" t.Address.Street t.Address.HouseNumber) ]
                      p [ ] [ str (sprintf "%s %s" t.Address.ZipCode t.Address.City) ] ]
              | None -> SharedComponents.Spinners.overlaySpinner RequestState.Active) ] ]

let private signatureField (model : Model) (signer :string option) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kundenunterschrift"
          match model.Signature with
          | Some signatureDoc ->
              div [ Class "d-flex flex-column" ]
                [ div [ Class "signature-image-container" ]
                    [ img [ Class "signature-image"
                            HTMLAttr.Src (sprintf "data:image/png;base64, %s" signatureDoc.Content) ] ]
                  match signer with
                  | Some signer ->
                        div [ ]
                            [ str (sprintf "Name: %s" signer) ]
                  | None -> Fable.React.Helpers.nothing ]
          | _ ->
              div [ ]
                [ p [ ] [ str "Es ist keine Unterschrift vorhanden." ] ] ]

let private orderAndTaskCommentField (model : Model) =
    let comment comment =
        if System.String.IsNullOrWhiteSpace comment then
            "Es ist kein Kommentar vorhanden." else comment

    let orderComment, taskComment =
        match model.RepairOrderWithServiceReport, model.Task with
        | Some repairOrder, Some task ->
            (comment repairOrder.FailureDescription), (comment task.Comment)
        | Some repairOrder, None ->
            (comment repairOrder.FailureDescription), ""
        | None, Some task ->
            "", (comment task.Comment)
        | None, None -> "", ""
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kommentare"
          div [ Class "d-flex" ]
            [ div [ Class "mr-3" ]
                [ p [ ] [ str (sprintf "Auftragskommentar: %s" orderComment) ]
                  p [ ] [ str (sprintf "Aufgabenkommentar: %s" taskComment) ] ] ] ]

let private materialRow (usedMaterial : UsedMaterial) =
    tr [ Key usedMaterial.Material ]
        [ td [ ]
            [ str (usedMaterial.Material) ]
          td [ ]
            [ usedMaterial.Quantity |> Helper.floatValueToString |> str ]
          td [ ]
            [ str usedMaterial.Unit ] ]

let private materialTable (usedMaterial : UsedMaterial list) =
    table [ Class "table table-hover" ]
      [ thead [ ]
          [ tr [ ]
              [ th [ ]
                  [ str "Material" ]
                th [ ]
                  [ str "Menge" ]
                th [ ]
                  [ str "Einheit"] ] ]
        tbody [ ]
          [ usedMaterial
            |> List.map materialRow
            |> ofList ] ]

let private materialCommentField (usedMaterial : UsedMaterial list) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Materialverbrauch"
          materialTable usedMaterial ]

let private commentField comment =
    let commentContent =
        if System.String.IsNullOrWhiteSpace comment then "Es ist kein Kommentar vorhanden." else comment
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Tätigkeitsbeschreibung"
          div [ Class "d-flex" ]
            [ p [ Class "mr-3" ]
                [ str commentContent ] ] ]

let private entityServiceReport (model : Model) dispatch (entityServiceReport : ServiceReportPerEntity) =
    let selectedClass entityId =
        match model.SelectedEntity with
        | None -> ""
        | Some entity ->
            if entity.Id = entityId then "active"
            else ""
    match model.Entities |> List.tryFind (fun e -> e.Id = entityServiceReport.EntityId) with
    | Some entity ->
        button [ Type "button"
                 Class (sprintf "list-group-item list-group-item-action %s" (selectedClass entity.Id))
                 OnClick(fun _ -> dispatch (SelectEntity entity)) ]
           [ str entity.Name]
    | None -> Fable.React.Helpers.nothing



let private entityImageSlider (model : Model) (serviceReport : ServiceReportPerEntity) dispatch =
    let imageSlide index (image : Image) =
        let docOption = model.Images |> List.tryFind (fun i -> i.Id = image.Id)
        match docOption with
        | Some imageDoc ->
            div [ Class "img-container mr-2" ]
                [ a [ OnClick (fun _ -> (serviceReport.EntityId, index) |> OpenImageViewer |> dispatch)
                      Class "img-thumbnail-href" ]
                    [ ReactImgOrientation.image [ ReactImgOrientationProps.ClassName "signature-image img-thumbnail"
                                                  ReactImgOrientationProps.Src (sprintf "data:image/png;base64, %s" imageDoc.Content) ] ] ]
        | None -> Fable.React.Helpers.nothing
    let viewer =
        let images =
            serviceReport.Images |> List.choose (fun image ->
                let imageDoc = model.Images |> List.tryFind (fun i -> i.Id = image.Id)
                match imageDoc with
                | Some doc ->
                    { ImageDecorator.src = (sprintf "data:image/png;base64, %s" doc.Content) } |> Some
                | None -> None)
            |> List.toArray
        imageViewer [ CommonProps.ClassName "img-viewer"
                      Images images
                      Visible model.ImageViewerOpen.[serviceReport.EntityId]
                      ActiveIndex model.ImageViewerIndex
                      OnClose (fun _ -> serviceReport.EntityId |> CloseImageViewer |> dispatch) ]
    div [ Class "d-flex image-container mt-3" ]
        [ serviceReport.Images
          |> List.mapi imageSlide
          |> ofList
          viewer ]

let private entityDetail (model : Model) dispatch (serviceReport : ServiceReportPerEntity) =
    let entity = model.Entities |> List.tryFind (fun e -> e.Id = serviceReport.EntityId)
    match entity with
    | Some entity ->
        div [ Class "mr-3 d-flex flex-column mb-4" ]
            [ h3 [ ] [ str entity.Name ]
              div [ Class "d-flex" ]
                [ div [ Class "d-flex flex-column flex-grow-1" ]
                      [ label [ HtmlFor "comment" ]
                            [ str "Tätigkeitsbeschreibung" ]
                        div [ Class "entity-comment flex-grow-1" ]
                            [ str serviceReport.Comment ] ]
                  div [ Class "ml-2 flex-grow-1" ]
                    [ label [ HtmlFor "material" ]
                        [ str "Materialverbrauch" ]
                      materialTable serviceReport.UsedMaterial ] ]
              if serviceReport.Images.IsEmpty then
                  str "Es sind keine Bilder vorhanden."
              else entityImageSlider model serviceReport dispatch ]
    | None -> Fable.React.Helpers.nothing

let private entityList (serviceReport : ServiceReport) (model : Model) dispatch =
    serviceReport.ReportPerEntities
    |> List.map (entityDetail model dispatch)
    |> ofList

let private repairDispositionView model dispatch (serviceReport : ServiceReport) =
    div [ Class "d-flex flex-column flex-grow-1" ]
      [ div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ repairDispositionDescriptionField model ]
            div [ Class "flex-grow-1 p-2"]
              [ orderDescription model ] ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ orderAndTaskCommentField model ]
            div [ Class "flex-grow-1 p-2"]
              [ signatureField model serviceReport.Signer ] ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ commentField serviceReport.Comment ]
            div [ Class "flex-grow-1 p-2"]
              [ materialCommentField serviceReport.UsedMaterial ] ]
        div [ Class "p-2" ]
          [ SharedComponents.Headers.subHeaderForm "Geräte" ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ entityList serviceReport model dispatch ] ] ]

let serviceOrderDispositionBreadcrumb (serviceOrderId : ServiceOrderId) =
    let (ServiceOrderId serviceOrderId) = serviceOrderId
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RepairOrderOverview |> Routes.toPath) "Serviceaufträge"
        breadcrumbLink (serviceOrderId |> Page.RepairOrderDetail |> Routes.toPath) "Serviceauftrag"
        breadcrumbStr "Report"
    ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "repair-order-report"
          Class "flex-grow-1 d-flex flex-column" ]
        [ SharedComponents.Spinners.overlaySpinner model.RequestState
          serviceOrderDispositionBreadcrumb model.OrderId
          div [ Id "order-content"]
            [ mainHeaderBar None "Disposition" (Some (fun _ -> dispatch PrintAsPdf)) None
              match model.RepairOrderWithServiceReport with
              | Some serviceOrder ->
                  let report =
                    if serviceOrder.Tasks.IsEmpty then
                        None
                    else
                        match serviceOrder.Tasks.Head with
                        | ServiceOrderTask.AssignedServiceTask t -> t.ServiceReport
                        | ServiceOrderTask.ServiceTask t -> None
                  match report with
                  | Some serviceReport -> repairDispositionView model dispatch serviceReport
                  | None -> Fable.React.Helpers.nothing
              | None -> Fable.React.Helpers.nothing ] ]
