module Order.ServiceOrder.FormValidation

open Order.ServiceOrder.Types
open Shared.ServiceOrder
open Validation
open System
open Shared.Address

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let isValid =
    { Validations = []
      FormValid = ValidationState.Valid }

let private create key value : ValidationKeyValue =
    { Key = key
      Value = value }

let formValid (newValidations : ValidationKeyValue list) =
    let isNotValid =
        newValidations
        |> List.exists (fun validation ->
            match validation.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    if isNotValid then ValidationState.NotValid "" else ValidationState.Valid

let entities (serviceOrder : ServiceOrder) =
    if not serviceOrder.EntityIds.IsEmpty then
        create "entities" ValidationState.Valid
    else create "entities" (ValidationState.NotValid "Gerät muss ausgewählt sein.")

let address (address : Address) =
    if System.String.IsNullOrWhiteSpace address.City || System.String.IsNullOrWhiteSpace address.Street then
        create "address" (ValidationState.NotValid "Für das Gerät ist leider keine Adresse hinterlegt.")
    else create "address" ValidationState.Valid

let validateNewForm (serviceOrderForm : ServiceOrderNewForm) : FormValidation =
    let serviceOrder = serviceOrderForm.ServiceOrder
    let newValidations = [ entities serviceOrder
                           address serviceOrder.ExecutionAddress ]
    { Validations = newValidations
      FormValid = formValid newValidations }