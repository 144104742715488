module Order.RentOrder.PositionTable

open Client.SharedComponents
open Order.RentOrder.PositionTypes
open Order.RentOrder.Helper
open SharedComponents
open Shared
open SharedComponents.ReactSelect
open System
open Validation
open Shared.Entity
open Shared.RentOrder
open Shared.TaskTypes
open SharedComponents.Badges
open Shared.Material

module SelectProps =
    open Fable.React
    open Fable.React.Props

    let filterEntityPositions positions =
        positions
        |> List.choose (function
            | PositionRow.ShowEntityPosition (pos, _) -> Some pos
            | PositionRow.EditEntityPosition (pos, _) -> Some pos
            | PositionRow.NewEntityPosition (pos, _) -> Some pos
            | _ -> None)

    let templateProps (rentOrderPosition : RentOrderEntityPosition) (state : State) dispatch isDisabled templateId =
        let options (templates : Template list) =
            templates
            |> List.map (fun el ->
                { label = el.TemplateBaseInformation.Name
                  value = el.Id })
            |> List.toArray

        let emptySearchResultContent =
            div [ Class "mr-auto p-2" ]
              [ str "Es konnte kein passender Typ gefunden werden." ]

        let valueElement =
            match templateId with
            | Some templateId ->
                let templateOption = state.Templates |> List.tryFind (fun t -> t.Id = templateId)
                match templateOption with
                | Some template ->
                    [| ReactSelect.CommonProps<TemplateId>.Value (Some { label = template.TemplateBaseInformation.Name; value = template.Id }) |]
                | None -> [| ReactSelect.CommonProps<TemplateId>.Value None |]
            | None -> [| ReactSelect.CommonProps<TemplateId>.Value None |]
        Array.append
            [| SharedComponents.ReactSelect.CommonProps<TemplateId>.Options (options state.Templates)
               SharedComponents.ReactSelect.CommonProps<TemplateId>.OnChange (fun e -> dispatch (SetTemplateToPosition (e, rentOrderPosition.PosNo)))
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsClearable true
               SharedComponents.ReactSelect.CommonProps<TemplateId>.Placeholder "Suchen oder Filtern"
               SharedComponents.ReactSelect.CommonProps<TemplateId>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<TemplateId>.ClassName "template-select flex-grow-1"
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsDisabled isDisabled |]
            valueElement

    let entityProps (rentOrderPosition : RentOrderEntityPosition) (state : State) dispatch isDisabled templateId =
        let entityInOtherPosition (entityId : EntityId) =
            state.Positions
            |> filterEntityPositions
            |> List.filter(fun p -> p.State <> RentOrderPositionState.Returned)
            |> List.filter (fun rPos -> rPos.EntityId <> Shared.EntityId (System.Guid.Empty))
            |> List.map (fun rPos -> rPos.EntityId)
            |> List.contains(entityId)

        let filterByTemplateId (entity : EntityDto) =
            match templateId with
            | Some templateId -> entity.TemplateId = templateId
            | None -> true

        let options (entities : EntityDto list) =
            entities
            |> List.filter filterByTemplateId
            |> List.filter (fun e -> not (entityInOtherPosition e.Id))
            |> List.map (fun el ->
                { label = el.Name
                  value = el.Id, el.TemplateId })
            |> List.toArray

        let emptySearchResultContent =
            div [ Class "mr-auto p-2" ]
              [ str "Es konnte keine passendes Gerät gefunden werden." ]

        let valueElement =
            if rentOrderPosition.EntityId <> Shared.EntityId (System.Guid.Empty) then
                state.Entities
                |> List.tryFind (fun e -> e.Id = rentOrderPosition.EntityId )
                |> (fun el ->
                    match el with
                    | Some entity -> [| SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.Value (Some { label = entity.Name; value = (entity.Id, entity.TemplateId) }) |]
                    | None -> [| SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.Value None |])
            else  [| SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.Value None |]

        Array.append
            [| SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.Options (options state.Entities)
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.OnChange (fun e -> dispatch (SetEntityToPosition (e, rentOrderPosition.PosNo, rentOrderPosition.SubPosNo)))
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.IsClearable true
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.Placeholder "Suchen oder Filtern"
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.ClassName "entity-select flex-grow-1"
               SharedComponents.ReactSelect.CommonProps<EntityId * TemplateId>.IsDisabled isDisabled |]
            valueElement

    let materialSelectProps (position : RentOrderMaterialPosition) (state : State) dispatch isDisabled =
        let options (materials : Material list) =
            materials
            |> List.filter(fun m -> m.IsDeleted |> not)
            |> List.map (fun el ->
                { label = el.Name
                  value = el })
            |> List.toArray

        let emptySearchResultContent =
            div [ Class "mr-auto p-2" ]
              [ str "Es konnte keine passendes Material gefunden werden." ]

        let valueElement =
            if position.MaterialId <> (System.Guid.Empty |> MaterialId) then
                state.Materials
                |> List.tryFind (fun e -> e.Id = position.MaterialId )
                |> (fun el ->
                    match el with
                    | Some material -> [| SharedComponents.ReactSelect.CommonProps<Material>.Value (Some { label = material.Name; value = material }) |]
                    | None -> [| SharedComponents.ReactSelect.CommonProps<Material>.Value None |])
            else  [| SharedComponents.ReactSelect.CommonProps<Material>.Value None |]

        Array.append
            [| SharedComponents.ReactSelect.CommonProps<Material>.Options (options state.Materials)
               SharedComponents.ReactSelect.CommonProps<Material>.OnChange (fun m -> dispatch (SetMaterialToPosition (m, position.PosNo)))
               SharedComponents.ReactSelect.CommonProps<Material>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<Material>.IsClearable false
               SharedComponents.ReactSelect.CommonProps<Material>.Placeholder "Suchen oder Filtern"
               SharedComponents.ReactSelect.CommonProps<Material>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<Material>.ClassName "entity-select flex-grow-1"
               SharedComponents.ReactSelect.CommonProps<Material>.IsDisabled isDisabled |]
            valueElement

    let filterStateProps (state : State) dispatch =
        let options =
            [
                { label = "erfasst"
                  value = RentOrderPositionState.Planned }
                { label = "Lieferung eingeplant"
                  value = RentOrderPositionState.AssignedToDeliver }
                { label = "geliefert"
                  value = RentOrderPositionState.Delivered }
                { label = "Abholung eingeplant"
                  value = RentOrderPositionState.AssignedToReturn }
                { label = "abgeholt"
                  value = RentOrderPositionState.Returned }
                { label = "freigegeben"
                  value = RentOrderPositionState.Released }
            ]
            |> List.toArray

        let emptySearchResultContent =
            div [ Class "mr-auto p-2" ]
              [ str "Es konnte kein passender Status gefunden werden." ]

        let valueElement =
            match state.CurrentFilterState with
            | Some currentState ->
                let value = options |> Array.tryFind(fun o -> o.value = currentState)
                match value with
                | Some v -> [| ReactSelect.CommonProps<RentOrderPositionState>.Value (Some { label = v.label; value = v.value }) |]
                | None ->  [| ReactSelect.CommonProps<RentOrderPositionState>.Value None |]
            | None ->  [| ReactSelect.CommonProps<RentOrderPositionState>.Value None |]
        Array.append
            [| SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.Options (options)
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.OnChange (fun e -> dispatch (FilterByState (e)))
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.IsClearable true
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.Placeholder "Filter"
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.ClassName "template-select flex-grow-1"
               SharedComponents.ReactSelect.CommonProps<RentOrderPositionState>.IsDisabled false |]
            valueElement
module TableRows =
    open Feliz
    open Fable.React

    let row (key : string) className (actionElement : ReactElement option) (posNumber : string) (typeSelect : ReactElement) (entitySelect : ReactElement)
            (info : ReactElement) (startDate : ReactElement) (endDate : ReactElement) (state : ReactElement option) (comment : ReactElement) (selfserviceDelivery : ReactElement) (selfserviceReturn : ReactElement) (releaseDate : ReactElement option) =
        Html.tr [
            prop.key key
            prop.className (sprintf "rent-order-position-rows %s" className)
            prop.children [
                Html.td [
                    match actionElement with
                    | Some element -> element
                    | None -> Html.none
                ]
                Html.td [
                    prop.className "position-cell"
                    prop.text posNumber
                ]
                Html.td [
                    prop.className "type-select-column"
                    prop.children typeSelect
                ]
                Html.td [
                    prop.className "select-column"
                    prop.children entitySelect
                ]
                Html.td [
                    prop.className "info-column"
                    prop.children info
                ]
                Html.td [
                    prop.className "date-picker-column"
                    prop.children startDate
                ]
                Html.td [
                    prop.className "delivery-self-service"
                    prop.children [
                        selfserviceDelivery
                    ]
                ]
                Html.td [
                    prop.className "date-picker-column"
                    prop.children endDate
                ]
                Html.td [
                    prop.className "return-self-service"
                    prop.children [
                        selfserviceReturn
                    ]
                ]
                Html.td [
                    match state with
                    | Some state ->
                        prop.children state
                    | None -> ()
                ]
                Html.td [
                    prop.children comment
                ]
                Html.td [
                    match releaseDate with
                    | Some releaseDate ->
                        prop.children releaseDate
                    | None -> ()
                ]
            ]
        ]

    let removeIcon dispatch positionNumber subPositionNumber =
        Buttons.iconButton "fas fa-times" (fun _ -> (positionNumber, subPositionNumber) |> RemoveRentOrderPosition |> dispatch)

    let entityInfoToolTipText (state : State) (entity : EntityDto) =
        let locationString =
            match entity.CurrentLocation with
            | EntityLocation.RentOrderLocation _ ->
               sprintf "%s %s <br> %s %s <br> " entity.CurrentAddress.Street entity.CurrentAddress.HouseNumber entity.CurrentAddress.ZipCode entity.CurrentAddress.City
            | EntityLocation.ExecutionLocation l ->
               sprintf "%s %s <br> %s %s <br> " l.Street l.HouseNumber l.ZipCode l.City
            | EntityLocation.HomeLocation _ -> "Lager"
            | EntityLocation.OnRoadToRentOrder _
            | EntityLocation.OnRoadFromRentOrder _
            | EntityLocation.OnRoad _ -> "Unterwegs"
        let attributesString =
            match state.Templates |> List.tryFind(fun t -> t.Id = entity.TemplateId) with
            | Some t ->
                let attribute attributeId =
                    entity.Attributes |> List.find (fun a -> (Entity.Helper.entityAttributeId a) = attributeId)
                t.Attributes |> List.map (fun a -> sprintf "%s: %s <br>" a.Name (Entity.Helper.entityAttributeValueAsString (attribute a.Id)))
                |> List.fold (+) ""
            | None -> ""
        sprintf "<strong>Standort</strong> <br> %s <br> <strong>Details</strong> <br> %s" locationString attributesString

    let entityInfoIcon (state : State) (position : RentOrderEntityPosition) =
        match state.Entities |> List.tryFind(fun e -> e.Id = position.EntityId) with
        | Some entity ->
            Html.span [
                Html.i [
                    prop.custom ("data-tip", entityInfoToolTipText state entity)
                    prop.className "fas fa-info-circle date-icon"
                ]
                ReactTooltip.tooltip [ ReactTooltip.CommonProps.Html true ]
            ]
        | None -> Html.none

    let positionStateBadge position =
        match position |> Helper.Position.state with
        | RentOrderPositionState.Planned -> badgePill "erfasst" BadgeClassType.Warning
        | RentOrderPositionState.AssignedToDeliver -> badgePill "Lieferung eingeplant" BadgeClassType.Info
        | RentOrderPositionState.AssignedToReturn -> badgePill "Abholung eingeplant" BadgeClassType.Info
        | RentOrderPositionState.Delivered -> badgePill "geliefert" BadgeClassType.Success
        | RentOrderPositionState.Returned -> badgePill "abgeholt" BadgeClassType.Danger
        | RentOrderPositionState.Released -> badgePill "freigegeben" BadgeClassType.Dark

    let rowWarningClassName state (posNo : PositionNumber) (subPosNo : PositionNumber option) =
        let posNo = posNo |> Helper.unwrapPositionNumber
        let subPosNo = subPosNo |> Option.map Helper.unwrapPositionNumber |> Option.defaultValue 0
        let validationKey = sprintf "pos_%i_%i" posNo subPosNo

        match state.FormValidation with
        | Some validations ->
            if validations.Validations |> List.exists (fun validation -> validation.Key = validationKey)
            then "table-warning"
            else ""
        | None -> ""

        // if state.NotValidRentOrderPositions
        //     |> List.map (fun r -> r |> Helper.Position.posNo)
        //     |> List.contains(posNo)
        // then "table-warning"
        // else ""

    let isOptional (rentOrderPosition : RentOrderEntityPosition) (state : State) templateId =
        let entities = state.Entities
        let templates = state.Templates
        match templateId with
        | None -> false
        | Some templateId ->
            match rentOrderPosition.SubPosNo with
            | Some (PositionNumber subPosNo) ->
                if not templates.IsEmpty then
                    let parentPosition =
                        state.Positions |> List.map Row.toRentOrderPosition |> Helper.Position.filterEntityPositions
                        |> List.find (fun p -> p.PosNo = rentOrderPosition.PosNo && p.SubPosNo.IsNone)
                    let entityOption = entities |> List.tryFind (fun e -> e.Id = parentPosition.EntityId)
                    match entityOption with
                    | None -> false
                    | Some entity ->
                        let templateOption = templates |> List.tryFind (fun t -> t.Id = entity.TemplateId)
                        match templateOption with
                        | None -> false
                        | Some template ->
                            match template.ChildTemplates with
                            | Some childTemplates ->
                                match childTemplates.Templates |> List.tryFind (fun rt -> rt.TemplateId = templateId) with
                                | None ->
                                    false
                                | Some childTemplate ->
                                    if childTemplate.Min <> childTemplate.Max then
                                        let (PositionNumber minPosNumberOfTemplate) =
                                            state.Positions
                                            |> List.filter (function
                                                | ShowEntityPosition _ -> false
                                                | EditEntityPosition (pos, None) -> false
                                                | EditEntityPosition (pos, Some templateId) ->
                                                    pos.PosNo = rentOrderPosition.PosNo && templateId = childTemplate.TemplateId
                                                | NewEntityPosition (pos, None) -> false
                                                | NewEntityPosition (pos, Some templateId) ->
                                                    pos.PosNo = rentOrderPosition.PosNo && templateId = childTemplate.TemplateId
                                                | ShowMaterialPosition _
                                                | EditMaterialPosition _
                                                | NewMaterialPosition _ -> false
                                                )
                                            |> List.choose (fun t -> t |> Row.apply Helper.Position.subPosNo) |> List.min
                                        subPosNo - minPosNumberOfTemplate >= childTemplate.Min
                                    else false
                            | None -> false
                else false
            | None -> false

    let isSelfserviceDeliveryCheckboxDisabled (position : RentOrderPosition) =
        let currentState = position |> Helper.Position.state
        let selfserviceDelivery = position |> Helper.Position.selfserviceDelivery
        currentState = RentOrderPositionState.Delivered ||
        currentState = RentOrderPositionState.Returned ||
        (currentState = RentOrderPositionState.AssignedToDeliver && selfserviceDelivery |> not)

    let isSelfserviceReturnCheckboxDisabled (position : RentOrderPosition) =
        let currentState = position |> Helper.Position.state
        let selfserviceReturn = position |> Helper.Position.selfserviceReturn
        currentState = RentOrderPositionState.Returned ||
        (currentState = RentOrderPositionState.AssignedToReturn && selfserviceReturn |> not)

    let rentOrderNewEntityPositionRow (state : State) (dispatch : PositionTypes.Msg -> unit) (rentOrderPosition : RentOrderEntityPosition) templateId =
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let excludedDateTimes =
            match state.EntitiesNotAvailableDateTimes |> List.tryFind (fun e -> e.EntityId = rentOrderPosition.EntityId) with
            | Some v -> v.DateTimes |> List.toArray
            | None -> [||]

        let isDisabled = false

        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> posNoToString
        let key = posNumber
        let actionElement =
            match rentOrderPosition.SubPosNo with
            | Some _ when not (isOptional rentOrderPosition state templateId) -> None
            | _ -> removeIcon dispatch rentOrderPosition.PosNo rentOrderPosition.SubPosNo |> Some
        let typeSelect = ReactSelect.selectWithoutLabel (SelectProps.templateProps rentOrderPosition state dispatch (isDisabled || rentOrderPosition.SubPosNo.IsSome) templateId)
        let entitySelect = ReactSelect.selectWithoutLabel (SelectProps.entityProps rentOrderPosition state dispatch isDisabled templateId)
        let info = entityInfoIcon state rentOrderPosition
        let startDate =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                          Some rentOrderPosition.PlannedDeliveryDate,
                          (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                          startDate = rentOrderPosition.PlannedDeliveryDate,
                          endDate = rentOrderPosition.PlannedReturnDate,
                          maxDate = System.DateTime.UtcNow.Date,
                          excludedDates = excludedDateTimes,
                          isDisabled = rentOrderPosition.SubPosNo.IsSome)
            else
                ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                          rentOrderPosition.PlannedDeliveryDate
                          (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo)))
                          rentOrderPosition.PlannedDeliveryDate
                          rentOrderPosition.PlannedReturnDate
                          DateTime.UtcNow.Date
                          excludedDateTimes
                          (state.WithoutDelivery || rentOrderPosition.SubPosNo.IsSome)
        let endDate =
            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                      rentOrderPosition.PlannedReturnDate
                      (if rentOrderPosition.SubPosNo.IsSome then ignore
                       else (fun v -> dispatch (SetPositionEndDate (v, rentOrderPosition.PosNo))))
                      rentOrderPosition.PlannedDeliveryDate
                      rentOrderPosition.PlannedReturnDate
                      rentOrderPosition.PlannedDeliveryDate
                      excludedDateTimes
                      false
        let state = None
        let comment = Form.Input.inlineInput((fun ev -> dispatch (SetPositionComment (ev, rentOrderPosition.PosNo, rentOrderPosition.SubPosNo))), rentOrderPosition.Comment)

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery false
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn false
        let releaseDate = None

        row key className actionElement posNumber typeSelect entitySelect info startDate endDate state comment selfserviceDelivery selfserviceReturn releaseDate |> Some

    let rentOrderNewMaterialPositionRow (state : State) (dispatch : PositionTypes.Msg -> unit) (rentOrderPosition : RentOrderMaterialPosition) quantityValue =
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let actionElement =  removeIcon dispatch rentOrderPosition.PosNo rentOrderPosition.SubPosNo |> Some
        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString
        let key = posNumber
        let typeSelect = Html.span "Material"
        let entitySelect = ReactSelect.selectWithoutLabel (SelectProps.materialSelectProps rentOrderPosition state dispatch false)
        let materialUnit =
            state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
            |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue ""
        let info _ = Form.Input.inlineInput(
                        (fun value -> (value, rentOrderPosition.PosNo) |> SetUnitQuantity |> dispatch), quantityValue.Raw,
                        validation = (state.FormValidation, (rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString) + "_qty"),
                        className ="material-quantity", appendElement = materialUnit)

        let startDate =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                          Some rentOrderPosition.PlannedDeliveryDate,
                          (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                          startDate = rentOrderPosition.PlannedDeliveryDate,
                          endDate = rentOrderPosition.PlannedReturnDate,
                          maxDate = System.DateTime.UtcNow.Date,
                          isDisabled = rentOrderPosition.SubPosNo.IsSome)
            else
                ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                          rentOrderPosition.PlannedDeliveryDate
                          (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo)))
                          rentOrderPosition.PlannedDeliveryDate
                          rentOrderPosition.PlannedReturnDate
                          DateTime.UtcNow.Date
                          [| |]
                          (state.WithoutDelivery || rentOrderPosition.SubPosNo.IsSome)
        let endDate =
            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                      rentOrderPosition.PlannedReturnDate
                      (if rentOrderPosition.SubPosNo.IsSome then ignore
                       else (fun v -> dispatch (SetPositionEndDate (v, rentOrderPosition.PosNo))))
                      rentOrderPosition.PlannedDeliveryDate
                      rentOrderPosition.PlannedReturnDate
                      rentOrderPosition.PlannedDeliveryDate
                      [| |]
                      false

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery false
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn false
        let comment = Form.Input.inlineInput((fun ev -> dispatch (SetPositionComment (ev, rentOrderPosition.PosNo, rentOrderPosition.SubPosNo))), rentOrderPosition.Comment)

        row key className actionElement posNumber typeSelect entitySelect (info()) startDate endDate None comment selfserviceDelivery selfserviceReturn None |> Some
        
    let private filterByEntityName entity state row =
        match entity with
        | Some entity ->
            if entity.Name.Contains state.CurrentFilterEntityName then
                row |> Some
            else None
        | None -> row |> Some

    let rentOrderEditEntityPositionRow state dispatch (rentOrderPosition : RentOrderEntityPosition) templateId =
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let isPositionReleased = rentOrderPosition.State = RentOrderPositionState.Released
        let excludedDateTimes =
            match state.EntitiesNotAvailableDateTimes |> List.tryFind (fun e -> e.EntityId = rentOrderPosition.EntityId) with
            | Some v -> v.DateTimes |> List.toArray
            | None -> [||]
        let isAdmin = state.UserData.AssignedRoles |> List.contains RoleNames.Administrator
        let isReturnDateNotEditable =
            if isAdmin |> not then
                (isPositionReleased ||
                 (rentOrderPosition.ReleaseDate.IsSome && rentOrderPosition.State = AssignedToReturn || rentOrderPosition.State = RentOrderPositionState.Returned))
            else
                rentOrderPosition.State = RentOrderPositionState.AssignedToReturn ||
                rentOrderPosition.State = RentOrderPositionState.Released

        let isDisabled = rentOrderPosition.Id <> RentOrderPositionId System.Guid.Empty

        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> posNoToString
        let key = posNumber

        let typeSelect = ReactSelect.selectWithoutLabel (SelectProps.templateProps rentOrderPosition state dispatch (isDisabled || rentOrderPosition.SubPosNo.IsSome) templateId)
        let entitySelect = ReactSelect.selectWithoutLabel (SelectProps.entityProps rentOrderPosition state dispatch isDisabled templateId)
        let info = entityInfoIcon state rentOrderPosition
        let startDate =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some rentOrderPosition.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                    startDate = rentOrderPosition.PlannedDeliveryDate,
                    endDate = rentOrderPosition.PlannedReturnDate,
                    excludedDates = excludedDateTimes,
                    isDisabled = (rentOrderPosition.SubPosNo.IsSome || rentOrderPosition.State = RentOrderPositionState.Released || isDisabled)
                    )
            else
                if isAdmin && (rentOrderPosition.State = RentOrderPositionState.Returned || rentOrderPosition.State = RentOrderPositionState.Delivered) then
                    ReactDatepicker.Datepicker.datepicker(
                            rentOrderPosition.DeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = (match rentOrderPosition.ReturnDate with | Some returnDate -> returnDate | None -> rentOrderPosition.PlannedReturnDate),
                            maxDate = (match rentOrderPosition.ReturnDate with | Some returnDate -> returnDate | None -> rentOrderPosition.PlannedReturnDate),
                            excludedDates = excludedDateTimes)
                else
                    if rentOrderPosition.SelfserviceDelivery then
                        ReactDatepicker.Datepicker.datepicker(
                            Some rentOrderPosition.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = rentOrderPosition.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            excludedDates = excludedDateTimes,
                            isDisabled = (rentOrderPosition.SubPosNo.IsSome || rentOrderPosition.State <> RentOrderPositionState.AssignedToDeliver))
                    else
                        ReactDatepicker.Datepicker.datepicker(
                            Some rentOrderPosition.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = rentOrderPosition.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            excludedDates = excludedDateTimes,
                            isDisabled = (rentOrderPosition.SubPosNo.IsSome || rentOrderPosition.State <> RentOrderPositionState.Planned))

        let endDate =
            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                      (match rentOrderPosition.ReturnDate with | None -> rentOrderPosition.PlannedReturnDate | Some returnDate -> returnDate)
                      (if rentOrderPosition.SubPosNo.IsSome then ignore
                       else (fun v -> dispatch (SetPositionEndDate (v, rentOrderPosition.PosNo))))
                      rentOrderPosition.PlannedDeliveryDate
                      rentOrderPosition.PlannedReturnDate
                      rentOrderPosition.PlannedDeliveryDate
                      excludedDateTimes
                      isReturnDateNotEditable
        let rentOrderPositionState = rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> positionStateBadge |> Some
        let comment = Form.Input.inlineInput((fun ev -> dispatch (SetPositionComment (ev, rentOrderPosition.PosNo, rentOrderPosition.SubPosNo))), rentOrderPosition.Comment)
        let releaseDate =
            match rentOrderPosition.ReleaseDate with
            | None -> None
            | Some date -> ReactDatepicker.datepickerWithoutLabel date ignore true |> Some

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery (rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> isSelfserviceDeliveryCheckboxDisabled)
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn (rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> isSelfserviceReturnCheckboxDisabled)

        row key className None posNumber typeSelect entitySelect info startDate endDate rentOrderPositionState comment selfserviceDelivery selfserviceReturn releaseDate |> Some

    let rentOrderEditMaterialPositionRow (state : State) dispatch (rentOrderPosition : RentOrderMaterialPosition) quantityValue =
        let isAdmin = state.UserData.AssignedRoles |> List.contains RoleNames.Administrator

        let isDisabled = rentOrderPosition.Id <> RentOrderPositionId System.Guid.Empty
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let isPositionReleased = rentOrderPosition.State = RentOrderPositionState.Released

        let isNewMaterialRow = rentOrderPosition.Id = RentOrderPositionId System.Guid.Empty
        let materialUnit =
            state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
            |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue ""

        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString
        let key = posNumber
        let actionElement =
            if rentOrderPosition.State = RentOrderPositionState.Planned then
                removeIcon dispatch rentOrderPosition.PosNo rentOrderPosition.SubPosNo |> Some
            else None
        let typeSelect = Html.span "Material"
        let entitySelect =
            if isNewMaterialRow then
                ReactSelect.selectWithoutLabel (SelectProps.materialSelectProps rentOrderPosition state dispatch false)
            else
                let materialName =
                    state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
                    |> Option.map (fun m -> m.Name) |> Option.defaultValue "lädt..."
                Html.span materialName
        let info =
            if isNewMaterialRow then
                let materialUnit =
                    state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
                    |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue ""
                Form.Input.inlineInput(
                            (fun value -> (value, rentOrderPosition.PosNo) |> SetUnitQuantity |> dispatch), quantityValue.Raw,
                            validation = (state.FormValidation, (rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString) + "_qty"),
                            className ="material-quantity", appendElement = materialUnit)
            else
                Html.span (sprintf "%s %s" (rentOrderPosition.Quantity |> Helper.floatValueToString) materialUnit)

        let startDate =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some rentOrderPosition.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                    startDate = rentOrderPosition.PlannedDeliveryDate,
                    endDate = rentOrderPosition.PlannedReturnDate,
                    isDisabled = not isNewMaterialRow
                    )
            else
                if isAdmin && (rentOrderPosition.State = RentOrderPositionState.Returned || rentOrderPosition.State = RentOrderPositionState.Delivered) then
                    ReactDatepicker.Datepicker.datepicker(
                            rentOrderPosition.DeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = (match rentOrderPosition.ReturnDate with | Some returnDate -> returnDate | None -> rentOrderPosition.PlannedReturnDate),
                            maxDate = (match rentOrderPosition.ReturnDate with | Some returnDate -> returnDate | None -> rentOrderPosition.PlannedReturnDate))
                else
                    if rentOrderPosition.SelfserviceDelivery then
                        ReactDatepicker.Datepicker.datepicker(
                            Some rentOrderPosition.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = rentOrderPosition.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            isDisabled = (rentOrderPosition.SubPosNo.IsSome ))
                    else
                        ReactDatepicker.Datepicker.datepicker(
                            Some rentOrderPosition.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, rentOrderPosition.PosNo))),
                            startDate = rentOrderPosition.PlannedDeliveryDate,
                            endDate = rentOrderPosition.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            isDisabled = (rentOrderPosition.SubPosNo.IsSome || rentOrderPosition.State <> RentOrderPositionState.Planned))
        let endDate =
            ReactDatepicker.Datepicker.datepicker(
                      Some rentOrderPosition.PlannedReturnDate,
                      (if rentOrderPosition.SubPosNo.IsSome then ignore else (fun v -> dispatch (SetPositionEndDate (v, rentOrderPosition.PosNo)))),
                      startDate = rentOrderPosition.PlannedDeliveryDate, endDate = rentOrderPosition.PlannedReturnDate,
                      minDate = rentOrderPosition.PlannedDeliveryDate)
        let state = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> positionStateBadge |> Some
        let comment = Form.Input.inlineInput((fun ev -> dispatch (SetPositionComment (ev, rentOrderPosition.PosNo, rentOrderPosition.SubPosNo))), rentOrderPosition.Comment)
        let releaseDate =
            match rentOrderPosition.ReleaseDate with
            | None -> Html.span "" |> Some
            | Some date -> ReactDatepicker.datepickerWithoutLabel date ignore true |> Some

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery (rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> isSelfserviceDeliveryCheckboxDisabled)
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn (rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> isSelfserviceReturnCheckboxDisabled)

        row key className actionElement posNumber typeSelect entitySelect info startDate endDate state comment selfserviceDelivery selfserviceReturn releaseDate |> Some

    let rentOrderPositionRow (state : State) dispatch (rentOrderPosition : RentOrderEntityPosition) isChecked =
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let entity =
            state.Entities |> List.tryFind (fun e -> e.Id = rentOrderPosition.EntityId)
        let notActiveEntity =
            state.NotActiveEntities |> List.tryFind (fun e -> e.Id = rentOrderPosition.EntityId)
        let template =
            match entity with
            | Some entity ->
                state.Templates |> List.tryFind (fun t -> t.Id = entity.TemplateId)
            | None ->
                match notActiveEntity with
                | Some notActiveEntity ->
                    state.Templates |> List.tryFind (fun t -> t.Id = notActiveEntity.TemplateId)
                | None -> None

        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> posNoToString
        let key = posNumber

        let checkBox =
            match state.ComponentState with
            | ComponentState.NewPosition
            | ComponentState.EditPositions -> None
            | ComponentState.Show ->
                Form.checkBoxWithoutLabel (fun _ -> (rentOrderPosition.PosNo, rentOrderPosition.SubPosNo) |> SelectRentOrderPosition |> dispatch) isChecked false |> Some

        let typeSelect = Html.span (match template with | Some t -> t.TemplateBaseInformation.Name | None -> "lädt...")
        let entitySelect = Html.span (match entity with | Some entity -> entity.Name | None -> match notActiveEntity with | Some entity -> entity.Name | None -> "lädt...")
        let info = entityInfoIcon state rentOrderPosition
        let startDate =
            match rentOrderPosition.DeliveryDate with
            | Some deliveryDate ->
                if deliveryDate = rentOrderPosition.PlannedDeliveryDate then
                    Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Lieferdatum: %s" (rentOrderPosition.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (rentOrderPosition.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
        let endDate =
            match rentOrderPosition.ReturnDate with
            | Some returnDate ->
                if returnDate = rentOrderPosition.PlannedReturnDate then
                    Html.span (returnDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Abholdatum: %s" (rentOrderPosition.PlannedReturnDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (returnDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (rentOrderPosition.PlannedReturnDate.ToString("dd.MM.yyyy"))
        let rentOrderPositionState = rentOrderPosition |> RentOrderPosition.RentOrderEntityPosition |> positionStateBadge |> Some
        let comment = Html.span rentOrderPosition.Comment
        let releaseDate =
            match rentOrderPosition.ReleaseDate with
            | Some date -> Html.span (date.ToString("dd.MM.yyyy")) |> Some
            | None -> None

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery true
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn true
        
        let rentOrderPosState = rentOrderPosition|> RentOrderPosition.RentOrderEntityPosition |> Helper.Position.state 
        
        let rowFilteredByName =
            match entity with
            | Some entity ->
                if entity.Name.ToLower().Contains (state.CurrentFilterEntityName.ToLower()) then
                    row key className checkBox posNumber typeSelect entitySelect info startDate endDate rentOrderPositionState comment selfserviceDelivery selfserviceReturn releaseDate |> Some
                else None
            | None -> row key className checkBox posNumber typeSelect entitySelect info startDate endDate rentOrderPositionState comment selfserviceDelivery selfserviceReturn releaseDate |> Some
        match state.CurrentFilterState  with
        | Some currentFilteredState ->
            if currentFilteredState = rentOrderPosState then
                rowFilteredByName
            else None
        | None -> rowFilteredByName
        
    let rentOrderMaterialPositionRow (state : State) (dispatch : PositionTypes.Msg -> unit) (rentOrderPosition : RentOrderMaterialPosition) isChecked =
        let className = rowWarningClassName state rentOrderPosition.PosNo rentOrderPosition.SubPosNo
        let material =
            state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
        let materialName =
            material
            |> Option.map (fun m -> m.Name) |> Option.defaultValue "lädt..."
        let materialUnit =
            state.Materials |> List.tryFind (fun m -> m.Id = rentOrderPosition.MaterialId)
            |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue "lädt..."
        let posNumber = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString
        let key = posNumber

        let checkBox =
            match state.ComponentState with
            | ComponentState.NewPosition
            | ComponentState.EditPositions -> None
            | ComponentState.Show ->
                Form.checkBoxWithoutLabel (fun _ -> (rentOrderPosition.PosNo, rentOrderPosition.SubPosNo) |> SelectRentOrderPosition |> dispatch) isChecked false |> Some

        let typeSelect = Html.span "Material"
        let entitySelect = Html.span materialName
        let info = Html.span (sprintf "%s %s" (rentOrderPosition.Quantity |> Helper.floatValueToString) materialUnit)
        let startDate =
            match rentOrderPosition.DeliveryDate with
            | Some deliveryDate ->
                if deliveryDate = rentOrderPosition.PlannedDeliveryDate then
                    Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Lieferdatum: %s" (rentOrderPosition.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (rentOrderPosition.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
        let endDate =
            match rentOrderPosition.ReturnDate with
            | Some returnDate ->
                if returnDate = rentOrderPosition.PlannedReturnDate then
                    Html.span (returnDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Abholdatum: %s" (rentOrderPosition.PlannedReturnDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (returnDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (rentOrderPosition.PlannedReturnDate.ToString("dd.MM.yyyy"))
        let rentOrderPositionState = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> positionStateBadge |> Some
        let comment = Html.span rentOrderPosition.Comment
        let releaseDate =
            match rentOrderPosition.ReleaseDate with
            | Some date -> Html.span (date.ToString("dd.MM.yyyy")) |> Some
            | None -> Html.span "" |> Some

        let selfserviceDelivery = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceDelivery true
        let selfserviceReturn = Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, rentOrderPosition.PosNo) |> dispatch ) rentOrderPosition.SelfserviceReturn true
        
        let rentOrderPosState = rentOrderPosition |> RentOrderPosition.RentOrderMaterialPosition |> Helper.Position.state
     
        let rowFilteredByName =
           match material with
                | Some material ->
                    if material.Name.ToLower().Contains (state.CurrentFilterEntityName.ToLower()) then
                        row key className checkBox posNumber typeSelect entitySelect info startDate endDate rentOrderPositionState comment selfserviceDelivery selfserviceReturn releaseDate |> Some
                    else None
                | None -> row key className checkBox posNumber typeSelect entitySelect info startDate endDate rentOrderPositionState comment selfserviceDelivery selfserviceReturn releaseDate |> Some

        match state.CurrentFilterState  with
        | Some currentFilteredState ->
            if currentFilteredState = rentOrderPosState then
                rowFilteredByName
            else None
        | None -> rowFilteredByName
        
        

module PositionShared =
    let isSelfserviceDeliveryCheckboxDisabled (position : RentOrderPosition) =
        let currentState = position |> Helper.Position.state
        let selfserviceDelivery = position |> Helper.Position.selfserviceDelivery
        currentState = RentOrderPositionState.Delivered ||
        currentState = RentOrderPositionState.Returned ||
        (currentState = RentOrderPositionState.AssignedToDeliver && selfserviceDelivery |> not)

    let isSelfserviceReturnCheckboxDisabled (position : RentOrderPosition) =
        let currentState = position |> Helper.Position.state
        let selfserviceReturn = position |> Helper.Position.selfserviceReturn
        currentState = RentOrderPositionState.Returned ||
        (currentState = RentOrderPositionState.AssignedToReturn && selfserviceReturn |> not)

    let isAdmin state =
        state.UserData.AssignedRoles |> List.contains RoleNames.Administrator

module MaterialPosition =
    open Fable.React
    open Feliz

    let materialName (state : State) position =
        state.Materials |> List.tryFind (fun m -> m.Id = position.MaterialId)
        |> Option.map (fun m -> m.Name) |> Option.defaultValue "lädt..."

    let materialUnit (state : State) position =
        state.Materials |> List.tryFind (fun m -> m.Id = position.MaterialId)
        |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue "lädt..."

    let checkBox state dispatch (position : RentOrderMaterialPosition) isChecked =
        match state.ComponentState with
        | ComponentState.NewPosition
        | ComponentState.EditPositions -> Html.none
        | ComponentState.Show ->
            Form.checkBoxWithoutLabel (fun _ -> (position.PosNo, position.SubPosNo) |> SelectRentOrderPosition |> dispatch) isChecked false

    let posNumber position =
        position |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString

    let state position =
        position |> RentOrderPosition.RentOrderMaterialPosition |> TableRows.positionStateBadge

    let comment dispatch (position : RentOrderMaterialPosition)  =
        Form.Input.inlineInput(
            (fun ev -> dispatch (SetPositionComment (ev, position.PosNo, position.SubPosNo))),
            position.Comment)

    let releaseDate (position : RentOrderMaterialPosition) =
        match position.ReleaseDate with
        | Some date -> Html.span (date.ToString("dd.MM.yyyy"))
        | None -> Html.span ""

    module Show =
        let info (state : State) position =
            let materialUnit =
                state.Materials |> List.tryFind (fun m -> m.Id = position.MaterialId)
                |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue "lädt..."

            Html.span (sprintf "%s %s" (position.Quantity |> Helper.floatValueToString) materialUnit)

        let deliveryDate (position : RentOrderMaterialPosition) =
            match position.DeliveryDate with
            | Some deliveryDate ->
                if deliveryDate = position.PlannedDeliveryDate then
                    Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Lieferdatum: %s" (position.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (position.PlannedDeliveryDate.ToString("dd.MM.yyyy"))

        let returnDate (position : RentOrderMaterialPosition) =
            match position.ReturnDate with
            | Some returnDate ->
                if returnDate = position.PlannedReturnDate then
                    Html.span (returnDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Abholdatum: %s" (position.PlannedReturnDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (returnDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (position.PlannedReturnDate.ToString("dd.MM.yyyy"))

        let selfserviceDelivery dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch ) position.SelfserviceDelivery true

        let selfserviceReturn dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch ) position.SelfserviceReturn true

    module New =
        let removeIcon dispatch (position : RentOrderMaterialPosition) =
            TableRows.removeIcon dispatch position.PosNo position.SubPosNo

        let materialName state dispatch position =
            ReactSelect.selectWithoutLabel (SelectProps.materialSelectProps position state dispatch false)

        let info (state : State) dispatch position value =
            let materialUnit =
                state.Materials |> List.tryFind (fun m -> m.Id = position.MaterialId)
                |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue ""

            Form.Input.inlineInput(
                (fun value -> (value, position.PosNo) |> SetUnitQuantity |> dispatch), value.Raw,
                validation = (state.FormValidation, (position |> RentOrderPosition.RentOrderMaterialPosition |> posNoToString) + "_qty"),
                className ="material-quantity", appendElement = materialUnit)

        let deliveryDate (state : State) dispatch position =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some position.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                    startDate = position.PlannedDeliveryDate,
                    endDate = position.PlannedReturnDate,
                    maxDate = System.DateTime.UtcNow.Date,
                    isDisabled = position.SubPosNo.IsSome)
            else
                ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                    position.PlannedDeliveryDate
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo)))
                    position.PlannedDeliveryDate
                    position.PlannedReturnDate
                    DateTime.UtcNow.Date
                    [| |]
                    (state.WithoutDelivery || position.SubPosNo.IsSome)

        let returnDate dispatch position =
            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                position.PlannedReturnDate
                (if position.SubPosNo.IsSome then ignore
                 else (fun v -> dispatch (SetPositionEndDate (v, position.PosNo))))
                position.PlannedDeliveryDate
                position.PlannedReturnDate
                position.PlannedDeliveryDate
                [| |]
                false

        let selfserviceDelivery dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch)
                position.SelfserviceDelivery false

        let selfserviceReturn dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch)
                position.SelfserviceReturn false

    module Edit =
        let info (state : State) position =
            let materialUnit =
                state.Materials |> List.tryFind (fun m -> m.Id = position.MaterialId)
                |> Option.map (fun m -> m.Unit |> Helper.unitToShortString) |> Option.defaultValue ""

            Html.span (sprintf "%s %s" (position.Quantity |> Helper.floatValueToString) materialUnit)

        let deliveryDate (state : State) dispatch position =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some position.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                    startDate = position.PlannedDeliveryDate,
                    endDate = position.PlannedReturnDate,
                    isDisabled = true)
            else
                if PositionShared.isAdmin state && (position.State = RentOrderPositionState.Returned || position.State = RentOrderPositionState.Delivered) then
                    ReactDatepicker.Datepicker.datepicker(
                        position.DeliveryDate,
                        (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                        startDate = position.PlannedDeliveryDate,
                        endDate = (match position.ReturnDate with | Some returnDate -> returnDate | None -> position.PlannedReturnDate),
                        maxDate = (match position.ReturnDate with | Some returnDate -> returnDate | None -> position.PlannedReturnDate))
                else
                    if position.SelfserviceDelivery then
                        ReactDatepicker.Datepicker.datepicker(
                            Some position.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                            startDate = position.PlannedDeliveryDate,
                            endDate = position.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            isDisabled = (position.SubPosNo.IsSome ))
                    else
                        ReactDatepicker.Datepicker.datepicker(
                            Some position.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                            startDate = position.PlannedDeliveryDate,
                            endDate = position.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            isDisabled = (position.SubPosNo.IsSome || position.State <> RentOrderPositionState.Planned))

        let returnDate dispatch position =
            ReactDatepicker.Datepicker.datepicker(
                Some position.PlannedReturnDate,
                (if position.SubPosNo.IsSome then ignore else (fun v -> dispatch (SetPositionEndDate (v, position.PosNo)))),
                startDate = position.PlannedDeliveryDate, endDate = position.PlannedReturnDate,
                minDate = position.PlannedDeliveryDate)

        let selfserviceDelivery dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch)
                position.SelfserviceDelivery
                (position |> RentOrderPosition.RentOrderMaterialPosition |> PositionShared.isSelfserviceDeliveryCheckboxDisabled)

        let selfserviceReturn dispatch (position : RentOrderMaterialPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch)
                position.SelfserviceReturn
                (position |> RentOrderPosition.RentOrderMaterialPosition |> PositionShared.isSelfserviceReturnCheckboxDisabled)

module EntityPosition =
    open Fable.React
    open Feliz

    let entity (state : State) (position : RentOrderEntityPosition) =
        state.Entities |> List.tryFind (fun e -> e.Id = position.EntityId)

    let notActiveEntity (state : State) (position : RentOrderEntityPosition) =
        state.NotActiveEntities |> List.tryFind (fun e -> e.Id = position.EntityId)

    let template (state : State) position =
        match entity state position with
        | Some entity ->
            state.Templates |> List.tryFind (fun t -> t.Id = entity.TemplateId)
        | None ->
            match notActiveEntity state position with
            | Some notActiveEntity ->
                state.Templates |> List.tryFind (fun t -> t.Id = notActiveEntity.TemplateId)
            | None -> None

    let posNumber position = position |> RentOrderPosition.RentOrderEntityPosition |> posNoToString

    let templateColumn state position =
        let template = template state position
        Html.span (match template with | Some t -> t.TemplateBaseInformation.Name | None -> "lädt...")

    let entityColumn state position =
        let entity = entity state position
        Html.span (match entity with | Some entity -> entity.Name | None -> match notActiveEntity state position with | Some entity -> entity.Name | None -> "lädt...")

    let info state position =
        TableRows.entityInfoIcon state position

    let state position =
        position |> RentOrderPosition.RentOrderEntityPosition |> TableRows.positionStateBadge

    let comment dispatch (position : RentOrderEntityPosition) =
        Form.Input.inlineInput(
            (fun ev -> dispatch (SetPositionComment (ev, position.PosNo, position.SubPosNo))),
            position.Comment)

    let releaseDate (position : RentOrderEntityPosition) =
        match position.ReleaseDate with
        | Some date -> Html.span (date.ToString("dd.MM.yyyy"))
        | None -> Html.none

    module Show =
        let checkBox state dispatch (position : RentOrderEntityPosition) isChecked =
            match state.ComponentState with
            | ComponentState.NewPosition
            | ComponentState.EditPositions -> Html.none
            | ComponentState.Show ->
                Form.checkBoxWithoutLabel (fun _ -> (position.PosNo, position.SubPosNo) |> SelectRentOrderPosition |> dispatch) isChecked false

        let startDate (position : RentOrderEntityPosition) =
            match position.DeliveryDate with
            | Some deliveryDate ->
                if deliveryDate = position.PlannedDeliveryDate then
                    Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Lieferdatum: %s" (position.PlannedDeliveryDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (deliveryDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (position.PlannedDeliveryDate.ToString("dd.MM.yyyy"))

        let returnDate (position : RentOrderEntityPosition) =
            match position.ReturnDate with
            | Some returnDate ->
                if returnDate = position.PlannedReturnDate then
                    Html.span (returnDate.ToString("dd.MM.yyyy"))
                else
                    let tooltipText = sprintf "Geplantes Abholdatum: %s" (position.PlannedReturnDate.ToString("dd.MM.yyyy"))
                    Html.span [
                        Html.span (returnDate.ToString("dd.MM.yyyy"))
                        Html.i [
                            prop.custom ("data-tip", tooltipText)
                            prop.className "fas fa-info-circle date-icon"
                        ]
                        ReactTooltip.tooltip [ ]
                    ]
            | None -> Html.span (position.PlannedReturnDate.ToString("dd.MM.yyyy"))

        let selfserviceDelivery dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch ) position.SelfserviceDelivery true

        let selfserviceReturn dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch ) position.SelfserviceReturn true

    module Edit =
        let isPositionReleased (position : RentOrderEntityPosition) =
            position.State = RentOrderPositionState.Released

        let excludedDateTimes state (position : RentOrderEntityPosition) =
            match state.EntitiesNotAvailableDateTimes |> List.tryFind (fun e -> e.EntityId = position.EntityId) with
            | Some v -> v.DateTimes |> List.toArray
            | None -> [| |]

        let deliveryDate (state : State) dispatch (position : RentOrderEntityPosition) isDisabled =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some position.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                    startDate = position.PlannedDeliveryDate,
                    endDate = position.PlannedReturnDate,
                    excludedDates = excludedDateTimes state position,
                    isDisabled = (position.SubPosNo.IsSome || position.State = RentOrderPositionState.Released || isDisabled)
                    )
            else
                if (PositionShared.isAdmin state) && (position.State = RentOrderPositionState.Returned || position.State = RentOrderPositionState.Delivered) then
                    ReactDatepicker.Datepicker.datepicker(
                        position.DeliveryDate,
                        (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                        startDate = position.PlannedDeliveryDate,
                        endDate = (match position.ReturnDate with | Some returnDate -> returnDate | None -> position.PlannedReturnDate),
                        maxDate = (match position.ReturnDate with | Some returnDate -> returnDate | None -> position.PlannedReturnDate),
                        excludedDates = excludedDateTimes state position)
                else
                    if position.SelfserviceDelivery then
                        ReactDatepicker.Datepicker.datepicker(
                            Some position.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                            startDate = position.PlannedDeliveryDate,
                            endDate = position.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            excludedDates = excludedDateTimes state position,
                            isDisabled = (position.SubPosNo.IsSome || position.State <> RentOrderPositionState.AssignedToDeliver))
                    else
                        ReactDatepicker.Datepicker.datepicker(
                            Some position.PlannedDeliveryDate,
                            (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                            startDate = position.PlannedDeliveryDate,
                            endDate = position.PlannedReturnDate,
                            minDate = System.DateTime.UtcNow.Date,
                            excludedDates = excludedDateTimes state position,
                            isDisabled = (position.SubPosNo.IsSome || position.State <> RentOrderPositionState.Planned))

        let returnDate (state : State) dispatch (position : RentOrderEntityPosition) =
            let isDisabled =
                if PositionShared.isAdmin state |> not then
                    (isPositionReleased position ||
                     (position.ReleaseDate.IsSome && position.State = AssignedToReturn || position.State = RentOrderPositionState.Returned))
                else
                    position.State = RentOrderPositionState.AssignedToReturn ||
                    position.State = RentOrderPositionState.Released

            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                (match position.ReturnDate with | None -> position.PlannedReturnDate | Some returnDate -> returnDate)
                (if position.SubPosNo.IsSome then ignore
                 else (fun v -> dispatch (SetPositionEndDate (v, position.PosNo))))
                position.PlannedDeliveryDate
                position.PlannedReturnDate
                position.PlannedDeliveryDate
                (excludedDateTimes state position)
                isDisabled

        let selfserviceDelivery dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch )
                position.SelfserviceDelivery
                (position |> RentOrderPosition.RentOrderEntityPosition |> PositionShared.isSelfserviceDeliveryCheckboxDisabled)

        let selfserviceReturn dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch )
                position.SelfserviceReturn
                (position |> RentOrderPosition.RentOrderEntityPosition |> PositionShared.isSelfserviceReturnCheckboxDisabled)

    module New =
        let removeIcon state dispatch (position : RentOrderEntityPosition) templateId =
            match position.SubPosNo with
            | Some _ when not (TableRows.isOptional position state templateId) ->
                Html.none
            | _ ->
                TableRows.removeIcon dispatch position.PosNo position.SubPosNo

        let templateColumn state dispatch position templateId =
            ReactSelect.selectWithoutLabel (SelectProps.templateProps position state dispatch position.SubPosNo.IsSome templateId)

        let entityColumn state dispatch position templateId =
            ReactSelect.selectWithoutLabel (SelectProps.entityProps position state dispatch false templateId)

        let private excludedDateTimes state (position : RentOrderEntityPosition) =
            match state.EntitiesNotAvailableDateTimes |> List.tryFind (fun e -> e.EntityId = position.EntityId) with
            | Some v -> v.DateTimes |> List.toArray
            | None -> [||]

        let deliveryDate (state : State) dispatch (position : RentOrderEntityPosition) =
            if state.WithoutDelivery then
                ReactDatepicker.Datepicker.datepicker(
                    Some position.PlannedDeliveryDate,
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo))),
                    startDate = position.PlannedDeliveryDate,
                    endDate = position.PlannedReturnDate,
                    maxDate = System.DateTime.UtcNow.Date,
                    excludedDates = (excludedDateTimes state position),
                    isDisabled = position.SubPosNo.IsSome)
            else
                ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                    position.PlannedDeliveryDate
                    (fun v -> dispatch (SetPositionStartDate (v, position.PosNo)))
                    position.PlannedDeliveryDate
                    position.PlannedReturnDate
                    DateTime.UtcNow.Date
                    (excludedDateTimes state position)
                    (state.WithoutDelivery || position.SubPosNo.IsSome)

        let returnDate state dispatch (position : RentOrderEntityPosition) =
            ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                position.PlannedReturnDate
                (if position.SubPosNo.IsSome then ignore
                else (fun v -> dispatch (SetPositionEndDate (v, position.PosNo))))
                position.PlannedDeliveryDate
                position.PlannedReturnDate
                position.PlannedDeliveryDate
                (excludedDateTimes state position)
                false

        let selfserviceDelivery dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceDelivery (ev.Checked, position.PosNo) |> dispatch )
                position.SelfserviceDelivery false

        let selfserviceReturn dispatch (position : RentOrderEntityPosition) =
            Form.checkBoxWithoutLabel
                (fun ev -> SetPositionSelfserviceReturn (ev.Checked, position.PosNo) |> dispatch )
                position.SelfserviceReturn false

        let releaseDate = None

module Column =
    open Feliz

    let checkBox (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, isChecked) -> EntityPosition.Show.checkBox state dispatch pos isChecked
        | PositionRow.NewEntityPosition(pos, templateId) -> EntityPosition.New.removeIcon state dispatch pos templateId
        | PositionRow.EditEntityPosition(pos, templateId) -> Html.none
        | PositionRow.ShowMaterialPosition(pos, isChecked) -> MaterialPosition.checkBox state dispatch pos isChecked
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.removeIcon dispatch pos
        | PositionRow.EditMaterialPosition(pos, value) -> Html.none

    let posNo (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> Html.text (EntityPosition.posNumber pos)
        | PositionRow.NewEntityPosition(pos, _) -> Html.text (EntityPosition.posNumber pos)
        | PositionRow.EditEntityPosition(pos, _) -> Html.text (EntityPosition.posNumber pos)
        | PositionRow.ShowMaterialPosition(pos, _) -> Html.text (MaterialPosition.posNumber pos)
        | PositionRow.NewMaterialPosition(pos, _) -> Html.text (MaterialPosition.posNumber pos)
        | PositionRow.EditMaterialPosition(pos, _) -> Html.text (MaterialPosition.posNumber pos)

    let template (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.templateColumn state pos
        | PositionRow.NewEntityPosition(pos, templateId) -> EntityPosition.New.templateColumn state dispatch pos templateId
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.templateColumn state pos
        | PositionRow.ShowMaterialPosition _ -> Html.span "Material"
        | PositionRow.NewMaterialPosition _ -> Html.span "Material"
        | PositionRow.EditMaterialPosition _ -> Html.span "Material"

    let entity (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.entityColumn state pos
        | PositionRow.NewEntityPosition(pos, templateId) -> EntityPosition.New.entityColumn state dispatch pos templateId
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.entityColumn state pos
        | PositionRow.ShowMaterialPosition(pos, _) -> Html.text (MaterialPosition.materialName state pos)
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.materialName state dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> Html.text (MaterialPosition.materialName state pos)

    let info (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.info state pos
        | PositionRow.NewEntityPosition _ -> Html.none
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.info state pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.Show.info state pos
        | PositionRow.NewMaterialPosition(pos, value) -> MaterialPosition.New.info state dispatch pos value
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.Edit.info state pos

    let deliveryDate (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.Show.startDate pos
        | PositionRow.NewEntityPosition(pos, _) -> EntityPosition.New.deliveryDate state dispatch pos
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.Edit.deliveryDate state dispatch pos false
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.Show.deliveryDate pos
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.deliveryDate state dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.Edit.deliveryDate state dispatch pos

    let returnDate (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.Show.returnDate pos
        | PositionRow.NewEntityPosition(pos, _) -> EntityPosition.New.returnDate state dispatch pos
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.Edit.returnDate state dispatch pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.Show.returnDate pos
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.returnDate dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.Edit.returnDate dispatch pos

    let selfserviceDelivery (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.Show.selfserviceDelivery dispatch pos
        | PositionRow.NewEntityPosition(pos, _) -> EntityPosition.New.selfserviceDelivery dispatch pos
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.Edit.selfserviceDelivery dispatch pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.Show.selfserviceDelivery dispatch pos
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.selfserviceDelivery dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.Edit.selfserviceDelivery dispatch pos

    let selfserviceReturn (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.Show.selfserviceReturn dispatch pos
        | PositionRow.NewEntityPosition(pos, _) -> EntityPosition.New.selfserviceReturn dispatch pos
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.Edit.selfserviceReturn dispatch pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.Show.selfserviceReturn dispatch pos
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.New.selfserviceReturn dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.Edit.selfserviceReturn dispatch pos

    let state (state : State) (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, isChecked) -> EntityPosition.state pos
        | PositionRow.NewEntityPosition(pos, templateId) -> EntityPosition.state pos
        | PositionRow.EditEntityPosition(pos, templateId) -> EntityPosition.state pos
        | PositionRow.ShowMaterialPosition(pos, isChecked) -> MaterialPosition.state pos
        | PositionRow.NewMaterialPosition(pos, value) -> MaterialPosition.state pos
        | PositionRow.EditMaterialPosition(pos, value) -> MaterialPosition.state pos

    let comment (state : State) dispatch (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> Html.span pos.Comment
        | PositionRow.NewEntityPosition(pos, _) -> EntityPosition.comment dispatch pos
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.comment dispatch pos
        | PositionRow.ShowMaterialPosition(pos, _) -> Html.span pos.Comment
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.comment dispatch pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.comment dispatch pos

    let releaseDate (state : State) (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> EntityPosition.releaseDate pos
        | PositionRow.NewEntityPosition _ -> Html.none
        | PositionRow.EditEntityPosition(pos, _) -> EntityPosition.releaseDate pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.releaseDate pos
        | PositionRow.NewMaterialPosition _ -> Html.none
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.releaseDate pos

module SortFunction =
    open Feliz

    let posNo = function
        | PositionRow.ShowEntityPosition(pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> Helper.posNoToString
        | PositionRow.NewEntityPosition(pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> Helper.posNoToString
        | PositionRow.EditEntityPosition(pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> Helper.posNoToString
        | PositionRow.ShowMaterialPosition(pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> Helper.posNoToString
        | PositionRow.NewMaterialPosition(pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> Helper.posNoToString
        | PositionRow.EditMaterialPosition(pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> Helper.posNoToString

    let template state position =
        let templateName pos =
            let template = EntityPosition.template state pos
            match template with | Some t -> t.TemplateBaseInformation.Name | None -> ""
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> templateName pos
        | PositionRow.NewEntityPosition(pos, _) -> templateName pos
        | PositionRow.EditEntityPosition(pos, _) -> templateName pos
        | PositionRow.ShowMaterialPosition _
        | PositionRow.NewMaterialPosition _
        | PositionRow.EditMaterialPosition _ -> "Material"

    let entity (state : State) (position : PositionRow) =
        let entityName position =
            let entity = EntityPosition.entity state position
            match entity with | Some entity -> entity.Name | None -> match EntityPosition.notActiveEntity state position with | Some entity -> entity.Name | None -> ""

        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> entityName pos
        | PositionRow.NewEntityPosition(pos, templateId) -> entityName pos
        | PositionRow.EditEntityPosition(pos, _) -> entityName pos
        | PositionRow.ShowMaterialPosition(pos, _) -> MaterialPosition.materialName state pos
        | PositionRow.NewMaterialPosition(pos, _) -> MaterialPosition.materialName state pos
        | PositionRow.EditMaterialPosition(pos, _) -> MaterialPosition.materialName state pos

    let deliveryDate (position : PositionRow) =
        let entityDeliveryDate (position : RentOrderEntityPosition) =
            match position.DeliveryDate with
            | Some deliveryDate -> deliveryDate
            | None -> position.PlannedDeliveryDate
        let materialDeliveryDate (position : RentOrderMaterialPosition) =
            match position.DeliveryDate with
            | Some deliveryDate -> deliveryDate
            | None -> position.PlannedDeliveryDate

        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> entityDeliveryDate pos
        | PositionRow.NewEntityPosition(pos, _) -> entityDeliveryDate pos
        | PositionRow.EditEntityPosition(pos, _) -> entityDeliveryDate pos
        | PositionRow.ShowMaterialPosition(pos, _) -> materialDeliveryDate pos
        | PositionRow.NewMaterialPosition(pos, _) -> materialDeliveryDate pos
        | PositionRow.EditMaterialPosition(pos, _) -> materialDeliveryDate pos

    let returnDate (position : PositionRow) =
        let entityReturnDate (position : RentOrderEntityPosition) =
            match position.ReturnDate with
            | Some returnDate -> returnDate
            | None -> position.PlannedReturnDate
        let materialReturnDate (position : RentOrderMaterialPosition) =
            match position.ReturnDate with
            | Some returnDate -> returnDate
            | None -> position.PlannedReturnDate

        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> entityReturnDate pos
        | PositionRow.NewEntityPosition(pos, _) -> entityReturnDate pos
        | PositionRow.EditEntityPosition(pos, _) -> entityReturnDate pos
        | PositionRow.ShowMaterialPosition(pos, _) -> materialReturnDate pos
        | PositionRow.NewMaterialPosition(pos, _) -> materialReturnDate pos
        | PositionRow.EditMaterialPosition(pos, _) -> materialReturnDate pos

    let selfserviceDelivery = function
        | PositionRow.ShowEntityPosition(pos, _) -> pos.SelfserviceDelivery.ToString()
        | PositionRow.NewEntityPosition(pos, _) -> pos.SelfserviceDelivery.ToString()
        | PositionRow.EditEntityPosition(pos, _) -> pos.SelfserviceDelivery.ToString()
        | PositionRow.ShowMaterialPosition(pos, _) -> pos.SelfserviceDelivery.ToString()
        | PositionRow.NewMaterialPosition(pos, _) -> pos.SelfserviceDelivery.ToString()
        | PositionRow.EditMaterialPosition(pos, _) -> pos.SelfserviceDelivery.ToString()

    let selfserviceReturn = function
        | PositionRow.ShowEntityPosition(pos, _) -> pos.SelfserviceReturn.ToString()
        | PositionRow.NewEntityPosition(pos, _) -> pos.SelfserviceReturn.ToString()
        | PositionRow.EditEntityPosition(pos, _) -> pos.SelfserviceReturn.ToString()
        | PositionRow.ShowMaterialPosition(pos, _) -> pos.SelfserviceReturn.ToString()
        | PositionRow.NewMaterialPosition(pos, _) -> pos.SelfserviceReturn.ToString()
        | PositionRow.EditMaterialPosition(pos, _) -> pos.SelfserviceReturn.ToString()

    let state = function
        | PositionRow.ShowEntityPosition(pos, _) -> pos.State |> Helper.Position.stateToString
        | PositionRow.NewEntityPosition(pos, _) -> pos.State |> Helper.Position.stateToString
        | PositionRow.EditEntityPosition(pos, _) -> pos.State |> Helper.Position.stateToString
        | PositionRow.ShowMaterialPosition(pos, _) -> pos.State |> Helper.Position.stateToString
        | PositionRow.NewMaterialPosition(pos, _) -> pos.State |> Helper.Position.stateToString
        | PositionRow.EditMaterialPosition(pos, _) -> pos.State |> Helper.Position.stateToString

    let comment (position : PositionRow) =
        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> pos.Comment
        | PositionRow.NewEntityPosition(pos, _) -> pos.Comment
        | PositionRow.EditEntityPosition(pos, _) -> pos.Comment
        | PositionRow.ShowMaterialPosition(pos, _) -> pos.Comment
        | PositionRow.NewMaterialPosition(pos, _) -> pos.Comment
        | PositionRow.EditMaterialPosition(pos, _) -> pos.Comment

    let releaseDate (position : PositionRow) =
        let entityReleaseDate (position : RentOrderEntityPosition) =
            match position.ReleaseDate with
            | Some releaseDate -> releaseDate
            | None -> position.PlannedReturnDate
        let materialReleaseDate (position : RentOrderMaterialPosition) =
            match position.ReleaseDate with
            | Some releaseDate -> releaseDate
            | None -> position.PlannedReturnDate

        match position with
        | PositionRow.ShowEntityPosition(pos, _) -> entityReleaseDate pos
        | PositionRow.NewEntityPosition (pos, _) -> entityReleaseDate pos
        | PositionRow.EditEntityPosition(pos, _) -> entityReleaseDate pos
        | PositionRow.ShowMaterialPosition(pos, _) -> materialReleaseDate pos
        | PositionRow.NewMaterialPosition (pos, _) -> materialReleaseDate pos
        | PositionRow.EditMaterialPosition(pos, _) -> materialReleaseDate pos

open Feliz
open Sww.Frontend.ReactTable
// open Fable.React

let positionTable (rentOrderPositions : PositionRow list) (state : State) (dispatch : PositionTypes.Msg -> unit) =
    let editPositionColumns =
        match state.ComponentState with
        | ComponentState.NewPosition
        | ComponentState.EditPositions ->
            [ //th [ Scope "col"
                //     Class "info-col" ]
                //  [ str "" ]
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.children [
                        Html.text "Lieferdatum"
                        ReactDatepicker.customDatepickerWithoutLabel
                            state.DeliveryDateForAllPositions
                            (SetDeliveryDateToAllPositions >> dispatch)
                            true
                            [ ReactDatepicker.CommonProps.MinDate System.DateTime.UtcNow ]
                    ]
                ]
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.text "Selbstabholung"
                ]
                Html.th [
                // Scope "col"
                    prop.className"return-date-col"
                    prop.children [
                        Html.text "Abholdatum (voraussichtlich)"
                        ReactDatepicker.customDatepickerWithoutLabel
                              state.ReturnDateForAllPositions
                              (SetReturnDateToAllPositions >> dispatch)
                              (state.Positions |> returnDateForAllPositionsIsDisabled)
                              [ ReactDatepicker.CommonProps.MinDate state.DeliveryDateForAllPositions ]
                    ]
                ]
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.text "Selbstrückgabe"
                ]
            ]

        | _ ->
            [
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.text "Lieferdatum"
                ]
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.text "Selbstabholung"
                ]
                Html.th [
                    // Scope "col"
                    prop.className "return-date-col"
                    prop.text "Abholdatum (voraussichtlich)"
                ]
                Html.th [
                    // Scope "col"
                    prop.className "delivery-date-col"
                    prop.text "Selbstrückgabe"
                ]
            ]

    let tableHeader =
        seq {
            Html.th [
                // prop.scope "col"
                prop.className "actions-col"
                prop.text ""
            ]
            Html.th [
                // Scope "col"
                prop.className "position-col"
                prop.text "Pos"
            ]
            Html.th [
                // Scope "col"
                prop.className "template-col"
                prop.text "Geräte-Typ"
            ]
            Html.th [
                // Scope "col"
                prop.className "entity-col"
                prop.children [
                    Html.text "Gerät"
                    Html.input [
                        prop.id "input-entity-filter"
                        prop.className "form-control"
                        prop.custom ("data-cy", "login-username-input")
                        prop.valueOrDefault state.CurrentFilterEntityName
                        prop.onChange (FilterEntityName >> dispatch)
                        prop.placeholder "Gerät"
                    ]
                ] 
            ]
            Html.th [
                // Scope "col"
            ]
            yield! editPositionColumns
            Html.th [
                // Scope "col"
                prop.children [
                    Html.text "Status"
                    ReactSelect.selectWithoutLabel (SelectProps.filterStateProps state dispatch)
                ]
            ]
            Html.th [
                // Scope "col"
                prop.text "Kommentar"
            ]
            Html.th [
                // Scope "col"
                prop.text "Freigemeldet für"
            ]
        } |> List.ofSeq


    Html.div [
        prop.className "table"
        prop.children [
            // reactTable [
            //     tableProp.keyField "Id"
            //     tableProp.rows rentOrderPositions
            //     tableProp.tableKey "rentorders"
            //     tableProp.tableExportName "mietauftraege_export.csv"
            //     tableProp.pagination false
            //     tableProp.exportCSV false
            //     tableProp.columns [
            //         tableColumn [
            //             colProp.selector "Action"
            //             colProp.label ""
            //             colProp.formatter (Custom (fun _ pos -> Column.checkBox state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Pos"
            //             colProp.label "Pos"
            //             colProp.sortFunction (CustomNaturalSort SortFunction.posNo)
            //             colProp.formatter (Custom (fun _ pos -> Column.posNo pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Template"
            //             colProp.label "Geräte-Typ"
            //             colProp.sortFunction (CustomNaturalSort (SortFunction.template state))
            //             colProp.filter (CustomTextFilter (SortFunction.template state))
            //             colProp.formatter (Custom (fun _ pos -> Column.template state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Entity"
            //             colProp.label "Gerät"
            //             colProp.sortFunction (CustomNaturalSort (SortFunction.entity state))
            //             colProp.filter (CustomTextFilter (SortFunction.entity state))
            //             colProp.formatter (Custom (fun _ pos -> Column.entity state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Info"
            //             colProp.label "Info"
            //             colProp.formatter (Custom (fun _ pos -> Column.info state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "DeliveryDate"
            //             colProp.label "Lieferdatum"
            //             colProp.sortFunction (DateSort SortFunction.deliveryDate)
            //             colProp.filter (CustomTextFilter (fun pos -> (SortFunction.deliveryDate pos).ToString("dd.MM.yyyy")))
            //             colProp.formatter (Custom (fun _ pos -> Column.deliveryDate state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Selbstabholung"
            //             colProp.label "Selbstabholung"
            //             colProp.sortFunction (CustomNaturalSort SortFunction.selfserviceDelivery)
            //             colProp.formatter (Custom (fun _ pos -> Column.selfserviceDelivery state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "ReturnDate"
            //             colProp.label "Aboldatum (voraussichtlich)"
            //             colProp.sortFunction (DateSort SortFunction.returnDate)
            //             colProp.filter (CustomTextFilter (fun pos -> (SortFunction.returnDate pos).ToString("dd.MM.yyyy")))
            //             colProp.formatter (Custom (fun _ pos -> Column.returnDate state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "SelfReturn"
            //             colProp.label "Selbstrückgabe"
            //             colProp.sortFunction (CustomNaturalSort SortFunction.selfserviceReturn)
            //             colProp.formatter (Custom (fun _ pos -> Column.selfserviceReturn state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "State"
            //             colProp.label "Status"
            //             colProp.sortFunction (CustomNaturalSort SortFunction.state)
            //             colProp.filter (SelectFilter(["erfasst"; "Lieferung eingeplant"; "Abholung eingeplant"; "geliefert"; "abgeholt"; "freigegeben"], SortFunction.state))
            //             colProp.formatter (Custom (fun _ pos -> Column.state state pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "Comment"
            //             colProp.label "Kommentar"
            //             colProp.sortFunction (CustomNaturalSort SortFunction.comment)
            //             colProp.filter (CustomTextFilter SortFunction.comment)
            //             colProp.formatter (Custom (fun _ pos -> Column.comment state dispatch pos))
            //         ]
            //         tableColumn [
            //             colProp.selector "ReturnDate"
            //             colProp.label "Freigemeldet für"
            //             colProp.sortFunction (DateSort SortFunction.releaseDate)
            //             colProp.filter (CustomTextFilter (fun pos -> (SortFunction.releaseDate pos).ToString("dd.MM.yyyy")))
            //             colProp.formatter (Custom (fun _ pos -> Column.releaseDate state pos))
            //         ]
            //     ]
            // ]
            Html.table [
                prop.className "table rent-order-position-table"
                prop.children [
                    Html.thead [
                        Html.tr tableHeader
                    ]
                    Html.tbody [
                        rentOrderPositions
                        |> List.choose (fun positionRow ->
                            match positionRow with
                            | PositionRow.ShowEntityPosition (position, isChecked) ->
                                TableRows.rentOrderPositionRow state dispatch position isChecked
                            | PositionRow.NewEntityPosition (position, templateId) ->
                                TableRows.rentOrderNewEntityPositionRow state dispatch position templateId
                            | PositionRow.EditEntityPosition (position, templateId) ->
                                TableRows.rentOrderEditEntityPositionRow state dispatch position templateId

                            | PositionRow.ShowMaterialPosition (position, isChecked) ->
                                TableRows.rentOrderMaterialPositionRow state dispatch position isChecked
                            | PositionRow.NewMaterialPosition (position, value) ->
                                TableRows.rentOrderNewMaterialPositionRow state dispatch position value
                            | PositionRow.EditMaterialPosition (position, value) ->
                                TableRows.rentOrderEditMaterialPositionRow state dispatch position value
                            )
                        |> prop.children
                    ]
                ]
            ]
        ]
    ]
