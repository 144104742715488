module SharedComponents.Breadcrumb

open Feliz

let breadcrumbStr (text : string) = //str text
    Html.li [
        prop.key text
        prop.className "breadcrumb-item active"
        prop.custom ("aria-current", "page")
        prop.text text
    ]

let breadcrumbLink path (text : string) =
    Html.li [
        prop.key text
        prop.className "breadcrumb-item"
        prop.children [
            Html.a [
                prop.href path
                prop.text text
            ]
        ]
    ]

let breadcrumb crumbs =
    let rec crumb (state : ReactElement list) (y : ReactElement list) =
        match y with
        | [ ] ->
            state
        | [ last ] ->
            crumb ([last] |> List.append state) [ ]
        | head :: rest ->
            crumb ([ head ] |> List.append state) rest
    Html.nav [
        prop.custom ("aria-label", "breadcrumb")
        prop.children [
            Html.ol [
                prop.id "breadcrumb"
                prop.className "breadcrumb"
                crumbs |> crumb [ ] |> prop.children
            ]
        ]
    ]
