module SharedComponents.Jumbotron

open Fable.React
open Fable.React.Props
open Elmish

let errorJumbotron className headerTxt leadText descText route btnText =
    div [ Class ("jumbotron " + className) ]
        [ h1 [ Class "display-4" ]
            [ str headerTxt ]
          p [ Class "lead" ]
            [ str leadText ]
          hr [ Class "my-4" ]
          p [ ]
            [ str descText ]
          a [ Class "btn btn-primary btn-lg"
              Href (route)
              Role "button" ]
            [ str btnText ] ]
