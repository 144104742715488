module Appointment.State

open Elmish
open Appointment.Types
open Shared
open Shared.Appointment
open SharedComponents.Spinners

let getAppointmentsCmd =
    Cmd.OfPromise.either Communication.getRequest<AppointmentsResponse> "/api/appointments" AppointmentsFetched FetchError

let init (user : UserData) : Model * Cmd<Msg> =
    let initialModel : Model =
        { Appointments = [ ]
          SelectedAppointment = None
          SelectedView = ViewOption.Table
          AppointmentFetchedState = RequestState.Active }
    initialModel, getAppointmentsCmd

let update (msg:Msg) (model : Model) : Model*Cmd<Msg> =
    match msg with
    | SelectEvent event ->
        let selectedAppointment =
            let appointmentId (id : AppointmentId) =
                let (AppointmentId guid) = id
                guid
            match model.Appointments |> List.tryFind (fun a -> appointmentId a.Id = event.id) with
            | Some a -> Some a
            | None -> None
        { model with SelectedAppointment = selectedAppointment} , Cmd.none
    | SelectView view ->
        { model with SelectedView = view
                     SelectedAppointment = None }, Cmd.none
    | SelectAppointment appointmentId ->
        let selectedAppointment = model.Appointments |> List.tryFind(fun a -> a.Id = appointmentId)
        { model with SelectedAppointment = selectedAppointment }, Cmd.none
    | AppointmentsFetched response ->
        let cmd =
            if not response.Appointments.IsEmpty then Cmd.ofMsg(SelectAppointment response.Appointments.Head.Id) else Cmd.none
        { model with Appointments = response.Appointments
                     AppointmentFetchedState = RequestState.NotActive }, cmd
    | FetchError e ->
        model, Cmd.none
