module MasterData.Material.View

open Feliz
open SharedComponents
open SharedComponents.Spinners
open SharedComponents.Headers
open SharedComponents.ReactSelect

open MasterData.Material.Types
open Shared
open Shared.Material
open SharedComponents.Breadcrumb
open Routes
open SharedComponents.DeleteMsgBox

let private selectLabel : Types.LabelType =
    { Types.FormState = Types.FormState.New
      IsRequired = true
      Value = "Einheit"
      IsDisabled = false }

let private selectUnitProps (material : Material) isDisabled dispatch =
    let options = [|
        { label = MaterialUnit.Millimeter |> Helper.unitToString
          value = MaterialUnit.Millimeter }
        { label = MaterialUnit.Centimeter |> Helper.unitToString
          value = MaterialUnit.Centimeter }
        { label = MaterialUnit.Meter |> Helper.unitToString
          value = MaterialUnit.Meter }
        { label = MaterialUnit.Gram |> Helper.unitToString
          value = MaterialUnit.Gram }
        { label = MaterialUnit.Kilogram |> Helper.unitToString
          value = MaterialUnit.Kilogram }
        { label = MaterialUnit.Piece |> Helper.unitToString
          value = MaterialUnit.Piece }
        { label = MaterialUnit.Liter |> Helper.unitToString
          value = MaterialUnit.Liter }
        { label = MaterialUnit.Milliliter |> Helper.unitToString
          value = MaterialUnit.Milliliter }
        { label = MaterialUnit.Hour |> Helper.unitToString
          value = MaterialUnit.Hour }
    |]

    let emptySearchResultContent =
        Html.div [
            prop.className "mr-auto p-2"
            prop.text "Es konnte kein passender Filter gefunden werden."
        ]

    let selectedElement = options |> Array.tryFind (fun o -> o.value = material.Unit)

    [| CommonProps<MaterialUnit>.Options (options)
       CommonProps<MaterialUnit>.OnChange (fun value -> value |> SetUnit |> dispatch)
       CommonProps<MaterialUnit>.IsSearchable true
       CommonProps<MaterialUnit>.IsMulti false
       CommonProps<MaterialUnit>.IsClearable false
       CommonProps<MaterialUnit>.IsDisabled isDisabled
       CommonProps<MaterialUnit>.Placeholder "Einheit wählen"
       CommonProps<MaterialUnit>.ClassName "ml-2 material-unit-select"
       CommonProps<MaterialUnit>.NoOptionsMessage (fun _ -> emptySearchResultContent)
       CommonProps<MaterialUnit>.Value selectedElement |]

let prinzingSpecificForm dispatch isDisabled (prinzingSpecific : RentPrice) =
    Html.div [
        Form.Input.inlineInput((SetPrinzingRentPrice >> dispatch), prinzingSpecific.Raw,
                               inputLabel = "Mietpreis", cyPostFix = "material-rent-price", isDisabled=isDisabled)
    ]

let private newForm (form : MaterialNewForm) (dispatch : Msg -> unit) =
    Html.div [
        Html.div [
            subHeaderForm "Details"
            Form.Input.inlineInput((SetName >> dispatch), form.Material.Name,
                                   inputLabel = "Name", cyPostFix = "material-name", required = true,
                                   validation = (form.FormValidation, "material-name"))
            ReactSelect.selectWithLabel (selectUnitProps form.Material false dispatch) selectLabel "material-unit-label label-right"

            match form.Material.CustomerSpecific with
            | CustomerSpecificMaterial.Prinzing prinzingSpecific ->
                prinzingSpecificForm dispatch false form.RentPrice
            | CustomerSpecificMaterial.Default -> ()

            Form.requiredLegend
        ]

        Html.div [
            prop.className "controls d-flex"
            prop.children [
                Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> SaveMaterial |> dispatch) false "Material anlegen" "ml-auto"
            ]
        ]
    ]

let private editForm (form : MaterialEditForm) (dispatch : Msg -> unit) =
    Html.div [
        prop.className [ if form.EditField <> EditField.EditName then "disabled-form" else "" ]
        prop.children [
            subHeaderFormWithEdit (form.EditField = EditField.EditName) "Details"
                                  (fun _ -> EditMaterial |> dispatch) ""
                                  (fun _ -> SaveMaterial |> dispatch) ""
                                  (fun _ -> AbortEdit |> dispatch) ""
            Form.Input.inlineInput((SetName >> dispatch), form.Material.Name,
                                   inputLabel = "Name", cyPostFix = "material-name", required = true,
                                   isDisabled = (form.EditField <> EditField.EditName),
                                   validation = (form.FormValidation, "material-name"))
            ReactSelect.selectWithLabel (selectUnitProps form.Material true dispatch) selectLabel "material-unit-label label-right"

            match form.Material.CustomerSpecific with
            | CustomerSpecificMaterial.Prinzing prinzingSpecific ->
                prinzingSpecificForm dispatch (form.EditField <> EditField.EditName) form.RentPrice
            | CustomerSpecificMaterial.Default -> ()

            Form.requiredLegend
        ]
    ]

let private formContainer model dispatch formElement =
    Html.div [
        prop.className "d-flex flex-column flex-grow-1"
        prop.children [
            Html.div [
                prop.className "d-flex"
                prop.children [
                    Html.div [
                        prop.className "flex-grow-1 p-2"
                        prop.children [
                            formElement
                        ]
                    ]
                ]
            ]
        ]
    ]

let materialFormBreadcrumb (form : MaterialForm) =
    let breadcrumb name =
        breadcrumb [
            breadcrumbLink (Page.MaterialOverview |> Routes.toPath) "Material"
            breadcrumbStr name
        ]
    match form with
    | Loading -> Fable.React.Helpers.nothing
    | New form -> breadcrumb form.Material.Name
    | Edit form -> breadcrumb form.Material.Name

let private deleteMaterialModal (model : Model) dispatch =
    match model.DeleteRequested with
    | None ->
        deleteRequestMessageBox Fable.React.Helpers.nothing
    | Some id ->
        let displayText = sprintf "Wollen Sie das Material wirklich löschen?"
        deleteRequestMessageBox
            (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                            (fun _ -> dispatch (AbortDelete))
                                            displayText)

let editView (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.children [
            Html.section [
                prop.children [
                    deleteMaterialModal model dispatch
                ]
            ]
            overlaySpinner model.RequestState
            materialFormBreadcrumb model.Form
            Html.div [
                Html.div [
                    prop.id "masterdata-container"
                    prop.className "material flex-grow-1 d-flex flex-column"
                    prop.children [
                        Html.div [
                            prop.id "content-container"
                            prop.children [
                                mainHeaderBar None "Material" None ((fun _ -> dispatch OpenDeleteModal) |> Some)
                                match model.Form with
                                | Loading -> overlaySpinner SharedComponents.Spinners.RequestState.Active
                                | New _ -> Html.span "Failed"
                                | Edit form ->
                                    editForm form dispatch
                                    |> formContainer model dispatch
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let newView (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.children [
            overlaySpinner model.RequestState
            materialFormBreadcrumb model.Form
            Html.div [
                prop.id "masterdata-container"
                prop.className "material flex-grow-1 d-flex flex-column"
                prop.children [
                    Html.div [
                        prop.id "content-container"
                        prop.children [
                            mainHeader "Material"
                            match model.Form with
                            | Loading -> overlaySpinner SharedComponents.Spinners.RequestState.Active
                            | New form ->
                                newForm form dispatch
                                |> formContainer model dispatch
                            | Edit _ -> Html.span "Failed"
                        ]
                    ]
                ]
            ]
        ]
    ]

let private materialRow model (material : Material) =
    let (MaterialId materialId) = material.Id

    Html.tr [
        prop.key (materialId |> string)
        prop.children [
            Html.td material.Name
            Html.td (material.Unit |> Helper.unitToString)
            match model.UserData.CompanyName |> Shared.Helper.parseCustomer with
            | Customer.Prinzing ->
                match material.CustomerSpecific with
                | CustomerSpecificMaterial.Prinzing f ->
                    match f.RentPrice with
                    | None -> Html.td ""
                    | Some value -> Html.td (value.ToString().Replace(".", ","))
                | _ -> Html.none
            | Customer.Default -> Html.none
            Html.td [
                Html.a [
                    prop.href (Routes.toPath (Routes.Page.MaterialEditForm materialId))
                    prop.children [
                        Html.i [ prop.className "fas fa-eye" ]
                        Html.span [
                            prop.className "url-text"
                            prop.text "Anzeigen"
                        ]
                    ]
                ]
            ]
        ]
    ]

let private materialTable (model : Model) =
    Html.table [
        prop.className "table"
        prop.children [
            Html.thead [
                Html.tr [
                    Html.th "Name"
                    Html.th "Einheit"
                    match model.UserData.CompanyName |> Shared.Helper.parseCustomer with
                    | Customer.Prinzing ->
                        Html.th "Mietpreis"
                    | Customer.Default -> ()
                    Html.th ""
                ]
            ]

            Html.tbody (model.Materials |> List.map (fun m -> materialRow model m))
        ]
    ]

let overview (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.children [
            overlaySpinner model.RequestState
            Html.div [
                prop.id "masterdata-container"
                prop.className "flex-grow-1 d-flex flex-column"
                prop.children [
                    Html.div [
                        prop.id "content-container"
                        prop.children [
                            mainHeader "Material"
                            Html.div [
                                prop.className "controls d-flex mb-2"
                                prop.children [
                                    Html.div [  ]
                //                    SearchBar.searchBar (fun ev -> ev.Value |> SetFilter |> dispatch) model.FilterText "order-search-bar"

                                    Html.div [ prop.className "flex-fill" ]
                    //                    ReactSelect.multiSelectWithoutLabel (selectFilterProps model dispatch)

                                    Html.div [
                                        Buttons.primaryButton (Routes.toPath Routes.Page.MaterialNewForm) "Material anlegen" "ml-auto"
                                    ]
                                ]
                            ]

                            Html.div [
                                prop.className "d-flex flex-wrap"
                                prop.children [
                                    materialTable model
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]
