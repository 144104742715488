module Shared.TaskTypes

open System
open Address
open Shared
open Shared.Entity

type TravelTime =
    { Duration : int<Seconds>
      Distance : int<Meter>
      OriginTaskId : TaskId option }

type RentOrderTaskReport =
    { Images : Image list
      Signature : Image option
      Signer : string option
      Comment : string
      Timestamp : DateTime }

type DeliveryTaskState =
    | Planned
    | Loading
    | InDelivery
    | Unloading
    | Delivered

type SelfserviceDeliveryTaskState =
    | Planned
    | Started
    | Delivered

type SelfserviceReturnTaskState =
    | Planned
    | Started
    | Returned

type ReturnTaskState =
    | Planned
    | Approaching
    | Loading
    | DrivingHome
    | Returned

type ServiceTaskState =
    | Planned
    | Approaching
    | Executing
    | DrivingHome
    | Finished

type PickTaskState =
    | Planned
    | Picking
    | Finished

type FreeTaskState =
    | Planned
    | Executing
    | Finished

type TaskState =
    | DeliveryTaskState of DeliveryTaskState
    | ReturnTaskState of ReturnTaskState
    | ServiceTaskState of ServiceTaskState
    | PickTaskState of PickTaskState
    | FreeTaskState of FreeTaskState

type EntityType =
    | EntityId of EntityId
    | CompoundEntityId of CompoundEntityId

type ReturnTarget =
    | Task of TaskId
    | Storage of LocationId

type DeliveryTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAdress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId }

type AssignedDeliveryTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAdress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int // TODO create userId type
      DispositionDeliveryDate : DateTime
      DeliveryPosition : float
      State : DeliveryTaskState
      TravelTime : TravelTime option
      PlannedWorkTime : int<Minutes>
      Comment : string
      AllowMultipleDrivers : bool
      RentOrderTaskReport : RentOrderTaskReport option }

type AssignedSelfserviceDeliveryTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAddress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int // TODO create userId type
      DispositionDeliveryDate : DateTime
      DeliveryPosition : float
      State : SelfserviceDeliveryTaskState
      Comment : string
      RentOrderTaskReport : RentOrderTaskReport option }

type CarrierDeliveryTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAdress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int
      CarrierId : CarrierId
      DispositionDeliveryDate : DateTime
      DeliveredDate : DateTime
      DeliveryPosition : float
      Comment : string
      TaskReport : RentOrderTaskReport option }

type ReturnTask =
    { TaskId : TaskId
      OrderReturnDate : DateTime
      ReturnAddress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      ReturnTarget : ReturnTarget }

type AssignedSelfserviceReturnTask =
    { TaskId : TaskId
      OrderReturnDate : DateTime
      ReturnAddress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int // TODO create userId type
      DispositionReturnDate : DateTime
      DeliveryPosition : float
      State : SelfserviceReturnTaskState
      ReturnTarget : ReturnTarget
      Comment : string
      RentOrderTaskReport : RentOrderTaskReport option }

type AssignedReturnTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAddress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int // TODO create userId type
      DispositionDeliveryDate : DateTime
      DeliveryPosition : float
      State : ReturnTaskState
      TravelTime : TravelTime option
      ReturnTarget : ReturnTarget
      PlannedWorkTime : int<Minutes>
      Comment : string
      AllowMultipleDrivers : bool
      RentOrderTaskReport : RentOrderTaskReport option }

type CarrierReturnTask =
    { TaskId : TaskId
      OrderDeliveryDate : DateTime
      DeliveryAdress : Address
      PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId
      UserId : int
      CarrierId : CarrierId
      DispositionDeliveryDate : DateTime
      DeliveredDate : DateTime
      DeliveryPosition : float
      Comment : string
      TaskReport : RentOrderTaskReport option }

type ServiceTask =
    { TaskId : TaskId
      ExecutionDate : DateTime
      Address : Address
      EntityIds : EntityType list
      ServiceOrderId : ServiceOrderId
      Priority : PriorityLevel }

type UsedMaterial =
    { Material : string
      Quantity : float
      Unit : string }

type ServiceReportPerEntity =
    { EntityId : EntityId
      Comment : string
      UsedMaterial : UsedMaterial list
      Images : Image list
      Repaired : bool }

type ServiceReport =
    { TaskId : TaskId
      Comment : string
      UsedMaterial : UsedMaterial list
      ReportPerEntities : ServiceReportPerEntity list
      Signature : Image option
      Signer : string option
      IsCompleted : bool
      FollowUpDescription : string
      Timestamp : DateTime }

type AssignedServiceTask =
    { TaskId : TaskId
      PlannedExecutionDate : DateTime
      Address : Address
      EntityIds : EntityType list
      ServiceOrderId : ServiceOrderId
      UserId : int // TODO create userId type
      ScheduledExecutionDate : DateTime
      TaskPosition : float
      State : ServiceTaskState
      TravelTime : TravelTime option
      PlannedWorkTime : int<Minutes>
      Priority : PriorityLevel
      ServiceReport : ServiceReport option
      Comment : string }

type AssignedMechanicTask =
    { TaskId : TaskId
      ReferencedTaskId : TaskId
      UserId : int
      RentOrderId : RentOrderId }

type FreeTask =
    { TaskId : TaskId
      FreeOrderId : FreeOrderId
      ExecutionDate : DateTime
      Address : Address
      Title : string
      Description : string }

type AssignedFreeTask =
    { TaskId : TaskId
      PlannedExecutionDate : DateTime
      Address : Address
      Title : string
      Description : string
      FreeOrderId : FreeOrderId
      UserId : int // TODO create userId type
      ScheduledExecutionDate : DateTime
      TaskPosition : float
      State : FreeTaskState
      TravelTime : TravelTime option
      PlannedWorkTime : int<Minutes>
      Comment : string
      TaskReport : RentOrderTaskReport option }

type AssignArgs =
    { TaskId : TaskId
      UserId : int // TODO create userId type
      DeliveryDate : DateTime
      DeliveryPosition : float}

type PickTask =
    { TaskId : TaskId
      PlannedStartDate : DateTime
      ExecutionLocation : Location
      RentOrderId : RentOrderId }

type AssignedPickTask =
    { TaskId : TaskId
      PlannedStartDate : DateTime
      ExecutionLocation : Location
      RentOrderId : RentOrderId
      UserId : int // TODO create userId type
      PickDate : DateTime
      TaskPosition : float
      State : PickTaskState
      TravelTime : TravelTime option
      PlannedWorkTime : int<Minutes>
      Comment : string }

type Task =
    | DeliveryTask of DeliveryTask
    | AssignedDeliveryTask of AssignedDeliveryTask
    | ReturnTask of ReturnTask
    | AssignedReturnTask of AssignedReturnTask
    | ServiceTask of ServiceTask
    | AssignedServiceTask of AssignedServiceTask
    | AssignedMechanicTask of AssignedMechanicTask
    | PickTask of PickTask
    | AssignedPickTask of AssignedPickTask
    | CarrierDeliveryTask of CarrierDeliveryTask
    | CarrierReturnTask of CarrierReturnTask
    | AssignedSelfserviceReturnTask of AssignedSelfserviceReturnTask
    | AssignedSelfserviceDeliveryTask of AssignedSelfserviceDeliveryTask
    | FreeOrderTask of FreeTask
    | AssignedFreeOrderTask of AssignedFreeTask

type UnassignedTask =
    | DeliveryTask of DeliveryTask
    | ReturnTask of ReturnTask
    | ServiceTask of ServiceTask
    | PickTask of PickTask
    | FreeTask of FreeTask

type AssignedTask =
    | AssignedDeliveryTask of AssignedDeliveryTask
    | AssignedReturnTask of AssignedReturnTask
    | AssignedServiceTask of AssignedServiceTask
    | AssignedMechanicTask of AssignedMechanicTask
    | AssignedPickTask of AssignedPickTask
    | CarrierDeliveryTask of CarrierDeliveryTask
    | CarrierReturnTask of CarrierReturnTask
    | AssignedSelfserviceReturnTask of AssignedSelfserviceReturnTask
    | AssignedSelfserviceDeliveryTask of AssignedSelfserviceDeliveryTask
    | AssignedFreeTask of AssignedFreeTask

type DeliveryTaskType =
    | DeliveryTask of DeliveryTask
    | AssignedDeliveryTask of AssignedDeliveryTask

type ReturnTaskType =
    | ReturnTask of ReturnTask
    | AssignedReturnTask of AssignedReturnTask

type ServiceTaskType =
    | ServiceTask of ServiceTask
    | AssignedServiceTask of AssignedServiceTask

type MechanicTaskType =
    | AssignedMechanicTask of AssignedMechanicTask

type RentOrderTask =
    | DeliveryTask of DeliveryTask
    | AssignedDeliveryTask of AssignedDeliveryTask
    | ReturnTask of ReturnTask
    | AssignedReturnTask of AssignedReturnTask
    | AssignedMechanicTask of AssignedMechanicTask
    | PickTask of PickTask
    | AssignedPickTask of AssignedPickTask
    | CarrierDeliveryTask of CarrierDeliveryTask
    | CarrierReturnTask of CarrierReturnTask
    | AssignedSelfserviceReturnTask of AssignedSelfserviceReturnTask
    | AssignedSelfserviceDeliveryTask of AssignedSelfserviceDeliveryTask

type ServiceOrderTask =
    | ServiceTask of ServiceTask
    | AssignedServiceTask of AssignedServiceTask

type FreeOrderTask =
    | FreeTask of FreeTask
    | AssignedFreeTask of AssignedFreeTask

type SaveMechanicTaskDto =
    { ReferencedTaskId : TaskId
      UserId : int }

type RentOrderTaskCompleted =
    { Signature : Image option
      Signer : string option
      Comment : string
      Images : Image list }

type TaskType =
    | DeliveryTask
    | ReturnTask
    | ServiceTask
    | PickTask
    | FreeTask

type AssignedTaskType =
    | DeliveryTask
    | ReturnTask
    | ServiceTask
    | FreeTask
    | PickTask
    | SupportTask of TaskId
    | SelfserviceDeliveryTask
    | SelfserviceReturnTask

[<RequireQualifiedAccess>]
type OrderIdType =
    | RentOrderId of RentOrderId
    | FreeOrderId of FreeOrderId
    | ServiceOrderId of ServiceOrderId
    
type ClientUnassignedTask =
    { Id : TaskId
      PlannedDate : DateTime
      ExecutionAdress : Address
      ShortInfo : string
      AdditionalInfo : string
      Priority : PriorityLevel option
      EntityIds : EntityId list
      ConnectedTaskIds : TaskId list
      OrderId : OrderIdType
      TaskType : TaskType }

type TaskExecutionInformation =
    { TravelTime : TravelTime option
      PlannedWorkTime : int<Minutes>
      Comment : string
    }

type ClientTaskState =
    | Planned
    | Started
    | Finished
    
type ClientAssignedTask =
    { Id : TaskId
      PlannedDate : DateTime
      ScheduledDate : DateTime
      UserId : int
      ExecutionAdress : Address
      ShortInfo : string
      AdditionalInfo : string
      Priority : PriorityLevel option
      TaskType : AssignedTaskType
      EntityIds : EntityId list
      AllowMultipleDrivers : bool
      ReturnTarget : ReturnTarget option
      ConnectedTaskIds : TaskId list
      TaskPosition : float
      State : ClientTaskState
      OrderId : OrderIdType
      ExecutionInformation : TaskExecutionInformation }

type ClientTask =
    | UnassignedTask of ClientUnassignedTask
    | AssignedTask of ClientAssignedTask

type PendingTask =
    { Task : ClientTask
      UserId : int
      Index : float
      Date : DateTime
      AffectedTaskId : TaskId
      PredecessorTaskId : TaskId option
      SuccessorTaskId : TaskId option }

type AssignTaskDto =
    { UserId : int
      Index : float
      Date : DateTime
      AffectedTaskId : TaskId
      PredecessorTaskId : TaskId option
      SuccessorTaskId : TaskId option }

type UnassignTaskDto =
    { AffectedTaskId : TaskId
      PredecessorTaskId : TaskId option
      SuccessorTaskId : TaskId option }

type UpdatedTaskDto =
    { TaskId : TaskId
      UserId : int
      TaskType : AssignedTaskType
      AllowMultipleDrivers : bool
      ReturnTarget : ReturnTarget option
      PlannedWorkTime : int<Minutes>
      Comment : string }

type AllTasksResponse =
    { Tasks : Task list }

type ClientTasksResponse =
    { Tasks : ClientTask list }


type AssignedTasksResponse =
    { Tasks : AssignedTask list }

type CloseTaskDto =
    { TaskId : TaskId
      ReturnTarget : ReturnTarget option
      CloseDate : DateTime }

type CloseTaskResultResponse =
    | Closed of TaskId
    | Failed of TaskId
    | NotAllEntitiesOnCorrectLocationFailed of TaskId

module Helper =
    let taskId task =
        match task with
        | Task.DeliveryTask t -> t.TaskId
        | Task.AssignedDeliveryTask t -> t.TaskId
        | Task.ReturnTask t -> t.TaskId
        | Task.AssignedReturnTask t -> t.TaskId
        | Task.ServiceTask t -> t.TaskId
        | Task.AssignedServiceTask t -> t.TaskId
        | Task.AssignedMechanicTask t -> t.TaskId
        | Task.PickTask t -> t.TaskId
        | Task.AssignedPickTask t -> t.TaskId
        | Task.CarrierDeliveryTask t -> t.TaskId
        | Task.CarrierReturnTask t -> t.TaskId
        | Task.AssignedSelfserviceDeliveryTask t -> t.TaskId
        | Task.AssignedSelfserviceReturnTask t -> t.TaskId
        | Task.FreeOrderTask t -> t.TaskId
        | Task.AssignedFreeOrderTask t -> t.TaskId

    let rentOrderTaskId task =
        match task with
        | RentOrderTask.DeliveryTask t -> t.TaskId
        | RentOrderTask.AssignedDeliveryTask t -> t.TaskId
        | RentOrderTask.ReturnTask t -> t.TaskId
        | RentOrderTask.AssignedReturnTask t -> t.TaskId
        | RentOrderTask.AssignedMechanicTask t -> t.TaskId
        | RentOrderTask.PickTask t -> t.TaskId
        | RentOrderTask.AssignedPickTask t -> t.TaskId
        | RentOrderTask.CarrierDeliveryTask t -> t.TaskId
        | RentOrderTask.CarrierReturnTask t -> t.TaskId
        | RentOrderTask.AssignedSelfserviceDeliveryTask t -> t.TaskId
        | RentOrderTask.AssignedSelfserviceReturnTask t -> t.TaskId

    let serviceOrderTaskId task =
        match task with
        | ServiceOrderTask.ServiceTask t -> t.TaskId
        | ServiceOrderTask.AssignedServiceTask t -> t.TaskId

    let freeOrderTaskId task =
        match task with
        | FreeOrderTask.FreeTask t -> t.TaskId
        | FreeOrderTask.AssignedFreeTask t -> t.TaskId

    let assignedTaskId assignedTask =
        match assignedTask with
        | AssignedTask.AssignedDeliveryTask t -> t.TaskId
        | AssignedTask.AssignedReturnTask t -> t.TaskId
        | AssignedTask.AssignedServiceTask t -> t.TaskId
        | AssignedTask.AssignedMechanicTask t -> t.TaskId
        | AssignedTask.AssignedPickTask t -> t.TaskId
        | AssignedTask.CarrierDeliveryTask t -> t.TaskId
        | AssignedTask.CarrierReturnTask t -> t.TaskId
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> t.TaskId
        | AssignedTask.AssignedSelfserviceReturnTask t -> t.TaskId
        | AssignedTask.AssignedFreeTask t -> t.TaskId

    let clientTaskId task =
        match task with
        | ClientTask.UnassignedTask t -> t.Id
        | ClientTask.AssignedTask t -> t.Id

    let assignedTaskComment assignedTask =
        match assignedTask with
        | AssignedTask.AssignedDeliveryTask t -> Some t.Comment
        | AssignedTask.AssignedReturnTask t -> Some t.Comment
        | AssignedTask.AssignedServiceTask t -> Some t.Comment
        | AssignedTask.AssignedMechanicTask t -> None
        | AssignedTask.AssignedPickTask t -> Some t.Comment
        | AssignedTask.CarrierDeliveryTask t -> Some t.Comment
        | AssignedTask.CarrierReturnTask t -> Some t.Comment
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> Some t.Comment
        | AssignedTask.AssignedSelfserviceReturnTask t -> Some t.Comment
        | AssignedTask.AssignedFreeTask t -> Some t.Comment

    let assignedTaskPlannedWorkTime assignedTask =
        match assignedTask with
        | AssignedTask.AssignedDeliveryTask t -> Some t.PlannedWorkTime
        | AssignedTask.AssignedReturnTask t -> Some t.PlannedWorkTime
        | AssignedTask.AssignedServiceTask t -> Some t.PlannedWorkTime
        | AssignedTask.AssignedMechanicTask t -> None
        | AssignedTask.AssignedPickTask t -> Some t.PlannedWorkTime
        | AssignedTask.AssignedFreeTask t -> Some t.PlannedWorkTime
        | AssignedTask.CarrierDeliveryTask t -> None
        | AssignedTask.CarrierReturnTask t -> None
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> None
        | AssignedTask.AssignedSelfserviceReturnTask t -> None

    let taskEntityIds task =
        match task with
        | Task.AssignedDeliveryTask t -> failwith "no entities"
        | Task.AssignedServiceTask t -> t.EntityIds
        | Task.AssignedReturnTask t -> failwith "no entities"
        | Task.DeliveryTask t -> failwith "no entities"
        | Task.ReturnTask t -> failwith "no entities"
        | Task.CarrierDeliveryTask t -> failwith "no entities"
        | Task.CarrierReturnTask t -> failwith "no entities"
        | Task.AssignedSelfserviceDeliveryTask t -> failwith "no entities"
        | Task.AssignedSelfserviceReturnTask t -> failwith "no entities"
        | Task.ServiceTask t -> t.EntityIds
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedMechanicTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> []

    let taskAddress task =
        match task with
        | Task.DeliveryTask t -> t.DeliveryAdress
        | Task.ServiceTask t -> t.Address
        | Task.ReturnTask t -> t.ReturnAddress
        | Task.PickTask t -> emptyAddress
        | Task.AssignedDeliveryTask t -> t.DeliveryAdress
        | Task.AssignedServiceTask t -> t.Address
        | Task.AssignedReturnTask t -> t.DeliveryAddress
        | Task.AssignedMechanicTask t -> emptyAddress
        | Task.AssignedPickTask t -> emptyAddress
        | Task.CarrierDeliveryTask t -> t.DeliveryAdress
        | Task.CarrierReturnTask t -> t.DeliveryAdress
        | Task.AssignedSelfserviceDeliveryTask t -> t.DeliveryAddress
        | Task.AssignedSelfserviceReturnTask t -> t.ReturnAddress
        | Task.FreeOrderTask t -> t.Address
        | Task.AssignedFreeOrderTask t -> t.Address

    let taskUserId task =
        match task with
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.FreeOrderTask _
        | Task.PickTask _ -> None
        | Task.AssignedDeliveryTask t -> Some t.UserId
        | Task.AssignedServiceTask t -> Some t.UserId
        | Task.AssignedReturnTask t -> Some t.UserId
        | Task.AssignedMechanicTask t -> Some t.UserId
        | Task.AssignedPickTask t -> Some t.UserId
        | Task.AssignedSelfserviceDeliveryTask t -> Some t.UserId
        | Task.AssignedSelfserviceReturnTask t -> Some t.UserId
        | Task.AssignedFreeOrderTask t -> Some t.UserId

    let taskRentOrderId task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedMechanicTask _
        | Task.AssignedServiceTask _
        | Task.ServiceTask _ -> None
        | Task.CarrierReturnTask t -> Some t.RentOrderId
        | Task.CarrierDeliveryTask t -> Some t.RentOrderId
        | Task.DeliveryTask t -> Some t.RentOrderId
        | Task.ReturnTask t -> Some t.RentOrderId
        | Task.AssignedDeliveryTask t -> Some t.RentOrderId
        | Task.AssignedReturnTask t -> Some t.RentOrderId
        | Task.PickTask t -> Some t.RentOrderId
        | Task.AssignedPickTask t -> Some t.RentOrderId
        | Task.AssignedSelfserviceDeliveryTask t -> Some t.RentOrderId
        | Task.AssignedSelfserviceReturnTask t -> Some t.RentOrderId

    let taskRepairOrderId task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedPickTask _
        | Task.AssignedMechanicTask _ -> None
        | Task.AssignedServiceTask t -> Some t.ServiceOrderId
        | Task.ServiceTask t -> Some t.ServiceOrderId

    let isRentOrderTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedServiceTask _
        | Task.ServiceTask _ -> false
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.AssignedMechanicTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> true

    let isRepairOrderTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.AssignedMechanicTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> false
        | Task.AssignedServiceTask _
        | Task.ServiceTask _ -> true

    let taskIdByRentOrderId task rentOrderId =
        let taskIdForExpectedTaskId rId taskId =
            if rId = rentOrderId then Some taskId else None

        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedMechanicTask _
        | Task.AssignedServiceTask _
        | Task.ServiceTask _ -> None
        | Task.AssignedSelfserviceDeliveryTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.AssignedSelfserviceReturnTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.CarrierReturnTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.CarrierDeliveryTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.DeliveryTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.ReturnTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.AssignedDeliveryTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.AssignedReturnTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.PickTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId
        | Task.AssignedPickTask t -> taskIdForExpectedTaskId t.RentOrderId t.TaskId

    let taskIdByRepairOrderId task repairOrderId =
        let taskIdForExpectedTaskId rId taskId =
            if rId = repairOrderId then Some taskId else None

        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.AssignedMechanicTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> None
        | Task.AssignedServiceTask t -> taskIdForExpectedTaskId t.ServiceOrderId t.TaskId
        | Task.ServiceTask t -> taskIdForExpectedTaskId t.ServiceOrderId t.TaskId

    let isAssignedDeliveryTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.AssignedMechanicTask _
        | Task.DeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.AssignedServiceTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> false
        | Task.AssignedDeliveryTask _ -> true

    let isAssignedReturnTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.AssignedMechanicTask _
        | Task.DeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.AssignedServiceTask _
        | Task.AssignedDeliveryTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> false
        | Task.AssignedReturnTask _ -> true

    let isAssignedMechanicTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.DeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.AssignedServiceTask _
        | Task.AssignedDeliveryTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> false
        | Task.AssignedMechanicTask _ -> true

    let isAssignedTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.PickTask _ -> false
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.AssignedServiceTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.AssignedMechanicTask _
        | Task.AssignedPickTask _ -> true

    let assignedDeliveryTasks task (rentOrderId : RentOrderId) =
        let taskIdForExpectedTaskId (task : AssignedDeliveryTask) =
            if task.RentOrderId = rentOrderId then Some task else None

        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.AssignedMechanicTask _
        | Task.AssignedServiceTask _
        | Task.ServiceTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedPickTask _ -> None
        | Task.AssignedDeliveryTask t -> taskIdForExpectedTaskId t

    let filterDeliveryTasks (tasks : RentOrderTask list) =
        tasks
        |> List.map (function
            | RentOrderTask.DeliveryTask t -> Some t
            | RentOrderTask.AssignedSelfserviceDeliveryTask _
            | RentOrderTask.AssignedSelfserviceReturnTask _
            | RentOrderTask.CarrierDeliveryTask _
            | RentOrderTask.CarrierReturnTask _
            | RentOrderTask.AssignedDeliveryTask _
            | RentOrderTask.AssignedReturnTask _
            | RentOrderTask.ReturnTask _
            | RentOrderTask.AssignedMechanicTask _
            | RentOrderTask.PickTask _
            | RentOrderTask.AssignedPickTask _ -> None)
        |> List.choose id

    let unassignedTaskDate (task : UnassignedTask) =
        match task with
        | UnassignedTask.DeliveryTask t -> t.OrderDeliveryDate
        | UnassignedTask.ReturnTask t -> t.OrderReturnDate
        | UnassignedTask.ServiceTask t -> t.ExecutionDate
        | UnassignedTask.PickTask t -> t.PlannedStartDate
        | UnassignedTask.FreeTask t -> t.ExecutionDate

    let taskDispositionDate (task : Task) =
        match task with
        | Task.FreeOrderTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.DeliveryTask _
        | Task.PickTask _
        | Task.AssignedMechanicTask _ -> None
        | Task.AssignedServiceTask t -> Some t.ScheduledExecutionDate
        | Task.CarrierReturnTask t -> Some t.DispositionDeliveryDate
        | Task.CarrierDeliveryTask t -> Some t.DispositionDeliveryDate
        | Task.AssignedReturnTask t -> Some t.DispositionDeliveryDate
        | Task.AssignedDeliveryTask t -> Some t.DispositionDeliveryDate
        | Task.AssignedSelfserviceDeliveryTask t -> Some t.DispositionDeliveryDate
        | Task.AssignedSelfserviceReturnTask t -> Some t.DispositionReturnDate
        | Task.AssignedPickTask t -> Some t.PickDate
        | Task.AssignedFreeOrderTask t -> Some t.ScheduledExecutionDate

    let assignedTaskUserId task =
        match task with
        | AssignedTask.AssignedDeliveryTask t -> t.UserId
        | AssignedTask.AssignedServiceTask t -> t.UserId
        | AssignedTask.AssignedReturnTask t -> t.UserId
        | AssignedTask.AssignedMechanicTask t -> t.UserId
        | AssignedTask.AssignedPickTask t -> t.UserId
        | AssignedTask.CarrierDeliveryTask t -> t.UserId
        | AssignedTask.CarrierReturnTask t -> t.UserId
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> t.UserId
        | AssignedTask.AssignedSelfserviceReturnTask t -> t.UserId
        | AssignedTask.AssignedFreeTask t -> t.UserId

    let assignedTaskDispositionDate (task : AssignedTask) =
        match task with
        | AssignedTask.AssignedMechanicTask _ -> None
        | AssignedTask.AssignedServiceTask t -> Some t.ScheduledExecutionDate
        | AssignedTask.AssignedReturnTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedDeliveryTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedPickTask t -> Some t.PickDate
        | AssignedTask.CarrierDeliveryTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.CarrierReturnTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedSelfserviceReturnTask t -> Some t.DispositionReturnDate
        | AssignedTask.AssignedFreeTask t -> Some t.ScheduledExecutionDate

    let assignedTaskPosition (task : AssignedTask) =
        match task with
        | AssignedTask.AssignedMechanicTask _ -> None
        | AssignedTask.AssignedReturnTask t -> Some t.DeliveryPosition
        | AssignedTask.AssignedServiceTask t -> Some t.TaskPosition
        | AssignedTask.AssignedDeliveryTask t -> Some t.DeliveryPosition
        | AssignedTask.CarrierDeliveryTask t -> Some t.DeliveryPosition
        | AssignedTask.CarrierReturnTask t -> Some t.DeliveryPosition
        | AssignedTask.AssignedPickTask t -> Some t.TaskPosition
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> Some t.DeliveryPosition
        | AssignedTask.AssignedSelfserviceReturnTask t -> Some t.DeliveryPosition
        | AssignedTask.AssignedFreeTask t -> Some t.TaskPosition

    let toRentOrderTask task =
        match task with
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _
        | Task.AssignedServiceTask _
        | Task.ServiceTask _ -> None
        | Task.AssignedMechanicTask t -> Some (RentOrderTask.AssignedMechanicTask t)
        | Task.CarrierReturnTask t -> Some (RentOrderTask.CarrierReturnTask t)
        | Task.CarrierDeliveryTask t -> Some (RentOrderTask.CarrierDeliveryTask t)
        | Task.DeliveryTask t -> Some (RentOrderTask.DeliveryTask t)
        | Task.ReturnTask t -> Some (RentOrderTask.ReturnTask t)
        | Task.AssignedDeliveryTask t -> Some (RentOrderTask.AssignedDeliveryTask t)
        | Task.AssignedReturnTask t -> Some (RentOrderTask.AssignedReturnTask t)
        | Task.PickTask t -> Some (RentOrderTask.PickTask t)
        | Task.AssignedPickTask t -> Some (RentOrderTask.AssignedPickTask t)
        | Task.AssignedSelfserviceDeliveryTask t -> Some (RentOrderTask.AssignedSelfserviceDeliveryTask t)
        | Task.AssignedSelfserviceReturnTask t -> Some (RentOrderTask.AssignedSelfserviceReturnTask t)

    let toTaskFromRentOrderTask (task : RentOrderTask) =
        match task with
        | RentOrderTask.AssignedMechanicTask t -> Task.AssignedMechanicTask t
        | RentOrderTask.CarrierReturnTask t -> Task.CarrierReturnTask t
        | RentOrderTask.CarrierDeliveryTask t -> Task.CarrierDeliveryTask t
        | RentOrderTask.DeliveryTask t -> Task.DeliveryTask t
        | RentOrderTask.ReturnTask t -> Task.ReturnTask t
        | RentOrderTask.AssignedDeliveryTask t -> Task.AssignedDeliveryTask t
        | RentOrderTask.AssignedReturnTask t -> Task.AssignedReturnTask t
        | RentOrderTask.PickTask t -> Task.PickTask t
        | RentOrderTask.AssignedPickTask t -> Task.AssignedPickTask t
        | RentOrderTask.AssignedSelfserviceDeliveryTask t -> Task.AssignedSelfserviceDeliveryTask t
        | RentOrderTask.AssignedSelfserviceReturnTask t -> Task.AssignedSelfserviceReturnTask t

    let toServiceOrderTask task =
        match task with
        | Task.AssignedServiceTask t -> Some (ServiceOrderTask.AssignedServiceTask t)
        | Task.ServiceTask t -> Some (ServiceOrderTask.ServiceTask t)
        | Task.AssignedMechanicTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.AssignedPickTask _
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _ -> None

    let toTaskFromServiceOrderTask (task : ServiceOrderTask) =
        match task with
        | ServiceOrderTask.AssignedServiceTask t -> Task.AssignedServiceTask t
        | ServiceOrderTask.ServiceTask t -> Task.ServiceTask t

    let toFreeOrderTask task =
        match task with
        | Task.AssignedFreeOrderTask  t -> Some (FreeOrderTask.AssignedFreeTask t)
        | Task.FreeOrderTask t -> Some (FreeOrderTask.FreeTask t)
        | Task.AssignedServiceTask _
        | Task.ServiceTask _
        | Task.AssignedMechanicTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.DeliveryTask _
        | Task.ReturnTask _
        | Task.AssignedDeliveryTask _
        | Task.AssignedReturnTask _
        | Task.PickTask _
        | Task.AssignedSelfserviceDeliveryTask _
        | Task.AssignedSelfserviceReturnTask _
        | Task.AssignedPickTask _
        | Task.FreeOrderTask _
        | Task.AssignedFreeOrderTask _ -> None

    let toAssignedDeliveryTask (deliveryTask : DeliveryTask) userId deliveryDate deliveryPosition state =
        { AssignedDeliveryTask.TaskId = deliveryTask.TaskId
          OrderDeliveryDate = deliveryTask.OrderDeliveryDate
          DeliveryAdress = deliveryTask.DeliveryAdress
          PositionIds = deliveryTask.PositionIds
          RentOrderId = deliveryTask.RentOrderId
          UserId = userId
          DispositionDeliveryDate = deliveryDate
          DeliveryPosition = deliveryPosition
          State = state
          TravelTime = None
          PlannedWorkTime = 0 * 1<Minutes>
          Comment = ""
          AllowMultipleDrivers = false
          RentOrderTaskReport = None }

    let toUnassignedDeliveryTask (deliveryTask : AssignedDeliveryTask) =
        { DeliveryTask.TaskId = deliveryTask.TaskId
          OrderDeliveryDate = deliveryTask.OrderDeliveryDate
          DeliveryAdress = deliveryTask.DeliveryAdress
          PositionIds = deliveryTask.PositionIds
          RentOrderId = deliveryTask.RentOrderId }

    let toAssignedReturnTask (returnTask : ReturnTask) userId deliveryDate deliveryPosition state =
        { AssignedReturnTask.TaskId = returnTask.TaskId
          OrderDeliveryDate = returnTask.OrderReturnDate
          DeliveryAddress = returnTask.ReturnAddress
          PositionIds = returnTask.PositionIds
          RentOrderId = returnTask.RentOrderId
          UserId = userId
          DispositionDeliveryDate = deliveryDate
          DeliveryPosition = deliveryPosition
          ReturnTarget = returnTask.ReturnTarget
          State = state
          TravelTime = None
          PlannedWorkTime = 0 * 1<Minutes>
          Comment = ""
          AllowMultipleDrivers = false
          RentOrderTaskReport = None }

    let toUnassignedReturnTask (returnTask : AssignedReturnTask) =
        { ReturnTask.TaskId = returnTask.TaskId
          PositionIds = returnTask.PositionIds
          RentOrderId = returnTask.RentOrderId
          OrderReturnDate = returnTask.OrderDeliveryDate
          ReturnAddress = returnTask.DeliveryAddress
          ReturnTarget = returnTask.ReturnTarget }

    let toAssignedServiceTask (repairTask : ServiceTask) userId deliveryDate deliveryPosition state =
        { AssignedServiceTask.TaskId = repairTask.TaskId
          PlannedExecutionDate = repairTask.ExecutionDate
          Address = repairTask.Address
          EntityIds = repairTask.EntityIds
          ServiceOrderId = repairTask.ServiceOrderId
          UserId = userId
          ScheduledExecutionDate = deliveryDate
          TaskPosition = deliveryPosition
          State = state
          TravelTime = None
          PlannedWorkTime = 0 * 1<Minutes>
          Priority = repairTask.Priority
          Comment = ""
          ServiceReport = None }

    let toUnassignedServiceTask (repairTask : AssignedServiceTask) =
        { ServiceTask.TaskId = repairTask.TaskId
          Address = repairTask.Address
          EntityIds = repairTask.EntityIds
          ServiceOrderId = repairTask.ServiceOrderId
          ExecutionDate = repairTask.PlannedExecutionDate
          Priority = repairTask.Priority }

    let toAssignedFreeTask (freeTask : FreeTask) userId deliveryDate deliveryPosition state =
        { AssignedFreeTask.TaskId = freeTask.TaskId
          PlannedExecutionDate = freeTask.ExecutionDate
          Address = freeTask.Address
          FreeOrderId = freeTask.FreeOrderId
          UserId = userId
          ScheduledExecutionDate = deliveryDate
          TaskPosition = deliveryPosition
          State = state
          TravelTime = None
          PlannedWorkTime = 0 * 1<Minutes>
          Comment = ""
          Title = freeTask.Title
          Description = freeTask.Description
          TaskReport = None }

    let toUnassignedFreeTask (freeTask : AssignedFreeTask) =
        { FreeTask.TaskId = freeTask.TaskId
          Address = freeTask.Address
          FreeOrderId = freeTask.FreeOrderId
          ExecutionDate = freeTask.PlannedExecutionDate
          Title = freeTask.Title
          Description = freeTask.Description }

    let toAssignedPickTask (pickTask : PickTask) userId deliveryDate deliveryPosition state =
        { AssignedPickTask.TaskId = pickTask.TaskId
          PlannedStartDate = pickTask.PlannedStartDate
          ExecutionLocation = pickTask.ExecutionLocation
          RentOrderId = pickTask.RentOrderId
          UserId = userId
          PickDate = deliveryDate
          TaskPosition = deliveryPosition
          State = state
          TravelTime = None
          PlannedWorkTime = 0 * 1<Minutes>
          Comment = "" }

    let toUnassignedPickTask (pickTask : AssignedPickTask) =
        { PickTask.TaskId = pickTask.TaskId
          PlannedStartDate = pickTask.PlannedStartDate
          ExecutionLocation = pickTask.ExecutionLocation
          RentOrderId = pickTask.RentOrderId }

    let toAssignedTask (task : Task) =
        match task with
        | Task.FreeOrderTask _
        | Task.CarrierReturnTask _
        | Task.CarrierDeliveryTask _
        | Task.ServiceTask _
        | Task.ReturnTask _
        | Task.DeliveryTask _
        | Task.PickTask _
        | Task.AssignedMechanicTask _ -> None
        | Task.AssignedSelfserviceDeliveryTask t -> t |> AssignedTask.AssignedSelfserviceDeliveryTask |> Some
        | Task.AssignedSelfserviceReturnTask t -> t |> AssignedTask.AssignedSelfserviceReturnTask |> Some
        | Task.AssignedServiceTask t -> t |> AssignedTask.AssignedServiceTask |> Some
        | Task.AssignedReturnTask t -> t |> AssignedTask.AssignedReturnTask |> Some
        | Task.AssignedDeliveryTask t -> t |> AssignedTask.AssignedDeliveryTask |> Some
        | Task.AssignedPickTask t -> t |> AssignedTask.AssignedPickTask |> Some
        | Task.AssignedFreeOrderTask t -> t |> AssignedTask.AssignedFreeTask |> Some

    let extractAssignArgs (pendingTask : PendingTask) taskId =
        { AssignArgs.TaskId = taskId
          DeliveryDate = pendingTask.Date
          DeliveryPosition = pendingTask.Index
          UserId = pendingTask.UserId }

    let toAssignedMechanicTask newTaskId userId rentOrderId (task : Task) =
        { AssignedMechanicTask.TaskId = newTaskId
          ReferencedTaskId = taskId task
          UserId = userId
          RentOrderId = rentOrderId }

    let assignedTaskAddress (task : AssignedTask) =
        match task with
        | AssignedTask.AssignedMechanicTask _
        | AssignedTask.AssignedPickTask _ -> None
        | AssignedTask.AssignedServiceTask t -> Some t.Address
        | AssignedTask.AssignedReturnTask t -> Some t.DeliveryAddress
        | AssignedTask.AssignedDeliveryTask t -> Some t.DeliveryAdress
        | AssignedTask.CarrierDeliveryTask t -> Some t.DeliveryAdress
        | AssignedTask.CarrierReturnTask t -> Some t.DeliveryAdress
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> Some t.DeliveryAddress
        | AssignedTask.AssignedSelfserviceReturnTask t -> Some t.ReturnAddress
        | AssignedTask.AssignedFreeTask t -> Some t.Address

    let assignedTaskEntityIds (task : AssignedTask) =
        match task with
        | AssignedTask.AssignedMechanicTask _
        | AssignedTask.AssignedPickTask _ -> [ ]
        | AssignedTask.AssignedServiceTask t -> t.EntityIds
        | AssignedTask.AssignedReturnTask t -> failwith "No entity ids"
        | AssignedTask.AssignedDeliveryTask t -> failwith "No entity ids"
        | AssignedTask.CarrierDeliveryTask t -> failwith "No entity ids"
        | AssignedTask.CarrierReturnTask t -> failwith "No entity ids"
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> failwith "No entity ids"
        | AssignedTask.AssignedSelfserviceReturnTask t -> failwith "No entity ids"
        | AssignedTask.AssignedFreeTask t -> failwith "No entity ids"

    let assignedTaskDate (task : AssignedTask) =
        match task with
        | AssignedTask.CarrierReturnTask _
        | AssignedTask.CarrierDeliveryTask _
        | AssignedTask.AssignedMechanicTask _ -> None
        | AssignedTask.AssignedServiceTask t -> Some t.ScheduledExecutionDate
        | AssignedTask.AssignedReturnTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedDeliveryTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedPickTask t -> Some t.PickDate
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> Some t.DispositionDeliveryDate
        | AssignedTask.AssignedSelfserviceReturnTask t -> Some t.DispositionReturnDate
        | AssignedTask.AssignedFreeTask t -> Some t.ScheduledExecutionDate

    let assignedTaskTravelTime (task : AssignedTask) =
        match task with
        | AssignedTask.CarrierReturnTask _
        | AssignedTask.CarrierDeliveryTask _
        | AssignedTask.AssignedSelfserviceDeliveryTask _
        | AssignedTask.AssignedSelfserviceReturnTask _
        | AssignedTask.AssignedMechanicTask _ -> None
        | AssignedTask.AssignedPickTask t -> t.TravelTime
        | AssignedTask.AssignedServiceTask t -> t.TravelTime
        | AssignedTask.AssignedReturnTask t -> t.TravelTime
        | AssignedTask.AssignedDeliveryTask t -> t.TravelTime
        | AssignedTask.AssignedFreeTask t -> t.TravelTime

    let assignedTaskToTask (task : AssignedTask) =
        match task with
        | AssignedTask.AssignedFreeTask t -> t |> Task.AssignedFreeOrderTask
        | AssignedTask.CarrierReturnTask t -> t |> Task.CarrierReturnTask
        | AssignedTask.CarrierDeliveryTask t -> t |> Task.CarrierDeliveryTask
        | AssignedTask.AssignedMechanicTask t -> t |> Task.AssignedMechanicTask
        | AssignedTask.AssignedPickTask t -> t |> Task.AssignedPickTask
        | AssignedTask.AssignedServiceTask t -> t |> Task.AssignedServiceTask
        | AssignedTask.AssignedReturnTask t -> t |> Task.AssignedReturnTask
        | AssignedTask.AssignedDeliveryTask t -> t |> Task.AssignedDeliveryTask
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> t |> Task.AssignedSelfserviceDeliveryTask
        | AssignedTask.AssignedSelfserviceReturnTask t -> t |> Task.AssignedSelfserviceReturnTask

    let rentOrderId (task : RentOrderTask) =
        match task with
        | RentOrderTask.AssignedSelfserviceDeliveryTask t -> t.RentOrderId
        | RentOrderTask.AssignedSelfserviceReturnTask t -> t.RentOrderId
        | RentOrderTask.CarrierReturnTask t -> t.RentOrderId
        | RentOrderTask.CarrierDeliveryTask t -> t.RentOrderId
        | RentOrderTask.AssignedMechanicTask t -> t.RentOrderId
        | RentOrderTask.AssignedPickTask t -> t.RentOrderId
        | RentOrderTask.AssignedReturnTask t -> t.RentOrderId
        | RentOrderTask.AssignedDeliveryTask t -> t.RentOrderId
        | RentOrderTask.PickTask t -> t.RentOrderId
        | RentOrderTask.ReturnTask t -> t.RentOrderId
        | RentOrderTask.DeliveryTask t -> t.RentOrderId

    let rentOrderTaskPositionIds (task : RentOrderTask) =
        match task with
        | RentOrderTask.AssignedSelfserviceDeliveryTask t -> t.PositionIds |> Some
        | RentOrderTask.AssignedSelfserviceReturnTask t -> t.PositionIds |> Some
        | RentOrderTask.CarrierReturnTask t -> t.PositionIds |> Some
        | RentOrderTask.CarrierDeliveryTask t -> t.PositionIds |> Some
        | RentOrderTask.AssignedMechanicTask t -> None
        | RentOrderTask.AssignedPickTask t -> None
        | RentOrderTask.AssignedReturnTask t -> t.PositionIds |> Some
        | RentOrderTask.AssignedDeliveryTask t -> t.PositionIds |> Some
        | RentOrderTask.PickTask t -> None
        | RentOrderTask.ReturnTask t -> t.PositionIds |> Some
        | RentOrderTask.DeliveryTask t -> t.PositionIds |> Some

    let serviceOrderId (task : ServiceOrderTask) =
        match task with
        | ServiceOrderTask.AssignedServiceTask t -> t.ServiceOrderId
        | ServiceOrderTask.ServiceTask t -> t.ServiceOrderId

    let toEntityIds (entityTypes : EntityType) =
        match entityTypes with
        | EntityType.EntityId entityId -> [ entityId ]
        | EntityType.CompoundEntityId comp -> comp.EntityId :: comp.SubEntityIds

    let mechanicTaskReferencedTaskIds (clientAssignedTasks : ClientAssignedTask list) =
        clientAssignedTasks
        |> List.choose (fun t ->
            match t.TaskType with
            | AssignedTaskType.FreeTask _
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.SelfserviceReturnTask _
            | AssignedTaskType.DeliveryTask _
            | AssignedTaskType.ReturnTask _
            | AssignedTaskType.ServiceTask _
            | AssignedTaskType.PickTask -> None
            | AssignedTaskType.SupportTask referencedTaskId -> Some referencedTaskId)

    let assignedMechanicTasks (clientAssignedTasks : ClientAssignedTask list) =
        clientAssignedTasks
        |> List.choose (fun t ->
            match t.TaskType with
            | AssignedTaskType.FreeTask _
            | AssignedTaskType.SelfserviceDeliveryTask _
            | AssignedTaskType.SelfserviceReturnTask _
            | AssignedTaskType.DeliveryTask _
            | AssignedTaskType.ReturnTask _
            | AssignedTaskType.ServiceTask _
            | AssignedTaskType.PickTask -> None
            | AssignedTaskType.SupportTask referencedTaskId -> Some (t, referencedTaskId))

    let isAssignedDeliveryTaskInPlannedState (assignedTask : AssignedDeliveryTask) =
        match assignedTask.State with
        | DeliveryTaskState.Planned _ -> true
        | DeliveryTaskState.InDelivery _
        | DeliveryTaskState.Loading _
        | DeliveryTaskState.Delivered _
        | DeliveryTaskState.Unloading _ -> false

    let isAssignedReturnTaskInPlannedState (assignedTask : AssignedReturnTask) =
        match assignedTask.State with
        | ReturnTaskState.Planned _ -> true
        | ReturnTaskState.Approaching _
        | ReturnTaskState.DrivingHome _
        | ReturnTaskState.Loading _
        | ReturnTaskState.Returned _ -> false

    let isAssignedServiceTaskInPlannedState (assignedTask : AssignedServiceTask) =
        match assignedTask.State with
        | ServiceTaskState.Planned _ -> true
        | ServiceTaskState.Approaching _
        | ServiceTaskState.DrivingHome _
        | ServiceTaskState.Executing _
        | ServiceTaskState.Finished _ -> false

    let isAssignedFreeTaskInPlannedState (assignedTask : AssignedFreeTask) =
        match assignedTask.State with
        | FreeTaskState.Planned _ -> true
        | FreeTaskState.Executing _
        | FreeTaskState.Finished _ -> false

    let isAssignedPickTaskInPlannedState (assignedTask : AssignedPickTask) =
        match assignedTask.State with
        | PickTaskState.Planned _ -> true
        | PickTaskState.Picking _
        | PickTaskState.Finished _ -> false