module Order.RentOrder.RevertReleaseDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Thoth.Json
open Client.SharedComponents
open Shared.Configuration
open Shared.Address
open SharedComponents.ReactSelect
open SharedComponents.Types.Helpers
open Types
open Shared.RentOrder
open Feliz.ElmishComponents
open System
open SharedComponents.Toast

type Msg =
    | RevertReleasePosition of RevertReleaseRentOrderPositionDto
    | PositionReleaseReverted of PostResponse<RentOrderRevertReleaseResult>
    | FetchError of exn


let revertReleaseRentOrderPositionCmd (dto : RevertReleaseRentOrderPositionDto) =
    let url = "/api/rentorders/revertrelease"
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<RentOrderRevertReleaseResult>> (url, body) PositionReleaseReverted FetchError

type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { RentOrderId : RentOrderId
      PositionIds : RentOrderPositionId list
      RequestActive : bool
      WarningMsg : string option
      SuccessCallback : unit -> unit }

let init rentOrderId rentOrderPositionIds successCallback : State * Cmd<Msg> =
    let initialModel =
        { RentOrderId = rentOrderId
          PositionIds = rentOrderPositionIds
          RequestActive = false
          WarningMsg = None
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | RevertReleasePosition dto ->
        { state with RequestActive = true }, revertReleaseRentOrderPositionCmd dto
    | PositionReleaseReverted response ->
        let state, newCmd =
            match response.Result with
            | RentOrderRevertReleaseResult.RevertRelease _ ->
                state.SuccessCallback()
                Swal.close (SweetAlert.Result.Value ())
                { state with RequestActive = false }, toast (ToastType.Success "Freimeldung für Positionen wurde zurückgenommen.")
            | RentOrderRevertReleaseResult.PositionsInWrongState positionIds ->
                let posNos = positionIds |> List.map(fun (pId, posNo) -> posNo)
                let msg = "Für folgende Positionen konnte die Freimeldung nicht zurückgenommen werden: " + (String.concat " " posNos)
                { state with WarningMsg = msg |> Some }, toast (ToastType.Error "Freimeldung für Positionen konnte nicht zurückgenommen werden. Positionen sind im falschen Zustand.")
        state, newCmd
    | FetchError e ->
        { state with RequestActive = false }, Cmd.none

let dialogBody (state : State) dispatch =
    Html.div [
        prop.id "delete-position-dialog"
        prop.className "form-group"
        prop.children [

            match state.WarningMsg with
            | Some msg -> SharedComponents.Alerts.warningAlert msg
            | None -> Html.none

            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.text "Willst du die Freimeldung für die selektierten Positionen wirklich zurücknehmen?"
            ]

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnctAndIsDisabled
                        (fun _ ->
                            let dto =
                                { RevertReleaseRentOrderPositionDto.RentOrderId = state.RentOrderId
                                  RevertReleaseRentOrderPositionDto.PositionIds = state.PositionIds }
                            dto |> RevertReleasePosition |> dispatch)
                        (state.RequestActive)
                        "Freimeldung zurücknehmen" "mr-3"
                    Buttons.primaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" ""
                ]
            ]

        ]
    ]

let elmishComp (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) successCallback =
    React.elmishComponent("RevertReleaseDialog", init rentOrderId positionIds successCallback, update, dialogBody)

let render (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (isDisabled : bool) successCallback =
    let disabledClass = if isDisabled then "disabled" else ""
    Html.button [
        prop.className (sprintf "dropdown-item %s" disabledClass)
        prop.disabled isDisabled
        prop.text "Freimeldung zurücknehmen"
        prop.onClick (fun _ ->
                        Swal.fire ([
                            swal.html (elmishComp rentOrderId positionIds successCallback)
                            swal.showCancelButton false
                            swal.showConfirmButton false
                            ],
                            (function
                                | SweetAlert.Result.Value _ ->
                                    Swal.Simple.success "Freimeldung für Positionen wurden zurückgenommen"
                                    successCallback ()
                                | SweetAlert.Result.Dismissal d ->
                                    printfn "Dismiss %O" d
                                | SweetAlert.Result.Denied ->
                                    printfn "Denied"
                            )
                        )
                    )
    ]

let revertReleaseDialog' =
    React.functionComponent(fun (props : {| rentOrderId : RentOrderId
                                            positionIds : RentOrderPositionId list
                                            isDisabled : bool
                                            successCallback : unit -> unit |}) -> render props.rentOrderId props.positionIds props.isDisabled props.successCallback)

let revertReleaseDialog (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (isDisabled : bool) successCallback =
    revertReleaseDialog' {| rentOrderId = rentOrderId
                            positionIds = positionIds
                            isDisabled = isDisabled
                            successCallback = successCallback |}