module Dispositions.RentOrderDisposition.View

open System
open Fable.React
open Fable.React.Props
open Dispositions.RentOrderDisposition.Types
open Shared
open Shared.RentOrder
open Shared.TaskTypes
open Shared.Entity
open Shared.Material
open SharedComponents.Headers
open Shared.TaskTypes.Helper
open SharedComponents.Breadcrumb
open Routes
open SharedComponents.ReactImageViewer
open SharedComponents.ReactImgOrientation
open SharedComponents

open Feliz
open SharedComponents.Spinners

let private employeeName (employees : Employee list) (employeeId : int) =
    match employees |> List.tryFind (fun e -> e.Id = employeeId) with
    | Some employee -> sprintf "%s %s" employee.Firstname employee.Lastname
    | None -> ""

let private entityName (entities : Entity list) (positions : RentOrderEntityPosition list) =
    let entities =
        positions
        |> List.choose (fun pos ->
            match entities |> List.tryFind (fun e -> e.Id = pos.EntityId) with
            | Some entity -> Some entity.Name
            | None -> None)
    if not entities.IsEmpty then
        entities
        |> List.reduce (fun combined element -> sprintf "%s, %s" combined element)
    else ""

let private deliveredEntities text (model : Model) (positionIds : RentOrderPositionId list) =
    match model.RentOrderWithCompletedTasks with
    | Some order ->
        let positions =
            order.Positions
            |> List.choose (function | RentOrderPosition.RentOrderEntityPosition p -> Some p | _ -> None )
            |> List.filter (fun pos -> positionIds |> List.contains (pos.Id))
        p [ ] [ str (sprintf "%s: %s" text (entityName model.Entities positions)) ]
    | None -> Html.text "Lädt..."

let private materialNames (materials : Material list) (positions : RentOrderMaterialPosition list) =
    let materials =
        positions
        |> List.map (fun pos ->
            let material = materials |> List.find (fun m -> m.Id = pos.MaterialId)
            sprintf "%s %s %s" (pos.Quantity |> Helper.floatValueToString) (material.Unit |> Helper.unitToShortString) material.Name)
    if not materials.IsEmpty then
        materials
        |> List.reduce (fun combined element -> sprintf "%s, %s" combined element)
    else ""

let private deliveredMaterials text model (positionIds : RentOrderPositionId list) =
    match model.RentOrderWithCompletedTasks with
    | Some order ->
        let positions =
            order.Positions
            |> List.choose (function | RentOrderPosition.RentOrderMaterialPosition p -> Some p | _ -> None )
            |> List.filter (fun pos -> positionIds |> List.contains (pos.Id))
        p [ ] [ str (sprintf "%s: %s" text (materialNames model.Materials positions)) ]
    | None -> Html.text "Lädt..."

let private executionDate (rentOrderTaskReport : RentOrderTaskReport option) =
    match rentOrderTaskReport with
    | Some report -> (report.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm"))
    | None -> ""

let private deliveryTaskContent (model : Model) (task : AssignedDeliveryTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Lieferdatum: %s" (task.RentOrderTaskReport |> executionDate)) ]
          deliveredEntities "Ausgelieferte Geräte" model task.PositionIds
          deliveredMaterials "Ausgeliefertes Material" model task.PositionIds
          p [ ] [ str (sprintf "Fahrer: %s" (employeeName model.Drivers task.UserId) ) ] ]

let private selfserviceDeliveryTaskContent (model : Model) (task : AssignedSelfserviceDeliveryTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Lieferdatum: %s" (task.RentOrderTaskReport |> executionDate)) ]
          deliveredEntities "Ausgelieferte Geräte" model task.PositionIds
          deliveredMaterials "Ausgeliefertes Material" model task.PositionIds ]

let private carrierDeliveryTaskContent (model : Model) (task : CarrierDeliveryTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Lieferdatum: %s" (task.TaskReport |> executionDate)) ]
          deliveredEntities "Ausgelieferte Geräte" model task.PositionIds
          deliveredMaterials "Ausgeliefertes Material" model task.PositionIds
          p [ ] [ str (sprintf "Fahrer: %s" (employeeName model.Drivers task.UserId) ) ] ]

let private returnTaskContent (model : Model) (task : AssignedReturnTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Abholdatum: %s" (task.RentOrderTaskReport |> executionDate)) ]
          deliveredEntities "Abgeholte Geräte" model task.PositionIds
          deliveredMaterials "Abgeholtes Material" model task.PositionIds
          p [ ] [ str (sprintf "Fahrer: %s" (employeeName model.Drivers task.UserId) ) ] ]

let private selfserviceReturnTaskContent (model : Model) (task : AssignedSelfserviceReturnTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Rückgabedatum: %s" (task.RentOrderTaskReport |> executionDate)) ]
          deliveredEntities "Abgeholte Geräte" model task.PositionIds
          deliveredMaterials "Abgeholtes Material" model task.PositionIds ]

let private carrierReturnTaskContent (model : Model) (task : CarrierReturnTask) =
    div [ Class "mr-3" ]
        [ p [ ] [ str (sprintf "Abholdatum: %s" (task.TaskReport |> executionDate)) ]
          deliveredEntities "Abgeholte Geräte" model task.PositionIds
          deliveredMaterials "Abgeholtes Material" model task.PositionIds
          p [ ] [ str (sprintf "Fahrer: %s" (employeeName model.Drivers task.UserId) ) ] ]


let private repairTaskContent (task : AssignedServiceTask) =
    str "TODO repair"

let private taskContent model (task : AssignedTask) =
    match task with
    | AssignedTask.AssignedFreeTask t -> str "TODO"
    | AssignedTask.AssignedDeliveryTask t -> deliveryTaskContent model t
    | AssignedTask.CarrierDeliveryTask t -> carrierDeliveryTaskContent model t
    | AssignedTask.CarrierReturnTask t -> carrierReturnTaskContent model t
    | AssignedTask.AssignedReturnTask t -> returnTaskContent model t
    | AssignedTask.AssignedServiceTask t -> repairTaskContent t
    | AssignedTask.AssignedSelfserviceDeliveryTask t -> selfserviceDeliveryTaskContent model t
    | AssignedTask.AssignedSelfserviceReturnTask t -> selfserviceReturnTaskContent model t
    | AssignedTask.AssignedPickTask t -> str "TODO"
    | AssignedTask.AssignedMechanicTask t -> Fable.React.Helpers.nothing

let private taskHeader (task : AssignedTask option) =
    match task with
    | Some task ->
        match task with
        | AssignedTask.AssignedFreeTask t -> "Eigene Aufgabe"
        | AssignedTask.AssignedDeliveryTask t -> "Lieferung"
        | AssignedTask.CarrierDeliveryTask t -> "Lieferung per Spedition"
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> "Selbstabholung"
        | AssignedTask.AssignedReturnTask t -> "Abholung"
        | AssignedTask.CarrierReturnTask t -> "Abholung per Spedition"
        | AssignedTask.AssignedSelfserviceReturnTask t -> "Selbstrückgabe"
        | AssignedTask.AssignedServiceTask t -> ""
        | AssignedTask.AssignedPickTask t -> ""
        | AssignedTask.AssignedMechanicTask t -> ""
    | None -> ""

let private deliveryDispositionDescriptionField (model : Model) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm (taskHeader model.Task)
          div [ Class "d-flex" ]
            [ (match model.Task with
              | Some task -> taskContent model task
              | None -> SharedComponents.Spinners.overlaySpinner RequestState.Active) ] ]

let private taskAddressHeader (task : AssignedTask option) =
    match task with
    | Some task ->
        match task with
        | AssignedTask.AssignedFreeTask t -> "Adresse"
        | AssignedTask.AssignedDeliveryTask t -> "Lieferadresse"
        | AssignedTask.AssignedReturnTask t -> "Abholadresse"
        | AssignedTask.CarrierDeliveryTask t -> "Lieferadresse"
        | AssignedTask.CarrierReturnTask t -> "Abholadresse"
        | AssignedTask.AssignedSelfserviceDeliveryTask t -> "Lieferadresse"
        | AssignedTask.AssignedSelfserviceReturnTask t -> "Abholadresse"
        | AssignedTask.AssignedServiceTask t -> ""
        | AssignedTask.AssignedPickTask t -> ""
        | AssignedTask.AssignedMechanicTask t -> ""
    | None -> ""

let private orderDescription (model : Model) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm (taskAddressHeader model.Task)
          div [ Class "d-flex" ]
            [ (match model.RentOrderWithCompletedTasks with
              | Some order ->
                div [ Class "mr-3" ]
                    [ p [ ] [ str (sprintf "%s %s" order.ExecutionLocation.Street order.ExecutionLocation.HouseNumber) ]
                      p [ ] [ str (sprintf "%s %s" order.ExecutionLocation.ZipCode order.ExecutionLocation.City) ] ]
              | None -> SharedComponents.Spinners.overlaySpinner RequestState.Active) ] ]

let private signatureField (model : Model) (signature : Image option) (signer :string option) =
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kundenunterschrift"
          match signature, model.Signature with
          | Some signature, Some signatureDoc ->
              div [ Class "d-flex flex-column" ]
                [ div [ Class "signature-image-container" ]
                    [ img [ Class "signature-image"
                            HTMLAttr.Src (sprintf "data:image/png;base64, %s" signatureDoc.Content) ] ]
                  match signer with
                  | Some signer ->
                        div [ ]
                            [ str (sprintf "Name: %s" signer) ]
                  | None -> Fable.React.Helpers.nothing ]
          | _, _ -> div [ ]
                      [ p [ ] [ str "Es ist keine Unterschrift vorhanden." ] ] ]

let private commentField comment =
    let commentContent =
        if System.String.IsNullOrWhiteSpace comment then "Es ist kein Kommentar vorhanden." else comment
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Kommentar"
          div [ Class "d-flex" ]
            [ p [ Class "mr-3" ]
                [ str commentContent ] ] ]

let private imagesField (images : Image list) (imageDocuments : DocumentWithContent list) model dispatch =
    let imageContainer index (image : Image) =
        let imageDoc = imageDocuments |> List.tryFind (fun i -> i.Id = image.Id)
        match imageDoc with
        | Some imageDoc ->
            div [ Class "signature-image-container" ]
                [ a [ OnClick (fun _ -> index |> OpenImageViewer |> dispatch)
                      Class "img-thumbnail-href" ]
                    [ ReactImgOrientation.image [ ClassName "signature-image img-thumbnail"
                                                  Src (sprintf "data:image/png;base64, %s" imageDoc.Content) ] ] ]
        | None -> nothing
    let optionalInfoText =
        if images.IsEmpty then str "Es sind keine Fotos vorhanden" else Fable.React.Helpers.nothing
    let viewer =
        let images =
            images |> List.choose (fun image ->
                let imageDoc = imageDocuments |> List.tryFind (fun i -> i.Id = image.Id)
                match imageDoc with
                | Some doc ->
                    { ImageDecorator.src = (sprintf "data:image/png;base64, %s" doc.Content) } |> Some
                | None -> None)
            |> List.toArray
        imageViewer [ CommonProps.ClassName "img-viewer"
                      Images images
                      Visible model.ImageViewerOpen
                      ActiveIndex model.ImageViewerIndex
                      OnClose (fun _ -> dispatch CloseImageViewer) ]
    div [ Class "d-flex flex-column" ]
        [ SharedComponents.Headers.subHeaderForm "Fotos"
          div [ Class "d-flex" ]
            [ div [ Class "d-flex image-container" ]
                [ images |> List.mapi imageContainer |> ofList
                  viewer
                  optionalInfoText ] ] ]

let private deliveryDispositionView model dispatch (rentOrderTask : RentOrderTask) =
    let rentOrderTaskReport =
        match rentOrderTask with
        | RentOrderTask.AssignedDeliveryTask t -> t.RentOrderTaskReport
        | RentOrderTask.AssignedReturnTask t -> t.RentOrderTaskReport
        | RentOrderTask.CarrierDeliveryTask t -> t.TaskReport
        | RentOrderTask.CarrierReturnTask t -> t.TaskReport
        | RentOrderTask.AssignedSelfserviceDeliveryTask t -> t.RentOrderTaskReport
        | RentOrderTask.AssignedSelfserviceReturnTask t -> t.RentOrderTaskReport
        | _ -> None
    let comment, signature, signer, images =
        match rentOrderTaskReport with
        | Some report -> report.Comment, report.Signature, report.Signer, report.Images
        | None -> "", None, None, []
    div [ Class "d-flex flex-column flex-grow-1" ]
      [ div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ deliveryDispositionDescriptionField model ]
            div [ Class "flex-grow-1 p-2"]
              [ orderDescription model ] ]
        div [ Class "d-flex flex-sm-row flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ commentField comment ]
            div [ Class "flex-grow-1 p-2"]
              [ signatureField model signature signer ] ]
        div [ Class "d-flex flex-column"]
          [ div [ Class "flex-grow-1 p-2"]
              [ imagesField images model.Images model dispatch ] ] ]

let rentOrderDispositionBreadcrumb (rentOrderId : RentOrderId) (task : AssignedTask option)=
    let (RentOrderId rentOrderId) = rentOrderId
    let breadcrumb text =
        breadcrumb [
            breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
            breadcrumbLink (Page.RentOrderOverview |> Routes.toPath) "Mietaufträge"
            breadcrumbLink (rentOrderId |> Page.RentOrderDetail |> Routes.toPath) "Mietauftrag"
            breadcrumbStr text
        ]
    match task with
    | Some t ->
        match t with
        | AssignedTask.AssignedFreeTask _ -> breadcrumb "Eigene Aufgabe"
        | AssignedTask.AssignedDeliveryTask _ -> breadcrumb "Lieferung"
        | AssignedTask.AssignedReturnTask _ -> breadcrumb "Abholung"
        | AssignedTask.CarrierDeliveryTask _ -> breadcrumb "Lieferung"
        | AssignedTask.CarrierReturnTask _ -> breadcrumb "Abholung"
        | AssignedTask.AssignedSelfserviceDeliveryTask _ -> breadcrumb "Lieferung"
        | AssignedTask.AssignedSelfserviceReturnTask _ -> breadcrumb "Abholung"
        | AssignedTask.AssignedServiceTask _
        | AssignedTask.AssignedPickTask _
        | AssignedTask.AssignedMechanicTask _ -> Fable.React.Helpers.nothing
    | None -> Fable.React.Helpers.nothing

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ SharedComponents.Spinners.overlaySpinner model.RequestState
          rentOrderDispositionBreadcrumb model.OrderId model.Task
          div [ Id "order-content"]
            [ mainHeaderBar None "Disposition" (Some (fun _ -> dispatch (PrintAsPdf model.CurrentCompletedRentOrderTask))) None
              match model.CurrentCompletedRentOrderTask with
              | Some rentOrderTask ->
                  deliveryDispositionView model dispatch rentOrderTask
              | None -> Fable.React.Helpers.nothing ] ]
