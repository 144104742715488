module Login.State

open Elmish
open Thoth.Json
open Login.Types
open Shared

let authUserCmd (username, password) =
    let login = { Username = username; Password = password } |> (fun l -> Encode.Auto.toString (0,l))
    Cmd.OfPromise.either Communication.postRequest ("/api/login", login) LoginSuccessfull FetchError

let init () : Model * Cmd<Msg> =
    let initialModel = { Username = ""
                         Password = ""
                         Message = NoMessage }
    initialModel, Cmd.none

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | SetUsername name ->
        { model with Username = name }, Cmd.none
    | SetPassword password ->
        { model with Password = password }, Cmd.none
    | SubmitLogin event ->
        event.preventDefault ()
        { model with Message = NoMessage }, (authUserCmd (model.Username, model.Password))
    | LoginSuccessfull response ->
        model, Cmd.none
    | FetchError e ->
        { model with Message = InvalidLogin "Anmeldung ist leider fehlgeschlagen."}, Cmd.none
