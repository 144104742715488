module MasterData.Templates.View


open Fable.React
open Fable.React.Props

open Shared
open Routes

open MasterData.Templates.Types
open SharedComponents.Spinners
open SharedComponents.Headers
open Shared.Entity
open SharedComponents.ReactSelect
open SharedComponents
open SharedComponents.Badges

let private selectFilterProps (model : Model) dispatch =
    let createOptions =
      [| { label = "Geräte"
           value = SelectFilterType.TemplateFilter }
         { label = "Geräte-Gruppen"
           value = SelectFilterType.TemplateGroupFilter } |]

    let getDisplayName (filterType : SelectFilterType) =
      createOptions
      |> Array.find (fun v -> v.value = filterType) |> (fun o -> o.label)

    let selectFilterToDisplay =
      if model.SelectedFilters.IsEmpty then createOptions
      else
        createOptions
        |> Array.filter (fun v -> not (model.SelectedFilters |> List.contains v.value))

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Filter gefunden werden." ]

    let valueElements =
      if model.SelectedFilters.IsEmpty then
        [||]
      else
        let elements =
          model.SelectedFilters
          |> List.map (fun e -> { label = getDisplayName e; value = e })
          |> List.toArray
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Value elements |]

    Array.append
        [| SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Options (selectFilterToDisplay)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.OnChange (SetSelectFilters >> dispatch)
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsSearchable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsMulti true;
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.IsClearable true
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.Placeholder "Filter wählen"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.ClassName "ml-2 overview-select-filter"
           SharedComponents.ReactSelect.CommonPropsMultiSelect<SelectFilterType>.NoOptionsMessage (fun _ -> emptySearchResultContent) |]
        valueElements

let templateRow model dispatch (template : Template) =
    let (TemplateId templateId) = template.Id
    tr [ ]
        [ td []
            [ str template.TemplateBaseInformation.Name ]
          td [ ]
            [ match template.ChildTemplates with
              | Some _ -> badgePill "Geräte-Gruppe" BadgeClassType.Primary
              | None -> badgePill "Gerät" BadgeClassType.Secondary ]
          td [ ]
            [ str template.TemplateBaseInformation.Description ]
          td [ ]
            [ a [ Href (Routes.toPath (Routes.Page.Entity templateId)) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Baugeräte anzeigen" ] ] ] ]


let allTemplateRow model dispatch =
    tr [ ]
        [ td []
            [ str "Alle Baugeräte anzeigen" ]
          td [ ]
            [ badgePill "Alle Geräte" BadgeClassType.Info ]
          td [ ]
            [ str "" ]
          td [ ]
            [ a [ Href (Routes.toPath (Routes.Page.AllEntities)) 
                ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Baugeräte anzeigen" ] ] ] ]

let templatesOverview (model : Model) dispatch =
  div [ Id "masterdata-content" ]
      [ mainHeader "Baugerätekategorien"
        div [ Class "controls d-flex" ]
          [ div [ Class "" ]
              [ SearchBar.searchBar (fun ev -> ev.Value |> SetFilter |> dispatch) model.FilterText "overview-search-bar" ]
            div [ Class "flex-fill" ]
              [ ReactSelect.multiSelectWithoutLabel (selectFilterProps model dispatch) ] ]
        div [ Class "d-flex flex-wrap" ]
            [ table [ Class "table" ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ ]
                                [ str "Name" ]
                              th [ ]
                                [ str "Art"]
                              th [ ]
                                [ str "Beschreibung"]
                              th [ ]
                                [ str "Aktion"] ] ]
                      tbody [ ]
                        [ model.SelectFilterTemplates
                          |> List.map (templateRow model dispatch)
                          |> List.append [ allTemplateRow model dispatch ]
                          |> ofList  ] ] ] ]

let view (model : Model) (dispatch : Msg -> unit) =
  div [ Id "masterdata-container"
        Class "flex-grow-1 d-flex flex-column" ]
      [ SharedComponents.Spinners.overlaySpinner model.TemplateRequestState
        templatesOverview model dispatch ]

