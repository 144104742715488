module Order.RentOrder.Helper

open Order.RentOrder.Types
open Shared
open SharedComponents.DeleteMsgBox
open Shared.RentOrder
open Shared.Entity
open Routes
open SharedComponents.Badges
open SharedComponents
open Feliz
open Client.SharedComponents
open System
open PositionTypes

module Row =
    let apply func = function
        | PositionRow.NewEntityPosition (pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> func
        | PositionRow.EditEntityPosition (pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> func
        | PositionRow.ShowEntityPosition (pos, _) -> pos |> RentOrderPosition.RentOrderEntityPosition |> func
        | PositionRow.NewMaterialPosition (pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> func
        | PositionRow.EditMaterialPosition (pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> func
        | PositionRow.ShowMaterialPosition (pos, _) -> pos |> RentOrderPosition.RentOrderMaterialPosition |> func

    let private toEntityPos = function
        | RentOrderPosition.RentOrderEntityPosition p -> p
        | RentOrderPosition.RentOrderMaterialPosition p -> failwith "Helper function only for safe cases"

    let private toMaterialPos = function
        | RentOrderPosition.RentOrderEntityPosition p -> failwith "Helper function only for safe cases"
        | RentOrderPosition.RentOrderMaterialPosition p -> p

    let map (func : RentOrderPosition -> RentOrderPosition) = function
        | PositionRow.NewEntityPosition (pos, template) ->
            pos |> RentOrderPosition.RentOrderEntityPosition |> func |> toEntityPos
            |> (fun pos -> pos, template) |> PositionRow.NewEntityPosition
        | PositionRow.EditEntityPosition (pos, template) ->
            pos |> RentOrderPosition.RentOrderEntityPosition|> func |> toEntityPos
            |> (fun pos -> pos, template) |> PositionRow.EditEntityPosition
        | PositionRow.ShowEntityPosition (pos, isChecked) ->
            pos |> RentOrderPosition.RentOrderEntityPosition |> func |> toEntityPos
            |> (fun pos -> pos, isChecked) |> PositionRow.ShowEntityPosition
        | PositionRow.NewMaterialPosition (pos, value) ->
            pos |> RentOrderPosition.RentOrderMaterialPosition |> func
            |> toMaterialPos |> (fun pos -> pos, value) |> PositionRow.NewMaterialPosition
        | PositionRow.EditMaterialPosition (pos, value) ->
            pos |> RentOrderPosition.RentOrderMaterialPosition|> func
            |> toMaterialPos |> (fun pos -> pos, value) |> PositionRow.EditMaterialPosition
        | PositionRow.ShowMaterialPosition (pos, isChecked) ->
            pos |> RentOrderPosition.RentOrderMaterialPosition |> func |> toMaterialPos
            |> (fun pos -> pos, isChecked) |> PositionRow.ShowMaterialPosition

    let setTemplateId templateId = function
        | PositionRow.NewEntityPosition (pos, _) ->
            (pos, templateId) |> PositionRow.NewEntityPosition
        | PositionRow.EditEntityPosition (pos, _) ->
            (pos, templateId) |> PositionRow.EditEntityPosition
        | PositionRow.ShowEntityPosition _
        | PositionRow.NewMaterialPosition _
        | PositionRow.EditMaterialPosition _
        | PositionRow.ShowMaterialPosition _ -> failwith "function can only called on new and edit entity pos"

    let isMaterialPosition = function
        | PositionRow.NewMaterialPosition _
        | PositionRow.EditMaterialPosition _
        | PositionRow.ShowMaterialPosition _ -> true
        | _ -> false

    let toRentOrderPosition = apply id


let posNoToString (position : RentOrderPosition) =
    let toString posNo =
        let (PositionNumber posNo) = posNo
        posNo.ToString ()
    match position |> Helper.Position.subPosNo with
    | Some subPos ->
        sprintf "%s.%s" (position |> Helper.Position.posNo |> toString) (subPos |> toString)
    | None ->
        position |> Helper.Position.posNo |> toString

let deleteModal model dispatch =
    match model.DeleteRequested with
    | None ->
        let emptyMsgBox = deleteRequestMessageBox Fable.React.Helpers.nothing
        match model.FormState with
        | FormState.Edit form ->
            match form.EditField with
            | EditField.Nothing _
            // | EditField.EditPositions _
            | EditField.EditProjectInformation _
            | EditField.EditTermOfLease _
            | EditField.EditNewReminder _
            | EditField.EditCustomerAndExecutionInformation -> emptyMsgBox
            | EditField.EditReminder _ ->
                let displayText = sprintf "Wollen Sie die Erinnerung wirklich löschen?"
                deleteRequestMessageBox
                    (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteReminderRequest))
                                                    (fun _ -> dispatch (AbortReminderDelete))
                                                    displayText)
        | FormState.New _
        | FormState.Loading -> emptyMsgBox
    | Some id ->
        let displayText = sprintf "Wollen Sie den Mietauftrag wirklich löschen?"
        deleteRequestMessageBox
            (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                            (fun _ -> dispatch (AbortDelete))
                                            displayText)

let returnDateForAllPositionsIsDisabled (positions : PositionRow list) =
    let positionIsActive (position : RentOrderPosition) =
        let state = position |> Helper.Position.state
        state = RentOrderPositionState.AssignedToDeliver ||
        state = RentOrderPositionState.Delivered ||
        state = RentOrderPositionState.Planned

    positions
    |> List.map Row.toRentOrderPosition
    |> List.exists positionIsActive
    |> not

module Table =
    open Fable.React.Helpers
    open Fable.React
    open Fable.React.Props

    let detailBtn (rentOrder : RentOrderOverview) =
        let (RentOrderId rentOrderId) = rentOrder.Id
        a [ Href (Routes.toPath (Page.RentOrderDetail rentOrderId) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    let infoToolTip (rentOrder : RentOrderOverview) =
        Html.span [
            prop.className "task-tooltip"
            prop.children [
                Html.i [
                    prop.custom ("data-tip", rentOrder.EntitiesInfo)
                    prop.className "fas fa-info-circle date-icon ml-1"
                ]
                ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
            ]
        ]


    let stateBadge (rentOrder : RentOrderOverview) =
        match rentOrder.State with
        | PlacedForPick _ -> badgePill "in Disponierung" BadgeClassType.Info
        | WaitForPick _ -> badgePill "in Kommissionierung" BadgeClassType.Info
        | Placed -> badgePill "erfasst" BadgeClassType.Warning
        | InProgress -> badgePill "aktiv" BadgeClassType.Success
        | RentOrderState.Completed -> badgePill "abgeschlossen" BadgeClassType.Secondary

    let selectOptions =
        [ "erfasst"
          "aktiv"
          "abgeschlossen"
          "in Kommissionierung"
          "in Disponierung" ]

    let selectFilter (selectedValue : string) (rentOrders : RentOrderOverview[]) =
        if selectedValue = "0" then
            rentOrders
            |> Array.filter (fun order ->
                match order.State with
                | Placed -> true
                | PlacedForPick _
                | WaitForPick _
                | InProgress _
                | RentOrderState.Completed -> false)
        elif selectedValue = "1" then
            rentOrders
            |> Array.filter (fun order ->
                match order.State with
                | InProgress -> true
                | PlacedForPick _
                | WaitForPick _
                | Placed _
                | RentOrderState.Completed -> false)
        elif selectedValue = "2" then
            rentOrders
            |> Array.filter (fun order ->
                match order.State with
                | RentOrderState.Completed -> true
                | PlacedForPick _
                | WaitForPick _
                | Placed _
                | RentOrderState.InProgress -> false)
        elif selectedValue = "3" then
            rentOrders
            |> Array.filter (fun order ->
                match order.State with
                | RentOrderState.WaitForPick _ -> true
                | PlacedForPick _
                | InProgress _
                | Placed _
                | RentOrderState.Completed -> false)
        elif selectedValue = "4" then
            rentOrders
            |> Array.filter (fun order ->
                match order.State with
                | RentOrderState.PlacedForPick _ -> true
                | InProgress _
                | WaitForPick _
                | Placed _
                | RentOrderState.Completed -> false)
        else rentOrders