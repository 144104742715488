module Dashboard.View

open Fable.React
open Fable.React.Props
open Dashboard.Types
open SharedComponents.ReactGoogleMap
open SharedComponents.Headers
open SharedComponents.Spinners
open SharedComponents.ReactSelect
open SharedComponents.Dividers
open Routes
open Shared
open Shared.RentOrder
open SharedComponents.Badges
open SharedComponents.Buttons
open Shared.Entity
open Shared.Address
open Feliz
open Feliz.Recharts
open Shared.Dashboard
open Shared.CardFlag


let createOptions (templates : Template list) =
    let templateOptions =
        templates
        |> List.map(fun t ->
            { label = t.TemplateBaseInformation.Name
              value = ObjectType.Template t.Id })
        |> List.toArray
    [| { label = "Alle Mietaufträge"
         value = ObjectType.RentOrder }
       { label = "Alle Geräte"
         value = ObjectType.Entity }
         |] |> Array.append templateOptions

let selectConfiguration (model : Model) dispatch =
    let selectableOptions =
      createOptions model.DashboardInfo.Templates
      |> Array.filter (fun v -> v.value <> model.SelectedObjectType)

    let value = { value = model.SelectedObjectType; label = (createOptions model.DashboardInfo.Templates |> Array.find (fun v -> v.value = model.SelectedObjectType) |> (fun o -> o.label)) }
    [| CommonProps<ObjectType>.IsSearchable true;
       CommonProps<ObjectType>.Value (Some value);
       CommonProps<ObjectType>.Options (selectableOptions)
       CommonProps<ObjectType>.Placeholder "Filter wählen";
       CommonProps<ObjectType>.OnChange (ChangeObjectTypeSelection >> dispatch);
       CommonProps<ObjectType>.NoOptionsMessage (fun _ -> str "Leider haben wir keinen passenden Treffer gefunden.")|]

let locationCenter (model : Model) =
    let isFilterEntityIsEmpty = model.CardFlag.IsEmpty
    let lats =
      if isFilterEntityIsEmpty then
       [ 48.703159 ]
      else
        model.CardFlag
        |> List.map (fun e ->
          match e.Address.Coordinates with
          | None -> None
          | Some a -> Some a.Latitude)
        |> List.choose id
    let lngs =
      if isFilterEntityIsEmpty then
       [ 9.654000 ]
      else
        model.CardFlag
        |> List.map (fun e ->
          match e.Address.Coordinates with
          | None -> None
          | Some a -> Some a.Longitude)
        |> List.choose id

    let centerLat, centerLng =
        calculateCenter lats lngs
    (centerLat, centerLng, lats, lngs, 10)

let private selectedMarkerClassName rentOrderId entityIds (model : Model) =
    match model.SelectedRentOrder, model.SelectedEntities with
    | None, None _
    | Some _, Some _ -> ""
    | Some r, None ->
        match rentOrderId with
        | Some rentOrderId ->
            if rentOrderId = r.Id then
              "selected-marker"
            else ""
        | None -> ""
    | None, Some e ->
        let selectedEntityIds =
            e |> List.map (fun e -> e.Id)
        if selectedEntityIds = entityIds then "selected-marker" else ""

let getMapMarkers (model : Model) dispatch =
    model.CardFlag
    |> List.map (fun e ->
                 match e.Address.Coordinates with
                 | None -> Fable.React.Helpers.nothing
                 | Some coordinate ->
                    match e.CardFlagType with
                    | CardFlagType.RentOrder (rentOrderId, _) ->
                        let mutable content = Fable.React.Helpers.nothing
                        let (RentOrderId rentOrderIdGuid) = rentOrderId
                        let onMouseEnter = (OnMouseEnter (fun _ -> content <- div [ Id "hover-container" ]
                                                                              [ a [ Href (Routes.toPath (Page.RentOrderDetail rentOrderIdGuid)) ]
                                                                                  [ label [ Class "hover-label" ]
                                                                                 [ p [] [ str (sprintf "%s %s" e.Header e.Body  )] ]] ]))
                        let onMouseLeave = (OnMouseLeave (fun _ -> content <- Fable.React.Helpers.nothing ))
                        let onClick = (OnClick (fun _ -> dispatch (FetchRentOrder rentOrderId)))
                        (marker (markerElementWithHoverContent (sprintf "maps-marker-executionlocations %s" (selectedMarkerClassName (rentOrderId |> Some) [] model)) onMouseEnter onMouseLeave onClick content)
                                                   { lat = coordinate.Latitude
                                                     lng = coordinate.Longitude
                                                     text = e.Header })
                    | CardFlagType.Entity entityIds ->
                        let mutable content = Fable.React.Helpers.nothing
                        let onMouseEnter = (OnMouseEnter (fun _ -> content <- div [ Id "hover-container" ]
                                                                              [ a [ ]
                                                                                  [ label [ Class "hover-label" ]
                                                                                 [ p [] [ str (sprintf "%s %s" e.Header e.Body)] ]] ]))
                        let onMouseLeave = (OnMouseLeave (fun _ -> content <- Fable.React.Helpers.nothing ))
                        let onClick = (OnClick (fun _ -> dispatch (SelectEntities entityIds)))
                        (marker (markerElementWithHoverContent (sprintf "maps-marker-executionlocations %s" (selectedMarkerClassName None entityIds model)) onMouseEnter onMouseLeave onClick content)
                                                   { lat = coordinate.Latitude
                                                     lng = coordinate.Longitude
                                                     text = e.Header })
                    | CardFlagType.Template (entityIds, templateId) ->
                        let mutable content = Fable.React.Helpers.nothing
                        let onMouseEnter = (OnMouseEnter (fun _ -> content <- div [ Id "hover-container" ]
                                                                              [ a [ ]
                                                                                  [ label [ Class "hover-label" ]
                                                                                 [ p [] [ str (sprintf "%s %s" e.Header e.Body)] ]] ]))
                        let onMouseLeave = (OnMouseLeave (fun _ -> content <- Fable.React.Helpers.nothing ))
                        let onClick = (OnClick (fun _ -> dispatch (SelectEntities entityIds)))
                        (marker (markerElementWithHoverContent (sprintf "maps-marker-executionlocations %s" (selectedMarkerClassName None entityIds model)) onMouseEnter onMouseLeave onClick content)
                                                   { lat = coordinate.Latitude
                                                     lng = coordinate.Longitude
                                                     text = e.Header }))

let dashboardMap (model : Model) dispatch =
    let centerLat, centerLng, lats, lngs, zoom = locationCenter model // [ model.SelectedObjectType ]
    div [ Class "dashboard-map" ]
        [ SharedComponents.ReactSelect.selectLine (selectConfiguration model dispatch )
          googleMap [| CommonProps.BootstrapURLKeys { key = "AIzaSyASFW97YoSKQe3o0vMBC1opstpId4VUqfI" };
                       CommonProps.Center [|centerLat; centerLng|]
                       CommonProps.Zoom zoom
                       CommonProps.YesIWantToUseGoogleMapApiInternals true
                       // TODO: Remove?
                       //CommonProps.OnGoogleApiLoaded (handleApiLoaded lats lngs)
                        |]
                 (getMapMarkers model dispatch)
        ]


let dashboardCard (headerText : string) (content : ReactElement list) =
    Html.div [
        prop.className "card dashboard-card"
        prop.children [
            Html.div [
                prop.className "card-body"
                prop.children [
                    Html.h4 [
                        prop.className "card-title"
                        prop.text headerText
                    ]

                    Html.p [
                        prop.className "card-text"
                        prop.children content
                    ]
                ]
            ]
        ]
    ]

let welcomeCard (model : Model) (dashboardInfo : DashboardInfoDto) dispatch =
    let label = "Hallo " + model.UserInformation.UserData.DisplayName
    let description =
        if dashboardInfo.UnreadedMessages = 0 then "Es liegen keine Nachrichten für Sie vor."
        else (sprintf "Es liegen %i ungelesene Nachrichten für Sie vor." dashboardInfo.UnreadedMessages)
    dashboardCard label [
        Html.span description
        Html.br [ ]
        Html.a [
            prop.href (Routes.toPath Routes.Page.MessageCenter)
            prop.children [
                Html.i [ prop.className "fas fa-envelope" ]
                Html.span [
                    prop.className "url-text"
                    prop.text "Zu den Nachrichten"
                ]
            ]
        ]
    ]

let shortActionCard =
    let cardContent (description : string) target =
        Html.p [
            prop.className "tag label label-info"
            prop.children [
                Html.a [
                    prop.href (Routes.toPath target)
                    prop.children [
                        Html.i [
                            prop.className "fas fa-plus dashboard-card-icon mr-2"
                        ]
                        Html.label [
                            prop.text description
                        ]
                    ]
                ]
            ]
        ]

    dashboardCard "Schnellaktionen" [
        cardContent "Mietauftrag anlegen" (Routes.Page.RentOrderNewForm)
        cardContent "Serviceauftrag anlegen" (Routes.Page.RepairOrderNewForm)
    ]

let appointmentsCard (dashboardInfo : DashboardInfoDto) =
    let cardContent (appointment : ShortAppointmentInfo) =
        Html.div [
            prop.className "tag label label-info d-flex mb-1"
            prop.children [
                Html.div [
                    prop.className "date mr-3"
                    prop.text (appointment.Date.ToString("dd.MM.yyyy"))
                ]
                Html.div [
                    prop.className "description"
                    prop.text appointment.Text
                ]
            ]
        ]

    dashboardCard "Termine" [
        dashboardInfo.ShortAppointmentInfo |> List.map cardContent |> ofList
        Html.div [
            prop.className "mt-3"
            prop.children [
                Html.a [
                    prop.href (Routes.toPath Page.Appointment)
                    prop.text "Zu meinen Terminen"
                ]
            ]
        ]
    ]

type PieSlice = { name: string; value: int }

let entityUsageCard (dashboardInfo : DashboardInfoDto) =
    let data = [
        { name = "Geräte auf der Baustelle"; value = dashboardInfo.EntitiesWorkload.EntitiesOnExecutionLocation }
        { name = "Geräte im Lager"; value = dashboardInfo.EntitiesWorkload.EntitiesInStock }
        { name = "Geräte unterwegs"; value = dashboardInfo.EntitiesWorkload.EntitiesOnRoad }
    ]

    let cells =
        let color index = if index = 0 then "#ecbf2b" elif index = 1 then "#1d1d1b" else "#7d7d7c"
        data
        |> List.mapi (fun index _ ->
            Recharts.cell [
                cell.fill (color index)
            ])

    let renderCustomLabel (input: IPieLabelProperties) =
        let radius = input.innerRadius + (input.outerRadius - input.innerRadius) * 0.5;
        let radian = System.Math.PI / 180.
        let x = (input.cx + radius * cos (-input.midAngle * radian))
        let y = (input.cy + radius * sin (-input.midAngle * radian))
        Html.text [
            prop.style [
                style.fill color.white
            ]
            prop.x x
            prop.y y
            prop.dominantBaseline.central
            if x > input.cx then prop.textAnchor.startOfText else prop.textAnchor.endOfText
            prop.text (sprintf "%.0f%%" (100. * input.percent))
        ]


    dashboardCard "Geräteauslastung" [
        Recharts.pieChart [
            pieChart.width 400
            pieChart.height 300
            pieChart.children [
                Recharts.pie [
                    pie.data data
                    pie.cx 180
                    pie.cy 150
                    pie.labelLine false
                    pie.label renderCustomLabel
                    //pie.label true
                    pie.outerRadius 120
                    pie.fill "#8884d8"
                    pie.children cells
                ]
                Recharts.tooltip []
            ]
        ]
    ]

let private rentOrderCard (rentOrder : RentOrder) (model : Model) =
    let (RentOrderId rentOrderId) = rentOrder.Id
    let rentOrderPositionEntityIds =
        rentOrder.Positions
        |> List.choose (function
                        | RentOrderEntityPosition pos -> Some pos
                        | RentOrderMaterialPosition _ -> None)
        |> List.map (fun p -> p.EntityId)
    let stateName =
        match rentOrder.State with
        | PlacedForPick _ -> badgePill "in Disponierung" BadgeClassType.Info
        | WaitForPick _ -> badgePill "in Kommissionierung" BadgeClassType.Info
        | Placed -> badgePill "erfasst" BadgeClassType.Warning
        | InProgress -> badgePill "aktiv" BadgeClassType.Success
        | RentOrderState.Completed -> badgePill "abgeschlossen" BadgeClassType.Secondary
    Html.div [
        prop.className "map-detail-box card dashboard-card speech-bubble"
        prop.children [
            Html.div [
                prop.className "card-body d-flex flex-column"
                prop.children [
                    Html.h4 "Mietauftrag"

                    Html.p (sprintf "%s %s" (rentOrder.ExecutionLocation.Street) (rentOrder.ExecutionLocation.HouseNumber))
                    Html.p (sprintf "%s %s" (rentOrder.ExecutionLocation.ZipCode) (rentOrder.ExecutionLocation.City))
                    divider
                    Html.p (sprintf "Projektnummer: %s" rentOrder.ProjectInformation.ProjectNumber)
                    Html.p (sprintf "Bauvorhaben %s" (rentOrder.ProjectInformation.BuildingProjectName))
                    divider
                    Html.div [
                        prop.className "entity-list-scrollable"
                        prop.children [
                            model.DashboardInfo.Entities
                            |> List.filter (fun e -> rentOrderPositionEntityIds |> List.contains e.Id)
                            |> List.groupBy (fun e -> e.TemplateId)
                            |> List.map (fun (templateId, e) ->
                                let (TemplateId templateGuid) = templateId
                                let template = model.DashboardInfo.Templates |> List.find (fun t -> t.Id = templateId)
                                Html.div [
                                    Html.p template.TemplateBaseInformation.Name
                                    e
                                    |> List.map (fun e ->
                                        let (EntityId entityId) = e.Id
                                        if model.UserInformation.IsAdminOrDispatcher || model.UserInformation.IsTechnician then
                                            Html.p [
                                                Html.a [
                                                    prop.href (Routes.toPath (Page.EntityViewForm (templateGuid, entityId)))
                                                    prop.children [
                                                        Html.span [
                                                            prop.className "url-text"
                                                            prop.text e.Name
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        else
                                            Html.p [
                                                Html.strong e.Name
                                            ]
                                    )
                                    |> ofList
                                ]
                            )
                            |> ofList
                            divider
                            stateName
                            if model.UserInformation.IsAdminOrDispatcher then
                                divider
                                Html.a [
                                    prop.href (Routes.toPath (Page.RentOrderDetail rentOrderId))
                                    prop.children [
                                        Html.i [ prop.className "fas fa-info" ]
                                        Html.span [
                                            prop.className "url-text"
                                            prop.text "zum Mietauftrag"
                                        ]
                                    ]
                                ]
                            else Html.none
                        ]
                    ]
                ]
            ]
        ]
    ]

let private entityCard (entities : EntityDashboardDto list) (model : Model) =
    let entityIds = entities |> List.map (fun e -> e.Id)
    let currentAddress = entities.Head.CurrentAddress
    let buildingProjectName = entities.Head.BuildingProjectName
    let projectNumber = entities.Head.ProjectNumber

    div [ Class "map-detail-box card dashboard-card speech-bubble" ]
        [ div [ Class "card-body d-flex flex-column" ]
            [ Html.h4 "Geräte"
              Html.p (sprintf "%s %s" (currentAddress.Street) (currentAddress.HouseNumber))
              Html.p (sprintf "%s %s" (currentAddress.ZipCode) (currentAddress.City))
              divider
              match buildingProjectName, projectNumber with
              | Some buildingProjectName, Some projectNumber ->
                  Html.p (sprintf "Projektnummer: %s" projectNumber)
                  Html.p (sprintf "Bauvorhaben %s" buildingProjectName)
                  divider
              | None, Some projectNumber ->
                  Html.p (sprintf "Projektnummer: %s" projectNumber)
                  divider
              | Some buildingProjectName, None ->
                  Html.p (sprintf "Bauvorhaben %s" buildingProjectName)
                  divider
              | None, None -> Html.none
              Html.div [
                  prop.className "entity-list-scrollable"
                  prop.children [
                      (model.DashboardInfo.Entities
                      |> List.filter (fun e -> entityIds |> List.contains e.Id)
                      |> List.groupBy (fun e -> e.TemplateId)
                      |> List.map (fun (templateId, e) ->
                                  let (TemplateId templateGuid) = templateId
                                  let template = model.DashboardInfo.Templates |> List.find (fun t -> t.Id = templateId)
                                  div [ Class ""]
                                    [ p[ ]
                                        [ str (template.TemplateBaseInformation.Name) ]
                                      e
                                      |> List.map (fun e ->
                                          let (EntityId entityId) = e.Id
                                          if model.UserInformation.IsAdminOrDispatcher || model.UserInformation.IsTechnician then
                                              p [ ]
                                                [ a [ Href (Routes.toPath (Page.EntityViewForm (templateGuid, entityId))) ]
                                                    [ span [ Class "url-text" ] [ str e.Name ] ] ]
                                          else
                                              p [ ]
                                                [ strong [ ]
                                                    [ str e.Name ] ] )
                                      |> ofList ])
                      |> ofList)
                  ]
              ]

          ]
          Html.div [ prop.className "speech-bubble-arrow" ]

          ]



let private selectedSideContent model =
    match model.SelectedRentOrder, model.SelectedEntities with
    | Some _, Some _
    | None, None -> Html.none
    | None, Some selectedEntities ->
        entityCard selectedEntities model
    | Some selectedRentOrder, None ->
        rentOrderCard selectedRentOrder model

let dashboardOverview (model : Model) dashboardInfo dispatch =
    Html.div [
        prop.className "dashboard-overview flex-grow-1 d-flex"
        prop.children [
            Html.div [
                prop.id "dashboard-info-column"
                prop.className "d-flex flex-column"
                prop.children [
                    welcomeCard model dashboardInfo dispatch
                    if Shared.RoleValidation.containsRoles model.UserInformation.UserData.AssignedRoles Shared.RoleNames.Dispatcher then
                        shortActionCard
                    else Html.none
                    entityUsageCard dashboardInfo
                ]
            ]
            Html.div [
                prop.id "dashboard-map-info-column"
                prop.className "d-flex flex-column"
                prop.children [
                    appointmentsCard dashboardInfo
                    selectedSideContent model
                ]
            ]
            Html.div [
                prop.className "dashboard-sidebar-scrollable flex-grow-1"
                prop.children [
                    dashboardMap model dispatch
                ]
            ]
        ]
    ]

let view (model:Model) (dispatch: Msg -> unit) =
    Html.div [
        prop.id "dashboard-container"
        prop.className "flex-grow-1 d-flex"
        prop.children [
            dashboardOverview model model.DashboardInfo dispatch
        ]
    ]
