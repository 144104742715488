module Configuration.Workflow.View

open System
open Fable.React
open Fable.React.Props
open Configuration.Workflow.Types
open SharedComponents.Headers
open SharedComponents
open Shared.Configuration
open SharedComponents.FileUploads
open SharedComponents.Buttons
open Fable.Core.JsInterop
open Elmish.React.Common

open Shared
open SharedComponents.Spinners
open Shared.Address

let private workflowBaseConfiguration (workflow : Workflow) (currentEditField : EditField) dispatch =
    let workflowBasePartDisabled = (match currentEditField with | EditField.EditBaseWorkflow _ -> false | _ -> true)
    let formDisabledClass = if workflowBasePartDisabled then "disabled-form" else ""
    div [ Class ("p-2 " + formDisabledClass) ]
      [ subHeaderByFormState (match currentEditField with | EditField.EditBaseWorkflow _ -> true | _ -> false)
          "Basis"
          (fun _ -> EditBaseWorkflow |> dispatch) "workflowbaseinfo"
          (fun _ -> dispatch SaveConfiguration) "workflowbaseinfo"
          (fun _ -> EndEdit |> dispatch) ""
          SharedComponents.Types.FormState.View
        Form.checkBox (fun ev -> ev.Checked |> SetCloseRentOrderOption |> dispatch) workflow.CloseRentOrderAfterReturnTaskFinished "Mietauftrag automatisch abschließen, nach Abschluss der letzten Abholaufgabe" workflowBasePartDisabled "className"  ]

let private form (c : ConfigurationForm) (model : Model) dispatch =
    div [ ]
        [ div [ Class "flex-row p-2 flex-column"]
            [ mainHeader "Workflow" ]
          div [ Class "flex-sm-row flex-column p-2" ]
            [ workflowBaseConfiguration c.Configuration.Workflow c.EditField dispatch ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ ]
      [ overlaySpinner model.RequestState
        div [ Class "d-flex flex-grow-1 flex-column p-2" ]
         [ (match model.FormState with
            | Edit c -> form c model dispatch
            | Loading -> Fable.React.Helpers.nothing) ] ]
