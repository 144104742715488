module CarrierManagement.Helper
open Shared.Carrier
open Routes
open Shared

module Table =
    open Fable.React.Helpers
    open Fable.React
    open Fable.React.Props

    let detailBtn (carrier : Carrier) =
        let (CarrierId carrierId) = carrier.Id
        a [ Href (Routes.toPath (Page.CarrierManagementViewForm carrierId) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]