module Order.RentOrder.View

open Order.RentOrder.Types
open Order.RentOrder.Helper
open SharedComponents
open Shared
open SharedComponents.Headers
open SharedComponents.Types
open System
open SharedComponents.Alerts
open Validation
open Shared.Entity
open Shared.RentOrder
open Shared.TaskTypes
open SharedComponents.Badges
open SharedComponents.Breadcrumb
open SharedComponents.Buttons
open Order.RentOrder.Helper.Table
open Routes
open Shared.Material

open Fable.React
open Fable.React.Props

let private driverName model driverId =
    model.FetchedTypes.Drivers
    |> List.tryFind (fun d -> d.Id = driverId)
    |> (function
        | Some d -> sprintf "%s %s" d.Firstname d.Lastname
        | None -> "")

let getInvalidText (formValidationOption : FormValidation option) key  =
    match formValidationOption with
    | None -> str ""
    | Some formValidation ->
      match formValidation.FormValid with
      | Valid -> str ""
      | NotValid _ ->
          if (formValidation.Validations |> List.map (fun f -> f.Key) |> List.contains key) then
            match (formValidation.Validations |> List.find (fun f -> f.Key = key)).Value with
            | Valid -> str ""
            | NotValid t -> Form.invalidSpan t
          else str ""

let private invalidMessage (formValidationOption : FormValidation option) key =
    match formValidationOption with
    | None -> None
    | Some formValidation ->
      match formValidation.FormValid with
      | Valid -> None
      | NotValid _ ->
          if (formValidation.Validations |> List.map (fun f -> f.Key) |> List.contains key) then
            match (formValidation.Validations |> List.find (fun f -> f.Key = key)).Value with
            | Valid -> None
            | NotValid t ->
              Some { MessageType = MessageType.Warning
                     Message = t }
          else None

let private hasRentOrderAssignedDeliveryPosition (rentOrder : RentOrder) =
    rentOrder.Positions
    |> List.exists (fun p -> p |> Helper.Position.state = RentOrderPositionState.AssignedToDeliver)

let private executionDate (rentOrderTaskReport : RentOrderTaskReport option) =
    match rentOrderTaskReport with
    | Some report -> (report.Timestamp.ToLocalTime().ToString("dd.MM.yyyy HH:mm"))
    | None -> ""

let private deliveryDispositionRow model (task : DeliveryTaskType) =
    let (stateBadge, showDetails), TaskId taskId, RentOrderId rentOrderId, driverName, deliveryDate =
        match task with
        | DeliveryTaskType.DeliveryTask t -> ((badgePill "offen" BadgeClassType.Info), false), t.TaskId, t.RentOrderId, "", "Offen"
        | DeliveryTaskType.AssignedDeliveryTask t ->
            (match t.State with
            | DeliveryTaskState.Planned _ -> (badgePill "eingelant" BadgeClassType.Info), false
            | DeliveryTaskState.Unloading _
            | DeliveryTaskState.Loading _
            | DeliveryTaskState.InDelivery _ -> (badgePill "in Ausführung" BadgeClassType.Info), false
            | DeliveryTaskState.Delivered _ -> (badgePill "ausgeliefert" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, driverName model t.UserId, executionDate t.RentOrderTaskReport
    tr [ Key (taskId |> string) ]
        [ td [ ]
            [ str deliveryDate ]
          td [ ]
            [ str driverName ]
          td [ ]
            [ stateBadge ]
          td [ ]
            [ (if showDetails then
                a [ Href (Routes.toPath (Routes.Page.RentOrderDispositionDetail (rentOrderId, taskId))) ]
                    [ str "Details"]
               else Fable.React.Helpers.nothing) ] ]

let private masterOfLoadingName model (userId : int) =
    sprintf "%s (%s)" (driverName model userId) "verantwortlich für Verladung"

let private taskDispositionRow model (task : RentOrderTask) =
    let deliveryBadge = (badgePill "Lieferung" BadgeClassType.Light)
    let selfserviceDeliveryBadge = (badgePill "Selbstabholung" BadgeClassType.Light)
    let selfserviceReturnBadge = (badgePill "Selbstrückgabe" BadgeClassType.Light)
    let returnBadge = (badgePill "Abholung" BadgeClassType.Light)
    let (taskBadge, stateBadge, showDetails), TaskId taskId, RentOrderId rentOrderId, driverName, deliveryDate =
        match task with
        | RentOrderTask.CarrierDeliveryTask t ->
            (deliveryBadge, (badgePill "ausgeliefert (Spedition)" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, driverName model t.UserId, t.DeliveredDate.ToShortDateString()
        | RentOrderTask.DeliveryTask t ->
            (deliveryBadge, (badgePill "offen" BadgeClassType.Info), false), t.TaskId, t.RentOrderId, "", ""
        | RentOrderTask.AssignedDeliveryTask t ->
            if t.AllowMultipleDrivers then
                (deliveryBadge, (badgePill "verladen" BadgeClassType.Info), false), t.TaskId, t.RentOrderId, masterOfLoadingName model t.UserId, executionDate t.RentOrderTaskReport
            else
                (match t.State with
                | DeliveryTaskState.Planned _ -> deliveryBadge, (badgePill "eingeplant" BadgeClassType.Info), false
                | DeliveryTaskState.Unloading _
                | DeliveryTaskState.Loading _
                | DeliveryTaskState.InDelivery _ -> deliveryBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
                | DeliveryTaskState.Delivered _ -> deliveryBadge, (badgePill "ausgeliefert" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, driverName model t.UserId, executionDate t.RentOrderTaskReport
        | RentOrderTask.ReturnTask t ->
            (returnBadge, (badgePill "offen" BadgeClassType.Info), false), t.TaskId, t.RentOrderId, "", ""
        | RentOrderTask.AssignedReturnTask t ->
            if t.AllowMultipleDrivers then
                (returnBadge, (badgePill "verladen" BadgeClassType.Info), false), t.TaskId, t.RentOrderId, masterOfLoadingName model t.UserId, executionDate t.RentOrderTaskReport
            else
                (match t.State with
                | ReturnTaskState.Planned _ -> returnBadge, (badgePill "eingeplant" BadgeClassType.Info), false
                | ReturnTaskState.DrivingHome _
                | ReturnTaskState.Loading _
                | ReturnTaskState.Approaching _ -> returnBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
                | ReturnTaskState.Returned _ ->
                    returnBadge, (badgePill "abgeholt" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, driverName model t.UserId, (executionDate t.RentOrderTaskReport)
        | RentOrderTask.CarrierReturnTask t ->
            (returnBadge, (badgePill "abgeholt (Spedition)" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, driverName model t.UserId, t.DeliveredDate.ToShortDateString()
        | RentOrderTask.AssignedMechanicTask(_) -> failwith "Not Implemented"
        | RentOrderTask.PickTask(_) -> failwith "Not Implemented"
        | RentOrderTask.AssignedPickTask(_) -> failwith "Not Implemented"
        | RentOrderTask.AssignedSelfserviceReturnTask t ->
            (match t.State with
            | SelfserviceReturnTaskState.Planned _ -> selfserviceReturnBadge, (badgePill "eingeplant" BadgeClassType.Info), false
            | SelfserviceReturnTaskState.Started _ -> selfserviceReturnBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
            | SelfserviceReturnTaskState.Returned _ ->
                selfserviceReturnBadge, (badgePill "zurückgebracht" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, "Disponent", (executionDate t.RentOrderTaskReport)
        | RentOrderTask.AssignedSelfserviceDeliveryTask t ->
            (match t.State with
            | SelfserviceDeliveryTaskState.Planned _ -> selfserviceDeliveryBadge, (badgePill "eingeplant" BadgeClassType.Info), false
            | SelfserviceDeliveryTaskState.Started _ -> selfserviceDeliveryBadge, (badgePill "in Ausführung" BadgeClassType.Info), false
            | SelfserviceDeliveryTaskState.Delivered _ -> selfserviceDeliveryBadge, (badgePill "abgeholt" BadgeClassType.Info), true), t.TaskId, t.RentOrderId, "Disponent", (executionDate t.RentOrderTaskReport)
    tr [ Key (taskId |> string) ]
        [ td [ ]
            [ taskBadge ]
          td [ ]
            [ str deliveryDate ]
          td [ ]
            [ str driverName ]
          td [ ]
            [ stateBadge ]
          td [ ]
            [ (if showDetails then
                a [ Href (Routes.toPath (Routes.Page.RentOrderDispositionDetail (rentOrderId, taskId))) ]
                    [ str "Details"]
               else Fable.React.Helpers.nothing) ] ]

let private taskDispositionTable (form : RentOrderForm) (model : Model) dispatch =
    div [ Class "table-responsive" ]
      [ table [ Class "table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                      [ str "Aufgabe" ]
                    th [ ]
                      [ str "Datum" ]
                    th [ ]
                      [ str "Fahrer" ]
                    th [ ]
                      [ str "Status" ]
                    th [ ]
                      [ str "" ] ] ]
            tbody [ ]
              [ form.RentOrder.Tasks
                |> List.map
                    (function
                    | RentOrderTask.AssignedDeliveryTask t -> Some  (RentOrderTask.AssignedDeliveryTask t)
                    | RentOrderTask.DeliveryTask t -> Some  (RentOrderTask.DeliveryTask t)
                    | RentOrderTask.AssignedReturnTask t -> Some (RentOrderTask.AssignedReturnTask t)
                    | RentOrderTask.ReturnTask t -> Some (RentOrderTask.ReturnTask t)
                    | RentOrderTask.CarrierDeliveryTask t -> Some (RentOrderTask.CarrierDeliveryTask t)
                    | RentOrderTask.CarrierReturnTask t -> Some (RentOrderTask.CarrierReturnTask t)
                    | RentOrderTask.AssignedSelfserviceDeliveryTask t -> Some (RentOrderTask.AssignedSelfserviceDeliveryTask t)
                    | RentOrderTask.AssignedSelfserviceReturnTask t -> Some (RentOrderTask.AssignedSelfserviceReturnTask t)
                    | _ -> None)
                |> List.choose id
                |> List.map (taskDispositionRow model)
                |> ofList ] ] ]

let buttonElement text onClick active disabled =
    button [ Class (sprintf "btn btn-primary %s" (if active then "active" else ""))
             OnClick onClick
             Disabled disabled ]
      [ str text ]

open Feliz
open SharedComponents.Spinners
open Sww.Frontend.ReactTable

let private orderNewForm (form : RentOrderNewForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    let isRentOrderCompleted = form.RentOrder.State = RentOrderState.Completed
    let executionLocation = form.RentOrder.ExecutionLocation
    let projectInformation = form.RentOrder.ProjectInformation
    Html.div [
        prop.className "d-flex flex-column flex-grow-1"
        prop.children [
            Html.div [
                prop.className "d-flex"
                prop.children [
                    Html.div [
                        prop.className "flex-grow-1 p-2"
                        prop.children [
                            subHeaderForm "Lieferadresse"
                            Form.Input.inlineInput((fun ev -> dispatch (SetCompany ev)), executionLocation.CompanyName |> Option.defaultValue "",
                                                    inputLabel = "Firma", validation = (form.FormValidation, "company"), cyPostFix = "company")
                            Form.Input.inlineInput((fun ev -> dispatch (SetContactPerson ev)), executionLocation.ContactPerson |> Option.defaultValue "",
                                                     inputLabel = "Ansprechpartner", validation = (form.FormValidation, "contact-person"), cyPostFix = "contact-person")
                            Form.Input.inlineInput((fun ev -> dispatch (SetStreet ev)), executionLocation.Street,
                                                    inputLabel = "Straße", validation = (form.FormValidation, "street"), required = true, cyPostFix = "street")
                            Form.Input.inlineInput((fun ev -> dispatch (SetHouseNumber ev)), executionLocation.HouseNumber,
                                                     inputLabel = "Hausnummer", cyPostFix = "housenumber")
                            Form.Input.inlineInput((fun ev -> dispatch (SetZipCode ev)), executionLocation.ZipCode,
                                                     inputLabel = "PLZ", cyPostFix = "zipcode")
                            Form.Input.inlineInput((fun ev -> dispatch (SetCity ev)), executionLocation.City,
                                                   inputLabel = "Stadt", validation = (form.FormValidation, "city"), required = true, cyPostFix = "city")
                            Html.div [
                                prop.className "d-flex flex-row align-items-center entity-delivery-type-group"
                                prop.children [
                                    Html.span [
                                        prop.className "entity-delivery-type-label mr-3"
                                        prop.text "Gerätelieferung"
                                    ]
                                    Html.div [
                                        prop.className "btn-group flex-grow-1"
                                        prop.role "group"
                                        prop.custom ("aria-label", "Basic example")
                                        prop.children [
                                            buttonElement "Standard" (fun _ -> SetDefaultDelivery |> dispatch) (not form.PickEntities) false
                                            buttonElement "Mit Kommissionierung" (fun _ -> SetPickEntities |> dispatch) form.PickEntities false
                                        ]
                                    ]
                                ]
                            ]
                            Form.requiredLegend
                        ]
                    ]
                    Html.div [
                        prop.className "flex-grow-1 p-2"
                        prop.children [
                            subHeaderForm "Projektinformation"
                            Form.Input.inlineInput((fun ev -> dispatch (SetProjectNumber ev)), projectInformation.ProjectNumber,
                                                     inputLabel = "Projektnummer", cyPostFix = "projectnumber")
                            Form.Input.inlineInput((fun ev -> dispatch (SetBuildingProjectName ev)), projectInformation.BuildingProjectName,
                                                     inputLabel = "Bauvorhaben", cyPostFix = "buildingprojectname")
                            Form.textArea (fun ev -> dispatch (SetComment ev)) projectInformation.Comment "Kommentar" false ""
                        ]
                    ]
                ]
            ]
            if not form.PickEntities then
                Html.div [
                    match model.Configuration with
                    | Some configuration ->
                        Positions.view
                            form.RentOrder
                            configuration
                            PositionTypes.ComponentState.NewPosition
                            model.UserData
                            form.FormValidation
                            model.SaveRequested
                            (fun withoutDelivery positions -> (withoutDelivery, positions) |> SetPositions |> dispatch)
                            (fun _ -> dispatch FetchRentOrder)
                    | None -> Html.none
                ]
            else
                Html.div [
                    prop.className "d-flex flex-row align-items-center"
                    prop.children [
                        Html.span [
                            prop.className "mr-2"
                            prop.text "Mietzeitraum:"
                        ]
                        ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                          form.RentStartDate
                          (SetRentStartDate >> dispatch)
                          form.RentStartDate
                          form.RentEndDate
                          DateTime.UtcNow.Date
                          [| |]
                          false
                        Html.span [
                            prop.className "mx-2"
                            prop.text "-"
                        ]
                        ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                          form.RentEndDate
                          (SetRentEndDate >> dispatch)
                          form.RentStartDate
                          form.RentEndDate
                          DateTime.UtcNow.Date
                          [| |]
                          false
                    ]
                ]
            Html.div [
                prop.className "controls d-flex"
                prop.children [
                    Buttons.primaryButtonWithFnctAndIsDisabled (fun _ -> dispatch RequestSave) (model.SaveState = SaveState.Saving) "Auftrag anlegen" "ml-auto"
                ]
            ]
        ]
    ]

let private orderEditForm (form : RentOrderForm) (model : Model) (dispatch : Msg -> unit) (formState : SharedComponents.Types.FormState) =
    let positionsSelected = not model.SelectedRentOrderPositionIds.IsEmpty
    let releaseDate = model.PositionReleaseDate
    let isRentOrderCompleted = form.RentOrder.State = RentOrderState.Completed
    let positionsWithDeliveredState =
        form.RentOrder.Positions
        |> List.filter (fun p -> p |> Helper.Position.state = RentOrderPositionState.Delivered)
    let executionLocation = form.RentOrder.ExecutionLocation
    let addressPartDisabled = (match form.EditField with | EditField.EditCustomerAndExecutionInformation _ -> false | _ -> true)
    let projectInformation = form.RentOrder.ProjectInformation
    let projectInformationPartDisabled = (match form.EditField with | EditField.EditProjectInformation _ -> false | _ -> true)
    let disabledClass = if addressPartDisabled then "disabled-form" else ""
    let projectInformationPartDisabledClass = if projectInformationPartDisabled then "disabled-form" else ""
    Html.div [
        prop.className "d-flex flex-column flex-grow-1"
        prop.children [
            Html.div [
                prop.className "d-flex"
                prop.children [
                    Html.div [
                        prop.className ("flex-grow-1 p-2 " + disabledClass)
                        prop.children [
                            if form.RentOrder.State <> RentOrderState.Completed then
                                subHeaderByFormState (match form.EditField with | EditField.EditCustomerAndExecutionInformation _ -> true | _ -> false)
                                    "Lieferadresse"
                                    (fun _ -> EditCustomerAndExecutionInformation |> dispatch) ""
                                    (fun _ -> dispatch SaveRentOrderForm) ""
                                    (fun _ -> EndEdit |> dispatch) ""
                                    formState
                            else subHeaderForm "Lieferadresse"
                            Form.Input.inlineInput((SetCompany >> dispatch), executionLocation.CompanyName |> Option.defaultValue "",
                                                 inputLabel = "Firma", validation = (form.FormValidation, "company"), isDisabled = addressPartDisabled, cyPostFix = "company")
                            Form.Input.inlineInput((SetContactPerson >> dispatch), executionLocation.ContactPerson |> Option.defaultValue "",
                                                 inputLabel = "Ansprechpartner", validation = (form.FormValidation, "contact-person"),
                                                 isDisabled = addressPartDisabled, cyPostFix = "contact-person")
                            Form.Input.inlineInput((SetStreet >> dispatch), executionLocation.Street,
                                                 inputLabel = "Straße", validation = (form.FormValidation, "street"), required = true,
                                                 isDisabled = addressPartDisabled, cyPostFix = "street")
                            Form.Input.inlineInput((SetHouseNumber >> dispatch), executionLocation.HouseNumber,
                                                 inputLabel = "Hausnummer", isDisabled = addressPartDisabled, cyPostFix = "housenumber")
                            Form.Input.inlineInput((SetZipCode >> dispatch), executionLocation.ZipCode,
                                                 inputLabel = "PLZ", isDisabled = addressPartDisabled, cyPostFix = "zipcode")
                            Form.Input.inlineInput((SetCity >> dispatch), executionLocation.City,
                                                 inputLabel = "Stadt", validation = (form.FormValidation, "city"), required = true,
                                                 isDisabled = addressPartDisabled, cyPostFix = "city")
                            Html.div [
                                prop.className "d-flex flex-row align-items-center entity-delivery-type-group"
                                prop.children [
                                    Html.span [
                                        prop.className "entity-delivery-type-label mr-3"
                                        prop.text "Gerätelieferung" ]
                                    Html.div [
                                        prop.className "btn-group flex-grow-1"
                                        prop.role "group"
                                        prop.custom ("aria-label", "Basic example")
                                        prop.children [
                                            buttonElement "Standard" (fun _ -> SetDefaultDelivery |> dispatch) (not form.PickEntities) true
                                            buttonElement "Mit Kommissionierung" (fun _ -> SetPickEntities |> dispatch) form.PickEntities true
                                        ]
                                    ]
                                ]
                            ]
                            Form.requiredLegend
                        ]
                    ]
                    Html.div [
                        prop.className ("flex-grow-1 p-2 " + projectInformationPartDisabledClass)
                        prop.children [
                            subHeaderByFormState (match form.EditField with | EditField.EditProjectInformation _ -> true | _ -> false)
                                "Projektinformation"
                                (fun _ -> EditProjectInformation |> dispatch ) ""
                                (fun _ -> dispatch SaveRentOrderForm) ""
                                (fun _ -> EndEdit |> dispatch) ""
                                formState
                            Form.Input.inlineInput(ignore, form.RentOrder.OrderNumber,
                                                 inputLabel = "Auftragsnummer", isDisabled = true, cyPostFix = "order-number")
                            Form.Input.inlineInput((SetProjectNumber >> dispatch), projectInformation.ProjectNumber,
                                                 inputLabel = "Projektnummer", isDisabled = projectInformationPartDisabled, cyPostFix = "projectnumber")
                            Form.Input.inlineInput((SetBuildingProjectName >> dispatch), projectInformation.BuildingProjectName,
                                                 inputLabel = "Bauvorhaben", isDisabled = projectInformationPartDisabled, cyPostFix = "buildingprojectname")
                            Form.textArea (SetComment >> dispatch) projectInformation.Comment "Kommentar" projectInformationPartDisabled ""
                        ]
                    ]
                ]
            ]

            Html.div [
                if isRentOrderCompleted then subHeaderForm "Geräte"
                elif form.PickEntities then
                    match form.RentOrder.State with
                    | PlacedForPick _ ->
                        subHeaderByFormState (match form.EditField with | EditField.EditTermOfLease _ -> true | _ -> false)
                            "Geräte"
                            (fun _ -> EditTermOfLease |> dispatch) ""
                            (fun _ -> dispatch SaveRentOrderForm) ""
                            (fun _ -> EndEdit |> dispatch) ""
                            formState
                    | WaitForPick _
                    | _ -> subHeaderForm "Geräte"
                else
                    Html.none
                getAlert model.DetailRentOrderMessage
            ]
            if not form.PickEntities then
                match model.Configuration with
                | Some configuration ->
                    Positions.view form.RentOrder
                        configuration
                        PositionTypes.ComponentState.Show
                        model.UserData
                        form.FormValidation
                        model.SaveRequested
                        (fun withoutDelivery positions -> (withoutDelivery, positions) |> SetPositions |> dispatch)
                        (fun _ -> dispatch FetchRentOrder)
                | None -> Html.none
            else
                Html.div [
                    prop.className "d-flex flex-row align-items-center"
                    prop.children [
                        Html.span [
                            prop.className "mr-2"
                            prop.text "Mietzeitraum:"
                        ]
                        ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                            form.RentStartDate
                            (SetRentStartDate >> dispatch)
                            form.RentStartDate
                            form.RentEndDate
                            DateTime.UtcNow.Date
                            [| |]
                            (match form.EditField with | EditField.EditTermOfLease _ -> false | _ -> true)
                        Html.span [
                            prop.className "mx-2"
                            prop.text "-"
                        ]
                        ReactDatepicker.datepickerWithoutLabelWithEndAndStartDate
                            form.RentEndDate
                            (SetRentEndDate >> dispatch)
                            form.RentStartDate
                            form.RentEndDate
                            DateTime.UtcNow.Date
                            [| |]
                            (match form.EditField with | EditField.EditTermOfLease _ -> false | _ -> true)
                    ]
                ]
            ]
        ]


let private rentOrderNewFormView (form : RentOrderNewForm) (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        mainHeader "Mietauftrag anlegen"
        getAlert model.DetailRentOrderMainMessage
        orderNewForm form model dispatch FormState.New
    ]

let reminderEditNewRow formState (dispatch : Msg -> unit) index (reminder : Reminder) =
    tr [ Key (reminder.Id.ToString()) ]
        [ td [ ]
            [ Buttons.iconButton "fas fa-times" (fun _ -> index |> RemoveReminder |> dispatch) ]
          td [ ]
            [ Form.inputWithoutLabel
                (fun ev -> (index, ev.Value) |> SetReminderDescription |> dispatch)
                reminder.Description "Beschreibung" (formState <> New) ]
          td [ ]
            [ ReactDatepicker.datepickerWithoutLabel
                reminder.TargetDate
                (fun ev -> (index, ev) |> SetReminderTargetDate |> dispatch)
                false ] ]

let editReminderRow formState isRentOrderCompleted (dispatch : Msg -> unit) index (reminder : Reminder) =
    let notEditableRow =
        tr [ Key (reminder.Id.ToString()) ]
            [ td [ ]
                [ ]
              td [ ]
                [ str reminder.Description ]
              td [ ]
                [ str (reminder.TargetDate.ToString("dd.MM.yyyy")) ] ]
    let editableRow =
        tr [ Key (reminder.Id.ToString()) ]
            [ td [ ]
                [ match formState with
                  | EditField.EditReminder reminderId when reminderId = reminder.Id ->
                      Buttons.iconButton "fas fa-undo" (fun _ -> EndEdit |> dispatch)
                      Buttons.iconButton "fas fa-save" (fun _ -> SaveRentOrderForm |> dispatch)
                      Buttons.iconButtonToOpenModal "fas fa-trash" (fun _ -> OpenDeleteReminderModal |> dispatch) "#delete-messagebox-form-modal"
                  | _ ->
                      Buttons.iconButton "fas fa-pen" (fun _ -> reminder.Id |> EditReminder |> dispatch)
                ]
              td [ ]
                [ match formState with
                  | EditField.EditReminder reminderId when reminderId = reminder.Id ->
                      Form.inputWithoutLabel
                        (fun ev -> (index, ev.Value) |> SetReminderDescription |> dispatch)
                        reminder.Description "Beschreibung" false
                  | _ -> str reminder.Description ]
              td [ ]
                [ match formState with
                  | EditField.EditReminder reminderId when reminderId = reminder.Id ->
                      ReactDatepicker.datepickerWithoutLabel
                        reminder.TargetDate
                        (fun ev -> (index, ev) |> SetReminderTargetDate |> dispatch)
                        false
                  | _ ->  str (reminder.TargetDate.ToString("dd.MM.yyyy")) ] ]

    if isRentOrderCompleted then notEditableRow else editableRow

let reminderTable (reminders : Reminder list) formState isRentOrderCompleted (dispatch : Msg -> unit) =
    Html.div [
        prop.className "table"
        prop.children [
            Html.table [
                prop.className "table"
                prop.children [
                    Html.thead [
                        Html.tr [
                            Html.th [
                                prop.text "Aktion"
                            ]
                            Html.th [
                                prop.text "Beschreibung"
                            ]
                            Html.th [
                                prop.text "Datum"
                            ]
                        ]
                    ]
                    Html.tbody [
                        reminders
                        |> List.mapi (fun i r ->
                          match formState with
                          | EditField.EditNewReminder reminder ->
                              if reminder |> List.map (fun r -> r.Id) |> List.contains r.Id then reminderEditNewRow FormState.New dispatch i r
                              else editReminderRow formState isRentOrderCompleted dispatch i r
                          | EditField.EditCustomerAndExecutionInformation _
                          | EditField.EditProjectInformation _
                          | EditField.EditTermOfLease _
                          | EditField.EditReminder _
                          | EditField.Nothing ->
                              editReminderRow formState isRentOrderCompleted dispatch i r)
                        |> prop.children
                    ]
                ]
            ]
        ]
    ]

let private tabs (form : RentOrderForm) model dispatch =
    let isRentOrderCompleted = form.RentOrder.State = RentOrderState.Completed
    Html.div [
        prop.id "order-tabs"
        prop.children [
            Html.ul [
                prop.classes [ "nav"; "nav-tabs" ]
                prop.id "myTab"
                prop.role "tablist"
                prop.children [
                    Html.li [
                        prop.className "nav-item"
                        prop.role "presentation"
                        prop.children [
                            Html.a [
                                prop.id "disposition-tab"
                                prop.classes [ "nav-link"; "active" ]
                                prop.custom ("data-toggle", "tab")
                                prop.href "#disposition"
                                prop.role "tab"
                                prop.text "Disposition"
                            ]
                        ]
                    ]
                    Html.li [
                        prop.className "nav-item"
                        prop.role "presentation"
                        prop.children [
                            Html.a [
                                prop.id "reminder-tab"
                                prop.className "nav-link"
                                prop.custom ("data-toggle", "tab")
                                prop.href "#reminder"
                                prop.role "tab"
                                prop.text "Erinnerungen"
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.className "tab-content"
                prop.id "myTabContent"
                prop.children [
                    Html.div [
                        prop.classes [ "tab-pane"; "fade"; "show"; "active" ]
                        prop.id "disposition"
                        prop.role "tabpanel"
                        prop.children [
                            taskDispositionTable form model dispatch
                        ]
                    ]
                    Html.div [
                        prop.classes [ "tab-pane"; "fade" ]
                        prop.id "reminder"
                        prop.role "tabpanel"
                        prop.children [
                            if isRentOrderCompleted then
                                subHeaderForm "Erinnerungen"
                            else
                                subHeaderByFormStateWithAddButton (match form.EditField with | EditField.EditNewReminder _ -> true | _ -> false)
                                    "Erinnerungen"
                                    (fun _ -> AddReminder |> dispatch)
                                    (fun _ -> EditNewReminder |> dispatch) ""
                                    (fun _ -> SaveRentOrderForm |> dispatch) ""
                                    (fun _ -> EndEdit |> dispatch) ""
                                    FormState.View
                            reminderTable form.RentOrder.Reminders form.EditField isRentOrderCompleted dispatch
                        ]
                    ]
                ]
            ]
        ]
    ]

let private rentOrderEditFormView (form : RentOrderForm) (model : Model) (dispatch : Msg -> unit) =
    let openDeleteCmd = Some (fun _ -> dispatch OpenDeleteModal)
    let closeBtn =
        if form.RentOrder.State <> RentOrderState.Completed then
            dropDownButton (fun _ -> CloseRentOrder |> dispatch) false "Auftrag abschließen" |> Some
        else None
    let now = DateTime.UtcNow
    let firstDate = new DateTime(now.Year, now.Month, 1)
    let customButtons =
        [ closeBtn
          match Shared.Helper.parseCustomer model.UserData.CompanyName with
          | Customer.Prinzing ->
              DownloadPrinzingDialog.DownloadPrinzingDialog' {| Now = firstDate
                                                                RentOrderId = Some form.RentOrder.Id |} |> Some
          | _ -> None ] |> List.choose id |> Some
        
    Html.div [
        mainHeaderBar customButtons ("Mietauftrag") (Some (fun _ -> dispatch PrintAsPdf)) openDeleteCmd
        getAlert model.DetailRentOrderMainMessage
        orderEditForm form model dispatch FormState.View
        tabs form model dispatch
    ]

let rentOrderFormBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RentOrderOverview |> Routes.toPath) "Mietaufträge"
        breadcrumbStr "Mietauftrag"
    ]

let rentOrderFormView (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.id "order-container"
        prop.className "flex-grow-1 d-flex flex-column"
        prop.children [
            overlaySpinner model.RequestState
            rentOrderFormBreadcrumb
            Html.div [
                prop.id "order-content"
                prop.children [
                    Html.section [
                        deleteModal model dispatch
                    ]
                    match model.FormState with
                    | Order.RentOrder.Types.FormState.New form ->
                        rentOrderNewFormView form model dispatch
                    | Order.RentOrder.Types.FormState.Edit form ->
                        rentOrderEditFormView form model dispatch
                    | Order.RentOrder.Types.FormState.Loading -> Fable.React.Helpers.nothing
                ]
            ]
        ]
    ]

let private orderTable (model : Model) dispatch =
    reactTable [
        tableProp.keyField "Id"
        tableProp.defaultSortField ("OrderNumber", "dsc")
        tableProp.rows model.RentOrders
        tableProp.tableKey "rentorders"
        tableProp.tableExportName "mietauftraege_export.csv"
        tableProp.columns [
            tableColumn [
                colProp.selector "OrderNumber"
                colProp.label "Auftragsnummer"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "ExecutionLocation.CompanyName"
                colProp.label "Firma"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "Info"
                colProp.label "Info"
                colProp.formatter (Formatter.Custom (fun value order -> infoToolTip order))
            ]
            tableColumn [
                colProp.selector "ExecutionLocation.City"
                colProp.label "Stadt"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "ProjectInformation.ProjectNumber"
                colProp.label "Projektnummer"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "ProjectInformation.BuildingProjectName"
                colProp.label "Bauvorhaben"
                colProp.sortFunction NaturalSort
                colProp.filter TextFilter
            ]
            tableColumn [
                colProp.selector "State"
                colProp.label "Status"
                colProp.sortFunction (CustomNaturalSort (fun data -> data.State |> Helper.stateToString))
                colProp.filter (SelectFilter(selectOptions, (fun data -> data.State |> Helper.stateToString)))
                colProp.formatter (Custom (fun value order -> stateBadge order))
                colProp.csvFormatter (fun _ data -> data.State |> Helper.stateToString |> Html.text)
            ]
            tableColumn [
                colProp.selector "Aktion"
                colProp.label "Aktion"
                colProp.formatter (Custom (fun value order -> detailBtn order))
                colProp.csvPrint false
            ]
        ]
    ]

let rentOrderOverviewBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbStr "Mietaufträge"
    ]

let prinzingPrintButton (model : Model) dispatch =
    match Shared.Helper.parseCustomer model.UserData.CompanyName with
    | Customer.Prinzing ->
        Buttons.primaryButtonWithFnct (fun _ -> dispatch PrinzingPrintRentOrders) "Drucken" "ml-auto mb-3 mr-3"
    | Customer.Default ->
        Buttons.primaryButtonWithFnct (fun _ -> dispatch PrinzingPrintRentOrders) "Drucken" "ml-auto mb-3 mr-3"

let view (model : Model) (dispatch : Msg -> unit) =
                      
    let now = DateTime.UtcNow
    let firstDate = new DateTime(now.Year, now.Month, 1)
    
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          rentOrderOverviewBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Mietaufträge"
              getAlert model.OverviewRentOrderMessage
              div [ Class "controls d-flex justify-content-end" ]
                [ div [ ]
                    [ prinzingPrintButton model dispatch
                      match Shared.Helper.parseCustomer model.UserData.CompanyName with
                      | Customer.Prinzing ->
                          DownloadPrinzingDialog.DownloadPrinzingDialog' {| Now = firstDate
                                                                            RentOrderId = None |}
                      | _ -> ()
                      
                      Buttons.primaryButton (Routes.toPath Routes.Page.RentOrderNewForm) "Auftrag anlegen" "ml-auto mb-3" ] ]
              orderTable model dispatch
              div [ Class "controls d-flex justify-content-end" ]
                  [ div [ Class "" ]
                      [ Buttons.primaryButton (Routes.toPath (Page.RentOrderOverviewCompleted)) "Archiv" "ml-auto btn-sm" ] ]  ] ]

let rentOrderOverviewCompletedBreadcrumb =
    breadcrumb [
        breadcrumbLink (Page.OrderOverview |> Routes.toPath) "Aufträge"
        breadcrumbLink (Page.RentOrderOverview |> Routes.toPath) "Mietaufträge"
        breadcrumbStr "Archiv"
    ]

let rentOrderCompletedOverview (model : Model) (dispatch : Msg -> unit) =
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ overlaySpinner model.RequestState
          rentOrderOverviewCompletedBreadcrumb
          div [ Id "order-content" ]
            [ mainHeader "Mietaufträge"
              orderTable model dispatch ] ]

