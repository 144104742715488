module MasterData.Entities.State


open Elmish
open Fable.Core.JsInterop
open Fable.Import
open MasterData.Entities.Types
open Thoth.Json
open Elmish.Navigation
open Shared
open Browser.Dom
open Browser.Blob
open Browser.Types
open Client
open Fetch
open System
open Shared.Entity
open MasterData.Entities.FormValidation
open Validation
open Shared.Address
open Shared.Configuration
open SharedComponents.Toast
open Routes
open MasterData.Entities.Helper
open System.Text.RegularExpressions
open SharedComponents.Spinners

let getTemplateCmd (templateId : TemplateId) =
    let (TemplateId templateId) = templateId
    let templateId = templateId.ToString()
    Cmd.OfPromise.either Communication.getRequest<Template> (sprintf "/api/templates/%s" templateId) TemplateFetched FetchError

let getActiveEntitiesCmd (templateId : TemplateId) =
    let templateId = templateId |> Helper.unwrapTemplateId
    Cmd.OfPromise.either Communication.getRequest<EntitiesOverviewDtoResponse> (sprintf "/api/templates/%s/entities/overview" templateId) EntitiesFetched FetchError

let getDeactivatedEntitiesCmd (templateId : TemplateId) =
    let templateId = templateId |> Helper.unwrapTemplateId
    Cmd.OfPromise.either Communication.getRequest<EntitiesOverviewDtoResponse> (sprintf "/api/templates/%s/entities/overview/deactivated" templateId) EntitiesFetched FetchError

let getEntityCmd (templateId : TemplateId) (entityId : EntityId) =
    Commands.getEntityDtoForTemplateCmd templateId entityId EntityFetched FetchError

let getEntityHistoryCmd (entityId : EntityId) =
    let (EntityId entityId) = entityId
    let entityId = entityId.ToString()
    Cmd.OfPromise.either Communication.getRequest<EntityHistoryResponse> (sprintf "/api/entities/%s/history" entityId) EntityHistoryFetched FetchError

let saveEntityCmd body =
    Cmd.OfPromise.either Communication.postRequest<PostResponse<SaveEntityResult>> ("/api/entities", body) EntitySaved FetchError

let updateEntityCmd entityId (updateElement : EntityGroupUpdate) =
    let (EntityId entityId) = entityId
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<UpdateEntityResult>>
                             ((sprintf "/api/entities/%s" (entityId.ToString())), body)
                             EntityUpdated FetchError

let downloadHistoryCSV (entityId : EntityId) =
    let (EntityId entityId) = entityId
    let entityId = entityId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest (sprintf "/api/entities/%s/history/download" entityId, "historie.csv") HistoryCSVFetched FetchError

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let initialEntity (template : Template) : CreateEntityDto =
    { TemplateId = template.Id
      Attributes = template.Attributes |> List.map createAttribute
      Name = ""
      Image = None
      Thumbnail = None
      CurrentLocation = EntityLocation.HomeLocation (LocationId System.Guid.Empty)
      Documents = [ ]
      ActiveState = EntityActiveState.Active
      NotAvailableRanges = []
      CreateMultipleEntities = None }

let initialModel templateId userData formState =
    { TemplateId = templateId
      Entities = [ ]
      UserData = userData
      Template = None
      Templates = []
      Configuration = None
      DocumentUploadForm = None
      MasterData.Entities.Types.FormState = formState
      DeleteRequested = None
      EntityRequestState = RequestState.NotActive }

let init templateId userData (oldModel : Model option) : Model * Cmd<Msg> =
    let templateId = TemplateId templateId
    { initialModel templateId userData Loading with EntityRequestState = RequestState.Active }, Cmd.batch [ getActiveEntitiesCmd templateId; getTemplateCmd templateId ]

let initDeactivatedEntities templateId userData (oldModel : Model option) : Model * Cmd<Msg> =
    let templateId = TemplateId templateId
    { initialModel templateId userData Loading with EntityRequestState = RequestState.Active }, Cmd.batch [ getDeactivatedEntitiesCmd templateId; getTemplateCmd templateId ]

let initNewForm userData templateId : Model * Cmd<Msg> =
    let templateId = TemplateId templateId
    initialModel templateId userData (FormState.New (initialEntityNewForm templateId)), Cmd.batch [getTemplateCmd templateId; Commands.getTemplatesCmd TemplatesFetched FetchError]

let initViewForm userData templateId entityId : Model * Cmd<Msg> =
    let templateId = TemplateId templateId
    { initialModel templateId userData FormState.Loading with EntityRequestState = RequestState.Active },
    Cmd.batch [ getTemplateCmd templateId
                getEntityCmd templateId (EntityId entityId)
                Commands.getTemplatesCmd TemplatesFetched FetchError
                Commands.getConfigurationCmd ConfigurationFetched FetchError ]
   
let initCopyForm userData templateId entityId : Model * Cmd<Msg> =
    let templateId = TemplateId templateId
    initialModel templateId userData (FormState.New (initialEntityNewForm templateId)),
    Cmd.batch [ getTemplateCmd templateId
                getEntityCmd templateId (EntityId entityId)
                Commands.getTemplatesCmd TemplatesFetched FetchError
                Commands.getConfigurationCmd ConfigurationFetched FetchError ]
    
let toActionList (action : (Msg -> 'a) -> unit) =
    let actionList = [action]
    actionList

let updateAttributesInForm (entityAttributes : EntityAttribute list) updateFunc =
    entityAttributes
    |> List.map updateFunc

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    // Form
    | FetchError e ->
        printfn "e %s" e.Message
        model, ErrorHandling.handleFetchError e
    | EntitiesFetched response ->
        let entities =
            match model.Template with
            | Some t -> response.Entities |> List.filter (fun e -> e.TemplateId = t.Id)
            | None -> response.Entities
        { model with Entities = entities
                     EntityRequestState = RequestState.NotActive }, Cmd.none
    | EntityFetched entity ->
        let cmd =
            match entity.Image with
            | Some image -> Commands.getImage image.Id EntityImageFetched FetchError
            | None -> Cmd.none
        let cmd = Cmd.batch [ cmd; getEntityHistoryCmd entity.Id ]
        let holder = Helper.decimalAttributeAsStringHolder entity.Attributes
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form -> 
                { Entity = 
                    { TemplateId = entity.TemplateId
                      Attributes = entity.Attributes
                      Name = ""
                      Image = None
                      Thumbnail = None
                      CurrentLocation = EntityLocation.HomeLocation (LocationId System.Guid.Empty)
                      Documents = [ ]
                      ActiveState = EntityActiveState.Active
                      NotAvailableRanges = []
                      CreateMultipleEntities = None }
                  DecimalAttributeAsStringHolder = holder
                  EntityImage = None
                  FormValidation = None } |> FormState.New
            | Edit form -> initialEntityEditForm entity |> Edit
            | Loading -> initialEntityEditForm entity |> Edit
        { model with FormState = newFormState
                     EntityRequestState = RequestState.NotActive }, cmd
    | EditName ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditName
                                       Entity = form.EntitySnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditAttributes ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditAttributes
                            DecimalAttributeAsStringHolder = form.DecimalAttributeAsStringHolderSnapshot
                            Entity = form.EntitySnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditAddress ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.EditAddress
                            Entity = form.EntitySnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form ->
                { form with EditField = EditField.Nothing
                            DecimalAttributeAsStringHolder = form.DecimalAttributeAsStringHolderSnapshot
                            Entity = form.EntitySnapshot }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | SetBoolAttributeValue (id, value) ->
        let replaceAttribute attribute =
            match attribute with
            | EntityBoolAttribute a -> if a.AttributeId = id then EntityAttribute.EntityBoolAttribute { a with Value = value } else EntityAttribute.EntityBoolAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityBoolAttribute { a with Value = Some value } else EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetIntAttributeValue (id, value) ->
        let replaceAttribute attribute =
            match attribute with
            | EntityIntAttribute a -> if a.AttributeId = id then EntityAttribute.EntityIntAttribute { a with Value = value } else EntityAttribute.EntityIntAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityIntAttribute { a with Value = if String.IsNullOrEmpty (value.ToString()) then None else Some value } else EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetDecimalAttributeValue (id, value) ->
        if Regex.Match(value, floatStateRegex).Success then
            let updateAttribute (attrStrHolder : DecimalAttributeAsStringHolder list) =
                attrStrHolder
                |> List.map (fun d ->
                    if d.AttributeId = id then
                        { d with Value = value |> Some }
                    else d)
            let replaceAttribute attribute =
                match attribute with
                | EntityDecimalAttribute a ->
                    if a.AttributeId = id then
                        let newValue = value.Replace(",", ".") |> float
                        EntityAttribute.EntityDecimalAttribute { a with Value = newValue }
                    else EntityAttribute.EntityDecimalAttribute a
                | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
                | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
                | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
                | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
                | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
                | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
                | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
                | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
                | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
                | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
                | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
            let newFormState =
                match model.FormState with
                | MasterData.Entities.Types.New form ->
                    let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                    { form with Entity = updatedEntity
                                DecimalAttributeAsStringHolder = updateAttribute form.DecimalAttributeAsStringHolder }
                    |> FormState.New
                | Edit form ->
                    let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                    { form with Entity = updatedEntity
                                DecimalAttributeAsStringHolder = updateAttribute form.DecimalAttributeAsStringHolder }
                    |> FormState.Edit
                | Loading -> Loading
            { model with FormState = newFormState }, Cmd.none
        else model, Cmd.none
    | SetOptionalDecimalAttributeValue (id, value) ->
        let updatedValue oldValue =
            if String.IsNullOrEmpty value then None
            elif Regex.Match(value, floatStateRegex).Success then value |> Some
            else oldValue
        let updateAttribute (attrStrHolder : DecimalAttributeAsStringHolder list) =
            attrStrHolder
            |> List.map (fun d ->
                if d.AttributeId = id then
                    { d with Value = updatedValue d.Value }
                else d)
        let replaceAttribute attribute =
            match attribute with
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a ->
                if a.AttributeId = id then
                    if String.IsNullOrEmpty value then
                        EntityAttribute.OptionalEntityDecimalAttribute { a with Value = None }
                    elif Regex.Match(value, floatStateRegex).Success then
                        let newValue = value.Replace(",", ".") |> float |> Some
                        EntityAttribute.OptionalEntityDecimalAttribute { a with Value = newValue }
                    else EntityAttribute.OptionalEntityDecimalAttribute a
                else EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity
                            DecimalAttributeAsStringHolder = updateAttribute form.DecimalAttributeAsStringHolder }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity
                            DecimalAttributeAsStringHolder = updateAttribute form.DecimalAttributeAsStringHolder }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetOptionalIntAttributeValue (id, value) ->
        let value = if String.IsNullOrEmpty value then None else value |> int |> Some
        let replaceAttribute attribute =
            match attribute with
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityIntAttribute { a with Value = value } else EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetStringAttributeValue (id, value) ->
        let replaceAttribute attribute =
            match attribute with
            | EntityStringAttribute a -> if a.AttributeId = id then EntityAttribute.EntityStringAttribute { a with Value = value } else EntityAttribute.EntityStringAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityStringAttribute { a with Value = if String.IsNullOrEmpty value then None else Some value } else EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetOptionalDateAttributeValue (id, date) ->
        let date = date.ToString() |> DateTime.Parse |> Some
        let replaceAttribute attribute =
            match attribute with
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityDateAttribute { a with Value = date } else EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityDateWithReminderAttribute { a with Value = date } else EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | ClearOptionalDateAttributeValue id ->
        let replaceAttribute attribute =
            match attribute with
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDateWithReminderAttribute a -> EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityDateAttribute { a with Value = None } else EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityDateWithReminderAttribute { a with Value = None } else EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetDateAttributeValue (id, value) ->
        let date = value.ToString() |> DateTime.Parse
        let replaceAttribute attribute =
            match attribute with
            | EntityDateAttribute a -> if a.AttributeId = id then EntityAttribute.EntityDateAttribute { a with Value = date } else EntityAttribute.EntityDateAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | EntityDateWithReminderAttribute a -> if a.AttributeId = id then EntityAttribute.EntityDateWithReminderAttribute { a with Value = date } else EntityAttribute.EntityDateWithReminderAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetDateReminder (id, intervall) ->
        let replaceAttribute attribute =
            match attribute with
            | EntityDateWithReminderAttribute a -> if a.AttributeId = id then EntityAttribute.EntityDateWithReminderAttribute { a with Reminder = intervall } else EntityAttribute.EntityDateWithReminderAttribute a
            | EntityDateAttribute a -> EntityAttribute.EntityDateAttribute a
            | EntityBoolAttribute a -> EntityAttribute.EntityBoolAttribute a
            | EntityIntAttribute a -> EntityAttribute.EntityIntAttribute a
            | EntityDecimalAttribute a -> EntityAttribute.EntityDecimalAttribute a
            | EntityStringAttribute a -> EntityAttribute.EntityStringAttribute a
            | OptionalEntityStringAttribute a -> EntityAttribute.OptionalEntityStringAttribute a
            | OptionalEntityIntAttribute a -> EntityAttribute.OptionalEntityIntAttribute a
            | OptionalEntityDecimalAttribute a -> EntityAttribute.OptionalEntityDecimalAttribute a
            | OptionalEntityBoolAttribute a -> EntityAttribute.OptionalEntityBoolAttribute a
            | OptionalEntityDateAttribute a -> EntityAttribute.OptionalEntityDateAttribute a
            | OptionalEntityDateWithReminderAttribute a -> if a.AttributeId = id then EntityAttribute.OptionalEntityDateWithReminderAttribute { a with Reminder = intervall } else EntityAttribute.OptionalEntityDateWithReminderAttribute a
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.New
            | Edit form ->
                let updatedEntity = { form.Entity with Attributes = (updateAttributesInForm form.Entity.Attributes replaceAttribute) }
                { form with Entity = updatedEntity }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetName name ->
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                { form with Entity = { form.Entity with Name = name } }
                |> FormState.New
            | Edit form ->
                { form with Entity = { form.Entity with Name = name } }
                |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetMultipleEntitiesCreation ->
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                let updatedForm =
                    match form.Entity.CreateMultipleEntities with
                    | Some _ -> { form.Entity with  CreateMultipleEntities = None }
                    | None -> { form.Entity with
                                        Name = ""
                                        CreateMultipleEntities = Some { StartNumber = ""
                                                                        Quantity = "" } }
                { form with Entity = updatedForm }
                |> FormState.New
            | Edit form -> form |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetStartNumber value ->
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                match form.Entity.CreateMultipleEntities with
                | Some cme ->
                    { form with Entity = { form.Entity with CreateMultipleEntities = Some { cme with StartNumber = value } } }
                | None -> form
                |> FormState.New
            | Edit form -> form |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetQuantity value ->
        let newFormState =
            match model.FormState with
            | MasterData.Entities.Types.New form ->
                match form.Entity.CreateMultipleEntities with
                | Some cme ->
                    { form with Entity = { form.Entity with CreateMultipleEntities = Some { cme with Quantity = value } } }
                | None -> form
                |> FormState.New
            | Edit form -> form |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHomeLocation newHomeLocation ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> model.FormState
            | FormState.New _ -> model.FormState
            | FormState.Edit form ->
                match newHomeLocation with
                | Some location ->
                    { form with Entity = { form.Entity with CurrentLocation = EntityLocation.HomeLocation location.value.Id } } |> FormState.Edit
                | None -> model.FormState
        { model with FormState = newFormState }, Cmd.none
    | ActivateDeletedEntity ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                { model with EntityRequestState = RequestState.Active },
                EntityActiveState.Active
                |> EntityGroupUpdate.ActiveState
                |> updateEntityCmd form.Entity.Id
            | Loading -> model, Cmd.none
        newModel, newCmd

    // Set edit states
    | ValidateForm actionType ->
        let validation =
            match model.FormState with
            | New form -> validateNewForm form
            | Edit form ->
                match form.EditField with
                | EditField.EditName -> validateEditForm form
                | EditField.EditAttributes -> validateEditForm form
                | EditField.EditAddress -> validateEditForm form
                | EditField.Nothing -> notValid
            | Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                match actionType with
                | Add -> model, Cmd.ofMsg SaveEntity
                | ActionType.SaveName -> model, Cmd.ofMsg SaveName
                | ActionType.SaveAttributes -> model, Cmd.ofMsg SaveAttributes
                | ActionType.SaveAddress -> model, Cmd.ofMsg SaveAddress
            else
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, Cmd.none
                | Loading -> model, Cmd.none
                | New form ->
                    let newFormState = { form with FormValidation = Some validation } |> New
                    { model with FormState = newFormState }, Cmd.none
        newModel, cmd
    | SaveName ->
        let newCmd, newModel =
            match model.FormState with
            | New form -> Cmd.none, { model with FormState = form |> New }
            | Edit form ->
                let cmd =
                    form.Entity.Name
                    |> EntityGroupUpdate.Name
                    |> updateEntityCmd form.Entity.Id
                let newFormState =
                    { form with EditField = Nothing
                                FormValidation = None } |> Edit
                let newModel =
                    { model with FormState = newFormState
                                 EntityRequestState = RequestState.Active }
                cmd, newModel
            | Loading -> Cmd.none, { model with FormState = FormState.Loading }
        newModel, newCmd
    | SaveAttributes ->
        let newCmd, newModel =
            match model.FormState with
            | New form -> Cmd.none, { model with FormState = form |> New }
            | Edit form ->
                let cmd =
                    form.Entity.Attributes
                    |> EntityGroupUpdate.Attributes
                    |> updateEntityCmd form.Entity.Id
                let newFormState =
                    { form with EditField = Nothing } |> Edit
                let newModel =
                    { model with FormState = newFormState
                                 EntityRequestState = RequestState.Active }
                cmd, newModel
            | Loading -> Cmd.none, { model with FormState = FormState.Loading }
        newModel, newCmd
    | SaveAddress ->
        let newCmd, newModel =
            match model.FormState with
            | New form -> Cmd.none, { model with FormState = form |> New }
            | Edit form ->
                let cmd =
                    form.Entity.CurrentLocation
                    |> EntityGroupUpdate.CurrentLocation
                    |> updateEntityCmd form.Entity.Id
                let newFormState =
                    { form with EditField = Nothing } |> Edit
                let newModel =
                    { model with FormState = newFormState
                                 EntityRequestState = RequestState.Active }
                cmd, newModel
            | Loading -> Cmd.none, { model with FormState = FormState.Loading }
        newModel, newCmd
    | SaveEntity ->
        let newModel, cmd =
            match model.FormState with
            | New form ->
                let updatedForm =
                    { form with FormValidation = None } |> FormState.New
                { model with EntityRequestState = RequestState.Active
                             FormState = updatedForm }, saveEntityCmd (Encode.Auto.toString (0, form.Entity))
            | Edit _ -> model, Cmd.none
            | Loading -> model, Cmd.none
        newModel, cmd

    | EntityImageFetched response ->
        let newFormState =
            match model.FormState with
            | Loading -> model.FormState
            | New _ -> model.FormState
            | Edit form ->
                { form with EntityImage = Some response }
                |> Edit
        { model with FormState = newFormState }, Cmd.none
    // Delete actions
    | AbortDelete ->
        { model with DeleteRequested = None }, Cmd.none
    | DeleteRequest ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                { model with EntityRequestState = RequestState.Active },
                EntityActiveState.NotActive
                |> EntityGroupUpdate.ActiveState
                |> updateEntityCmd form.Entity.Id
            | Loading -> model, Cmd.none
        newModel, newCmd
    | OpenDeleteModal ->
        let model =
            match model.FormState with
            | FormState.Loading -> model
            | New form -> model
            | Edit form -> { model with DeleteRequested = Some form.Entity.Id }
        model, Cmd.none

    | EntitySaved response ->
        let newModel, newCmd =
            match response.Result with
            | SaveEntityResult.Saved entityId ->
                match model.FormState with
                | New form ->
                    let (EntityId entityId) = entityId
                    let (TemplateId templateId) = form.Entity.TemplateId
                    model, Cmd.batch [ Navigation.newUrl (Routes.toPath (Page.EntityViewForm (templateId, entityId))); toast (ToastType.Success "Gerät erfolgreich angelegt.")]
                | Edit _ -> model, Cmd.none
                | Loading -> model, Cmd.none
            | SaveEntityResult.EntityNameAlreadyExists ->
                match model.FormState with
                | New form ->
                    let newForm = 
                        match form.Entity.CreateMultipleEntities with
                        | None -> { form with FormValidation = entityNameAlreadyExists() |> Some } |> FormState.New
                        | Some _  -> { form with FormValidation = numberInputWrong() |> Some } |> FormState.New
                    { model with FormState = newForm
                                 EntityRequestState = RequestState.NotActive }, toast (ToastType.Error "Ein Gerät mit diesem Namen existiert bereits.")
                | Edit _ -> model, Cmd.none
                | Loading -> model, Cmd.none
            | SaveEntityResult.StartOrQuantityNumberIsNegative ->
                match model.FormState with
                | New form ->
                    let newForm = { form with FormValidation = numberInputWrong() |> Some } |> FormState.New
                    { model with FormState = newForm
                                 EntityRequestState = RequestState.NotActive }, toast (ToastType.Error "Startnummer oder Anzahl darf nicht kleiner als 0 sein.")
                | Edit _ -> model, Cmd.none
                | Loading -> model, Cmd.none
        newModel, newCmd
    | EntityDeleted response ->
        // let (PostResponseResult.Ok id) = response.Result
        // match model.EntityForm with
        // | Some form ->
        //     let (TemplateId templateId) = form.Entity.TemplateId
        //     let message = { MessageType = MessageType.Info
        //                     Message = "Das Gerät wurde erfolgreich gelöscht." }
        //     { model with OverviewEntityMessage = Some message
        //                  SavedEntityGuid = Some id }, Navigation.newUrl (Routes.toPath (Routes.Page.Entity templateId))
        // | None -> model, Cmd.none
        model, Cmd.none
    | EntityUpdated result ->
        let newFormState, newCmd =
            match model.FormState with
            | Loading -> Loading, Cmd.none
            | New form -> model.FormState, Cmd.none
            | Edit form ->
                match result.Result with
                | UpdateEntityResult.Updated _ ->
                    let decimalAttributeAsStringHolder = Helper.decimalAttributeAsStringHolder form.Entity.Attributes
                    { form with EntitySnapshot = form.Entity
                                DecimalAttributeAsStringHolder = decimalAttributeAsStringHolder
                                DecimalAttributeAsStringHolderSnapshot = decimalAttributeAsStringHolder} |> Edit,
                    toast (ToastType.Success "Geräte erfolgreich aktualisiert.")
                | UpdateEntityResult.EntityNameAlreadyExists ->
                    { form with FormValidation = entityNameAlreadyExists() |> Some } |> Edit, toast (ToastType.Error "Gerät mit diesem Name existiert bereits.")
                | UpdateEntityResult.EntityStateUpdated (entityId, newState) ->
                    match newState with
                    | EntityActiveState.Active ->
                        let (TemplateId templateId) = form.Entity.TemplateId
                        let (EntityId entityId) = form.Entity.Id
                        { form with EntitySnapshot = form.Entity } |> Edit,
                        Cmd.batch [ toast (ToastType.Info "Gerät wurde erfolgreich aktiviert.");
                                    Navigation.newUrl (Routes.toPath (Routes.Page.Entity (templateId)))]
                    | EntityActiveState.NotActive ->
                        let (TemplateId templateId) = form.Entity.TemplateId
                        form |> Edit,
                        Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.Entity templateId))
                                    toast (ToastType.Info "Gerät wurde erfolgreich gelöscht.")]
                | UpdateEntityResult.StateUpdatedFailedActiveEntityNameExists _ -> form |> Edit, toast (ToastType.Warning "Gerät konnte nicht reaktiviert werden da es bereits ein Gerät mit dem Name gibt.")
                | UpdateEntityResult.StateUpdatedFailed _ -> form |> Edit, toast (ToastType.Warning "Gerät konnte nicht gelöscht werden da es in Verwendung ist.")
                | UpdateEntityResult.AddNotAvailableRangeFailed _ -> form |> Edit, Cmd.none
        { model with FormState = newFormState
                     EntityRequestState = RequestState.NotActive }, newCmd
    | SetDocumentUploadFormDescripton description ->
        match model.FormState with
        | Loading -> model, Cmd.none
        | New _ -> model, Cmd.none
        | Edit form ->
            let documentUploadForm =
                match model.DocumentUploadForm with
                | Some duf -> { duf with Description = description }
                | None -> { File = ""
                            Name = ""
                            EntityId = form.Entity.Id
                            Description = description
                            Target = None }
            { model with DocumentUploadForm = Some documentUploadForm }, Cmd.none
    | EntityDocumentUploadCompleted ->
        match model.FormState with
        | New _ -> model, Cmd.none
        | Loading -> model, Cmd.none
        | Edit form ->
            { model with DocumentUploadForm = None },
            getEntityCmd model.TemplateId form.Entity.Id
    | EntityDocumentDescriptionSaved response ->
        model, Cmd.ofMsg EntityDocumentUploadCompleted
    | SetField content ->
        model, Cmd.none
    | FromFile (action, target) ->
        match model.FormState with
        | Loading -> model, Cmd.none
        | New _ -> model, Cmd.none
        | Edit form ->
            let name : string = target?files?(0)?name
            let file = target?files?item(0)
            let documentUploadForm =
                match model.DocumentUploadForm with
                | Some duf -> { duf with Name = name }
                | None -> { File = file
                            Name = name
                            EntityId = form.Entity.Id
                            Description = ""
                            Target = Some target }
            let delayedAction newAction =
                let fileReader = createNew FileReader "" :?> FileReader
                fileReader?onloadend <- (fun () -> newAction <| action !!fileReader?result)
                fileReader?readAsBinaryString target?files?(0) |> ignore
            { model with DocumentUploadForm = Some documentUploadForm }, delayedAction |> toActionList
    | UploadedDocument ->
        model, Cmd.ofMsg FetchEntity
    | ConfigurationFetched response ->
        { model with Configuration = Some response }, Cmd.none
    | TemplatesFetched response ->
        { model with Templates = response.Templates
                     EntityRequestState = RequestState.NotActive }, Cmd.none
    | Msg.FetchEntity ->
        let cmd =
            match model.FormState with
            | Edit form ->
                getEntityCmd model.TemplateId form.Entity.Id
            | New _ -> Cmd.none
            | Loading -> Cmd.none
        model, cmd
        
    | Msg.FetchEntityWithTemplateId (entityId, templateId) ->
        let (EntityId entityId) = entityId
        let (TemplateId templateId) = templateId
        model, Cmd.batch [ Navigation.newUrl (Routes.toPath (Page.EntityViewForm (templateId, entityId))); toast (ToastType.Success "Gerät erfolgreich angelegt.")]
    | TemplateFetched template ->
        let newFormState =
            match model.FormState with
            | New form ->
                let entity = Helper.updateInitialEntity template
                let holder = Helper.decimalAttributeAsStringHolder entity.Attributes
                { form with Entity = entity
                            DecimalAttributeAsStringHolder = holder }
                |> FormState.New
            | Edit form -> form |> FormState.Edit
            | Loading -> Loading
        { model with Template = Some template
                     FormState = newFormState }, Cmd.none
    | EntityHistoryFetched response ->
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form -> { form with EntityHistory = response.EntityHistory } |> FormState.Edit
            | Loading -> Loading
        { model with FormState = newFormState }, Cmd.none
    | DownloadDocument (documentId, filename) ->
        model, Cmd.batch [ Commands.downloadFileCmd documentId filename DocumentFetched FetchError; toast (ToastType.Info "Dokument wird heruntergeladen") ]
    | DownloadHistoryCSV ->
        let newModel, cmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form -> { model with EntityRequestState = RequestState.Active }, Cmd.batch [ downloadHistoryCSV form.Entity.Id; toast (ToastType.Info "Dokument wird heruntergeladen") ]
            | Loading -> model, Cmd.none
        newModel, cmd
    | DocumentFetched (response, filename) ->
        model, getBlob response filename
    | HistoryCSVFetched (response, filename) ->
        { model with EntityRequestState = RequestState.NotActive }, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none

