module Order.RentOrder.ReleaseDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Thoth.Json
open Client.SharedComponents
open Shared.Configuration
open Shared.Address
open SharedComponents.ReactSelect
open SharedComponents.Types.Helpers
open Types
open Shared.RentOrder
open Feliz.ElmishComponents
open System
open SharedComponents.Toast

type Msg =
    | Empty
    | SetReleaseDate of DateTime
    | SetLocation of LocationId option
    | SetWithoutReturnTask of bool
    | ReleasePositions of SetReturnStateToRentOrderPositionDto
    | PositionReleased of PostResponse<RentOrderReleaseResult>
    | FetchError of exn


let saveRentOrderPositionsWithReturnStateCmd (dto : SetReturnStateToRentOrderPositionDto) =
    let url = "/api/rentorders/returnstate"
    let body = Encode.Auto.toString (0, dto)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<RentOrderReleaseResult>> (url, body) PositionReleased FetchError

type Validated<'a> =
    { Raw : string
      Parsed : 'a option }

type State =
    { Configuration : Configuration
      RentOrderId : RentOrderId
      PositionIds : RentOrderPositionId list
      IsDisabled : bool
      ReleaseDate : System.DateTime
      MinDate : System.DateTime
      OnlyWithTask : bool
      WarningMsg : string option
      WithoutReturnTask : bool
      RequestActive : bool
      SelectedLocation : LocationId option
      SuccessCallback : unit -> unit }

let init configuration rentOrderId rentOrderPositionIds minDate onlyWithTask isDisabled successCallback : State * Cmd<Msg> =
    let initialModel =
        { Configuration = configuration
          RentOrderId = rentOrderId
          ReleaseDate = System.DateTime.UtcNow.Date
          PositionIds = rentOrderPositionIds
          IsDisabled = isDisabled
          MinDate = minDate
          RequestActive = false
          WithoutReturnTask = false
          WarningMsg = None
          OnlyWithTask = onlyWithTask
          SelectedLocation = None
          SuccessCallback = successCallback }
    initialModel, Cmd.none

let update (msg : Msg) (state : State) =
    match msg with
    | Empty -> state, Cmd.none
    | SetLocation locationId ->
        { state with SelectedLocation = locationId }, Cmd.none
    | SetWithoutReturnTask withoutTask ->
        { state with WithoutReturnTask = withoutTask }, Cmd.none
    | SetReleaseDate releaseDate ->
        { state with ReleaseDate = releaseDate }, Cmd.none
    | ReleasePositions dto ->
        { state with RequestActive = true }, saveRentOrderPositionsWithReturnStateCmd dto
    | PositionReleased response ->
        let state, newCmd =
            match response.Result with
            | RentOrderReleaseResult.Released _ ->
                state.SuccessCallback()
                Swal.close (SweetAlert.Result.Value ())
                { state with RequestActive = false }, toast (ToastType.Success "Positionen wurden freigemeldet.")
            | RentOrderReleaseResult.PositionsInWrongState positionIds ->
                let posNos = positionIds |> List.map(fun (pId, posNo) -> posNo)
                let msg = "Folgende Positionen konnten nicht freigemeldet werden: " + (String.concat " " posNos)
                { state with WarningMsg = msg |> Some }, toast (ToastType.Error "Positionen konnten nicht freigemeldet werden. Positionen sind im falschen Zustand.")
        state, newCmd
    | FetchError e ->
        { state with RequestActive = false }, Cmd.none

let dialogBody (state : State) dispatch =
    Html.div [
        prop.id "release-dialog"
        prop.className "form-group"
        prop.children [

            match state.WarningMsg with
            | Some msg -> SharedComponents.Alerts.warningAlert msg
            | None -> Html.none

            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.text "Datum"
            ]
            Html.div [
                prop.children [
                    ReactDatepicker.customDatepickerWithoutLabel
                        state.ReleaseDate
                        (fun d -> d |> SetReleaseDate |> dispatch)
                        false
                        [ ReactDatepicker.CommonProps.MinDate state.MinDate ]
                ]
            ]

            if state.OnlyWithTask |> not then
                Html.div [
                    prop.className "d-flex mt-3"
                    prop.children [
                        Form.inlineCheckBoxLabelRight (fun _ ->
                            if state.WithoutReturnTask then
                                false |> SetWithoutReturnTask |> dispatch
                            else true |> SetWithoutReturnTask |> dispatch) state.WithoutReturnTask "Ohne Abholaufgabe" false
                        Html.span [
                            prop.className "task-tooltip mr-2 ml-3"
                            prop.children [
                                Html.i [
                                    prop.custom ("data-tip", "Geräte & Material direkt einem Lager zuweisen. Es wird keine Abholaufgabe erstellt, die disponiert werden kann.")
                                    prop.className "fas fa-info-circle date-icon ml-1"
                                ]
                                ReactTooltip.tooltip [ ReactTooltip.CommonProps.Wrapper "span" ]
                            ]
                        ]
                    ]
                ]

            if state.WithoutReturnTask then
                Html.div [
                    prop.className "form-group"
                    prop.children [
                        ReactSelect.selectWithoutLabel (
                            let displayText (location : Location) =
                                let address = location.Address
                                sprintf "%s | %s %s | %s %s" location.Name address.Street address.HouseNumber address.City address.ZipCode

                            let options (configuration : Configuration) =
                                configuration.MainLocation :: configuration.Locations
                                |> List.map (fun location ->
                                    { label = displayText location
                                      value = location })
                                |> List.toArray

                            let emptySearchResultContent =
                                Html.div [
                                    prop.className "mr-auto p-2"
                                    prop.text "Es konnte kein passender Standort gefunden werden." ]

                            let valueElement =
                                match state.SelectedLocation with
                                | None -> [| SharedComponents.ReactSelect.CommonProps<Location>.Value None |]
                                | Some locationId ->
                                    if state.Configuration.MainLocation.Id = locationId then
                                        [| SharedComponents.ReactSelect.CommonProps<Location>.Value (Some { label = displayText state.Configuration.MainLocation; value = state.Configuration.MainLocation }) |]
                                    else
                                      state.Configuration.Locations
                                      |> List.tryFind (fun l -> l.Id = locationId)
                                      |> (fun location ->
                                          match location with
                                          | None -> [| SharedComponents.ReactSelect.CommonProps<Location>.Value None |]
                                          | Some location ->
                                              [| SharedComponents.ReactSelect.CommonProps<Location>.Value (Some { label = displayText location; value = location }) |])

                            Array.append
                                [| SharedComponents.ReactSelect.CommonProps<Location>.Options (options state.Configuration)
                                   SharedComponents.ReactSelect.CommonProps<Location>.OnChange (fun address ->
                                                                                                    match address with
                                                                                                    | Some a -> a.value.Id |> Some |> SetLocation |> dispatch
                                                                                                    | None -> ())
                                   SharedComponents.ReactSelect.CommonProps<Location>.IsSearchable true
                                   SharedComponents.ReactSelect.CommonProps<Location>.IsClearable false
                                   SharedComponents.ReactSelect.CommonProps<Location>.Placeholder "Standort wählen"
                                   SharedComponents.ReactSelect.CommonProps<Location>.NoOptionsMessage (fun _ -> emptySearchResultContent)
                                   SharedComponents.ReactSelect.CommonProps<Location>.ClassName "address-select flex-grow-1"
                                   SharedComponents.ReactSelect.CommonProps<Location>.IsDisabled false |]
                                valueElement
                        )
                    ]
                ]
            else Html.none

            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnctAndIsDisabled
                        (fun _ ->
                            let dto =
                                { SetReturnStateToRentOrderPositionDto.PositionIds = state.PositionIds
                                  SetReturnStateToRentOrderPositionDto.RentOrderId = state.RentOrderId
                                  SetReturnStateToRentOrderPositionDto.ReleaseDate = state.ReleaseDate
                                  SetReturnStateToRentOrderPositionDto.LocationId = state.SelectedLocation }
                            dto |> ReleasePositions |> dispatch)
                        ((state.WithoutReturnTask && state.SelectedLocation.IsNone) || state.RequestActive)
                        "Speichern" "mr-3"
                    Buttons.primaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" ""
                ]
            ]

        ]
    ]

let elmishComp (configuration : Configuration) (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (minDate : System.DateTime) (onlyWithTask : bool) (isDisabled : bool) successCallback =
    React.elmishComponent("ReleaseDialog", init configuration rentOrderId positionIds minDate onlyWithTask isDisabled successCallback, update, dialogBody)

let render (configuration : Configuration) (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (minDate : System.DateTime) (onlyWithTask : bool) (isDisabled : bool) successCallback =
    let disabledClass = if isDisabled then "disabled" else ""
    Html.button [
        prop.className (sprintf "dropdown-item %s" disabledClass)
        prop.disabled isDisabled
        prop.text "Positionen freimelden"
        prop.onClick (fun _ ->
                        Swal.fire ([
                            swal.html (elmishComp configuration rentOrderId positionIds minDate onlyWithTask isDisabled successCallback)
                            swal.showCancelButton false
                            swal.showConfirmButton false
                            ],
                            (function
                                | SweetAlert.Result.Value _ ->
                                    Swal.Simple.success "Positionen wurden freigemeldet"
                                    successCallback ()
                                | SweetAlert.Result.Dismissal d ->
                                    printfn "Dismiss %O" d
                                | SweetAlert.Result.Denied ->
                                    printfn "Denied"
                            )
                        )
                    )
    ]

let releaseDialog' =
    React.functionComponent(fun (props : {| configuration : Configuration
                                            rentOrderId : RentOrderId
                                            positionIds : RentOrderPositionId list
                                            minDate : System.DateTime
                                            onlyWithTask : bool
                                            isDisabled : bool
                                            successCallback : unit -> unit |}) -> render props.configuration props.rentOrderId props.positionIds props.minDate props.onlyWithTask props.isDisabled props.successCallback)

let releaseDialog (configuration : Configuration) (rentOrderId : RentOrderId) (positionIds : RentOrderPositionId list) (minDate : System.DateTime) (onlyWithTask : bool) (isDisabled : bool) successCallback =
    releaseDialog' {| configuration = configuration
                      rentOrderId = rentOrderId
                      positionIds = positionIds
                      minDate = minDate
                      onlyWithTask = onlyWithTask
                      isDisabled = isDisabled
                      successCallback = successCallback |}
