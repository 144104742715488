module MyProfil.State

open Elmish
open MyProfil.Types
open MyProfil.FormValidation
open Shared
open Validation
open SharedComponents.Toast
open SharedComponents.Spinners

let getUserCmd userId =
    Cmd.OfPromise.either Communication.getRequest<User> (sprintf "/api/users/%i" userId) UserFetched FetchError

let updateMyProfileCmd userId (updateElement : MyProfileUpdate) =
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>>
                            (sprintf "/api/myprofile/%i" userId, body)
                            MyProfileUpdated FetchError

let init (user : UserData) : Model * Cmd<Msg> =
    let initialModel =
        { FormState = FormState.Loading
          RequestState = RequestState.Active }
    initialModel, getUserCmd user.UserId

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | SetPassword password ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | FormState.Edit form ->
                { form with User = { form.User with Password = password } } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | SetSecondPassword password ->
        let newFormState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | FormState.Edit form ->
                { form with User = { form.User with SecondPassword = password } } |> FormState.Edit
        { model with FormState = newFormState }, Cmd.none
    | EditPassword ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form ->
                { form with EditField = EditField.EditPassword
                            User = form.UserSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | SaveMyProfile ->
        let validation =
                match model.FormState with
                | Edit form ->
                    match form.EditField with
                    | EditField.EditPassword _ -> validatePassword form
                    | EditField.Nothing -> failwith "Not Implemented"
                | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let newModel, cmd =
                    match model.FormState with
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditPassword ->
                            { model with RequestState = RequestState.Active },
                            form.User.Password
                            |> MyProfileUpdate.Password
                            |> updateMyProfileCmd form.User.User.Id
                        | EditField.Nothing -> model, Cmd.none
                    | FormState.Loading -> model, Cmd.none
                model, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, warningToast
                | FormState.Loading -> model, Cmd.none
        newModel, cmd
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form ->
                { form with EditField = EditField.Nothing
                            User = form.UserSnapshot
                            FormValidation = None } |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    | UserFetched user ->
        let user = { User = user;
                     Password = "";
                     SecondPassword = "";
                     Roles = user.AssignedRoles}
        let userForm =
            { User = user
              UserSnapshot = user
              FormValidation = None
              EditField = EditField.Nothing }
        let newFormState =
            match model.FormState with
            | FormState.Loading -> userForm |> FormState.Edit
            | FormState.Edit _ -> userForm |> FormState.Edit
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, Cmd.none

    | MyProfileUpdated response ->
        let newFormState, cmd =
            match model.FormState with
            | FormState.Loading -> FormState.Loading, Cmd.none
            | Edit form ->
                    { form with UserSnapshot = form.User
                                EditField = EditField.Nothing } |> FormState.Edit,
                    toast (ToastType.Success "Profil erfolgreich aktualisiert.")
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, cmd
    | FetchError e ->
        model, Cmd.none
