module Configuration.Locations.State

open Configuration.Locations.Types
open Elmish
open Shared
open Shared.Address
open Shared.Configuration
open Thoth.Json
open SharedComponents.Toast
open SharedComponents.Spinners

let getConfigurationCmd =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" ConfigurationFetched FetchError

let addLocationCmd (location : Location) =
    let body = Encode.Auto.toString(0, location)
    Cmd.OfPromise.either Communication.postRequest ("/api/configuration/location", body) LocationAdded FetchError

let updateLocationCmd (location : Location) =
    let body = Encode.Auto.toString(0, location)
    Cmd.OfPromise.either Communication.putRequest ("/api/configuration/location", body) LocationUpdated FetchError

let init : Model * Cmd<Msg> =
    let initialModel =
        { FormState = FormState.View
          ConfigurationForm = None
          Configuration = None
          RequestState = RequestState.Active }
    initialModel, getConfigurationCmd

let toActionList (action : (Msg -> 'a) -> unit) =
    let actionList = [action]
    actionList

let update (msg : Msg) (model : Model) =
    match msg with
    // Set home address
    | SetLocationName locationName ->
        let newConfigurationForm =
            match model.ConfigurationForm with
            | Some c -> Some { c with Location = { c.Location with Name = locationName } }
            | None -> None
        { model with ConfigurationForm = newConfigurationForm }, Cmd.none
    | SetStreet street ->
        let newConfigurationForm =
            match model.ConfigurationForm with
            | Some c -> Some { c with Location = { c.Location with Address = { c.Location.Address with Street = street } } }
            | None -> None
        { model with ConfigurationForm = newConfigurationForm }, Cmd.none
    | SetHouseNumber houseNumber ->
        let newConfigurationForm =
            match model.ConfigurationForm with
            | Some c -> Some { c with Location = { c.Location with Address = { c.Location.Address with HouseNumber = houseNumber } } }
            | None -> None
        { model with ConfigurationForm = newConfigurationForm }, Cmd.none
    | SetZipCode zipCode ->
        let newConfigurationForm =
            match model.ConfigurationForm with
            | Some c -> Some { c with Location = { c.Location with Address = { c.Location.Address with ZipCode = zipCode } } }
            | None -> None
        { model with ConfigurationForm = newConfigurationForm }, Cmd.none
    | SetCity city ->
        let newConfigurationForm =
            match model.ConfigurationForm with
            | Some c -> Some { c with Location = { c.Location with Address = { c.Location.Address with City = city } } }
            | None -> None
        { model with ConfigurationForm = newConfigurationForm }, Cmd.none
    // Set edit states
    | NewLocation ->
        let newModel =
            { model with FormState = FormState.New
                         ConfigurationForm =
                            Some { Location = Configuration.Helper.emptyLocation
                                   LocationSnapshot = Configuration.Helper.emptyLocation } }
        newModel, Cmd.none
    | EditLocation location ->
        let newModel =
            { model with FormState = FormState.Edit location.Id
                         ConfigurationForm =
                            Some { Location = location
                                   LocationSnapshot = location } }
        newModel, Cmd.none
    | AbortEditLocation ->
        let config =
            match model.Configuration, model.ConfigurationForm with
            | Some configuration, Some form ->
                let locations = configuration.Locations |> List.map (fun l -> if l = form.Location then form.LocationSnapshot else l)
                Some { configuration with Locations = locations }
            | Some _, None
            | None, Some _
            | None, None -> None
        let newModel =
            { model with FormState = FormState.View
                         ConfigurationForm = None }
        newModel, Cmd.none
    // Actions
    | AddLocation location ->
        let newModel, cmd =
            match model.Configuration with
            | Some c -> { model with RequestState = RequestState.Active }, addLocationCmd location
            | None -> model, Cmd.none
        { newModel with FormState = View }, cmd
    | UpdateLocation location ->
        let newModel, cmd =
            match model.Configuration with
            | Some c -> { model with RequestState = RequestState.Active }, updateLocationCmd location
            | None -> model, Cmd.none
        { newModel with FormState = View }, cmd
    // Fetched
    | ConfigurationFetched configuration ->
        { model with Configuration = Some configuration
                     RequestState = RequestState.NotActive }, Cmd.none
    | LocationAdded response ->
        model, Cmd.batch [ getConfigurationCmd; toast (ToastType.Success "Konfiguration erfolgreich gespeichert.")]
    | LocationUpdated response ->
        model, Cmd.batch [ getConfigurationCmd; toast (ToastType.Success "Konfiguration erfolgreich gespeichert.")]
    | FetchError e ->
        { model with RequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
