module Available.State

open Elmish
open Available.Types
open Shared
open SharedComponents
open Shared.Entity
open Shared.Available
open Fable.MomentJs
open SharedComponents.Types
open Elmish.Navigation
open Routes
open System

let getTemplatesCmd =
    Cmd.OfPromise.either Communication.getRequest<TemplatesResponse> "/api/templates" TemplatesFetched FetchError

let getNotAvailableRangesForEntitiesPerTemplateCmd (templateId : TemplateId) =
    let (TemplateId templateId) = templateId
    Cmd.OfPromise.either Communication.getRequest<AvailableResponse> (sprintf "/api/available/templates/%s/not" (templateId.ToString())) NotAvailableRangesFetched FetchError

let getTemplateWorkload (templateId : TemplateId) =
    let (TemplateId templateId) = templateId
    Cmd.OfPromise.either Communication.getRequest<TemplateWorkload> (sprintf "/api/available/templates/%s/workload" (templateId.ToString())) TemplateWorkloadResponse FetchError

let getTemplateWorkloadByDaterange (templateId : TemplateId) (startDate : DateTime) (endDate : DateTime) =
    let (TemplateId templateId) = templateId
    let startDate = startDate.ToString("dd.MM.yyyy")
    let endDate = endDate.ToString("dd.MM.yyyy")
    Cmd.OfPromise.either Communication.getRequest<TemplateWorkloadByDaterange> (sprintf "/api/available/templates/%s/%s/%s/workload" (templateId.ToString()) startDate endDate) TemplateWorkloadByDaterangeResponse FetchError

let init (user : UserData) : Model * Cmd<Msg> =
    let moment = moment.Invoke System.DateTime.Now
    moment.locale(language="de") |> ignore
    let customTimeSteps : ReactCalendarTimeline.CustomTimeSteps =
        { second = 0
          minute = 0
          hour = 0
          day = 1
          month = 1
          year = 1 }
    let initialModel : Model =
        { Templates = [ ]
          SelectedTemplate = None
          FetchData = false
          Items = []
          Moment = moment
          TemplateWorkload = None
          TemplateWorkloadByDaterange = None
          ChartItems = []
          Groups = []
          AvailableItems = []
          TemplateWorkloadDaterange =
            { StartDate = DateTime.UtcNow
              EndDate = DateTime.UtcNow }
          CustomTimeSteps = customTimeSteps
          WorkloadRequest = false }
    initialModel, getTemplatesCmd

let groups (notAvailableGroup : AvailableGroup list) : ReactCalendarTimeline.Group list =
    notAvailableGroup
    |> List.map(fun a ->
        { ReactCalendarTimeline.Group.id = a.Id
          ReactCalendarTimeline.Group.title = a.Title
          ReactCalendarTimeline.Group.root = false
          ReactCalendarTimeline.Group.parent = None })

let itemProps (itemType : ItemType) : ReactCalendarTimeline.ItemProps =
    let className =
        match itemType with
        | ItemType.RentOrderPlanned -> "item-rentorder-planned"
        | ItemType.RentOrderActive -> "item-rentorder-active"
        | ItemType.RentOrderCompleted -> "item-rentorder-completed"
        | ItemType.NotAvailableRange -> "item-notavailablerange"
    { className = className }

let items (notAvailableItems : AvailableItem list) : ReactCalendarTimeline.Item list =
    notAvailableItems
    |> List.map(fun item ->
        let startDate = moment.Invoke (item.StartDate)
        let endDate = moment.Invoke (item.EndDate)
        { SharedComponents.ReactCalendarTimeline.Item.id = item.Id
          ReactCalendarTimeline.Item.group = item.GroupId
          ReactCalendarTimeline.Item.itemProps = item.ItemType |> itemProps
          ReactCalendarTimeline.Item.title = item.Title
          ReactCalendarTimeline.Item.start_time = startDate.toDate()
          ReactCalendarTimeline.Item.end_time = endDate.add(1., "day").toDate()
        })

let update (msg:Msg) (model : Model) : Model*Cmd<Msg> =
    match msg with
    | OnItemClick id ->
        match model.AvailableItems |> List.tryFind(fun i -> i.Id = id) with
        | Some item ->
            match item.RentOrderId with
            | Some rentOrderId ->
                let (RentOrderId rentOrderIdGuid) = rentOrderId
                model, Cmd.batch [ (Navigation.newUrl (Routes.toPath (Page.RentOrderDetail rentOrderIdGuid))) ]
            | None -> model, Cmd.none
        | None -> model, Cmd.none
    | SelectTemplate template ->
        match template with
        | Some template ->
            { model with SelectedTemplate = template.value |> Some
                         FetchData = true }, Cmd.batch [ getNotAvailableRangesForEntitiesPerTemplateCmd template.value.Id; getTemplateWorkload template.value.Id ]
        | None -> model, Cmd.none
    | SelectStartDate startDate ->
        let updatedTemplateWorkloadDaterange =
            { model.TemplateWorkloadDaterange with StartDate = startDate }
        { model with TemplateWorkloadDaterange = updatedTemplateWorkloadDaterange }, Cmd.none
    | SelectEndDate endDate ->
        let updatedTemplateWorkloadDaterange =
            { model.TemplateWorkloadDaterange with EndDate = endDate }
        { model with TemplateWorkloadDaterange = updatedTemplateWorkloadDaterange }, Cmd.none
    | FetchTemplateWorkloadByDaterange ->
        match model.SelectedTemplate with
        | Some template ->
            { model with WorkloadRequest = true }, getTemplateWorkloadByDaterange template.Id model.TemplateWorkloadDaterange.StartDate model.TemplateWorkloadDaterange.EndDate
        | None -> model, Cmd.none
    | TemplateWorkloadByDaterangeResponse response ->
        { model with TemplateWorkloadByDaterange = response |> Some
                     WorkloadRequest = false }, Cmd.none
    | NotAvailableRangesFetched response ->
        let sorted = response.AvailableGroups |> List.sortWith (fun a b -> NaturalOrder.sortAsc a.Title b.Title)
        { model with Groups = sorted |> groups
                     ChartItems = response.AvailableItems
                     Items = response.AvailableItems |> items
                     AvailableItems = response.AvailableItems
                     FetchData = false }, Cmd.none
    | TemplatesFetched response ->
        { model with Templates = response.Templates }, Cmd.none
    | TemplateWorkloadResponse response ->
        { model with TemplateWorkload = response |> Some }, Cmd.none
    | FetchError e ->
        { model with WorkloadRequest = false }, Cmd.none
