module Order.View


open Fable.React
open Fable.React.Props
open Order.Types
open Routes
open SharedComponents
open Shared
open SharedComponents
open SharedComponents.Headers
open SharedComponents.Spinners
open Elmish.React.Helpers
open SharedComponents.Alerts

let orderTypeCard orderTypeName hrefOverview hrefNewForm className dispatch isCreateButtonVisible cyIdentifier =
    let cyIdentifierShow = "ordertypecard-show-" + cyIdentifier
    let cyIdentifierCreate = "ordertypecard-create-" + cyIdentifier
    div [ Class "card order-card" ]
        [ i [ Class (sprintf "card-icon fa-5x mt-2 %s" className)]
            [ ]
          div [ Class "card-body" ]
            [ h5 [ Class "card-title" ]
                [ str orderTypeName ]
              p [ Class "card-text" ]
                [ str orderTypeName ]
              a [ Href hrefOverview
                  Class "btn btn-primary"
                  Data ("cy",cyIdentifierShow ) ]
                [ str "Aufträge anzeigen" ]
              if isCreateButtonVisible then
                  a [ Href hrefNewForm
                      Class "btn btn-primary mt-2"
                      Data ("cy", cyIdentifierCreate) ]
                    [ str "Auftrag anlegen" ]
              else Fable.React.Helpers.nothing ] ]

let view (model : Model) (dispatch : Msg -> unit) =
    let isCreateButtonVisible =
        model.UserData.AssignedRoles
        |> List.exists (fun r -> r = Shared.RoleNames.Administrator || r = Shared.RoleNames.Dispatcher)
    div [ Id "order-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ div [ Id "order-content" ]
            [ mainHeader "Aufträge"
              div [ Class "d-flex flex-wrap" ]
                [ orderTypeCard
                      "Mietauftrag"
                      (Routes.toPath Page.RentOrderOverview)
                      (Routes.toPath Page.RentOrderNewForm)
                      "fas fa-retweet"
                      dispatch
                      isCreateButtonVisible
                      "rentorder"
                  orderTypeCard
                      "Serviceauftrag"
                      (Routes.toPath Page.RepairOrderOverview)
                      (Routes.toPath Page.RepairOrderNewForm)
                      "fas fa-wrench"
                      dispatch
                      isCreateButtonVisible
                      "repairorder"
                  orderTypeCard
                      "Eigener Auftrag"
                      (Routes.toPath Page.FreeOrderOverview)
                      (Routes.toPath Page.FreeOrderNewForm)
                      "fas fa-folder"
                      dispatch
                      isCreateButtonVisible
                      "greeorder" ] ] ]
