module ToastRenderer

open Thoth.Elmish
open Toast
open Fable.Core
open Fable.React
open Fable.React.Props

/// **Description**
/// Default implementation for the Toast renderer,
/// you are encourage to write your own implementation
/// to match your application style
/// **Output Type**
///   * `IRenderer<string>`
let render =
    { new IRenderer<string> with
        member __.Toast children _ =
            div [ Class "dispoheld-toast" ]
                children
        member __.CloseButton onClick =
            span [ Class "close-button"
                   OnClick onClick ]
                [ ]
        member __.InputArea children =
            div [ ]
                [ str "Not implemented yet" ]
        member __.Input (txt : string) (callback : (unit -> unit)) =
            div [ ]
                [ str "Not implemented yet" ]
        member __.Title txt =
            span [ Class "toast-title" ]
                [ str txt ]
        member __.Icon (icon : string) =
            div [ Class "toast-layout-icon" ]
                [ i [ Class ("fa fa-2x " + icon) ]
                    [  ] ]
        member __.SingleLayout title message =
            div [ Class "toast-layout-content" ]
                [ title; message ]
        member __.Message txt =
            span [ Class "toast-message" ]
                [ str txt ]
        member __.SplittedLayout iconView title message =
            div [ Style [ Display DisplayOptions.Flex
                          Width "100%" ] ]
                [ iconView
                  div [ Class "toast-layout-content" ]
                    [ title
                      message ] ]
        member __.StatusToColor status =
            match status with
            | Status.Success -> "is-success"
            | Status.Warning -> "is-warning"
            | Status.Error -> "is-error"
            | Status.Info -> "is-info" }