module Shared.Entity
open System
open Address
open Shared

type AttributeType =
    | Integer
    | Decimal
    | String
    | Boolean
    | Date
    | DateWithReminder
    | OptionalInteger
    | OptionalDecimal
    | OptionalString
    | OptionalBoolean
    | OptionalDate
    | OptionalDateWithReminder

type Attribute =
    { Id : AttributeId
      Name : string
      Type : AttributeType
      DisplayOnPrint : bool }

type EntityStringAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : string }

type EntityIntAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : int }

type EntityDecimalAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : float }

type EntityBoolAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : bool }

type EntityDateAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : System.DateTime }

type DateReminder =
    | ThreeDays
    | OneWeek
    | OneMonth

type EntityDateWithReminderAttribute =
    { //AttributeName : string
      AttributeId : AttributeId
      Value : System.DateTime
      Reminder : DateReminder }

type OptionalEntityStringAttribute =
    { AttributeId : AttributeId
      Value : string option }

type OptionalEntityIntAttribute =
    { AttributeId : AttributeId
      Value : int option }

type OptionalEntityDecimalAttribute =
    { AttributeId : AttributeId
      Value : float option }

type OptionalEntityBoolAttribute =
    { AttributeId : AttributeId
      Value : bool option }

type OptionalEntityDateAttribute =
    { AttributeId : AttributeId
      Value : System.DateTime option }

type OptionalEntityDateWithReminderAttribute =
    { AttributeId : AttributeId
      Value : System.DateTime option
      Reminder : DateReminder }

type EntityAttribute =
    | EntityStringAttribute of EntityStringAttribute
    | EntityIntAttribute of EntityIntAttribute
    | EntityDecimalAttribute of EntityDecimalAttribute
    | EntityBoolAttribute of EntityBoolAttribute
    | EntityDateAttribute of EntityDateAttribute
    | EntityDateWithReminderAttribute of EntityDateWithReminderAttribute
    | OptionalEntityStringAttribute of OptionalEntityStringAttribute
    | OptionalEntityIntAttribute of OptionalEntityIntAttribute
    | OptionalEntityDecimalAttribute of OptionalEntityDecimalAttribute
    | OptionalEntityBoolAttribute of OptionalEntityBoolAttribute
    | OptionalEntityDateAttribute of OptionalEntityDateAttribute
    | OptionalEntityDateWithReminderAttribute of OptionalEntityDateWithReminderAttribute

/// Templates

type TemplateBaseInformation =
    { Name : string
      Description : string }

//type SubTemplateQuantity =
//    { Min : int<Quantity>
//      Max : int<Quantity> }

//type SubTemplate =
//    { Id: SubTemplateId
//      TemplateBaseInformation : TemplateBaseInformation
//      Attributes : Attribute list
//      Quantity : SubTemplateQuantity
//      Image : Image option }

type ReferencedTemplate =
    { TemplateId : TemplateId
      Min : int
      Max : int }

type ChildTemplates =
    { Templates : ReferencedTemplate list }

type TemplateV1 =
    { Id : TemplateId
      TemplateBaseInformation : TemplateBaseInformation
      Attributes : Attribute list
      ChildTemplates : ChildTemplates option
      Image : Image option }

type Template =
    { Id : TemplateId
      TemplateBaseInformation : TemplateBaseInformation
      Attributes : Attribute list
      ChildTemplates : ChildTemplates option
      Documents : Document list
      Image : Image option
      Thumbnail : Image option
      Deleted : bool }

type TemplateImageDto =
    { TemplateId : TemplateId
      DocumentId : DocumentId }

type TemplateGroupUpdate =
    | BaseInformation of TemplateBaseInformation
    | Attibute of Attribute
    | NewAttributes of Attribute list
    | DeleteAttribute of AttributeId

type TemplatesResponse =
    { Templates : Template list }

// Entity

type EntityLocation =
    | ExecutionLocation of Shared.Address.Address
    | RentOrderLocation of RentOrderId
    | HomeLocation of LocationId
    | OnRoad of int
    | OnRoadFromRentOrder of RentOrderId * int
    | OnRoadToRentOrder of RentOrderId * int

type EntityActiveState =
    | Active
    | NotActive

type EntityV1 =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocation
      Attributes : EntityAttribute list
      Documents : Document list
      Image : Image option }

type NotAvailableRange =
    { Id : EntityNotAvailableRangeId
      StartDate : DateTime
      EndDate : DateTime option
      Description : string }

type Entity =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocation
      Attributes : EntityAttribute list
      Documents : Document list
      Image : Image option
      Thumbnail : Image option
      ActiveState : EntityActiveState
      NotAvailableRanges : NotAvailableRange list }

type UpdatEntityLocation =
    { Entity : Entity
      RentOrderId : RentOrderId
      All : (RentOrderId * Entity list) list }
type CreateMultipleEntities =
    { StartNumber : string
      Quantity : string }
    
type CreateEntityDto =
    { Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocation
      Attributes : EntityAttribute list
      Documents : Document list
      Image : Image option
      Thumbnail : Image option
      ActiveState : EntityActiveState
      NotAvailableRanges : NotAvailableRange list
      CreateMultipleEntities : CreateMultipleEntities option }
    
type EntityLocationForTemplateOverviewDto =
    | RentOrderLocation
    | HomeLocation
    | OnRoad
    | NotAvaiable

type EntityForTemplateOverviewDto =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocationForTemplateOverviewDto
      Attributes : EntityAttribute list
      ActiveState : EntityActiveState }
      
type EntityDto =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      Attributes : EntityAttribute list
      CurrentLocation : EntityLocation
      CurrentAddress : Address }

type EntityDashboardDto =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocation
      BuildingProjectName : string option
      ProjectNumber : string option
      CurrentAddress : Address }

type EntityDetailDto =
    { Id : EntityId
      Name : string
      TemplateId : TemplateId
      CurrentLocation : EntityLocation
      Attributes : EntityAttribute list
      Documents : Document list
      Image : Image option
      Thumbnail : Image option
      ActiveState : EntityActiveState
      CurrentAddress : Address
      NotAvailableRanges : NotAvailableRange list }

type EntityGroupUpdate =
    | Name of string
    | Attributes of EntityAttribute list
    | CurrentLocation of EntityLocation
    | ActiveState of EntityActiveState
    | AddNotAvailableRange of NotAvailableRange
    | UpdateNotAvailableRange of NotAvailableRange
    | RemoveNotAvailableRange of EntityNotAvailableRangeId

type AddNotAvailableRangeFailed =
    | OverlapsWithRentOrders of RentOrderId list
    | OverlapsWithOtherNotAvailableRange of NotAvailableRange list

type UpdateEntityResult =
    | Updated of EntityId
    | EntityNameAlreadyExists
    | EntityStateUpdated of EntityId * EntityActiveState
    | StateUpdatedFailed of EntityId
    | StateUpdatedFailedActiveEntityNameExists of EntityId
    | AddNotAvailableRangeFailed of AddNotAvailableRangeFailed

type EntityWithTemplate =
    { Id : EntityId
      Name : string
      CurrentLocation : EntityLocation
      Template : Template
      Attributes : EntityAttribute list }

type CompleteTemplate =
    { Id : TemplateId
      Name : string
      Description : string
      Attributes : Attribute list
      ChildTemplates : ChildTemplates option
      Documents : Document list
      Image : Image option
      Thumbnail : Image option
      Entities : Entity list
      Deleted : bool }

type EntityAllEntitiesOverviewDto =
    { EntityId : EntityId
      TemplateId : TemplateId
      Name : string
      TemplateName : string
      StorageName : string
      CurrentAddressAsString : string
      CurrentLocationTypeAsString : string
      ProjectNumber : string
      BuildingProjectNumber : string
      ActiveStateAsString : string }

type CompoundEntity =
    { Entity : Entity
      SubEntities : Entity list }

type CompoundEntityId =
    { EntityId : EntityId
      SubEntityIds : EntityId list }

type CompoundEntityIdDto =
    { EntityId : string
      SubEntityIds : string list }

type NotAvailableEntityDateTimes =
    { EntityId : EntityId
      DateTimes : System.DateTime list }

type EntitiesResponse =
    { Entities : Entity list }

type EntitiesOverviewDtoResponse =
    { Entities : EntityForTemplateOverviewDto list }

type EntitiesDtoResponse =
    { EntityDtos : EntityDto list }

type EntitiesDashboardDtoResponse =
    { Entities : EntityDashboardDto list }
    
type EntityForPrintAttribute =
    { Key : string
      Value : string }

type EntityForPrint =
    { EntityId : EntityId
      EntityName : string
      TemplateName : string
      KeyValueAttributes : EntityForPrintAttribute list }

type EntitiesForPrintResponse =
    { EntitiesForPrint : EntityForPrint list }

type EntityHistoryOrderType =
    | ServiceOrder of ServiceOrderId
    | RentOrder of RentOrderId * DateTime

type EntityHistoryState =
    | Planned
    | Active
    | Completed

type EntityHistory =
    { EntityHistoryOrderType : EntityHistoryOrderType
      State : EntityHistoryState
      Date : DateTime
      BuildingProjectName : string
      ProjectNumber : string
      Address : Address }

type EntityHistoryResponse =
    { EntityHistory : EntityHistory list }

type SaveTemplateResult =
    | Saved of TemplateId
    | InvalidName

type SaveEntityResult =
    | Saved of EntityId
    | EntityNameAlreadyExists
    | StartOrQuantityNumberIsNegative

type TransformEntityDto =
    { EntityId : EntityId
      TargetTemplateId : TemplateId }

type TransformEntityResult =
    | Saved of EntityId * TemplateId
    | NotInStorage
    | EntityNameAlreadyExists
    
module Helper =
    let emptyTemplate =
        { Id = System.Guid.Empty |> TemplateId
          TemplateBaseInformation = { Name = ""
                                      Description = "" }
          ChildTemplates = None
          Attributes = []
          Documents = []
          Image = None
          Thumbnail = None
          Deleted = false }

    let toTemplate (completedTemplate : CompleteTemplate) : Template =
        { Id = completedTemplate.Id
          TemplateBaseInformation = { Name = completedTemplate.Name
                                      Description = completedTemplate.Description }
          ChildTemplates = completedTemplate.ChildTemplates
          Attributes = completedTemplate.Attributes
          Documents = completedTemplate.Documents
          Image = completedTemplate.Image
          Thumbnail = completedTemplate.Thumbnail
          Deleted = completedTemplate.Deleted }

    let toEntityAttribute (attribute : Attribute) =
        match attribute.Type with
        | Integer ->
            { //EntityIntAttribute.AttributeName = attribute.Name
              EntityIntAttribute.AttributeId = attribute.Id
              Value = 0 }
            |> EntityAttribute.EntityIntAttribute
        | Decimal ->
            { //EntityDecimalAttribute.AttributeName = attribute.Name
              EntityDecimalAttribute.AttributeId = attribute.Id
              Value = 0. }
            |> EntityAttribute.EntityDecimalAttribute
        | String ->
            { //EntityStringAttribute.AttributeName = attribute.Name
              EntityStringAttribute.AttributeId = attribute.Id
              Value = "" }
            |> EntityAttribute.EntityStringAttribute
        | Boolean ->
            { //EntityBoolAttribute.AttributeName = attribute.Name
              EntityBoolAttribute.AttributeId = attribute.Id
              Value = false }
            |> EntityAttribute.EntityBoolAttribute
        | Date ->
            { //EntityDateAttribute.AttributeName = attribute.Name
              EntityDateAttribute.AttributeId = attribute.Id
              Value = System.DateTime.UtcNow.Date }
            |> EntityAttribute.EntityDateAttribute
        | DateWithReminder ->
            { //EntityDateWithReminderAttribute.AttributeName = attribute.Name
              EntityDateWithReminderAttribute.AttributeId = attribute.Id
              Reminder = OneMonth
              Value = System.DateTime.UtcNow.Date }
            |> EntityAttribute.EntityDateWithReminderAttribute
        | OptionalInteger ->
            { OptionalEntityIntAttribute.AttributeId = attribute.Id
              Value = None }
            |> EntityAttribute.OptionalEntityIntAttribute
        | OptionalDecimal ->
            { OptionalEntityDecimalAttribute.AttributeId = attribute.Id
              Value = None }
            |> EntityAttribute.OptionalEntityDecimalAttribute
        | OptionalString ->
            { OptionalEntityStringAttribute.AttributeId = attribute.Id
              Value = None }
            |> EntityAttribute.OptionalEntityStringAttribute
        | OptionalBoolean ->
            { OptionalEntityBoolAttribute.AttributeId = attribute.Id
              Value = None }
            |> EntityAttribute.OptionalEntityBoolAttribute
        | OptionalDate ->
            { OptionalEntityDateAttribute.AttributeId = attribute.Id
              Value = None }
            |> EntityAttribute.OptionalEntityDateAttribute
        | OptionalDateWithReminder ->
            { OptionalEntityDateWithReminderAttribute.AttributeId = attribute.Id
              Reminder = OneMonth
              Value = None }
            |> EntityAttribute.OptionalEntityDateWithReminderAttribute

    let toEntityDocument entityId (document : Document) =
        { DocumentId = document.Id
          Name = document.Name
          Description = document.Description
          EntityId = entityId }

    let entityAttributeId attribute =
        match attribute with
        | EntityStringAttribute a -> a.AttributeId
        | EntityIntAttribute a -> a.AttributeId
        | EntityDecimalAttribute a -> a.AttributeId
        | EntityBoolAttribute a -> a.AttributeId
        | EntityDateAttribute a -> a.AttributeId
        | EntityDateWithReminderAttribute a -> a.AttributeId
        | OptionalEntityStringAttribute a -> a.AttributeId
        | OptionalEntityIntAttribute a -> a.AttributeId
        | OptionalEntityDecimalAttribute a -> a.AttributeId
        | OptionalEntityBoolAttribute a -> a.AttributeId
        | OptionalEntityDateAttribute a -> a.AttributeId
        | OptionalEntityDateWithReminderAttribute a -> a.AttributeId

    let entityAttributeValueAsString attribute =
        match attribute with
        | EntityStringAttribute a -> a.Value.ToString()
        | EntityIntAttribute a -> a.Value.ToString()
        | EntityDecimalAttribute a ->  a.Value |> Helper.floatValueToString
        | EntityBoolAttribute a -> if a.Value then "Ja" else "Nein"
        | EntityDateAttribute a -> a.Value.ToString("dd.MM.yyyy")
        | EntityDateWithReminderAttribute a -> a.Value.ToString("dd.MM.yyyy")
        | OptionalEntityStringAttribute a -> a.Value |> Option.defaultValue ""
        | OptionalEntityIntAttribute a -> match a.Value with | Some v -> v |> string | None -> ""
        | OptionalEntityDecimalAttribute a -> match a.Value with | Some v -> v |> Helper.floatValueToString | None -> ""
        | OptionalEntityBoolAttribute a ->
            match a.Value with
            | Some v -> if v then "Ja" else "Nein"
            | None -> ""
        | OptionalEntityDateAttribute a -> match a.Value with | Some v -> v.ToString("dd.MM.yyyy") | None -> ""
        | OptionalEntityDateWithReminderAttribute a -> match a.Value with | Some v -> v.ToString("dd.MM.yyyy") | None -> ""

    let entityAttributeValueOptinalDateTime attribute =
        match attribute with
        | EntityStringAttribute a -> None
        | EntityIntAttribute a -> None
        | EntityDecimalAttribute a -> None
        | EntityBoolAttribute a -> None
        | OptionalEntityStringAttribute a -> None
        | OptionalEntityIntAttribute a -> None
        | OptionalEntityDecimalAttribute a -> None
        | OptionalEntityBoolAttribute a -> None
        | OptionalEntityDateAttribute a -> a.Value
        | OptionalEntityDateWithReminderAttribute a -> a.Value
        | EntityDateAttribute a -> a.Value |> Some
        | EntityDateWithReminderAttribute a -> a.Value |> Some

    let isAttributeTypeOptional attributeType =
        match attributeType with
        | Integer
        | Decimal
        | String
        | Boolean
        | Date
        | DateWithReminder -> false
        | OptionalInteger
        | OptionalDecimal
        | OptionalString
        | OptionalBoolean
        | OptionalDate
        | OptionalDateWithReminder -> true

    let toOptionalAttributeType attributeType =
        match attributeType with
        | Integer -> OptionalInteger
        | Decimal -> OptionalDecimal
        | String -> OptionalString
        | Boolean -> OptionalBoolean
        | Date -> OptionalDate
        | DateWithReminder -> OptionalDateWithReminder
        | OptionalInteger -> OptionalInteger
        | OptionalDecimal -> OptionalDecimal
        | OptionalString -> OptionalString
        | OptionalBoolean -> OptionalBoolean
        | OptionalDate -> OptionalDate
        | OptionalDateWithReminder -> OptionalDateWithReminder

    let toAttributeType attributeType =
        match attributeType with
        | Integer -> Integer
        | Decimal -> Decimal
        | String -> String
        | Boolean -> Boolean
        | Date -> Date
        | DateWithReminder -> DateWithReminder
        | OptionalInteger -> Integer
        | OptionalDecimal -> Decimal
        | OptionalString -> String
        | OptionalBoolean -> Boolean
        | OptionalDate -> Date
        | OptionalDateWithReminder -> DateWithReminder

    let toString attributeType =
        match attributeType with
        | Integer -> "Ganzzahl"
        | Decimal -> "Kommazahl"
        | String -> "Text"
        | Boolean -> "ja/nein"
        | Date -> "Datum"
        | DateWithReminder -> "Datum mit Erinnerung (optional)"
        | OptionalInteger -> "Ganzzahl (optional)"
        | OptionalDecimal -> "Kommazahl (optional)"
        | OptionalString -> "Text (optional)"
        | OptionalBoolean -> "ja/nein (optional)"
        | OptionalDate -> "Datum (optional)"
        | OptionalDateWithReminder -> "Datum mit Erinnerung (optional)"

    let idToString (EntityId entityId) = entityId.ToString()

    let activeStateAsString (state : EntityActiveState) =
        match state with
        | EntityActiveState.Active -> "Aktiv"
        | NotActive -> "außer Betrieb"