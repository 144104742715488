module MasterData.Entities.DocumentDeleteDialog

open Elmish
open Feliz
open Shared
open SharedComponents
open Feliz.SweetAlert
open Shared.Entity

type Props =
    { Entity : EntityDetailDto
      IsTemplateDocument : bool
      DocumentId : DocumentId
      SuccessCallback : unit -> unit }

let private deleteDocumentCmd (entity : EntityDetailDto) isTemplateDocument documentId =
    let docId = documentId |> Helper.unwrapDocumentId
    let entityId = entity.Id |> Helper.unwrapEntityId
    let templateId = entity.TemplateId |> Helper.unwrapTemplateId
    
    let url = 
        if isTemplateDocument
        then sprintf "/api/templates/%s/document/%s/delete" templateId docId
        else sprintf "/api/entities/%s/document/%s/delete" entityId docId
    Communication.deleteRequest<Shared.EmptyResponse> url

let private dialogBody props =
    Html.div [
        prop.className "form-group"
        prop.children [
            Html.label [
                prop.htmlFor "input-manufacturer"
                prop.className "form-label"
                prop.children [ Html.text "Willst du das Dokument wirklich löschen?" ]
            ]
            Html.div [
                prop.className "swal2-actions"
                prop.children [
                    Buttons.primaryButtonWithFnct
                        (fun _ ->
                            deleteDocumentCmd props.Entity props.IsTemplateDocument props.DocumentId
                            |> Promise.iter (fun res ->
                                Swal.close (SweetAlert.Result.Value ()))
                        )
                        "Löschen" "mr-3"
                    Buttons.secondaryButtonWithFnct
                        (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                        "Abbrechen" false ""
                ]
            ]
        ]
    ]

let private documentDeleteDialog' = React.functionComponent(fun props ->
    Buttons.iconButton "fas fa-times"
        (fun _ ->
            Swal.fire ([
                swal.html (dialogBody props)
                swal.showCancelButton false
                swal.showConfirmButton false
                ],
                (function
                    | SweetAlert.Result.Value _ ->
                        props.SuccessCallback ()
                        Swal.Simple.success "Dokument wurde erfolgreich gelöscht"
                    | SweetAlert.Result.Dismissal d ->
                        ()
                    | SweetAlert.Result.Denied ->
                        printfn "Denied"
                )
            )
        )
    )

let documentDeleteDialog (entity : EntityDetailDto) isTemplateDocument (documentId : DocumentId) successCallback =
    documentDeleteDialog'
        { Entity = entity
          IsTemplateDocument = isTemplateDocument
          DocumentId = documentId
          SuccessCallback = successCallback }
