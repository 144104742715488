module App

open Client.Types
open Client.View
open Elmish
open Elmish.Navigation
open Elmish.HMR
open Elmish.React
open Fable.Import
open Thoth.Elmish
open Routes
open Shared
open Browser.Dom
open Fable.Core
open Fable.Core.JsInterop
open Browser.WebStorage
open Thoth.Json
open Sww.Frontend.I18next

let jq = importDefault<obj> "jquery"

[<Import("*", from="core-js")>]

type SentryUser =
    { Username : string }

type SentryToken =
        { dsn : string }

[<ImportAll("@sentry/browser")>]
let sentry: obj = jsNative

let handleNotFound (model: Model) =
    console.error("Error parsing url: " + window.location.href)
    ( model, Navigation.modifyUrl (toPath Page.Dashboard) )

let handleNotPermitted (model: Model) =
    console.error("Error parsing url cause access not allow: " + window.location.href)
    ( model, Navigation.modifyUrl (toPath Page.Dashboard) )

let handleNoUser (model : Model) =
    match localStorage.getItem "user" |> Decode.Auto.fromString<UserData> with
    | Result.Ok userData ->
        Some userData, { model with User = Some userData }
    | Result.Error _ ->
        None, model

// let initI18nCmd =
//     Cmd.OfPromise.perform Sww.Frontend.I18next.initialize "mwk_userdata" (fun _ -> Initialized)

let urlUpdate (result:Page option) (model: Model) =
    if result.IsSome && model.User.IsSome && not (isPageVisibleForRole result.Value model.User.Value.AssignedRoles) then
        handleNotPermitted model
    else
        let user, model =
            match model.User with
            | Some user -> Some user, model
            | None -> handleNoUser model

        match result, user with
        | None, _ ->
            handleNotFound model
        | Some Page.Login, _ ->
            let m, cmd = Login.State.init ()
            { model with PageModel = LoginPageModel m
                         Route = Page.Login }, Cmd.map LoginMsg cmd
        | Some Page.FatalErrorView, _ ->
            let m, cmd = ErrorViews.FatalErrorView.init ()
            { model with PageModel = FatalErrorViewModel m
                         Route = Page.FatalErrorView }, Cmd.map FatalErrorViewMsg cmd
        | Some Page.NotLoggedInErrorView, _ ->
            let m, cmd = ErrorViews.NotLoggedInErrorView.init ()
            { model with PageModel = NotLoggedInViewModel m
                         Route = Page.NotLoggedInErrorView }, Cmd.map NotLoggedInViewMsg cmd
        | Some Page.Dashboard, Some user ->
            let m, cmd = Dashboard.State.init user
            { model with PageModel = DashboardModel m
                         Route = Page.Dashboard }, Cmd.map DashboardMsg cmd
        | Some Page.AllEntities, Some user ->
            let m, cmd = MasterData.AllEntities.State.init user
            { model with PageModel = AllEntitiesModel m
                         Route = Page.AllEntities }, Cmd.map AllEntitiesMsg cmd
        | Some Page.Templates, Some user ->
            let m, cmd = MasterData.Templates.State.initOverview user
            { model with PageModel = TemplatesModel m
                         Route = Page.Templates }, Cmd.map TemplatesMsg cmd
        | Some Page.TemplatesNewForm, Some user ->
            let m, cmd = TemplateConfiguration.State.initNewTemplateForm user
            { model with PageModel = TemplateConfigurationModel m
                         Route = Page.TemplatesNewForm }, Cmd.map TemplateConfigurationMsg cmd
        | Some (Page.TemplatesViewForm templateId), Some user ->
            let m, cmd = TemplateConfiguration.State.initViewForm templateId user
            { model with PageModel = TemplateConfigurationModel m
                         Route = (Page.TemplatesViewForm templateId) }, Cmd.map TemplateConfigurationMsg cmd
        | Some Page.TemplateGroupNewForm, Some user ->
            let m, cmd = TemplateConfiguration.State.initNewTemplateGroupForm user
            { model with PageModel = TemplateConfigurationModel m
                         Route = Page.TemplateGroupNewForm }, Cmd.map TemplateConfigurationMsg cmd
        | Some (Page.TemplateGroupViewForm templateGroupId), Some user ->
            let m, cmd = TemplateConfiguration.State.initViewForm templateGroupId user
            { model with PageModel = TemplateConfigurationModel m
                         Route = (Page.TemplateGroupViewForm templateGroupId) }, Cmd.map TemplateConfigurationMsg cmd
        | Some Page.MyTaskOverview, Some user ->
            let m, cmd = MyTask.State.init user
            { model with PageModel = MyTaskModel m
                         Route = (Page.MyTaskOverview) }, Cmd.map MyTaskMsg cmd
        | Some Page.MyProfil, Some user ->
            let m, cmd = MyProfil.State.init user
            { model with PageModel = MyProfilModel m
                         Route = (Page.MyProfil) }, Cmd.map MyProfilMsg cmd
        | Some Page.Appointment, Some user ->
            let m, cmd = Appointment.State.init user
            { model with PageModel = AppointmentModel m
                         Route = (Page.Appointment) }, Cmd.map AppointmentMsg cmd
        | Some Page.Available, Some user ->
            let m, cmd = Available.State.init user
            { model with PageModel = AvailableModel m
                         Route = (Page.Available) }, Cmd.map AvailableMsg cmd
        | Some Page.Reports, Some user ->
            let m, cmd = Reports.State.init user
            { model with PageModel = ReportsModel m
                         Route = (Page.Reports) }, Cmd.map ReportsMsg cmd
        | Some Page.Control, Some user ->
            if (user.AssignedRoles |> List.contains (Administrator)) then
                let m, cmd = Control.State.init user
                { model with PageModel = ControlModel m
                             Route = (Page.Control) }, Cmd.map ControlMsg cmd
            else model, Cmd.none
        | Some Page.Support, Some user ->
            let m, cmd = Support.State.init ()
            { model with PageModel = SupportModel m
                         Route = (Page.Support) }, Cmd.map SupportMsg cmd
        | Some Page.TemplateConfiguration, Some user ->
            let m, cmd = TemplateConfiguration.State.initOverview (user)
            { model with PageModel = TemplateConfigurationModel m
                         Route = (Page.TemplateConfiguration) }, Cmd.map TemplateConfigurationMsg cmd
        | Some (Page.MyTaskViewForm taskId), Some user ->
            let m, cmd = MyTask.State.initDetailView taskId user
            { model with PageModel = MyTaskModel m
                         Route = (Page.MyTaskViewForm taskId) }, Cmd.map MyTaskMsg cmd
        | Some (Page.Entity templateGroupId), Some user ->
            let oldModel =
                match model.PageModel with
                | EntityModel t -> Some t
                | _ -> None
            let m, cmd = MasterData.Entities.State.init templateGroupId user oldModel
            { model with PageModel = EntityModel m
                         Route = Page.Entity templateGroupId }, Cmd.map EntityMsg cmd
        | Some (Page.DeactivateEntity templateGroupId), Some user ->
            let oldModel =
                match model.PageModel with
                | EntityModel t -> Some t
                | _ -> None
            let m, cmd = MasterData.Entities.State.initDeactivatedEntities templateGroupId user oldModel
            { model with PageModel = EntityModel m
                         Route = Page.DeactivateEntity templateGroupId }, Cmd.map EntityMsg cmd
        | Some (Page.EntityNewForm templateGroupId), Some user ->
            let m, cmd = MasterData.Entities.State.initNewForm user templateGroupId
            { model with PageModel = EntityModel m
                         Route = Page.EntityNewForm templateGroupId }, Cmd.map EntityMsg cmd
        | Some (Page.EntityViewForm (templateId, entityId)), Some user ->
            let m, cmd = MasterData.Entities.State.initViewForm user templateId entityId
            { model with PageModel = EntityModel m
                         Route = (Page.EntityViewForm (templateId, entityId)) }, Cmd.map EntityMsg cmd
        | Some (Page.EntityCopyForm (templateId, entityId)), Some user ->
            let m, cmd = MasterData.Entities.State.initCopyForm user templateId entityId
            { model with PageModel = EntityModel m
                         Route = (Page.EntityCopyForm (templateId, entityId)) }, Cmd.map EntityMsg cmd
        | Some Page.MessageCenter, Some user ->
            let m, cmd = MessageCenter.State.init ()
            { model with PageModel = MessageCenterModel m
                         Route = Page.MessageCenter }, Cmd.map MessageCenterMsg cmd
        | Some Page.UserManagement, Some user ->
            let oldModel =
                match model.PageModel with
                | UserManagementModel e -> Some e
                | _ -> None
            let m, cmd = UserManagement.State.init oldModel
            { model with PageModel = UserManagementModel m
                         Route = Page.UserManagement }, Cmd.map UserManagementMsg cmd
        | Some Page.UserManagementNewForm, Some user ->
            let m, cmd = UserManagement.State.initNewForm ()
            { model with PageModel = UserManagementModel m
                         Route = Page.UserManagementNewForm }, Cmd.map UserManagementMsg cmd
        | Some (Page.UserManagementViewForm userId), Some user ->
            let m, cmd = UserManagement.State.initViewForm userId
            { model with PageModel = UserManagementModel m
                         Route = Page.UserManagementViewForm userId }, Cmd.map UserManagementMsg cmd


        | Some Page.CarrierManagement, Some user ->
            let m, cmd = CarrierManagement.State.initOverview user
            { model with PageModel = CarrierManagementModel m
                         Route = Page.CarrierManagement }, Cmd.map CarrierManagementMsg cmd
        | Some Page.CarrierManagementNewForm, Some user ->
            let m, cmd = CarrierManagement.State.initNewView user
            { model with PageModel = CarrierManagementModel m
                         Route = Page.CarrierManagementNewForm }, Cmd.map CarrierManagementMsg cmd
        | Some (Page.CarrierManagementViewForm carrierId), Some user ->
            let m, cmd = CarrierManagement.State.initEditView carrierId user
            { model with PageModel = CarrierManagementModel m
                         Route = Page.CarrierManagementViewForm carrierId }, Cmd.map CarrierManagementMsg cmd


        | Some Page.DispositionOverview, Some user ->
            let m, cmd = DispositionOverview.State.init ()
            { model with PageModel = DispositionOverviewModel m
                         Route = Page.DispositionOverview }, Cmd.map DispositionOverviewMsg cmd
        | Some (Page.DispostionNewForm query), Some user ->
            let m, cmd = Dispositions.State.init query
            { model with PageModel = DispositionModel m
                         Route = Page.DispostionNewForm query}, Cmd.map DispositonMsg cmd
        | Some Page.Configuration, Some user ->
            let m, cmd = Configuration.State.init
            { model with PageModel = ConfigurationModel m
                         Route = Page.Configuration }, Cmd.map ConfigurationMsg cmd
        | Some Page.OrderOverview, Some user ->
            let m, cmd = Order.State.init user
            { model with PageModel = OrderModel m
                         Route = Page.OrderOverview }, Cmd.map OrderMsg cmd
        | Some Page.RentOrderOverview, Some user ->
            let oldModel =
                match model.PageModel with
                | RentOrderModel rentOrderModel -> Some rentOrderModel
                | _ -> None
            let m, cmd = Order.RentOrder.State.initOverview user oldModel
            { model with PageModel = RentOrderModel m
                         Route = Page.RentOrderOverview }, Cmd.map RentOrderMsg cmd
        | Some Page.RentOrderOverviewCompleted, Some user ->
            let oldModel =
                match model.PageModel with
                | RentOrderModel rentOrderModel -> Some rentOrderModel
                | _ -> None
            let m, cmd = Order.RentOrder.State.initOverviewCompleted user oldModel
            { model with PageModel = RentOrderModel m
                         Route = Page.RentOrderOverviewCompleted }, Cmd.map RentOrderMsg cmd
        | Some Page.RentOrderNewForm, Some user ->
            let m, cmd = Order.RentOrder.State.initNewForm user
            { model with PageModel = RentOrderModel m
                         Route = Page.RentOrderNewForm }, Cmd.map RentOrderMsg cmd
        | Some (Page.RentOrderDetail orderId), Some user ->
            let m, cmd = Order.RentOrder.State.initDetail user orderId
            { model with PageModel = RentOrderModel m
                         Route = Page.RentOrderDetail orderId }, Cmd.map RentOrderMsg cmd
        | Some (Page.RentOrderEditForm orderId), Some user ->
            let m, cmd = Order.RentOrder.State.initEditForm user orderId
            { model with PageModel = RentOrderModel m
                         Route = Page.RentOrderEditForm orderId }, Cmd.map RentOrderMsg cmd
        | Some (Page.RentOrderDispositionDetail (orderId, taskId)), Some user ->
            let m, cmd = Dispositions.RentOrderDisposition.State.init (RentOrderId orderId) (TaskId taskId)
            { model with PageModel = RentOrderDispositionModel m
                         Route = Page.RentOrderDispositionDetail (orderId, taskId) }, Cmd.map RentOrderDispositionMsg cmd
        | Some Page.RepairOrderOverview, Some user ->
            let oldModel =
                match model.PageModel with
                | RepairOrderModel repairOrderModel -> Some repairOrderModel
                | _ -> None
            let m, cmd = Order.ServiceOrder.State.initOverview user oldModel
            { model with PageModel = RepairOrderModel m
                         Route = Page.RepairOrderOverview }, Cmd.map RepairOrderMsg cmd
        | Some Page.ServiceOrderOverviewCompleted, Some user ->
            let oldModel =
                match model.PageModel with
                | RepairOrderModel repairOrderModel -> Some repairOrderModel
                | _ -> None
            let m, cmd = Order.ServiceOrder.State.initOverviewCompleted user oldModel
            { model with PageModel = RepairOrderModel m
                         Route = Page.ServiceOrderOverviewCompleted }, Cmd.map RepairOrderMsg cmd
        | Some Page.RepairOrderNewForm, Some user ->
            let m, cmd = Order.ServiceOrder.State.initNewForm user
            { model with PageModel = RepairOrderModel m
                         Route = Page.RepairOrderNewForm }, Cmd.map RepairOrderMsg cmd
        | Some (Page.RepairOrderDetail orderId), Some user ->
            let m, cmd = Order.ServiceOrder.State.initDetail user orderId
            { model with PageModel = RepairOrderModel m
                         Route = Page.RepairOrderDetail orderId }, Cmd.map RepairOrderMsg cmd
        | Some (Page.RepairOrderDispositionDetail (orderId, taskId)), Some user ->
            let m, cmd = Dispositions.RepairOrderDisposition.State.init (ServiceOrderId orderId) (TaskId taskId)
            { model with PageModel = RepairOrderDispositionModel m
                         Route = Page.RepairOrderDispositionDetail (orderId, taskId) }, Cmd.map RepairOrderDispositionMsg cmd


        | Some Page.FreeOrderOverview, Some user ->
            let oldModel =
                match model.PageModel with
                | FreeOrderModel model -> Some model
                | _ -> None
            let m, cmd = Order.FreeOrder.State.initOverview user oldModel
            { model with PageModel = FreeOrderModel m
                         Route = Page.FreeOrderOverview }, Cmd.map FreeOrderMsg cmd
        | Some Page.FreeOrderOverviewCompleted, Some user ->
            let oldModel =
                match model.PageModel with
                | FreeOrderModel model -> Some model
                | _ -> None
            let m, cmd = Order.FreeOrder.State.initOverviewCompleted user oldModel
            { model with PageModel = FreeOrderModel m
                         Route = Page.FreeOrderOverviewCompleted }, Cmd.map FreeOrderMsg cmd
        | Some Page.FreeOrderNewForm, Some user ->
            let m, cmd = Order.FreeOrder.State.initNewForm user
            { model with PageModel = FreeOrderModel m
                         Route = Page.FreeOrderNewForm }, Cmd.map FreeOrderMsg cmd
        | Some (Page.FreeOrderDetail orderId), Some user ->
            let m, cmd = Order.FreeOrder.State.initDetail user orderId
            { model with PageModel = FreeOrderModel m
                         Route = Page.FreeOrderDetail orderId }, Cmd.map FreeOrderMsg cmd
        | Some (Page.FreeOrderDispositionDetail (orderId, taskId)), Some user ->
            let m, cmd = Dispositions.FreeOrderDisposition.State.init (FreeOrderId orderId) (TaskId taskId)
            { model with PageModel = FreeOrderDispositionModel m
                         Route = Page.FreeOrderDispositionDetail (orderId, taskId) }, Cmd.map FreeOrderDispositionMsg cmd

        | Some Page.MaterialOverview, Some user ->
            let m, cmd = MasterData.Material.State.initOverview user
            { model with PageModel = MaterialModel m
                         Route = Page.MaterialOverview }, Cmd.map MaterialMsg cmd
        | Some Page.MaterialNewForm, Some user ->
            let m, cmd = MasterData.Material.State.initNewView user
            { model with PageModel = MaterialModel m
                         Route = Page.MaterialNewForm }, Cmd.map MaterialMsg cmd
        | Some (Page.MaterialEditForm materialId), Some user ->
            let m, cmd = MasterData.Material.State.initEditView materialId user
            { model with PageModel = MaterialModel m
                         Route = Page.MaterialEditForm materialId }, Cmd.map MaterialMsg cmd
        | _, None ->
            let m, cmd = Login.State.init ()
            { model with PageModel = LoginPageModel m
                         Route = Page.Login }, Navigation.newUrl (toPath Page.Login)

let init page : Model * Cmd<Msg> =
    let env = localStorage.getItem "env" |> Option.ofObj
    match env with
    | Some e when e <> "test" ->
        sentry?init({ dsn = "https://71cf0a18fa5a40369aa5c52bddcedfb4@sentry.io/1449446"})
    | Some _
    | None -> ()
    let user = { UserName = ""
                 DisplayName = ""
                 UserId = 0
                 AssignedRoles = []
                 Token = ""
                 CompanyName = ""
                 FirebaseToken = "" }
    let dashboardModel, cmd = Dashboard.State.init user
    let initialModel =
        { PageModel = PageModel.DashboardModel dashboardModel
          Route = Page.Dashboard
          User = None
          ToggleMenuState = Closed
          Initialized = false }
    let model, cmd = urlUpdate page initialModel
    model, Cmd.batch [ cmd; Cmd.OfPromise.perform Sww.Frontend.I18next.initialize "mwk_userdata" (fun _ -> Initialized)]

let update (msg : Msg) (currentModel : Model) : Model * Cmd<Msg> =
    match currentModel.PageModel, msg with
    | LoginPageModel m, LoginMsg message ->
        match message with
        | Login.Types.Msg.LoginSuccessfull userData ->
            /// Msg intercepted
            let env = localStorage.getItem "env" |> Option.ofObj
            match env with
            | Some e when e <> "test" ->
                sentry?setUser({SentryUser.Username = userData.UserName})
            | Some _
            | None -> ()

            currentModel, Cmd.OfFunc.either (fun data -> localStorage.setItem ("user", Encode.Auto.toString(0, data))) userData (fun _ -> LoggedIn userData) StorageFailure
        | _ ->
            let pageModel, pageCmd = Login.State.update message m
            { currentModel with PageModel = LoginPageModel pageModel }, Cmd.map LoginMsg pageCmd
    | _, LoggedIn newUser ->
        { currentModel with User = Some newUser }, Navigation.newUrl (toPath Page.Dashboard)
    | _, Logout ->
        currentModel, Cmd.OfFunc.either localStorage.removeItem "user" (fun _ -> LoggedOut) StorageFailure
    | _, LoggedOut ->
        { currentModel with User = None }, Navigation.newUrl (Routes.toPath Routes.Page.Login)
    | _, Initialized ->
        { currentModel with Initialized = true }, Cmd.none
    | _, StorageFailure e ->
        printfn "Unable to access local storage: %A" e // TODO error handling
        currentModel, Cmd.none
    | _, ToggleMenu ->
        let selector = jq $ ("#main-navbar")
        let isCollapsing : bool = selector?hasClass("collapse")
        let newToggleMenuState =
            if isCollapsing then
                selector?removeClass ("show")
                ToggleMenuState.Closed
            else currentModel.ToggleMenuState
        { currentModel with ToggleMenuState = newToggleMenuState }, Cmd.none
    | FatalErrorViewModel m, FatalErrorViewMsg message ->
        let pageModel, pageCmd = ErrorViews.FatalErrorView.update message m
        { currentModel with PageModel = FatalErrorViewModel pageModel }, Cmd.map FatalErrorViewMsg pageCmd
    | NotLoggedInViewModel m, NotLoggedInViewMsg message ->
        let pageModel, pageCmd =
            match message with
            | ErrorViews.NotLoggedInErrorView.Msg.Logout ->
                { currentModel with User = None }, Cmd.OfFunc.attempt localStorage.removeItem "user" StorageFailure
            | _ ->
                let pageModel, pageCmd = ErrorViews.NotLoggedInErrorView.update message m
                { currentModel with PageModel = NotLoggedInViewModel pageModel }, Cmd.map NotLoggedInViewMsg pageCmd
        pageModel, pageCmd
    | DashboardModel m, DashboardMsg message ->
        let pageModel, pageCmd = Dashboard.State.update message m
        { currentModel with PageModel = DashboardModel pageModel }, Cmd.map DashboardMsg pageCmd
    | AllEntitiesModel m, AllEntitiesMsg message ->
        let pageModel, pageCmd = MasterData.AllEntities.State.update message m
        { currentModel with PageModel = AllEntitiesModel pageModel }, Cmd.map AllEntitiesMsg pageCmd
    | TemplatesModel m, TemplatesMsg message ->
        let pageModel, pageCmd = MasterData.Templates.State.update message m
        { currentModel with PageModel = TemplatesModel pageModel }, Cmd.map TemplatesMsg pageCmd
    | EntityModel m, EntityMsg message ->
        let pageModel, pageCmd = MasterData.Entities.State.update message m
        { currentModel with PageModel = EntityModel pageModel }, Cmd.map EntityMsg pageCmd
    | MessageCenterModel m, MessageCenterMsg message ->
        let pageModel, pageCmd = MessageCenter.State.update message m
        { currentModel with PageModel = MessageCenterModel pageModel }, Cmd.map MessageCenterMsg pageCmd
    | UserManagementModel m, UserManagementMsg message ->
        let pageModel, pageCmd = UserManagement.State.update message m
        { currentModel with PageModel = UserManagementModel pageModel }, Cmd.map UserManagementMsg pageCmd
    | DispositionOverviewModel m, DispositionOverviewMsg message ->
        let pageModel, pageCmd = DispositionOverview.State.update message m
        { currentModel with PageModel = DispositionOverviewModel pageModel }, Cmd.map DispositionOverviewMsg pageCmd
    | DispositionModel m, DispositonMsg message ->
        let pageModel, pageCmd = Dispositions.State.update message m
        { currentModel with PageModel = DispositionModel pageModel }, Cmd.map DispositonMsg pageCmd
    | RentOrderDispositionModel m, RentOrderDispositionMsg message ->
        let pageModel, pageCmd = Dispositions.RentOrderDisposition.State.update message m
        { currentModel with PageModel = RentOrderDispositionModel pageModel }, Cmd.map RentOrderDispositionMsg pageCmd
    | MyTaskModel m, MyTaskMsg message ->
        let pageModel, pageCmd = MyTask.State.update message m
        { currentModel with PageModel = MyTaskModel pageModel }, Cmd.map MyTaskMsg pageCmd
    | AppointmentModel m, AppointmentMsg message ->
        let pageModel, pageCmd = Appointment.State.update message m
        { currentModel with PageModel = AppointmentModel pageModel }, Cmd.map AppointmentMsg pageCmd
    | AvailableModel m, AvailableMsg message ->
        let pageModel, pageCmd = Available.State.update message m
        { currentModel with PageModel = AvailableModel pageModel }, Cmd.map AvailableMsg pageCmd
    | ReportsModel m, ReportsMsg message ->
        let pageModel, pageCmd = Reports.State.update message m
        { currentModel with PageModel = ReportsModel pageModel }, Cmd.map ReportsMsg pageCmd
    | ControlModel m, ControlMsg message ->
        let pageModel, pageCmd = Control.State.update message m
        { currentModel with PageModel = ControlModel pageModel }, Cmd.map ControlMsg pageCmd
    | MyProfilModel m, MyProfilMsg message ->
        let pageModel, pageCmd = MyProfil.State.update message m
        { currentModel with PageModel = MyProfilModel pageModel }, Cmd.map MyProfilMsg pageCmd
    | SupportModel m, SupportMsg message ->
        let pageModel, pageCmd = Support.State.update message m
        { currentModel with PageModel = SupportModel pageModel }, Cmd.map SupportMsg pageCmd
    | TemplateConfigurationModel m, TemplateConfigurationMsg message ->
        let pageModel, pageCmd = TemplateConfiguration.State.update message m
        { currentModel with PageModel = TemplateConfigurationModel pageModel }, Cmd.map TemplateConfigurationMsg pageCmd
    | OrderModel m, OrderMsg message ->
        let pageModel, pageCmd = Order.State.update message m
        { currentModel with PageModel = OrderModel pageModel }, Cmd.map OrderMsg pageCmd
    | RentOrderModel m, RentOrderMsg message ->
        let pageModel, pageCmd = Order.RentOrder.State.update message m
        { currentModel with PageModel = RentOrderModel pageModel }, Cmd.map RentOrderMsg pageCmd
    | ConfigurationModel m, ConfigurationMsg message ->
        let pageModel, pageCmd = Configuration.State.update message m
        { currentModel with PageModel = ConfigurationModel pageModel }, Cmd.map ConfigurationMsg pageCmd
    | RepairOrderModel m, RepairOrderMsg message ->
        let pageModel, pageCmd = Order.ServiceOrder.State.update message m
        { currentModel with PageModel = RepairOrderModel pageModel }, Cmd.map RepairOrderMsg pageCmd
    | RepairOrderDispositionModel m, RepairOrderDispositionMsg message ->
        let pageModel, pageCmd = Dispositions.RepairOrderDisposition.State.update message m
        { currentModel with PageModel = RepairOrderDispositionModel pageModel }, Cmd.map RepairOrderDispositionMsg pageCmd
    | FreeOrderModel m, FreeOrderMsg message ->
        let pageModel, pageCmd = Order.FreeOrder.State.update message m
        { currentModel with PageModel = FreeOrderModel pageModel }, Cmd.map FreeOrderMsg pageCmd
    | FreeOrderDispositionModel m, FreeOrderDispositionMsg message ->
        let pageModel, pageCmd = Dispositions.FreeOrderDisposition.State.update message m
        { currentModel with PageModel = FreeOrderDispositionModel pageModel }, Cmd.map FreeOrderDispositionMsg pageCmd
    | MaterialModel m, MaterialMsg message ->
        let pageModel, pageCmd = MasterData.Material.State.update message m
        { currentModel with PageModel = MaterialModel pageModel }, Cmd.map MaterialMsg pageCmd
    | CarrierManagementModel m, CarrierManagementMsg message ->
        let pageModel, pageCmd = CarrierManagement.State.update message m
        { currentModel with PageModel = CarrierManagementModel pageModel }, Cmd.map CarrierManagementMsg pageCmd
    | _ -> currentModel, Cmd.none

#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

Program.mkProgram init update view
|> Program.toNavigable Routes.urlParser urlUpdate
|> Toast.Program.withToast ToastRenderer.render
#if DEBUG
|> Program.withConsoleTrace
// |> Program.withHMR
#endif
|> Program.withReactBatched "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
#if !DEBUG
|> Program.withErrorHandler
    (fun (error, exn) ->
        let env = localStorage.getItem "env" |> Option.ofObj
        match env with
        | Some e when e <> "test" ->
            sentry?captureException(exn)
        | Some _
        | None ->
            raise exn)
#endif
|> Program.run
