module Reports.View

open Fable.React
open Fable.React.Props
open Reports.Types
open SharedComponents.Spinners
open SharedComponents.ReactSelect
open Feliz
open Shared.Reports
open Shared
open Sww.Frontend.ReactTable
open SharedComponents.Headers

let detailBtn (entity : AvailablePerEntity) =
    let (EntityId entityId) = entity.EntityId
    let (TemplateId templateId) = entity.TemplateId
    a [ Href (Routes.toPath (Routes.Page.EntityViewForm (templateId, entityId)) ) ]
        [ i [ Class ("fas fa-eye" ) ] [ ]
          span [ Class "url-text" ] [ str "Anzeigen" ] ]

let private report1 (model : Model) dispatch =
    let reportProps (model : Model)  (initialReportData : InitialReportData) dispatch isDisabled =
        let options =
            initialReportData.TemplateNamesAndIds
            |> List.map (fun r ->
                let name, id = r
                { SharedComponents.ReactSelect.label = name
                  value = id })
            |> List.toArray

        let emptySearchResultContent =
            div [ Class "mr-auto p-2" ]
              [ str "Es konnte kein passender Bericht gefunden werden." ]

        let valueElement =
            match model.SelectedTemplateId with
            | Some templateId ->
                let label =
                    match initialReportData.TemplateNamesAndIds
                          |> List.tryFind(fun r ->
                            let name, id = r
                            id = templateId) with
                    | Some r ->
                        let name, id = r
                        name
                    | None -> ""
                [| SharedComponents.ReactSelect.CommonProps<TemplateId>.Value (Some { label = label; value = templateId }) |]
            | None -> [| SharedComponents.ReactSelect.CommonProps<TemplateId>.Value None |]
        Array.append
            [| SharedComponents.ReactSelect.CommonProps<TemplateId>.Options options
               SharedComponents.ReactSelect.CommonProps<TemplateId>.OnChange (fun e -> dispatch (SelectTemplateId (e)))
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsSearchable true
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsClearable true
               SharedComponents.ReactSelect.CommonProps<TemplateId>.Placeholder "Suchen oder Filtern"
               SharedComponents.ReactSelect.CommonProps<TemplateId>.NoOptionsMessage (fun _ -> emptySearchResultContent)
               SharedComponents.ReactSelect.CommonProps<TemplateId>.ClassName "template-select flex-grow-1 mb-3"
               SharedComponents.ReactSelect.CommonProps<TemplateId>.IsDisabled isDisabled |]
            valueElement
    match model.InitialReportData with
    | Some initialReportData ->
        Html.div [
            Html.div [
                prop.className "d-flex"
                prop.children [
                    Html.div [
                        prop.className "flex-grow-1"
                        prop.children [
                            SharedComponents.ReactSelect.selectWithoutLabel (reportProps model initialReportData dispatch false)
                        ]
                    ]
                    Html.div [
                      prop.className "d-flex ml-auto"
                      prop.children [
                        Html.div [
                            prop.className "d-flex-row"
                            prop.children [
                              SharedComponents.ReactDatepicker.Datepicker.datepicker(
                                  Some model.StartDate,
                                  (fun date -> date |> SetStartDate |> dispatch),
                                  labelName="Von",
                                  maxDate=model.EndDate
                                  )
                              SharedComponents.ReactDatepicker.Datepicker.datepicker(
                                  Some model.EndDate,
                                  (fun date -> date |> SetEndDate |> dispatch),
                                  labelName="Bis",
                                  minDate=model.StartDate
                                  )
                              SharedComponents.Buttons.primaryButtonWithFnct
                                  (fun _ -> RequestReport |> dispatch)
                                  "Auslastung abfragen" "d-flex ml-auto"
                            ]
                        ]
                      ]
                    ]
                ]
            ]
            Html.div [
                match model.ReportResult with
                | Some result ->
                    match result with
                    | ReportResult.AvailableTemplateDateRangeReportResult data ->
                        reactTable [
                            tableProp.keyField "Id"
                            tableProp.defaultSortField ("Name", "asc")
                            tableProp.rows data.AvailablePerEntity
                            tableProp.tableKey "allentities"
                            tableProp.columns [
                                tableColumn [
                                    colProp.selector "EntityName"
                                    colProp.label "Name"
                                    colProp.sortFunction NaturalSort
                                    colProp.filter TextFilter
                                ]
                                tableColumn [
                                    colProp.selector "TotalDays"
                                    colProp.label "Tage gesamt"
                                    colProp.sortFunction (CustomNaturalSort (fun (entity : AvailablePerEntity) -> entity.TotalDays.ToString()))
                                    colProp.filter TextFilter
                                ]
                                tableColumn [
                                    colProp.selector "DaysAvailable"
                                    colProp.label "Tage verfügbar"
                                    colProp.sortFunction (CustomNaturalSort (fun (entity : AvailablePerEntity) -> entity.DaysAvailable.ToString()))
                                    colProp.filter TextFilter
                                ]
                                tableColumn [
                                    colProp.selector "DaysOnExecutionLocation"
                                    colProp.label "Tage auf Baustelle"
                                    colProp.sortFunction (CustomNaturalSort (fun (entity : AvailablePerEntity) -> entity.DaysOnExecutionLocation.ToString()))
                                    colProp.filter TextFilter
                                ]
                                tableColumn [
                                    colProp.selector "DaysNotAvailable"
                                    colProp.label "Tage außer Betrieb"
                                    colProp.sortFunction (CustomNaturalSort (fun (entity : AvailablePerEntity) -> entity.DaysNotAvailable.ToString()))
                                    colProp.filter TextFilter
                                ]
                                tableColumn [
                                    colProp.selector "Aktion"
                                    colProp.label "Aktion"
                                    colProp.formatter (Custom (fun value order -> detailBtn order))
                                    colProp.csvPrint false
                                ]
                            ]
                        ]
                | None -> Html.none
            ]
        ]
    | None -> Html.none
    

let reportProps (model : Model) dispatch isDisabled =
    let options =
        Shared.Reports.reports
        |> List.map (fun r ->
            { SharedComponents.ReactSelect.label = r.Name
              value = r.Id })
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Bericht gefunden werden." ]

    let valueElement =
        match model.SelectedReportNum with
        | Some num ->
            let label =
                match Shared.Reports.reports |> List.tryFind(fun r -> r.Id = num) with
                | Some r -> r.Name
                | None -> ""
            [| SharedComponents.ReactSelect.CommonProps<int>.Value (Some { label = label; value = num }) |]
        | None -> [| SharedComponents.ReactSelect.CommonProps<int>.Value None |]
    Array.append
        [| SharedComponents.ReactSelect.CommonProps<int>.Options options
           SharedComponents.ReactSelect.CommonProps<int>.OnChange (fun e -> dispatch (SelectReport (e)))
           SharedComponents.ReactSelect.CommonProps<int>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<int>.IsClearable true
           SharedComponents.ReactSelect.CommonProps<int>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<int>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<int>.ClassName "template-select flex-grow-1 mb-3"
           SharedComponents.ReactSelect.CommonProps<int>.IsDisabled isDisabled |]
        valueElement

let private overview (model:Model) (dispatch: Msg -> unit) =
    Html.div [
        prop.id "reports-content"
        prop.className "flex-grow-1"
        prop.children [
            mainHeader "Berichte"
            SharedComponents.ReactSelect.selectWithoutLabel (reportProps model dispatch false)

            match model.SelectedReportNum with
            | None -> Html.none
            | Some id ->
                match id with
                | 1 -> report1 model dispatch
                | _ -> Html.text "Kein Bericht "
        ]
    ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "reports-container"
          Class "flex-grow-1 d-flex" ]
        [ overlaySpinner model.ReportFetchState
          overview model dispatch ]