module Client.View


open Fable.React
open Fable.React.Props
open Routes
open Shared
open SharedComponents.Types
open Client.Types
open Feliz

let pageView model dispatch =
    match model.PageModel, model.Route with
    | LoginPageModel m, Page.Login ->
        Login.View.view m (LoginMsg >> dispatch)
    | FatalErrorViewModel m, Page.FatalErrorView ->
        ErrorViews.FatalErrorView.view m (FatalErrorViewMsg >> dispatch)
    | NotLoggedInViewModel m, Page.NotLoggedInErrorView ->
        ErrorViews.NotLoggedInErrorView.view m (NotLoggedInViewMsg >> dispatch)
    | DashboardModel m, Page.Dashboard ->
        Dashboard.View.view m (DashboardMsg >> dispatch)
    | TemplatesModel m, Page.Templates ->
        MasterData.Templates.View.view m (TemplatesMsg >> dispatch)
    | AllEntitiesModel m, Page.AllEntities ->
        MasterData.AllEntities.View.view m (AllEntitiesMsg >> dispatch)
    | EntityModel m, Page.Entity templateId ->
        MasterData.Entities.View.entityView (TemplateId templateId) m (EntityMsg >> dispatch)
    | EntityModel m, Page.DeactivateEntity templateId ->
        MasterData.Entities.View.deactivateEntityView (TemplateId templateId) m (EntityMsg >> dispatch)
    | EntityModel m, Page.EntityNewForm templateId ->
        MasterData.Entities.View.entityFormView templateId m (EntityMsg >> dispatch) FormState.New
    | EntityModel m, Page.EntityViewForm (templateId, entityId) ->
        MasterData.Entities.View.entityFormView templateId m (EntityMsg >> dispatch) FormState.View
    | EntityModel m, Page.EntityCopyForm (templateId, entityId) ->
        MasterData.Entities.View.entityFormView templateId m (EntityMsg >> dispatch) FormState.View
    | MessageCenterModel m, Page.MessageCenter ->
        MessageCenter.View.view m (MessageCenterMsg >> dispatch)
    | ConfigurationModel m, Page.Configuration ->
        Configuration.View.view m (ConfigurationMsg >> dispatch)
    | UserManagementModel m, Page.UserManagement ->
        UserManagement.View.view m (UserManagementMsg >> dispatch)
    | UserManagementModel m, Page.UserManagementNewForm ->
        UserManagement.View.userFormView m (UserManagementMsg >> dispatch)
    | UserManagementModel m, Page.UserManagementViewForm userId ->
        UserManagement.View.userViewFormView m (UserManagementMsg >> dispatch)
    | DispositionOverviewModel m, Page.DispositionOverview ->
        DispositionOverview.View.view m (DispositionOverviewMsg >> dispatch)
    | DispositionModel m, Page.DispostionNewForm _ ->
        Dispositions.View.view m (DispositonMsg >> dispatch)
    | RentOrderDispositionModel m, Page.RentOrderDispositionDetail _ ->
        Dispositions.RentOrderDisposition.View.view m (RentOrderDispositionMsg >> dispatch)
    | OrderModel m, Page.OrderOverview ->
        Order.View.view m (OrderMsg >> dispatch)
    | RentOrderModel m, Page.RentOrderOverview ->
        Order.RentOrder.View.view m (RentOrderMsg >> dispatch)
    | RentOrderModel m, Page.RentOrderOverviewCompleted ->
        Order.RentOrder.View.rentOrderCompletedOverview m (RentOrderMsg >> dispatch)
    | RentOrderModel m, Page.RentOrderDetail orderId ->
        Order.RentOrder.View.rentOrderFormView m (RentOrderMsg >> dispatch)
    | RentOrderModel m, Page.RentOrderNewForm ->
        Order.RentOrder.View.rentOrderFormView m (RentOrderMsg >> dispatch)
    | RentOrderModel m, Page.RentOrderEditForm _ ->
        Order.RentOrder.View.rentOrderFormView m (RentOrderMsg >> dispatch)
    | RepairOrderModel m, Page.RepairOrderOverview ->
        Order.ServiceOrder.View.view m (RepairOrderMsg >> dispatch)
    | RepairOrderModel m, Page.ServiceOrderOverviewCompleted ->
        Order.ServiceOrder.View.viewServiceOrderCompleted m (RepairOrderMsg >> dispatch)
    | RepairOrderModel m, Page.RepairOrderDetail orderId ->
        Order.ServiceOrder.View.detailView orderId m (RepairOrderMsg >> dispatch)
    | RepairOrderModel m, Page.RepairOrderNewForm ->
        Order.ServiceOrder.View.newRepairOrderFormView m (RepairOrderMsg >> dispatch)
    | RepairOrderDispositionModel m, Page.RepairOrderDispositionDetail _ ->
        Dispositions.RepairOrderDisposition.View.view m (RepairOrderDispositionMsg >> dispatch)
    | FreeOrderModel m, Page.FreeOrderOverview ->
        Order.FreeOrder.View.view m (FreeOrderMsg >> dispatch)
    | FreeOrderModel m, Page.FreeOrderOverviewCompleted ->
        Order.FreeOrder.View.viewFreeOrdersCompleted m (FreeOrderMsg >> dispatch)
    | FreeOrderModel m, Page.FreeOrderDetail orderId ->
        Order.FreeOrder.View.detailView orderId m (FreeOrderMsg >> dispatch)
    | FreeOrderModel m, Page.FreeOrderNewForm ->
        Order.FreeOrder.View.newFreeOrderFormView m (FreeOrderMsg >> dispatch)
    | FreeOrderDispositionModel m, Page.FreeOrderDispositionDetail _ ->
        Dispositions.FreeOrderDisposition.View.view m (FreeOrderDispositionMsg >> dispatch)
    | MyTaskModel m, Page.MyTaskOverview _ ->
        MyTask.View.view m (MyTaskMsg >> dispatch)
    | MyTaskModel m, Page.MyTaskViewForm taskId ->
        MyTask.View.myTaskView m (MyTaskMsg >> dispatch)
    | AppointmentModel m, Page.Appointment ->
        Appointment.View.view m (AppointmentMsg >> dispatch)
    | AvailableModel m, Page.Available ->
        Available.View.view m (AvailableMsg >> dispatch)
    | MyProfilModel m, Page.MyProfil ->
        MyProfil.View.view m (MyProfilMsg >> dispatch)
    | SupportModel m, Page.Support ->
        Support.View.view m (SupportMsg >> dispatch)
    | TemplateConfigurationModel m, Page.TemplateConfiguration ->
        TemplateConfiguration.View.view m (TemplateConfigurationMsg >> dispatch)
    | TemplateConfigurationModel m, Page.TemplatesNewForm ->
        TemplateConfiguration.View.newView m (TemplateConfigurationMsg >> dispatch)
    | TemplateConfigurationModel m, Page.TemplatesViewForm templateId ->
        TemplateConfiguration.View.editView m (TemplateConfigurationMsg >> dispatch)
    | TemplateConfigurationModel m, Page.TemplateGroupNewForm ->
        TemplateConfiguration.View.newView m (TemplateConfigurationMsg >> dispatch)
    | TemplateConfigurationModel m, Page.TemplateGroupViewForm templateId ->
        TemplateConfiguration.View.editView m (TemplateConfigurationMsg >> dispatch)
    | MaterialModel m, Page.MaterialOverview ->
        MasterData.Material.View.overview m (MaterialMsg >> dispatch)
    | MaterialModel m, Page.MaterialNewForm ->
        MasterData.Material.View.newView m (MaterialMsg >> dispatch)
    | MaterialModel m, Page.MaterialEditForm _ ->
        MasterData.Material.View.editView m (MaterialMsg >> dispatch)
    | CarrierManagementModel m, Page.CarrierManagement ->
        CarrierManagement.View.overview m (CarrierManagementMsg >> dispatch)
    | CarrierManagementModel m, Page.CarrierManagementNewForm ->
        CarrierManagement.View.newView m (CarrierManagementMsg >> dispatch)
    | CarrierManagementModel m, Page.CarrierManagementViewForm _ ->
        CarrierManagement.View.editView m (CarrierManagementMsg >> dispatch)
    | ReportsModel m, Page.Reports ->
        Reports.View.view m (ReportsMsg >> dispatch)
    | ControlModel m, Page.Control ->
        Control.View.view m (ControlMsg >> dispatch)
    | _, _ -> str "Error Case"

let private isNavigationVisible (page : Page) =
    match page with
    | Page.Login _
    | Page.NotLoggedInErrorView _
    | Page.FatalErrorView -> false
    | _ -> true

let view (model : Model) (dispatch : Msg -> unit) =
    if model.Initialized then
        div [ Id "main-container"
              Class "d-flex flex-column" ]
            [ (if isNavigationVisible model.Route then Navigation.view model dispatch (fun _ -> dispatch Logout) model.Route else Fable.React.Helpers.nothing)
              div [ Id "content"
                    Class "flex-grow-1 d-flex flex-column" ]
                  [ pageView model dispatch ] ]
    else
        Html.div [
            prop.className "d-flex h-100"
            prop.children [
                SharedComponents.Spinners.spinner
            ]
        ]