module Login.View

open Login.Types
open SharedComponents
open SharedComponents.Alerts
open Feliz

let view (model : Model) (dispatch : Msg -> unit) =
    Html.div [
        prop.id "login-container"
        prop.className "flex-grow-1 d-flex"
        prop.children [
            Html.div [
                prop.id "login-form-container"
                prop.className ""
                prop.children [
                    Html.img [
                        prop.id "dispoprofi-logo"
                        prop.className ""
                        prop.src "logo_login.png"
                    ]
                    Html.form [
                        prop.id "login-form"
                        prop.onSubmit (SubmitLogin >> dispatch)
                        prop.children [
                            Html.div [
                                prop.className "form-group"
                                prop.children [
                                    Html.label [
                                        prop.htmlFor "input-username"
                                        prop.className "form-label"
                                        prop.text "Benutzername"
                                    ]
                                    Html.input [
                                        prop.id "input-username"
                                        prop.className "form-control"
                                        prop.custom ("data-cy", "login-username-input")
                                        prop.valueOrDefault model.Username
                                        prop.onChange (SetUsername >> dispatch)
                                        prop.placeholder "Benutzername"
                                    ]
                                ]
                            ]
                            Html.div [
                                prop.className "form-group"
                                prop.children [
                                    Html.label [
                                        prop.htmlFor "input-password"
                                        prop.className "form-label"
                                        prop.text "Passwort"
                                    ]
                                    Html.input [
                                        prop.id "input-password"
                                        prop.className "form-control"
                                        prop.custom ("data-cy", "login-password-input")
                                        prop.valueOrDefault model.Password
                                        prop.onChange (SetPassword >> dispatch)
                                        prop.type' "password"
                                        prop.placeholder "Passwort"
                                    ]
                                ]
                            ]
                            Html.div [
                                prop.id "forgot-password"
                                prop.text "Passwort vergessen?"
                            ]
                            match model.Message with
                            | InvalidLogin msg -> errorAlert msg
                            | NoMessage -> Html.none
                            Html.div [
                                prop.className "controls d-flex"
                                prop.children [
                                    Buttons.formSubmitButton "Anmelden" "ml-auto" "login-submit"
                                ]
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.id "login-image-container"
                prop.className "flex-grow-1"
            ]
        ]
    ]
