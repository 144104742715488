namespace Validation

open System

type ValidationState =
    | NotValid of string
    | Valid

type ValidationKeyValue = {
    Key : string
    Value : ValidationState }

type FormValidation =
    { Validations : ValidationKeyValue list
      FormValid : ValidationState }

module Validate =
    let isInvalid (formValidation : FormValidation option) (key : string) =
        match formValidation with
        | None -> false, ""
        | Some formValidation ->
            match formValidation.FormValid with
            | Valid -> false, ""
            | NotValid _ ->
                if (formValidation.Validations
                    |> List.map (fun f -> f.Key)
                    |> List.contains (key)) then
                   match (formValidation.Validations |> List.find (fun r -> r.Key = key)).Value with
                   | Valid -> false, ""
                   | NotValid t -> true, t
                else false, ""