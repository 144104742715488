module Shared.Reports

open Shared
open System

type Report =
    { Id : int
      Name : string }

type InitialReportData =
    { TemplateNamesAndIds : (string * TemplateId) list }

type AvailableTemplateDateRangeReportDto =
    { TemplateId : TemplateId
      StartDate : DateTime
      EndDate : DateTime }

type ReportDtoTypes =
    | AvailableTemplateDateRangeReport of AvailableTemplateDateRangeReportDto

type AvailablePerEntity =
    { EntityName : string
      EntityId : EntityId
      TemplateId : TemplateId
      DaysNotAvailable : int
      DaysOnExecutionLocation : int
      DaysAvailable : int
      TotalDays : int }

type AvailableTemplateDateRangeReportResult =
    { AvailablePerEntity : AvailablePerEntity list }

type ReportResult =
    | AvailableTemplateDateRangeReportResult of AvailableTemplateDateRangeReportResult

let reports = [
    { Id = 1
      Name = "Auslastung nach Kategorie und Zeitraum" } ]

