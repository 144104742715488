namespace Shared

open System

module ResultCE =
    let ofOption error = function Some s -> Ok s | None -> Error error

    type ResultBuilder() =
        member __.Return(x) = Ok x

        member __.ReturnFrom(m: Result<_, _>) = m

        member __.Bind(m, f) = Result.bind f m
        member __.Bind((m, error): (Option<'T> * 'E), f) = m |> ofOption error |> Result.bind f

        member __.Zero() = None

        member __.Combine(m, f) = Result.bind f m

        member __.Delay(f: unit -> _) = f

        member __.Run(f) = f()

        member __.TryWith(m, h) =
            try __.ReturnFrom(m)
            with e -> h e

        member __.TryFinally(m, compensation) =
            try __.ReturnFrom(m)
            finally compensation()

        member __.Using(res:#IDisposable, body) =
            __.TryFinally(body res, fun () -> match res with null -> () | disp -> disp.Dispose())

        member __.While(guard, f) =
            if not (guard()) then Ok () else
            do f() |> ignore
            __.While(guard, f)

        member __.For(sequence:seq<_>, body) =
            __.Using(sequence.GetEnumerator(), fun enum -> __.While(enum.MoveNext, __.Delay(fun () -> body enum.Current)))

    let result = ResultBuilder ()

[<Measure>] type EUR

[<Measure>] type Cm
[<Measure>] type Meter
[<Measure>] type Piece
[<Measure>] type Kg
[<Measure>] type Litre
[<Measure>] type Seconds
[<Measure>] type Minutes
[<Measure>] type Quantity

type Unit =
    | Piece of float<Piece>
    | Meter of float<Meter>
    | Kg of float<Kg>
    | Litre of float<Litre>

type PriorityLevel =
    | High
    | Medium
    | Low

/// Identifier types start

type AddressId = AddressId of Guid

type ServiceOrderId = ServiceOrderId of Guid

type RentOrderId = RentOrderId of Guid

type AttributeId = AttributeId of Guid

type EntityId = EntityId of Guid

type TemplateId = TemplateId of Guid

type InvoiceId = InvoiceId of Guid

type DocumentId = DocumentId of Guid

type ConfigurationId = ConfigurationId of Guid

type BankingDataId = BankingDataId of Guid

type ContactPersonId = ContactPersonId of Guid

type TaskId = TaskId of Guid

type LocationId = LocationId of Guid

type RentOrderPositionId = RentOrderPositionId of Guid

type MessageId = MessageId of Guid

type AppointmentId = AppointmentId of Guid

type ReminderId = ReminderId of Guid

type MaterialId = MaterialId of Guid

type LabelId = LabelId of Guid

type CarrierId = CarrierId of Guid

type EntityNotAvailableRangeId = EntityNotAvailableRangeId of Guid

type FreeOrderId = FreeOrderId of Guid

type NotificationToken = NotificationToken of string

/// Identifier types end

type Customer =
    | Default
    | Prinzing

type RoleNames =
    | Administrator
    | Driver
    | Dispatcher
    | Mechanic
    | Technician
    | Picker

type Role =
    { Id : int
      Name : RoleNames
      Description : string }

type User =
    { Id : int
      Email : string
      Firstname : string
      Lastname : string
      IsDeleted : bool
      AssignedRoles : Role list }

type UserDeleteResult =
    | Successful
    | CannotDeleteYourself

type Employee =
    { Id : int
      Firstname : string
      Lastname : string
      IsDeleted : bool
      RoleNames : RoleNames list }

type DispositionEntitiesListDto =
    { Id : int
      StartDate : DateTime
      EndDate : DateTime
      EntityIds : Guid list
      LocationId : int
      AssignedUserId : int
      Comment : string }

type Image =
    { Id : DocumentId
      Name : string }

type Document =
    { Id : DocumentId
      Description : string
      Name : string }

type DocumentWithContent =
    { Id : DocumentId
      FileName : string
      Content : string }

type DocumentDescriptionDto =
    { DocumentId : DocumentId
      Description : string }

type EntityWithTemplateDocument =
    { Id : EntityId
      Name : string
      Description : string
      FileContent : Byte [] }

type EntityDocumentDto =
    { DocumentId : DocumentId
      Name : string
      Description : string
      EntityId : EntityId }

type DeliveryDispositionState =
    | PlannedToDeliver
    | StartedToDeliver
    | Delivered

type PositionNumber = PositionNumber of int

type ReturnDispositionState =
    | PlannedToReturn
    | StartedToReturn
    | Returned

type RentOrderState =
    | PlacedForPick of DateTime * DateTime
    | WaitForPick of DateTime * DateTime
    | Placed
    | InProgress
    | Completed

type RentOrderPositionState =
    | Planned
    | AssignedToDeliver
    | Delivered
    | Released
    | AssignedToReturn
    | Returned

type RentOrderPositionV1 =
    { PosNo : PositionNumber
      SubPosNo : PositionNumber option
      Id : RentOrderPositionId
      EntityId : EntityId
      DeliveryDate : DateTime
      ReturnDate : DateTime
      ReleaseDate : DateTime option
      State : RentOrderPositionState }

type RentOrderPositionV2 =
    { PosNo : PositionNumber
      SubPosNo : PositionNumber option
      Id : RentOrderPositionId
      EntityId : EntityId
      PlannedDeliveryDate : DateTime
      DeliveryDate : DateTime option
      PlannedReturnDate : DateTime
      ReturnDate : DateTime option
      ReleaseDate : DateTime option
      State : RentOrderPositionState }

type RentOrderPositionV3 =
    { PosNo : PositionNumber
      SubPosNo : PositionNumber option
      Id : RentOrderPositionId
      EntityId : EntityId
      PlannedDeliveryDate : DateTime
      DeliveryDate : DateTime option
      PlannedReturnDate : DateTime
      ReturnDate : DateTime option
      ReleaseDate : DateTime option
      Comment : string
      State : RentOrderPositionState }

type RentOrderEntityPosition =
    { PosNo : PositionNumber
      SubPosNo : PositionNumber option
      Id : RentOrderPositionId
      EntityId : EntityId
      PlannedDeliveryDate : DateTime
      DeliveryDate : DateTime option
      PlannedReturnDate : DateTime
      ReturnDate : DateTime option
      ReleaseDate : DateTime option
      Comment : string
      SelfserviceDelivery : bool
      SelfserviceReturn : bool
      State : RentOrderPositionState }

type RentOrderMaterialPosition =
    { Id : RentOrderPositionId
      PosNo : PositionNumber
      SubPosNo : PositionNumber option
      MaterialId : MaterialId
      Quantity : float
      PlannedDeliveryDate : DateTime
      DeliveryDate : DateTime option
      PlannedReturnDate : DateTime
      ReturnDate : DateTime option
      ReleaseDate : DateTime option
      Comment : string
      SelfserviceDelivery : bool
      SelfserviceReturn : bool
      State : RentOrderPositionState }

type RentOrderPosition =
    | RentOrderEntityPosition of RentOrderEntityPosition
    | RentOrderMaterialPosition of RentOrderMaterialPosition

type SetReturnStateToRentOrderPositionDto =
    { PositionIds : RentOrderPositionId list
      ReleaseDate : DateTime
      RentOrderId : RentOrderId
      LocationId : LocationId option }

type RevertReleaseRentOrderPositionDto =
    { PositionIds : RentOrderPositionId list
      RentOrderId : RentOrderId }

type DeleteRentOrderPositionsDto =
    { RentOrderId : RentOrderId
      PositionIds : RentOrderPositionId list }

type SelfServiceType =
    | Delivery
    | Return

type SelfServiceRentOrderPositionDto =
    { RentOrderId : RentOrderId
      PositionId : RentOrderPositionId
      SelfServiceType : SelfServiceType }

type TemporaryFile = {
    Name : string
    Content : byte [] }

type Login =
    { Username : string
      Password : string }

type LoginWithHash =
    { Id : int
      Email : string
      Firstname : string
      Lastname : string
      PasswordHash : string
      IsDeleted : bool
      Salt : string }

type JWT = string

type UserData =
  { UserName : string
    DisplayName : string
    UserId : int
    AssignedRoles : RoleNames list
    Token : JWT
    CompanyName : string
    FirebaseToken : string }

type NewUserDto =
    { User : User
      Password : string
      SecondPassword : string
      Roles : Role list }

type UserUpdateBaseInformation =
    { Email : string
      Firstname : string
      Lastname : string }

type UserUpdate =
    | Password of string
    | Roles of Role list
    | BaseInformation of UserUpdateBaseInformation

type PasswordResetDto =
    { UserId : int
      Password : string }

type ChangePasswordFromMobileDeviceDto =
    { NewPassword : string }
    
type MyProfileUpdate =
    | Password of string

/// Response Types

type RolesResponse =
    { Roles : Role list }

type UsersResponse =
    { Users : User list }

type EmployeesResponse =
    { Employees : Employee list }

type DriversResponse =
    { Drivers : Employee list }

type TechniciansResponse =
    { Technicians : Employee list }

type MechanicsResponse =
    { Mechanics : Employee list }

type PickersResponse =
    { Pickers : Employee list }

type EntityDocumentDtoResponse =
    { EntityDocumentDto : EntityDocumentDto list }

type EntityDocumentResponse =
    { EntityDocument : Document }

type UserCreateResponseResult =
    | UserWithEmailExists
    | Created of int

type EmptyResponse =
    | Empty

type EntityAvailableErrorType =
    | StartDateGreaterThanEndDate of EntityId
    | EntityIsNotAvailable of EntityId

type GetEntityAvailableResult =
    | Ok of EntityId
    | Error of EntityAvailableErrorType

type PostResponse<'O> =
    { Result : 'O }

type GetEntityAvailableResponse =
    { Result : GetEntityAvailableResult }

type UserNotifictionToken =
    { UserId : int
      NotificationToken : NotificationToken }
      
/// role validation
module RoleValidation =
    let containsRoles assignedRoles neededRoles =
        assignedRoles |> List.contains neededRoles

module Date =
    let dateRange (startDate : System.DateTime) (endDate : System.DateTime) =
        Seq.unfold (fun d -> if d < endDate then Some(d, d.AddDays(1.0)) else None) startDate
        |> Seq.toList

    let between (startDate : DateTime) (endDate : DateTime) (testDate : DateTime) =
        testDate >= startDate && testDate <= endDate

    let doRangesOverlap (startDate : DateTime) (endDate : DateTime) (testStartDate : DateTime) (testEndDate : DateTime) =
        if startDate = endDate then
            testStartDate <= startDate && startDate <= testEndDate
        else
            (testStartDate >= startDate && testStartDate <= endDate) || (testEndDate >= startDate && testEndDate <= endDate) ||
            (testStartDate <= startDate && testStartDate <= endDate && testEndDate >= endDate && testEndDate >= startDate)

module Helper =
    let unwrapRentOrderId (RentOrderId r) = r.ToString()
    let unwrapServiceOrderId (ServiceOrderId s) = s.ToString()
    let unwrapTaskId (TaskId t) = t.ToString()
    let unwrapTemplateId (TemplateId e) = e.ToString()
    let unwrapEntityId (EntityId e) = e.ToString()
    let unwrapMaterialId (MaterialId m) = m.ToString()
    let unwrapMessageId (MessageId m) = m.ToString()
    let unwrapCarrierId (CarrierId c) = c.ToString()
    let unwrapPositionId (RentOrderPositionId p) = p.ToString()
    let unwrapFreeOrderId (FreeOrderId c) = c.ToString()
    let unwrapPositionNumber (PositionNumber n) = n
    let unwrapDocumentId (DocumentId p) = p.ToString()
    let unwrapNotificationToken (NotificationToken notificationToken) = notificationToken

    let floatValueToString floatValue =
        if floatValue = 0. then "0,0"
        else
          let floatStringValue = floatValue.ToString().Replace(".", ",")
          if floatStringValue.Contains(",") then floatStringValue
          else floatStringValue + ",0"

    let parseCustomer = function
        | "baustrom-prinzing" -> Customer.Prinzing
        | "konstruktion-prinzing" -> Customer.Prinzing
        | _ -> Customer.Default
