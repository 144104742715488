module ErrorViews.NotLoggedInErrorView

open Fable.React
open Fable.React.Props
open Elmish
open SharedComponents.Jumbotron

type Model = { Loading : bool }

type Msg =
    | Logout
    | FetchError of exn

let init () : Model * Cmd<Msg> =
    let initialModel = { Loading = false }
    initialModel, Cmd.ofMsg Logout

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | Logout ->
        model, Cmd.none
    | FetchError e ->
        model, Cmd.none

let content model dispatch =
    let className = "error-jumbotron"
    let headerTxt = "Du wurdest automatisch abgemeldet."
    let leadText = "Deine Sitzung ist abgelaufen daher wurdest du abgemeldet."
    let descText = "Wechsel zur Anmeldeseite und melde dich mit deinen Benutzerdaten an."
    let route = "/"
    let btnText = "zur Anmeldung"
    div [ Id "errorview-content"]
        [ errorJumbotron className headerTxt leadText descText route btnText ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "errorview-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ content model dispatch  ]