module SharedComponents.Spinners

open Feliz

type RequestState =
    | Active
    | NotActive

let overlaySpinner (state : RequestState) =
    match state with
    | Active ->
        Html.div [
            prop.className "d-flex justify-content-center overlay-spinner"
            prop.children [
              Html.div [
                prop.className "spinner-grow"
                prop.role "status"
                prop.children [
                  Html.span [
                    prop.className "sr-only"
                    prop.text "Loading ..."
                  ]
                ]
              ]
            ]
        ]
    | NotActive -> Html.none

let spinner =
    Html.div [
      prop.className "spinner-grow .spinner ml-auto mr-auto mt-auto mb-auto"
      prop.role "status"
      prop.children [
        Html.span [
          prop.className "sr-only"
          prop.text "Loading ..."
        ]
      ]
    ]