module MessageCenter.View

open Fable.React
open Fable.React.Props
open MessageCenter.Types
open Shared
open SharedComponents.Headers
open Shared.Message
open Shared.RentOrder
open SharedComponents.Form
open SharedComponents.Badges
open SharedComponents.Spinners

let private messageText message =
    match message.MessageType with
    | MessageType.RentOrderPositionReturnMessage (rentOrderId, positions) ->
        let posString =
            let positionsToReduce =
                positions
                |> List.map (fun p ->
                    let (PositionNumber posNo) = p |> Helper.Position.posNo
                    let subPos = p |> Helper.Position.subPosNo
                    match subPos with
                    | Some subPos ->
                      let (PositionNumber subPos) = subPos
                      sprintf "%s.%s" (posNo.ToString()) (subPos.ToString())
                    | None -> posNo.ToString())
            if positionsToReduce.IsEmpty then "" else
                positionsToReduce
                |> List.reduce (fun combined element -> sprintf "%s, %s" combined element)
        let (RentOrderId rentOrderGuid) = rentOrderId
        div [ ]
          [ str message.Text
            p []
              [ str (sprintf "Betroffene Positionsnummern: %s" posString) ]
            a [ Href (Routes.toPath (Routes.Page.RentOrderDetail rentOrderGuid)) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Auftrag anzeigen" ] ] ]
    | MessageType.EntityAttributeReminderMessage (entityId, templateId, attributes) ->
        let attributeDisplayString =
            let attributeNames =
                attributes |> List.map (fun e -> e.Name)
            if attributeNames.IsEmpty then ""
            else attributeNames |> List.reduce (fun combined element -> sprintf "%s, %s" combined element)
        let (EntityId entityGuid) = entityId
        let (TemplateId templateGuid) = templateId
        div [ ]
          [ str message.Text
            p []
              [ str (sprintf "%s" attributeDisplayString) ]
            a [ Href (Routes.toPath (Routes.Page.EntityViewForm (templateGuid, entityGuid))) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Gerät anzeigen" ] ] ]
    | MessageType.RentOrderReminder (rentOrderId, reminder) ->
        let (RentOrderId rentOrderGuid) = rentOrderId
        div [ ]
          [ str message.Text
            p []
              [ str (sprintf "Erinnerung für: %s" reminder.Description) ]
            a [ Href (Routes.toPath (Routes.Page.RentOrderDetail rentOrderGuid)) ]
                [ i [ Class ("fas fa-eye" ) ] [ ]
                  span [ Class "url-text" ] [ str "Auftrag anzeigen" ] ] ]

let private messageRow model dispatch (message : Message)  =
    let envelopeName = if message.MarkAsRead then "fa-envelope-open-text" else "fa-envelope"
    let statusText, statusLabel, toolTipText =
        if message.MarkAsRead then
            "gelesen", BadgeClassType.Secondary, "Als ungelesen markieren"
        else "ungelesen", BadgeClassType.Primary, "Als gelesen markieren"
    tr [ Key (string message.Id) ]
        [ td [ ]
            [ str message.Headline ]
          td [ ]
            [ messageText message ]
          td [ ]
            [ str (message.CreatedAt.ToShortDateString()) ]
          td [ ]
            [ badgePill statusText statusLabel ]
          td [ ]
            [ span [ Class "d-inline-block mr-2"
                     TabIndex 0
                     DataToggle "tooltip"
                     Title toolTipText ]
                  [ a [ OnClick (fun _ -> message.Id |> MarkAsRead |> dispatch) ]
                     [ i [ Class ("fas " + envelopeName) ] [ ] ] ]
              span [ Class "d-inline-block ml-2"
                     TabIndex 0
                     DataToggle "tooltip"
                     Title "Nachricht löschen" ]
                  [ a [ OnClick (fun _ -> message.Id |> DeleteMessage |> dispatch) ]
                     [ i [ Class ("fas fa-trash") ] [ ] ] ] ] ]

let private messageTable messages (model : Model) dispatch =
    div [ Class "table-responsive" ]
      [ table [ Class "table" ]
          [ thead [ ]
              [ tr [ ]
                  [ th [ ]
                      [ str "Bezeichnung" ]
                    th [ ]
                      [ str "Beschreibung" ]
                    th [ ]
                      [ str "Datum"]
                    th [ ]
                      [ str "Status"]
                    th [ ]
                      [ str "Controls "] ] ]
            tbody [ ]
              [ messages
                |> List.map (messageRow model dispatch)
                |> ofList  ] ] ]

let private messageCenterContent (model : Model) dispatch =
    div [ Id "message-center-content"
          Class "d-flex flex-column" ]
        [ mainHeader "Nachrichten"

          subHeaderForm "Ungelesene Nachrichten"
          messageTable model.UnreadMessages model dispatch

          subHeaderForm "Gelesene Nachrichten"
          messageTable model.ReadMessages model dispatch ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ ]
      [ overlaySpinner model.RequestState
        div [ Id "message-center-container"
              Class "flex-grow-1 d-flex flex-column" ]
          [ messageCenterContent model dispatch ] ]
