module Order.RentOrder.FormValidation

open Order.RentOrder.Helper
open Order.RentOrder.Types
open Shared
open Validation
open Shared.Entity
open Shared.RentOrder
open SharedComponents.Alerts
open PositionTypes

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let valid =
    { Validations = []
      FormValid = Valid }

let private create key value : ValidationKeyValue =
    { Key = key
      Value = value }

let private validateCity city =
    if city = "" then
        create "city" (ValidationState.NotValid "Stadt muss angegeben sein.")
    else create "city" ValidationState.Valid

let private validateStreet street =
    if street = "" then
        create "street" (ValidationState.NotValid "Straße muss angegeben sein.")
    else create "street" ValidationState.Valid

let private validatePositions (positions : RentOrderPosition list) =
    let positions =
        positions
        |> List.filter (function
            | RentOrderEntityPosition pos -> pos.EntityId = EntityId (System.Guid.Empty)
            | RentOrderMaterialPosition pos -> pos.MaterialId = (System.Guid.Empty |> MaterialId))
    if positions.IsEmpty then
        create "positions" ValidationState.Valid
    else
        create "positions" (ValidationState.NotValid "Positionen müssen angelegt sein und valide sein.")

let private validateMaterialPositionsForNewForm (positions : RentOrderPosition list) =
    let positions =
        positions
        |> List.choose (function
            | RentOrderEntityPosition pos -> None
            | RentOrderMaterialPosition pos ->
                if pos.MaterialId = (System.Guid.Empty |> MaterialId)
                then Some pos
                else None)
    if positions.IsEmpty then
        create "positions" ValidationState.Valid
    else
        create "positions" (ValidationState.NotValid "Positionen müssen angelegt sein und valide sein.")


let private validateMaterialPositions (positions : PositionRow list) =
    let posNoToString posNo =
        let (PositionNumber posNo) = posNo
        posNo.ToString ()
    let validate value posNoString =
        if value.Parsed.IsSome
        then create (posNoString + "_qty") ValidationState.Valid
        else create (posNoString + "_qty") (ValidationState.NotValid "Menge eingeben")
    positions
    |> List.choose (function
        | NewMaterialPosition (pos, value) ->
            validate value (pos.PosNo |> Helper.unwrapPositionNumber |> string) |> Some
        | EditMaterialPosition (pos, value) ->
            validate value (pos.PosNo |> Helper.unwrapPositionNumber |> string) |> Some
        | _ -> None)

let formValid (newValidations : ValidationKeyValue list) =
    let isNotValid =
        newValidations
        |> List.exists (fun validation ->
            match validation.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    if isNotValid then ValidationState.NotValid "" else ValidationState.Valid

let validateNewForm (rentOrderToValid : RentOrderNewForm) : FormValidation =
    let rentOrder = rentOrderToValid.RentOrder
    let city _ = validateCity rentOrderToValid.RentOrder.ExecutionLocation.City
    let street _ = validateStreet rentOrderToValid.RentOrder.ExecutionLocation.Street
    let positions _ = validateMaterialPositionsForNewForm rentOrderToValid.RentOrder.Positions
    // let materialPositionValidations _ = validateMaterialPositions rentOrderToValid.MaterialPositionValues
    let newValidations = [ [street ()]; [city (); ]; [ positions()] ] |> List.collect id
    { Validations = newValidations
      FormValid = formValid newValidations }

let validateEditPositionsForm (positions : PositionRow list) : FormValidation =
    // let rentOrder = rentOrderToValid.RentOrder
    let selectedValidation _ = validatePositions (positions |> List.map Row.toRentOrderPosition) // rentOrderToValid.PickEntities
    let materialPositionValidations _ = validateMaterialPositions positions
    let newValidations = [ [selectedValidation ()]; materialPositionValidations() ] |> List.collect id
    { Validations = newValidations
      FormValid = formValid newValidations }

let createPosNotAvailableValidation (positions : RentOrderPosition list) : ValidationKeyValue list =
    let createPosName pos =
        let posNo = pos |> Helper.Position.posNo |> Helper.unwrapPositionNumber
        let subPosNo = pos |> Helper.Position.subPosNo |> Option.map Helper.unwrapPositionNumber |> Option.defaultValue 0
        sprintf "pos_%i_%i" posNo subPosNo
    positions
    |> List.map (createPosName
        >> (fun s ->
        create s (ValidationState.NotValid "Positionen müssen angelegt sein und valide sein.")))
    // if positions.IsEmpty then
    //     create "positions" ValidationState.Valid
    // else
    //     create "positions" (ValidationState.NotValid "Positionen müssen angelegt sein und valide sein.")
    // let rentOrder = rentOrderToValid.RentOrder
    // let city _ = validateCity rentOrderToValid.RentOrder.ExecutionLocation.City
    // let street _ = validateStreet rentOrderToValid.RentOrder.ExecutionLocation.Street
    // // let materialPositionValidations _ = validateMaterialPositions rentOrderToValid.MaterialPositionValues
    // let newValidations = [ [street ()]; [city ()] ] |> List.collect id
    // { Validations = newValidations
    //   FormValid = formValid newValidations }

let validateEditAddressForm (rentOrderToValid : RentOrderForm) : FormValidation =
    let city _ = validateCity rentOrderToValid.RentOrder.ExecutionLocation.City
    let street _ = validateStreet rentOrderToValid.RentOrder.ExecutionLocation.Street
    let newValidations = [street (); city ();]
    { Validations = newValidations
      FormValid = formValid newValidations }

let validationWarning (currentFormValidation : FormValidation option) (key : string) =
    match currentFormValidation with
    | None -> Fable.React.Helpers.nothing
    | Some validation ->
        match validation.Validations |> List.tryFind(fun t -> t.Key = key) with
        | Some value ->
            if value.Value = ValidationState.Valid then
                Fable.React.Helpers.nothing
            else
                match value.Value with
                | NotValid msg ->
                    SharedComponents.Alerts.getAlert (buildMessage MessageType.Warning msg |> Some)
                | Valid -> Fable.React.Helpers.nothing
        | None -> Fable.React.Helpers.nothing