module Dashboard.State

open Elmish
open Shared
open Dashboard.Types
open Shared.CardFlag
open Shared.Entity
open Shared.Dashboard
open Shared.Configuration
open SharedComponents.Spinners

let getRentOrderCardFlag =
    Cmd.OfPromise.either Communication.getRequest<CardFlag list> "/api/cardflag/rentorder" RentOrderCardFlagFetched FetchError

let getEntityCardFlag =
    Cmd.OfPromise.either Communication.getRequest<CardFlag list> "/api/cardflag/entity" EntityCardFlagFetched FetchError

let getTemplateCardFlag templateId =
    let (TemplateId templateId) = templateId
    Cmd.OfPromise.either Communication.getRequest<CardFlag list> (sprintf "/api/cardflag/template/%s" (templateId.ToString())) TemplateCardFlagFetched FetchError

let getDashboardInfoCmd =
    Cmd.OfPromise.either Communication.getRequest<DashboardInfoDto> "/api/dashboard/data" DashboardInfoFetched FetchError

let getRentOrderCmd (rentOrderId : RentOrderId) =
    let (RentOrderId id) = rentOrderId
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/rentorders/%s" (id.ToString())) RentOrderFetched FetchError

let isAdminOrderDispatcher (userData : UserData) =
    userData.AssignedRoles
    |> List.exists (fun r -> r = Administrator || r = Dispatcher)

let isTechnician (userData : UserData) =
    userData.AssignedRoles
    |> List.exists (fun r -> r = Technician)

let init user : Model * Cmd<Msg> =
    let userInformation =
        { UserData = user
          IsTechnician = isTechnician user
          IsAdminOrDispatcher = isAdminOrderDispatcher user }
    let initialModel = { DashboardInfo = 
                            { EntitiesWorkload =
                                { EntitiesInStock = 0
                                  EntitiesOnExecutionLocation = 0
                                  EntitiesOnRoad = 0 }
                              ShortAppointmentInfo = []
                              UnreadedMessages = 0
                              Entities = []
                              Configuration = None
                              Templates = [ ] }
                         SelectedObjectType = ObjectType.RentOrder
                         UnreadMessages = 0
                         CardFlag = []
                         UserInformation = userInformation
                         SelectedRentOrder = None
                         SelectedEntities = None }
    initialModel, Cmd.batch [ getDashboardInfoCmd; getRentOrderCardFlag ]

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    | ChangeObjectTypeSelection objectType ->
        let newCmd =
            match objectType with
            | None -> Cmd.none
            | Some oType ->
                match oType.value with
                | ObjectType.Entity -> getEntityCardFlag
                | ObjectType.RentOrder -> getRentOrderCardFlag
                | ObjectType.Template templateId -> getTemplateCardFlag templateId
        model, newCmd
    | SelectEntities entityIds ->
        let selectedEntities =
            Some (model.DashboardInfo.Entities |> List.filter (fun e -> entityIds |> List.contains e.Id))
        { model with SelectedEntities = selectedEntities
                     SelectedRentOrder = None }, Cmd.none
    | DashboardInfoFetched response ->
        { model with DashboardInfo = response }, Cmd.none
    | FetchRentOrder rentOrderId ->
        model, getRentOrderCmd rentOrderId
    | RentOrderCardFlagFetched response ->
        { model with CardFlag = response
                     SelectedObjectType = ObjectType.RentOrder
                     SelectedRentOrder = None
                     SelectedEntities = None },  Cmd.none
    | EntityCardFlagFetched response ->
        { model with CardFlag = response
                     SelectedObjectType = ObjectType.Entity
                     SelectedRentOrder = None
                     SelectedEntities = None }, Cmd.none
    | TemplateCardFlagFetched response ->
        match response.Head.CardFlagType with
        | Template (entities, templateId) ->
            { model with CardFlag = response
                         SelectedObjectType = ObjectType.Template templateId
                         SelectedRentOrder = None
                         SelectedEntities = None }, Cmd.none
        | CardFlagType.RentOrder(_, _) -> model, Cmd.none
        | CardFlagType.Entity(_) -> model, Cmd.none
    | RentOrderFetched rentOrder ->
        { model with SelectedRentOrder = Some rentOrder
                     SelectedEntities = None }, Cmd.none
    | FetchError e ->
        model, ErrorHandling.handleFetchError e