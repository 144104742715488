module MasterData.Material.State


open Elmish
open MasterData.Material.Types
open Elmish.Navigation
open Shared
open Client
open System
open Shared.Material
open Validation
open Shared.Configuration
open SharedComponents.Toast
open Routes
open SharedComponents.Spinners
open Shared.Helper

let getMaterialsCmd =
    Commands.getMaterialsCmd MaterialsFetched FetchError

let getMaterialCmd materialId =
    Commands.getMaterialCmd materialId MaterialFetched FetchError

let createMaterialCmd (material : Material) =
    let body = Thoth.Json.Encode.Auto.toString (0, material)
    Cmd.OfPromise.either Communication.postRequest<PostResponse<MaterialId>> ("/api/materials", body) MaterialCreated FetchError

let updateMaterialCmd (material : Material) =
    let (MaterialId materialId) = material.Id
    let guid = materialId.ToString()
    let body = Thoth.Json.Encode.Auto.toString (0, material)
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>> ((sprintf "/api/materials/%s" guid), body) MaterialUpdated FetchError

let deleteMaterialCmd (materialId : MaterialId) =
    let (MaterialId materialId) = materialId
    let guid = materialId.ToString()
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<MaterialId>> (sprintf "/api/materials/%s" guid) MaterialDeleted FetchError

let validateForm (material : Material) : FormValidation =
    let create key value : ValidationKeyValue =
        { Key = key
          Value = value }

    let nameValidation _ =
        if not (String.IsNullOrWhiteSpace material.Name) then
            create "material-name" ValidationState.Valid
        else create "material-name" (ValidationState.NotValid "Ein Name muss vergeben sein.")

    let validations = [ nameValidation () ]
    let formValid =
        let isValid =
            validations
            |> List.map (fun r -> r.Value)
            |> List.filter (fun value -> match value with
                                         | ValidationState.Valid -> false
                                         | ValidationState.NotValid _ -> true)
            |> List.isEmpty
        if isValid then ValidationState.Valid else ValidationState.NotValid ""
    { Validations = validations
      FormValid = formValid }

let initialModel (userData : UserData) : Model =
    { Materials = []
      Form = Loading
      DeleteRequested = None
      RequestState = RequestState.Active
      UserData = userData }

let initOverview userData : Model * Cmd<Msg> =
    initialModel userData, getMaterialsCmd

let initNewView (userData : UserData) : Model * Cmd<Msg> =
    let form =
        { MaterialNewForm.Material = Helper.emptyMaterial (Shared.Helper.parseCustomer userData.CompanyName)
          FormValidation = None
          RentPrice =
            { Raw = ""
              Parsed = None } }
    { initialModel userData with Form = form |> MaterialForm.New
                                 RequestState = RequestState.NotActive
                                 UserData = userData }, Cmd.batch [ ]

let initEditView materialId userData : Model * Cmd<Msg> =
    let materialId = materialId |> MaterialId
    initialModel userData, Cmd.batch [ getMaterialCmd materialId ]

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | SetName name ->
        let form =
            match model.Form with
            | MaterialForm.Loading -> model.Form
            | New form ->
                { form with Material = { form.Material with Name = name } }
                |> MaterialForm.New
            | Edit form ->
                { form with Material = { form.Material with Name = name } }
                |> MaterialForm.Edit
        { model with Form = form }, Cmd.none
    | SetPrinzingRentPrice rentPrice ->
        let parsedRentPrice =
            try rentPrice.Replace(",", ".") |> float |> Some
            with | _ -> None
        let rentPriceType =
            { Raw = rentPrice
              Parsed = parsedRentPrice }
        let form =
            match model.Form with
            | MaterialForm.Loading -> model.Form
            | New form ->
                let customerSpecific =
                    match form.Material.CustomerSpecific with
                    | CustomerSpecificMaterial.Prinzing specific ->
                        { specific with RentPrice = parsedRentPrice }
                        |> CustomerSpecificMaterial.Prinzing
                    | _ ->
                        { RentPrice = parsedRentPrice }
                        |> CustomerSpecificMaterial.Prinzing
                { form with Material = { form.Material with CustomerSpecific = customerSpecific }
                            RentPrice = rentPriceType }
                |> MaterialForm.New
            | Edit form ->
                let customerSpecific =
                    match form.Material.CustomerSpecific with
                    | CustomerSpecificMaterial.Prinzing specific ->
                        { specific with RentPrice = parsedRentPrice }
                        |> CustomerSpecificMaterial.Prinzing
                    | _ ->
                        { RentPrice = parsedRentPrice }
                        |> CustomerSpecificMaterial.Prinzing
                { form with Material = { form.Material with CustomerSpecific = customerSpecific }
                            RentPrice = rentPriceType }
                |> MaterialForm.Edit
        { model with Form = form }, Cmd.none
    | SetUnit unit ->
        match unit with
        | Some unit ->
            let form =
                match model.Form with
                | MaterialForm.Loading -> model.Form
                | New form ->
                    { form with Material = { form.Material with Unit = unit.value } }
                    |> MaterialForm.New
                | Edit form ->
                    { form with Material = { form.Material with Unit = unit.value } }
                    |> MaterialForm.Edit
            { model with Form = form }, Cmd.none
        | None ->
            model, Cmd.none

    | SaveMaterial ->
        match model.Form with
        | Loading -> model, Cmd.none
        | New form ->
            let validation = form.Material |> validateForm
            match validation.FormValid with
            | ValidationState.Valid ->
                { model with RequestState = RequestState.Active }, createMaterialCmd form.Material
            | NotValid _ ->
                { model with Form = { form with FormValidation = Some validation } |> MaterialForm.New }, Cmd.none
        | Edit form ->
            let validation = form.Material |> validateForm
            match validation.FormValid with
            | ValidationState.Valid ->
                { model with RequestState = RequestState.Active }, updateMaterialCmd form.Material
            | NotValid _ ->
                { model with Form = { form with FormValidation = Some validation } |> MaterialForm.Edit }, Cmd.none

    | EditMaterial ->
        match model.Form with
        | Loading
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = EditField.EditName } |> MaterialForm.Edit }, Cmd.none
    | AbortEdit ->
        match model.Form with
        | Loading
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = Nothing
                                            Material = form.MaterialSnapshot } |> MaterialForm.Edit }, Cmd.none

    /// Requests
    | MaterialsFetched response ->
        { model with Materials = response.Materials |> List.filter(fun m -> m.IsDeleted |> not)
                     RequestState = RequestState.NotActive }, Cmd.none
    | MaterialFetched material ->
        match model.Form with
        | Loading ->
            let rentPrice =
                match material.CustomerSpecific with
                | CustomerSpecificMaterial.Default ->
                    { Raw = ""
                      Parsed = None }
                | CustomerSpecificMaterial.Prinzing f ->
                    { Raw = f.RentPrice.ToString().Replace(".", ",") 
                      Parsed = f.RentPrice }

            let form =
                { Material = material
                  MaterialSnapshot = material
                  EditField = EditField.Nothing
                  FormValidation = None
                  RentPrice = rentPrice }
            { model with Form = form |> MaterialForm.Edit
                         RequestState = RequestState.NotActive }, Cmd.none
        | New _
        | Edit _ ->
            model, Cmd.none
    | MaterialCreated materialId ->
        let (MaterialId materialId) = materialId.Result
        { model with RequestState = RequestState.NotActive},
        Cmd.batch [
            materialId |> Page.MaterialEditForm |> Routes.toPath |> Navigation.newUrl
            toast (ToastType.Success "Material erfolgreich angelegt.")
        ]
    | MaterialUpdated _ ->
        match model.Form with
        | Loading -> model, Cmd.none
        | New _ -> model, Cmd.none
        | Edit form ->
            { model with Form = { form with EditField = EditField.Nothing } |> MaterialForm.Edit
                         RequestState = RequestState.NotActive },
            Cmd.batch [
                //updateMaterialCmd
                toast (ToastType.Success "Material erfolgreich bearbeitet.")
            ]
    // Delete actions
    | AbortDelete ->
        { model with DeleteRequested = None }, Cmd.none
    | DeleteRequest ->
        let newModel, newCmd =
            match model.Form with
            | New form -> model, Cmd.none
            | Edit form ->
                { model with RequestState = RequestState.Active },
                deleteMaterialCmd form.Material.Id
            | Loading -> model, Cmd.none
        newModel, newCmd
    | OpenDeleteModal ->
        let model =
            match model.Form with
            | Loading -> model
            | New form -> model
            | Edit form -> { model with DeleteRequested = Some form.Material.Id }
        model, Cmd.none
    | MaterialDeleted response ->
        { model with DeleteRequested = None },  Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.MaterialOverview))
                                                            toast (ToastType.Info "Material wurde erfolgreich gelöscht.")]
        
    | FetchError e ->
        printfn "Failed"
        model, Cmd.none

