module MyTask.View

open SharedComponents.ReactGoogleMap
open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open MyTask.Types
open System
open Shared.Address
open Shared.TaskTypes
open Shared.TaskTypes.Helper

// let private taskComment task =
//     match task with
//     | MyRentOrderTask rentOrderTask -> rentOrderTask.Comment
//     | MyServiceOrderTask repairOrderTask -> repairOrderTask.Comment
//     | MyPickTask pickTask -> pickTask.Comment

// let private taskName task =
//     match task with
//     | MyRentOrderTask rentOrderTask -> rentOrderTask.Name
//     | MyServiceOrderTask repairOrderTask -> repairOrderTask.Name
//     | MyPickTask pickTask -> pickTask.Comment

// let private taskLocation task =
//     match task with
//     | MyRentOrderTask rentOrderTask -> rentOrderTask.Location
//     | MyServiceOrderTask repairOrderTask -> repairOrderTask.Location
//     | MyPickTask pickTask -> emptyAddress

// let private isCurrentDateGreaterThanToday (currentDate : DateTime) =
//     currentDate > System.DateTime.Today

// let private detailView (model : Model) (dispatch : Msg -> unit) =
//     match model.SelectedMyTask with
//     | Some myTask ->
//       div [ Class "d-flex flex-column" ]
//           [ mainHeader "Aufgabe"
//             //locationDetailHeader myTask.Location "mytask-details" dispatch

//             subHeaderForm "Informationen"
//             div [ Class "controls d-flex" ]
//               [ str (taskComment myTask) ]

//             subHeaderForm "Geräte"
//             div [ Class "controls d-flex" ]
//               [ ]

//             table [ Class "table" ]
//               [ thead [ ]
//                   [ tr [ ]
//                       [ th [ ]
//                           [ str "Gerät"]
//                         th [ ]
//                           [ str "Bemerkung "] ] ]
//                 tbody [ ]
//                   [ ] ] ]
//     | None -> div [ ] [ ]

// let getMapMarkers coordinates =
//     (marker (markerElement "maps-marker-entities")
//                                { lat = coordinates.Latitude
//                                  lng = coordinates.Longitude
//                                  text = "e.Name" })

// let locationCenter (coordinates : Coordinates option) =
//     let lats =
//           match coordinates with
//           | None -> 0.0
//           | Some a -> if a.Longitude <> 0.0 then a.Latitude else 0.0
//     let lngs =
//           match coordinates with
//           | None -> 0.0
//           | Some a -> if a.Longitude <> 0.0 then a.Longitude else 0.0
//     let centerLat, centerLng =
//         calculateCenter [lats] [lngs]
//     (centerLat, centerLng, [lats], [lngs], 9)

// let private cardMap (location : Address) (model : Model) dispatch =
//     let centerLat, centerLng, lats, lngs, zoom = locationCenter location.Coordinates
//     let coordinates =
//         match location.Coordinates with
//         | Some c ->
//             { Latitude = c.Latitude
//               Longitude = c.Longitude }
//         | None ->
//             { Latitude = 0.
//               Longitude = 0. }
//     div [ Class "mytask-card-map" ]
//         [ googleMap [| CommonProps.BootstrapURLKeys { key = "AIzaSyASFW97YoSKQe3o0vMBC1opstpId4VUqfI" };
//                        CommonProps.Center [|centerLat; centerLng|]
//                        CommonProps.Zoom zoom
//                        CommonProps.YesIWantToUseGoogleMapApiInternals true
//                        CommonProps.OnGoogleApiLoaded (handleApiLoaded lats lngs) |]
//                     [(getMapMarkers coordinates)] ]

// let private taskViewTitle (myTask : MyTask) =
//     match myTask with
//     | MyTask.MyRentOrderTask rentOrderTask ->
//         match rentOrderTask.MyRentOrderTaskType with
//         | DeliveryDisposition _ -> "Auslieferung"
//         | ReturnDisposition _ -> "Abholung"
//     | MyTask.MyServiceOrderTask repairOrderTask -> "Serviceauftrag"
//     | MyTask.MyPickTask pickTask -> "Kommissionierauftrag"

// let private taskView model dispatch (task : MyTask) =
//     div [ Class "card m-2" ]
//         [ div [ Class "card-header" ]
//             [ str (taskViewTitle task) ]
//           div [ Class "card-body row" ]
//             [ div [ Class "col-8" ]
//                 [ h5 [ Class "card-title" ]
//                     [ str (taskName task) ]
//                   p [ Class "card-text" ]
//                    [ str (sprintf "Kommentar: %s" (taskComment task) ) ] ]
//               div [ Class "col-4" ]
//                 [ cardMap (taskLocation task) model dispatch ] ] ]

// let private emptyTaskView (date : DateTime) =
//     let infotext =
//         if isCurrentDateGreaterThanToday date
//         then "Für diesen Tag wurde Ihnen noch keine Aufgabe zugewiesen."
//         else "An diesem Tag hatten Sie keine Aufgabe zugewiesen bekommen."

//     div [ Class "card m-2" ]
//         [ div [ Class "card-header" ]
//             [ str "" ]
//           div [ Class "card-body row" ]
//             [ div [ Class "col-8" ]
//                 [ h5 [ Class "card-title" ]
//                     [ str infotext ] ] ] ]

// let private myTaskRow model dispatch (cluster : DateTime * MyTask list) =
//     let datetime, mytasks = cluster
//     let myTasks = mytasks |> List.filter (fun t -> (executionDate t) = model.CurrentSelectedDate )

//     div []
//       [ myTasks
//         |> List.map (taskView model dispatch)
//         |> ofList ]

// let private myTaskTable (model : Model) dispatch =
//     let todayTitle =
//                  if model.CurrentSelectedDate.ToShortDateString() = DateTime.Today.ToShortDateString()
//                  then "- heute" else ""

//     let taskElements _ =
//       let ele =
//         model.MyTasks
//               |> List.filter (fun (d, t) -> d = model.CurrentSelectedDate)
//               |> List.map (myTaskRow model dispatch)
//       if ele.IsEmpty then
//           emptyTaskView model.CurrentSelectedDate else ele |> ofList

//     div [ ]
//       [ div [ Class "d-flex month-selector" ]
//               [ span [ Class "arrow fas fa-angle-left my-auto ml-auto"
//                        OnClick (fun _ -> ShowPrevDay |> dispatch) ]
//                       [  ]
//                 div [ Class "my-auto d-flex flex-column month-name" ]
//                     [ span [ Class " my-auto" ]
//                         [ str "Tag" ]
//                       str (sprintf "%s %s" (model.CurrentSelectedDate.ToString("dd.MM.yyyy")) todayTitle) ]

//                 span [ Class "arrow fas fa-angle-right my-auto mr-auto"
//                        OnClick (fun _ -> ShowNextDay |> dispatch) ]
//                         [ ] ]
//         div []
//           [ taskElements () ] ]

let myTaskView model dispatch =
    div [ Id "mytask-container" ]
        [ div [ Id "mytask-content" ]
            [ //detailView model dispatch
             ] ]

let view (model:Model) (dispatch: Msg -> unit) =
    div [ Id "mytask-container"
          Class "flex-grow-1 d-flex flex-column" ]
        [ div [ Id "mytask-content" ]
            [ mainHeader "Mein Aufgaben"
              div [ Class "flex-grow-1 d-flex flex-column" ]
                [ //myTaskTable model dispatch 
                ] ] ]