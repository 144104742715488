module Available.View

open Shared
open SharedComponents.Headers
open Fable.React
open Fable.React.Props
open Available.Types
open SharedComponents
open Shared.Entity
open SharedComponents.ReactSelect
open Feliz
open Feliz.Recharts
open Shared.Available

let templateProps (model : Model) dispatch isDisabled =
    let options (templates : Template list) =
        templates
        |> List.map (fun el ->
            { label = el.TemplateBaseInformation.Name
              value = el })
        |> List.toArray

    let emptySearchResultContent =
        div [ Class "mr-auto p-2" ]
          [ str "Es konnte kein passender Typ gefunden werden." ]

    let valueElement =
        match model.SelectedTemplate with
        | Some template -> [| ReactSelect.CommonProps<Template>.Value (Some { label = template.TemplateBaseInformation.Name; value = template }) |]
        | None -> [| ReactSelect.CommonProps<Template>.Value None |]
    Array.append
        [| SharedComponents.ReactSelect.CommonProps<Template>.Options (options model.Templates)
           SharedComponents.ReactSelect.CommonProps<Template>.OnChange (fun e -> dispatch (SelectTemplate (e)))
           SharedComponents.ReactSelect.CommonProps<Template>.IsSearchable true
           SharedComponents.ReactSelect.CommonProps<Template>.IsClearable true
           SharedComponents.ReactSelect.CommonProps<Template>.Placeholder "Suchen oder Filtern"
           SharedComponents.ReactSelect.CommonProps<Template>.NoOptionsMessage (fun _ -> emptySearchResultContent)
           SharedComponents.ReactSelect.CommonProps<Template>.ClassName "template-select flex-grow-1 mb-3"
           SharedComponents.ReactSelect.CommonProps<Template>.IsDisabled isDisabled |]
        valueElement

let legend =
    Html.div [
        prop.className "mb-3"
        prop.children [
            Html.div [
                Html.i [
                  prop.className "fas fa-circle dot-rentorder-planned mr-2"]
                Html.strong "Gerät geplant für Baustelle"
            ]
            Html.div [
                Html.i [
                  prop.className "fas fa-circle dot-rentorder-active mr-2"]
                Html.strong "Gerät befindet sich auf der Baustelle"
            ]
            Html.div [
                Html.i [
                  prop.className "fas fa-circle dot-rentorder-completed mr-2"]
                Html.strong "Gerät wurde abgeholt"
            ]
            Html.div [
                Html.i [
                  prop.className "fas fa-circle dot-notavailablerange mr-2"]
                Html.strong "außer Betrieb"
            ]
        ]
    ]


type PieSlice = { name: string; value: int }

let templateWorkloadDaterangeChartData (workload : TemplateWorkloadByDaterange) =
    [
        { name = "Freie Geräte"; value = workload.Free }
        { name = "Nicht verfügbare Geräte"; value = workload.NotAvailable }
    ]

let templateWorkloadChartData (workload : TemplateWorkload) =
    [
        { name = "Geräte auf der Baustelle"; value = workload.OnExecutionLocation }
        { name = "Geräte im Lager"; value = workload.InStock }
        { name = "Geräte unterwegs"; value = workload.OnRoad }
    ]

let workloadChart (model : Model) dispatch =
    let toChart data =
      let cells =
          let color index = if index = 0 then "#ecbf2b" elif index = 1 then "#1d1d1b" else "#7d7d7c"
          data
          |> List.mapi (fun index _ ->
              Recharts.cell [
                  cell.fill (color index)
              ])

      let renderCustomLabel (input: IPieLabelProperties) =
          let radius = input.innerRadius + (input.outerRadius - input.innerRadius) * 0.5;
          let radian = System.Math.PI / 180.
          let x = (input.cx + radius * cos (-input.midAngle * radian))
          let y = (input.cy + radius * sin (-input.midAngle * radian))
          Html.text [
              prop.style [
                  style.fill color.white
              ]
              prop.x x
              prop.y y
              prop.dominantBaseline.central
              if x > input.cx then prop.textAnchor.startOfText else prop.textAnchor.endOfText
              prop.text (sprintf "%.0f%%" (100. * input.percent))
          ]

      Html.div [
        prop.className "d-flex"
        prop.children [
          Html.div [
            prop.className "d-flex-row"
            prop.children [
              ReactDatepicker.Datepicker.datepicker(
                  Some model.TemplateWorkloadDaterange.StartDate,
                  (fun date -> date |> SelectStartDate |> dispatch),
                  labelName="Von",
                  maxDate=model.TemplateWorkloadDaterange.EndDate
                  )
              ReactDatepicker.Datepicker.datepicker(
                  Some model.TemplateWorkloadDaterange.EndDate,
                  (fun date -> date |> SelectEndDate |> dispatch),
                  labelName="Bis",
                  minDate=model.TemplateWorkloadDaterange.StartDate
                  )
              Buttons.primaryButtonWithFnct
                  (fun _ ->  FetchTemplateWorkloadByDaterange |> dispatch)
                  "Auslastung abfragen" "d-flex ml-auto"
            ]
          ]
          Html.div [
            Recharts.pieChart [
                pieChart.width 250
                pieChart.height 150
                pieChart.children [
                    Recharts.pie [
                        pie.data data
                        pie.cx 125
                        pie.cy 70
                        pie.labelLine false
                        pie.label renderCustomLabel
                        //pie.label true
                        pie.outerRadius 75
                        pie.fill "#8884d8"
                        pie.children cells
                    ]
                    Recharts.tooltip []
                ]
            ]
          ]
        ]
      ]
    
    if model.WorkloadRequest then
      Html.div [
          prop.className "d-flex justify-content-center"
          prop.children [
              SharedComponents.Spinners.spinner
            ]
      ]
    else
      match model.TemplateWorkload, model.TemplateWorkloadByDaterange with
      | None, Some templateWorkloadByDaterange
      | Some _, Some templateWorkloadByDaterange ->
          (templateWorkloadDaterangeChartData templateWorkloadByDaterange) |> toChart
      | Some workload, None ->
          (templateWorkloadChartData workload) |> toChart
      | None, None ->
          Html.none

let overview (model:Model) (dispatch: Msg -> unit) =
    let defaultTimeStart = model.Moment.startOf("day").add(-1., "week").toDate()
    let defaultTimeEnd =  model.Moment.startOf("day").add(3., "week").toDate()

    Html.div [
        prop.id "available-content"
        prop.className "flex-grow-1"
        prop.children [
            mainHeader "Buchungsplan"
            Html.div [
              prop.className "d-flex"
              prop.children [
                Html.div [
                  prop.className "d-flex-row"
                  prop.children [
                    ReactSelect.selectWithoutLabel (templateProps model dispatch false)
                    legend
                  ]
                ]
                Html.div [
                  prop.className "d-flex ml-auto mr-5"
                  prop.children [
                    workloadChart model dispatch
                  ]
                ]
              ]
            ]
            if model.FetchData then
              Html.div [
                  prop.className "d-flex justify-content-center"
                  prop.children [
                      SharedComponents.Spinners.spinner
                    ]
              ]
            else
              SharedComponents.ReactCalendarTimeline.calendartimeline
                                  [ ReactCalendarTimeline.CommonProps.Groups (model.Groups |> List.toArray)
                                    ReactCalendarTimeline.CommonProps.Items (model.Items |> List.toArray)
                                    ReactCalendarTimeline.CommonProps.DefaultTimeStart (defaultTimeStart)
                                    ReactCalendarTimeline.CommonProps.DefaultTimeEnd (defaultTimeEnd)
                                    ReactCalendarTimeline.CommonProps.TimeSteps model.CustomTimeSteps
                                    ReactCalendarTimeline.CommonProps.OnItemClick (fun id -> dispatch (OnItemClick id))
                                    ReactCalendarTimeline.CommonProps.SidebarWidth 250
                                    ReactCalendarTimeline.CommonProps.CanMove false
                                    ReactCalendarTimeline.CommonProps.CanResize "right"
                                    ReactCalendarTimeline.CommonProps.ItemsSorted true
                                    ReactCalendarTimeline.CommonProps.ItemTouchSendsClick false
                                    ReactCalendarTimeline.CommonProps.StackItems true
                                    ReactCalendarTimeline.CommonProps.ItemHeightRatio 0.75
                                    ReactCalendarTimeline.CommonProps.ShowCursorLine true
                                    ReactCalendarTimeline.CommonProps.MinZoom (999999999)
                                  ] "Geräte"
        ]
    ]

let view (model:Model) (dispatch: Msg -> unit) =
    Html.div [
        prop.id "available-container"
        prop.className "flex-grow-1 d-flex"
        prop.children [
          overview model dispatch
        ]
    ]