module Communication

open Fetch
open Fetch.Types
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Fable.Import
open Browser.Types
open Browser.WebStorage
open Browser.Dom
open Browser.XMLHttpRequest
open ErrorHandling
open System
open Fable.Core

[<Emit("new Uint8Array($0)")>]
let createUInt8Array(x: 'a) : byte[] = jsNative

let token _ =
    match localStorage.getItem "user" |> Decode.Auto.fromString<UserData> with
    | Result.Ok t -> t.Token
    | Result.Error _ -> ""

// Custom error message
let errorString (response: Response) =
    string response.Status + " " + response.StatusText + " for URL " + response.Url

let inline decode<'T> (str : byte []) =
    let reader = Fable.Remoting.MsgPack.Read.Reader str
    let b = typeof<'T>
    let result = (reader.Read b) :?> 'T
    result

let debugDecode decoder str =
    match Decode.fromString decoder str with
    | Result.Ok obj -> obj
    | Result.Error e -> failwith e

let inline fetchWithDecoder<'T> (url: string) (init: RequestProperties list) =
    GlobalFetch.fetch(RequestInfo.Url url, Fetch.requestProps init)
    |> Promise.bind (fun response ->
        match response.Status with
        | 400 -> raise Exception400
        | 401 -> raise Exception401
        | 403 -> raise Exception403
        | 404 -> raise Exception404
        | 500 -> raise Exception500
        | _ ->
            // TODO: sentry logging
            printfn "Response: %i" response.Status
            #if DEBUG
            let decoder = Decode.Auto.generateDecoderCached<'T>()
            response.text()
            |> Promise.map (debugDecode decoder))
            #else
            response.arrayBuffer()
            |> Promise.map (fun blob -> decode<'T> (blob |> createUInt8Array)))
            #endif

// Inline the function so Fable can resolve the generic parameter at compile time
let inline fetchAs<'T> (url: string) (init: RequestProperties list) =
    // In this example we use Thoth.Json cached auto decoders
    // More info at: https://mangelmaxime.github.io/Thoth/json/v3.html#caching
    fetchWithDecoder<'T> url init

// [<Obsolete("Do not use anymore. Use async version instead")>]
let inline getRequest<'a> url =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.GET
              Fetch.requestHeaders
                [ HttpRequestHeaders.Authorization ("Bearer " + (token()))
                  HttpRequestHeaders.ContentType "application/json"
                  HttpRequestHeaders.CacheControl "no-cache"
                  HttpRequestHeaders.Pragma "no-cache"
                  HttpRequestHeaders.IfModifiedSince "0"
                  HttpRequestHeaders.Custom("Expires", "0") ] ]
        return! fetchAs<'a> url props
    }

let inline getFileRequest (url, filename) =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.GET
              Fetch.requestHeaders
                [ HttpRequestHeaders.Authorization ("Bearer " + (token()))
                  HttpRequestHeaders.ContentType "Content-Disposition" ] ]
        let! file = Fetch.fetch url props
        return file, filename
    }

let inline postRequest<'a> (url, (body : string)) =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.POST
              Fetch.requestHeaders [
                HttpRequestHeaders.Authorization ("Bearer " + (token()))
                HttpRequestHeaders.ContentType "application/json" ]
              RequestProperties.Body !^body ]
        return! fetchAs<'a> url props
    }

let inline postFileRequest<'a> (url, (formData: FormData)) =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.POST
              Fetch.requestHeaders [
                HttpRequestHeaders.Authorization ("Bearer " + (token())) ]
              RequestProperties.Body (formData |> unbox) ]
        return! fetchAs<'a> url props
    }

let inline putRequest<'a> (url, (body : string)) =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.PUT
              Fetch.requestHeaders [
                HttpRequestHeaders.Authorization ("Bearer " + (token()))
                HttpRequestHeaders.ContentType "application/json" ]
              RequestProperties.Body !^body ]
        return! fetchAs<'a> url props
    }

let inline deleteRequest<'a> url =
    promise {
        let props =
            [ RequestProperties.Method HttpMethod.DELETE
              Fetch.requestHeaders [
                HttpRequestHeaders.Authorization ("Bearer " + (token()))
                HttpRequestHeaders.ContentType "application/json" ] ]

        return! fetchAs<'a> url props
    }

open Fable.SimpleHttp

let inline asyncGetRequest<'a> url =
    async {
        // let props =
        //     [ RequestProperties.Method HttpMethod.GET
        //       Fetch.requestHeaders
        //         [ HttpRequestHeaders.Authorization ("Bearer " + (token()))
        //           HttpRequestHeaders.ContentType "application/json"
        //           HttpRequestHeaders.CacheControl "no-cache"
        //           HttpRequestHeaders.Pragma "no-cache"
        //           HttpRequestHeaders.IfModifiedSince "0"
        //           HttpRequestHeaders.Custom("Expires", "0") ] ]
        let! response =
            Http.request url
            |> Http.method GET
            |> Http.header (Headers.authorization ("Bearer " + (token())))
            #if DEBUG
            #else
            |> Http.overrideResponseType ResponseTypes.ArrayBuffer
            #endif
            |> Http.send

        match response.statusCode with
        | 400 -> raise Exception400
        | 401 -> raise Exception401
        | 403 -> raise Exception403
        | 404 -> raise Exception404
        | 500 -> raise Exception500
        | _ -> ()
        // return! fetchAs<'a> url props

        #if DEBUG
        let decoder = Decode.Auto.generateDecoderCached<'a>()
        return (debugDecode decoder response.responseText)
        #else
        let resp =
            match response.content with
            | ResponseContent.ArrayBuffer a -> a |> createUInt8Array
            | ResponseContent.Text t -> failwith "not implemented - text response"
            | ResponseContent.Blob a -> failwith "not implemented - blob response"
            | ResponseContent.Unknown a -> a :?> byte []
        return (decode<'a> resp)
        #endif
    }
