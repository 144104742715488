module Order.RentOrder.DownloadPrinzingDialog

open Elmish
open Feliz
open Fetch.Types
open SharedComponents
open Feliz.SweetAlert
open System
open Feliz.ElmishComponents
open Shared
open Browser.Types
open Fable.Core.JsInterop
open Browser.Dom

type Msg =
    | Download
    | DownloadCompleted of Response * string
    | BlobReceived of Blob * string
    | SetStartDate of DateTime
    | SetEndDate of DateTime
    | FetchError of exn

let printRentOrdersPositionExcelCmd (rentOrderId : RentOrderId) (startDate : DateTime) (endDate : DateTime) =
    // TODO: Remove useless param and add download api without param
    let (RentOrderId rentOrderId) = rentOrderId
    let id = rentOrderId.ToString()
    let startDate = startDate.ToString("dd.MM.yyyy")
    let endDate = endDate.ToString("dd.MM.yyyy")
    let url = sprintf "/api/rentorders/prinzing/%s/report/positions/%s/%s" id startDate endDate
    Cmd.OfPromise.either Communication.getFileRequest (url, "prinzing_position_export.xlsx") DownloadCompleted FetchError

let printRentOrdersPositionZipCmd(startDate : DateTime) (endDate : DateTime) =
    // TODO: Remove useless param and add download api without param
    let startDate = startDate.ToString("dd.MM.yyyy")
    let endDate = endDate.ToString("dd.MM.yyyy")
    let url = sprintf "/api/rentorders/prinzing/report/positions/%s/%s" startDate endDate
    Cmd.OfPromise.either Communication.getFileRequest (url, "prinzing_position_export.zip") DownloadCompleted FetchError

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }
    
let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError
    
type State =
    { StartDate : DateTime
      EndDate : DateTime
      RentOrderId : RentOrderId option
      Loading : bool }
    
let private init rentOrderId now : State * Cmd<Msg> =
    let initialModel =
        { StartDate = now
          EndDate = now
          RentOrderId = rentOrderId
          Loading = false }
    initialModel, Cmd.none
    
let private update (msg : Msg) (state : State) =
    match msg with
    | Download ->
        let cmd =
            match state.RentOrderId with
            | Some rentOrderId -> printRentOrdersPositionExcelCmd rentOrderId state.StartDate state.EndDate
            | None -> printRentOrdersPositionZipCmd state.StartDate state.EndDate
            
        { state with Loading = true }, cmd 
    | SetStartDate startDate ->
        if startDate > state.EndDate then
            state, Cmd.none
        else { state with StartDate = startDate }, Cmd.none
    | SetEndDate endDate ->
        if endDate < state.StartDate then
            state, Cmd.none
        else { state with EndDate = endDate }, Cmd.none
    | DownloadCompleted (response, filename) ->
        { state with Loading = false }, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        state, Cmd.none
    | FetchError e ->
        { state with Loading = false }, Cmd.none

let dialogBody (state : State) dispatch =
    Html.div [
        prop.children [
              Html.label [
                    prop.htmlFor "input-manufacturer"
                    prop.className "form-label"
                    prop.text "Zeitraum auswählen"
                ]

              ReactDatepicker.Datepicker.datepicker(
                  Some state.StartDate,
                  (fun date -> dispatch (SetStartDate date)),
                  labelName="Von",
                  isDisabled=state.Loading,
                  dateFormat="MM.yyyy",
                  monthPicker=true)
              
              ReactDatepicker.Datepicker.datepicker(
                  Some state.EndDate,
                  (fun date -> dispatch (SetEndDate date)),
                  labelName="Bis",
                  isDisabled=state.Loading,
                  dateFormat="MM.yyyy",
                  monthPicker=true)

              Html.div [
                    prop.className "swal2-actions"
                    prop.children [
                        Buttons.primaryButtonWithFnctAndIsDisabled
                            (fun _ -> Download |> dispatch)
                            (state.Loading)
                            "Exportieren" "mr-3"
                        Buttons.primaryButtonWithFnct
                            (fun _ -> Swal.close (SweetAlert.Result.Dismissal Cancel))
                            "Abbrechen" ""
                    ]
                ]
        ]
    ]
    
let private Body rentOrderId now =
    React.elmishComponent("DownloadExcelPrinzingDialog", init rentOrderId now, update, dialogBody)
    
let DownloadPrinzingDialog' =
    React.functionComponent(fun (props : {| Now : DateTime
                                            RentOrderId : RentOrderId option |}) ->
        Html.button [
              prop.classes [
                  match props.RentOrderId with
                  | Some _ -> "dropdown-item"
                  | None -> "btn btn-primary ml-auto mb-3 mr-3"
              ]
              prop.onClick (fun _ ->
                            Swal.fire ([
                                swal.html (Body props.RentOrderId props.Now)
                                swal.showCancelButton false
                                swal.showConfirmButton false
                                ],
                                (function
                                    | SweetAlert.Result.Value _ -> ()
                                    | SweetAlert.Result.Dismissal d -> ()
                                    | SweetAlert.Result.Denied -> ()
                                )
                            )
                        )
              prop.children [
                  Html.i [
                      prop.classes [
                          "fa fa-file-excel"
                          "mr-1"
                      ]
                  ]
                  Html.text
                      (match props.RentOrderId with
                       | Some _ -> "Position Excel Export"
                       | None -> "Position .zip Export")
                      
              ]
          ])