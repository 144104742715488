module Dispositions.RentOrderDisposition.State

open Elmish
open Elmish.Navigation
open Dispositions.RentOrderDisposition.Types
open Shared
open System
open Thoth.Json
open Shared.TaskTypes
open Shared.Entity
open Browser.Dom
open Client
open Fable.Core.JsInterop
open Fetch
open SharedComponents.Toast
open SharedComponents.Spinners

let printTaskCmd (taskId : TaskId) taskName =
    let (TaskId taskId) = taskId
    let id = taskId.ToString()
    Cmd.OfPromise.either Communication.getFileRequest ((sprintf "/api/print/pdf/document/task/%s" id), (sprintf "%s.pdf" taskName)) PdfFetched FetchError

let getRentOrderWithCompletedTasksFetchedCmd (orderId : RentOrderId) =
    let (RentOrderId id) = orderId
    let rentOrderId = id.ToString()
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/rentorders/%s/task" rentOrderId) RentOrderWithCompletedTaskFetched FetchError

let getTask (taskId : TaskId) =
    let (TaskId taskId) = taskId
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/tasks/%s" (taskId |> string)) TaskFetched FetchError

let getEntitiesCmd =
    Cmd.OfPromise.either Communication.getRequest<EntitiesResponse> "/api/entities" EntitiesFetched FetchError

let getMaterialsCmd = Commands.getMaterialsCmd MaterialsFetched FetchError

let getDriversCmd =
    Cmd.OfPromise.either Communication.getRequest<DriversResponse> "/api/users/driver" DriversFetched FetchError

let extractBlob ((response : Response), filename) =
    promise {
        let! blob = response.blob()
        return blob, filename
    }

let getBlob (response : Response) filename =
    Cmd.OfPromise.either extractBlob (response, filename) BlobReceived FetchError

let init orderId taskId : Model * Cmd<Msg> =
    { OrderId = orderId
      TaskId = taskId
      RentOrderWithCompletedTasks = None
      CurrentCompletedRentOrderTask = None
      Task = None
      Drivers = [ ]
      Entities = [ ]
      Materials = [ ]
      Signature = None
      Images = [ ]
      RequestState = RequestState.Active
      ImageViewerOpen = false
      ImageViewerIndex = 1 }, Cmd.batch [
          getTask taskId
          getRentOrderWithCompletedTasksFetchedCmd orderId
          getEntitiesCmd
          getDriversCmd
          getMaterialsCmd  ]

let update (msg:Msg) (model : Model) : Model * Cmd<Msg> =
    match msg with
    | OpenImageViewer index ->
        { model with ImageViewerOpen = true; ImageViewerIndex = index }, Cmd.none
    | CloseImageViewer ->
        { model with ImageViewerOpen = false }, Cmd.none
    | PrintAsPdf taskOption ->
        let newModel, newCmd =
            match taskOption with
            | None -> model, Cmd.none
            | Some task ->
                let taskName =
                    match model.Task with
                    | None -> ""
                    | Some t ->
                        match t with
                        | AssignedTask.AssignedFreeTask _ -> "Eigene Aufgabe"
                        | AssignedTask.AssignedDeliveryTask _ -> "Lieferung"
                        | AssignedTask.AssignedServiceTask _ -> "Reparatur"
                        | AssignedTask.AssignedReturnTask _ -> "Abholung"
                        | AssignedTask.AssignedMechanicTask _ -> "Montage"
                        | AssignedTask.AssignedPickTask _ -> "Kommissionierung"
                        | AssignedTask.CarrierDeliveryTask _ -> "Lieferung per Spedition"
                        | AssignedTask.CarrierReturnTask _ -> "Abholung per Spedition"
                        | AssignedTask.AssignedSelfserviceDeliveryTask _ -> "Selbstabholung"
                        | AssignedTask.AssignedSelfserviceReturnTask _ -> "Selbstrückgabe"
                let pdfName = sprintf "%s_%s" taskName (System.DateTime.Now.ToString())
                let taskId = Helper.rentOrderTaskId task
                let model, cmd =
                    { model with RequestState = RequestState.Active }, Cmd.batch [ printTaskCmd taskId pdfName; toast (ToastType.Info "Dokument wird heruntergeladen")  ]
                model, cmd
        newModel, newCmd

    /// Requests
    | PdfFetched (response, filename) ->
        { model with RequestState = RequestState.NotActive }, getBlob response filename
    | BlobReceived (blob, filename) ->
        /// https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        let url : string = window?URL?createObjectURL(blob)
        let element = document.createElement "a"
        element.setAttribute("href", url)
        element.setAttribute("download", filename)
        document?body?appendChild(element);
        element.click()
        window?URL?revokeObjectURL(url)
        element?remove()
        model, Cmd.none
    | TaskFetched response ->
        let task =
            match response with
            | Task.AssignedFreeOrderTask t -> AssignedTask.AssignedFreeTask t |> Some
            | Task.AssignedDeliveryTask t -> AssignedTask.AssignedDeliveryTask t |> Some
            | Task.AssignedReturnTask t -> AssignedTask.AssignedReturnTask t |> Some
            | Task.AssignedServiceTask t -> AssignedTask.AssignedServiceTask t |> Some
            | Task.AssignedPickTask t -> AssignedTask.AssignedPickTask t |> Some
            | Task.CarrierDeliveryTask t -> AssignedTask.CarrierDeliveryTask t |> Some
            | Task.CarrierReturnTask t -> AssignedTask.CarrierReturnTask t |> Some
            | Task.AssignedSelfserviceDeliveryTask t -> AssignedTask.AssignedSelfserviceDeliveryTask t |> Some
            | Task.AssignedSelfserviceReturnTask t -> AssignedTask.AssignedSelfserviceReturnTask t |> Some
            | Task.AssignedMechanicTask _
            | Task.DeliveryTask _
            | Task.ReturnTask _
            | Task.ServiceTask _
            | Task.FreeOrderTask _
            | Task.PickTask _ -> None
        { model with Task = task }, Cmd.none
    | EntitiesFetched response ->
        { model with Entities = response.Entities }, Cmd.none
    | MaterialsFetched response ->
        { model with Materials = response.Materials }, Cmd.none
    | DriversFetched response ->
        { model with Drivers = response.Drivers }, Cmd.none
    | ImageFetched response ->
        { model with Images = response :: model.Images }, Cmd.none
    | SignatureFetched response ->
        { model with Signature = Some response }, Cmd.none
    | RentOrderWithCompletedTaskFetched response ->
        let currentTask = response.Tasks |> List.tryFind (fun t -> t |> Helper.rentOrderTaskId = model.TaskId)
        let cmds =
            match currentTask with
            | Some task ->
                let report =
                    match task with
                    | RentOrderTask.AssignedDeliveryTask task -> task.RentOrderTaskReport
                    | RentOrderTask.AssignedReturnTask task -> task.RentOrderTaskReport
                    | RentOrderTask.CarrierDeliveryTask task -> task.TaskReport
                    | RentOrderTask.CarrierReturnTask task -> task.TaskReport
                    | RentOrderTask.AssignedSelfserviceDeliveryTask t -> t.RentOrderTaskReport
                    | RentOrderTask.AssignedSelfserviceReturnTask t -> t.RentOrderTaskReport
                    | _ -> None
                match report with
                | Some report ->
                    match report.Signature with
                    | Some signature ->
                        [ Commands.getImage signature.Id SignatureFetched FetchError ]
                    | None -> [ ]
                    |> List.append
                        (report.Images |> List.map (fun i -> Commands.getImage i.Id ImageFetched FetchError))
                    |> Cmd.batch
                | None -> Cmd.none
            | None -> Cmd.none
        { model with RentOrderWithCompletedTasks = Some response
                     CurrentCompletedRentOrderTask = currentTask
                     RequestState = RequestState.NotActive }, cmds
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
