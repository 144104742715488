module Order.FreeOrder.State

open Elmish
open Elmish.Navigation
open Thoth.Json
open Order.FreeOrder.Types
open Shared
open Order.FreeOrder.FormValidation
open Order.FreeOrder.Helper
open Validation
open System
open Shared.Address
open Routes
open SharedComponents.Toast
open SharedComponents.Spinners
open Shared.FreeOrder
open Client

let getFreeOrdersCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/freeorders/active" FreeOrdersFetched FetchError

let getFreeOrdersCompletedCmd =
    Cmd.OfPromise.either Communication.getRequest "/api/freeorders/completed" FreeOrdersFetched FetchError

let getFreeOrderCmd (orderId : System.Guid) =
    let id = orderId.ToString()
    Cmd.OfPromise.either Communication.getRequest (sprintf "/api/freeorders/%s" id) FreeOrderFetched FetchError

let saveFreeOrderCmd form =
    Cmd.OfPromise.either Communication.postRequest ("/api/freeorders", form) FreeOrderSaved FetchError

let updateFreeOrderCmd (freeOrderId : FreeOrderId) form =
    let url = sprintf "/api/freeorders/%s" (freeOrderId |> Shared.Helper.unwrapFreeOrderId)
    Cmd.OfPromise.either Communication.putRequest (url, form) FreeOrderUpdated FetchError

let deleteFreeOrderCmd (freeOrderId : FreeOrderId) =
    let freeOrderId = freeOrderId |> Shared.Helper.unwrapFreeOrderId
    Cmd.OfPromise.either Communication.deleteRequest<PostResponse<FreeOrderId>> (sprintf "/api/freeorders/%s" freeOrderId) FreeOrderDeleted FetchError

let initialModel userData =
    { FreeOrders = []
      FormState = FormState.Loading
      UserData = userData
      SaveState = SaveState.Nothing
      RequestState = RequestState.NotActive
    }

let initialFreeOrder : FreeOrder =
    { PlannedExecutionDate = System.DateTime.UtcNow
      ExecutionDate = System.DateTime.UtcNow
      Title = ""
      Description = ""
      ExecutionAddress = emptyAddress
      Id = FreeOrderId System.Guid.Empty
      State = FreeOrderState.Undisposed
      Tasks = [] }

let initNewForm userData : Model * Cmd<Msg> =
    let newFormState =
        { FreeOrder = initialFreeOrder
          FormValidation = None } |> FormState.New
    { initialModel userData with FormState = newFormState }, Cmd.none

let initOverview userData (oldModel : Model option) : Model * Cmd<Msg> =
    { initialModel userData with RequestState = RequestState.Active }, getFreeOrdersCmd

let initOverviewCompleted userData (oldModel : Model option) : Model * Cmd<Msg> =
    { initialModel userData with RequestState = RequestState.Active }, getFreeOrdersCompletedCmd

let initDetail userData orderId : Model * Cmd<Msg> =
    { initialModel userData with RequestState = RequestState.Active }, getFreeOrderCmd orderId

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    // Form
    | SetPlannedExecutionDate plannedExecutionDate ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with FreeOrder = { form.FreeOrder with PlannedExecutionDate = plannedExecutionDate } } |> FormState.New
            | Edit form ->
                { form with FreeOrder = { form.FreeOrder with PlannedExecutionDate = plannedExecutionDate } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetContactPerson contactPerson ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with ContactPerson = Some contactPerson }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with ContactPerson = Some contactPerson }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetCompany company ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with CompanyName = Some company }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with CompanyName = Some company }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetCity city ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with City = city }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with City = city }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetStreet street ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with Street = street }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with Street = street }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetZipCode zipCode ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with ZipCode = zipCode }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with ZipCode = zipCode }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHouseNumber houseNumber ->
        let newFormState =
            match model.FormState with
            | New form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with HouseNumber = houseNumber }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.New
            | Edit form ->
                let newAddress = { form.FreeOrder.ExecutionAddress with HouseNumber = houseNumber }
                { form with FreeOrder = { form.FreeOrder with ExecutionAddress = newAddress } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    | SetDescription description ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with FreeOrder = { form.FreeOrder with Description = description } } |> FormState.New
            | Edit form ->
                { form with FreeOrder = { form.FreeOrder with Description = description} } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetTitle title ->
        let newFormState =
            match model.FormState with
            | New form ->
                { form with FreeOrder = { form.FreeOrder with Title = title } } |> FormState.New
            | Edit form ->
                { form with FreeOrder = { form.FreeOrder with Title = title } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Set edit states
    | EditInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditInformation
                                       FreeOrder = form.FreeOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditAddress ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditAddress
                                       FreeOrder = form.FreeOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditPlannedExecutionDate ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.EditPlannedExecutionDate
                                       FreeOrder = form.FreeOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | New form -> FormState.New form
            | Edit form -> { form with EditField = EditField.Nothing
                                       FreeOrder = form.FreeOrderSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    | SaveFreeOrder ->
        let validation =
            match model.FormState with
            | New form ->
                validateNewForm form
            | Edit form ->
                match form.EditField with
                | EditField.EditInformation -> isValid
                | EditField.EditPlannedExecutionDate -> isValid
                | EditField.EditAddress -> isValid
                | EditField.Nothing -> notValid
            | FormState.Loading -> notValid
        let newModel, cmd =
            if validation.FormValid = ValidationState.Valid then
                let newModel, cmd =
                    match model.FormState with
                    | New form ->
                        { model with RequestState = RequestState.Active },
                        form.FreeOrder
                        |> Encode.toString 0
                        |> saveFreeOrderCmd
                    | Edit form ->
                        match form.EditField with
                        | EditField.EditAddress ->
                            { model with RequestState = RequestState.Active },
                            form.FreeOrder.ExecutionAddress
                            |> FreeOrderUpdate.Address
                            |> Encode.toString 0
                            |> updateFreeOrderCmd form.FreeOrder.Id
                        | EditField.EditInformation ->
                            { model with RequestState = RequestState.Active },
                            {
                                Title = form.FreeOrder.Title
                                Description = form.FreeOrder.Description
                            }
                            |> FreeOrderUpdate.Information
                            |> Encode.toString 0
                            |> updateFreeOrderCmd form.FreeOrder.Id
                        | EditField.EditPlannedExecutionDate ->
                            { model with RequestState = RequestState.Active },
                            form.FreeOrder.PlannedExecutionDate
                            |> FreeOrderUpdate.PlannedExecutionDate
                            |> Encode.toString 0
                            |> updateFreeOrderCmd form.FreeOrder.Id
                        | EditField.Nothing -> model, Cmd.none
                    | FormState.Loading -> model, Cmd.none
                { newModel with SaveState = SaveState.Saving }, cmd
            else
                let warningToast = toast (ToastType.Warning "Bitte überprüfe das Formular.")
                match model.FormState with
                | Edit form ->
                    let newFormState = { form with FormValidation = Some validation } |> Edit
                    { model with FormState = newFormState }, Cmd.batch [addressToastCmd validation; warningToast]
                | FormState.Loading -> model, Cmd.none
                | New form ->
                    let newFormState = { form with FormValidation = Some validation } |> New
                    { model with FormState = newFormState }, Cmd.batch [addressToastCmd validation; warningToast]
        newModel, cmd

    // Delete actions
    | AbortDelete ->
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form ->
                { form with DeleteRequested = None } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | RequestDelete ->
        let newFormState =
            match model.FormState with
            | New form -> form |> FormState.New
            | Edit form ->
                { form with DeleteRequested = Some form.FreeOrder.Id } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | DeleteRequest ->
        let newModel, newCmd =
            match model.FormState with
            | New form -> model, Cmd.none
            | Edit form ->
                { model with RequestState = RequestState.Active },
                deleteFreeOrderCmd form.FreeOrder.Id
            | FormState.Loading -> model, Cmd.none
        newModel, newCmd

    | FreeOrderSaved response ->
        let model, cmd =
            match model.FormState with
            | New form ->
                model, Cmd.batch [ Navigation.newUrl (Routes.toPath (Page.FreeOrderDetail response.Result)); toast (ToastType.Success "Eigener Auftrag erfolgreich angelegt.")]
            | Edit _
            | FormState.Loading -> model, Cmd.none
        { model with SaveState = SaveState.Nothing
                     RequestState = RequestState.NotActive }, cmd
    | FreeOrderDeleted response ->
        { initialModel model.UserData with RequestState = RequestState.NotActive }, Cmd.batch [ Navigation.newUrl (Routes.toPath (Routes.Page.FreeOrderOverview )); toast (ToastType.Info "Eigener Auftrag wurde erfolgreich gelöscht.")]
    | FreeOrderUpdated response ->
        let model, cmd =
            match model.FormState with
            | FormState.Loading -> model, Cmd.none
            | New form -> model, Cmd.none
            | Edit form ->
                match response with
                | FreeOrderUpdateResponse.FreeOrderIsExecuting ->
                    match form.EditField with
                    | EditField.EditPlannedExecutionDate ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits aktiv.")
                    | EditField.EditInformation ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits aktiv.")
                    | EditField.EditAddress ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits aktiv.")
                    | EditField.Nothing ->
                        model, Cmd.none
                | FreeOrderUpdateResponse.FreeOrderIsFinished ->
                    match form.EditField with
                    | EditField.EditPlannedExecutionDate ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits abgeschlossen.")
                    | EditField.EditInformation ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits abgeschlossen.")
                    | EditField.EditAddress ->
                        model, toast (ToastType.Warning "Auftrag wurde nicht aktualisiert. Auftrag ist bereits abgeschlossen.")
                    | EditField.Nothing ->
                        model, Cmd.none
                | FreeOrderUpdateResponse.Updated ->
                    { model with FormState = { form with FreeOrderSnapshot = form.FreeOrder
                                                         EditField = EditField.Nothing } |> FormState.Edit },
                    toast (ToastType.Success "Auftrag wurde erfolgreich aktualisiert.")
        { model with SaveState = Nothing
                     RequestState = RequestState.NotActive }, cmd
    | FreeOrderFetched response ->
        let formStateEdit =
            { FreeOrder = response
              FreeOrderSnapshot = response
              FormValidation = None
              DeleteRequested = None
              Employees = []
              EditField = EditField.Nothing }
        let newFormState, newCmd =
            match model.FormState with
            | New form -> form |> FormState.New, Cmd.none
            | FormState.Loading -> formStateEdit |> FormState.Edit, Cmd.none
            | FormState.Edit form -> formStateEdit |> FormState.Edit, Cmd.none
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, Commands.getEmployeesCmd EmployeesFetched FetchError
    | FreeOrdersFetched response ->
        { model with FreeOrders = response.FreeOrders
                     RequestState = RequestState.NotActive }, Cmd.none
    | EmployeesFetched response ->
        let newFormState, newCmd =
            match model.FormState with
            | New form -> form |> FormState.New, Cmd.none
            | FormState.Loading -> FormState.Loading, Cmd.none
            | FormState.Edit form -> { form with Employees = response.Employees } |> FormState.Edit, Cmd.none
        { model with FormState = newFormState }, newCmd
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
