namespace Sww.Frontend

module LocalStorageHelper =
    open Browser.WebStorage
    open Thoth.Json

    let initialPageSizeItemKey = "initialPageSize"

    type LocalStorageUserData =
        { TablePaginationConfiguration : Map<string, int>
          SelectedLanguage : string }

    let emptyLocalStorageUserData =
        { TablePaginationConfiguration = Map.empty
          SelectedLanguage = "de" }

    /// <summary>
    /// This function doesn't do anything really, just returns an empty string
    /// </summary>
    /// <param name="applicationKey">Key of application storage, like mwk_userdata</param>
    /// <param name="key">Optional key for table name</param>
    let initialPageSize applicationKey (key : string option) =
        let defaultValue = 10
        let parsedUserData =
            localStorage.getItem applicationKey |> Option.ofObj
            |> Option.map Decode.Auto.fromString<LocalStorageUserData>
        match parsedUserData, key with
        | Some (Result.Ok userData), Some key ->
            try
                userData.TablePaginationConfiguration.[key]
            with
            | e ->
                defaultValue
        | None, _
        | Some (Result.Error _), Some _
        | Some _, None -> defaultValue

    let writeInitialPageSize applicationKey (key : string option) (value : int) =
        let parsedUserData =
            localStorage.getItem applicationKey |> Option.ofObj
            |> Option.map Decode.Auto.fromString<LocalStorageUserData>
        let updatedUserData =
            match parsedUserData, key with
            | Some (Result.Ok userData), Some key ->
                { userData with
                    TablePaginationConfiguration =
                        userData.TablePaginationConfiguration |> Map.add key value }
            | None, _
            | Some (Result.Error _), Some _
            | Some _, None ->
                emptyLocalStorageUserData
        localStorage.setItem(applicationKey, Thoth.Json.Encode.Auto.toString (0, updatedUserData))

    let writeLanguage applicationKey (value : string) =
        let updatedUserData =
            match localStorage.getItem applicationKey |> Option.ofObj with
            | Some userData ->
                match userData |> Decode.Auto.fromString<LocalStorageUserData> with
                | Result.Ok userData ->
                    { userData with SelectedLanguage = value }
                | Result.Error _ ->
                    emptyLocalStorageUserData
            | None -> emptyLocalStorageUserData
        localStorage.setItem(applicationKey, Thoth.Json.Encode.Auto.toString (0, updatedUserData))

    let currentLanguage applicationKey =
        let initialLanguage = "de"
        match localStorage.getItem applicationKey |> Option.ofObj with
        | Some userData ->
            match userData |> Decode.Auto.fromString<LocalStorageUserData> with
            | Result.Ok userData ->
                if userData.SelectedLanguage |> Option.ofObj |> Option.isNone
                then initialLanguage
                else userData.SelectedLanguage
            | Result.Error _ -> initialLanguage
        | None -> initialLanguage
