module SharedComponents.Alerts

open Fable.React
open Fable.React.Props

type MessageType =
    | Error
    | Info
    | Warning

type Message = { MessageType : MessageType
                 Message : string }

let buildMessage (messageType : MessageType) msg =
   { MessageType = messageType
     Message = msg }

let private alert className stringLabel msg cyIdentifier =
   div [ Class ("alert " + className)
         Data ("cy", ("alert-"+cyIdentifier)) ]
    [ strong [ ]
        [ str stringLabel ]
      str (" " + msg) ]

let infoAlert msg =
   alert "alert-info" "Info:" msg "info"

let warningAlert msg =
   alert "alert-warning" "Warnung:" msg "warning"

let errorAlert msg =
   alert "alert-danger" "Fehler:" msg "error"

let getAlert ( message : Message option ) =
   match message with
   | None -> Fable.React.Helpers.nothing
   | Some msg ->
       match msg.MessageType with
       | Error ->
            errorAlert msg.Message
       | Info ->
            infoAlert msg.Message
       | Warning ->
            warningAlert msg.Message