module Configuration.Company.State

open Configuration.Company.Types
open Elmish
open Shared.Configuration
open SharedComponents
open Thoth.Json
open Shared
open Fable.Core.JsInterop
open Browser.Dom
open Browser.Blob
open Browser.Types
open Browser.WebStorage
open Browser.XMLHttpRequest
open SharedComponents.Alerts
open SharedComponents.Toast
open SharedComponents.Spinners

let getConfigurationCmd =
    Cmd.OfPromise.either Communication.getRequest<Configuration> "/api/configuration" ConfigurationFetched FetchError

let updateConfigurationCmd (updateElement : ConfigurationUpdate) =
    let body = updateElement |> Thoth.Json.Encode.toString 0
    Cmd.OfPromise.either Communication.putRequest<PostResponse<EmptyResponse>> ("/api/configuration", body) ConfigurationSaved FetchError

let saveFileCmd formData =
    Cmd.OfPromise.either Communication.postFileRequest<PostResponse<EmptyResponse>> ("/api/configuration/company/image", formData) CompanyImageSaved FetchError

let getImage (imageId : DocumentId) =
    let (DocumentId imageId) = imageId
    Cmd.OfPromise.either Communication.getRequest<DocumentWithContent> (sprintf "/api/image/%s" (imageId.ToString ())) CompanyImageFetched FetchError

let init : Model * Cmd<Msg> =
    let initialModel =
        { FormState = FormState.Loading
          DocumentUploadForm = None
          RequestState = RequestState.Active }
    initialModel, getConfigurationCmd

let toActionList (action : (Msg -> 'a) -> unit) =
    let actionList = [action]
    actionList

let update (msg : Msg) (model : Model) =
    match msg with
    // Set company base information
    | SetCompanyName companyName ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newBaseInformation =
                    { form.Configuration.Company.CompanyBaseInformation with Name = companyName }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with CompanyBaseInformation = newBaseInformation } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetWebsite website ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newBaseInformation =
                    { form.Configuration.Company.CompanyBaseInformation with Website = website }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with CompanyBaseInformation = newBaseInformation } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetEmailAddress email ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newBaseInformation =
                    { form.Configuration.Company.CompanyBaseInformation with EmailAddress = email }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with CompanyBaseInformation = newBaseInformation } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetPhoneNumber phoneNumber ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newBaseInformation =
                    { form.Configuration.Company.CompanyBaseInformation with PhoneNumber = phoneNumber }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with CompanyBaseInformation = newBaseInformation } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetFaxNumber faxNumber ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newBaseInformation =
                    { form.Configuration.Company.CompanyBaseInformation with FaxNumber = faxNumber }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with CompanyBaseInformation = newBaseInformation } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    // Set home address
    | SetHomeCity city ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newHomeAddress =
                    { form.Configuration.Company.HomeAddress with City = city }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with HomeAddress = newHomeAddress } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHomeZipCode zipCode ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newHomeAddress =
                    { form.Configuration.Company.HomeAddress with ZipCode = zipCode }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with HomeAddress = newHomeAddress } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHomeStreet street ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newHomeAddress =
                    { form.Configuration.Company.HomeAddress with Street = street }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with HomeAddress = newHomeAddress } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none
    | SetHomeHouseNumber houseNumber ->
        let newFormState =
            match model.FormState with
            | FormState.Edit form ->
                let newHomeAddress =
                    { form.Configuration.Company.HomeAddress with HouseNumber = houseNumber }
                { form with Configuration = { form.Configuration with Company = { form.Configuration.Company with HomeAddress = newHomeAddress } } } |> FormState.Edit
            | FormState.Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Set edit states
    | EndEdit ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form ->
                { form with EditField = EditField.Nothing
                            Configuration = form.ConfigurationSnapshot }
                |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditBaseInformation ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form -> { form with EditField = EditField.EditBaseInformation
                                       Configuration = form.ConfigurationSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none
    | EditAddress ->
        let formState =
            match model.FormState with
            | FormState.Loading -> FormState.Loading
            | Edit form -> { form with EditField = EditField.EditAddress
                                       Configuration = form.ConfigurationSnapshot } |> FormState.Edit
        { model with FormState = formState }, Cmd.none

    // File upload
    | FromFile (action, target) ->
        match model.FormState with
        | FormState.Edit form ->
            let name : string = target?files?(0)?name
            let file = target?files?item(0)
            let documentUploadForm =
                match model.DocumentUploadForm with
                | Some duf -> { duf with Name = name }
                | None -> { File = file
                            Name = name
                            Description = ""
                            Target = Some target }
            let delayedAction newAction =
                let fileReader = createNew FileReader "" :?> FileReader
                fileReader?onloadend <- (fun () -> newAction <| action !!fileReader?result)
                fileReader?readAsBinaryString target?files?(0) |> ignore
            { model with DocumentUploadForm = Some documentUploadForm }, delayedAction |> toActionList
        | FormState.Loading -> model, Cmd.none
    | SetField content ->
        model, Cmd.none
    | UploadImage ->
        match model.FormState with
        | FormState.Edit form ->
            match model.DocumentUploadForm with
            | Some c ->
                let formData = FormData.Create()
                formData.append(c.Name, c.File)
                let cmd = saveFileCmd formData
                { model with RequestState = RequestState.Active }, Cmd.batch [ cmd; toast (ToastType.Info "Bild wird hochgeladen") ]
            | None -> model, Cmd.none
        | FormState.Loading -> model, Cmd.none
    | CompanyImageSaved response ->
        { model with RequestState = RequestState.NotActive }, Cmd.batch [ getConfigurationCmd; toast (ToastType.Success "Bild wurde hochgeladen") ]
    | CompanyImageFetched response ->
        let newFormState =
            match model.FormState with
            | Edit form ->
                { form with CompanyImage = Some response } |> FormState.Edit
            | Loading -> FormState.Loading
        { model with FormState = newFormState }, Cmd.none

    // Actions
    | SaveCompany ->
        let newModel, cmd =
            match model.FormState with
            | Edit form ->
                match form.EditField with
                | EditField.EditBaseInformation ->
                    { model with RequestState = RequestState.Active },
                    form.Configuration.Company.CompanyBaseInformation
                    |> ConfigurationUpdate.BaseInformation
                    |> updateConfigurationCmd
                | EditField.EditAddress ->
                    { model with RequestState = RequestState.Active },
                    form.Configuration.Company.HomeAddress
                    |> ConfigurationUpdate.HomeAddress
                    |> updateConfigurationCmd
                | EditField.Nothing -> model, Cmd.none
            | FormState.Loading -> model, Cmd.none
        newModel, cmd

    // Fetched
    | ConfigurationSaved response ->
        let newFormState, cmd =
            match model.FormState with
            | Edit form ->
                { Configuration = form.Configuration
                  CompanyImage = form.CompanyImage
                  ConfigurationSnapshot = form.Configuration
                  EditField = EditField.Nothing } |> FormState.Edit, toast (ToastType.Success "Konfiguration erfolgreich gespeichert.")
            | FormState.Loading -> FormState.Loading, Cmd.none
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, cmd
    | ConfigurationFetched configuration ->
        let cmd (configuration : Configuration) =
            match configuration.Company.Image with
            | Some id -> getImage id.Id
            | None -> Cmd.none
        let newFormState, cmd =
            match model.FormState with
            | Loading ->
                { Configuration = configuration
                  CompanyImage = None
                  ConfigurationSnapshot = configuration
                  EditField = EditField.Nothing } |> FormState.Edit, cmd configuration
            | Edit form ->
                { Configuration = configuration
                  CompanyImage = None
                  ConfigurationSnapshot = configuration
                  EditField = EditField.Nothing } |> FormState.Edit, cmd configuration
        { model with FormState = newFormState
                     RequestState = RequestState.NotActive }, cmd
    | FetchError e ->
        { model with RequestState = RequestState.NotActive }, ErrorHandling.handleFetchError e
