module Order.FreeOrder.FormValidation

open Order.FreeOrder.Types
open Shared.FreeOrder
open Validation
open System
open Shared.Address

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let isValid =
    { Validations = []
      FormValid = ValidationState.Valid }

let private create key value : ValidationKeyValue =
    { Key = key
      Value = value }

let formValid (newValidations : ValidationKeyValue list) =
    let isNotValid =
        newValidations
        |> List.exists (fun validation ->
            match validation.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    if isNotValid then ValidationState.NotValid "" else ValidationState.Valid

let private validateCity city =
    if city = "" then
        create "city" (ValidationState.NotValid "Stadt muss angegeben sein.")
    else create "city" ValidationState.Valid

let private validateStreet street =
    if street = "" then
        create "street" (ValidationState.NotValid "Straße muss angegeben sein.")
    else create "street" ValidationState.Valid

let private validateTitle street =
    if street = "" then
        create "title" (ValidationState.NotValid "Ein Titel muss angegeben sein.")
    else create "title" ValidationState.Valid

let validateNewForm (freeOrderForm : FreeOrderNewForm) : FormValidation =
    let freeOrder = freeOrderForm.FreeOrder
    let city _ = validateCity freeOrder.ExecutionAddress.City
    let street _ = validateStreet freeOrder.ExecutionAddress.Street
    let title _ = validateTitle freeOrder.Title
    let newValidations = [ street()
                           city()
                           title() ]
    { Validations = newValidations
      FormValid = formValid newValidations }